import { z } from "zod"

export const UserLogin = z.object({
  email: z.string().email({ message: "Email Address must be valid" }),
  password: z.string().min(1, "Password is required"),
  serviceParams: z
    .intersection(
      z.object({
        service: z.string(),
      }),
      z.record(z.string(), z.string())
    )
    .optional(),
})

export type UserLogin = z.infer<typeof UserLogin>
