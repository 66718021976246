import { CreateRegion } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { FormControlLabel, TextField } from "@vindral/components"
import { useForm } from "react-hook-form"
import { FormSection } from "../../../atoms/FormSection"
import { Page, PageContainer, PageContent, PageHeader } from "../../../atoms/Page"
import { SaveButton } from "../../../atoms/SaveButton"
import { SaveSection } from "../../../atoms/SaveSection"
import { Breadcrumbs } from "../../../molecules/Breadcrumbs"

const resolver = zodResolver(CreateRegion)

export const RegionCreate = ({ createRegion }: { createRegion: (value: CreateRegion) => Promise<void> }) => {
  const form = useForm<CreateRegion>({
    resolver,
    mode: "onChange",
    defaultValues: {
      name: "",
    },
  })
  const {
    handleSubmit,
    register,
    getFieldState,
    formState: { isDirty, isValid },
  } = form

  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
          </div>
        </PageHeader>
        <PageContainer>
          <form onSubmit={handleSubmit(createRegion)} autoComplete="off" className="flex flex-col gap-4 pt-4">
            <FormSection title="General" description="General settings">
              <div className="flex max-w-sm flex-col gap-4">
                <div className="flex flex-col gap-1">
                  <FormControlLabel required error={getFieldState("name").error?.message}>
                    Name
                  </FormControlLabel>
                  <TextField {...register("name")} />
                </div>
              </div>
            </FormSection>
            <SaveSection>
              <SaveButton text="Create" disabled={!isDirty || !isValid} />
            </SaveSection>
          </form>
        </PageContainer>
      </PageContent>
    </Page>
  )
}
