import { z } from "zod"
import { Order } from "../order"

export const RegionFindManyParameters = z
  .object({
    orderBy: z.enum(["name", "createdAt"]),
    search: z.string().optional(),
  })
  .merge(Order)

export type RegionFindManyParameters = z.infer<typeof RegionFindManyParameters>
