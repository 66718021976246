import { AccessPermissionRole } from "@core-services/data-types"
import { Expose, Type } from "class-transformer"
import { OrganizationDTO } from "../organization/organization.dto"

export class AccessPermissionsDTO {
  @Expose()
  id?: number

  @Expose()
  role!: AccessPermissionRole

  @Expose()
  @Type(() => OrganizationDTO)
  organization!: OrganizationDTO
}
