import { Transition } from "@headlessui/react"
import { Label as RadixLabel } from "@radix-ui/react-label"
import { forwardRef, Fragment, LabelHTMLAttributes } from "react"

export type LabelProps = LabelHTMLAttributes<HTMLLabelElement> & {
  required?: boolean
  error?: string
}

const classNames = {
  enter: "transform transition duration-300",
  enterFrom: "-translate-y-5 opacity-0",
  enterTo: "translate-y-0 opacity-100",
  leave: "transform duration-150 transition ease-out",
  leaveFrom: "translate-y-0 opacity-100",
  leaveTo: "translate-y-2 opacity-0 ",
}

export const Label = forwardRef<HTMLLabelElement, LabelProps>((props, ref) => {
  return (
    <RadixLabel ref={ref} {...props} className="relative block whitespace-nowrap">
      <div className="opacity-0" aria-hidden>
        placeholder-for-height
      </div>
      <Transition
        show={!props.error}
        as={Fragment}
        enter={classNames.enter}
        enterFrom={classNames.enterFrom}
        enterTo={classNames.enterTo}
        leave={classNames.leave}
        leaveFrom={classNames.leaveFrom}
        leaveTo={classNames.leaveTo}
      >
        <div className="absolute top-0">
          {props.children}
          {props.required && <span className="ml-1 inline-block h-2 w-2 rounded-full bg-solid-primary" />}
        </div>
      </Transition>
      <Transition
        show={!!props.error}
        as={Fragment}
        enter={classNames.enter}
        enterFrom={classNames.enterFrom}
        enterTo={classNames.enterTo}
        leave={classNames.leave}
        leaveFrom={classNames.leaveFrom}
        leaveTo={classNames.leaveTo}
      >
        <div className="absolute top-0 text-fg-danger-subtle">{props.error}</div>
      </Transition>
    </RadixLabel>
  )
})
Label.displayName = "Label"
