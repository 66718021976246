import { useAbility } from "@casl/react"
import { Action } from "@core-services/data-types"
import { IconPlus } from "@tabler/icons-react"
import { Link } from "@tanstack/react-router"
import { Button, Pagination } from "@vindral/components"
import { AbilityContext } from "../../acl"
import { Page, PageContainer, PageContent, PageFooter, PageHeader } from "../../atoms/Page"
import { Breadcrumbs } from "../../molecules/Breadcrumbs"
import { AdminUserTable, UseAdminUserReactTableProps, useAdminUserReactTable } from "../../organisms/AdminUserTable"

export function CreateUserButton() {
  const ability = useAbility(AbilityContext)

  if (!ability.can(Action.Create, "User")) {
    return null
  }

  return (
    <Link to="/users/create">
      <Button variant="primary">
        <IconPlus size={16} />
        Create user
      </Button>
    </Link>
  )
}

export type AllUserListProps = UseAdminUserReactTableProps & {
  saveColumnVisibility: () => void
  resetColumnVisibilityToDefault: () => void
  isStaleData: boolean
  totalCount: number
}

export function AdminUserList({ isStaleData, totalCount, ...props }: AllUserListProps) {
  const userTable = useAdminUserReactTable(props)
  if (props.data.length === 0 && !props.state?.globalFilter && !isStaleData) {
    return (
      <div className="relative flex justify-center pt-40">
        <div>
          <h1 className="font-medium">There is nothing here right now</h1>
          <p className="py-1 text-fg-subtle last-of-type:pb-3">You will be able to see users here very soon.</p>
        </div>
      </div>
    )
  }

  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
            <CreateUserButton />
          </div>
        </PageHeader>
        <PageContainer>
          <AdminUserTable {...props} table={userTable} />
        </PageContainer>
      </PageContent>

      <PageFooter>
        <div className="flex h-16 items-center justify-between">
          <div>
            {totalCount} User{totalCount > 1 ? "s" : ""}
          </div>
          <Pagination
            pageIndex={userTable.getState().pagination.pageIndex}
            pageCount={userTable.getPageCount()}
            nextPage={() => userTable.nextPage()}
            previousPage={() => userTable.previousPage()}
          />
        </div>
      </PageFooter>
    </Page>
  )
}
