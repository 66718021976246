import { createColumnHelper } from "@tanstack/react-table"
import { Button, TextField } from "@vindral/components"
import { useState } from "react"
import { MultiSelector, MultiSelectorFilterTextField, MultiSelectorTable } from "./MultiSelector"

export interface InputMultiSelectorProps {
  onSelect: (item: { value: string }) => void
  onDeselect: (index: number) => void
  selected: { value: string }[]
  nothingSelectedText: string
}

export function InputMultiSelector({ onSelect, onDeselect, selected, nothingSelectedText }: InputMultiSelectorProps) {
  const columnHelper = createColumnHelper<{ value: string }>()
  const columns = [
    columnHelper.accessor("value", {
      header: () => "Name",
      cell: (info) => <div className="flex gap-2 whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.display({
      id: "actions",
      header: () => "",
      cell: (info) => (
        <div className="flex justify-end">
          <Button variant="danger" size="small" onClick={() => onDeselect(info.row.index)}>
            Remove
          </Button>
        </div>
      ),
      size: 1,
    }),
  ]

  const hasSelectedAtLeastOneItem = selected.length > 0

  const onSelectUnique = (item: { value: string }) => {
    if (!selected.some((selectedItem) => selectedItem.value === item.value)) {
      onSelect(item)
    }
  }

  return (
    <MultiSelector>
      <div className="flex justify-between gap-4">
        <div className="max-w-xs">
          <MultiSelectInputField onSelect={onSelectUnique} />
        </div>
        <div className="shrink-0">
          <MultiSelectorFilterTextField />
        </div>
      </div>
      {hasSelectedAtLeastOneItem ? (
        <MultiSelectorTable data={selected} getRowId={(row) => row.value} columns={columns} />
      ) : (
        <div className="flex flex-col gap-2 py-4">{nothingSelectedText}</div>
      )}
    </MultiSelector>
  )
}

function MultiSelectInputField({ onSelect }: { onSelect: (item: { value: string }) => void }) {
  const [fieldValue, setFieldValue] = useState("")

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSelect({ value: fieldValue })
      setFieldValue("")
    }
  }

  return (
    <div className="flex items-center gap-2">
      <TextField value={fieldValue} onChange={(event) => setFieldValue(event.target.value)} onKeyDown={onKeyDown} />
      <Button
        variant="secondary"
        size="medium"
        onClick={() => {
          onSelect({ value: fieldValue })
          setFieldValue("")
        }}
      >
        Add
      </Button>
    </div>
  )
}
