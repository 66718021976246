import { InformationCircleIcon } from "@heroicons/react/20/solid"
import { zodResolver } from "@hookform/resolvers/zod"
import { Cross1Icon } from "@radix-ui/react-icons"
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  TextField,
} from "@vindral/components"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { Hyperlink } from "../../atoms/Hyperlink"
import Form from "../../organisms/Form"
import { ReCaptchaText } from "../../organisms/ReCaptchaText"
import { isEmailDomainForbidden } from "./isEmailDomainForbidden"

const resolver = zodResolver(z.object({ email: z.string().email({ message: "Email Address must be valid" }) }))

export interface VerifyEmailProps {
  verifyEmail: (value: { email: string }) => Promise<void>
  showLink: boolean
}

export const VerifyEmail = (props: VerifyEmailProps) => {
  const [showModal, setShowModal] = useState(false)
  const { verifyEmail, showLink } = props
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid, isSubmitSuccessful },
  } = useForm<{ email: string }>({
    resolver,
    mode: "onChange",
  })

  const verifyIfValid = (event: { email: string }) => {
    if (isEmailDomainForbidden(event)) {
      setShowModal(true)
      return Promise.reject()
    }

    return verifyEmail(event)
  }

  if (isSubmitSuccessful) {
    return (
      <div className="my-4 rounded-b bg-canvas-positive-card px-4 py-3 shadow-md" role="alert">
        <div className="flex">
          <div className="py-1">
            <InformationCircleIcon className="mr-4 h-6 w-6 text-solid-positive" />
          </div>
          <div>
            <p className="font-semibold text-fg">Check your email inbox</p>
            <p className="text-fg-positive">We sent an email link to complete your registration.</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Form onSubmit={handleSubmit(verifyIfValid)} className="flex max-w-md flex-col gap-4 py-4">
      <FormControl>
        <FormControlLabel error={errors.email?.message} required>
          E-mail
        </FormControlLabel>
        <TextField {...register("email")} placeholder="email@domain.com" autoComplete="email" data-pw="email" />
        <ReCaptchaText />
      </FormControl>
      <div>
        <Button disabled={!isDirty || !isValid} type="submit" variant="primary">
          Sign up
        </Button>
      </div>
      <Dialog open={showModal}>
        <DialogContent size={"small"}>
          <div className="flex items-start justify-between p-3">
            <span className="min-w-0 font-medium">Unsupported email</span>
            <DialogClose className="" onClick={() => setShowModal(false)}>
              <Cross1Icon></Cross1Icon>
            </DialogClose>
          </div>
          <div className="">
            <div className="flex flex-col gap-2 p-3">
              <p>
                Vindral is only available for registered corporations and organizations. You must use a corporate email.
              </p>
              <p>
                If you represent a valid corporation/organization but explicitly want to use another email address -
                contact us for manual handling.
              </p>
            </div>
            <Divider />
            <div className="flex justify-end gap-3 p-3">
              <Button onClick={() => setShowModal(false)} variant="secondary" size={"small"}>
                Cancel
              </Button>
              <a href="https://vindral.com/contact" target="_blank" rel="noreferrer">
                <Button onClick={() => setShowModal(false)} variant="primary" size={"small"}>
                  Contact us
                </Button>
              </a>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {showLink && (
        <div className="mt-1 text-sm">
          <Hyperlink href="https://vindral.com/" external text="Read more about Vindral" />
        </div>
      )}
    </Form>
  )
}
