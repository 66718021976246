import { LimitationMode } from "@core-services/data-types"
import { SiteItem } from "@core-services/portal"
import { CheckIcon, Cross2Icon, ExclamationTriangleIcon, ListBulletIcon } from "@radix-ui/react-icons"
import { IconAdjustmentsFilled } from "@tabler/icons-react"
import { Link } from "@tanstack/react-router"
import {
  TableOptions,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSwitchItem,
  DropdownMenuTrigger,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from "@vindral/components"
import { ReactNode } from "react"
import { DebouncedTextField } from "../molecules/DebouncedTextField"
import { trpc } from "../trpc"
import { checkSiteAllowNothing, isSiteLimited } from "../utils/limited"
import { InteractiveTable } from "./InteractiveTable"
import { NoResultsFound } from "./NoResultsFound"

function columnNames(id: string) {
  switch (id) {
    case "name":
      return "Name"
    case "code":
      return "Code"
    case "providers":
      return "Provider"
    case "region.name":
      return "Region"
    case "latitude":
      return "Latitude"
    case "longitude":
      return "Longitude"
    case "limited":
      return "Limited"
    case "mediaIngressActive":
      return "Media Ingress"
    case "viewerTrafficActive":
      return "Load Balancer"
    case "transcodingActive":
      return "Transcoding"
    case "edgeOriginActive":
      return "Edge origin"
    case "edgeEgressActive":
      return "Edge egress"
    case "transcodingPriority":
      return "Transcoding Prio"
  }

  return id
}

function SiteLink({ code, children }: { code?: string; children: ReactNode }) {
  if (!code) {
    return null
  }

  return (
    <Link to="/sites/edit/$code/general" className="whitespace-nowrap font-semibold" params={{ code }}>
      {children}
    </Link>
  )
}

function isNumbers(array: number[] | string[]): array is number[] {
  return typeof array[0] === "number"
}

function LimitationHoverRow({
  mode,
  name,
  limited,
}: {
  mode?: LimitationMode
  name?: string
  limited?: number[] | string[]
}) {
  if (mode === "allow" && limited && limited.length === 0) {
    return (
      <>
        <span style={{ textTransform: "capitalize" }}>{mode}</span>
        {name}:<span className="pl-1 font-semibold">ATTENTION! This will block everything!</span>
      </>
    )
  }

  if (!mode || !name || !limited || limited.length === 0) {
    return null
  }

  let limitedText = limited.join(", ")

  if (name === "Org" && isNumbers(limited)) {
    const { data: organizationsData } = trpc.organization.findIdentifiersByIds.useQuery(
      { ids: limited },
      { staleTime: 10_000 }
    )

    limitedText = organizationsData ? organizationsData.map((x) => x.name).join(", ") : limitedText
  }

  if (name === "Channel" && isNumbers(limited)) {
    const { data: channelsData } = trpc.channel.findIdentifiersByIds.useQuery({ ids: limited }, { staleTime: 10_000 })

    limitedText = channelsData ? channelsData.map((x) => x.name).join(", ") : limitedText
  }

  return (
    <>
      <span style={{ textTransform: "capitalize" }}>{mode}</span> {name}: {limitedText}
    </>
  )
}

const LimitationsCell = (props: SiteItem) => {
  if (!isSiteLimited(props)) {
    return <></>
  }

  const { asnLimitation, channelLimitation, countryLimitation, organizationLimitation } = props
  const limitations = [
    { limitable: asnLimitation, name: "ASN" },
    { limitable: channelLimitation, name: "Channel" },
    { limitable: countryLimitation, name: "Country" },
    { limitable: organizationLimitation, name: "Org" },
  ].filter((l) => l.limitable?.mode !== "disabled")

  const Limitations = () => (
    <>
      {limitations.map((limitation, index) => (
        <div key={`${props.code}-${index}`}>
          <LimitationHoverRow name={limitation.name} {...limitation.limitable} />
        </div>
      ))}
    </>
  )

  const iconSize = 20
  const allowNothingWarning = checkSiteAllowNothing(props)

  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger
          style={{
            height: "100%",
            width: "100%",
            cursor: "auto",
            display: "flex",
            justifyContent: "center",
          }}
          className="text-solid-warning"
        >
          <ExclamationTriangleIcon width={iconSize} height={iconSize} />
          {allowNothingWarning && <span className="relative bottom-1">*</span>}
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent side="top">
            <Limitations />
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  )
}

function ActivatedIndicatorCell({ active }: { active?: boolean }) {
  const className = active ? "text-solid-positive" : "text-solid-danger"
  const iconSize = 22

  return (
    <div style={{ display: "flex", justifyContent: "center" }} className={className}>
      {active ? <CheckIcon width={iconSize} height={iconSize} /> : <Cross2Icon width={iconSize} height={iconSize} />}
    </div>
  )
}

function TranscodingPriorityCell(props: SiteItem) {
  if (!props || !props.transcodingPriority || props.transcodingPriority.length === 0) {
    return null
  }

  const PrioritizedSites = (site: SiteItem) => (
    <>
      {site.transcodingPriority?.map((site) => {
        const className = site.destinationSite.transcodingActive ? "text-solid-positive" : "text-solid-danger"
        return (
          <span key={`o${props?.code}-p${site.destinationSite.code}`} className={className}>
            {site.destinationSite.code}&nbsp;
          </span>
        )
      })}
    </>
  )
  const iconSize = 20

  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger
          style={{
            height: "100%",
            width: "100%",
            cursor: "auto",
            display: "flex",
            justifyContent: "center",
          }}
          className="text-solid-primary"
        >
          <ListBulletIcon width={iconSize} height={iconSize} />
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent side="top">
            <PrioritizedSites {...props} />
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  )
}

export type SiteTableProps = Omit<
  TableOptions<SiteItem>,
  "columns" | "getCoreRowModel" | "getSortedRowModel" | "getPaginationRowModel"
> & {
  saveColumnVisibility: () => void
  resetColumnVisibilityToDefault: () => void
}

export function SiteTable(options: SiteTableProps) {
  const columnHelper = createColumnHelper<SiteItem>()
  const columns = [
    columnHelper.accessor("name", {
      header: () => "Name",
      cell: (info) => <SiteLink code={info.row.original.code}>{info.getValue()}</SiteLink>,
      size: 150,
      maxSize: 150,
    }),
    columnHelper.accessor("code", {
      header: () => "Code",
      cell: (info) => <span className="text-fg-subtle">{info.getValue()}</span>,
    }),
    columnHelper.accessor("providers", {
      header: () => "Provider",
      cell: (info) => <span className="text-fg-subtle">{info.getValue()}</span>,
    }),
    columnHelper.accessor("region.name", {
      id: "region.name",
      header: () => "Region",
      cell: (info) => <span className="text-fg-subtle">{info.getValue()}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor("latitude", {
      header: () => "Latitude",
      cell: (info) => <span className="text-fg-subtle">{info.getValue()}</span>,
    }),
    columnHelper.accessor("longitude", {
      header: () => "Longitude",
      cell: (info) => <span className="text-fg-subtle">{info.getValue()}</span>,
    }),
    columnHelper.display({
      id: "limited",
      header: () => (
        <Tooltip delayDuration={0}>
          <TooltipTrigger asChild>
            <div>Limited</div>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent sideOffset={5}>
              <div className="max-w-sm">
                <p>
                  Limitations can be added to a site to restrict which channels, organizations, countries and ASNs that
                  can use the site.
                </p>
              </div>
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      ),
      meta: { style: { textAlign: "center" } },
      cell: (info) => <LimitationsCell {...info.row.original} />,
      enableSorting: false,
      size: 45,
      maxSize: 45,
    }),
    columnHelper.accessor("transcodingPriority", {
      header: () => "Transcoding Prio",
      cell: (info) => <TranscodingPriorityCell {...info.row.original} />,
      size: 45,
      maxSize: 45,
    }),
    columnHelper.accessor("mediaIngressActive", {
      header: () => "Media Ingress",
      meta: { style: { textAlign: "center" } },
      cell: (info) => <ActivatedIndicatorCell active={info.getValue()} />,
      size: 45,
      maxSize: 45,
    }),
    columnHelper.accessor("viewerTrafficActive", {
      header: () => "Load Balancer",
      meta: { style: { textAlign: "center" } },
      cell: (info) => <ActivatedIndicatorCell active={info.getValue()} />,
      size: 45,
      maxSize: 45,
    }),
    columnHelper.accessor("transcodingActive", {
      header: () => "Transcoding",
      meta: { style: { textAlign: "center" } },
      cell: (info) => <ActivatedIndicatorCell active={info.getValue()} />,
      enableSorting: false,
      size: 45,
      maxSize: 45,
    }),
    columnHelper.accessor("edgeOriginActive", {
      header: () => "Edge origin",
      meta: { style: { textAlign: "center" } },
      cell: (info) => <ActivatedIndicatorCell active={info.getValue()} />,
      size: 45,
      maxSize: 45,
    }),
    columnHelper.accessor("edgeEgressActive", {
      header: () => "Edge egress",
      meta: { style: { textAlign: "center" } },
      cell: (info) => <ActivatedIndicatorCell active={info.getValue()} />,
      size: 45,
      maxSize: 45,
    }),
  ]

  const table = useReactTable({
    ...options,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowId: (row) => row.publicId ?? row.name,
  })

  // If there is a filter AND no rows - that means we should show "no results".
  // This is different from zero rows and no filter - in that case we should show
  // a call to action.
  const noResults = table.getRowModel().rows.length === 0 && !!table.getState().globalFilter

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-end gap-2">
        <div>
          <DebouncedTextField
            // eslint-disable-next-line total-functions/no-unsafe-type-assertion
            initialValue={table.getState().globalFilter as string}
            onValueChange={table.setGlobalFilter}
            debounceMs={200}
            placeholder="Filter..."
          />
        </div>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button>
              <IconAdjustmentsFilled size={16} />
              Configure
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent sideOffset={4} align="start">
            {table.getAllLeafColumns().map((column) => {
              return (
                <DropdownMenuSwitchItem
                  key={column.id}
                  checked={column.getIsVisible()}
                  onSelect={() => column.toggleVisibility()}
                >
                  {columnNames(column.id)}
                </DropdownMenuSwitchItem>
              )
            })}
            <DropdownMenuSeparator />
            <DropdownMenuItem onSelect={() => options.saveColumnVisibility()}>Save</DropdownMenuItem>
            <DropdownMenuItem onSelect={() => options.resetColumnVisibilityToDefault()}>
              Reset to default
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      {noResults ? <NoResultsFound /> : <InteractiveTable table={table} includePaginationFooter={true} />}
    </div>
  )
}
