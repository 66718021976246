import { Expose, Type } from "class-transformer"
import { UserDTO } from "../user/user.dto"

export class LoginResponseDTO {
  @Expose()
  @Type(() => UserDTO)
  user!: UserDTO

  @Expose()
  redirectUrl?: string
}
