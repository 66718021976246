import { Expose } from "class-transformer"
import { BaseDTO } from "../base.dto"

export class OrganizationUsageRowDTO extends BaseDTO<OrganizationUsageRowDTO> {
  @Expose()
  id!: number

  @Expose()
  bytesTotal!: number

  @Expose()
  bytesThumbnail!: number

  @Expose()
  bytesEgress!: number

  @Expose()
  channelId!: string

  @Expose()
  channelName!: string
}

export class OrganizationUsageDTO extends BaseDTO<OrganizationUsageDTO> {
  @Expose()
  rows!: OrganizationUsageRowDTO[]

  @Expose()
  bytesTotal!: number

  @Expose()
  bytesThumbnail!: number

  @Expose()
  bytesEgress!: number
}
