import { AppAbility, RegionFindById, UpdateRegion } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { Button, ConfirmDialogContent, Dialog, DialogTrigger, FormControlLabel, TextField } from "@vindral/components"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { FormSection } from "../../../atoms/FormSection"
import { Page, PageContainer, PageContent, PageFooter, PageHeader } from "../../../atoms/Page"
import { SaveButton } from "../../../atoms/SaveButton"
import { SaveSection } from "../../../atoms/SaveSection"
import { Breadcrumbs } from "../../../molecules/Breadcrumbs"
import { FormattedDate } from "../../../molecules/FormattedDate"
import { Titled } from "../../../molecules/Titled"

const resolver = zodResolver(UpdateRegion)

export interface RegionEditProps {
  region: RegionFindById
  ability: AppAbility
  onUpdate: (region: UpdateRegion) => Promise<void>
  onDelete: () => Promise<void>
  error?: { message?: string; name?: string } | null
}

export const RegionEdit = ({ onUpdate, onDelete, region }: RegionEditProps) => {
  const form = useForm<UpdateRegion>({
    resolver,
    mode: "onChange",
    defaultValues: {
      publicId: region.publicId,
      name: region.name,
    },
  })
  const {
    handleSubmit,
    reset,
    register,
    getFieldState,
    formState: { isDirty, isValid, isSubmitSuccessful },
  } = form

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful, reset])

  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
          </div>
        </PageHeader>
        <PageContainer>
          <form onSubmit={handleSubmit(onUpdate)} autoComplete="off" className="flex flex-col gap-4 pt-4">
            <FormSection title="General" description="General settings">
              <div className="flex max-w-sm flex-col gap-4">
                <div className="flex flex-col gap-1">
                  <FormControlLabel required error={getFieldState("name").error?.message}>
                    Name
                  </FormControlLabel>
                  <TextField {...register("name")} />
                </div>
              </div>
            </FormSection>
            <SaveSection>
              <SaveButton text="Save" disabled={!isDirty || !isValid} />
            </SaveSection>
          </form>
        </PageContainer>
      </PageContent>
      <PageFooter>
        <div className="flex h-16 items-center justify-between">
          <div className="flex gap-8">
            <Titled title="Created">{region.createdAt && <FormattedDate date={new Date(region.createdAt)} />}</Titled>
            {typeof region.updatedAt !== "undefined" && (
              <Titled title="Updated">
                <FormattedDate date={new Date(region.updatedAt)} />
              </Titled>
            )}
          </div>
          <Dialog>
            <DialogTrigger asChild>
              <Button variant="danger" data-pw="deleteButton">
                Delete
              </Button>
            </DialogTrigger>
            <ConfirmDialogContent
              onConfirm={onDelete}
              title={`Confirm deletion of region ${region.name}`}
              description={
                <>
                  Please confirm that you are absolutely sure that you want to delete region
                  <span className="font-medium"> {region.name}</span>.
                </>
              }
            />
          </Dialog>
        </div>
      </PageFooter>
    </Page>
  )
}
