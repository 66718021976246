export function formatFrameSkipping(
  frameSkipping: "none" | "half" | "quarter" | "frame_every_1_seconds" | "frame_every_5_seconds"
) {
  switch (frameSkipping) {
    case "frame_every_1_seconds":
      return "Every second"
    case "frame_every_5_seconds":
      return "Every 5 seconds"
    case "half":
      return "Half"
    case "quarter":
      return "Quarter"
    case "none":
      return "None"
  }

  return frameSkipping
}
