import { ComponentProps, ElementRef, forwardRef, ImgHTMLAttributes, ReactNode } from "react"
import { Button } from "../atoms/Button"

export interface CallToActionProps {
  imageSrc: string
  title: string
  paragraphs: string[]
  callToActionText: string
  onClick: () => void
}

export function CallToAction({ imageSrc, title, paragraphs, callToActionText, onClick }: CallToActionProps) {
  return (
    <div className="flex items-start gap-4">
      <CallToActionIcon src={imageSrc} className="w-16" />
      <div className="">
        <CallToActionTitle>{title}</CallToActionTitle>
        {paragraphs.map((paragraph) => {
          return <CallToActionParagraph key={paragraph} text={paragraph} />
        })}
        <CallToActionButton onClick={onClick}>{callToActionText}</CallToActionButton>
      </div>
    </div>
  )
}

export function CallToActionTitle({ children }: { children: ReactNode }) {
  return <h1 className="font-medium">{children}</h1>
}

export const CallToActionIcon = forwardRef<HTMLImageElement, ImgHTMLAttributes<HTMLImageElement>>((props, ref) => {
  return <img {...props} ref={ref} />
})

export function CallToActionParagraph({ text }: { text: string }) {
  return <p className="pb-1 text-fg-subtle last-of-type:pb-3">{text}</p>
}

export const CallToActionButton = forwardRef<ElementRef<typeof Button>, ComponentProps<typeof Button>>((props, ref) => {
  return (
    <Button {...props} variant="primary" ref={ref}>
      {props.children}
    </Button>
  )
})
