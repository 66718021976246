import { Outlet } from "@tanstack/react-router"
import { ReactNode } from "react"
import { RouteTabs, RouteTrigger } from "../../molecules/RouteTabs"
import { PageLayout } from "../../organisms/PageLayout"

interface AuthProps {
  pathname: string
}

const LoginDescription = () => {
  return (
    <>
      <h1 className="text-2xl">Welcome back</h1>
      <p className="text-lg">Sign in to your account</p>
    </>
  )
}

const RegisterDescription = () => {
  return (
    <>
      <h1 className="text-2xl">Sign up for free</h1>
      <p className="text-lg">Sign up for a free trial account* and get started using Vindral in a matter of minutes!</p>
      <ul className="list-inside list-disc text-lg">
        <li>No credit card needed</li>
        <li>No commitment</li>
        <li>Quality of service client</li>
        <li>Detailed analytics</li>
        <li>Transcoding (ABR)</li>
        <li>Global egress</li>
        <li>Easy-to-integrate video player</li>
        <li>Powerful SDKs</li>
        <li>Ultra-low latency</li>
      </ul>
      <p className="text-sm italic">
        * Trial accounts are only available for registered companies and organizations. Trial accounts are limited to 10
        simultaneous viewers. AV1 transcoding is not available for trial accounts.
      </p>
    </>
  )
}

const ForgotPasswordDescription = () => {
  return (
    <>
      <h1 className="text-2xl">Forgot password?</h1>
      <p className="text-lg">
        Enter the email address associated with your account and we will send you a link to reset your password
      </p>
    </>
  )
}

const authPathDescriptionMapping: Record<string, ReactNode> = {
  "/auth/login": <LoginDescription />,
  "/auth/register": <RegisterDescription />,
  "/auth/forgot-password": <ForgotPasswordDescription />,
}

export const Auth = (props: AuthProps) => {
  const { pathname } = props

  return (
    <PageLayout description={authPathDescriptionMapping[pathname]}>
      <div className="max-w-md px-4">
        <RouteTabs border="none">
          <RouteTrigger to="/auth/register" search border="none">
            REGISTER
          </RouteTrigger>
          <RouteTrigger to="/auth/login" search border="none">
            SIGN IN
          </RouteTrigger>
          <RouteTrigger to="/auth/forgot-password" search border="none">
            FORGOT PASSWORD
          </RouteTrigger>
        </RouteTabs>
        <Outlet />
      </div>
    </PageLayout>
  )
}
