import { Action, denyNothingStringLimitation } from "@core-services/data-types"
import { AppAbility, OrganizationFindOne, UpdateOrganization } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { IconAlertCircle } from "@tabler/icons-react"
import { createColumnHelper } from "@tanstack/react-table"
import { Button } from "@vindral/components"
import { useEffect } from "react"
import { useController, useFieldArray, useForm } from "react-hook-form"
import { z } from "zod"
import { checkIfSuperUser, organizationSubjectInOrganization } from "../../../acl"
import { FormSection } from "../../../atoms/FormSection"
import { SaveButton } from "../../../atoms/SaveButton"
import { SaveSection } from "../../../atoms/SaveSection"
import { LimitationModeSelect } from "../../../molecules/LimitationModeSelect"
import { RouteBlockDialog } from "../../../molecules/RouteBlockDialog"
import { MultiSelectorSiteCombobox } from "../../../organisms/multiselect/MultiSelectSiteCombobox"
import {
  MultiSelector,
  MultiSelectorFilterTextField,
  MultiSelectorTable,
} from "../../../organisms/multiselect/MultiSelector"

const resolver = zodResolver(UpdateOrganization.merge(z.object({ siteLimitation: z.any() })))

export interface OrganizationEditSiteRulesProps {
  organization: OrganizationFindOne
  ability: AppAbility
  onUpdate: (organization: UpdateOrganization) => Promise<void>
}
export function OrganizationEditSiteRules({ organization, ability, onUpdate }: OrganizationEditSiteRulesProps) {
  const subject = organizationSubjectInOrganization(organization.publicId)
  const siteLimitation = organization.siteLimitation ?? denyNothingStringLimitation
  const isSuperUser = checkIfSuperUser({ ability })

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid, isSubmitting, isSubmitSuccessful },
    reset,
  } = useForm({
    resolver,
    mode: "onChange",
    defaultValues: {
      publicId: organization.publicId,
      siteLimitation: { mode: siteLimitation.mode, limited: siteLimitation.limited.map((l) => ({ code: l })) },
    },
  })

  useEffect(() => {
    reset({
      publicId: organization.publicId,
      siteLimitation: { mode: siteLimitation.mode, limited: siteLimitation.limited.map((l) => ({ code: l })) },
    })
  }, [organization.publicId, reset, siteLimitation])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful, reset])

  const fieldArray = useFieldArray({ control, name: "siteLimitation.limited" })
  const columnHelper = createColumnHelper<typeof fieldArray.fields[0]>()
  const columns = [
    columnHelper.accessor("code", {
      header: () => "Name",
      cell: (info) => <div className="flex gap-2 whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.display({
      id: "actions",
      header: () => "",
      cell: (info) => (
        <div className="flex justify-end">
          <Button variant="danger" size="small" onClick={() => fieldArray.remove(info.row.index)}>
            Remove
          </Button>
        </div>
      ),
      size: 1,
    }),
  ]
  const mode = useController({ control, name: "siteLimitation.mode" })
  const hasSelectedAtLeastOneItem = fieldArray.fields.length > 0
  const willAlwaysBlock = !hasSelectedAtLeastOneItem && mode.field.value === "allow"
  const showCallToAction = mode.field.value !== "disabled" && !hasSelectedAtLeastOneItem

  if (!isSuperUser) {
    return <></>
  }

  const columnWidth = "w-full max-w-lg"

  return (
    <div className="py-4">
      <RouteBlockDialog condition={isDirty && !isSubmitting} />
      <form
        className="flex flex-col gap-4"
        onSubmit={handleSubmit((org) => {
          console.log("submit")
          return onUpdate({
            ...org,
            siteLimitation: {
              mode: org.siteLimitation.mode,
              limited: org.siteLimitation.limited.map((limited) => limited.code),
            },
          })
        })}
      >
        <FormSection
          width={columnWidth}
          title="Site limitation"
          description="Limit which sites this organization can use."
        >
          <div className="flex flex-col gap-4">
            <div>
              <LimitationModeSelect
                value={mode.field.value}
                onValueChange={(newRole) => mode.field.onChange(newRole)}
              />
            </div>

            <div className={mode.field.value === "disabled" ? "pointer-events-none select-none opacity-50" : ""}>
              <MultiSelector>
                <div className="flex justify-between gap-4 pb-4">
                  <MultiSelectorSiteCombobox
                    onSelect={(item) => fieldArray.prepend(item)}
                    selected={fieldArray.fields}
                  />
                  <div className="shrink-0">
                    <MultiSelectorFilterTextField />
                  </div>
                </div>

                {hasSelectedAtLeastOneItem ? (
                  <MultiSelectorTable data={fieldArray.fields} getRowId={(row) => row.code} columns={columns} />
                ) : null}
                {willAlwaysBlock ? (
                  <div className="flex items-center gap-2 py-4 font-medium text-fg-danger-subtle">
                    <IconAlertCircle className="h-4 w-4" />
                    This rule will always block this organization unless you pick at least one site.
                  </div>
                ) : null}
                {showCallToAction ? (
                  <div className="flex flex-col gap-2 py-4">
                    Select sites from the dropdown to {mode.field.value} this organization to be served from there
                  </div>
                ) : null}
              </MultiSelector>
            </div>
          </div>
        </FormSection>
        {ability.can(Action.Update, subject, "siteLimitation") && (
          <SaveSection width={columnWidth}>
            <SaveButton text="Save" disabled={!isDirty || !isValid} />
          </SaveSection>
        )}
      </form>
    </div>
  )
}
