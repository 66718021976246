import { CreateSupportTicket } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { Cross1Icon } from "@radix-ui/react-icons"
import { IconPaperclip } from "@tabler/icons-react"
import { Button, DialogClose, DialogContent, FormControlLabel, TextField, toast } from "@vindral/components"
import { useState } from "react"
import { useController, useForm } from "react-hook-form"
import { Hyperlink } from "../atoms/Hyperlink"
import { useCreateSupportTicket } from "../http/mutations"
import { trpc } from "../trpc"

const resolver = zodResolver(CreateSupportTicket)

type SupportFormDialogProps = {
  subject: string
  email: string
}

const DialogHeader = () => (
  <div className="flex justify-between">
    <span className="font-medium">Support</span>
    <DialogClose className="cursor-pointer">
      <Cross1Icon />
    </DialogClose>
  </div>
)

const SubmitForm = ({
  email,
  subject,
  onSubmitSuccessful,
}: SupportFormDialogProps & { onSubmitSuccessful: () => void }) => {
  const { data: isFreshdeskUser } = trpc.support.isFreshdeskUser.useQuery({ email }, { enabled: !!email })
  const ticketMutation = useCreateSupportTicket()

  const createTicket = async (args: CreateSupportTicket) => {
    await ticketMutation.mutateAsync(args, {
      onSuccess: () => {
        onSubmitSuccessful()
        toast({ title: "Ticket created!" })
      },
      onError: (error) => {
        toast({ title: "Failed to create ticket!", description: error.message })
      },
    })
  }

  const {
    control,
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<CreateSupportTicket>({
    resolver,
    mode: "onChange",
    defaultValues: { description: "", attachments: undefined, subject, email },
  })
  const { field: attachmentsField } = useController({ control, name: "attachments" })

  return (
    <form
      encType="multipart/form-data"
      onSubmit={handleSubmit((args) => createTicket(args))}
      className={ticketMutation.isLoading ? "animate-pulse" : ""}
    >
      <div className="flex flex-col gap-4 p-3">
        <DialogHeader />
        <div className="overflow-y-auto">
          <p className="text-fg-subtle">
            What is the issue? Please describe the problem as detailed as possible. We will reply on your registered
            e-mail as soon as we can.
          </p>
        </div>
      </div>
      <hr className="border-divider" />
      <div className="flex flex-col gap-4 p-3">
        <div className="flex flex-col gap-1">
          {errors.description?.message && (
            <FormControlLabel error={errors.description?.message} required>
              Description
            </FormControlLabel>
          )}
          <TextField
            {...register("description")}
            placeholder="Describe the issue..."
            invisible
            disabled={ticketMutation.isLoading}
            readOnly={ticketMutation.isLoading}
            multiline
            minRows={2}
            required
            autoFocus
          />
        </div>
        <div className="flex flex-col gap-1">
          {errors.attachments?.message && (
            <div className="text-fg-danger-subtle transition-colors">{errors.attachments.message}</div>
          )}
          <label htmlFor="file_input" className="-m-1 flex w-fit cursor-pointer items-center gap-1 p-1 text-fg-subtle">
            <IconPaperclip className="h-3.5 w-3.5" />
            Attach images or videos
          </label>
          <input
            disabled={ticketMutation.isLoading}
            multiple
            className="hidden"
            id="file_input"
            type="file"
            name="attachments"
            onChange={(e) => {
              attachmentsField.onChange(e.target.files)
            }}
          />
          {attachmentsField.value?.length ? (
            <span className="line-clamp-2 text-sm text-fg-subtle">
              {Array.from(attachmentsField.value)
                .map((file) => file.name)
                .join(", ")}
            </span>
          ) : null}
        </div>
      </div>
      <hr className="border-divider" />
      <div className="flex flex-col gap-4 p-3">
        <div className="flex items-center justify-end gap-3">
          {isFreshdeskUser ? (
            <div className="grow">
              Or, create ticket in <Hyperlink href="https://realsprint-support.freshdesk.com/" text="Freshdesk" />
            </div>
          ) : null}
          <DialogClose asChild>
            <Button size="small" variant="secondary">
              Cancel
            </Button>
          </DialogClose>
          <Button disabled={!isValid || ticketMutation.isLoading} size="small" variant="primary" type="submit">
            Send
          </Button>
        </div>
      </div>
    </form>
  )
}

const Submitted = () => (
  <div className="flex flex-col gap-4 p-3">
    <DialogHeader />
    <div className="flex h-12 w-full items-center justify-center">Thank you. We will get back to you shortly.</div>
    <div className="flex justify-end gap-3">
      <DialogClose asChild>
        <Button size="small" variant="tertiary">
          Close
        </Button>
      </DialogClose>
    </div>
  </div>
)

const Content = (props: SupportFormDialogProps) => {
  const [submitted, setSubmitted] = useState(false)
  return submitted ? <Submitted /> : <SubmitForm onSubmitSuccessful={() => setSubmitted(true)} {...props} />
}

export function SupportFormDialog(props: SupportFormDialogProps) {
  return (
    <DialogContent
      size="small"
      // Prevent closing on click outside
      onInteractOutside={(e) => e.preventDefault()}
    >
      <Content {...props} />
    </DialogContent>
  )
}
