import { OrganizationType } from "@core-services/data-types"
import { OrganizationBillingStatus } from "@core-services/data-types/src/lib/organization-billing-status"
import { z } from "zod"

export const CreateOrganization = z.object({
  name: z.string().min(2).max(50),
  type: z.nativeEnum(OrganizationType),
  billingStatus: z.nativeEnum(OrganizationBillingStatus).optional(),
  country: z.string().max(100).optional(),
  industry: z.string().max(100).optional(),
  companySize: z.string().max(100).optional(),
})

export type CreateOrganization = z.infer<typeof CreateOrganization>
