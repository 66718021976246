import { Divider } from "@vindral/components"
import { FormSection } from "../../../atoms/FormSection"
import { Hyperlink } from "../../../atoms/Hyperlink"
import { grafanaUrl, nomadUrl } from "../../../utils/urls"
import { ChannelFindById } from "../ChannelEdit"

export interface ChannelEditDebugProps {
  channel: ChannelFindById
}

export function ChannelEditDebug({ channel }: ChannelEditDebugProps) {
  const { streamKey } = channel

  const nomadLink = `${nomadUrl()}/ui/jobs?search=${streamKey}`
  const metricsMediaIngress = `${grafanaUrl()}/d/ff2da599-149e-4362-9e13-3051bcbaebc9/media-ingress-per-stream-key?var-stream_key=${streamKey}`
  const metricsPackager = `${grafanaUrl()}/d/cX-u-8Fnk/packager-per-stream-key?var-stream_key=${streamKey}`
  const metricViewers = `${grafanaUrl()}/d/PxIsvum4k/viewer-metrics?var-stream_key=${streamKey}`
  const logsMediaIngress = `${grafanaUrl()}/d/Q-_k4QHnz/logs?var-job=%5Emedia-ingress-rust&var-search=${streamKey}`
  const logsPackager = `${grafanaUrl()}/d/Q-_k4QHnz/logs?var-job=%5Epackager&var-search=${streamKey}`

  return (
    <div>
      <div className="flex flex-col gap-4">
        <FormSection title="Nomad" description={<div className="flex flex-col gap-4"></div>}>
          <div className="flex flex-col gap-4">
            <div>
              <div className="text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-300">
                packager
              </div>
              <Hyperlink href={nomadLink} text={nomadLink} />
            </div>
          </div>
        </FormSection>
        <Divider />
        <FormSection title="Metrics" description={<div className="flex flex-col gap-4"></div>}>
          <div className="flex flex-col gap-4">
            <div>
              <div className="text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-300">
                media ingress
              </div>
              <Hyperlink href={metricsMediaIngress} text={metricsMediaIngress} />
            </div>
            <div>
              <div className="text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-300">
                packager
              </div>
              <Hyperlink href={metricsPackager} text={metricsPackager} />
            </div>
            <div>
              <div className="text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-300">
                viewer metrics
              </div>
              <Hyperlink href={metricViewers} text={metricViewers} />
            </div>
          </div>
        </FormSection>
        <Divider />
        <FormSection title="Logs" description={<div className="flex flex-col gap-4"></div>}>
          <div className="flex flex-col gap-4">
            <div>
              <div className="text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-300">
                media ingress
              </div>
              <Hyperlink href={logsMediaIngress} text={logsMediaIngress} />
            </div>
            <div>
              <div className="text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-300">
                packager
              </div>
              <Hyperlink href={logsPackager} text={logsPackager} />
            </div>
          </div>
        </FormSection>
      </div>
    </div>
  )
}
