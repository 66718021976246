import { Expose } from "class-transformer"
import {
  IsArray,
  IsBoolean,
  IsLatitude,
  IsLongitude,
  IsNotEmptyObject,
  IsNumber,
  IsOptional,
  IsString,
} from "class-validator"
import { IsEntityName, IsNonEmptyString, IsPositiveInteger } from "../common"
import { LimitableDTO } from "../limitable.dto"

export class UpdateSiteDTO {
  id!: number

  @IsEntityName()
  name!: string

  @IsNumber()
  region!: number

  @IsLongitude()
  longitude!: number

  @IsLatitude()
  latitude!: number

  @IsBoolean()
  viewerTrafficActive!: boolean

  @IsBoolean()
  edgeEgressActive!: boolean

  @IsBoolean()
  edgeOriginActive!: boolean

  @IsBoolean()
  mediaIngressActive!: boolean

  @IsNonEmptyString()
  code!: string

  @IsOptional()
  @IsString()
  providers?: string

  @IsOptional()
  @IsString()
  street_address?: string

  @IsOptional()
  @IsString()
  city?: string

  @IsOptional()
  @IsString()
  zipcode?: string

  @IsOptional()
  @IsString()
  country?: string

  @IsString()
  notes!: string

  @IsOptional()
  @IsPositiveInteger()
  max_viewers?: number

  @IsOptional()
  @IsPositiveInteger()
  max_capacity?: number

  @Expose()
  @IsNotEmptyObject()
  asnLimitation!: LimitableDTO

  @Expose()
  @IsNotEmptyObject()
  countryLimitation!: LimitableDTO

  @Expose()
  @IsNotEmptyObject()
  organizationLimitation!: LimitableDTO

  @Expose()
  @IsNotEmptyObject()
  channelLimitation!: LimitableDTO

  @Expose()
  @IsBoolean()
  transcodingActive!: boolean

  @Expose()
  @IsArray()
  transcodingPriority!: string[]
}
