import { Expose, Transform } from "class-transformer"
import { BaseDTO } from "../base.dto"
import { LimitableDTO } from "../limitable.dto"
import { RegionDTO } from "../region/region.dto"
import { transformDate } from "../transforms"

export class SiteDTO extends BaseDTO<SiteDTO> {
  public static default = () => {
    return new SiteDTO({
      id: 1,
      name: "site1",
      code: "site1",
      viewerTrafficActive: true,
      mediaIngressActive: true,
      edgeEgressActive: true,
      edgeOriginActive: true,
      latitude: 0,
      longitude: 0,
      region: RegionDTO.default(),
      asnLimitation: new LimitableDTO(),
      countryLimitation: new LimitableDTO(),
      channelLimitation: new LimitableDTO(),
      organizationLimitation: new LimitableDTO(),
      createdAt: new Date(0),
      notes: "",
      transcodingPriority: [],
      transcodingActive: true,
    })
  }

  @Expose()
  @Transform(transformDate)
  createdAt!: Date

  @Expose()
  @Transform(transformDate)
  updatedAt?: Date

  @Expose()
  id!: number

  @Expose()
  name!: string

  @Expose()
  region!: RegionDTO

  @Expose()
  longitude!: number

  @Expose()
  latitude!: number

  @Expose()
  viewerTrafficActive!: boolean

  @Expose()
  edgeEgressActive!: boolean

  @Expose()
  edgeOriginActive!: boolean

  @Expose()
  mediaIngressActive!: boolean

  @Expose()
  code!: string

  @Expose()
  street_address?: string

  @Expose()
  city?: string

  @Expose()
  zipcode?: string

  @Expose()
  country?: string

  @Expose()
  max_viewers?: number

  @Expose()
  notes!: string

  @Expose()
  providers?: string

  /**
   * Site maximum capacity
   * measured in Mbps (megabits per second)
   */
  @Expose()
  max_capacity?: number

  @Expose()
  asnLimitation!: LimitableDTO

  @Expose()
  countryLimitation!: LimitableDTO

  @Expose()
  organizationLimitation!: LimitableDTO

  @Expose()
  channelLimitation!: LimitableDTO

  @Expose()
  transcodingActive!: boolean

  @Expose()
  transcodingPriority!: string[]
}
