import { IconChevronDown } from "@tabler/icons-react"
import {
  TableOptions,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { Button, Combobox, ComboboxContent, ComboboxPortal, ComboboxTrigger } from "@vindral/components"
import { ReactElement, ReactNode, createContext, useContext, useMemo, useState } from "react"
import { DebouncedTextField } from "../../molecules/DebouncedTextField"
import { InteractiveTable } from "../InteractiveTable"

export interface MultiSelectorProps {
  children: ReactNode
}

export interface MultiSelectorContextProps {
  filtered: string
  onFilteredChange: (search: string) => void
}

const MultiSelectorContext = createContext<MultiSelectorContextProps>({
  filtered: "",
  onFilteredChange: () => {
    // noop
  },
})

function MultiSelector({ children }: MultiSelectorProps) {
  const [filtered, onFilteredChange] = useState("")
  return <MultiSelectorContext.Provider value={{ filtered, onFilteredChange }} children={children} />
}

function MultiSelectorFilterTextField() {
  const { filtered, onFilteredChange } = useContext(MultiSelectorContext)

  return (
    <DebouncedTextField
      initialValue={filtered}
      onValueChange={onFilteredChange}
      debounceMs={100}
      placeholder="Filter..."
    />
  )
}

export interface MultiSelectorComboboxProps<IdField extends string, T extends Record<IdField, string>> {
  idField: IdField
  selected: Set<string>
  options: { id: string; title?: string; items: ReadonlyArray<T> }[]
  isLoading?: boolean
  onSelect: (item: T) => void
  onReachedEnd?: () => void
  renderItem: (item: T) => ReactElement
  buttonSize?: "small" | "medium" | "large"
  search: string
  onSearchChange: (search: string) => void
  children: ReactNode
}

function MultiSelectorCombobox<IdField extends string, T extends Record<IdField, string>>(
  props: MultiSelectorComboboxProps<IdField, T>
) {
  const { idField, options, selected, search, onSearchChange } = props
  const groups = useMemo(() => {
    return options.map((option) => ({
      ...option,
      items: option.items.filter((item) => !selected.has(item[idField])),
    }))
  }, [options, selected, idField])
  const [open, setOpen] = useState(false)

  return (
    <Combobox open={open} onOpenChange={setOpen}>
      <ComboboxTrigger asChild>
        <Button size={props.buttonSize || "medium"}>
          <div className="flex w-full justify-between gap-1">
            {props.children}
            <IconChevronDown className="h-4 w-4" />
          </div>
        </Button>
      </ComboboxTrigger>
      <ComboboxPortal>
        <ComboboxContent
          emptyText="No items to add"
          popoverContentProps={{ align: "start", sideOffset: 8 }}
          onReachedEnd={props.onReachedEnd}
          isLoading={props.isLoading}
          groups={groups}
          renderItem={props.renderItem}
          selected={null}
          multiple={false}
          query={search}
          onSelect={props.onSelect}
          getId={(item) => item[idField]}
          onQueryChange={onSearchChange}
          renderTitle={(title) => (
            <div className="p-2 text-sm font-medium uppercase tracking-wider text-fg-subtle">{title}</div>
          )}
        />
      </ComboboxPortal>
    </Combobox>
  )
}

export interface MultiSelectorTableProps<T> extends Pick<TableOptions<T>, "data" | "getRowId" | "columns"> {
  pageSize?: number
}

function MultiSelectorTable<T>(props: MultiSelectorTableProps<T>) {
  const { filtered, onFilteredChange } = useContext(MultiSelectorContext)
  const { pageSize = 10, ...rest } = props
  const table = useReactTable({
    state: { globalFilter: filtered },
    onGlobalFilterChange: onFilteredChange,
    initialState: { pagination: { pageSize } },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    ...rest,
  })

  return <InteractiveTable table={table} includePaginationFooter />
}

export { MultiSelector, MultiSelectorCombobox, MultiSelectorFilterTextField, MultiSelectorTable }
