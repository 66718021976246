import { IsArray } from "class-validator"
import { IsEntityName } from "../common"

export class UpdateChannelGroupDTO {
  id!: number

  @IsEntityName()
  name!: string

  @IsArray()
  channels!: number[]
}
