import { Expose } from "class-transformer"
import { IsOptional } from "class-validator"

export class WebrtcInfoDTO {
  @Expose()
  streamKey!: string

  @IsOptional()
  @Expose()
  authSecret?: string

  @IsOptional()
  @Expose()
  targetType?: string

  @IsOptional()
  @Expose()
  targetUrl?: string
}

export class WebrtcIngestConnectResponse {
  @Expose()
  hosts!: string[]
}
