import { IconAlertTriangle, IconPlayerPlay, IconWorldOff } from "@tabler/icons-react"
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@vindral/components"
import { cva } from "class-variance-authority"
import { RouterOutputs } from "../trpc"
import { ChannelThumbnail } from "./ChannelThumbnail"

export type Channel = RouterOutputs["channel"]["findById"]

export interface ChannelPreviewProps {
  channel: Channel
  hoverable?: boolean
}

const channelPreview = cva(
  "group/preview flex aspect-video flex-col items-center justify-center rounded-sm text-xs font-medium uppercase",
  {
    variants: {
      state: {
        suspended: ["bg-amber-3", "border-amber-6", "text-amber-11"],
        offline: ["border-divider-interactive", "bg-component", "text-fg-subtle"],
        live: ["border-divider-positive", "text-fg-positive", "bg-canvas-positive"],
        liveWithThumbnail: [
          "transition",
          "absolute",
          "inset-0",
          "hover:bg-black/50",
          "dark:text-fg-positive",
          "text-component-positive",
        ],
      },
    },
  }
)

export function ChannelPreview({ channel, hoverable = true }: ChannelPreviewProps) {
  if (channel.suspended) {
    return (
      <div className={channelPreview({ state: "suspended" })}>
        <IconAlertTriangle size={16} stroke={1.5} />
        Suspended
      </div>
    )
  }

  if (!channel.isLive) {
    return (
      <div className={channelPreview({ state: "offline" })}>
        <IconWorldOff size={16} stroke={1.5} />
        Offline
      </div>
    )
  }

  if (!channel.thumbnail.baseUrl || channel.thumbnail.sizes.length === 0) {
    return (
      <div className={channelPreview({ state: "live" })}>
        <IconPlayerPlay size={16} stroke={1.5} />
        Live
      </div>
    )
  }

  return (
    <div className="aspect-video overflow-hidden rounded">
      <HoverCard closeDelay={0} openDelay={0} open={hoverable ? undefined : false}>
        <HoverCardTrigger asChild>
          <button className="relative h-full w-full justify-center">
            <ChannelThumbnail
              channelId={channel.channelId}
              baseUrl={channel.thumbnail.baseUrl}
              sizes={channel.thumbnail.sizes}
              authToken={channel.authToken}
            />
            <div className={channelPreview({ state: "liveWithThumbnail" })}>
              <IconPlayerPlay
                size={16}
                stroke={1.5}
                className="opacity-0 transition-opacity group-hover/preview:opacity-100"
              />
            </div>
          </button>
        </HoverCardTrigger>
        <HoverCardContent asChild align="start" side="right" sideOffset={8}>
          <div className="pointer-events-none w-60">
            <ChannelThumbnail
              channelId={channel.channelId}
              baseUrl={channel.thumbnail.baseUrl}
              sizes={channel.thumbnail.sizes}
              authToken={channel.authToken}
            />
          </div>
        </HoverCardContent>
      </HoverCard>
    </div>
  )
}
