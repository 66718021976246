import { Route, useNavigate, useSearch } from "@tanstack/react-router"
import { PaginationState, SortingState, VisibilityState } from "@tanstack/react-table"
import { useToast } from "@vindral/components"
import * as dateFns from "date-fns"
import { useEffect, useState } from "react"
import { useAsyncFn, useLocalStorage } from "react-use"
import { z } from "zod"
import { usageIndexRoute } from "."
import { UsageList } from "../../templates/usage/UsageList"
import { trpc } from "../../trpc"
import { formatGigabyte } from "../../utils/format"

const defaultColumns = {
  channelName: true,
  channelId: true,
  bytesEgress: true,
  bytesThumbnail: true,
  bytesTotal: true,
}

export interface SelectPeriodMenuItem {
  label: string
  value: string
}

const now = new Date()
const menuItems: SelectPeriodMenuItem[] = dateFns
  .eachMonthOfInterval({
    start: dateFns.parse("2022-05", "yyyy-MM", now),
    end: now,
  })
  .map((d) => ({
    value: dateFns.format(d, "yyyy-MM"),
    label: dateFns.format(d, "MMMM yyyy"),
  }))
  .reverse()

const params = z.object({
  organizationId: z.string(),
  usageListView: z
    .object({
      sorting: z.array(
        z.object({
          id: z.enum(["channelName", "channelId", "bytesEgress", "bytesThumbnail", "bytesTotal"]),
          desc: z.boolean(),
        })
      ),
      pagination: z.object({
        pageIndex: z.number().nonnegative(),
        pageSize: z.number().nonnegative(),
      }),
      search: z.string().default(""),
      selectedPeriod: z.string(),
    })
    .catch({
      sorting: [{ desc: false, id: "channelName" }],
      pagination: { pageIndex: 0, pageSize: 100 },
      search: "",
      selectedPeriod: menuItems[0]?.value ? menuItems[0].value : "",
    }),
})

const getYear = (selectedPeriod: string) => dateFns.parseISO(selectedPeriod).getFullYear()
const getMonth = (selectedPeriod: string) => dateFns.parseISO(selectedPeriod).getMonth() + 1
const getCsvDownloadLink = (organizationId: string | undefined, selectedPeriod: string) =>
  organizationId
    ? `/api/usage/organization/${organizationId}/csv?year=${getYear(selectedPeriod)}&month=${getMonth(selectedPeriod)}`
    : ""

type SearchParams = ReturnType<typeof useSearch<typeof usageListRoute["id"]>>

function toTrpcParams(params: SearchParams) {
  const {
    organizationId,
    usageListView: { selectedPeriod },
  } = params

  return {
    organizationPublicId: organizationId,
    year: getYear(selectedPeriod),
    month: getMonth(selectedPeriod),
  } as const
}

export interface UsageRowItem {
  id: number
  channelId: string
  channelName: string
  bytesEgress: number
  bytesThumbnail: number
  bytesTotal: number
}

export const usageListRoute = new Route({
  path: "/",
  getParentRoute: () => usageIndexRoute,
  validateSearch: params,
  onLoad: async ({ search, context: { trpcContext } }) => {
    const params = toTrpcParams(search)
    await trpcContext.usage.usageByOrganization.ensureData(params, { staleTime: 1000 })
  },
  preSearchFilters: [(search) => search],
  component: List,
})
function List() {
  const searchParams = useSearch({ from: usageListRoute.id })
  const navigate = useNavigate({ from: usageListRoute.id })
  const { toast } = useToast()
  const trpcParams = toTrpcParams(searchParams)
  const { data } = trpc.usage.usageByOrganization.useQuery(trpcParams, { staleTime: 1000 })
  const { data: organization } = trpc.organization.findOne.useQuery({ publicId: searchParams.organizationId })

  const [sorting, setSorting] = useState<SortingState>(searchParams.usageListView.sorting)
  const [pagination, setPagination] = useState<PaginationState>(searchParams.usageListView.pagination)
  const [selectedPeriod, setSelectedPeriod] = useState<string>(searchParams.usageListView.selectedPeriod)
  const [globalFilter, setGlobalFilter] = useState(searchParams.usageListView.search)
  const [value, setValue, remove] = useLocalStorage<VisibilityState>("usage-list-columns", defaultColumns)
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(value ?? defaultColumns)
  const pageCount = Math.ceil((data?.rows.length ?? 0) / pagination.pageSize)
  const [navigationState, doNavigate] = useAsyncFn(
    async (...args: Parameters<typeof navigate>) => {
      return navigate(...args)
    },
    [navigate]
  )

  if (globalFilter !== searchParams.usageListView.search && pagination.pageIndex !== 0) {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }))
  }

  const downloadCsvUrl = getCsvDownloadLink(searchParams.organizationId, searchParams.usageListView.selectedPeriod)
  const totalUsage = data?.bytesTotal ? formatGigabyte(data.bytesTotal) : "0"
  const totalEgress = data?.bytesEgress ? formatGigabyte(data.bytesEgress) : "0"
  const totalThumbnail = data?.bytesThumbnail ? formatGigabyte(data.bytesThumbnail) : "0"
  const tableData: UsageRowItem[] | undefined = data?.rows.map((d) => ({
    id: d.id,
    channelId: d.channelId,
    channelName: d.channelName,
    bytesEgress: d.bytesEgress,
    bytesThumbnail: d.bytesThumbnail,
    bytesTotal: d.bytesTotal,
  }))

  useEffect(() => {
    void doNavigate({
      from: usageListRoute.id,
      to: "/usage",
      params: {},
      search: (prev) => {
        return { ...prev, usageListView: { sorting, pagination, search: globalFilter, selectedPeriod } }
      },
    })
  }, [doNavigate, sorting, pagination, globalFilter, selectedPeriod])

  const filteredTableData = tableData?.filter(
    (d) =>
      d.channelName.toLocaleLowerCase().includes(globalFilter.toLocaleLowerCase()) ||
      d.channelId.toLocaleLowerCase().includes(globalFilter.toLocaleLowerCase())
  )

  return (
    <UsageList
      organizationName={organization?.uniqueName ?? ""}
      selectPeriodMenuItems={menuItems}
      setSelectedPeriod={setSelectedPeriod}
      selectedPeriod={selectedPeriod}
      downloadCsvUrl={downloadCsvUrl}
      totalUsage={totalUsage}
      totalEgress={totalEgress}
      totalThumbnail={totalThumbnail}
      data={filteredTableData ?? []}
      manualFiltering
      onPaginationChange={setPagination}
      autoResetPageIndex={false}
      pageCount={pageCount}
      isStaleData={navigationState.loading || globalFilter !== searchParams.usageListView.search}
      state={{ pagination, sorting, globalFilter, columnVisibility }}
      onSortingChange={setSorting}
      onGlobalFilterChange={setGlobalFilter}
      onColumnVisibilityChange={setColumnVisibility}
      saveColumnVisibility={() => {
        setValue(columnVisibility)
        toast({ title: "Saved configuration" })
      }}
      resetColumnVisibilityToDefault={() => {
        remove()
        setColumnVisibility(defaultColumns)
        toast({ title: "Restored to default" })
      }}
    />
  )
}
