import { IconDownload } from "@tabler/icons-react"
import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@vindral/components"
import { Dispatch, SetStateAction } from "react"
import { Page, PageContainer, PageContent, PageHeader } from "../../atoms/Page"
import { Breadcrumbs } from "../../molecules/Breadcrumbs"
import { Titled } from "../../molecules/Titled"
import { UsageTable, UsageTableProps } from "../../organisms/UsageTable"
import { SelectPeriodMenuItem } from "../../routes/usage/usage-list"

export type UsageListProps = UsageTableProps &
  UsageListInnerProps & {
    isStaleData: boolean
    downloadCsvUrl: string
    organizationName: string
    totalUsage: string
    totalEgress: string
    totalThumbnail: string
    selectPeriodMenuItems: SelectPeriodMenuItem[]
    selectedPeriod: string
    setSelectedPeriod: Dispatch<SetStateAction<string>>
  }

export function UsageList({
  downloadCsvUrl,
  organizationName,
  totalEgress,
  totalThumbnail,
  totalUsage,
  selectPeriodMenuItems,
  selectedPeriod,
  setSelectedPeriod,
  ...props
}: UsageListProps) {
  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
          </div>
        </PageHeader>

        <PageContainer>
          <div className="flex items-center justify-between py-4">
            <div>
              <Select
                onValueChange={(value) => setSelectedPeriod(value)}
                value={selectedPeriod}
                defaultValue={selectedPeriod}
              >
                <SelectTrigger>
                  <SelectValue className="font-medium" />
                </SelectTrigger>
                <SelectContent>
                  {selectPeriodMenuItems.map(({ value, label }) => (
                    <SelectItem key={value} value={value}>
                      {label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <Titled title="Total Egress">{totalEgress}</Titled>
            <Titled title="Total Thumbnail">{totalThumbnail}</Titled>
            <Titled title="Total Usage">{totalUsage}</Titled>

            <a href={downloadCsvUrl} download={`${organizationName}-usage-${selectedPeriod}.csv`}>
              <Button variant="primary">
                <IconDownload size={16} />
                <span className="font-normal">Download CSV</span>
              </Button>
            </a>
          </div>
          <div className="py-4">
            <UsageListInner {...props} />
          </div>
        </PageContainer>
      </PageContent>
    </Page>
  )
}

export type UsageListInnerProps = UsageTableProps & {
  isStaleData: boolean
}

function UsageListInner({ isStaleData, ...props }: UsageListInnerProps) {
  if (props.data.length === 0 && !props.state?.globalFilter && !isStaleData) {
    return (
      <div className="relative flex justify-center pt-40">
        <div>
          <h1 className="font-medium">There is no usage data in selected period</h1>
        </div>
      </div>
    )
  }

  return <UsageTable {...props} />
}
