import { Expose } from "class-transformer"
import { BaseDTO } from "../base.dto"

export class ChannelUsageDTO extends BaseDTO<ChannelUsageDTO> {
  @Expose()
  id!: number

  @Expose()
  bytes!: number

  @Expose()
  datacenter?: string
}
