import { cva, VariantProps } from "class-variance-authority"
import { ReactNode } from "react"

const defaultVariants = {
  color: "blue",
  variant: "regular",
} as const

const chip = cva(
  [
    "inline-flex",
    "items-center",
    "gap-1",
    "rounded-full",
    "py-0.5",
    "px-2",
    "text-xs",
    "font-medium",
    "tracking-wider",
  ],
  {
    variants: {
      variant: {
        regular: ["border"],
        small: ["font-semibold", "uppercase", "tracking-wider", "border-0", "pt-px"],
      },
      color: {
        neutral: ["bg-gray-4", "text-gray-12", "border-gray-6"],
        green: ["bg-jade-4", "text-jade-12", "border-jade-6"],
        red: ["bg-tomato-4", "text-tomato-12", "border-tomato-6"],
        blue: ["bg-blue-4", "text-blue-12", "border-blue-6"],
        orange: ["bg-amber-4", "text-amber-12", "border-amber-6"],
        teal: ["bg-teal-4", "text-teal-12", "border-teal-6"],
        purple: ["bg-purple-4", "text-purple-12", "border-purple-6"],
        pink: ["bg-pink-4", "text-pink-12", "border-pink-6"],
      },
      clickable: { true: ["cursor-pointer"] },
    },
    defaultVariants,
  }
)

export type ChipProps = VariantProps<typeof chip> & {
  children?: ReactNode
  onClick?: () => void
}

export function Chip(props: ChipProps) {
  return (
    <div className={chip({ ...props, clickable: !!props.onClick })} onClick={props.onClick}>
      {props.children}
    </div>
  )
}

Chip.displayName = "Chip"
