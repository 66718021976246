import { z } from "zod"

export const clientOverrides = z.object({
  maxVideoBitRate: z.number().positive().nullish(),
  minBufferTime: z.number().positive().nullish(),
  maxBufferTime: z.number().positive().nullish(),
  burstEnabled: z.boolean().nullish(),
  sizeBasedResolutionCapEnabled: z.boolean().nullish(),
  separateVideoSocketEnabled: z.boolean().nullish(),
})

export type ClientOverrides = z.infer<typeof clientOverrides>
