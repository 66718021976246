import { MediaMetadata } from "@core-services/data-types"
import { z } from "zod"
import { Order } from "../order"

export const IngestSessionFindManyParameters = z
  .object({
    orderBy: z.enum(["startedAt", "stoppedAt", "sourceIp", "hostname"]),
    channelId: z.string(),
  })
  .merge(Order)

export const IngestSessionItem = z.object({
  startedAt: z.date(),
  stoppedAt: z.date().nullish(),
  sourceIp: z.string(),
  sessionId: z.string(),
  hostname: z.string().optional(),
  metadata: z.array(MediaMetadata),
  protocol: z.string(),
})
