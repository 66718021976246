import { Light } from "@vindral/components"

export function LiveStatus({ isLive, suspended }: { isLive: boolean; suspended: boolean }) {
  if (suspended) {
    return (
      <div className="flex items-center">
        <Light color="orange" />
        <span className="pl-2">Suspended</span>
      </div>
    )
  }
  if (isLive) {
    return (
      <div className="flex items-center">
        <Light color="green" />
        <span className="pl-2">Live</span>
      </div>
    )
  }

  return (
    <div className="flex items-center">
      <Light color="neutral" />
      <span className="pl-2">Not Live</span>
    </div>
  )
}
