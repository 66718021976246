import { OrganizationType } from "@core-services/data-types"
import { Expose, Transform, Type } from "class-transformer"
import { ValidateNested } from "class-validator"
import { ClientOverridesDTO } from "../client-overrides.dto"
import { ClientTelemetryDTO } from "../client-telemetry.dto"
import { DTO_GROUP_CONTROL_PANEL } from "../common"
import { LimitableDTO } from "../limitable.dto"
import { transformDate } from "../transforms"

export class OrganizationDTO {
  static readonly modelName = "Organization"

  @Expose()
  @Transform(transformDate)
  createdAt!: Date

  @Expose()
  @Transform(transformDate)
  updatedAt?: Date

  @Expose()
  id!: number

  @Expose()
  publicId!: string

  @Expose()
  name!: string

  @Expose()
  type!: OrganizationType

  @Expose({ groups: [DTO_GROUP_CONTROL_PANEL] })
  @Type(() => ClientOverridesDTO)
  @ValidateNested()
  clientOverrides?: ClientOverridesDTO

  @Expose({ groups: [DTO_GROUP_CONTROL_PANEL] })
  @Type(() => ClientTelemetryDTO)
  @ValidateNested()
  clientTelemetry?: ClientTelemetryDTO

  @Expose()
  max_streams!: number | null

  @Expose()
  max_viewers!: number | null

  @Expose()
  max_capacity!: number | null

  @Expose()
  max_partner_streams!: number | null

  @Expose()
  max_partner_viewers!: number | null

  @Expose()
  max_partner_capacity!: number | null

  @Expose()
  max_channels!: number

  @Expose()
  max_channel_groups!: number

  @Expose()
  max_transcoding_profiles!: number

  @Expose()
  max_users!: number

  @Expose()
  authSecret!: string

  @Expose()
  siteLimitation!: LimitableDTO

  @Expose()
  apiAccessEnabled!: boolean

  @Expose()
  suspended!: boolean

  @Expose()
  uniqueName!: string
}
