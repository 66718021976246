import { IconPlayerPlay, IconPlayerStop } from "@tabler/icons-react"
import { Button, ConfirmDialogContent, Dialog, DialogTrigger, Spinner, toast } from "@vindral/components"
import { trpc } from "../../../trpc"

export const StartStopManualChannelButton = (args: { channelId: string; channelName: string }) => {
  const { channelId, channelName } = args
  const context = trpc.useContext()

  const { data: ingestActive, isLoading: jobLoading } = trpc.channel.isPackagerJobActive.useQuery({ channelId })

  const start = trpc.channel.startJob.useMutation({
    onSuccess: () => {
      context.channel.isPackagerJobActive.setData({ channelId }, true)
      toast({ title: "Transcoding job started" })
    },
    onError: (err) => {
      toast({ title: `Unable to stop transcoding job: ${err.message}` })
    },
  })

  const stop = trpc.channel.stopJob.useMutation({
    onSuccess: () => {
      context.channel.isPackagerJobActive.setData({ channelId }, false)
      toast({ title: "Transcoding job stopped" })
    },
    onError: (err) => {
      toast({ title: `Unable to start transcoding job: ${err.message}` })
    },
  })

  const isLoading = start.isLoading || stop.isLoading || jobLoading

  if (isLoading) {
    return <LoadingButton ingestActive={!!ingestActive} />
  }

  if (ingestActive) {
    return <StopButton channelName={channelName} action={() => stop.mutateAsync({ channelId })} />
  }

  return <StartButton action={() => start.mutateAsync({ channelId })} />
}

const StartButton = ({ action }: { action: () => Promise<void> }) => (
  <Button onClick={action}>
    <FixedSizeDiv>
      <IconPlayerPlay size={12} stroke={2} />
    </FixedSizeDiv>
    Start
  </Button>
)

const StopButton = ({ action, channelName }: { action: () => Promise<void>; channelName: string }) => (
  <Dialog>
    <DialogTrigger asChild>
      <Button>
        <FixedSizeDiv>
          <IconPlayerStop size={12} stroke={2} />
        </FixedSizeDiv>
        Stop
      </Button>
    </DialogTrigger>
    <ConfirmDialogContent
      onConfirm={action}
      title={`Confirm stop of transcoding job for ${channelName}`}
      description={
        <>
          Please confirm that you want to stop the transcoding job for
          <span className="font-medium"> {channelName}</span>.
        </>
      }
    />
  </Dialog>
)

const LoadingButton = ({ ingestActive }: { ingestActive: boolean }) => (
  <Button>
    <FixedSizeDiv>
      <Spinner size="small" />
    </FixedSizeDiv>
    {ingestActive ? "Stop" : "Start"}
  </Button>
)

const FixedSizeDiv = ({ children }: { children: React.ReactNode }) => (
  <div className="flex h-4 w-4 items-center justify-center">{children}</div>
)
