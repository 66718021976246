import { ValidationArguments, ValidatorConstraint, ValidatorConstraintInterface } from "class-validator"

@ValidatorConstraint({ name: "whitespace", async: false })
export class WhitespaceConstraint implements ValidatorConstraintInterface {
  validate(text: string, _args: ValidationArguments): boolean {
    return text === text.trim()
  }

  defaultMessage(args: ValidationArguments): string {
    return `${args.property} must not contain leading or trailing spaces`
  }
}
