import { subject } from "@casl/ability"
import { Action, ApiKeyScope } from "@core-services/data-types"
import { AppAbility, CreateApiKey } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { Cross1Icon, PlusIcon } from "@radix-ui/react-icons"
import {
  Button,
  Chip,
  ConfirmDialogContent,
  Copyable,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
  FormControl,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  TextField,
  ToggleGroup,
  ToggleGroupItem,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from "@vindral/components"
import { ReactNode, useState } from "react"
import { useController, useForm } from "react-hook-form"
import { FormSection } from "../../atoms/FormSection"
import { FormattedDate } from "../../molecules/FormattedDate"
import { Titled } from "../../molecules/Titled"
import { ApiKeys, UserProfile } from "./Profile"

interface EditApiKeysProps {
  profile: UserProfile
  ability: AppAbility
  apiKeys: ApiKeys
  createApiKey: (value: CreateApiKey) => Promise<string>
  deleteApiKey: (publicId: string) => Promise<void>
}

const transformScope = (scope: ApiKeyScope) => {
  if (scope === ApiKeyScope.ReadWrite) {
    return "Read-write"
  }
  return "Read-only"
}

const resolver = zodResolver(CreateApiKey)

interface CreateApiKeyProps {
  onCreate: (apiKey: CreateApiKey) => Promise<void>
}

const CreateApiKeyDialog = (props: EditApiKeysProps & CreateApiKeyProps) => {
  const { profile, ability, onCreate } = props
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<CreateApiKey>({
    resolver,
    mode: "onChange",
    defaultValues: {
      name: "",
      notes: "",
      scope: ApiKeyScope.ReadWrite,
    },
  })
  const scopeController = useController({ control, name: "scope" })

  return (
    <form onSubmit={handleSubmit(onCreate)}>
      <div className="flex justify-between border-b border-divider p-4">
        <h1 className="font-medium">Create API key</h1>
        <DialogClose>
          <Cross1Icon />
        </DialogClose>
      </div>
      <div className="p-4">
        <div className="flex flex-col gap-4">
          <FormControl>
            <FormControlLabel required error={errors.name?.message}>
              Name
            </FormControlLabel>
            <TextField {...register("name")} error={!!errors?.name} />
          </FormControl>
          <FormControl>
            <FormControlLabel error={errors.notes?.message}>Notes</FormControlLabel>
            <TextField {...register("notes")} error={!!errors.notes} minRows={2} multiline />
          </FormControl>
          <FormControl>
            <FormControlLabel required>Scope</FormControlLabel>
            <div>
              <ToggleGroup
                type="single"
                onValueChange={scopeController.field.onChange}
                value={scopeController.field.value}
              >
                <ToggleGroupItem value={ApiKeyScope.ReadWrite}>Read-write</ToggleGroupItem>
                <ToggleGroupItem value={ApiKeyScope.Read}>Read-only</ToggleGroupItem>
              </ToggleGroup>
            </div>
          </FormControl>
        </div>
      </div>
      {ability.can(Action.Create, subject("ApiKey", { user: { publicId: profile.publicId } })) ? (
        <div className="border-t border-divider p-4 text-right">
          <Button disabled={!isDirty || !isValid} type="submit" variant="primary">
            Create
          </Button>
        </div>
      ) : null}
    </form>
  )
}

interface CreatedApiKeyProps {
  apiKey: string
}

const CreatedApiKeyDialog = (props: CreatedApiKeyProps) => {
  const { apiKey } = props

  return (
    <div className="flex flex-col">
      <div className="flex justify-between border-b border-divider p-4">
        <h1 className="font-medium">Your API key has been created</h1>
        <DialogClose>
          <Cross1Icon />
        </DialogClose>
      </div>
      <div className="p-4">
        <div className="flex max-w-lg flex-col gap-4">
          <div className="flex items-baseline gap-2">
            <Titled title="Api key">
              <Copyable text={apiKey}>
                <span className="font-medium" data-pw="apiKeyValue">
                  {apiKey}
                </span>
              </Copyable>
            </Titled>
          </div>
          <p className="text-fg-subtle">Make sure to copy the API key now. You won't be able to see it again!</p>
        </div>
      </div>
      <div className="border-t border-divider p-4 text-right">
        <DialogClose asChild>
          <Button variant="primary">Done, I have copied the API key</Button>
        </DialogClose>
      </div>
    </div>
  )
}

const TableCellTooltip = (props: { text: string; children: ReactNode }) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <div>{props.children}</div>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent>{props.text}</TooltipContent>
      </TooltipPortal>
    </Tooltip>
  )
}

const ApiKeyTable = (props: EditApiKeysProps) => {
  const { profile, apiKeys, deleteApiKey, ability } = props
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadCell>Name</TableHeadCell>
          <TableHeadCell>Notes</TableHeadCell>
          <TableHeadCell>Key</TableHeadCell>
          <TableHeadCell>Scope</TableHeadCell>
          <TableHeadCell>Created</TableHeadCell>
          <TableHeadCell></TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {apiKeys.map((apiKey) => (
          <TableRow key={apiKey.publicId}>
            <TableCell>
              <TableCellTooltip text={apiKey.name}>
                <div className="max-w-xs truncate">{apiKey.name}</div>
              </TableCellTooltip>
            </TableCell>
            <TableCell>
              <TableCellTooltip text={apiKey.notes}>
                <div className="max-w-xs truncate">{apiKey.notes}</div>
              </TableCellTooltip>
            </TableCell>
            <TableCell collapse>{apiKey.maskedKey}</TableCell>
            <TableCell collapse>
              <Chip color={apiKey.scope === ApiKeyScope.ReadWrite ? "blue" : "green"}>
                {transformScope(apiKey.scope)}
              </Chip>
            </TableCell>
            <TableCell collapse>{<FormattedDate date={new Date(apiKey.createdAt)} />}</TableCell>
            <TableCell collapse>
              {ability.can(Action.Delete, subject("ApiKey", { user: { publicId: profile.publicId } })) && (
                <Dialog>
                  <DialogTrigger asChild>
                    <Button variant="danger" size="small">
                      Delete
                    </Button>
                  </DialogTrigger>
                  <ConfirmDialogContent
                    onConfirm={() => deleteApiKey(apiKey.publicId)}
                    title={`Confirm deletion of ${apiKey.name}`}
                    description={
                      <>
                        Please confirm that you are absolutely sure that you want to delete
                        <span className="font-medium"> {apiKey.name}</span>.
                      </>
                    }
                  />
                </Dialog>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export const EditApiKeys = (props: EditApiKeysProps) => {
  const { ability, profile, apiKeys, createApiKey, deleteApiKey } = props
  const [newApiKey, setNewApiKey] = useState<string | null>(null)

  const onCreateApiKey = async (value: CreateApiKey) => {
    try {
      const apiKey = await createApiKey(value)
      setNewApiKey(apiKey)
    } catch (error) {
      console.error(error)
    }
  }

  const onOpenChange = (open: boolean) => {
    if (open) {
      setNewApiKey(null)
    }
  }

  return (
    <div>
      <div className="py-4">
        <FormSection
          title="API Keys"
          description="API keys can be used to authenticate through the API.
          Note that your API keys inherit the same permissions as your user.
          It can be used to access the same Organizations that you have access to.
          You can make the API key read-only in order to limit the key.
          API keys can't be edited after creation."
        >
          <div className="flex flex-col">
            <div className="pb-4">
              {ability.can(Action.Create, subject("ApiKey", { user: { publicId: profile.publicId } })) && (
                <Dialog onOpenChange={onOpenChange}>
                  <DialogTrigger asChild>
                    <Button>
                      <PlusIcon />
                      New
                    </Button>
                  </DialogTrigger>
                  <DialogContent size="medium">
                    {!newApiKey ? (
                      <CreateApiKeyDialog {...props} onCreate={onCreateApiKey} />
                    ) : (
                      <CreatedApiKeyDialog apiKey={newApiKey} />
                    )}
                  </DialogContent>
                </Dialog>
              )}
            </div>

            <ApiKeyTable {...props} apiKeys={apiKeys} deleteApiKey={deleteApiKey} />
          </div>
        </FormSection>
      </div>
    </div>
  )
}
