import { CreateSite } from "@core-services/portal"
import { Route, useNavigate } from "@tanstack/react-router"
import { toast } from "@vindral/components"
import { siteIndexRoute } from "."
import { SiteCreate } from "../../templates/infrastructure/site/SiteCreate"
import { trpc } from "../../trpc"

export const siteCreateRoute = new Route({
  getParentRoute: () => siteIndexRoute,
  preSearchFilters: [(search) => search],
  path: "create",
  component: Page,
  getContext: () => {
    return {
      pageTitle: "Create",
    }
  },
})

function Page() {
  const navigate = useNavigate()
  const context = trpc.useContext()
  const createMutation = trpc.site.create.useMutation({
    onSuccess: (site) => {
      void context.site.findMany.invalidate()
      toast({ title: "Site created" })
      void navigate({
        to: "/sites/edit/$code/general",
        params: { code: site.code },
        replace: true,
      })
    },
    onError: (error) => {
      toast({ title: "Failed to create site!", description: error.message })
    },
  })
  const createSite = async (site: CreateSite) => {
    createMutation.reset()
    await createMutation.mutateAsync(site)
  }
  return <SiteCreate createSite={createSite} />
}
