import { AppAbility, UpdateChannel } from "@core-services/portal"
import { Divider } from "@vindral/components"
import { ChannelFindById } from "../ChannelEdit"
import { EmbedSnippet } from "./playout/PlayoutEmbed"
import { PlayerLinks } from "./playout/PlayoutLinks"
import { PlayerBuilder } from "./playout/PlayoutPlayerBuilder"
import { ThumbnailUrl } from "./playout/PlayoutThumbnail"

export interface ChannelEditPlayoutProps {
  channel: ChannelFindById
  ability: AppAbility
  onSave: (channel: UpdateChannel) => Promise<void>
}

export function ChannelEditPlayout(props: ChannelEditPlayoutProps) {
  return (
    <div className="flex flex-col gap-4">
      <EmbedSnippet channel={props.channel} />
      <Divider />
      <PlayerLinks channel={props.channel} />
      <Divider />
      <ThumbnailUrl channel={props.channel} />
      <Divider />
      <PlayerBuilder {...props} />
    </div>
  )
}
