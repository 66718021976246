import { Button } from "@vindral/components"

export interface SaveButtonProps {
  disabled?: boolean
  text: string
}

export function SaveButton({ disabled = false, text }: SaveButtonProps) {
  return (
    <Button type="submit" data-pw="saveButton" variant="primary" disabled={disabled}>
      {text}
    </Button>
  )
}
