import { useAbility } from "@casl/react"
import { CreateChannel } from "@core-services/portal"
import { Route, useNavigate, useSearch } from "@tanstack/react-router"
import { toast } from "@vindral/components"
import { useCallback } from "react"
import { channelIndexRoute } from "."
import { AbilityContext } from "../../acl"
import { ChannelCreate } from "../../templates/channel/ChannelCreate"
import { trpc } from "../../trpc"

export const channelCreateRoute = new Route({
  getParentRoute: () => channelIndexRoute,
  preSearchFilters: [(search) => search],
  path: "create",
  component: Page,
  getContext: () => {
    return {
      pageTitle: "Create",
    }
  },
})

function Page() {
  const search = useSearch({ from: channelCreateRoute.id })
  const context = trpc.useContext()
  const navigate = useNavigate()
  const ability = useAbility(AbilityContext)
  const createMutation = trpc.channel.create.useMutation({
    onSuccess: (channel) => {
      const channelId = channel.channelId
      context.channel.findById.setData({ channelId }, channel)

      toast({ title: `Created ${channel.name}` })

      void navigate({
        to: "/channels/edit/$channelId/details",
        params: { channelId },
        replace: true,
      })
    },
    onError: (error) => {
      toast({ title: "Failed to create!", description: error.message })
    },
  })

  const onCreate = useCallback(
    async (channel: CreateChannel) => {
      createMutation.reset()
      await createMutation.mutateAsync(channel)
    },
    [createMutation]
  )

  return <ChannelCreate organizationId={search.organizationId} onCreate={onCreate} ability={ability} />
}
