import { z } from "zod"

export const UpdateWebhookConfig = z.object({
  organizationPublicId: z.string(),
  publicId: z.string(),
  enabled: z.boolean(),
  url: z.string().url().max(500),
})

export type UpdateWebhookConfig = z.infer<typeof UpdateWebhookConfig>
