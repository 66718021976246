import { Expose } from "class-transformer"
import { IsArray, IsBoolean, IsString } from "class-validator"
import isNumber from "lodash/isNumber"
import { BaseDTO } from "../base.dto"
import { Rendition } from "../rendition.dto"

export class EdgeViewersDTO extends BaseDTO<EdgeViewersDTO> {
  @Expose()
  hostname!: string
  @Expose()
  viewers!: number
}

export class EdgeTrafficDTO extends BaseDTO<EdgeTrafficDTO> {
  @Expose()
  streamKey!: string

  @Expose()
  isOrigin!: boolean
}

export class EdgeSourceDTO extends BaseDTO<EdgeSourceDTO> {
  @Expose()
  @IsString()
  streamKey!: string

  @Expose()
  @IsBoolean()
  isOrigin!: boolean

  @Expose()
  @IsArray()
  renditions!: Rendition[]
}

export class EdgeDTO extends BaseDTO<EdgeDTO> {
  public static default = (): EdgeDTO => {
    return new EdgeDTO({
      id: "123",
      bitRateIn: 0,
      bitRateOut: 0,
      load: 0,
      nodeHostname: "edge1",
      publicHttpUrl: "http://edge1.com",
      publicWsUrl: "ws://edge1.com",
      site: "site1",
      traffic: [],
      ingressLoad: 0,
      ingressCount: 0,
      adapterSpeedMbps: 0,
    })
  }

  @Expose()
  id!: string

  @Expose()
  publicWsUrl?: string

  @Expose()
  publicHttpUrl?: string

  @Expose()
  site!: string

  @Expose()
  nodeHostname!: string

  @Expose()
  traffic!: EdgeTrafficDTO[]

  @Expose()
  bitRateIn?: number

  @Expose()
  bitRateOut?: number

  /**
   * The egress load of the edge, represented as a percentage of the total capacity
   */
  @Expose()
  load!: number

  /**
   * The ingress load of the edge, represented as a percentage of the total capacity
   */
  @Expose()
  ingressLoad?: number

  /**
   * Active ingresses to the edge
   */
  @Expose()
  ingressCount?: number

  /**
   * Configured adapter speed in Mbps
   */
  @Expose()
  adapterSpeedMbps!: number
}

export const sortEdgesByEgressLoad = (edges: EdgeDTO[] = []) => {
  return edges.sort((e1, e2) => e1.load - e2.load)
}

// TODO: Rewrite when ingressLoad is not optional
export const sortEdgesByIngressLoad = (edges: EdgeDTO[] = []) => {
  return edges.sort((a, b) => {
    const aIngressLoad = a.ingressLoad
    const bIngressLoad = b.ingressLoad
    const aIngressCount = a.ingressCount || 0
    const bIngressCount = b.ingressCount || 0
    const aEgressLoad = a.load
    const bEgressLoad = b.load

    if (!isNumber(aIngressLoad) || !isNumber(bIngressLoad)) {
      return aEgressLoad - bEgressLoad
    }

    if (aIngressLoad === bIngressLoad) {
      if (aIngressCount === bIngressCount) {
        return aEgressLoad - bEgressLoad
      }
      return aIngressCount - bIngressCount
    }

    return aIngressLoad - bIngressLoad
  })
}
