import { AppAbility, OrganizationFindOne } from "@core-services/portal"
import { OrganizationEditPage } from "../../organisms/OrganizationEditPage"

interface OrganizationSettingsProps {
  organization: OrganizationFindOne
  ability: AppAbility
  onDelete: () => Promise<void>
}

export function OrganizationSettings(props: OrganizationSettingsProps) {
  return <OrganizationEditPage path="/settings" {...props} />
}
