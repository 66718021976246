import { PageDTO, SearchQueryDTO, SearchResultDTO } from "@core-services/dtos"
import { CreateSupportTicket } from "@core-services/portal"
import { UseMutationResult, useMutation } from "@tanstack/react-query"
import axios, { AxiosError, AxiosResponse } from "axios"

type Mutation<TInput, TOutput = TInput> = UseMutationResult<AxiosResponse<TOutput>, unknown, TInput, unknown>

/**
 *
 *           useMutation<A, B, C>
 *                       ┋  ┋  ┋
 *     server response ━━┛  ┃  ┃
 *     server error ━━━━━━━━┛  ┃
 *     mutation data ━━━━━━━━━━┛
 *
 */

export const useCreateSupportTicket = (): UseMutationResult<void, AxiosError<Error>, CreateSupportTicket, unknown> => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }
  return useMutation<void, AxiosError<Error>, CreateSupportTicket>((ticket) => {
    return axios.post("/api/support/tickets", ticket, config)
  })
}

export const useLogout = (): Mutation<void, unknown> => {
  return useMutation((dto) => {
    return axios.post("/api/logout", dto)
  })
}

export const useSearch = (): UseMutationResult<
  PageDTO<SearchResultDTO>,
  AxiosError<Error>,
  SearchQueryDTO,
  unknown
> => {
  return useMutation<PageDTO<SearchResultDTO>, AxiosError<Error>, SearchQueryDTO>(async (dto) => {
    const response = await axios.post("/api/search", dto)

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response.data
  })
}
