import { IsBoolean, IsLatitude, IsLongitude, IsNumber, IsOptional, IsString } from "class-validator"
import { IsEntityName, IsNonEmptyString, IsPositiveInteger } from "../common"

export class CreateSiteDTO {
  @IsEntityName()
  name!: string

  @IsNumber()
  region!: number

  @IsLongitude()
  longitude!: number

  @IsLatitude()
  latitude!: number

  @IsBoolean()
  viewerTrafficActive!: boolean

  @IsBoolean()
  edgeEgressActive!: boolean

  @IsBoolean()
  edgeOriginActive!: boolean

  @IsBoolean()
  mediaIngressActive!: boolean

  @IsNonEmptyString()
  code!: string

  @IsOptional()
  @IsPositiveInteger()
  max_capacity?: number

  @IsOptional()
  @IsString()
  providers?: string

  @IsBoolean()
  transcodingActive!: boolean
}
