import { useRouter } from "@tanstack/react-router"
import { ConfirmDialogContent, Dialog } from "@vindral/components"
import { useCallback, useEffect, useState } from "react"

export const RouteBlockDialog = ({
  condition,
  message = (
    <>
      Are you sure you want to leave this page?
      <br />
      You have unsaved changes that will be lost!
    </>
  ),
  title = "Confirm Navigation",
}: {
  condition: boolean
  message?: JSX.Element
  title?: string
}) => {
  const router = useRouter()
  const [promise, setPromise] = useState<{ resolve: (confirm: boolean) => void } | null>(null)

  const allowNavigation = useCallback(
    (allow: boolean) => {
      promise?.resolve(allow)
      setPromise(null)
    },
    [promise]
  )

  const showDialog = useCallback(() => {
    return new Promise<boolean>((resolve) => {
      setPromise({ resolve })
    })
  }, [])

  useEffect(() => {
    if (!condition) {
      return
    }

    const unblock = router.history.block((retry) => {
      const block = async () => {
        if (await showDialog()) {
          unblock()
          retry()
        }
      }
      void block()
    })

    return () => unblock()
  }, [router, condition, showDialog])

  const escFunction = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        allowNavigation(false)
      }
    },
    [allowNavigation]
  )

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false)
    return () => {
      document.removeEventListener("keydown", escFunction, false)
    }
  }, [escFunction])

  return (
    <Dialog open={promise !== null}>
      <ConfirmDialogContent
        onConfirm={() => allowNavigation(true)}
        onCancel={() => allowNavigation(false)}
        buttonVariant={"danger"}
        title={title}
        description={message}
      />
    </Dialog>
  )
}
