import { subject } from "@casl/ability"
import { Action } from "@core-services/data-types"
import { AppAbility, UpdateUserPassword, VerifyTwoFactor } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Button,
  ConfirmDialogContent,
  Dialog,
  DialogTrigger,
  FormControl,
  FormControlLabel,
  Light,
} from "@vindral/components"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { FormSection } from "../../atoms/FormSection"
import { PasswordTextField } from "../../molecules/PasswordTextField"
import { ConfigureTwoFactorModal } from "../../organisms/2fa/ConfigureTwoFactorModal"
import { UserProfile } from "./Profile"

const resolver = zodResolver(UpdateUserPassword)

interface EditPasswordProps {
  profile: UserProfile
  ability: AppAbility
  updateUserPassword: (value: UpdateUserPassword) => Promise<void>
  generate2fa: () => Promise<{ publicId: string; uri: string }>
  verify2fa: (value: VerifyTwoFactor) => Promise<void>
  disable2fa: () => Promise<void>
}

export const EditPassword = (props: EditPasswordProps): JSX.Element => {
  const { profile, ability, updateUserPassword, generate2fa, verify2fa, disable2fa } = props
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid, isSubmitSuccessful },
  } = useForm<UpdateUserPassword>({
    resolver,
    mode: "onChange",
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ oldPassword: "", newPassword: "", confirmNewPassword: "" })
    }
  }, [isSubmitSuccessful, reset])

  return (
    <div>
      <div className="py-4">
        <FormSection title="Change Password" description="">
          <form onSubmit={handleSubmit(updateUserPassword)} className="flex max-w-md flex-col gap-4">
            <FormControl>
              <FormControlLabel error={errors.oldPassword?.message}>Old Password</FormControlLabel>
              <PasswordTextField
                {...register("oldPassword")}
                error={!!errors?.newPassword}
                disabled={ability.cannot("update", subject("User", profile), "password")}
                placeholder="••••••••••••••••"
                autoComplete="current-password"
              />
            </FormControl>
            <FormControl>
              <FormControlLabel error={errors.newPassword?.message}>New Password</FormControlLabel>
              <PasswordTextField
                {...register("newPassword")}
                error={!!errors.newPassword}
                disabled={ability.cannot("update", subject("User", profile), "password")}
                autoComplete="new-password"
              />
            </FormControl>
            <FormControl>
              <FormControlLabel error={errors.confirmNewPassword?.message}>Confirm New Password</FormControlLabel>
              <PasswordTextField
                {...register("confirmNewPassword")}
                error={!!errors.confirmNewPassword}
                disabled={ability.cannot("update", subject("User", profile), "password")}
                autoComplete="new-password"
              />
            </FormControl>
            {ability.can(Action.Update, subject("User", profile)) ? (
              <div>
                <Button disabled={!isDirty || !isValid} data-pw="saveButton" type="submit" variant="primary">
                  Save
                </Button>
              </div>
            ) : null}
          </form>
        </FormSection>
      </div>
      <div className="py-4">
        <FormSection
          title="Two-factor authentication"
          description="Add an additional layer of security to your account by requiring more than just a password to sign in."
        >
          {profile.twoFactorEnabled && (
            <div className="flex max-w-2xl items-center">
              <div className="flex flex-col pb-4">
                <h1 className="mb-1 font-medium">Enabled</h1>
                <div className="text-fg-subtle">Once enabled, we will ask for authentication code when you log in</div>
              </div>
              <div className="ml-auto">
                <Dialog>
                  <DialogTrigger asChild>
                    <Button type="button" variant="danger">
                      Disable
                    </Button>
                  </DialogTrigger>
                  <ConfirmDialogContent
                    onConfirm={disable2fa}
                    title="Disable Two-Factor Authentication"
                    description={
                      <>
                        Please confirm that you are absolutely sure that you want to disable two-factor authentication.
                      </>
                    }
                  />
                </Dialog>
              </div>
            </div>
          )}
          <div className="flex max-w-2xl items-center rounded-sm border border-divider p-4">
            <div className="flex flex-col">
              <h1 className="mb-1 font-medium">Authenticator App</h1>
              <div className="text-fg-subtle">Receive authentication code through a mobile app</div>
            </div>
            <div className="ml-auto flex items-center">
              {profile.twoFactorEnabled ? (
                <>
                  <div className="mx-4">
                    <Light color="green" />
                    <span className="pl-2 text-fg-positive">Configured</span>
                  </div>
                  <ConfigureTwoFactorModal
                    title="Configure Two-Factor Authentication"
                    actionText="Configure"
                    generate={generate2fa}
                    verify={verify2fa}
                  />
                </>
              ) : (
                <ConfigureTwoFactorModal
                  title="Setup Two-Factor Authentication"
                  actionText="Enable"
                  generate={generate2fa}
                  verify={verify2fa}
                />
              )}
            </div>
          </div>
        </FormSection>
      </div>
    </div>
  )
}
