import { z } from "zod"
import { Order } from "../order"

export const UserFindManyParameters = z
  .object({
    orderBy: z.enum(["name", "email", "createdAt", "lastLoginAt"]),
    search: z.string().optional(),
    filter: z
      .object({
        organization: z.object({ publicId: z.string() }).optional(),
      })
      .optional(),
  })
  .merge(Order)
export type UserFindManyParameters = z.infer<typeof UserFindManyParameters>
