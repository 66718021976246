import { subject } from "@casl/ability"
import { AppAbility } from "@common/access-control"
import { Action } from "@core-services/data-types"
import { Link, Outlet } from "@tanstack/react-router"
import { Button, ConfirmDialogContent, Dialog, DialogTrigger } from "@vindral/components"
import { checkIfSuperUser } from "../../acl"
import { OpenPlayerDropdown } from "../../atoms/OpenPlayerDropdown"
import { Page, PageContainer, PageContent, PageFooter, PageHeader } from "../../atoms/Page"
import { useNoMetricsEdge } from "../../hooks/useNoMetricsEdge"
import { Breadcrumbs } from "../../molecules/Breadcrumbs"
import { FormattedDate } from "../../molecules/FormattedDate"
import { RouteTabs, RouteTrigger } from "../../molecules/RouteTabs"
import { Titled } from "../../molecules/Titled"
import { RouterOutputs } from "../../trpc"

export type ChannelFindById = RouterOutputs["channel"]["findById"]

export interface ChannelEditProps {
  ability: AppAbility
  channel: ChannelFindById
  onDelete: (channelPublicId: string) => Promise<void>
  rtmpPlayUrl: string | undefined | null
}

export function ChannelEdit({ ability, channel, onDelete, rtmpPlayUrl }: ChannelEditProps) {
  const noMetricsEdge = useNoMetricsEdge()

  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
            <OpenPlayerDropdown channel={channel} noMetricsEdge={noMetricsEdge} rtmpPlayUrl={rtmpPlayUrl} />
          </div>
          <RouteTabs border="none">
            <RouteTrigger to="/channels/edit/$channelId/details" params={{ channelId: channel.channelId }}>
              Details
            </RouteTrigger>
            <RouteTrigger to="/channels/edit/$channelId/sessions" params={{ channelId: channel.channelId }}>
              Sessions
            </RouteTrigger>
            <RouteTrigger to="/channels/edit/$channelId/broadcast" params={{ channelId: channel.channelId }}>
              Broadcast
            </RouteTrigger>
            <RouteTrigger to="/channels/edit/$channelId/playout" params={{ channelId: channel.channelId }}>
              Playout
            </RouteTrigger>
            {checkIfSuperUser({ ability }) && (
              <RouteTrigger to="/channels/edit/$channelId/debug" params={{ channelId: channel.channelId }}>
                Debug
              </RouteTrigger>
            )}
          </RouteTabs>
        </PageHeader>
        <PageContainer>
          <Outlet />
        </PageContainer>
      </PageContent>
      <PageFooter>
        <div className="flex h-16 items-center justify-between">
          <div className="flex gap-8">
            <Titled title="Organization">
              <Link to="/organizations/edit/$publicId" params={{ publicId: channel.organization.publicId }}>
                {channel.organization.name}
              </Link>
            </Titled>
            <Titled title="Created">
              <FormattedDate date={new Date(channel.createdAt)} />
            </Titled>
            {typeof channel.updatedAt !== "undefined" && (
              <Titled title="Updated">
                <FormattedDate date={new Date(channel.updatedAt)} />
              </Titled>
            )}
          </div>
          {ability.can(Action.Delete, subject("Channel", channel)) && (
            <Dialog>
              <DialogTrigger asChild>
                <Button variant="danger" data-pw="deleteButton">
                  Delete
                </Button>
              </DialogTrigger>
              <ConfirmDialogContent
                onConfirm={() => onDelete(channel.channelId)}
                title={`Confirm deletion of ${channel.name}`}
                description={
                  <>
                    Please confirm that you are absolutely sure that you want to delete
                    <span className="font-medium"> {channel.name}</span>.
                  </>
                }
              />
            </Dialog>
          )}
        </div>
      </PageFooter>
    </Page>
  )
}
