import { ReactNode } from "react"
import { StatusTitle } from "../atoms/StatusTitle"

export function StatusBlock({ title, children }: { title: string; children: ReactNode }) {
  return (
    <div>
      <StatusTitle>{title}</StatusTitle>
      {children}
    </div>
  )
}
