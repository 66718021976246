import { Light } from "@vindral/components"

interface StatusLightProps {
  loading: boolean
  online: boolean
  host: string | string[] | undefined | null
}

export function StatusLight({ online, loading, host }: StatusLightProps) {
  if (loading) {
    return (
      <div>
        <Light color="blue" state="pinging" />
        <span className="pl-2">Loading...</span>
      </div>
    )
  }

  const color = online ? "green" : "neutral"
  const message = online ? "Ok" : "No data"
  const hostnames = Array.isArray(host) ? host.join(", ") : host

  return (
    <div>
      <Light color={color} />
      <span className="pl-2">{hostnames || message}</span>
    </div>
  )
}
