import { IsBoolean, IsNotEmpty, IsOptional, IsString, MaxLength } from "class-validator"
import { IsEntityName } from "../common"

export class CreateChannelDTO {
  static readonly modelName = "Channel"

  @IsEntityName()
  name!: string

  @IsString()
  @MaxLength(200)
  notes!: string

  @IsNotEmpty()
  organizationId!: number

  @IsOptional()
  @IsBoolean()
  authRequired!: boolean
}
