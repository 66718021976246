import { z } from "zod"
import { normalizeIdentifier } from "../normalize"

export const CreateChannel = z.object({
  name: z
    .string()
    .min(2)
    .max(50)
    .refine((val) => normalizeIdentifier(val).length >= 2, {
      message: "Normalized name must be at least 2 characters long",
    }),
  notes: z.string().max(500),
  organizationId: z.string(),
  authRequired: z.boolean().default(false),
  transcodingProfileId: z.string({}),
})

export type CreateChannel = z.infer<typeof CreateChannel>
