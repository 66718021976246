import { AppAbility, OrganizationFindOne, UpdateOrganization } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { checkIfSuperUser } from "../../../acl"
import { FormSection } from "../../../atoms/FormSection"
import { SaveButton } from "../../../atoms/SaveButton"
import { SaveSection } from "../../../atoms/SaveSection"
import { RouteBlockDialog } from "../../../molecules/RouteBlockDialog"
import { TelemetryOverridesForm } from "../../../organisms/TelemetryOverridesForm"

const resolver = zodResolver(UpdateOrganization)

export interface OrganizationEditTelemetryProps {
  organization: OrganizationFindOne
  ability: AppAbility
  onUpdate: (organization: UpdateOrganization) => Promise<void>
}

export function OrganizationEditTelemetry({ organization, ability, onUpdate }: OrganizationEditTelemetryProps) {
  const isSuperUser = checkIfSuperUser({ ability })
  const form = useForm<UpdateOrganization>({
    resolver,
    mode: "onChange",
    defaultValues: {
      publicId: organization.publicId,
      clientTelemetry: organization.clientTelemetry,
    },
  })
  const {
    reset,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting, isSubmitSuccessful },
  } = form

  useEffect(() => {
    reset({
      publicId: organization.publicId,
      clientTelemetry: organization.clientTelemetry,
    })
  }, [reset, organization])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful, reset])

  if (!isSuperUser) {
    return <></>
  }

  const columnWidth = "w-full max-w-lg"

  return (
    <div className="py-4">
      <RouteBlockDialog condition={isDirty && !isSubmitting} />
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onUpdate)} className="flex flex-col gap-4">
          <FormSection
            width={columnWidth}
            title="Telemetry"
            description={
              <>
                Configure client telemetry options for this organization. This will override the default options in the
                client. The default behaviour for clients is to:
                <ul className="list-disc pl-4 pt-2">
                  <li>100% probability to send telemetry</li>
                  <li>Send telemetry every 30 seconds</li>
                  <li>Always send errors</li>
                  <li>Always send stats</li>
                  <li>Send telemetry to the connected edge</li>
                </ul>
              </>
            }
          >
            <TelemetryOverridesForm />
          </FormSection>

          {isSuperUser && (
            <SaveSection width={columnWidth}>
              <SaveButton text="Save" disabled={!isDirty || !isValid} />
            </SaveSection>
          )}
        </form>
      </FormProvider>
    </div>
  )
}
