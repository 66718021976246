import { toast } from "@vindral/components"
import { ReactNode, createContext, useCallback, useContext, useRef, useState } from "react"
import { VerifyTwoFactorModal } from "../organisms/2fa/VerifyTwoFactorModal"
import { trpc } from "../trpc"

type ExecuteFunction = (value: { code: string }) => Promise<void>
type ExecuteWithTwoFactor = <T extends unknown[], R>(fn: (...args: T) => Promise<R>) => (...args: T) => Promise<R>
type ExecuteWithTwoFactorUnauthenticated = <T extends unknown[], R>(
  userPublicId: string,
  fn: (...args: T) => Promise<R>
) => (...args: T) => Promise<R>

interface TwoFactorProviderProps {
  children: ReactNode
}

interface TwoFactorContextProps {
  withTwoFactor: ExecuteWithTwoFactor
  withTwoFactorUnauthenticated: ExecuteWithTwoFactorUnauthenticated
}

const TwoFactorContext = createContext<TwoFactorContextProps | undefined>(undefined)

export const useTwoFactor = () => {
  const context = useContext(TwoFactorContext)
  if (!context) {
    throw new Error("useTwoFactor must be used within a TwoFactorProvider")
  }
  return context
}

export const TwoFactorProvider = ({ children }: TwoFactorProviderProps) => {
  const executeFnRef = useRef<ExecuteFunction | undefined>(undefined)
  const [open, setOpen] = useState(false)
  const context = trpc.useContext()

  const verify2faMutation = trpc.auth.verifyTwoFactor.useMutation({
    onError: (error) => {
      toast({ title: "Two-factor authentication failed!", description: error.message })
    },
  })

  const withTwoFactor: ExecuteWithTwoFactor = useCallback(
    (fn) =>
      async (...args) => {
        const isTwoFactorVerified = await context.auth.isTwoFactorVerified.fetch()
        if (isTwoFactorVerified) {
          return await fn(...args)
        } else {
          setOpen(true)
          return new Promise((resolve) => {
            executeFnRef.current = async (value) => {
              await verify2faMutation.mutateAsync(value)
              resolve(await fn(...args))
            }
          })
        }
      },
    [context.auth.isTwoFactorVerified, verify2faMutation]
  )

  const verify2fuMutation = trpc.auth.verifyTwoFactorUnauthenticated.useMutation({
    onError: (error) => {
      toast({ title: "Two-factor authentication failed!", description: error.message })
    },
  })

  const withTwoFactorUnauthenticated: ExecuteWithTwoFactorUnauthenticated = useCallback(
    (userPublicId: string, fn) =>
      async (...args) => {
        setOpen(true)
        return new Promise((resolve) => {
          executeFnRef.current = async (value) => {
            await verify2fuMutation.mutateAsync({
              ...value,
              userPublicId,
            })
            resolve(await fn(...args))
          }
        })
      },
    [verify2fuMutation]
  )

  const verify = async (value: { code: string }) => {
    if (executeFnRef.current) {
      await executeFnRef.current(value)
      executeFnRef.current = undefined
    }
  }

  const onClose = useCallback(() => {
    setOpen(false)
    executeFnRef.current = undefined
  }, [])

  const contextValue: TwoFactorContextProps = {
    withTwoFactor,
    withTwoFactorUnauthenticated,
  }

  return (
    <TwoFactorContext.Provider value={contextValue}>
      <>
        {children}
        <VerifyTwoFactorModal open={open} verify={verify} onClose={onClose} actionText="Verify" />
      </>
    </TwoFactorContext.Provider>
  )
}
