import { ChannelMode } from "@core-services/data-types"
import { z } from "zod"
import { clientOverrides } from "../client-overrides"
import { clientTelemetry } from "../client-telemetry"
import { CountryCodeLimitation, StringLimitation } from "../limitable"
import { normalizeIdentifier } from "../normalize"

export const UpdateChannel = z.object({
  channelId: z.string(),
  name: z
    .string()
    .min(2)
    .max(50)
    .refine((val) => normalizeIdentifier(val).length >= 2, {
      message: "Normalized name must be at least 2 characters long",
    })
    .optional(),
  notes: z.string().max(500).optional(),
  authRequired: z.boolean().optional(),
  transcodingProfileId: z.string({}).nullish(),
  suspended: z.boolean().optional(),
  packagerConfiguration: z.object({}).passthrough().nullish(),
  mode: z.nativeEnum(ChannelMode).optional(),
  site: z.string().nullish(),
  hostName: z.string().nullish(),
  siteLimitation: StringLimitation.optional(),
  viewerCountryLimitation: CountryCodeLimitation.optional(),
  transcodingMultiplier: z.number().positive().nullish(),
  clientOverrides: clientOverrides.optional(),
  clientTelemetry: clientTelemetry.optional(),
})

export type UpdateChannel = z.infer<typeof UpdateChannel>
