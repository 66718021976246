import { Page, PageContainer, PageContent, PageHeader } from "../../atoms/Page"
import { Breadcrumbs } from "../../molecules/Breadcrumbs"
import { ApiKeyTable, type ApiKeyTableProps } from "../../organisms/ApiKeyTable"

export type ApiKeyListProps = ApiKeyTableProps & {
  isStaleData: boolean
}

export function ApiKeyList({ isStaleData, ...props }: ApiKeyListProps) {
  if (props.data.length === 0 && !props.state?.globalFilter && !isStaleData) {
    return (
      <div className="relative flex justify-center pt-40">
        <div>
          <h1 className="font-medium">There is nothing here right now</h1>
          <p className="py-1 text-fg-subtle last-of-type:pb-3">You will be able to see API keys here very soon.</p>
        </div>
      </div>
    )
  }

  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
          </div>
        </PageHeader>
        <PageContainer>
          <div className="py-4">
            <ApiKeyTable {...props} />
          </div>
        </PageContainer>
      </PageContent>
    </Page>
  )
}
