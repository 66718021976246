export const formatChannels = (channels: number): string => {
  if (channels === 1) {
    return "Mono"
  }
  if (channels === 2) {
    return "Stereo"
  }

  return channels.toString()
}
