import { Pagination } from "@vindral/components"
import { Page, PageContainer, PageContent, PageFooter, PageHeader } from "../../../atoms/Page"
import { Breadcrumbs } from "../../../molecules/Breadcrumbs"
import { Titled } from "../../../molecules/Titled"
import {
  PackagerServerTable,
  UsePackagerServerTableProps,
  usePackagerServerReactTable,
} from "../../../organisms/PackagerServerTable"

export type PackagerServerListProps = UsePackagerServerTableProps & {
  saveColumnVisibility: () => void
  resetColumnVisibilityToDefault: () => void
  isStaleData: boolean
  totalCount: number
  packagerCpuUsed: number
  packagerCpuFree: number
}

export function PackagerServerList({ isStaleData, totalCount, ...props }: PackagerServerListProps) {
  const packagerTable = usePackagerServerReactTable(props)
  if (props.data.length === 0 && !props.state?.globalFilter && !isStaleData) {
    return (
      <div className="relative flex justify-center pt-40">
        <div>
          <h1 className="font-medium">There is nothing here right now</h1>
          <p className="py-1 text-fg-subtle last-of-type:pb-3">
            You will be able to see packager servers here very soon.
          </p>
        </div>
      </div>
    )
  }

  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
          </div>
        </PageHeader>
        <PageContainer>
          <div className="py-4">
            <PackagerServerTable {...props} table={packagerTable} />
          </div>
        </PageContainer>
      </PageContent>
      <PageFooter>
        <div className="flex h-16 items-center justify-between">
          <div>
            {totalCount} Packager server{totalCount > 1 ? "s" : ""}
          </div>
          <div className="flex gap-4">
            <Titled title="Current Packager CPU Usage">{props.packagerCpuUsed} MHz</Titled>
            <Titled title="Free Packager CPU">{props.packagerCpuFree} MHz</Titled>
          </div>

          <Pagination
            pageIndex={packagerTable.getState().pagination.pageIndex}
            pageCount={packagerTable.getPageCount()}
            nextPage={() => packagerTable.nextPage()}
            previousPage={() => packagerTable.previousPage()}
          />
        </div>
      </PageFooter>
    </Page>
  )
}
