import { OrganizationFindOne } from "@core-services/portal"
import { useMemo, useState } from "react"
import { trpc } from "../trpc"

function getNextPageParam<T>(lastPage: { total: number }, allPages: { total: number; items: T[] }[]) {
  const nextSkip = allPages.reduce((prev, page) => prev + page.items.length, 0)
  return nextSkip < lastPage.total ? nextSkip : undefined
}

export function useOrganizationSelect(props?: { defaultOrganization?: OrganizationFindOne }) {
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<OrganizationFindOne | null>(props?.defaultOrganization ?? null)
  const [search, setSearch] = useState("")

  // allData use the same query as the initial search query to only generate one network fetch.
  const { data: allData } = trpc.organization.findMany.useInfiniteQuery({ orderBy: "name" })
  const query = trpc.organization.findMany.useInfiniteQuery(
    { orderBy: "name", search: search.length > 0 ? search : undefined },
    { getNextPageParam, keepPreviousData: true }
  )

  const allPages = useMemo(() => {
    return query.data?.pages.flatMap((pages) => pages.items) ?? []
  }, [query])

  const options = [
    {
      id: "options",
      items: allPages,
    },
  ]

  const yourOnlyOption = allData?.pages[0]?.total === 1 ? allData.pages[0].items[0] : undefined

  return {
    /**
     * If there only is one organization available to the user it will be present in this variable, else it will be undefined.
     */
    yourOnlyOption,
    query,
    options,
    selected,
    setSelected,
    search,
    setSearch,
    open,
    setOpen,
  }
}
