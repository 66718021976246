// MUST BE FIRST
import "reflect-metadata" // TODO: This is needed because Vite import it automatically
// MUST BE FIRST

import * as Sentry from "@sentry/react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { RouterProvider } from "@tanstack/react-router"
import { httpBatchLink, httpLink } from "@trpc/client"
import { Toaster, TooltipProvider } from "@vindral/components"
import { SnackbarProvider } from "notistack"
import React, { lazy } from "react"
import { createRoot } from "react-dom/client"
import { getSentryEnvironment, sentryEnabled, showDevtools } from "./config"
import { ReCaptchaProvider } from "./contexts"
import { GoogleTagManagerProvider } from "./contexts/googleTagManager/googleTagManager"
import { VindralThemeProvider } from "./contexts/theme"
import { TwoFactorProvider } from "./contexts/twoFactor"
import "./index.css"
import { router } from "./router"
import { trpc } from "./trpc"

if (sentryEnabled) {
  Sentry.init({
    dsn: "https://9fa4cead88008343ddb88572d6cd00a4@o4505069591855104.ingest.sentry.io/4506059180736512",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/portal.*\.cdn\.vindral\.com\/api/],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: getSentryEnvironment(),
  })
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
})

const trpcClient = trpc.createClient({
  links: [
    // We disable batch linking in dev mode because it's make debugging easier
    import.meta.env.PROD
      ? httpBatchLink({
          url: "/api/trpc",
        })
      : httpLink({
          url: "/api/trpc",
        }),
  ],
})

export const App = (): JSX.Element => {
  const context = trpc.useContext()

  return (
    <VindralThemeProvider>
      <SnackbarProvider maxSnack={3}>
        <Toaster />
        <TooltipProvider>
          <RouterProvider router={router} context={{ trpcContext: context }} />
        </TooltipProvider>
      </SnackbarProvider>
    </VindralThemeProvider>
  )
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById("root")!
const root = createRoot(container)

const ReactQueryDevtools = showDevtools
  ? lazy(() =>
      import("@tanstack/react-query-devtools").then((res) => ({
        default: res.ReactQueryDevtools,
      }))
    )
  : () => null

root.render(
  <React.StrictMode>
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <TwoFactorProvider>
          <ReCaptchaProvider>
            <GoogleTagManagerProvider>
              <App />
            </GoogleTagManagerProvider>
          </ReCaptchaProvider>
        </TwoFactorProvider>

        <ReactQueryDevtools
          initialIsOpen={false}
          position="bottom-right"
          toggleButtonProps={{ style: { filter: "grayscale(1)" } }}
        />
      </QueryClientProvider>
    </trpc.Provider>
  </React.StrictMode>
)
