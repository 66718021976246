import { useAbility } from "@casl/react"
import { Action } from "@core-services/data-types"
import { ChannelFindManyParameters } from "@core-services/portal"
import { Route, useNavigate, useSearch } from "@tanstack/react-router"
import { PaginationState, SortingState, VisibilityState } from "@tanstack/react-table"
import { useToast } from "@vindral/components"
import { useCallback, useEffect, useState } from "react"
import { useAsyncFn, useLocalStorage } from "react-use"
import { z } from "zod"
import { channelIndexRoute } from "."
import { AbilityContext } from "../../acl"
import { useNoMetricsEdge } from "../../hooks/useNoMetricsEdge"
import { ChannelList } from "../../templates/channel/ChannelList"
import { trpc } from "../../trpc"

function defaultColumns(showOrganizations: boolean) {
  return {
    name: true,
    notes: true,
    streamKey: false,
    channelId: false,
    createdAt: false,
    "organization.name": showOrganizations,
  }
}

const params = z.object({
  channelView: z
    .object({
      sorting: z.array(
        z.object({
          id: ChannelFindManyParameters.shape.orderBy,
          desc: z.boolean(),
        })
      ),
      pagination: z.object({
        pageIndex: z.number().nonnegative(),
        pageSize: z.number().nonnegative(),
      }),
      search: z.string().default(""),
    })
    .catch({
      sorting: [{ desc: false, id: "name" }],
      pagination: { pageIndex: 0, pageSize: 100 },
      search: "",
    }),
})

type SearchParams = ReturnType<typeof useSearch<typeof channelListRoute["id"]>>

function toTrpcParams(params: SearchParams) {
  const { pagination, sorting, search } = params.channelView

  return {
    cursor: pagination.pageIndex * pagination.pageSize,
    take: pagination.pageSize,
    orderBy: sorting[0]?.id ?? "name",
    order: sorting[0]?.desc ? "desc" : "asc",
    search: search.length > 0 ? search : undefined,
    filter: params.organizationId ? { organization: { publicId: params.organizationId } } : undefined,
  } as const
}

export const channelListRoute = new Route({
  path: "/",
  getParentRoute: () => channelIndexRoute,
  validateSearch: params,
  onLoad: async ({ search, context: { trpcContext } }) => {
    const params = toTrpcParams(search)

    if (search.organizationId) {
      await trpcContext.organization.findOne.ensureData({ publicId: search.organizationId })
    }
    await trpcContext.channel.findMany.ensureData(params, { staleTime: 1000 })
  },
  preSearchFilters: [(search) => search],
  component: Page,
})

function Page() {
  const searchParams = useSearch({ from: channelListRoute.id })
  return <List key={searchParams.organizationId} />
}

function List() {
  const searchParams = useSearch({ from: channelListRoute.id })
  const navigate = useNavigate({ from: channelListRoute.id })
  const trpcParams = toTrpcParams(searchParams)
  const { toast } = useToast()
  const ability = useAbility(AbilityContext)
  const noMetricsEdge = useNoMetricsEdge()

  const { data } = trpc.channel.findMany.useQuery(trpcParams, {
    keepPreviousData: true,
    staleTime: 1000,
    refetchInterval: 5000,
  })

  const { data: organization } = trpc.organization.findOne.useQuery(
    { publicId: searchParams.organizationId ?? "" },
    { enabled: !!searchParams.organizationId }
  )

  const [sorting, setSorting] = useState<SortingState>(searchParams.channelView.sorting)
  const [pagination, setPagination] = useState<PaginationState>(searchParams.channelView.pagination)
  const [globalFilter, setGlobalFilter] = useState(searchParams.channelView.search)
  const [value, setValue, remove] = useLocalStorage<VisibilityState>(
    "channel-columns",
    defaultColumns(!searchParams.organizationId)
  )
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    value ?? defaultColumns(!searchParams.organizationId)
  )
  const pageCount = Math.ceil((data?.total ?? 0) / pagination.pageSize)
  const [navigationState, doNavigate] = useAsyncFn(
    async (...args: Parameters<typeof navigate>) => {
      return navigate(...args)
    },
    [navigate]
  )

  if (globalFilter !== searchParams.channelView.search && pagination.pageIndex !== 0) {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }))
  }

  useEffect(() => {
    void doNavigate({
      from: channelListRoute.id,
      to: "/channels",
      replace: true,
      params: {},
      search: (prev) => {
        return { ...prev, channelView: { sorting, pagination, search: globalFilter } }
      },
    })
  }, [doNavigate, sorting, pagination, globalFilter, setPagination])

  const saveColumnVisibility = useCallback(() => {
    setValue(columnVisibility)
    toast({ title: "Saved configuration" })
  }, [toast, setValue, columnVisibility])

  const resetColumnVisibilityToDefault = useCallback(() => {
    remove()
    setColumnVisibility(defaultColumns(!searchParams.organizationId))
    toast({ title: "Restored to default" })
  }, [toast, remove, setColumnVisibility, searchParams.organizationId])

  const onClickCreate = useCallback(() => {
    void doNavigate({
      from: channelListRoute.id,
      to: "/channels/create",
      params: {},
      search: (prev) => {
        return { ...prev, channelView: { sorting, pagination, search: globalFilter } }
      },
    })
  }, [globalFilter, doNavigate, pagination, sorting])

  const downloadCsvUrl = searchParams.organizationId
    ? `/api/organizations/${searchParams.organizationId}/channels/csv`
    : undefined

  const showCreate = ability.can(Action.Create, "Channel") && !organization?.suspended

  return (
    <ChannelList
      downloadCsvUrl={downloadCsvUrl}
      noMetricsEdge={noMetricsEdge}
      onClickCreate={showCreate ? onClickCreate : undefined}
      data={data?.items ?? []}
      totalCount={data?.total ?? 0}
      pageCount={pageCount}
      manualPagination
      manualSorting
      manualFiltering
      isStaleData={navigationState.loading || globalFilter !== searchParams.channelView.search}
      state={{ sorting, pagination, globalFilter, columnVisibility }}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      onGlobalFilterChange={setGlobalFilter}
      onColumnVisibilityChange={setColumnVisibility}
      saveColumnVisibility={saveColumnVisibility}
      resetColumnVisibilityToDefault={resetColumnVisibilityToDefault}
      isSuspended={organization?.suspended}
      organizationId={organization?.publicId}
    />
  )
}
