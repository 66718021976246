import { SiteItem } from "@core-services/portal"
import { createColumnHelper } from "@tanstack/react-table"
import { Button } from "@vindral/components"
import { trpc } from "../../trpc"
import { MultiSelectorSiteCombobox } from "./MultiSelectSiteCombobox"
import { MultiSelector, MultiSelectorFilterTextField, MultiSelectorTable } from "./MultiSelector"

export interface SiteMultiSelectorProps {
  onSelect: (item: SiteItem) => void
  onDeselect: (index: number) => void
  selected: { code: string }[]
  nothingSelectedText: string
}

export function SiteMultiSelector(props: SiteMultiSelectorProps) {
  const { onSelect, onDeselect, selected, nothingSelectedText } = props

  const { data: allSites = [] } = trpc.site.findAll.useQuery()

  const getSiteName = (siteCode: string) => {
    const site = allSites.find((s) => s.code === siteCode)
    return site?.name ?? ""
  }
  const selectedWithName = selected.map((s) => ({ ...s, siteName: getSiteName(s.code) }))

  const columnHelper = createColumnHelper<typeof selectedWithName[0]>()
  const columns = [
    columnHelper.display({
      id: "priority",
      header: () => "Priority",
      cell: (info) => <div className="flex gap-2 whitespace-nowrap">{info.row.index + 1}</div>,
      size: 1,
    }),
    columnHelper.accessor("code", {
      header: () => "Code",
      cell: (info) => <div className="flex gap-2 whitespace-nowrap">{info.getValue()}</div>,
      size: 1,
    }),
    columnHelper.accessor("siteName", {
      header: () => "Name",
      cell: (info) => <div className="flex gap-2 whitespace-nowrap">{info.getValue()}</div>,
      size: Number.MAX_SAFE_INTEGER,
    }),
    columnHelper.display({
      id: "actions",
      header: () => "",
      cell: (info) => (
        <div className="flex justify-end">
          <Button variant="danger" size="small" onClick={() => onDeselect(info.row.index)}>
            Remove
          </Button>
        </div>
      ),
      size: 1,
    }),
  ]

  const hasSelectedAtLeastOneItem = selected.length > 0

  return (
    <MultiSelector>
      <div className="flex justify-between gap-4 pb-4">
        <MultiSelectorSiteCombobox onSelect={onSelect} selected={selected} />
        <div className="shrink-0">
          <MultiSelectorFilterTextField />
        </div>
      </div>
      {hasSelectedAtLeastOneItem ? (
        <MultiSelectorTable data={selectedWithName} getRowId={(row) => row.code} columns={columns} />
      ) : (
        <div className="flex flex-col gap-2 py-4">{nothingSelectedText}</div>
      )}
    </MultiSelector>
  )
}
