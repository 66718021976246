import { AccessPermissionRole, OrganizationBillingStatus } from "@core-services/data-types"
import { Link } from "@tanstack/react-router"
import { createColumnHelper } from "@tanstack/react-table"
import { Button } from "@vindral/components"
import { AccessPermissionRoleSelect } from "../../molecules/AccessPermissionRoleSelect"
import { MultiSelectorOrganizationCombobox } from "./MultiSelectOrganizationCombobox"
import { MultiSelector, MultiSelectorTable } from "./MultiSelector"

export interface AccessPermission {
  organization: { publicId: string; name: string; billingStatus: OrganizationBillingStatus }
  role: AccessPermissionRole
}

export interface AccessPermissionRoleMultiSelectorProps {
  onSelect: (item: AccessPermission) => void
  onDeselect: (index: number) => void
  onUpdate: (index: number, item: AccessPermission) => void
  selected: AccessPermission[]
  readonly?: boolean
}

export function AccessPermissionRoleMultiSelector(props: AccessPermissionRoleMultiSelectorProps) {
  const columnHelper = createColumnHelper<typeof props.selected[0]>()
  const columns = [
    columnHelper.accessor("organization.name", {
      header: () => "Name",
      cell: (info) => (
        <Link
          className="flex gap-2 whitespace-nowrap font-semibold"
          to="/organizations/edit/$publicId"
          params={{ publicId: info.row.original.organization.publicId }}
        >
          {info.getValue()}
        </Link>
      ),
      size: 200,
    }),
    columnHelper.accessor("role", {
      header: () => "Permissions",
      cell: (info) => (
        <AccessPermissionRoleSelect
          disabled={props.readonly}
          organizationUnderEvaluation={
            info.row.original.organization.billingStatus !== OrganizationBillingStatus.Billable
          }
          value={info.getValue()}
          onValueChange={(role) => {
            props.onUpdate(info.row.index, { ...info.row.original, role })
          }}
        />
      ),
      meta: { style: { textAlign: "right" } },
      size: Number.MAX_SAFE_INTEGER,
    }),
  ]

  if (!props.readonly) {
    columns.push(
      columnHelper.display({
        id: "actions",
        header: () => "",
        cell: (info) => (
          <div className="flex justify-end">
            <Button
              disabled={props.readonly}
              variant="danger"
              size="small"
              onClick={() => props.onDeselect(info.row.index)}
            >
              Remove
            </Button>
          </div>
        ),
        size: 1,
      })
    )
  }

  return (
    <div className="w-full">
      <MultiSelector>
        <div className="flex flex-col gap-4">
          <div>
            {!props.readonly ? (
              <MultiSelectorOrganizationCombobox
                onSelect={(organization) => props.onSelect({ organization, role: "user" })}
                selected={props.selected.map((f) => f.organization)}
              />
            ) : null}
          </div>
          {props.selected.length > 0 ? <MultiSelectorTable data={props.selected} columns={columns} /> : null}
        </div>
      </MultiSelector>
    </div>
  )
}
