import { WebhookConfig } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { Button, Copyable, FormControlLabel, Light, Switch, TextField } from "@vindral/components"
import { useEffect, useState } from "react"
import { useController, useForm } from "react-hook-form"
import { z } from "zod"
import { FormSection } from "../../../atoms/FormSection"
import { Hyperlink } from "../../../atoms/Hyperlink"
import { SaveButton } from "../../../atoms/SaveButton"
import { SaveSection } from "../../../atoms/SaveSection"

const webhookConfigSchema = z.object({
  url: z.string().url({ message: "URL must be valid" }).max(500),
  enabled: z.boolean(),
})
const resolver = zodResolver(webhookConfigSchema)
export type webhookConfigSchema = z.infer<typeof webhookConfigSchema>

interface OrganizationEditWebhooksProps {
  webhookConfig?: WebhookConfig
  onCreate: (value: webhookConfigSchema) => Promise<void>
  onUpdate: (value: webhookConfigSchema) => Promise<void>
  onTestCallbackUrl: (url: string) => Promise<{ success: boolean }>
  canEdit: boolean
}

type TestStatus = "undefined" | "success" | "unsuccess"

function TestLight({ status }: { status: TestStatus }) {
  if (status === "undefined") {
    return null
  }

  const successful = status === "success"

  return (
    <div className="mx-4 flex items-center">
      <Light color={successful ? "green" : "red"} />
      <span className="pl-4">{successful ? "Successful" : "Unsuccessful"}</span>
    </div>
  )
}

export function OrganizationEditWebhooks(props: OrganizationEditWebhooksProps) {
  const { webhookConfig, onCreate, onUpdate, onTestCallbackUrl, canEdit } = props
  const [status, setStatus] = useState<TestStatus>("undefined")

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
    getValues,
  } = useForm({
    resolver,
    mode: "onChange",
    defaultValues: {
      enabled: webhookConfig?.enabled ?? false,
      url: webhookConfig?.url ?? "",
    },
  })

  useEffect(() => {
    reset({
      enabled: webhookConfig?.enabled ?? false,
      url: webhookConfig?.url ?? "",
    })
    setStatus("undefined")
  }, [reset, webhookConfig])

  const onSubmit = async (value: webhookConfigSchema) => {
    if (webhookConfig) {
      await onUpdate(value)
    } else {
      await onCreate(value)
    }
  }

  const enabledController = useController({ control, name: "enabled" })

  const sendTest = async () => {
    try {
      const response = await onTestCallbackUrl(getValues("url"))

      if (!response.success) {
        setStatus("unsuccess")
      }

      setStatus("success")
    } catch (error) {
      setStatus("unsuccess")
    }
  }

  const columnWidth = "w-full max-w-lg"

  return (
    <form className="max-w-screen-xl space-y-4 py-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-8 flex flex-col">
        <h1 className="mb-1 text-xl font-medium">Webhooks</h1>
        <div className="text-gray-600 dark:text-gray-300">
          Receive and react to real-time updates from Vindral by configuring webhooks. Read more about webhooks{" "}
          <Hyperlink href="https://docs.vindral.com/manage/webhooks" text="here" />.
        </div>
      </div>
      <FormSection
        width={columnWidth}
        title="Enable Webhooks"
        description="Once enabled, the webhook will start receiving events"
      >
        <Switch
          disabled={!canEdit}
          onCheckedChange={enabledController.field.onChange}
          checked={enabledController.field.value}
        />
      </FormSection>
      <FormSection width={columnWidth} title="URL" description="The URL to make HTTP POST calls to">
        <TextField disabled={!canEdit} {...register("url")} error={!!errors?.url?.message} placeholder="https://" />
        <FormControlLabel error={errors?.url?.message} />
      </FormSection>
      <FormSection
        width={columnWidth}
        title="Secret"
        description="This secret will be used to sign every payload so that you can verify their integrity on the receiving end"
      >
        <div className="text-right">
          <div className="inline-block">
            {webhookConfig?.secret ? (
              <Copyable text={webhookConfig.secret}>
                <span className="block truncate font-mono text-sm">{webhookConfig.secret}</span>
              </Copyable>
            ) : (
              <span className="block truncate font-mono text-sm">
                A secret key will be generated after you save these settings
              </span>
            )}
          </div>
        </div>
      </FormSection>
      <FormSection width={columnWidth} title="Test" description="Verify the configured URL before enabling">
        <div className="flex justify-end">
          <TestLight status={status} />
          <Button disabled={!canEdit || !isValid} size="small" onClick={sendTest}>
            Send Test
          </Button>
        </div>
      </FormSection>
      {canEdit && (
        <SaveSection width={columnWidth}>
          <SaveButton text="Save" disabled={!isDirty || !isValid} />
        </SaveSection>
      )}
    </form>
  )
}
