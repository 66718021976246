import { forwardRef, ReactNode } from "react"
import { Label, LabelProps } from "../atoms/Label"

export interface FormControlProps {
  children: ReactNode
}

export function FormControl({ children }: FormControlProps) {
  return <div className="flex flex-col gap-1">{children}</div>
}

export const FormControlLabel = forwardRef<HTMLLabelElement, LabelProps>((props, ref) => {
  return <Label ref={ref} {...props} />
})

export const FormControlLabelHint = forwardRef<HTMLLabelElement, LabelProps>((props, ref) => {
  return (
    <div className="text-sm text-fg-subtle">
      <Label ref={ref} {...props} />
    </div>
  )
})
