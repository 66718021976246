import { Divider } from "@vindral/components"
import ProgressWithLabel from "../../atoms/ProgressWithLabel"
import { trpc } from "../../trpc"
import { AnalyticsQueryArgs } from "./Analytics"

const OTHER_KEY = "Other"

type DistributionListProps = {
  items: { key: string; value: number }[]
  max: number
}

const DistributionList = (props: DistributionListProps): JSX.Element => {
  const { items, max } = props
  const total = items.reduce((a, b) => a + b.value, 0)
  const topItems = items.slice(0, max)

  if (items.length > max) {
    const topTotal = topItems.reduce((a, b) => a + b.value, 0)

    // There already is an other item
    const other = topItems.find((item) => item.key === OTHER_KEY)
    if (other) {
      const index = topItems.findIndex((item) => item.key === OTHER_KEY)
      topItems[index] = { key: other.key, value: other.value + (total - topTotal) }
    } else {
      topItems[topItems.length - 1] = { key: OTHER_KEY, value: total - topTotal }
    }
  }

  return (
    <div className="pt-4">
      {topItems
        .sort((a, b) => b.value - a.value)
        .map((item) => {
          return (
            <div key={item.key}>
              {item.key}
              <ProgressWithLabel
                color="neutral"
                progress={(item.value / total) * 100}
                label={`${Math.round((item.value / total) * 100)}%`}
              />
            </div>
          )
        })}
      {!items.length && <div className="font-normal">No data</div>}
    </div>
  )
}

export const Distribution = (props: AnalyticsQueryArgs): JSX.Element => {
  const { data: browsers = [] } = trpc.analytics.browsers.useQuery(
    {
      ...props,
    },
    { keepPreviousData: true }
  )

  const { data: os = [] } = trpc.analytics.os.useQuery(
    {
      ...props,
    },
    { keepPreviousData: true }
  )

  const { data: devices = [] } = trpc.analytics.devices.useQuery(
    {
      ...props,
    },
    { keepPreviousData: true }
  )

  return (
    <div className="mt-4 flex gap-8">
      <div className="w-1/3">
        <div className="text-sm font-medium uppercase tracking-wider text-fg-subtle">Browser</div>
        <DistributionList items={browsers} max={10} />
      </div>
      <Divider variant="vertical" />
      <div className="w-1/3">
        <div className="text-sm font-medium uppercase tracking-wider text-fg-subtle">OS</div>
        <DistributionList items={os} max={10} />
      </div>
      <Divider variant="vertical" />
      <div className="w-1/3">
        <div className="text-sm font-medium uppercase tracking-wider text-fg-subtle">Device</div>
        <DistributionList items={devices} max={10} />
      </div>
    </div>
  )
}
