import {
  formatBitRate,
  formatCodec,
  formatGopSize,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "@vindral/components"
import { VideoSize } from "../atoms/VideoSize"
import { TranscodingProfileVideoRendition } from "../interfaces/TranscodingProfile"

interface VideoRenditionProps {
  rendition: TranscodingProfileVideoRendition
  matchSourceGop: boolean
}

function VideoRendition({ rendition, matchSourceGop }: VideoRenditionProps) {
  const gopSizeMs = "gopSizeMs" in rendition && rendition.gopSizeMs ? formatGopSize(rendition.gopSizeMs) : "N/A"

  return (
    <TableRow key={rendition.id}>
      <TableCell collapse>{formatCodec(rendition.codec)}</TableCell>
      <TableCell collapse className="tabular-nums">
        {"bitRate" in rendition && rendition.bitRate ? formatBitRate(rendition.bitRate) : "N/A"}
      </TableCell>
      <TableCell collapse className="tabular-nums">
        <VideoSize size={rendition.size} />
      </TableCell>
      <TableCell collapse>Match source</TableCell>
      <TableCell collapse>
        <span className="tabular-nums">{matchSourceGop ? "Match Source" : gopSizeMs}</span>
      </TableCell>
    </TableRow>
  )
}

interface VideoRenditionsProps {
  renditions: TranscodingProfileVideoRendition[]
  matchSourceGop: boolean
}

export function VideoRenditions({ renditions, matchSourceGop }: VideoRenditionsProps) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadCell>Codec</TableHeadCell>
          <TableHeadCell>Bit Rate</TableHeadCell>
          <TableHeadCell>Size</TableHeadCell>
          <TableHeadCell>Frame Rate</TableHeadCell>
          <TableHeadCell>GOP Size</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {renditions.map((rendition) => {
          return <VideoRendition key={rendition.id} rendition={rendition} matchSourceGop={matchSourceGop} />
        })}
      </TableBody>
    </Table>
  )
}
