import { Indicator, CheckboxProps as RadixCheckboxProps, Root } from "@radix-ui/react-checkbox"
import { CheckIcon } from "@radix-ui/react-icons"
import { Label } from "@radix-ui/react-label"
import { VariantProps, cva } from "class-variance-authority"

const defaultVariants = {
  size: "small",
  variant: "regular",
} as const

const root = cva(
  [
    "appearance-none",
    "rounded-sm",
    "border",
    "disabled:opacity-60",
    "shadow",
    "flex",
    "items-center",
    "justify-center",
    "dark:shadow-outline",
  ],
  {
    variants: {
      variant: {
        regular: ["border-divider", "bg-component", "dark:shadow-black/10", "enabled:active:bg-component-active"],
      },
      size: {
        small: ["h-4", "w-4"],
        medium: ["h-5", "w-5"],
        large: ["h-6", "w-6"],
        huge: ["h-7", "w-7"],
      },
    },
    defaultVariants,
  }
)

const indicator = cva(["h-full w-full"], {
  variants: {
    variant: {
      regular: [],
    },
  },
})

const icon = cva(["h-full w-full"], {
  variants: {
    variant: {
      regular: [],
    },
  },
})

export interface CheckboxProps extends RadixCheckboxProps, VariantProps<typeof root> {
  label?: string
}

export function Checkbox({ variant, size, label, ...rest }: CheckboxProps & { label?: string }) {
  return (
    <Label className="flex w-fit items-center gap-2">
      <Root {...rest} className={root({ variant, size })}>
        <Indicator className={indicator({ variant })}>
          <CheckIcon className={icon({ variant })} />
        </Indicator>
      </Root>
      {label}
    </Label>
  )
}

// export default CheckboxDemo;
