import { Exclude, Expose } from "class-transformer"
import { BaseDTO } from "../base.dto"
import { Rendition } from "../rendition.dto"
import { ThumbnailDTO } from "./thumbnail.dto"

@Exclude()
export class PublicApiChannelDTO extends BaseDTO<PublicApiChannelDTO> {
  @Expose()
  channelId!: string

  @Expose()
  name!: string

  @Expose()
  isLive!: boolean

  @Expose()
  thumbnail!: ThumbnailDTO

  @Expose()
  renditions!: Rendition[]

  @Expose()
  overrides?: Record<string, unknown>
}

@Exclude()
export class PublicApiChannelWithoutRenditionsDTO extends BaseDTO<PublicApiChannelWithoutRenditionsDTO> {
  @Expose()
  channelId!: string

  @Expose()
  name!: string

  @Expose()
  isLive!: boolean

  @Expose()
  thumbnail!: ThumbnailDTO
}
