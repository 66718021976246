import { AppAbility, CreateTranscodingProfile, OrganizationFindOne } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { FormControl, FormControlLabel, TextField } from "@vindral/components"
import { useCallback, useEffect } from "react"
import { useController, useForm } from "react-hook-form"
import { FormSection } from "../../atoms/FormSection"
import { Page, PageContainer, PageContent, PageHeader } from "../../atoms/Page"
import { SaveButton } from "../../atoms/SaveButton"
import { useOrganizationSelect } from "../../hooks/useOrganizationSelect"
import { Breadcrumbs } from "../../molecules/Breadcrumbs"
import { SingleSelectCombobox } from "../../molecules/SingleSelectCombobox"

const resolver = zodResolver(CreateTranscodingProfile)

export interface TranscodingProfileCreateProps {
  ability: AppAbility
  onCreate: (transcodingProfile: CreateTranscodingProfile) => Promise<void>
  organizationId?: string
}

export function TranscodingProfileCreate({ onCreate, ability, organizationId }: TranscodingProfileCreateProps) {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver,
    mode: "onChange",
    defaultValues: {
      name: "",
      notes: "",
      organizationId: organizationId ?? "",
      config: {
        streams: [
          {
            type: "video",
            renditions: [],
            matchSource: { stream: "best" },
            gopAlignment: false,
          },
          {
            type: "audio",
            renditions: [],
            matchSource: { stream: "best" },
          },
        ],
      },
    } satisfies CreateTranscodingProfile,
  })

  const organizationSelect = useOrganizationSelect()
  const organizationController = useController({ control, name: "organizationId" })
  const onOrganizationChange = useCallback(
    (organization: OrganizationFindOne) => {
      organizationController.field.onChange(organization.publicId)
      organizationSelect.setSelected(organization)
    },
    [organizationController.field, organizationSelect]
  )

  useEffect(() => {
    // Don't override the organization set by the prop
    if (organizationId) {
      return
    }

    if (!organizationSelect.selected && organizationSelect.yourOnlyOption) {
      onOrganizationChange(organizationSelect.yourOnlyOption)
    }
  }, [organizationSelect, onOrganizationChange, organizationId])

  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
          </div>
        </PageHeader>
        <PageContainer>
          <div className="py-4">
            <FormSection
              title="Create a new Transcoding Profile"
              description="Transcoding profiles determine how channels are transcoded. Multiple channels can share the same transcoding profile."
            >
              <form onSubmit={handleSubmit(onCreate)} className="flex flex-col items-start gap-4">
                {!organizationId && (
                  <FormControl>
                    <FormControlLabel error={organizationController.fieldState.error?.message} required>
                      Organization
                    </FormControlLabel>
                    <SingleSelectCombobox
                      options={organizationSelect.options}
                      selected={organizationSelect.selected}
                      search={organizationSelect.search}
                      isLoading={organizationSelect.query.isLoading}
                      onSearchChange={organizationSelect.setSearch}
                      dataPwButton="organizationSelector"
                      placeholder="Select an organization"
                      onSelect={onOrganizationChange}
                      onReachedEnd={() => {
                        if (!organizationSelect.query.hasNextPage) {
                          return
                        }
                        void organizationSelect.query.fetchNextPage()
                      }}
                    />
                  </FormControl>
                )}
                <div className="flex w-96 max-w-md flex-col gap-4">
                  <FormControl>
                    <FormControlLabel required error={errors.name?.message}>
                      Name
                    </FormControlLabel>
                    <TextField
                      {...register("name")}
                      error={!!errors?.name}
                      disabled={ability.cannot("create", "TranscodingProfile", "name")}
                    />
                  </FormControl>
                  <FormControl>
                    <FormControlLabel error={errors.notes?.message}>Notes</FormControlLabel>
                    <TextField
                      {...register("notes")}
                      multiline={true}
                      minRows={3}
                      error={!!errors.notes}
                      disabled={ability.cannot("create", "TranscodingProfile", "notes")}
                    />
                  </FormControl>
                </div>

                <div>
                  <SaveButton text="Create" disabled={!isDirty || !isValid} />
                </div>
              </form>
            </FormSection>
          </div>
        </PageContainer>
      </PageContent>
    </Page>
  )
}
