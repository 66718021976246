import { Route } from "@tanstack/react-router"
import { Spinner } from "@vindral/components"
import { ingestPointIndexRoute } from "."
import { IngestPoints } from "../../templates/infrastructure/ingest-points/IngestPoints"
import { IngestPointsDatacenter } from "../../templates/infrastructure/ingest-points/IngestPointsDatacenter"
import { IngestPointsLoadBalancer } from "../../templates/infrastructure/ingest-points/IngestPointsLoadBalancer"
import { IngestPointsRegional } from "../../templates/infrastructure/ingest-points/IngestPointsRegional"
import { trpc } from "../../trpc"

export const ingestPointListRoute = new Route({
  path: "/",
  getParentRoute: () => ingestPointIndexRoute,
  preSearchFilters: [],
  component: IngestPoints,
})

export const ingestPointRegionalRoute = new Route({
  getParentRoute: () => ingestPointListRoute,
  path: "regional",
  preSearchFilters: [],
  component: () => {
    const query = trpc.ingestPoint.regional.useQuery(undefined, {
      staleTime: 1000,
      refetchInterval: 30_000,
    })

    return <Loader component={IngestPointsRegional} {...query} />
  },
})

export const ingestPointDatacenterRoute = new Route({
  getParentRoute: () => ingestPointListRoute,
  path: "datacenter",
  preSearchFilters: [],
  component: () => {
    const query = trpc.ingestPoint.datacenter.useQuery(undefined, {
      staleTime: 1000,
      refetchInterval: 30_000,
    })

    return <Loader component={IngestPointsDatacenter} {...query} />
  },
})

export const ingestPointLoadBalancerRoute = new Route({
  getParentRoute: () => ingestPointListRoute,
  path: "load-balancer",
  preSearchFilters: [],
  component: () => {
    const query = trpc.ingestPoint.loadBalancer.useQuery(undefined, {
      staleTime: 1000,
      refetchInterval: 30_000,
    })

    return <Loader component={IngestPointsLoadBalancer} {...query} />
  },
})

interface LoaderProps<T> {
  data: T[] | undefined
  isLoading: boolean
  error: unknown
  component: ({ data }: { data: T[] }) => JSX.Element
}

const Loader = <T,>(props: LoaderProps<T>) => {
  const { component, data, isLoading, error } = props

  if (isLoading) {
    return (
      <div className="flex w-full grow items-center justify-center text-center">
        <Spinner />
      </div>
    )
  }

  if (error) {
    throw error
  }

  return component({ data: data ?? [] })
}
