import { IconLogout, IconUser, IconUserCircle } from "@tabler/icons-react"
import { useNavigate } from "@tanstack/react-router"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@vindral/components"
import { trpc } from "../../trpc"

interface ProfileMenuProps {
  onLogOut: () => void
  hideProfileLink?: boolean
}

export const ProfileMenu = (props: ProfileMenuProps) => {
  const { data: profile } = trpc.user.profile.useQuery(undefined, { suspense: true })
  const navigate = useNavigate()

  if (!profile) {
    return null
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div
          className="flex items-center gap-1 rounded-sm p-2 py-1.5 font-medium transition hover:bg-component-hover active:bg-component-active"
          role="button"
        >
          <IconUserCircle data-pw="profileIcon" size={16} />
          {profile.name}
        </div>
      </DropdownMenuTrigger>

      <DropdownMenuContent sideOffset={5} align="end" side="right">
        <div className="space-y-0.5 p-2">
          <div className="font-medium">{profile.name}</div>
          <div className="truncate text-fg-subtle">{profile.email}</div>
        </div>
        <DropdownMenuSeparator />
        {!props.hideProfileLink && (
          <>
            <DropdownMenuItem data-pw="profile" onSelect={() => navigate({ to: "/profile" })}>
              <div className="flex items-center gap-1">
                <IconUser size={16} stroke={1.5} />
                Your profile
              </div>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
          </>
        )}
        <DropdownMenuItem data-pw="logoutButton" onSelect={props.onLogOut}>
          <div className="flex items-center gap-1">
            <IconLogout size={16} stroke={1.5} />
            Log out
          </div>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
