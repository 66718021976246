import { UpgradeAccountRequest } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { CheckCircledIcon, Cross1Icon } from "@radix-ui/react-icons"
import { Button, DialogClose, DialogContent, FormControlLabel, TextField, toast } from "@vindral/components"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { trpc } from "../../trpc"

const resolver = zodResolver(UpgradeAccountRequest)

export type UpgradeAccountDialogProps = {
  email: string
  organizationId: string
}

const DialogHeader = ({ onClose }: { onClose?: () => void }) => (
  <div className="flex justify-between">
    <span className="font-medium">Upgrade</span>
    <DialogClose className="cursor-pointer" onClick={onClose}>
      <Cross1Icon />
    </DialogClose>
  </div>
)

const UpgradeForm = ({
  email,
  organizationId,
  onSubmitSuccessful,
}: UpgradeAccountDialogProps & { onSubmitSuccessful: () => void }) => {
  const upgradeAccountRequest = trpc.support.updgradeAccountRequest.useMutation({
    onSuccess: () => {
      onSubmitSuccessful()
      toast({ title: "Account upgrade requested!" })
    },
    onError: (error) => {
      toast({ title: "Failed to request account upgrade!", description: error.message })
    },
  })

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<UpgradeAccountRequest>({
    resolver,
    mode: "onChange",
    defaultValues: {
      useCase: "",
      peakViewers: "",
      geographic: "",
      streamingFrequency: "",
      channelCount: "",
      email,
      organizationId,
    },
  })

  const CheckMarkText = ({ text }: { text: string }) => (
    <span className="flex gap-2 align-middle leading-4">
      <CheckCircledIcon className="h-4 w-4 pt-0 text-solid-positive" />
      {text}
    </span>
  )

  return (
    <form
      onSubmit={handleSubmit((args) => upgradeAccountRequest.mutateAsync(args))}
      className={upgradeAccountRequest.isLoading ? "animate-pulse" : ""}
    >
      <div className="flex flex-col gap-4 p-3 pb-4">
        <DialogHeader />
        <div className="overflow-y-auto">
          <p className="text-fg-subtle">
            Thank you for your interest in upgrading your account. Please summarize your use case briefly and we'll get
            back to you as soon as possible.
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <h2 className="font-medium">What you get</h2>
          <CheckMarkText text="No Limits on viewers" />
          <CheckMarkText text="Custom transcoding profiles" />
          <CheckMarkText text="AV1 Support" />
        </div>
      </div>
      <hr className="border-divider" />
      <div className="flex flex-col gap-2 pt-2">
        <div className="flex flex-col gap-1 px-2">
          <div className="pl-1">
            <FormControlLabel error={errors.useCase?.message} required>
              Use Case
            </FormControlLabel>
          </div>

          <TextField
            {...register("useCase")}
            placeholder="Our use case is..."
            disabled={upgradeAccountRequest.isLoading}
            readOnly={upgradeAccountRequest.isLoading}
            multiline
            invisible
            minRows={1}
            required
            autoFocus
          />
        </div>
        <hr className="border-divider" />
        <div className="flex flex-col gap-1 px-2">
          <div className="pl-1">
            <FormControlLabel error={errors.peakViewers?.message} required>
              Estimated Peak Viewers
            </FormControlLabel>
          </div>

          <TextField
            {...register("peakViewers")}
            placeholder="At this specific event/at this time of the week, we expect to have..."
            disabled={upgradeAccountRequest.isLoading}
            readOnly={upgradeAccountRequest.isLoading}
            multiline
            invisible
            minRows={1}
            required
          />
        </div>
        <hr className="border-divider" />
        <div className="flex flex-col gap-1 px-2">
          <div className="pl-1">
            <FormControlLabel error={errors.geographic?.message} required>
              Viewer Geographic Distribution
            </FormControlLabel>
          </div>

          <TextField
            {...register("geographic")}
            placeholder="We expect most of our viewers to be located in..."
            disabled={upgradeAccountRequest.isLoading}
            readOnly={upgradeAccountRequest.isLoading}
            multiline
            invisible
            minRows={1}
            required
          />
        </div>
        <hr className="border-divider" />
        <div className="flex flex-col gap-1 px-2">
          <div className="pl-1">
            <FormControlLabel error={errors.streamingFrequency?.message} required>
              Streaming Frequency
            </FormControlLabel>
          </div>

          <TextField
            {...register("streamingFrequency")}
            placeholder="24/7 or event based..."
            disabled={upgradeAccountRequest.isLoading}
            readOnly={upgradeAccountRequest.isLoading}
            multiline
            invisible
            minRows={1}
            required
          />
        </div>
        <hr className="border-divider" />
        <div className="flex flex-col gap-1 px-2">
          <div className="pl-1">
            <FormControlLabel error={errors.channelCount?.message} required>
              Channel Count
            </FormControlLabel>
          </div>

          <TextField
            {...register("channelCount")}
            placeholder="Expected number of channels..."
            disabled={upgradeAccountRequest.isLoading}
            readOnly={upgradeAccountRequest.isLoading}
            multiline
            invisible
            minRows={1}
            required
          />
        </div>
      </div>
      <hr className="border-divider" />
      <div className="flex flex-col gap-4 p-3">
        <div className="flex items-center justify-end gap-3">
          <Button disabled={!isValid || upgradeAccountRequest.isLoading} size="small" variant="primary" type="submit">
            Request Full Access
          </Button>
        </div>
      </div>
    </form>
  )
}

const Submitted = ({ organizationId }: { organizationId: string }) => {
  const context = trpc.useContext()

  const onClose = async () => {
    await context.organization.findMany.invalidate()
    await context.organization.findOne.invalidate({ publicId: organizationId })
  }

  return (
    <div className="flex flex-col gap-4 p-3">
      <DialogHeader onClose={onClose} />
      <div className="flex h-12 w-full items-center justify-center">Thank you. We will get back to you shortly.</div>
      <div className="flex justify-end gap-3">
        <DialogClose asChild>
          <Button size="small" variant="tertiary" onClick={onClose}>
            Close
          </Button>
        </DialogClose>
      </div>
    </div>
  )
}

const Content = (props: UpgradeAccountDialogProps) => {
  const [submitted, setSubmitted] = useState(false)
  return submitted ? (
    <Submitted organizationId={props.organizationId} />
  ) : (
    <UpgradeForm onSubmitSuccessful={() => setSubmitted(true)} {...props} />
  )
}

export function UpgradeAccountDialog(props: UpgradeAccountDialogProps) {
  return (
    <DialogContent
      size="small"
      // Prevent closing on click outside
      onInteractOutside={(e) => e.preventDefault()}
    >
      <Content {...props} />
    </DialogContent>
  )
}
