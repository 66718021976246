import { Progress, ProgressProps } from "@vindral/components"

const ProgressWithLabel = ({ color, progress, label }: ProgressProps & { label: string }) => {
  return (
    <div className="flex items-center justify-between gap-2">
      {color ? <Progress color={color} progress={progress} /> : <Progress progress={progress} />}
      <span className="w-10 text-right tabular-nums text-fg-subtle">{label}</span>
    </div>
  )
}

export default ProgressWithLabel
