import { PackagerServerBasicDTO, PageDTO, SiteDTO, UseFulLinksDTO } from "@core-services/dtos"
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query"
import axios from "axios"
import { plainToClass } from "class-transformer"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const applyOptions = (options?: UseQueryOptions): UseQueryOptions<any> => {
  return {
    ...options,
    suspense: options?.suspense ?? true,
  }
}

// Useful links
export const useUsefulLinks = (options?: UseQueryOptions): UseQueryResult<UseFulLinksDTO[], unknown> => {
  return useQuery<UseFulLinksDTO[]>(
    ["links"],
    async () => {
      const { data } = await axios.get<UseFulLinksDTO[]>(`/api/usefullinks`)

      return data
    },
    applyOptions(options)
  )
}

// Sites
export const useQuerySites = (options?: UseQueryOptions): UseQueryResult<SiteDTO[], unknown> => {
  return useQuery<SiteDTO[]>(
    ["sites"],
    async () => {
      const response = await axios.get<PageDTO<SiteDTO>>(`/api/sites?skip=0&take=0&orderBy=code&order=asc`)
      const items = response.data.items
      const instances = plainToClass(SiteDTO, items)
      return instances
    },
    applyOptions(options)
  )
}

// Infrastructure
export const useQueryPackagerServersWithoutAllocations = (
  options?: UseQueryOptions
): UseQueryResult<PackagerServerBasicDTO[], unknown> => {
  return useQuery<PackagerServerBasicDTO[]>(
    ["packagerServersBasic"],
    async () => {
      try {
        const { data } = await axios.get<PackagerServerBasicDTO[]>(
          "/api/infrastructure/packagers-servers?allocations=false"
        )
        return data
      } catch (e) {
        console.error("Could not get basic Packager Servers:", e)
        return []
      }
    },
    applyOptions(options)
  )
}
