import { useAbility } from "@casl/react"
import { Action } from "@core-services/data-types"
import { IconPlus } from "@tabler/icons-react"
import { Link } from "@tanstack/react-router"
import {
  Button,
  Pagination,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from "@vindral/components"
import { AbilityContext, channelSubjectInOrganization, checkIfSuperUser } from "../../acl"
import { Page, PageContainer, PageContent, PageFooter, PageHeader } from "../../atoms/Page"
import { Breadcrumbs } from "../../molecules/Breadcrumbs"
import { ChannelTable, useChannelReactTable, type UseChannelTableProps } from "../../organisms/ChannelTable"
import { EmptyStateMessage } from "../../organisms/EmptyStateMessage"
import { trpc } from "../../trpc"

function CreateChannelButton({ organizationId }: { organizationId?: string }) {
  const { data: organization } = trpc.organization.findOne.useQuery(
    { publicId: organizationId ?? "" },
    { enabled: !!organizationId }
  )
  const ability = useAbility(AbilityContext)

  if (!organizationId && !checkIfSuperUser({ ability })) {
    return null
  }

  if (organizationId && ability.cannot(Action.Create, channelSubjectInOrganization(organizationId))) {
    return null
  }

  const content = (
    <Button disabled={organization?.suspended} variant="primary">
      <IconPlus size={16} />
      Create channel
    </Button>
  )

  return organization?.suspended ? (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>{content}</TooltipTrigger>
        <TooltipPortal>
          <TooltipContent align="start" sideOffset={5} side="bottom">
            Can not create new channels while organization is suspended!
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  ) : (
    <Link to="/channels/create">{content}</Link>
  )
}

export type ChannelListProps = UseChannelTableProps & {
  onClickCreate?: () => void
  saveColumnVisibility: () => void
  resetColumnVisibilityToDefault: () => void
  isStaleData: boolean
  isSuspended?: boolean
  downloadCsvUrl?: string
  totalCount: number
  organizationId?: string
}

export function ChannelList({
  onClickCreate,
  isStaleData,
  isSuspended,
  organizationId,
  totalCount,
  ...props
}: ChannelListProps) {
  const channelTable = useChannelReactTable(props)

  if (props.data.length === 0 && !props.state?.globalFilter && !isStaleData) {
    return (
      <EmptyStateMessage
        onClickCreate={onClickCreate}
        title="There are no channels in this organization yet"
        subTitle="Create your first channel to start streaming."
        subTitleNoCreate={
          isSuspended
            ? "Can not create new channels at the moment as the organization is suspended."
            : "Ask your organization administrator or a channel manager to create a channel."
        }
        createButtonText="Create a channel"
      />
    )
  }

  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
            <CreateChannelButton organizationId={organizationId} />
          </div>
        </PageHeader>
        <PageContainer>
          <div className="py-4">
            <ChannelTable {...props} table={channelTable} />
          </div>
        </PageContainer>
      </PageContent>
      <PageFooter>
        <div className="flex h-16 items-center justify-between">
          <div>
            {totalCount} Channel{totalCount > 1 ? "s" : ""}
          </div>
          <Pagination
            pageIndex={channelTable.getState().pagination.pageIndex}
            pageCount={channelTable.getPageCount()}
            nextPage={() => channelTable.nextPage()}
            previousPage={() => channelTable.previousPage()}
          />
        </div>
      </PageFooter>
    </Page>
  )
}
