import googleTagManager from "@analytics/google-tag-manager"
import Analytics, { AnalyticsInstance } from "analytics"
import { ReactNode, createContext, useContext, useEffect, useState } from "react"
import { trpc } from "../../trpc"

interface GoogleTagManagerProps {
  children: ReactNode
}

interface GoogleTagManagerContextProps {
  analytics: AnalyticsInstance | undefined
}

const GoogleTagManagerContext = createContext<GoogleTagManagerContextProps | undefined>(undefined)

export const useGoogleTagManager = () => {
  const context = useContext(GoogleTagManagerContext)
  if (!context) {
    throw new Error("useGoogleTagManager must be used within a GoogleTagManagerProvider")
  }
  return context
}

export const GoogleTagManagerProvider = ({ children }: GoogleTagManagerProps) => {
  const [analytics, setAnalytics] = useState<AnalyticsInstance | undefined>(undefined)
  const { data: gtmId } = trpc.configuration.getGoogleTagManagerId.useQuery()

  useEffect(() => {
    if (!gtmId) {
      return
    }

    const analytics = Analytics({
      plugins: [googleTagManager({ containerId: gtmId })],
    })
    setAnalytics(analytics)
  }, [gtmId])

  const contextValue: GoogleTagManagerContextProps = {
    analytics,
  }

  return <GoogleTagManagerContext.Provider value={contextValue}>{children}</GoogleTagManagerContext.Provider>
}
