import { Expose } from "class-transformer"

export class ThumbnailDTO {
  @Expose()
  baseUrl?: string

  @Expose()
  sizes!: Array<{
    width: number
    height: number
  }>
}
