import { GlobeEuropeAfricaIcon, MapPinIcon, TvIcon } from "@heroicons/react/24/outline"
import { ChevronDownIcon, Cross2Icon } from "@radix-ui/react-icons"
import { Outlet, useNavigate } from "@tanstack/react-router"
import {
  Button,
  Combobox,
  ComboboxContent,
  ComboboxPortal,
  ComboboxTrigger,
  Divider,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@vindral/components"
import { useEffect, useMemo, useState } from "react"
import { Page, PageContainer, PageContent, PageHeader } from "../../atoms/Page"
import { useChannelSelect } from "../../hooks/useChannelSelect"
import { Breadcrumbs } from "../../molecules/Breadcrumbs"
import { RouteTabs, RouteTrigger } from "../../molecules/RouteTabs"
import { SingleSelectCombobox } from "../../molecules/SingleSelectCombobox"
import { trpc } from "../../trpc"
import { DateRangeSelect } from "./DateRangeSelect"
import { ExportButton } from "./ExportButton"
import { AnalyticsDateRange, getDateRangeSeconds } from "./dateRanges"

const SELECT_ALL = "SELECT_ALL"
const defaultComboOption = { id: SELECT_ALL, name: "All" }
const selectAllChannel = { name: "All", channelId: SELECT_ALL, streamKey: "" }

export type AnalyticsQueryArgs = {
  time: { from: number; to: number; subFrom?: number; subTo?: number }
  country?: string
  streamKey?: string
  continent?: string
  organizationId?: string
}

type AnalyticsProps = {
  defaultChannel?: { name: string; channelId: string; streamKey: string }
  dateRange: AnalyticsDateRange
  country?: string
  continent?: string
  organizationId?: string
}

export const Analytics = ({
  dateRange,
  country,
  continent,
  organizationId,
  defaultChannel,
}: AnalyticsProps): JSX.Element => {
  const navigate = useNavigate()

  const [selectedDateOption, setSelectedDateOption] = useState<AnalyticsDateRange>(dateRange)
  const [selectedCountry, setSelectedCountry] = useState(country ? { id: country, name: country } : defaultComboOption)
  const [selectedContinent, setSelectedContinent] = useState(continent || SELECT_ALL)
  const [countryListOpen, setCountryListOpen] = useState(false)
  const [countryQuery, setCountryQuery] = useState("")

  const channelSelect = useChannelSelect({ organizationId, defaultSelected: defaultChannel || selectAllChannel })

  const clearQueryArgs = () => {
    setSelectedCountry(defaultComboOption)
    setSelectedContinent(SELECT_ALL)
    channelSelect.setSelected(selectAllChannel)
  }

  const queryArguments = useMemo(() => {
    const timeRange =
      selectedDateOption.type === "quick"
        ? { dateRange: selectedDateOption.range }
        : { from: selectedDateOption.range.from.getTime(), to: selectedDateOption.range.to.getTime() }

    return {
      dateRange: undefined,
      from: undefined,
      to: undefined,
      ...timeRange,
      country: selectedCountry.id === SELECT_ALL ? undefined : selectedCountry.id,
      streamKey: channelSelect.selected?.channelId === SELECT_ALL ? undefined : channelSelect?.selected?.streamKey,
      channelId: channelSelect.selected?.channelId === SELECT_ALL ? undefined : channelSelect?.selected?.channelId,
      continent: selectedContinent === SELECT_ALL ? undefined : selectedContinent,
      organizationId,
    }
  }, [selectedCountry, selectedContinent, channelSelect.selected, organizationId, selectedDateOption])

  useEffect(() => {
    void navigate({ search: (prev) => ({ ...prev, ...queryArguments }) })
  }, [queryArguments, navigate])

  const { data: continents = [] } = trpc.analytics.labelsWithViewers.useQuery(
    { organizationId, time: getDateRangeSeconds(dateRange), label: "continent" },
    { keepPreviousData: true }
  )
  const { data: countries = [], isFetching: isFetchingCountries } = trpc.analytics.labelsWithViewers.useQuery(
    {
      organizationId,
      time: getDateRangeSeconds(dateRange),
      continent: queryArguments.continent,
      label: "country",
    },
    { keepPreviousData: true }
  )

  // Reset country select if selected country is no longer available
  useEffect(() => {
    if (
      !isFetchingCountries &&
      !countries
        .filter((country) => country != null)
        .map((country) => country.toLowerCase())
        .includes(selectedCountry.name.toLowerCase())
    ) {
      setSelectedCountry(defaultComboOption)
    }
  }, [countries, setSelectedCountry, selectedCountry, isFetchingCountries])

  // Insert default option first in channel list
  const channelList = useMemo(() => {
    if (!channelSelect.options[0]) {
      return [{ id: "emptyChannels", items: [selectAllChannel] }]
    }
    return [
      {
        ...channelSelect.options[0],
        items: [selectAllChannel, ...channelSelect.options[0].items],
      },
    ]
  }, [channelSelect.options])

  const filteredCountries = countries
    .filter((country) => country != null)
    .filter((country) => country.toLowerCase().includes(countryQuery.toLowerCase()))
    .sort()

  return (
    <div className="overflow-auto">
      <Page>
        <PageContent>
          <PageHeader>
            <div className="flex h-16 items-center justify-between">
              <Breadcrumbs />
              <ExportButton {...queryArguments} time={getDateRangeSeconds(dateRange)} />
            </div>
            <div className="flex gap-2 py-4">
              <DateRangeSelect defaultValue={dateRange} onSelect={(value) => setSelectedDateOption(value)} />
              <Divider variant="vertical" />
              <SingleSelectCombobox
                renderTriggerContent={() => (
                  <>
                    <TvIcon className="h-4 w-4" />
                    <span className="font-normal text-fg-subtle">Channel</span>
                    <span className="truncate max-2xl:max-w-[120px]">{channelSelect.selected?.name}</span>
                  </>
                )}
                options={channelList}
                selected={channelSelect.selected}
                search={channelSelect.search}
                isLoading={channelSelect.query.isLoading}
                onSearchChange={channelSelect.setSearch}
                placeholder="All"
                onSelect={(selected) => {
                  channelSelect.setSelected(selected)
                }}
                onReachedEnd={() => {
                  if (!channelSelect.query.hasNextPage) {
                    return
                  }
                  void channelSelect.query.fetchNextPage()
                }}
              />
              <Select
                value={selectedContinent}
                defaultValue={SELECT_ALL}
                onValueChange={(value) => setSelectedContinent(value)}
              >
                <SelectTrigger size="medium">
                  <GlobeEuropeAfricaIcon className="h-4 w-4" />
                  <span className="font-normal text-fg-subtle">Region</span>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value={SELECT_ALL}>All</SelectItem>
                  {continents.sort().map((continent) => (
                    <SelectItem key={continent} value={continent}>
                      <div className="capitalize">{continent}</div>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Combobox onOpenChange={setCountryListOpen} open={countryListOpen}>
                <ComboboxTrigger onClick={() => setCountryListOpen(true)} asChild>
                  <Button style={{ transform: "none" }}>
                    <MapPinIcon className="h-4 w-4" />
                    <span className="font-normal text-fg-subtle">Country</span>
                    <span className="truncate font-normal capitalize max-2xl:max-w-[120px]">
                      {selectedCountry.name}
                    </span>
                    <ChevronDownIcon className="h-4 w-4" />
                  </Button>
                </ComboboxTrigger>
                <ComboboxPortal>
                  <ComboboxContent
                    popoverContentProps={{
                      align: "start",
                      side: "bottom",
                    }}
                    groups={[
                      {
                        id: 1,
                        title: "",
                        items: [
                          defaultComboOption,
                          ...filteredCountries.map((country) => ({ id: country, name: country })),
                        ],
                      },
                    ]}
                    selected={[]}
                    multiple={true}
                    query={countryQuery}
                    onQueryChange={setCountryQuery}
                    onSelect={([country]) => {
                      setSelectedCountry(country || defaultComboOption)
                      setCountryListOpen(false)
                    }}
                    getId={(item) => item.id}
                    renderTitle={(title) => title}
                    renderItem={(item) => <div key={item.id}>{item.name}</div>}
                  />
                </ComboboxPortal>
              </Combobox>
              <Button onClick={clearQueryArgs}>
                <Cross2Icon className="h-4 w-4" />
                <span className="font-normal">Clear</span>
              </Button>
            </div>
            <div>
              <RouteTabs border="none">
                <RouteTrigger to="/analytics/overview" params={{ ...queryArguments }}>
                  Overview
                </RouteTrigger>
                <RouteTrigger to="/analytics/sessions" params={{ ...queryArguments }}>
                  Sessions & Viewer Time
                </RouteTrigger>
                <RouteTrigger to="/analytics/traffic" params={{ ...queryArguments }}>
                  Traffic
                </RouteTrigger>
                <RouteTrigger to="/analytics/geography" params={{ ...queryArguments }}>
                  Geography
                </RouteTrigger>
                <RouteTrigger to="/analytics/devices" params={{ ...queryArguments }}>
                  Devices
                </RouteTrigger>
              </RouteTabs>
            </div>
          </PageHeader>
          <PageContainer>
            <Outlet />
          </PageContainer>
        </PageContent>
      </Page>
    </div>
  )
}
