import type { VideoSize } from "@core-services/portal"

export interface VideoSizeProps {
  size?: VideoSize
}

export function VideoSize({ size }: VideoSizeProps) {
  if (!size) {
    return <span className="tabular-nums">Match source</span>
  }

  if ("width" in size && "height" in size) {
    return (
      <span className="tabular-nums">
        {size.width}x{size.height}
      </span>
    )
  }

  if ("width" in size) {
    return <span className="tabular-nums">{size.width}px wide (match aspect ratio)</span>
  }

  if ("scaleFactor" in size) {
    return <span className="tabular-nums">{size.scaleFactor * 100}% of source size</span>
  }

  return <span className="tabular-nums">Match source</span>
}
