export const integerOrNull = (value: string) => {
  if (value === undefined || value === null || value === "") {
    return null
  }

  const valueAsNumber = Number(value)
  if (isNaN(valueAsNumber)) {
    return value
  }

  if (!Number.isInteger(valueAsNumber)) {
    return value
  }

  return valueAsNumber
}

export const floatOrNull = (value: string) => {
  if (value === undefined || value === null || value === "") {
    return null
  }

  const valueAsNumber = Number(value)
  if (isNaN(valueAsNumber)) {
    return value
  }

  return valueAsNumber
}
