import { Tooltip, TooltipContent, TooltipTrigger } from "@vindral/components"
import { formatDistance, formatDuration, intervalToDuration } from "date-fns"
import { useMemo } from "react"

export interface FormattedDurationProps {
  startDate: Date
  endDate: Date
}

export function FormattedDuration(props: FormattedDurationProps) {
  const date = useMemo(() => {
    return {
      formatted: formatDuration(intervalToDuration({ start: props.startDate, end: props.endDate })),
      distance: formatDistance(props.endDate, props.startDate),
    }
  }, [props.startDate, props.endDate])

  return (
    <Tooltip>
      <TooltipTrigger asChild className="cursor-default">
        <div>{date.distance}</div>
      </TooltipTrigger>
      <TooltipContent>{date.formatted}</TooltipContent>
    </Tooltip>
  )
}
