import { Exclude, Expose, Transform, Type } from "class-transformer"
import { AccessPermissionsDTO } from "../access-permissions/access-permissions.dto"
import { transformDate } from "../transforms"

export class UserDTO {
  static readonly modelName = "User"

  @Expose()
  publicId!: string

  @Expose()
  name!: string

  @Expose()
  email!: string

  @Expose()
  id!: number

  @Exclude()
  password?: string

  @Expose()
  @Type(() => AccessPermissionsDTO)
  accessPermissions?: AccessPermissionsDTO[]

  @Expose()
  @Transform(transformDate)
  lastLoginAt!: Date | null

  @Expose()
  @Transform(transformDate)
  createdAt!: Date

  @Expose()
  @Transform(transformDate)
  updatedAt!: Date
}
