import { RegisterUser } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { Button, Checkbox, FormControl, FormControlLabel, TextField } from "@vindral/components"
import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Hyperlink } from "../../atoms/Hyperlink"
import { PasswordTextField } from "../../molecules/PasswordTextField"
import Form from "../../organisms/Form"
import { ReCaptchaText } from "../../organisms/ReCaptchaText"

const resolver = zodResolver(RegisterUser)

export interface RegisterProps {
  registerUser: (value: RegisterUser) => Promise<void>
  email: string
  emailVerificationToken?: string
  inviteToken?: string
}

export const Register = (props: RegisterProps) => {
  const { registerUser, ...defaultValues } = props
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<RegisterUser>({
    resolver,
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
      newsletterSignUp: false,
    },
  })
  const [agreedTerms, setAgreedTerms] = useState(false)

  return (
    <Form onSubmit={handleSubmit(registerUser)} className="flex max-w-md flex-col gap-4 py-4" autoComplete="on">
      <div>
        <span>Email address</span>
        <h1 className="font-medium underline">{defaultValues.email}</h1>
      </div>
      <FormControl>
        <FormControlLabel error={errors.name?.message} required>
          Name
        </FormControlLabel>
        <TextField {...register("name")} autoComplete="given-name" data-pw="name" />
      </FormControl>
      <FormControl>
        <FormControlLabel error={errors.password?.message} required>
          Password
        </FormControlLabel>
        <PasswordTextField
          {...register("password")}
          error={!!errors.password}
          autoComplete="new-password"
          data-pw="password"
        />
        <ReCaptchaText />
      </FormControl>
      <FormControl>
        <div className="flex gap-2">
          <Checkbox checked={agreedTerms} onCheckedChange={(checked) => setAgreedTerms(checked === true)} />
          <span>
            I have read and agree to the <Hyperlink href="https://vindral.com/eula" text="EULA" /> and{" "}
            <Hyperlink href="https://vindral.com/privacy-policy" text="Privacy Policy" />
          </span>
        </div>
      </FormControl>
      <Controller
        name="newsletterSignUp"
        control={control}
        render={({ field }) => (
          <div className="flex gap-2">
            <Checkbox checked={field.value} onCheckedChange={field.onChange} />
            <span>Sign up for our newsletter</span>
          </div>
        )}
      />
      <div>
        <Button disabled={!isDirty || !isValid || !agreedTerms} type="submit" variant="primary">
          Sign up
        </Button>
      </div>
    </Form>
  )
}
