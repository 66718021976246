import { AppAbility, OrganizationFindOne } from "@core-services/portal"
import { OrganizationEditPage } from "../../organisms/OrganizationEditPage"

interface OrganizationEditProps {
  organization: OrganizationFindOne
  ability: AppAbility
  onDelete: () => Promise<void>
}

export function OrganizationEdit(props: OrganizationEditProps) {
  return <OrganizationEditPage path="/organizations/edit/$publicId" {...props} />
}
