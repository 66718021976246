import * as Sentry from "@sentry/react"
import { Outlet, RootRoute } from "@tanstack/react-router"
import { lazy, useEffect } from "react"
import { sentryEnabled, showDevtools } from "../config"
import { trpc } from "../trpc"

const TanStackRouterDevtools = showDevtools
  ? lazy(() =>
      import("@tanstack/react-router-devtools").then((res) => ({
        default: res.TanStackRouterDevtools,
      }))
    )
  : () => null

const SetSentryRelease = () => {
  const { data: vindralVersion } = trpc.configuration.getVindralVersion.useQuery()

  useEffect(() => {
    if (vindralVersion) {
      Sentry.configureScope((scope) =>
        scope.addEventProcessor((event) => ({
          ...event,
          release: vindralVersion,
        }))
      )
    }
  }, [vindralVersion])

  return null
}

function Root() {
  return (
    <>
      <TanStackRouterDevtools />
      <Outlet />
      {sentryEnabled && <SetSentryRelease />}
    </>
  )
}

export const rootRoute = new RootRoute({
  component: Root,
})
