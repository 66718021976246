import { Expose, Type } from "class-transformer"

export abstract class SizeDTO {}

export class AbsoluteSizeDTO extends SizeDTO {
  @Expose()
  width?: number

  @Expose()
  height?: number
}

export class ScaleFactorSizeDTO extends SizeDTO {
  @Expose()
  factor!: number
}

export class AutoSizeDTO extends SizeDTO {}

export abstract class RenditionDTO {
  @Expose()
  id!: string

  @Expose()
  bitRate!: number

  @Expose()
  codec!: string
}

export class VideoRenditionDTO extends RenditionDTO {
  @Expose()
  gopSizeMs!: number

  @Expose()
  @Type(() => SizeDTO, {
    discriminator: {
      property: "type",
      subTypes: [
        { value: AbsoluteSizeDTO, name: "absolute" },
        { value: ScaleFactorSizeDTO, name: "scale-factor" },
        { value: AutoSizeDTO, name: "auto" },
      ],
    },
  })
  size!: AbsoluteSizeDTO | ScaleFactorSizeDTO
}

export class AudioRenditionDTO extends RenditionDTO {
  @Expose()
  channelsLayout?: "mono" | "stereo"

  @Expose()
  sampleRate!: number
}

export class StreamDTO {
  @Expose()
  id!: string

  @Expose()
  passthrough!: boolean

  @Expose()
  streamId?: number

  @Expose()
  stream?: "best" | "all" | number
}

export class VideoStreamDTO extends StreamDTO {
  @Expose()
  type!: "video"

  @Expose()
  gopAlignment?: "match_incoming"

  @Expose()
  @Type(() => VideoRenditionDTO)
  renditions!: VideoRenditionDTO[]
}

export class AudioStreamDTO extends StreamDTO {
  @Expose()
  type!: "audio"

  @Expose()
  @Type(() => AudioRenditionDTO)
  renditions!: AudioRenditionDTO[]
}

export class TranscodingProfileDTO {
  @Expose()
  name!: string

  @Expose()
  @Type(() => StreamDTO, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: "type",
      subTypes: [
        { value: VideoStreamDTO, name: "video" },
        { value: AudioStreamDTO, name: "audio" },
      ],
    },
  })
  streams!: (VideoStreamDTO | AudioStreamDTO)[]
}
