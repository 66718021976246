import { Route, useNavigate, useSearch } from "@tanstack/react-router"
import { PaginationState, SortingState, VisibilityState } from "@tanstack/react-table"
import { useToast } from "@vindral/components"
import { useEffect, useState } from "react"
import { useAsyncFn, useLocalStorage } from "react-use"
import { z } from "zod"
import { packagerServerIndexRoute } from "."
import { PackagerServerList } from "../../templates/infrastructure/packager-servers/PackagerServerList"
import { trpc } from "../../trpc"

const defaultColumns = {
  nodeHostname: true,
  hardwareAcceleration: true,
  features: true,
  allocated: true,
  packagerCount: true,
}

const params = z.object({
  packagerServerListView: z
    .object({
      sorting: z.array(
        z.object({
          id: z.enum(["nodeHostname", "hardwareAcceleration", "features", "allocated", "packagerCount"]),
          desc: z.boolean(),
        })
      ),
      pagination: z.object({
        pageIndex: z.number().nonnegative(),
        pageSize: z.number().nonnegative(),
      }),
      search: z.string().default(""),
    })
    .catch({
      sorting: [{ desc: false, id: "nodeHostname" }],
      pagination: { pageIndex: 0, pageSize: 100 },
      search: "",
    }),
})

export const packagerServerListRoute = new Route({
  path: "/",
  getParentRoute: () => packagerServerIndexRoute,
  validateSearch: params,
  onLoad: async ({ context: { trpcContext } }) => {
    await trpcContext.infrastructure.getPackagerServers.ensureData(undefined, { staleTime: 1000 })
  },
  preSearchFilters: [(search) => search],
  component: List,
})

function List() {
  const searchParams = useSearch({ from: packagerServerListRoute.id })
  const navigate = useNavigate({ from: packagerServerListRoute.id })
  const { toast } = useToast()

  const { data } = trpc.infrastructure.getPackagerServers.useQuery(undefined, {
    staleTime: 1000,
  })
  const [sorting, setSorting] = useState<SortingState>(searchParams.packagerServerListView.sorting)
  const [pagination, setPagination] = useState<PaginationState>(searchParams.packagerServerListView.pagination)
  const [globalFilter, setGlobalFilter] = useState(searchParams.packagerServerListView.search)
  const [value, setValue, remove] = useLocalStorage<VisibilityState>("packager-server-list-columns", defaultColumns)
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(value ?? defaultColumns)
  const pageCount = Math.ceil((data?.length ?? 0) / pagination.pageSize)
  const [navigationState, doNavigate] = useAsyncFn(
    async (...args: Parameters<typeof navigate>) => {
      return navigate(...args)
    },
    [navigate]
  )

  if (globalFilter !== searchParams.packagerServerListView.search && pagination.pageIndex !== 0) {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }))
  }

  useEffect(() => {
    void doNavigate({
      from: packagerServerListRoute.id,
      to: "/packager-servers",
      params: {},
      search: (prev) => {
        return { ...prev, packagerServerListView: { sorting, pagination, search: globalFilter } }
      },
    })
  }, [doNavigate, sorting, pagination, globalFilter])

  const filteredPackagers = globalFilter
    ? data?.filter(
        (e) =>
          e.nodeHostname.toLocaleLowerCase().includes(globalFilter.toLowerCase()) ||
          e.packagers.map((p) => p.streamKey).filter((s) => s.includes(globalFilter.toLocaleLowerCase())).length != 0 ||
          e.hardwareAcceleration.filter((x) => x.includes(globalFilter.toLocaleLowerCase())).length != 0 ||
          e.features.filter((x) => x.includes(globalFilter.toLocaleLowerCase())).length != 0 ||
          e.status.toLocaleLowerCase().includes(globalFilter.toLowerCase())
      )
    : data

  const packagerCpuUsed = filteredPackagers
    ? filteredPackagers
        .filter((p) => p.status === "ok")
        .map((p) => p.cpuAllocatedPackager)
        .reduce((prev, curr) => prev + curr, 0)
    : 0

  const packagerCpuFree = filteredPackagers
    ? filteredPackagers
        .filter((p) => p.status === "ok")
        .map((p) => p.cpuAvailableTotal)
        .reduce((prev, curr) => prev + curr, 0) -
      filteredPackagers
        .filter((p) => p.status === "ok")
        .map((p) => p.cpuAllocatedTotal)
        .reduce((prev, curr) => prev + curr, 0)
    : 0

  return (
    <PackagerServerList
      packagerCpuUsed={packagerCpuUsed}
      packagerCpuFree={packagerCpuFree}
      globalFilter={globalFilter}
      data={filteredPackagers ?? []}
      totalCount={data?.length ?? 0}
      manualFiltering
      onPaginationChange={setPagination}
      pageCount={pageCount}
      isStaleData={navigationState.loading || globalFilter !== searchParams.packagerServerListView.search}
      state={{ pagination, sorting, globalFilter, columnVisibility }}
      onSortingChange={setSorting}
      onGlobalFilterChange={setGlobalFilter}
      onColumnVisibilityChange={setColumnVisibility}
      saveColumnVisibility={() => {
        setValue(columnVisibility)
        toast({ title: "Saved configuration" })
      }}
      resetColumnVisibilityToDefault={() => {
        remove()
        setColumnVisibility(defaultColumns)
        toast({ title: "Restored to default" })
      }}
    />
  )
}
