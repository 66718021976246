import { z } from "zod"
import { CountryCodeLimitation, NumberLimitation } from "../limitable"

export const UpdateSite = z.object({
  publicId: z.string(),
  name: z.string().min(1).max(100).optional(),
  latitude: z.number().min(-90).max(90).optional(),
  longitude: z.number().min(-180).max(180).optional(),
  viewerTrafficActive: z.boolean().optional(),
  edgeEgressActive: z.boolean().optional(),
  edgeOriginActive: z.boolean().optional(),
  mediaIngressActive: z.boolean().optional(),
  providers: z.string().nullish(),
  streetAddress: z.string().nullish(),
  city: z.string().nullish(),
  zipcode: z.string().nullish(),
  country: z.string().nullish(),
  notes: z.string().max(500).optional(),
  regionPublicId: z.string().optional(),
  maxViewers: z.number().int().positive().nullish(),
  maxCapacity: z.number().int().positive().nullish(),
  asnLimitation: NumberLimitation.optional(),
  organizationLimitation: NumberLimitation.optional(),
  channelLimitation: NumberLimitation.optional(),
  countryLimitation: CountryCodeLimitation.optional(),
  transcodingActive: z.boolean().optional(),
  transcodingPriority: z.array(z.string()).optional(),
})

export type UpdateSite = z.infer<typeof UpdateSite>
