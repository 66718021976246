import { Action } from "@core-services/data-types"
import { AppAbility, UpdateChannel, normalizeIdentifier } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { GearIcon } from "@radix-ui/react-icons"
import { Link } from "@tanstack/react-router"
import { Button, FormControl, FormControlLabel, Switch, TextField } from "@vindral/components"
import { useEffect } from "react"
import { useController, useForm } from "react-hook-form"
import { channelSubjectInOrganization, checkIfSuperUser } from "../../../acl"
import { FormSection } from "../../../atoms/FormSection"
import { SaveButton } from "../../../atoms/SaveButton"
import { SaveSection } from "../../../atoms/SaveSection"
import { useTranscodingProfileSelect } from "../../../hooks/useTranscodingProfileSelect"
import { Message, MessageSection } from "../../../molecules/Message"
import { RouteBlockDialog } from "../../../molecules/RouteBlockDialog"
import { SingleSelectCombobox } from "../../../molecules/SingleSelectCombobox"
import { ChannelFindById } from "../ChannelEdit"

const resolver = zodResolver(UpdateChannel)

export interface ChannelEditDetailsGeneralProps {
  ability: AppAbility
  channel: ChannelFindById
  onSave: (channel: UpdateChannel) => Promise<void>
}

export function ChannelEditDetailsGeneral({ ability, channel, onSave }: ChannelEditDetailsGeneralProps) {
  const subject = channelSubjectInOrganization(channel.organization.publicId)
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid, isSubmitting, isSubmitSuccessful },
    control,
    reset,
    watch,
  } = useForm({
    resolver,
    mode: "onChange",
    defaultValues: {
      channelId: channel.channelId,
      name: channel.name,
      notes: channel.notes,
      suspended: channel.suspended,
      authRequired: channel.authRequired,
      transcodingProfileId: channel.transcodingProfile?.publicId,
      transcodingMultiplier: channel.transcodingMultiplier,
    },
  })
  const name = watch("name")

  const transcodingProfileSelect = useTranscodingProfileSelect({
    organizationId: channel.organization.publicId,
    defaultSelected: channel.transcodingProfile,
  })
  const transcodingProfileController = useController({ control, name: "transcodingProfileId" })

  const isSuperUser = checkIfSuperUser({ ability })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful, reset])

  const submit = async (channel: UpdateChannel) => {
    const data = {
      ...channel,
      transcodingProfileId: channel.transcodingProfileId === undefined ? null : channel.transcodingProfileId,
    }
    await onSave(data)
  }

  return (
    <div className="py-4">
      <RouteBlockDialog condition={isDirty && !isSubmitting} />
      <form onSubmit={handleSubmit(submit)} className="flex flex-col gap-8">
        <FormSection title="General Information" description="Change general settings.">
          <div className="flex flex-col gap-4">
            <FormControl>
              <FormControlLabel required error={errors.name?.message}>
                Name
              </FormControlLabel>
              <TextField
                {...register("name")}
                error={!!errors?.name}
                disabled={ability.cannot(Action.Update, subject, "name")}
              />
              <div className="mt-1 flex h-2 w-full items-center">
                {name && (
                  <div className="text-xs text-gray-600 dark:text-gray-300">
                    Normalized name: <span className="font-semibold">{normalizeIdentifier(name)}</span>
                  </div>
                )}
              </div>
            </FormControl>
            <FormControl>
              <FormControlLabel error={errors.notes?.message}>Notes</FormControlLabel>
              <TextField
                {...register("notes")}
                multiline={true}
                minRows={3}
                error={!!errors.notes}
                disabled={ability.cannot(Action.Update, subject, "notes")}
              />
            </FormControl>
            {isSuperUser && (
              <Message severity="error">
                <MessageSection>
                  <div className="flex w-full">
                    <div className="grow">
                      <h1 className="mb-1 font-medium">Suspend</h1>
                      <span>Suspending a channel will block any future ingest.</span>
                    </div>
                    <Switch
                      defaultChecked={channel.suspended}
                      disabled={ability.cannot(Action.Update, subject, "suspended")}
                      onCheckedChange={(value) => {
                        setValue("suspended", value, { shouldDirty: true })
                      }}
                      label=""
                      variant="danger"
                    />
                  </div>
                </MessageSection>
              </Message>
            )}
          </div>
        </FormSection>

        <FormSection
          title="Transcoding"
          description="The transcoding profile specifies quality and resolution of the transcoded audio and video streams sent to clients. Contact us if you have custom needs."
          docsLink="https://docs.vindral.com/broadcast/transcoding"
        >
          <div className="flex w-full flex-col gap-1">
            <FormControlLabel error={transcodingProfileController.fieldState.error?.message}>
              Transcoding Profile
            </FormControlLabel>
            <div className="flex items-center gap-1">
              <SingleSelectCombobox
                options={transcodingProfileSelect.options}
                selected={transcodingProfileSelect.selected}
                search={transcodingProfileSelect.search}
                isLoading={transcodingProfileSelect.query.isLoading}
                onSearchChange={transcodingProfileSelect.setSearch}
                disabled={ability.cannot(Action.Update, subject, "transcodingProfileId")}
                disabledText={transcodingProfileSelect.selected?.name}
                dataPwButton="transcodingProfileSelector"
                dataPwValue="transcodingProfileSelectorValue"
                placeholder="Select a profile"
                onSelect={(selected) => {
                  transcodingProfileSelect.setSelected(selected)
                  transcodingProfileController.field.onChange(selected.publicId)
                }}
                onReachedEnd={() => {
                  if (!transcodingProfileSelect.query.hasNextPage) {
                    return
                  }
                  void transcodingProfileSelect.query.fetchNextPage()
                }}
              />
              {transcodingProfileSelect.selected && (
                <Link
                  to="/transcoding-profiles/edit/$transcodingProfileId"
                  params={{ transcodingProfileId: transcodingProfileSelect.selected?.publicId }}
                >
                  <Button>
                    <GearIcon />
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </FormSection>

        <FormSection
          title="Authentication"
          description="Add additional security to your channel with authentication."
          docsLink="https://docs.vindral.com/manage/advanced-authorization"
        >
          <div className="flex max-w-md flex-col gap-4">
            <div className="flex flex-col gap-4">
              <div className="flex w-full">
                <Switch
                  defaultChecked={channel.authRequired}
                  disabled={ability.cannot(Action.Update, subject, "authRequired")}
                  onCheckedChange={(value) => {
                    setValue("authRequired", value, { shouldDirty: true })
                  }}
                  label="Require authentication"
                />
              </div>
            </div>
          </div>
        </FormSection>
        <SaveSection>
          {ability.can(Action.Update, subject) && <SaveButton text="Save" disabled={!isDirty || !isValid} />}
        </SaveSection>
      </form>
    </div>
  )
}
