import { ExclamationTriangleIcon, LockClosedIcon, Pencil2Icon } from "@radix-ui/react-icons"
import { IconAdjustmentsFilled, IconDownload, IconSortAscending, IconSortDescending } from "@tabler/icons-react"
import { Link } from "@tanstack/react-router"
import {
  TableOptions,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import {
  Button,
  Chip,
  Copyable,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSwitchItem,
  DropdownMenuTrigger,
} from "@vindral/components"
import { ReactNode, useMemo } from "react"
import { ChannelPreview } from "../atoms/ChannelPreview"
import { ChannelItem } from "../interfaces/Channel"
import { DebouncedTextField } from "../molecules/DebouncedTextField"
import { FormattedDate } from "../molecules/FormattedDate"
import { playUrl } from "../utils/urls"
import { InteractiveTable } from "./InteractiveTable"
import { NoResultsFound } from "./NoResultsFound"

interface LastActiveProps {
  ingestSession?: { startedAt: string; stoppedAt: string | null }
}

function LastActive({ ingestSession }: LastActiveProps) {
  if (!ingestSession) {
    return <div className="text-fg-extra-subtle">Never</div>
  }

  return (
    <span className="whitespace-nowrap text-fg-subtle">
      <FormattedDate date={new Date(ingestSession.stoppedAt ?? Date.now())} />
    </span>
  )
}

function columnNames(id: string) {
  switch (id) {
    case "organization.name":
      return "Organization"
    case "ingestSessions.updatedAt":
      return "Last Active"
    case "name":
      return "Name"
    case "notes":
      return "Notes"
    case "createdAt":
      return "Created"
    case "thumbnail":
      return "Thumbnail"
    case "settings":
      return "Settings"
    case "streamKey":
      return "Stream Key"
    case "channelId":
      return "Channel ID"
    case "actions":
      return "Actions"
    case "status":
      return "Status"
  }

  return id
}

const isChannelLimited = (channel: ChannelItem): boolean => {
  if (channel.viewerCountryLimitation.mode !== "disabled") {
    if (channel.viewerCountryLimitation.mode !== "deny" || channel.viewerCountryLimitation.limited.length > 0) {
      return true
    }
  }

  if (channel.siteLimitation && channel.siteLimitation.mode !== "disabled") {
    if (channel.siteLimitation.mode !== "deny" || channel.siteLimitation.limited.length > 0) {
      return true
    }
  }

  return false
}

function ChannelLink({ channelId, children }: { channelId: string; children: ReactNode }) {
  return (
    <Link
      to="/channels/edit/$channelId/details"
      className="whitespace-nowrap font-semibold"
      params={{ channelId }}
      search={(search) => ({ ...search })}
    >
      {children}
    </Link>
  )
}

export type UseChannelTableProps = Omit<
  TableOptions<ChannelItem>,
  "columns" | "getCoreRowModel" | "getSortedRowModel" | "getPaginationRowModel"
> & {
  noMetricsEdge?: string
}

export function useChannelReactTable(options: UseChannelTableProps) {
  const { noMetricsEdge } = options

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<ChannelItem>()

    return [
      columnHelper.display({
        id: "status",
        header: () => "Status",
        cell: (info) => (
          <div>
            <a
              className="block w-[72px]"
              href={playUrl({ ...info.row.original, edgeUrl: noMetricsEdge })}
              target="_blank"
              data-pw="playLink"
            >
              <ChannelPreview channel={info.row.original} />
            </a>
          </div>
        ),
        size: 1,
      }),
      columnHelper.accessor("name", {
        header: () => "Name",
        cell: (info) => (
          <div>
            <ChannelLink channelId={info.row.original.channelId}>{info.getValue()}</ChannelLink>
            <span className="block text-fg-subtle">{info.row.original.notes || ""}</span>
          </div>
        ),
        maxSize: 300,
      }),
      columnHelper.accessor("notes", {
        meta: { isLogical: true },
      }),
      columnHelper.accessor("organization.name", {
        id: "organization.name",
        header: () => "Organization",
        cell: (info) => (
          <span className="font-semibold">
            <Link to="/organizations/edit/$publicId" params={{ publicId: info.row.original.organization.publicId }}>
              {info.getValue()}
            </Link>
          </span>
        ),
        maxSize: 300,
      }),

      columnHelper.accessor("streamKey", {
        header: () => "Stream Key",
        cell: (info) => (
          <Copyable text={info.getValue()}>
            <span className="truncate text-fg-subtle">{info.getValue()}</span>
          </Copyable>
        ),
        maxSize: 300,
      }),
      columnHelper.accessor("channelId", {
        header: () => "Channel ID",
        cell: (info) => (
          <Copyable text={info.getValue()}>
            <span className="truncate text-fg-subtle">{info.getValue()}</span>
          </Copyable>
        ),
        maxSize: 300,
      }),
      columnHelper.display({
        id: "settings",
        header: () => "Settings",
        cell: (info) => (
          <div>
            {info.row.original.authRequired ? (
              <Chip color="orange">
                <LockClosedIcon className="w-3" />
                Auth
              </Chip>
            ) : null}
            {isChannelLimited(info.row.original) ? (
              <Chip>
                <ExclamationTriangleIcon className="w-3" /> Limited
              </Chip>
            ) : null}
          </div>
        ),
        enableSorting: false,
        size: Number.MAX_SAFE_INTEGER,
        meta: { style: { textAlign: "right" } },
      }),
      columnHelper.accessor("mostRecentIngestSession", {
        id: "ingestSessions.updatedAt",
        header: () => "Last Active",
        cell: (info) => <LastActive ingestSession={info.row.original.mostRecentIngestSession} />,
        enableSorting: false,
        meta: { style: { textAlign: "right" } },
      }),
      columnHelper.accessor("createdAt", {
        header: () => "Created",
        cell: (info) => (
          <span className="whitespace-nowrap text-fg-subtle">
            <FormattedDate date={new Date(info.getValue())} />
          </span>
        ),
        size: 1,
        meta: { style: { textAlign: "right" } },
      }),
      columnHelper.display({
        id: "actions",
        header: () => "",
        cell: (info) => (
          <div className="flex justify-end">
            <Link to="/channels/edit/$channelId/details" params={{ channelId: info.row.original.channelId }}>
              <Button data-pw="editChannelButton" size="small" variant="tertiary">
                <Pencil2Icon />
              </Button>
            </Link>
          </div>
        ),
        size: 1,
      }),
    ]
  }, [noMetricsEdge])

  return useReactTable({
    ...options,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowId: (row) => row.channelId,
  })
}

export interface ChannelTableProps {
  saveColumnVisibility: () => void
  resetColumnVisibilityToDefault: () => void
  downloadCsvUrl?: string
  noMetricsEdge?: string
  table: ReturnType<typeof useChannelReactTable>
}

export function ChannelTable(options: ChannelTableProps) {
  const { downloadCsvUrl, table } = options

  // If there is a filter AND no rows - that means we should show "no results".
  // This is different from zero rows and no filter - in that case we should show
  // a call to action.
  const noResults = table.getRowModel().rows.length === 0 && !!table.getState().globalFilter
  const sortedById = table.getState().sorting[0]?.id
  const sortedBy = sortedById ? columnNames(sortedById) : ""

  return (
    <div className="flex flex-col gap-4">
      <div className="flex w-full justify-start gap-2">
        <div>
          <DebouncedTextField
            // eslint-disable-next-line total-functions/no-unsafe-type-assertion
            initialValue={table.getState().globalFilter as string}
            onValueChange={table.setGlobalFilter}
            debounceMs={200}
            placeholder="Filter..."
          />
        </div>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button>
              {table.getState().sorting[0]?.desc ? <IconSortDescending size={16} /> : <IconSortAscending size={16} />}

              <span className="whitespace-nowrap">Sort by {sortedBy}</span>
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent sideOffset={4} align="start">
            {table
              .getAllLeafColumns()
              .filter((column) => column.getCanSort())
              .map((column) => {
                return (
                  <DropdownMenuItem key={column.id} onSelect={column.getToggleSortingHandler()}>
                    {columnNames(column.id)}
                    {column.getIsSorted() === "asc" && <IconSortAscending size={16} />}
                    {column.getIsSorted() === "desc" && <IconSortDescending size={16} />}
                  </DropdownMenuItem>
                )
              })}
          </DropdownMenuContent>
        </DropdownMenu>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button data-pw="configureButton">
              <IconAdjustmentsFilled size={16} />
              Configure
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent sideOffset={4} align="start">
            {table
              .getAllLeafColumns()
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
              .filter((column) => !(column?.columnDef.meta as any)?.isLogical)
              .map((column) => {
                return (
                  <DropdownMenuSwitchItem
                    key={column.id}
                    checked={column.getIsVisible()}
                    onSelect={() => column.toggleVisibility()}
                  >
                    {columnNames(column.id)}
                  </DropdownMenuSwitchItem>
                )
              })}
            <DropdownMenuSeparator />
            <DropdownMenuItem onSelect={() => options.saveColumnVisibility()}>Save</DropdownMenuItem>
            <DropdownMenuItem onSelect={() => options.resetColumnVisibilityToDefault()}>
              Reset to default
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <div className="grow text-right">
          {downloadCsvUrl && (
            <a href={downloadCsvUrl} download="channels.csv">
              <Button>
                <IconDownload size={16} />
                <span className="font-normal">Download CSV</span>
              </Button>
            </a>
          )}
        </div>
      </div>

      {noResults ? <NoResultsFound /> : <InteractiveTable table={table} />}
    </div>
  )
}
