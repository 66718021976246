import { IngestHealthType } from "@core-services/portal"
import { Link } from "@tanstack/react-router"
import { Light } from "@vindral/components"

export function IngestHealth({
  health = "No source ingest",
  channelId,
}: {
  health?: IngestHealthType
  channelId: string
}) {
  let color: "green" | "red" | "neutral" | "blue" | "orange" | "teal" | "purple" | "pink" = "neutral"

  switch (health) {
    case "No source ingest":
      color = "neutral"
      break
    case "Healthy":
      color = "green"
      break
    case "Unknown":
      color = "orange"
      break
    default:
      color = "neutral"
      break
  }

  return (
    <div className="flex">
      <div>
        <Light color={color} />
      </div>
      <div>
        <span className="pl-2">{health}</span>
        {health === "No source ingest" && (
          <p className="pl-2 text-fg-subtle">
            Please check out the{" "}
            <Link
              to="/channels/edit/$channelId/broadcast"
              search={(prev) => ({ ...prev, tab: "general" })}
              params={{ channelId }}
              className="font-medium text-fg underline decoration-solid-primary decoration-2 underline-offset-2 hover:decoration-fg"
            >
              Broadcast tab
            </Link>{" "}
            for information about how to go live
          </p>
        )}
      </div>
    </div>
  )
}
