import { z } from "zod"

export const Recaptcha = z.object({ recaptchaToken: z.string(), email: z.string().email() })

export type Recaptcha = z.infer<typeof Recaptcha>

export const RecaptchaAction = {
  Login: "login",
  Register: "register",
  PasswordReset: "password_reset",
  RequestPasswordReset: "request_password_reset",
  VerifyEmail: "verify_email",
} as const

export type RecaptchaAction = typeof RecaptchaAction[keyof typeof RecaptchaAction]
