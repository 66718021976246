import { ReactNode } from "react"
import { HorizontalLogo } from "../atoms/logos/HorizontalLogo"
import { Logo } from "../atoms/logos/Logo"

interface PageLayoutProps {
  children: ReactNode
  description: ReactNode
}

export const PageLayout = ({ description, children }: PageLayoutProps) => {
  return (
    <div className="flex h-screen flex-col-reverse lg:flex-row">
      <HorizontalLogo className="absolute left-0 top-0 m-10 h-6" />
      <div className="flex h-full justify-center gap-4 bg-canvas-1 pt-80 lg:w-1/2">{children}</div>
      <div className="relative hidden h-full justify-center overflow-hidden bg-canvas-alt pt-80 lg:flex lg:w-1/2">
        <Logo className="absolute left-0 top-1/3 scale-150 text-component" />
        <div className="z-10 flex w-1/2 max-w-xl flex-col gap-4">{description}</div>
      </div>
    </div>
  )
}
