import { AppAbility, CreateChannelGroup, OrganizationFindOne, normalizeIdentifier } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { FormControl, FormControlLabel, TextField } from "@vindral/components"
import { useCallback, useEffect } from "react"
import { useController, useForm } from "react-hook-form"
import { FormSection } from "../../atoms/FormSection"
import { Hyperlink } from "../../atoms/Hyperlink"
import { Page, PageContainer, PageContent, PageHeader } from "../../atoms/Page"
import { SaveButton } from "../../atoms/SaveButton"
import { useOrganizationSelect } from "../../hooks/useOrganizationSelect"
import { Breadcrumbs } from "../../molecules/Breadcrumbs"
import { SingleSelectCombobox } from "../../molecules/SingleSelectCombobox"

const resolver = zodResolver(CreateChannelGroup)

export interface CreateChannelGroupProps {
  ability: AppAbility
  onCreate: (channelGroup: CreateChannelGroup) => Promise<void>
  organizationId?: string
}

export const ChannelGroupCreate = ({ onCreate, ability, organizationId }: CreateChannelGroupProps): JSX.Element => {
  const form = useForm<CreateChannelGroup>({
    resolver,
    mode: "onChange",
    defaultValues: {
      name: "",
      organizationPublicId: organizationId,
    },
  })
  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors, isDirty, isValid },
  } = form
  const name = watch("name")

  const organizationSelect = useOrganizationSelect()
  const organizationController = useController({
    control,
    name: "organizationPublicId",
  })

  const onOrganizationChange = useCallback(
    (organization: OrganizationFindOne) => {
      organizationController.field.onChange(organization.publicId)
      organizationSelect.setSelected(organization)
    },
    [organizationController.field, organizationSelect]
  )

  useEffect(() => {
    // Don't override the organization set by the prop
    if (organizationId) {
      return
    }

    if (!organizationSelect.selected && organizationSelect.yourOnlyOption) {
      onOrganizationChange(organizationSelect.yourOnlyOption)
    }
  }, [organizationSelect, onOrganizationChange, organizationId])

  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
          </div>
        </PageHeader>
        <PageContainer>
          <div className="py-4">
            <FormSection
              title="Create a new Channel Group"
              description={
                <p>
                  A channel can be part of a Channel Group, a logical grouping of channels.
                  <br />
                  <Hyperlink text="Read more" href="https://docs.vindral.com/system-overview#channel-vs-stream" />
                </p>
              }
            >
              <form onSubmit={handleSubmit(onCreate)} className="flex flex-col items-start gap-4">
                {!organizationId && (
                  <FormControl>
                    <FormControlLabel error={organizationController.fieldState.error?.message} required>
                      Organization
                    </FormControlLabel>
                    <SingleSelectCombobox
                      options={organizationSelect.options}
                      selected={organizationSelect.selected}
                      search={organizationSelect.search}
                      isLoading={organizationSelect.query.isLoading}
                      onSearchChange={organizationSelect.setSearch}
                      dataPwButton="organizationSelector"
                      placeholder="Select an organization"
                      onSelect={onOrganizationChange}
                      onReachedEnd={() => {
                        if (!organizationSelect.query.hasNextPage) {
                          return
                        }
                        void organizationSelect.query.fetchNextPage()
                      }}
                    />
                  </FormControl>
                )}
                <div className="flex w-96 max-w-md flex-col gap-2">
                  <FormControl>
                    <FormControlLabel required error={errors.name?.message}>
                      Name
                    </FormControlLabel>
                    <TextField
                      {...register("name")}
                      error={!!errors?.name}
                      disabled={ability.cannot("create", "Channel", "name")}
                    />
                  </FormControl>
                  <div className="flex h-2 w-full items-center">
                    <div className="text-sm text-fg-subtle">
                      {name && (
                        <>
                          Normalized name: <span className="font-semibold">{normalizeIdentifier(name)}</span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <SaveButton text="Create" disabled={!isDirty || !isValid} />
              </form>
            </FormSection>{" "}
          </div>
        </PageContainer>
      </PageContent>
    </Page>
  )
}
