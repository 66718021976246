const KILO = 1000
const MEGA = KILO * 1000
const GIGA = MEGA * 1000
const TERA = GIGA * 1000

export const formatBytes = (bytes: number, decimals = 1): string => {
  return `${formatSI(bytes, decimals)}byte`
}

const formatSI = (val: number, decimals = 1): string => {
  if (val >= TERA) {
    return `${formatNumber(val, TERA, decimals)} T`
  }

  if (val >= GIGA) {
    return `${formatNumber(val, GIGA, decimals)} G`
  }

  if (val >= MEGA) {
    return `${formatNumber(val, MEGA, decimals)} M`
  }

  if (val >= KILO) {
    return `${formatNumber(val, KILO, decimals)} K`
  }

  return `${val} `
}

const formatNumber = (numerator: number, denominator: number, decimals: number) => {
  if (numerator % denominator === 0) {
    return numerator / denominator
  }

  return (numerator / denominator).toFixed(decimals)
}
