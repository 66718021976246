import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu"
import { TriangleRightIcon } from "@radix-ui/react-icons"
import { ComponentProps, ElementRef, forwardRef, ReactNode } from "react"
import { Shortcut } from "../../atoms/Shortcut"
import { Switch } from "../../atoms/Switch"
import { contentClasses, itemClasses, separatorClasses } from "./classes"

export type DropdownMenuItemProps = ComponentProps<typeof RadixDropdownMenu.Item> & {
  shortcut?: string
}

export const DropdownMenuItem = forwardRef<ElementRef<typeof RadixDropdownMenu.Item>, DropdownMenuItemProps>(
  (props, ref) => {
    return (
      <RadixDropdownMenu.Item className={itemClasses()} {...props} ref={ref}>
        {props.children}
        {props.shortcut && (
          <span className="ml-4 text-right font-medium text-fg-extra-subtle">
            <Shortcut shortcut={props.shortcut} callback={() => props.onSelect?.(new Event("shortcut"))} />
          </span>
        )}
      </RadixDropdownMenu.Item>
    )
  }
)

export const DropdownMenuSwitchItem = forwardRef<
  ElementRef<typeof RadixDropdownMenu.Item>,
  DropdownMenuItemProps & { checked: boolean }
>((props, ref) => {
  return (
    <RadixDropdownMenu.Item
      className={itemClasses()}
      {...props}
      ref={ref}
      onSelect={(event) => {
        props.onSelect?.(event)
        event.preventDefault()
      }}
    >
      {props.children}
      <Switch checked={props.checked} size="small" label={""} />
    </RadixDropdownMenu.Item>
  )
})

export const DropdownMenuSubTrigger = forwardRef<
  ElementRef<typeof RadixDropdownMenu.SubTrigger>,
  ComponentProps<typeof RadixDropdownMenu.SubTrigger>
>(({ children, ...rest }, ref) => {
  return (
    <RadixDropdownMenu.SubTrigger className={itemClasses()} {...rest} ref={ref}>
      {children}
      <div className="text-fg-subtle">
        <TriangleRightIcon />
      </div>
    </RadixDropdownMenu.SubTrigger>
  )
})

export const DropdownMenuContent = forwardRef<
  ElementRef<typeof RadixDropdownMenu.Content>,
  ComponentProps<typeof RadixDropdownMenu.Content>
>((props, ref) => {
  return (
    <RadixDropdownMenu.Portal>
      <RadixDropdownMenu.Content
        collisionPadding={16}
        className={contentClasses({ className: "origin-radix-dropdown-menu" })}
        {...props}
        ref={ref}
      />
    </RadixDropdownMenu.Portal>
  )
})

export const DropdownMenuSubContent = forwardRef<
  ElementRef<typeof RadixDropdownMenu.SubContent>,
  ComponentProps<typeof RadixDropdownMenu.SubContent>
>((props, ref) => {
  return (
    <RadixDropdownMenu.SubContent
      collisionPadding={16}
      className={contentClasses({ className: "origin-radix-dropdown-menu" })}
      {...props}
      ref={ref}
    />
  )
})

export const DropdownMenuSeparator = forwardRef<
  ElementRef<typeof RadixDropdownMenu.Separator>,
  ComponentProps<typeof RadixDropdownMenu.Separator>
>((props, ref) => {
  return <RadixDropdownMenu.Separator className={separatorClasses()} {...props} ref={ref} />
})

export const DropdownMenuPortal = RadixDropdownMenu.Portal
export const DropdownMenuTrigger = RadixDropdownMenu.Trigger
export const DropdownMenu = RadixDropdownMenu.Root
export const DropdownMenuSub = RadixDropdownMenu.Sub

export interface DropdownMenuProps {
  children?: ReactNode
}
