import { z } from "zod"

export const clientTelemetry = z.object({
  url: z.string().nullish(),
  probability: z.number().positive().max(1).nullish(),
  includeErrors: z.boolean().nullish(),
  includeEvents: z.boolean().nullish(),
  includeStats: z.boolean().nullish(),
  maxRetries: z.number().int().positive().nullish(),
  maxErrorReports: z.number().int().positive().nullish(),
  interval: z.number().int().positive().nullish(),
})

export type ClientTelemetry = z.infer<typeof clientTelemetry>
