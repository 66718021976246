export const showDevtools = !import.meta.env.PROD && import.meta.env.VITE_DEVTOOLS === "true"
export const sentryEnabled = import.meta.env.PROD
export function getSentryEnvironment() {
  const origin = window.origin

  if (origin.includes("dev.cdn")) {
    return "dev"
  } else if (origin.includes("stage.cdn")) {
    return "stage"
  } else if (origin.includes(".vindral.test")) {
    return "localhost"
  } else if (origin.includes("portal.cdn.vindral.com")) {
    return "production"
  } else {
    return "unknown"
  }
}
