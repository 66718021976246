import * as RadixPopover from "@radix-ui/react-popover"
import { ComponentProps, ElementRef, forwardRef } from "react"

const StyledContent = forwardRef<ElementRef<typeof RadixPopover.Content>, ComponentProps<typeof RadixPopover.Content>>(
  (props, ref) => {
    return (
      <RadixPopover.Content
        ref={ref}
        {...props}
        className="relative z-50 rounded-sm border border-divider bg-canvas-2 shadow-lg animate-in fade-in zoom-in-95 origin-radix-popover data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=closed]:zoom-out-95 dark:shadow-black/30 "
      >
        {props.children}
      </RadixPopover.Content>
    )
  }
)

StyledContent.displayName = "StyledContent"

export const PopoverContent = StyledContent
export const Popover = RadixPopover.Root
export const PopoverTrigger = RadixPopover.Trigger
export const PopoverPortal = RadixPopover.Portal
