import { Expose } from "class-transformer"

export class RtmpServerDTO {
  @Expose()
  id!: string

  @Expose()
  url!: string

  @Expose()
  load?: number

  @Expose()
  site!: string
}
