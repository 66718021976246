import { Expose, Type } from "class-transformer"
import { BaseDTO } from "../base.dto"
import { PublicApiChannelDTO } from "../channel/channel-without-internal.dto"

export class TelemetryDTO {
  @Expose()
  url!: string

  @Expose()
  probability?: number

  @Expose()
  includeErrors?: boolean

  @Expose()
  includeEvents?: boolean

  @Expose()
  includeStats?: boolean

  @Expose()
  maxRetries?: number

  @Expose()
  maxErrorReports?: number

  @Expose()
  interval?: number
}

export class ConnectDTO extends BaseDTO<ConnectDTO> {
  @Expose()
  edges!: string[]

  @Expose()
  channels!: PublicApiChannelDTO[]

  @Expose()
  @Type(() => TelemetryDTO)
  telemetry?: TelemetryDTO
}
