import { SinkItem } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { Cross1Icon, PlusIcon } from "@radix-ui/react-icons"
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import {
  Button,
  Chip,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
} from "@vindral/components"
import { useEffect } from "react"
import { useController, useFieldArray, useForm } from "react-hook-form"
import { z } from "zod"
import { Hyperlink } from "../../../atoms/Hyperlink"
import { SaveButton } from "../../../atoms/SaveButton"
import { Message, MessageSection } from "../../../molecules/Message"
import { RouteBlockDialog } from "../../../molecules/RouteBlockDialog"
import { InteractiveTable } from "../../../organisms/InteractiveTable"

export const SinkSchema = z
  .object({
    url: z.string().max(500),
    enabled: z.boolean(),
  })
  .refine((data) => data.url.startsWith("rtmp"), {
    path: ["url"],
    message: "Must be a valid RTMP url",
  })
export type SinkSchema = z.infer<typeof SinkSchema>

const itemResolver = zodResolver(SinkSchema)
const arrayResolver = zodResolver(z.object({ sinks: z.array(SinkSchema).max(5) }))

export interface ChannelEditDetailsRestreamingProps {
  sinks: SinkItem[]
  canEdit: boolean
  trial: boolean
  onSave: (value: { sinks: SinkSchema[] }) => Promise<void>
}

export function ChannelEditDetailsRestreaming({ sinks, canEdit, trial, onSave }: ChannelEditDetailsRestreamingProps) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty, isValid, isSubmitting, isSubmitSuccessful },
  } = useForm<{
    sinks: SinkSchema[]
  }>({
    resolver: arrayResolver,
    mode: "onChange",
    defaultValues: {
      sinks: sinks.map((sink) => ({
        url: sink.url,
        enabled: sink.enabled,
      })),
    },
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful, reset])

  const { fields, append, update, remove } = useFieldArray({ control, name: "sinks" })
  const columnHelper = createColumnHelper<typeof fields[0]>()
  const columns = [
    columnHelper.accessor("url", {
      header: () => "URL",
      cell: (info) => <div className="truncate whitespace-nowrap">{info.getValue()}</div>,
      size: 650,
      maxSize: 650,
    }),
    columnHelper.accessor("enabled", {
      header: () => "Status",
      cell: (info) => <Chip color={info.getValue() ? "green" : "red"}>{info.getValue() ? "Enabled" : "Disabled"}</Chip>,
    }),
    columnHelper.display({
      id: "actions",

      header: () => "",
      meta: { style: { textAlign: "right" } },
      cell: (info) => (
        <div className="flex gap-2">
          <EditRestreamDialog sink={info.row.original} onSubmit={(value) => update(info.row.index, value)} />
          <Button variant="danger" size="medium" onClick={() => remove(info.row.index)}>
            Remove
          </Button>
        </div>
      ),
    }),
  ]

  const table = useReactTable({
    data: fields,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      columnVisibility: {
        actions: canEdit,
      },
    },
  })

  return (
    <div className="py-4">
      <RouteBlockDialog condition={isDirty && !isSubmitting} />
      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSave)}>
        {trial ? (
          <Message severity="warning">
            <MessageSection>
              <div className="flex w-full">
                <div className="grow">
                  <span>
                    Your organization does not currently have access to manage restreaming settings. To enable this
                    feature, please request full access.
                  </span>
                </div>
              </div>
            </MessageSection>
          </Message>
        ) : null}
        <div className="mb-8 flex flex-col">
          <h1 className="mb-1 text-xl font-medium">Restreaming</h1>
          <div className="text-gray-600 dark:text-gray-300">
            <p>
              Restreaming allows you to send your live stream to multiple destinations at the same time. You can add up
              to 5 destinations per channel.
            </p>
            <p>Please note that updating the restreaming settings will not affect the current live stream.</p>
            <p>
              For more information on restreaming, visit{" "}
              <Hyperlink href="https://docs.vindral.com/broadcast/restreaming" text="here" />.
            </p>
          </div>
        </div>
        <div className="flex justify-end">
          {canEdit && (
            <AddRestreamDialog
              onSubmit={(value) => {
                append(value)
              }}
              disabled={fields.length >= 5}
            />
          )}
        </div>
        {errors.sinks?.message && <FormControlLabel error={errors.sinks.message} />}
        {fields.length > 0 && <InteractiveTable table={table} includePaginationFooter />}
        {canEdit && (
          <div>
            <SaveButton text="Save" disabled={!isDirty || !isValid} />
          </div>
        )}
      </form>
    </div>
  )
}

function AddRestreamDialog(props: { onSubmit: (value: SinkSchema) => void; disabled: boolean }) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button disabled={props.disabled}>
          <PlusIcon />
          Add new
        </Button>
      </DialogTrigger>
      <RestreamDialogContent
        title="Add Restream"
        actionTitle="Add"
        sink={{ url: "", enabled: true }}
        onSubmit={props.onSubmit}
      />
    </Dialog>
  )
}

function EditRestreamDialog(props: { sink: SinkSchema; onSubmit: (value: SinkSchema) => void }) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button size="medium">Edit</Button>
      </DialogTrigger>
      <RestreamDialogContent title="Edit Restream" actionTitle="Edit" sink={props.sink} onSubmit={props.onSubmit} />
    </Dialog>
  )
}

function RestreamDialogContent(props: {
  title: string
  actionTitle: string
  sink: SinkSchema
  onSubmit: (value: SinkSchema) => void
}) {
  const { title, actionTitle, sink, onSubmit } = props
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<SinkSchema>({
    resolver: itemResolver,
    mode: "onChange",
    defaultValues: {
      url: sink.url,
      enabled: sink.enabled,
    },
  })

  const enabledController = useController({ control, name: "enabled" })

  return (
    <DialogContent size="medium" onCloseAutoFocus={() => reset()}>
      <form
        onSubmit={(event) => {
          event.stopPropagation()
          void handleSubmit(onSubmit)(event)
        }}
      >
        <div className="flex justify-between border-b border-divider p-4">
          <h1 className="font-medium">{title}</h1>
          <DialogClose>
            <Cross1Icon />
          </DialogClose>
        </div>
        <div className="p-4">
          <div className="flex flex-col gap-4">
            <FormControl>
              <FormControlLabel error={errors.url?.message}>URL</FormControlLabel>
              <TextField {...register("url")} error={!!errors?.url} placeholder="rtmps://" />
            </FormControl>
            <FormControl>
              <FormControlLabel>Enabled</FormControlLabel>
              <Switch onCheckedChange={enabledController.field.onChange} checked={enabledController.field.value} />
            </FormControl>
          </div>
        </div>
        <div className="border-t border-divider p-4 text-right">
          <DialogClose asChild>
            <Button disabled={!isDirty || !isValid} type="submit" variant="primary">
              {actionTitle}
            </Button>
          </DialogClose>
        </div>
      </form>
    </DialogContent>
  )
}
