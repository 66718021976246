import { IconAdjustmentsFilled } from "@tabler/icons-react"
import { Link } from "@tanstack/react-router"
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  TableOptions,
  useReactTable,
} from "@tanstack/react-table"
import {
  Button,
  Chip,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSwitchItem,
  DropdownMenuTrigger,
} from "@vindral/components"
import { ReactNode } from "react"
import { TranscodingProfileItem } from "../interfaces/TranscodingProfile"
import { DebouncedTextField } from "../molecules/DebouncedTextField"
import { FormattedDate } from "../molecules/FormattedDate"
import { InteractiveTable } from "./InteractiveTable"
import { NoResultsFound } from "./NoResultsFound"

function columnNames(id: string) {
  switch (id) {
    case "organization.name":
      return "Organization"
    case "name":
      return "Name"
    case "notes":
      return "Notes"
    case "createdAt":
      return "Created"
  }

  return id
}

function TranscodingProfileLink({
  transcodingProfileId,
  children,
}: {
  transcodingProfileId: string
  children: ReactNode
}) {
  return (
    <Link
      to="/transcoding-profiles/edit/$transcodingProfileId"
      className="whitespace-nowrap font-semibold"
      params={{ transcodingProfileId }}
    >
      {children}
    </Link>
  )
}

export type UseTranscodingProfileTableProps = Omit<
  TableOptions<TranscodingProfileItem>,
  "columns" | "getCoreRowModel" | "getSortedRowModel" | "getPaginationRowModel"
>

export function useTranscodingProfileReactTable(options: UseTranscodingProfileTableProps) {
  const columnHelper = createColumnHelper<TranscodingProfileItem>()
  const columns = [
    columnHelper.accessor("name", {
      header: () => "Name",
      cell: (info) => (
        <div className="flex gap-2 whitespace-nowrap">
          <TranscodingProfileLink transcodingProfileId={info.row.original.publicId}>
            {info.getValue()}
          </TranscodingProfileLink>
          {info.row.original.template && <Chip color="purple">Template</Chip>}
        </div>
      ),
      maxSize: 250,
    }),
    columnHelper.accessor("organization.name", {
      id: "organization.name",
      header: () => "Organization",
      cell: (info) => (
        <span className="whitespace-nowrap font-semibold">
          <Link to="/organizations/edit/$publicId" params={{ publicId: info.row.original.organization.publicId }}>
            {info.getValue()}
          </Link>
        </span>
      ),
      maxSize: 250,
    }),
    columnHelper.accessor("notes", {
      header: () => "Notes",
      cell: (info) => <span className="text-fg-subtle">{info.getValue()}</span>,
      maxSize: 250,
    }),
    columnHelper.accessor("createdAt", {
      header: () => "Created At",
      meta: { style: { textAlign: "right" } },
      cell: (info) => (
        <span className="whitespace-nowrap text-fg-subtle">
          <FormattedDate date={new Date(info.getValue())} />
        </span>
      ),
      size: 1,
    }),
  ]

  return useReactTable({
    ...options,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowId: (row) => row.publicId,
  })
}

export interface TranscodingProfileTableProps {
  table: ReturnType<typeof useTranscodingProfileReactTable>
  saveColumnVisibility: () => void
  resetColumnVisibilityToDefault: () => void
}
export function TranscodingProfileTable({
  table,
  saveColumnVisibility,
  resetColumnVisibilityToDefault,
}: TranscodingProfileTableProps) {
  // If there is a filter AND no rows - that means we should show "no results".
  // This is different from zero rows and no filter - in that case we should show
  // a call to action.
  const noResults = table.getRowModel().rows.length === 0 && !!table.getState().globalFilter

  return (
    <div className="flex flex-col gap-4">
      <div className="flex w-full justify-start gap-2">
        <div>
          <DebouncedTextField
            // eslint-disable-next-line total-functions/no-unsafe-type-assertion
            initialValue={table.getState().globalFilter as string}
            onValueChange={table.setGlobalFilter}
            debounceMs={200}
            placeholder="Filter..."
          />
        </div>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button>
              <IconAdjustmentsFilled size={16} />
              Configure
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent sideOffset={4} align="start">
            {table.getAllLeafColumns().map((column) => {
              return (
                <DropdownMenuSwitchItem
                  key={column.id}
                  checked={column.getIsVisible()}
                  onSelect={() => column.toggleVisibility()}
                >
                  {columnNames(column.id)}
                </DropdownMenuSwitchItem>
              )
            })}
            <DropdownMenuSeparator />
            <DropdownMenuItem onSelect={() => saveColumnVisibility()}>Save</DropdownMenuItem>
            <DropdownMenuItem onSelect={() => resetColumnVisibilityToDefault()}>Reset to default</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      {noResults ? <NoResultsFound /> : <InteractiveTable table={table} />}
    </div>
  )
}
