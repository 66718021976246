import { Navigate, Route } from "@tanstack/react-router"
import { onboardedRoute } from "./onboarded"

export const indexRoute = new Route({
  getParentRoute: () => onboardedRoute,
  path: "/",
  component: () => {
    return <Navigate to="/dashboard" replace />
  },
})
