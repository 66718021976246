interface IVindralLogoProps {
  className?: string
}

export const Logo = (props: IVindralLogoProps): JSX.Element => (
  <svg className={props.className} viewBox="0 0 311.38666 414.70667">
    <g id="g10" transform="matrix(1.3333333,0,0,-1.3333333,0,414.70667)">
      <g id="g12" transform="scale(0.1)">
        <path
          d="m 94.5586,2621.04 0.0391,0.02 C 24.5508,2683.84 -12.7188,2780.63 5.91406,2881.37 31.793,3021.26 161.086,3120.57 302.918,3109.49 443.445,3098.5 549.352,2987.27 560.758,2854.16 l 0.039,0.02 c 19.004,-219.34 39.82,-438.52 62.43,-657.52 2.539,-24.59 12.484,-47.82 28.496,-66.64 l 446.727,-525.28 c 8.8,-10.33 25.38,-7.86 30.78,4.59 l 576.2,1329.84 c 38.25,90.21 122.8,158.04 228.04,169.5 145.43,15.84 280.33,-85.12 306.04,-229.13 16.76,-93.83 -15,-184.18 -76.54,-246.56 L 1161.09,1601.05 c -17.62,-18.15 -18.48,-46.74 -1.98,-65.91 l 174.94,-203.19 -0.06,-0.04 c 37.38,-42.87 62.25,-97.58 67.79,-159.2 13.08,-145.47 -90.28,-278.269 -234.49,-301.499 -148.87,-23.992 -287.942,73.57 -318.755,217.969 L 619.406,2113.17 c -5.484,24.51 -18.394,46.74 -36.965,63.65 L 94.5586,2621.04"
          style={{ fill: "currentColor", fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
          id="path14"
        />
      </g>
    </g>
  </svg>
)
