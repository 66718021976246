import { InputProps, TextField } from "@vindral/components"
import { useState } from "react"
import { useDebounce } from "react-use"

type DebouncedTextFieldProps = Omit<InputProps, "onChange"> & {
  initialValue: string
  onValueChange: (value: string) => void
  debounceMs: number
}

export function DebouncedTextField({ onValueChange, initialValue, debounceMs, ...props }: DebouncedTextFieldProps) {
  const [filter, setFilter] = useState(initialValue)
  useDebounce(() => onValueChange(filter), debounceMs, [filter])

  return <TextField {...props} value={filter} onChange={(event) => setFilter(event.target.value)} />
}
