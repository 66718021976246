import { useAbility } from "@casl/react"
import { Action } from "@core-services/data-types"
import { IconPlus } from "@tabler/icons-react"
import { Link } from "@tanstack/react-router"
import { Button, Pagination } from "@vindral/components"
import { AbilityContext } from "../../acl"
import { Page, PageContainer, PageContent, PageFooter, PageHeader } from "../../atoms/Page"
import { Breadcrumbs } from "../../molecules/Breadcrumbs"
import {
  ChannelGroupTable,
  useChannelGroupReactTable,
  type UseChannelGroupTableProps,
} from "../../organisms/ChannelGroupTable"
import { EmptyStateMessage } from "../../organisms/EmptyStateMessage"

function CreateChannelGroupButton() {
  const ability = useAbility(AbilityContext)

  if (ability.cannot(Action.Create, "ChannelGroup")) {
    return null
  }

  return (
    <Link to="/channel-groups/create">
      <Button variant="primary">
        <IconPlus size={16} />
        Create channel group
      </Button>
    </Link>
  )
}

export type ChannelGroupListProps = UseChannelGroupTableProps & {
  onClickCreate?: () => void
  saveColumnVisibility: () => void
  resetColumnVisibilityToDefault: () => void
  isStaleData: boolean
  totalCount: number
}

export function ChannelGroupList({ onClickCreate, isStaleData, totalCount, ...props }: ChannelGroupListProps) {
  const channelGroupTable = useChannelGroupReactTable(props)
  if (props.data.length === 0 && !props.state?.globalFilter && !isStaleData) {
    return (
      <EmptyStateMessage
        onClickCreate={onClickCreate}
        createButtonText="Create a channel group"
        title="There are no channel groups in this organization yet"
        subTitle="Create your first channel group."
        subTitleNoCreate="Ask your organization administrator or a channel manager to create a channel group."
      />
    )
  }

  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
            <CreateChannelGroupButton />
          </div>
        </PageHeader>
        <PageContainer>
          <div className="py-4">
            <ChannelGroupTable {...props} table={channelGroupTable} />
          </div>
        </PageContainer>
      </PageContent>
      <PageFooter>
        <div className="flex h-16 items-center justify-between">
          <div>
            {totalCount} Channel group{totalCount > 1 ? "s" : ""}
          </div>
          <Pagination
            pageIndex={channelGroupTable.getState().pagination.pageIndex}
            pageCount={channelGroupTable.getPageCount()}
            nextPage={() => channelGroupTable.nextPage()}
            previousPage={() => channelGroupTable.previousPage()}
          />
        </div>
      </PageFooter>
    </Page>
  )
}
