import { Site } from "@core-services/portal"
import { Outlet } from "@tanstack/react-router"
import { Button, ConfirmDialogContent, Dialog, DialogTrigger } from "@vindral/components"
import { Page, PageContainer, PageContent, PageFooter, PageHeader } from "../../../atoms/Page"
import { Breadcrumbs } from "../../../molecules/Breadcrumbs"
import { FormattedDate } from "../../../molecules/FormattedDate"
import { RouteTabs, RouteTrigger } from "../../../molecules/RouteTabs"
import { Titled } from "../../../molecules/Titled"

export type SiteEditProps = {
  onDelete: () => Promise<void>
  site: Site
}

export const SiteEdit = ({ onDelete, site }: SiteEditProps): JSX.Element => {
  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
          </div>

          <RouteTabs border="none">
            <RouteTrigger to="/sites/edit/$code/general">General</RouteTrigger>
            <RouteTrigger to="/sites/edit/$code/geographic-rules">Geographic rules</RouteTrigger>
            <RouteTrigger to="/sites/edit/$code/channel-rules">Channel rules</RouteTrigger>
            <RouteTrigger to="/sites/edit/$code/organization-rules">Organization rules</RouteTrigger>
          </RouteTabs>
        </PageHeader>
        <PageContainer>
          <Outlet />
        </PageContainer>
      </PageContent>
      <PageFooter>
        <div className="flex h-16 items-center justify-between">
          <div className="flex gap-8">
            <Titled title="Created">{site.createdAt && <FormattedDate date={new Date(site.createdAt)} />}</Titled>
            {typeof site.updatedAt !== "undefined" && (
              <Titled title="Updated">
                <FormattedDate date={new Date(site.updatedAt)} />
              </Titled>
            )}
          </div>
          <Dialog>
            <DialogTrigger asChild>
              <Button variant="danger" data-pw="deleteButton">
                Delete
              </Button>
            </DialogTrigger>
            <ConfirmDialogContent
              onConfirm={onDelete}
              title={`Confirm deletion of site ${site.code}`}
              description={
                <>
                  Please confirm that you are absolutely sure that you want to delete site
                  <span className="font-medium"> {site.code}</span>.
                </>
              }
            />
          </Dialog>
        </div>
      </PageFooter>
    </Page>
  )
}
