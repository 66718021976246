import { useMemo, useState } from "react"
import { ChannelItem } from "../../interfaces/Channel"
import { trpc } from "../../trpc"
import { MultiSelectorCombobox } from "./MultiSelector"

function getNextPageParam<T>(lastPage: { total: number }, allPages: { total: number; items: T[] }[]) {
  const nextSkip = allPages.reduce((prev, page) => prev + page.items.length, 0)
  return nextSkip < lastPage.total ? nextSkip : undefined
}

export interface MultiSelectorChannelCombobox {
  organizationId?: string
  onSelect: (item: ChannelItem) => void
  selected: { channelId: string }[]
}

export function MultiSelectorChannelCombobox(props: MultiSelectorChannelCombobox) {
  const [search, onSearchChange] = useState("")
  const query = trpc.channel.findMany.useInfiniteQuery(
    {
      filter: props.organizationId ? { organization: { publicId: props.organizationId } } : undefined,
      orderBy: "name",
      search: search.length > 0 ? search : undefined,
    },

    { getNextPageParam, keepPreviousData: true }
  )
  const allPages = useMemo(() => query.data?.pages.flatMap((page) => page.items) ?? [], [query.data])
  const selected = useMemo(() => new Set(props.selected.map((f) => f.channelId)), [props.selected])

  return (
    <MultiSelectorCombobox
      idField="channelId"
      selected={selected}
      options={[{ id: "channels", title: "channels", items: allPages }]}
      onSelect={(channel) => props.onSelect(channel)}
      renderItem={(channel) => <div>{channel.name}</div>}
      onReachedEnd={query.fetchNextPage}
      isLoading={query.isLoading}
      search={search}
      onSearchChange={onSearchChange}
    >
      Add channel
    </MultiSelectorCombobox>
  )
}
