import type { Limitable as ILimitable, LimitationMode } from "@core-services/data-types"
import { Expose } from "class-transformer"

export class LimitableDTO implements ILimitable {
  @Expose()
  mode: LimitationMode = "deny"

  @Expose()
  limited: string[] | number[] = []
}
