import { Route } from "@tanstack/react-router"
import { onboardedRoute } from "../onboarded"

export const packagerServerIndexRoute = new Route({
  getParentRoute: () => onboardedRoute,
  preSearchFilters: [(search) => search],
  path: "packager-servers",
  getContext: () => {
    return {
      pageTitle: "Packager Servers",
    }
  },
})
