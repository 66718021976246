import { ApiKeyScope } from "@core-services/data-types"
import { Expose, Transform } from "class-transformer"
import { z } from "zod"
import { BaseDTO } from "../base.dto"
import { transformDate } from "../transforms"

export const ApiKey = z
  .object({
    publicId: z.string(),
    name: z.string(),
    notes: z.string(),
    maskedKey: z.string(),
    scope: z.enum([ApiKeyScope.Read, ApiKeyScope.ReadWrite]),
    createdAt: z.date(),
    updatedAt: z.date(),
    user: z
      .object({
        id: z.number(),
        name: z.string(),
      })
      .optional(),
  })
  .transform(({ publicId, ...rest }) => ({
    id: publicId,
    ...rest,
  }))

export type ApiKey = z.infer<typeof ApiKey>

export class ApiKeyDTO extends BaseDTO<ApiKeyDTO> {
  @Expose()
  @Transform(transformDate)
  createdAt!: Date

  @Expose()
  @Transform(transformDate)
  updatedAt?: Date

  @Expose()
  id!: string

  @Expose()
  name!: string

  @Expose()
  notes!: string

  @Expose()
  maskedKey!: string

  @Expose()
  scope!: ApiKeyScope

  @Expose()
  user?: {
    id: number
    name: string
  }
}
