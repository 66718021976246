import { Light, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from "@vindral/components"
import { StatusTitle } from "./StatusTitle"

interface SiteCapabilityJobsProps {
  hasJobs: boolean
  type: string
  expectedJobs?: string[]
}

export function SiteCapabilityJobs({ expectedJobs, hasJobs, type }: SiteCapabilityJobsProps) {
  const color = hasJobs ? "green" : "red"
  const message = hasJobs ? `Site has ${type} jobs` : `Site does NOT have ${type} jobs`

  return (
    <div>
      <Tooltip>
        <TooltipTrigger>
          <StatusTitle>JOBS</StatusTitle>
          <Light color={color} />
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent>
            {message}
            {!hasJobs && expectedJobs && expectedJobs.length > 0 && (
              <>
                <p className="pt-2 underline">Expected jobs:</p>
                <ul>
                  {expectedJobs.map((job) => (
                    <li className="pl-2" key={job}>
                      - {job}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </div>
  )
}
