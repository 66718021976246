import { RegionItem } from "@core-services/portal"
import { useMemo, useState } from "react"
import { trpc } from "../trpc"

function getNextPageParam<T>(lastPage: { total: number }, allPages: { total: number; items: T[] }[]) {
  const nextSkip = allPages.reduce((prev, page) => prev + page.items.length, 0)
  return nextSkip < lastPage.total ? nextSkip : undefined
}

export type SelectedRegion = Pick<RegionItem, "name" | "publicId">

export function useRegionSelect({ defaultSelected }: { defaultSelected?: SelectedRegion | null }) {
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<SelectedRegion | null>(defaultSelected ?? null)
  const [search, setSearch] = useState("")
  const query = trpc.region.findMany.useInfiniteQuery(
    {
      orderBy: "name",
      search: search.length > 0 ? search : undefined,
    },
    { getNextPageParam, keepPreviousData: true }
  )

  const options = useMemo(() => {
    return [
      {
        id: "options",
        items: query.data?.pages.flatMap((pages) => pages.items) ?? [],
      },
    ]
  }, [query])

  return { query, options, selected, setSelected, search, setSearch, open, setOpen }
}
