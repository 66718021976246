import { Copyable } from "@vindral/components"
import { FormSection } from "../../../../atoms/FormSection"
import { Hyperlink } from "../../../../atoms/Hyperlink"
import { rtmpIngestBaseUrl, rtmpIngestUrl } from "../../../../utils/urls"

const rtmpsUrl = rtmpIngestBaseUrl({ secure: true })
const rtmpUrl = rtmpIngestBaseUrl({ secure: false })

export const Rtmp = (props: { streamKey: string }) => {
  const { streamKey } = props
  const ffmpegRtmpCommand = `docker run --rm -it jrottenberg/ffmpeg -re -f lavfi -i "testsrc=size=1280x720:rate=25" -f lavfi -i "sine=frequency=440:sample_rate=48000, aformat=channel_layouts=stereo" -vcodec libx264 -preset veryfast -tune zerolatency -profile:v baseline -pix_fmt yuv420p -g 25 -acodec aac -b:a 64k -f flv ${rtmpIngestUrl(
    { streamKey, secure: true }
  )}`

  return (
    <>
      <h1 className="mb-1 text-xl font-medium">RTMP</h1>
      <FormSection
        title="Ingest"
        description={
          <div className="flex flex-col gap-4">
            <p>
              Supported on any software or device that supports broadcasting RTMP. OBS and FFmpeg are two free
              alternatives frequently used.
            </p>
            <Hyperlink
              href="https://docs.vindral.com/broadcast/broadcast-software/streaming-from-obs/"
              text="More information"
            />
          </div>
        }
      >
        <div className="flex flex-col gap-4">
          <div>
            <div className="text-xs font-medium uppercase tracking-wider text-fg-subtle">secure url</div>
            <Copyable text={rtmpsUrl}>
              <span data-pw="streamKeyId" className="block truncate font-mono text-xs">
                {rtmpsUrl}
              </span>
            </Copyable>
          </div>

          <div>
            <div className="text-xs font-medium uppercase tracking-wider text-fg-subtle">url</div>
            <Copyable text={rtmpUrl}>
              <span data-pw="streamKeyId" className="block truncate font-mono text-xs">
                {rtmpUrl}
              </span>
            </Copyable>
          </div>

          <div>
            <div className="text-xs font-medium uppercase tracking-wider text-fg-subtle">stream key</div>
            <Copyable text={streamKey}>
              <span data-pw="streamKeyId" className="block truncate font-mono text-xs">
                {streamKey}
              </span>
            </Copyable>
          </div>
        </div>
      </FormSection>
      <FormSection
        title="Docker ffmpeg example"
        description={
          <p>Use this simple docker ffmpeg command for running ffmpeg with sine audio and 720p25 test source.</p>
        }
      >
        <div className="flex flex-col gap-4">
          <div className="text-xs font-medium uppercase tracking-wider text-fg-subtle">Command</div>
          <Copyable text={ffmpegRtmpCommand}>
            <div data-pw="ffmpegCommand" className="min-w-0 break-words font-mono text-xs">
              {ffmpegRtmpCommand}
            </div>
          </Copyable>
        </div>
      </FormSection>
    </>
  )
}
