import { useAbility } from "@casl/react"
import { ChannelStatus, IngestStatus } from "@core-services/portal"
import { IconClockHour3, IconInfoCircle, IconUsers } from "@tabler/icons-react"
import { Copyable, HoverCard, HoverCardContent, HoverCardPortal, HoverCardTrigger } from "@vindral/components"
import { formatDistanceToNow } from "date-fns"
import { useEffect, useState } from "react"
import { AbilityContext, checkIfSuperUser } from "../../../acl"
import { ChannelPreview } from "../../../atoms/ChannelPreview"
import { IngestHealth } from "../../../atoms/IngestHealth"
import { StatusLight } from "../../../atoms/StatusLight"
import { useNoMetricsEdge } from "../../../hooks/useNoMetricsEdge"
import { StatusBlock } from "../../../molecules/StatusBlock"
import { IngestInfo } from "../../../organisms/IngestInfo"
import { LiveStatus } from "../../../organisms/LiveStatus"
import { playUrl } from "../../../utils/urls"
import { ChannelFindById } from "../ChannelEdit"

export interface ChannelDetailsInfoProps {
  channel: ChannelFindById
  viewers: number | undefined
  channelStatus: ChannelStatus | undefined
  channelStatusLoading: boolean
  ingestStatus: IngestStatus | undefined
}

function getUptime(channel?: ChannelFindById) {
  if (!channel || !channel.mostRecentIngestSession) {
    return "-"
  }

  const { startedAt, stoppedAt } = channel.mostRecentIngestSession

  if (!startedAt || stoppedAt) {
    return "-"
  }

  return formatDistanceToNow(new Date(startedAt))
}

export function ChannelDetailsInfo({
  channel,
  viewers,
  channelStatus,
  channelStatusLoading,
  ingestStatus,
}: ChannelDetailsInfoProps) {
  const [uptime, setUptime] = useState<string>(getUptime(channel))
  const ability = useAbility(AbilityContext)
  const isSuperUser = checkIfSuperUser({ ability })

  const { isLive, suspended } = channel
  const noMetricsEdge = useNoMetricsEdge()

  const showRtmpIngestLight = channelStatus?.rtmpIngestActive
  const showPackagerLight = channelStatus?.packagerActive || showRtmpIngestLight
  const showOriginEdgeLight = channelStatus?.originEdgeActive || showRtmpIngestLight
  const hasMetadata = !!ingestStatus?.metadata
  const formatProtocol = (protocol: string) => {
    switch (protocol) {
      case "rtmp":
        return "RTMP"
      case "srt":
        return "SRT"
      case "webrtc":
        return "WebRTC"
      default:
        return protocol
    }
  }

  useEffect(() => {
    function tick() {
      setUptime(getUptime(channel))
    }

    const timerID = setInterval(() => tick(), 5000)

    return () => clearInterval(timerID)
  }, [setUptime, channel])

  return (
    <div className="flex shrink items-start justify-between pb-4">
      <div className="flex gap-4">
        <a className="block h-32 w-60" href={playUrl({ ...channel, edgeUrl: noMetricsEdge })} target="_blank">
          <ChannelPreview channel={channel} hoverable={false} />
        </a>
        <div className="flex flex-col gap-2">
          <div className="flex gap-8 pb-2">
            <StatusBlock title="Status">
              <LiveStatus isLive={isLive} suspended={suspended} />
            </StatusBlock>

            {isLive && (
              <>
                <StatusBlock title="Viewers">
                  <span className="flex items-center gap-2">
                    <IconUsers className="h-4 w-4" />
                    {viewers ?? "-"}
                  </span>
                </StatusBlock>
                <StatusBlock title="Uptime">
                  <span className="flex items-center gap-2">
                    <IconClockHour3 className="h-4 w-4" />
                    {uptime}
                  </span>
                </StatusBlock>
              </>
            )}
          </div>
          <StatusBlock title="Channel ID">
            <Copyable text={channel.channelId}>
              <span data-pw="channelId" className="block max-w-full truncate font-mono text-sm max-[1280px]:max-w-xs">
                {channel.channelId}
              </span>
            </Copyable>
          </StatusBlock>
          <StatusBlock title="Stream Key">
            <Copyable text={channel.streamKey}>
              <span data-pw="streamKeyId" className="block max-w-full truncate font-mono text-sm max-[1280px]:max-w-xs">
                {channel.streamKey}
              </span>
            </Copyable>
          </StatusBlock>
        </div>
      </div>

      <div className="flex grow flex-col gap-4">
        <div className="flex items-start gap-8 max-[1280px]:gap-4">
          <div className="min-w-[7.5rem]">
            <StatusBlock title="Ingest health">
              <IngestHealth health={ingestStatus?.ingestHealth} channelId={channel.channelId} />
            </StatusBlock>
          </div>

          {ingestStatus?.protocol && (
            <StatusBlock title="Ingest protocol">
              <span>{formatProtocol(ingestStatus?.protocol) ?? "-"}</span>
              {hasMetadata && (
                <HoverCard>
                  <HoverCardTrigger>
                    <IconInfoCircle className="ml-2 inline-block h-4 w-4 align-text-top hover:cursor-pointer" />
                  </HoverCardTrigger>
                  <HoverCardPortal>
                    <HoverCardContent side="right">
                      <IngestInfo ingestStatus={ingestStatus} />
                    </HoverCardContent>
                  </HoverCardPortal>
                </HoverCard>
              )}
            </StatusBlock>
          )}
        </div>

        {isSuperUser && (
          <div className="flex gap-8 max-[1280px]:gap-4">
            {showRtmpIngestLight && (
              <div className="min-w-[7.5rem]">
                <StatusBlock title="Ingest">
                  <StatusLight
                    online={!!channelStatus?.rtmpIngestActive}
                    loading={channelStatusLoading}
                    host={channelStatus?.rtmpIngestHostname}
                  />
                </StatusBlock>
              </div>
            )}
            {showPackagerLight && (
              <div className="min-w-[7.5rem]">
                <StatusBlock title="Transcoding">
                  <StatusLight
                    online={!!channelStatus?.packagerActive}
                    loading={channelStatusLoading}
                    host={channelStatus?.packagerHostnames}
                  />
                </StatusBlock>
              </div>
            )}
            {showOriginEdgeLight && (
              <div className="min-w-[7.5rem]">
                <StatusBlock title="Origin Edge">
                  <StatusLight
                    online={!!channelStatus?.originEdgeActive}
                    loading={channelStatusLoading}
                    host={channelStatus?.originEdgeHostname}
                  />
                </StatusBlock>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
