import { useHotkeys } from "react-hotkeys-hook"
import { HotkeyCallback, OptionsOrDependencyArray } from "react-hotkeys-hook/dist/types"

const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
const isIOS = /(iPhone|iPod|iPad)/i.test(navigator.platform)

export function formatShortcut(shortcut: string) {
  const modKey = isMacLike || isIOS ? "⌘" : "ctrl"

  return shortcut
    .replaceAll("mod", modKey)
    .replaceAll("shift", "⇧")
    .replaceAll("left", "←")
    .replaceAll("right", "→")
    .toUpperCase()
}

export interface ShortcutProps {
  shortcut: string
  callback: HotkeyCallback
  options?: OptionsOrDependencyArray
}

/**
 * The useShortcut hook is useful when the shortcut is not always mounted, for example when shown in tooltips.
 */
export function useShortcut({ shortcut, callback, options }: ShortcutProps) {
  const ref = useHotkeys(shortcut, callback, options)
  return {
    ref,
    Shortcut: () => <span>{formatShortcut(shortcut)}</span>,
  }
}

export function Shortcut(props: ShortcutProps) {
  const { Shortcut } = useShortcut(props)
  return <Shortcut />
}
