import { Copyable } from "@vindral/components"
import { channelThumbnailUrl } from "../../../../atoms/ChannelThumbnail"
import { FormSection } from "../../../../atoms/FormSection"
import { lbUrl } from "../../../../utils/urls"
import { ChannelFindById } from "../../ChannelEdit"

export const ThumbnailUrl = (props: { channel: ChannelFindById }) => {
  const { channel } = props

  return (
    <>
      <h1 className="mb-1 text-xl font-medium">Thumbnail URL</h1>
      <FormSection
        title="Thumbnail"
        docsLink="https://docs.vindral.com/playout/fetching-live-thumbnails/"
        description={<p>Fetch your channel's thumbnail by utilizing the URL from our thumbnail service.</p>}
      >
        <div className="flex flex-col gap-4">
          <div className="text-xs font-medium uppercase tracking-wider text-fg-subtle">Thumbnail URL</div>
          <Copyable
            text={channelThumbnailUrl({
              channelId: channel.channelId,
              sizes: channel.thumbnail.sizes,
              baseUrl: `${lbUrl()}/api/thumbnail?channelId=${channel.channelId}`,
              authToken: channel.authToken,
            }).toString()}
          >
            <code className="min-w-0 break-words font-mono text-xs">
              {channelThumbnailUrl({
                channelId: channel.channelId,
                sizes: channel.thumbnail.sizes,
                baseUrl: `${lbUrl()}/api/thumbnail?channelId=${channel.channelId}`,
                authToken: channel.authToken,
              }).toString()}
            </code>
          </Copyable>
        </div>
      </FormSection>
    </>
  )
}
