import {
  formatCodec,
  formatFrameSkipping,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "@vindral/components"
import { VideoSize } from "../atoms/VideoSize"
import { TranscodingProfileVideoRendition } from "../interfaces/TranscodingProfile"

interface ThumbnailRenditionProps {
  rendition: TranscodingProfileVideoRendition
}

function ThumbnailRendition({ rendition }: ThumbnailRenditionProps) {
  return (
    <TableRow key={rendition.id}>
      <TableCell collapse>{formatCodec(rendition.codec)}</TableCell>
      <TableCell collapse className="tabular-nums">
        <VideoSize size={rendition.size} />
      </TableCell>
      <TableCell collapse>
        {rendition.frameSkipping ? formatFrameSkipping(rendition.frameSkipping) : undefined}
      </TableCell>
    </TableRow>
  )
}

interface ThumbnailRenditionsProps {
  renditions: TranscodingProfileVideoRendition[]
}

export function ThumbnailRenditions({ renditions }: ThumbnailRenditionsProps) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadCell>Codec</TableHeadCell>
          <TableHeadCell>Size</TableHeadCell>
          <TableHeadCell>Interval</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {renditions.map((rendition) => {
          return <ThumbnailRendition key={rendition.id} rendition={rendition} />
        })}
      </TableBody>
    </Table>
  )
}
