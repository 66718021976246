import { Limitable } from "@core-services/data-types"
import { SiteItem } from "@core-services/portal"
import { OrganizationItem } from "../interfaces/Organization"

function isLimited({ mode, limited }: Limitable): boolean {
  switch (mode) {
    case "allow":
      return true
    case "deny":
      return limited.length > 0
    case "disabled":
      return false
  }
}

export const isOrganizationLimited = (org: OrganizationItem): boolean =>
  !!org.siteLimitation && isLimited(org.siteLimitation)

export const isSiteLimited = (site: SiteItem): boolean =>
  (!!site.asnLimitation && isLimited(site.asnLimitation)) ||
  (!!site.countryLimitation && isLimited(site.countryLimitation)) ||
  (!!site.organizationLimitation && isLimited(site.organizationLimitation)) ||
  (!!site.channelLimitation && isLimited(site.channelLimitation))

export const checkSiteAllowNothing = (site: SiteItem): boolean =>
  (!!site.asnLimitation && site.asnLimitation.mode === "allow" && site.asnLimitation.limited.length === 0) ||
  (!!site.countryLimitation &&
    site.countryLimitation.mode === "allow" &&
    site.countryLimitation.limited.length === 0) ||
  (!!site.organizationLimitation &&
    site.organizationLimitation.mode === "allow" &&
    site.organizationLimitation.limited.length === 0) ||
  (!!site.channelLimitation && site.channelLimitation.mode === "allow" && site.channelLimitation.limited.length === 0)
