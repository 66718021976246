import { subject } from "@casl/ability"
import { useAbility } from "@casl/react"
import { AppAbility } from "@common/access-control"
import { Action } from "@core-services/data-types"
import { InviteUser } from "@core-services/portal"
import { Route, useSearch } from "@tanstack/react-router"
import { PaginationState, SortingState } from "@tanstack/react-table"
import { toast } from "@vindral/components"
import { useMemo, useState } from "react"
import { z } from "zod"
import { teamIndexRoute } from "."
import { AbilityContext, organizationSubjectInOrganization } from "../../acl"
import { Page, PageContainer, PageContent, PageHeader } from "../../atoms/Page"
import { Breadcrumbs } from "../../molecules/Breadcrumbs"
import { UserInvitesList } from "../../templates/user/UserInvites"
import { UserList } from "../../templates/user/UserList"
import { trpc } from "../../trpc"

const userListParams = z.object({
  organizationId: z.string().optional(),
})

export const teamListRoute = new Route({
  path: "/",
  getParentRoute: () => teamIndexRoute,
  onLoad: async ({ context: { trpcContext } }) => {
    await trpcContext.user.findMany.ensureData({ orderBy: "name" }, { staleTime: 1000 })
  },
  component: Index,
  validateSearch: userListParams,
  preSearchFilters: [(search) => search],
})

function canEditInvites(ability: AppAbility, organizationId: string | undefined) {
  if (!organizationId) {
    return false
  }

  return ability.can(Action.Create, subject("UserInviteToken", { organization: { publicId: organizationId } }))
}

function Index() {
  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
          </div>
        </PageHeader>
        <PageContainer>
          <Invites />
          <List />
        </PageContainer>
      </PageContent>
    </Page>
  )
}

function Invites() {
  const ability = useAbility(AbilityContext)
  const { organizationId = "" } = useSearch({ from: teamListRoute.id })
  const showInvites = canEditInvites(ability, organizationId)
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 10 })

  const { data, refetch } = trpc.auth.getInvitesByOrganizationId.useQuery(
    {
      organizationId,
      take: pagination.pageSize,
      cursor: pagination.pageIndex * pagination.pageSize,
    },
    { enabled: showInvites }
  )
  const pageCount = Math.ceil((data?.total ?? 0) / pagination.pageSize)

  const inviteUserMutation = trpc.auth.inviteUser.useMutation({
    onSuccess: () => {
      void refetch()
      toast({ title: "Invite link sent" })
    },
    onError: (error) => {
      toast({ title: "Failed to send invite link!", description: error.message })
    },
  })
  const inviteUser = async (value: InviteUser) => {
    inviteUserMutation.reset()
    await inviteUserMutation.mutateAsync(value)
  }

  const deleteInviteMutation = trpc.auth.deleteInvite.useMutation({
    onSuccess: () => {
      void refetch()
      toast({ title: "Invite canceled" })
      // Check if the current page is empty after the delete
      if (data?.items.length === 1 && pagination.pageIndex > 0) {
        setPagination((prevPagination) => ({
          ...prevPagination,
          pageIndex: prevPagination.pageIndex - 1,
        }))
      }
    },
    onError: (error) => {
      toast({ title: "Failed to cancel invite!", description: error.message })
    },
  })
  const deleteInvite = async (publicId: string) => {
    deleteInviteMutation.reset()
    await deleteInviteMutation.mutateAsync({ publicId })
  }

  if (!showInvites) {
    return null
  }

  return (
    <UserInvitesList
      ability={ability}
      manualPagination
      pageCount={pageCount}
      state={{ pagination }}
      data={data?.items ?? []}
      onPaginationChange={setPagination}
      organizationPublicId={organizationId}
      inviteUser={inviteUser}
      deleteInvite={deleteInvite}
    />
  )
}

function List() {
  const ability = useAbility(AbilityContext)
  const { organizationId } = useSearch({ from: teamListRoute.id })
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 20 })
  const [sorting, setSorting] = useState<SortingState>([{ id: "name", desc: false }])

  const filter = organizationId ? { organization: { publicId: organizationId } } : undefined
  const { data, refetch } = trpc.user.findMany.useQuery(
    {
      filter: filter,
      // eslint-disable-next-line total-functions/no-unsafe-type-assertion
      orderBy: (sorting[0]?.id as "name" | "email") ?? "name",
      order: sorting[0]?.desc ? "desc" : "asc",
      take: pagination.pageSize,
      cursor: pagination.pageIndex * pagination.pageSize,
    },
    { keepPreviousData: true, staleTime: 1000 }
  )

  const updateAccessPermission = trpc.user.updateAccessPermission.useMutation({
    onSuccess() {
      void refetch()
      toast({ title: "User permissions updated" })
    },
    onError() {
      toast({ title: "Failed to update permissions!" })
    },
  })

  const removeAccessPermission = trpc.user.removeAccessPermission.useMutation({
    onSuccess() {
      void refetch()
      toast({ title: "Removed user from organization" })
    },
    onError() {
      toast({ title: "Failed to update permissions!" })
    },
  })

  const isAdministrator = useMemo(
    () => !!organizationId && ability.can(Action.Update, organizationSubjectInOrganization(organizationId)),
    [ability, organizationId]
  )

  const pageCount = Math.ceil((data?.total ?? 0) / pagination.pageSize)

  return (
    <UserList
      ability={ability}
      organizationId={organizationId}
      manualPagination
      pageCount={pageCount}
      state={{ pagination, sorting }}
      data={data?.items ?? []}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      isAdministrator={isAdministrator}
      removeUser={(user, role) => {
        if (!organizationId) {
          return
        }
        removeAccessPermission.mutate({
          organizationPublicId: organizationId,
          userPublicId: user.publicId,
          role: role,
        })
      }}
      changeUserAccessPermission={(user, role, newRole) => {
        if (!organizationId) {
          return
        }
        updateAccessPermission.mutate({
          organizationPublicId: organizationId,
          userPublicId: user.publicId,
          role,
          newRole,
        })
      }}
    />
  )
}
