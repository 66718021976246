import * as Sentry from "@sentry/react"
import { FormEvent, FormHTMLAttributes, ReactNode, useRef, useState } from "react"

interface FormProps extends FormHTMLAttributes<HTMLFormElement> {
  onSubmit: () => Promise<void>
  children: ReactNode
}

const Form = (props: FormProps) => {
  const { onSubmit, children, ...rest } = props
  const [error, setError] = useState<Error | null>(null)
  const hpRef = useRef<HTMLInputElement>(null)

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()

    // If hp field is filled, don't submit the form
    if (hpRef.current && hpRef.current.value) {
      Sentry.captureMessage("Form submission blocked: honeypot field was filled", "warning")
      setError(new Error("Form submission blocked: potential spam detected"))
      return
    }

    try {
      await onSubmit()
      setError(null)
    } catch (error) {
      if (error instanceof Error) {
        setError(error)
      }
    }
  }

  return (
    <form {...rest} onSubmit={handleSubmit}>
      <input
        type="text"
        name="url"
        style={{ position: "absolute", left: "-5000px" }}
        tabIndex={-1}
        autoComplete="off"
        ref={hpRef}
      />
      {error && <li className="text-base text-fg-danger-subtle transition-colors">{`${error.message}`}</li>}
      {children}
    </form>
  )
}

export default Form
