"use client"

import { CalendarIcon } from "@radix-ui/react-icons"
import { subMonths } from "date-fns"
import { useState } from "react"
import { DateRange as AbsoluteDateRange } from "react-day-picker"
import { Button } from "./Button"
import { Calendar } from "./Calendar"
import { Popover, PopoverContent, PopoverTrigger } from "./Popover"

export interface SelectedDates {
  from: Date | undefined
  to: Date | undefined
}

export function DateRangePicker(props: {
  onSelect: (dateRange: SelectedDates) => void
  fromDate: Date
  toDate: Date
  selectedDates: AbsoluteDateRange | undefined
  maxDays: number
}) {
  const [open, setOpen] = useState(false)
  const previousMonth = subMonths(new Date(), 1)

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button className={"w-[300px] justify-start text-left font-normal"}>
          <CalendarIcon className="mr-1 h-4 w-4" />
          Pick time range
        </Button>
      </PopoverTrigger>
      <PopoverContent sideOffset={12} className="w-auto p-0" align="start" side="right">
        <Calendar
          fromDate={props.fromDate}
          toDate={props.toDate}
          initialFocus
          mode="range"
          max={props.maxDays}
          defaultMonth={previousMonth}
          numberOfMonths={2}
          selected={props.selectedDates}
          onSelect={(value) => {
            props.onSelect({
              from: value?.from,
              to: value?.to ? new Date(value.to?.setHours(23, 59, 59, 999)) : undefined,
            })
          }}
        />
      </PopoverContent>
    </Popover>
  )
}
