import { Action } from "@core-services/data-types"
import { AppAbility } from "@core-services/portal"
import { Button, ConfirmDialogContent, Copyable, Dialog, DialogTrigger, toast } from "@vindral/components"
import { sourceFeedSettingsInOrganization } from "../../../../acl"
import { FormSection } from "../../../../atoms/FormSection"
import { trpc } from "../../../../trpc"
import { srtIngestBaseUrl, srtIngestUrl, srtStreamId } from "../../../../utils/urls"
import { ChannelFindById } from "../../ChannelEdit"

interface SrtProps {
  ability: AppAbility
  channel: ChannelFindById
}

const ffmpegSrtCommand = (streamKey: string, passphrase: string | undefined) =>
  `docker run --rm -it jrottenberg/ffmpeg:4.4-centos -re -f lavfi -i "testsrc=size=1280x720:rate=25" -f lavfi -i "sine=frequency=440:sample_rate=48000, aformat=channel_layouts=stereo" -vcodec libx264 -preset veryfast -tune zerolatency -profile:v baseline -pix_fmt yuv420p -g 25 -acodec aac -b:a 64k -f mpegts '${srtIngestUrl(
    { streamKey, passphrase }
  )}'`

const srtUrl = srtIngestBaseUrl()

export const Srt = (props: SrtProps) => {
  const columnWidth = "w-96 shrink-0 grow-0 max-[1280px]:max-w-xs"
  const { ability, channel } = props
  const context = trpc.useContext()
  const subject = sourceFeedSettingsInOrganization(channel.organization.publicId)

  const { data: settings } = trpc.channel.getSourceFeedSettings.useQuery(
    { channelId: channel.channelId },
    { suspense: true }
  )

  const disabled = !ability.can(Action.Update, subject, "srt")

  const enableMutation = trpc.channel.regenerateSrtPassphrase.useMutation({
    onSuccess: (data) => {
      context.channel.getSourceFeedSettings.setData({ channelId: data.channelId }, data)
    },
    onError: (error) => {
      toast({ title: "Failed to save!", description: error.message })
    },
  })

  const disableMutation = trpc.channel.disableSrtPassphrase.useMutation({
    onSuccess: (data) => {
      context.channel.getSourceFeedSettings.setData({ channelId: data.channelId }, data)
    },
    onError: (error) => {
      toast({ title: "Failed to save!", description: error.message })
    },
  })

  const onClick = async () => {
    if (settings?.srt.passphrase) {
      disableMutation.reset()
      await disableMutation.mutateAsync({ channelId: settings.channelId })
    } else {
      enableMutation.reset()
      await enableMutation.mutateAsync({ channelId: channel.channelId })
    }
  }

  const ffmpegCommand = ffmpegSrtCommand(channel.streamKey, settings?.srt?.passphrase)

  return (
    <>
      <h1 className="text-xl font-medium">SRT</h1>
      <FormSection
        title="Ingest"
        description={
          <div className="flex flex-col gap-4">
            Supported on any software or device that supports broadcasting SRT. OBS and FFmpeg are two free alternatives
            frequently used.
          </div>
        }
      >
        <div className="flex flex-col gap-4">
          <div>
            <div className="text-xs font-medium uppercase tracking-wider text-fg-subtle">url</div>
            <Copyable text={srtUrl}>
              <span className="block truncate font-mono text-xs">{srtUrl}</span>
            </Copyable>
          </div>
          <div>
            <div className="text-xs font-medium uppercase tracking-wider text-fg-subtle">stream id</div>
            <Copyable text={srtStreamId({ streamKey: channel.streamKey })}>
              <span className="block truncate font-mono text-xs">{srtStreamId({ streamKey: channel.streamKey })}</span>
            </Copyable>
          </div>
        </div>
      </FormSection>
      <FormSection
        width={columnWidth}
        title="Encryption"
        description="If enabled, this passphrase must be used when ingesting, otherwise the ingest will be denied."
      >
        <div className="flex flex-col">
          <div className="text-xs font-medium uppercase tracking-wider text-fg-subtle">Passphrase</div>
          <SrtPassphrase passphrase={settings?.srt?.passphrase} disabled={disabled} onClick={onClick} />
        </div>
      </FormSection>
      <FormSection
        title="Docker ffmpeg example"
        description={
          <p>Use this simple docker ffmpeg command for running ffmpeg with sine audio and 720p25 test source.</p>
        }
      >
        <div className="flex flex-col gap-4">
          <div className="text-xs font-medium uppercase tracking-wider text-fg-subtle">Command</div>
          <Copyable text={ffmpegCommand}>
            <div data-pw="ffmpegCommand" className="min-w-0 break-words font-mono text-xs">
              {ffmpegCommand}
            </div>
          </Copyable>
        </div>
      </FormSection>
    </>
  )
}

const SrtPassphrase = (props: { passphrase?: string; disabled: boolean; onClick: () => void }) => {
  const { disabled, onClick, passphrase } = props
  const title = passphrase ? "Disable Encryption" : "Enable Encryption"
  const description = passphrase
    ? "Please note that disabling encryption will require you to update your broadcasting software to no longer use a passphrase."
    : "Please note that enabling encryption will require you to update your broadcasting software to send the passphrase."
  return (
    <div className="mb-6 flex flex-row justify-between gap-4">
      <Copyable text={passphrase ?? ""}>
        <span className="block truncate font-mono text-sm" data-pw="authsecret">
          {passphrase ?? "-"}
        </span>
      </Copyable>
      <Dialog>
        <DialogTrigger asChild>
          <Button disabled={disabled} variant="primary" className="">
            {passphrase ? "Disable Encryption" : "Enable Encryption"}
          </Button>
        </DialogTrigger>
        <ConfirmDialogContent onConfirm={onClick} title={title} description={description} buttonVariant={"primary"} />
      </Dialog>
    </div>
  )
}
