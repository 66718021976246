import { ReactNode } from "react"

export interface SaveSectionProps {
  children: ReactNode
  width?: string
}

export function SaveSection({ children, width = "w-96 max-[1280px]:max-w-xs" }: SaveSectionProps) {
  return (
    <div className="flex w-full gap-8">
      <div className={`${width} shrink-0 grow-0`}></div>
      <div className="min-w-0 grow">{children}</div>
    </div>
  )
}
