import { ApiKeyScope } from "@core-services/data-types"
import { IsNotEmpty, IsString, MaxLength } from "class-validator"
import { IsEntityName } from "../common"

export class CreateApiKeyDTO {
  @IsEntityName()
  name!: string

  @IsString()
  @MaxLength(200)
  notes!: string

  @IsNotEmpty()
  scope!: ApiKeyScope
}
