import { Expose, Transform } from "class-transformer"
import { Max, Min } from "class-validator"

export class PageDTO<T> {
  constructor(items: T[], total: number) {
    this.items = items
    this.total = total
  }

  @Expose()
  items!: T[]

  @Expose()
  total!: number
}

export class PageOptionsDTO<Entity = unknown> {
  @Min(0)
  @Max(100)
  @Expose()
  @Transform(({ value }: { value: string }) => (value ? parseInt(value, 10) : 10), { toClassOnly: true })
  take!: number

  @Min(0)
  @Expose()
  @Transform(({ value }: { value: string }) => (value ? parseInt(value, 10) : 0), { toClassOnly: true })
  skip!: number

  @Expose()
  order?: "asc" | "desc"

  @Expose()
  orderBy?: Extract<keyof Entity, string>

  @Expose()
  search?: string
}
