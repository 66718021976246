import { cva, cx } from "class-variance-authority"
import { InputHTMLAttributes, forwardRef, useEffect, useMemo, useRef } from "react"

const textField = cva(
  [
    "w-full",
    "inline-flex",
    "resize-none",
    "items-center",
    "gap-1",
    "overflow-hidden",
    "rounded-sm",
    "border",
    "outline-none",
    "transition-colors",
    "bg-transparent",
    "focus:border-divider-primary-active",
    "ring-divider-primary",
    "placeholder:text-fg-extra-subtle",
  ],
  {
    variants: {
      error: {
        false: ["border-divider-interactive"],
        true: ["border-divider-danger-active", "ring-divider-danger", "ring", "focus:ring-divider-primary"],
      },
      invisible: {
        false: ["shadow-sm", "dark:shadow-outline", "dark:shadow-black/10", "focus:ring", "px-2"],
        true: ["border-none", "bg-inherit", "shadow-none"],
      },
    },
    defaultVariants: {
      error: false,
      invisible: false,
    },
  }
)

interface TextareaAutosizeProps extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, "children" | "rows"> {
  maxRows?: number
  minRows?: number
}

export type InputProps = InputHTMLAttributes<HTMLInputElement> & { multiline?: false }
export type TextAreaProps = TextareaAutosizeProps & { multiline: true }

export type TextFieldProps = (InputProps | TextAreaProps) & { error?: boolean; invisible?: boolean }

const TextareaAutosize = forwardRef<HTMLTextAreaElement, TextareaAutosizeProps>((props, ref) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)
  const { maxRows = 50, minRows = 5, ...rest } = props
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    adjustHeight()
    props.onChange?.(event)
  }

  const adjustHeight = useMemo(
    () => () => {
      const textarea = textAreaRef.current
      if (!textarea) {
        return
      }

      textarea.style.height = "auto"
      const scrollHeight = textarea.scrollHeight + 8

      const minHeight = minRows * parseFloat(getComputedStyle(textarea).lineHeight || "0")
      const maxHeight = maxRows * parseFloat(getComputedStyle(textarea).lineHeight || "0")

      textarea.style.height = `${Math.min(Math.max(scrollHeight, minHeight), maxHeight)}px`
    },
    [maxRows, minRows]
  )

  useEffect(() => {
    if (textAreaRef.current) {
      adjustHeight()
    }
  }, [adjustHeight])

  return (
    <textarea
      {...rest}
      ref={(node) => {
        if (typeof ref === "function") {
          ref(node)
        } else if (ref) {
          ref.current = node
        }

        if (textAreaRef) {
          textAreaRef.current = node
        }
      }}
      onChange={handleChange}
      style={{
        resize: "none",
        overflowY: "auto",
        minHeight: `${minRows}em`,
        maxHeight: `${maxRows}em`,
      }}
    />
  )
})

export const TextField = forwardRef<HTMLInputElement | HTMLTextAreaElement, TextFieldProps>(
  ({ error, invisible, ...rest }, ref) => {
    if ("multiline" in rest && rest.multiline === true) {
      const { multiline, ...props } = rest
      return (
        <TextareaAutosize
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
          ref={ref as any}
          {...props}
          className={cx("p-1", textField({ error: error, invisible }))}
        />
      )
    }
    const { multiline, ...props } = rest
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
    return <input ref={ref as any} {...props} className={cx("h-7", textField({ error: error, invisible }))} />
  }
)

TextField.displayName = "TextField"
