import { cva, VariantProps } from "class-variance-authority"

const light = cva(["inline-flex", "rounded-full"], {
  variants: {
    color: {
      neutral: ["bg-gray-9"],
      green: ["bg-jade-9"],
      red: ["bg-tomato-9"],
      blue: ["bg-blue-9"],
      orange: ["bg-amber-9"],
      teal: ["bg-teal-9"],
      purple: ["bg-purple-9"],
      pink: ["bg-pink-9"],
    },
    state: {
      pinging: ["animate-ping opacity-75"],
      static: ["scale-150 opacity-40"],
    },
  },
})

type LightProps = VariantProps<typeof light>

export function Light({ color, state = "static" }: LightProps) {
  return (
    <span className="relative inline-flex h-2 w-2">
      <span className={light({ color, state, className: "absolute h-full w-full" })}></span>
      <span className={light({ color, className: "relative h-2 w-2" })}></span>
    </span>
  )
}
