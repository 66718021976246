import type { PackagerConfig } from "@core-services/data-types"
import { ChannelMode } from "@core-services/data-types"
import { Expose, Transform, Type } from "class-transformer"
import { ValidateNested } from "class-validator"
import { BaseDTO } from "../base.dto"
import { ChannelEventDTO } from "../channel-event/channel-event.dto"
import { ClientOverridesDTO } from "../client-overrides.dto"
import { ClientTelemetryDTO } from "../client-telemetry.dto"
import { DTO_GROUP_CONTROL_PANEL } from "../common"
import { LimitableDTO } from "../limitable.dto"
import { OrganizationDTO } from "../organization/organization.dto"
import { SiteDTO } from "../site/site.dto"
import { transformDate } from "../transforms"
export class ChannelDTO extends BaseDTO<ChannelDTO> {
  static readonly modelName = "Channel"

  public static default = (): ChannelDTO => {
    return new ChannelDTO({
      id: 1,
      name: "channel1",
      suspended: false,
      notes: "",
      createdAt: new Date(),
      channelId: "channel1_id",
      streamKey: "channel1_key",
      organizationId: 1,
      authRequired: false,
      clientOverrides: {},
      siteLimitation: {
        mode: "deny",
        limited: [],
      },
      mode: ChannelMode.Auto,
    })
  }

  @Expose()
  id!: number

  @Expose()
  name!: string

  @Expose()
  notes!: string

  @Expose()
  suspended!: boolean

  @Expose()
  @Transform(transformDate)
  createdAt!: Date

  @Expose()
  @Transform(transformDate)
  updatedAt?: Date

  @Expose()
  channelId!: string

  @Expose()
  streamKey!: string

  @Expose()
  organizationId!: number

  @Expose()
  @Type(() => OrganizationDTO)
  organization?: OrganizationDTO

  @Expose()
  events?: ChannelEventDTO[]

  @Expose()
  groups?: number[]

  @Expose({ groups: [DTO_GROUP_CONTROL_PANEL] })
  packager_configuration?: PackagerConfig

  @Expose({ groups: [DTO_GROUP_CONTROL_PANEL] })
  @Type(() => ClientOverridesDTO)
  @ValidateNested()
  clientOverrides?: ClientOverridesDTO

  @Expose({ groups: [DTO_GROUP_CONTROL_PANEL] })
  @Type(() => ClientTelemetryDTO)
  @ValidateNested()
  clientTelemetry?: ClientTelemetryDTO

  @Expose()
  authRequired!: boolean

  @Expose({ groups: [DTO_GROUP_CONTROL_PANEL] })
  @Type(() => SiteDTO)
  site?: SiteDTO | null

  @Expose({ groups: [DTO_GROUP_CONTROL_PANEL] })
  hostName?: string | null

  @Expose()
  mode?: ChannelMode

  @Expose()
  siteLimitation!: LimitableDTO

  @Expose({ groups: [DTO_GROUP_CONTROL_PANEL] })
  transcodingMultiplier?: number | null
}

export class ChannelDTOWithOrganization extends ChannelDTO {
  @Expose()
  organization!: OrganizationDTO
}
