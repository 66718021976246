import { IconEye, IconEyeOff } from "@tabler/icons-react"
import { InputProps, TextField } from "@vindral/components"
import { forwardRef, useState } from "react"

type PasswordTextFieldProps = Omit<InputProps, "type"> & {
  error?: boolean
}

export const PasswordTextField = forwardRef<HTMLInputElement, PasswordTextFieldProps>((props, ref) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <div className="relative">
      <TextField {...props} ref={ref} type={showPassword ? "text" : "password"} style={{ paddingRight: 36 }} />
      <button
        className="absolute right-2 top-0 flex h-full items-center transition-all active:scale-95"
        type="button"
        onClick={(e) => {
          e.preventDefault()
          setShowPassword(!showPassword)
        }}
      >
        {showPassword ? (
          <IconEyeOff size={20} stroke={1} className="stroke-fg-subtle" />
        ) : (
          <IconEye size={20} stroke={1} className="stroke-fg-subtle" />
        )}
      </button>
    </div>
  )
})
