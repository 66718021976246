import { ExternalLinkIcon } from "@radix-ui/react-icons"

export function Hyperlink({ href, text, external }: { href: string; text: string; external?: boolean }) {
  return (
    <a
      href={href}
      target="_blank"
      className="font-medium text-fg underline decoration-solid-primary decoration-2 underline-offset-2 hover:decoration-fg"
    >
      {text}
      {external && <ExternalLinkIcon width={12} height={12} className="ml-1 inline-block align-text-bottom" />}
    </a>
  )
}
