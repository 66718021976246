import { AppAbility, UpdateRecordingSink } from "@core-services/portal"
import { getClippingUrl } from "@core-services/utils"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { Button, FormControl, FormControlLabel, Switch, TextField, formatDate } from "@vindral/components"
import { useEffect } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { useForm } from "react-hook-form"
import { RouterOutputs, trpc } from "../../..//trpc"
import { checkIfSuperUser } from "../../../acl"
import { FormSection } from "../../../atoms/FormSection"
import { SaveButton } from "../../../atoms/SaveButton"
import { SaveSection } from "../../../atoms/SaveSection"
import { FormattedDuration } from "../../../molecules/FormattedDuration"
import { Message, MessageSection } from "../../../molecules/Message"
import { RouteBlockDialog } from "../../../molecules/RouteBlockDialog"
import { InteractiveTable } from "../../../organisms/InteractiveTable"
import { NoResultsFound } from "../../../organisms/NoResultsFound"
import { integerOrNull } from "../../../utils/numberOrNull"
const resolver = zodResolver(UpdateRecordingSink)

type Recording = RouterOutputs["sink"]["findRecordingSink"] | undefined

export interface ChannelEditDetailsRecordingProps {
  channelId: string
  streamKey: string
  ability: AppAbility
  recordingSink: Recording
  canEdit: boolean
  onSave: (recording: UpdateRecordingSink) => Promise<void>
}

export function ChannelEditDetailsRecording({
  ability,
  recordingSink,
  canEdit,
  channelId,
  streamKey,
  onSave,
}: ChannelEditDetailsRecordingProps) {
  const { register, handleSubmit, setValue, formState, reset } = useForm({
    resolver,
    mode: "onChange",
    defaultValues: {
      enabled: recordingSink?.enabled ?? false,
      retentionDays: recordingSink?.retentionDays ?? 2,
      channelId,
    },
  })

  const isSuperUser = checkIfSuperUser({ ability })

  const { errors, isDirty, isValid, isSubmitting, isSubmitSuccessful } = formState

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful, reset])

  const submit = async (recordingSink: UpdateRecordingSink) => {
    const data = {
      ...recordingSink,
    }
    await onSave(data)
  }

  return (
    <div className="space-y-4 py-4">
      <RouteBlockDialog condition={isDirty && !isSubmitting} />
      {!canEdit ? (
        <Message severity="warning">
          <MessageSection>
            <div className="flex w-full">
              <div className="grow">
                <span>
                  Managing recording settings is not yet available for your organization. Please contact support if you
                  wish to enable recording
                </span>
              </div>
            </div>
          </MessageSection>
        </Message>
      ) : null}

      <form onSubmit={handleSubmit(submit)} className="flex flex-col gap-8">
        <FormSection
          title="Recording"
          description={
            <div>
              <p>Toggle the switch to enable or disable recording of the live stream.</p>
              <p className="mt-4">
                Enter the number of days you want the recording to be saved for in the input field provided. After the
                specified number of days, the recording will be automatically deleted.
              </p>
              <p className="mt-4">
                Please note that updating the recording settings will not affect the current live stream.
              </p>
            </div>
          }
          docsLink="https://docs.vindral.com/broadcast/recording"
        >
          <div className="flex flex-col gap-4">
            <FormControl>
              <FormControlLabel error={errors.enabled?.message}>Enable recording</FormControlLabel>
              <Switch
                defaultChecked={recordingSink?.enabled}
                disabled={!canEdit}
                onCheckedChange={(value) => {
                  setValue("enabled", value, { shouldDirty: true })
                }}
                label=""
              />
            </FormControl>

            <FormControl>
              <FormControlLabel error={errors.retentionDays?.message}>Retention (days)</FormControlLabel>
              <TextField
                {...register("retentionDays", { setValueAs: integerOrNull })}
                type="number"
                error={!!errors.retentionDays}
                disabled={!canEdit}
              />
            </FormControl>
          </div>
        </FormSection>

        <SaveSection>
          <SaveButton text="Save" disabled={!canEdit || !isDirty || !isValid} />
        </SaveSection>
      </form>
      <ErrorBoundary
        FallbackComponent={() =>
          isSuperUser ? (
            <Message severity="error">
              <MessageSection>
                <div className="flex w-full">
                  <div className="grow">
                    <span>Something went wrong while fetching recorded clips</span>
                  </div>
                </div>
              </MessageSection>
            </Message>
          ) : null
        }
      >
        <ClippingTable streamKey={streamKey} channelId={channelId} ability={ability} />
      </ErrorBoundary>
    </div>
  )
}

function Duration({ start, end }: { start: string; end: string }) {
  return <FormattedDuration endDate={end ? new Date(end) : new Date()} startDate={new Date(start)} />
}

interface Clip {
  start: string
  end: string
}

export type ClippingTableProps = {
  streamKey: string
  channelId: string
  ability: AppAbility
}

function ClippingTable({ ability, streamKey, channelId }: ClippingTableProps) {
  const isSuperUser = checkIfSuperUser({ ability })

  const { data } = trpc.clipping.getRecordingData.useQuery(
    { channelId },
    { suspense: true, refetchInterval: 5000, useErrorBoundary: true }
  )

  const recordedClips = data?.recordedClips ?? []

  const columnHelper = createColumnHelper<Clip>()

  const columns = [
    columnHelper.accessor("start", {
      id: "start",
      header: () => "Started At",
      cell: (info) => formatDate(new Date(info.getValue())),
    }),
    columnHelper.display({
      id: "duration",
      header: () => "Duration",
      cell: (info) => <Duration start={info.row.original.start} end={info.row.original.end} />,
    }),
    columnHelper.display({
      id: "tools",
      header: () => "Tools",
      cell: (info) => {
        const url = getClippingUrl({
          edgeUrl: data?.edgeUrl ?? "",
          streamKey,
          from: new Date(info.row.original.start),
          to: new Date(info.row.original.end),
        })
        return (
          <a href={url} target="_blank">
            <Button disabled={!data?.edgeUrl}>View</Button>
          </a>
        )
      },
    }),
  ]

  const table = useReactTable({
    data: recordedClips,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowId: (row) => String(row.start),
  })

  // If there is a filter AND no rows - that means we should show "no results".
  // This is different from zero rows and no filter - in that case we should show
  // a call to action.
  const noResults = table.getRowModel().rows.length === 0 && !!table.getState().globalFilter

  if (!isSuperUser) {
    return null
  }

  return (
    <div className="flex min-h-0 grow flex-col">
      <h1 className="mb-1 font-semibold">Recorded clips</h1>

      {noResults ? <NoResultsFound /> : <InteractiveTable table={table} />}
    </div>
  )
}
