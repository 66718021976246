import { Type } from "class-transformer"
import {
  ArrayMaxSize,
  ArrayMinSize,
  IsArray,
  IsInt,
  IsNumber,
  IsString,
  Length,
  Min,
  Validate,
  ValidateIf,
} from "class-validator"
import { AccessPermissionsDTO } from "./access-permissions/access-permissions.dto"
import { ValidatorComposer } from "./composer"
import { WhitespaceConstraint } from "./constraints"

const MESSAGE_NOT_LESS_THAN_ZERO = "Must not be less than 0"

export const IsEntityName = ValidatorComposer([IsString(), Length(2, 60), Validate(WhitespaceConstraint)])

export const IsNonEmptyString = ValidatorComposer([IsString(), Length(1, 60), Validate(WhitespaceConstraint)])

export const IsNullable = ValidatorComposer([ValidateIf((_object, value) => value !== null)])

export const IsPositiveInteger = ValidatorComposer([
  IsInt({ message: "Must be an integer number" }),
  Min(0, { message: MESSAGE_NOT_LESS_THAN_ZERO }),
])

export const IsPositiveFloat = ValidatorComposer([
  IsNumber({ maxDecimalPlaces: 4 }, { message: "Must be a number with at most 4 decimals" }),
  Min(0, { message: MESSAGE_NOT_LESS_THAN_ZERO }),
])

export const IsAccessPermissions = ValidatorComposer([
  IsArray(),
  ArrayMinSize(1, { message: "at least one organization is required" }),
  ArrayMaxSize(1, { message: "more than one organization is not supported" }),
  Type(() => AccessPermissionsDTO),
])

export const DTO_GROUP_PORTAL = "portal"
export const DTO_GROUP_CONTROL_PANEL = "control-panel"
export const DTO_GROUPS = [DTO_GROUP_PORTAL, DTO_GROUP_CONTROL_PANEL]

// Make optional values required.
export type RequiredKeys<T, K extends keyof T> = Exclude<T, K> & Required<Pick<T, K>>
