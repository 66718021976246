import { AccessPermissionRole } from "@core-services/data-types"
import { z } from "zod"

export const CreateUser = z.object({
  password: z.string().min(8).max(128),
  email: z.string().email(),
  name: z.string().min(1).max(100),
  accessPermissions: z.array(
    z.object({
      role: z.nativeEnum(AccessPermissionRole),
      organization: z.object({
        publicId: z.string(),
      }),
    })
  ),
})

export type CreateUser = z.infer<typeof CreateUser>
