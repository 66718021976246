import { useMemo, useState } from "react"
import { TranscodingProfile } from "../interfaces/TranscodingProfile"
import { trpc } from "../trpc"

function getNextPageParam<T>(lastPage: { total: number }, allPages: { total: number; items: T[] }[]) {
  const nextSkip = allPages.reduce((prev, page) => prev + page.items.length, 0)
  return nextSkip < lastPage.total ? nextSkip : undefined
}

export type SelectedTranscodingProfile = Pick<TranscodingProfile, "name" | "publicId">

export function useTranscodingProfileSelect({
  organizationId,
  defaultSelected,
}: {
  organizationId?: string
  defaultSelected?: SelectedTranscodingProfile | null
}) {
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<SelectedTranscodingProfile | null>(defaultSelected ?? null)
  const [search, setSearch] = useState("")
  const query = trpc.transcodingProfile.findMany.useInfiniteQuery(
    {
      orderBy: "name",
      filter: organizationId ? { organization: { publicId: organizationId } } : undefined,
      search: search.length > 0 ? search : undefined,
    },
    { getNextPageParam, keepPreviousData: true, enabled: !!organizationId }
  )

  const options = useMemo(() => {
    return [
      {
        id: "options",
        items: query.data?.pages.flatMap((pages) => pages.items) ?? [],
      },
    ]
  }, [query])

  return { query, options, selected, setSelected, search, setSearch, open, setOpen }
}
