import { z } from "zod"
import { normalizeIdentifier } from "../normalize"

export const CreateChannelGroup = z.object({
  name: z
    .string()
    .min(2)
    .max(50)
    .refine((val) => normalizeIdentifier(val).length >= 2, {
      message: "Normalized name must be at least 2 characters long",
    }),
  organizationPublicId: z.string(),
})

export type CreateChannelGroup = z.infer<typeof CreateChannelGroup>
