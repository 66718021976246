import { Spinner } from "@vindral/components"

export function OrganizationUniqueNameStatus(props: {
  input?: string
  uniqueName?: string
  checkinguniqueName?: boolean
}) {
  const { input, uniqueName, checkinguniqueName } = props

  return (
    <div className="my-2 flex h-4 w-full items-center gap-2">
      {input && (
        <div className="text-xs text-fg-subtle">
          Organization identifier: <span className="font-semibold">{uniqueName}</span>
        </div>
      )}
      {checkinguniqueName && (
        <div>
          <Spinner size="small" />
        </div>
      )}
    </div>
  )
}
