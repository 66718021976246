import { Logo } from "../../atoms/logos/Logo"

export const MaxOrganizationsReached = () => {
  return (
    <div className="flex h-screen flex-col-reverse lg:flex-row">
      <div className="flex h-full justify-center gap-4 bg-canvas-1 pt-80 lg:w-1/2">
        <div className="z-10 flex w-1/2 max-w-md flex-col gap-4">
          {/* <h1 className="text-xl font-medium">Not able to register test accounts at the moment.</h1> */}
          <h1 className="text-xl font-medium">You are on the waitlist to test Vindral.</h1>
          <p>There are a lot of ongoing tests right now. We will notify you as soon as a slot opens up.</p>
        </div>
      </div>
      <div className=" relative hidden h-full justify-center overflow-hidden bg-canvas-alt pt-80 lg:flex lg:w-1/2">
        <Logo className="absolute left-0 top-1/3 scale-150 text-component" />
        <div className="z-10 flex w-1/2 max-w-md flex-col gap-4">
          <h2 className="text-center text-2xl">You will hear from us soon!</h2>
        </div>
      </div>
    </div>
  )
}
