import { Expose, Type } from "class-transformer"
import { Allow, IsNumber, IsOptional, IsString } from "class-validator"

export class RtmpIngestStreamVideoMetadata {
  constructor(props?: { [K in keyof RtmpIngestStreamVideoMetadata]: RtmpIngestStreamVideoMetadata[K] }) {
    Object.assign(this, props)
  }

  @Expose()
  @IsNumber()
  width!: number

  @Expose()
  @IsNumber()
  height!: number

  @Expose()
  @IsNumber()
  frameRate!: number

  @Expose()
  @IsString()
  codec!: string

  @Expose()
  @IsString()
  profile!: string

  @Expose()
  @IsString()
  level!: string
}

export class RtmpIngestStreamAudioMetadata {
  constructor(props?: { [K in keyof RtmpIngestStreamAudioMetadata]: RtmpIngestStreamAudioMetadata[K] }) {
    Object.assign(this, props)
  }

  @Expose()
  @IsString()
  codec!: string

  @Expose()
  @IsString()
  profile!: string

  @Expose()
  @IsNumber()
  channels!: number

  @Expose()
  @IsNumber()
  sampleRate!: number
}

export class RtmpIngestClientMeta {
  constructor(props?: { [K in keyof RtmpIngestClientMeta]: RtmpIngestClientMeta[K] }) {
    Object.assign(this, props)
  }

  @Expose()
  @IsString()
  address!: string

  @Expose()
  @IsString()
  flashVersion!: string

  @Expose()
  @IsString()
  swfUrl!: string
}

export abstract class ChannelEventExtraDTO {}

export class ChannelEventMetadataDTO extends ChannelEventExtraDTO {
  constructor(props?: { [K in keyof ChannelEventMetadataDTO]: ChannelEventMetadataDTO[K] }) {
    super()
    Object.assign(this, props)
  }

  @Expose()
  @IsOptional()
  @Type(() => RtmpIngestStreamVideoMetadata)
  videoMetadata?: RtmpIngestStreamVideoMetadata

  @Expose()
  @IsOptional()
  @Type(() => RtmpIngestStreamAudioMetadata)
  audioMetadata?: RtmpIngestStreamAudioMetadata

  @Expose()
  @Allow()
  @Type(() => RtmpIngestClientMeta)
  publisherMetadata!: RtmpIngestClientMeta
}
