import { formatDistance, formatISO9075 } from "date-fns"

export const formatDate = (date: Date) => {
  return formatISO9075(date)
}

export const formatDateDistance = (date: Date) => {
  return formatDistance(date, new Date(), {
    addSuffix: true,
  })
}
