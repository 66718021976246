import { Light, Spinner } from "@vindral/components"
import { AxisBottom, AxisRight } from "@visx/axis"
import { curveMonotoneX } from "@visx/curve"
import { localPoint } from "@visx/event"
import { GridRows } from "@visx/grid"
import { Group } from "@visx/group"
import { scaleLinear, scaleTime } from "@visx/scale"
import { Bar, Line, LinePath } from "@visx/shape"
import { TooltipWithBounds, defaultStyles, withTooltip } from "@visx/tooltip"
import { WithTooltipProvidedProps } from "@visx/tooltip/lib/enhancers/withTooltip"
import { bisector } from "d3-array"
import * as datefns from "date-fns"
import { useCallback, useMemo } from "react"

const { color: _unusedColor, background: _unusedBG, backgroundColor: _unusedBgColor, ...tooltipStyles } = defaultStyles

/** [time, value] */
type Point = [number, number]
export type LinePathGraphDataSet = Point[]

const getY = (p: Point) => p[1]
const getX = (p: Point) => p[0]
// eslint-disable-next-line @typescript-eslint/unbound-method
const bisectDate = bisector<Point, Date>((m) => new Date(getX(m))).left

type ToolTipType = {
  mainTime: number
  subTime: number | undefined
  mainValue: number
  subValue: number | undefined
  mainIndicatorValueTop: number | undefined
  subIndicatorValueTop: number | undefined
}

export type LinePathGraphProps = {
  mainData: LinePathGraphDataSet
  subData?: LinePathGraphDataSet
  width: number
  height: number
  isLoading: boolean
  margin?: { top: number; right: number; bottom: number; left: number }
  formatValue?: (value: number | undefined) => string
  formatYTicks?: (value: number | undefined) => string
  formatXTicks?: (value: number | Date) => string
  /** [wideTicks, narrowTicks] */
  numXTicks?: [number, number]
}
export const LinePathGraph = withTooltip<LinePathGraphProps, ToolTipType>(
  (props: LinePathGraphProps & WithTooltipProvidedProps<ToolTipType>) => {
    const {
      mainData = [],
      subData = [],
      width,
      height,
      margin = { top: 40, right: 80, bottom: 50, left: 0 },
      showTooltip,
      hideTooltip,
      tooltipData,
      tooltipTop = 0,
      tooltipLeft = 0,
      isLoading,
      formatValue = (value) => value?.toString() || "",
      formatYTicks,
      formatXTicks,
      numXTicks,
    } = props

    // Distance between start of the main and sub graph in milliseconds
    const xStartDiff = mainData[0] && subData[0] ? getX(mainData[0]) - getX(subData[0]) : 0

    const domainPaddingMax = 0.08
    const domainPaddingMin = 0.05

    const yScale = useMemo(() => {
      const mainMax = Math.max(...mainData.map(getY))
      const subMax = Math.max(...subData.map(getY))
      const max = Math.max(mainMax, subMax)

      const mainMin = Math.min(...mainData.map(getY))
      const subMin = Math.min(...subData.map(getY))
      const min = Math.min(mainMin, subMin)

      const diffMinMax = max - min

      const domainMax = Math.ceil(Math.max(1, max + diffMinMax * domainPaddingMax))
      const domainMin = Math.floor(Math.max(0, min - diffMinMax * domainPaddingMin))
      return scaleLinear<number>({
        domain: [domainMin, domainMax],
        nice: true,
      })
    }, [mainData, subData])

    const xScale = useMemo(() => {
      return scaleTime<number>({
        domain: [Math.min(...mainData.map(getX)), Math.max(...mainData.map(getX))],
      })
    }, [mainData])

    const xSizeMax = width - margin.left - margin.right
    const ySizeMax = height - margin.top - margin.bottom
    xScale.range([0, xSizeMax])
    yScale.range([ySizeMax, 0])

    const handleTooltip = useCallback(
      (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
        const { x, y } = localPoint(event) || { x: 0, y: 0 }
        const x0 = xScale.invert(x)

        const index = bisectDate(mainData, x0, 1)
        const mainD0 = mainData[index - 1]
        const mainD1 = mainData[index]
        let mainD = mainD0
        if (mainD0 && mainD1 && getX(mainD1)) {
          mainD = x0.valueOf() - getX(mainD0).valueOf() > getX(mainD1).valueOf() - x0.valueOf() ? mainD1 : mainD0
        }

        const subX0 = datefns.sub(x0, { seconds: xStartDiff / 1000 })
        const subIndex = bisectDate(subData, subX0, 1)
        const subD0 = subData[subIndex - 1]
        const subD1 = subData[subIndex]

        let subD = subD0
        if (subD0 && subD1 && getX(subD1)) {
          subD = x0.valueOf() - getX(subD0).valueOf() > getX(subD1).valueOf() - x0.valueOf() ? subD1 : subD0
        }

        if (!mainD) return

        showTooltip({
          tooltipData: {
            mainTime: getX(mainD),
            mainValue: getY(mainD),
            mainIndicatorValueTop: yScale(getY(mainD)),
            subTime: subD ? getX(subD) : undefined,
            subValue: subD ? getY(subD) : undefined,
            subIndicatorValueTop: subD ? yScale(getY(subD)) : undefined,
          },
          tooltipLeft: x,
          tooltipTop: y,
        })
      },
      [showTooltip, yScale, xScale, mainData, subData, xStartDiff]
    )

    if (width < 10) return <></>

    return (
      <div className="relative min-w-0">
        <svg width={width} height={height} className="overflow-visible">
          <Group left={margin.left} top={margin.top}>
            <GridRows
              scale={yScale}
              width={xSizeMax}
              height={ySizeMax}
              className="stroke-component"
              stroke="inherit"
              numTicks={5}
            />

            <AxisBottom
              axisLineClassName="stroke-component"
              tickLineProps={{
                className: "stroke-component",
              }}
              tickLabelProps={() => ({
                className: "stroke-0 fill-fg-subtle text-sm",
                textAnchor: "middle",
              })}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, total-functions/no-unsafe-type-assertion, @typescript-eslint/no-explicit-any
              tickFormat={formatXTicks as unknown as any}
              top={ySizeMax}
              scale={xScale}
              numTicks={numXTicks ? (width > 520 ? numXTicks[0] : numXTicks[1]) : width > 520 ? 10 : 5}
            />
            <AxisRight
              left={xSizeMax}
              scale={yScale}
              axisLineClassName="stroke-component"
              tickLineProps={{
                className: "stroke-component",
              }}
              tickFormat={(value) => {
                return formatYTicks ? `${formatYTicks(value.valueOf())}` : value.toString()
              }}
              tickLabelProps={() => ({
                className: "stroke-0 fill-fg-subtle text-sm",
                textAnchor: "start",
              })}
              numTicks={5}
            />
            <LinePath<Point>
              data={mainData}
              curve={curveMonotoneX}
              x={(d) => {
                return xScale(getX(d))
              }}
              y={(d) => yScale(getY(d))}
              className="stroke-blue-9"
              strokeWidth={1.5}
              strokeOpacity={1}
            />

            <LinePath<Point>
              data={subData}
              curve={curveMonotoneX}
              x={(d) => {
                return xScale(getX(d) + xStartDiff)
              }}
              y={(d) => yScale(getY(d))}
              className="stroke-amber-9"
              strokeWidth={1.5}
              strokeOpacity={0.5}
              strokeDasharray="2,4"
            />

            <Bar
              x={margin.left}
              y={0}
              width={xSizeMax}
              height={ySizeMax}
              fill="transparent"
              rx={14}
              onTouchStart={handleTooltip}
              onTouchMove={handleTooltip}
              onMouseMove={handleTooltip}
              onMouseLeave={() => hideTooltip()}
            />

            {tooltipData && (
              <g>
                <Line
                  from={{ x: tooltipLeft, y: 0 }}
                  to={{ x: tooltipLeft, y: ySizeMax }}
                  className="stroke-divider-interactive"
                  strokeWidth={1}
                  pointerEvents="none"
                  strokeDasharray="5,2"
                />
                <circle
                  cx={tooltipLeft}
                  cy={tooltipData.subIndicatorValueTop}
                  r={4}
                  strokeOpacity={0.7}
                  className="fill-amber-9"
                  stroke="white"
                  strokeWidth={2}
                  pointerEvents="none"
                />
                <circle
                  cx={tooltipLeft}
                  cy={tooltipData.mainIndicatorValueTop}
                  r={4}
                  className="fill-blue-9"
                  strokeOpacity={0.7}
                  stroke="white"
                  strokeWidth={2}
                  pointerEvents="none"
                />
              </g>
            )}
          </Group>
        </svg>
        {typeof tooltipData?.mainValue !== "undefined" && (
          <div>
            <TooltipWithBounds
              key={Math.random()}
              top={tooltipTop - 12}
              left={tooltipLeft + 12}
              style={tooltipStyles}
              className="border border-divider bg-component text-fg"
            >
              <div className="text-sm">
                <div className="pb-1">
                  <div className="pb-1">
                    <span className="text-sm font-medium">{`${datefns.format(
                      new Date(tooltipData.mainTime),
                      "yyyy-MM-dd HH:mm:ss"
                    )}`}</span>
                  </div>
                  <div className="flex items-center align-middle">
                    <Light color="blue" />
                    <span className="ml-2">{`${formatValue(tooltipData?.mainValue)}`}</span>
                  </div>
                </div>
                {typeof tooltipData?.subValue !== "undefined" && typeof tooltipData?.subTime !== "undefined" && (
                  <>
                    <div className="pb-1">
                      <span className="text-sm font-medium">{`${datefns.format(
                        new Date(tooltipData.subTime),
                        "yyyy-MM-dd HH:mm:ss"
                      )}`}</span>
                    </div>
                    <div className="flex items-center">
                      <Light color="orange" />
                      <span className="ml-2">{`${formatValue(tooltipData?.subValue)}`}</span>
                    </div>
                  </>
                )}
              </div>
            </TooltipWithBounds>
          </div>
        )}
        {!isLoading && mainData.length === 0 && (
          <div
            className="absolute left-0 w-full overflow-hidden text-ellipsis text-center font-medium"
            style={{
              top: `calc(${(height - margin.bottom - margin.top) / 2 + margin.top}px - 0.5rem)`,
              width: `calc(100% - ${margin.left + margin.right}px)`,
            }}
          >
            No data for selected parameters
          </div>
        )}
        {isLoading && mainData.length === 0 && (
          <div
            className="absolute left-0 w-full text-center"
            style={{
              top: `calc(${(height - margin.bottom - margin.top) / 2 + margin.top}px - 1rem)`,
              width: `calc(100% - ${margin.left + margin.right}px)`,
            }}
          >
            <Spinner />
          </div>
        )}
      </div>
    )
  }
)
