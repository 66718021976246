import { useAbility } from "@casl/react"
import { Action } from "@core-services/data-types"
import { CreateRegion } from "@core-services/portal/src/validation/region/region.create"
import { Route, useNavigate } from "@tanstack/react-router"
import { toast } from "@vindral/components"
import { regionIndexRoute } from "."
import { AbilityContext } from "../../acl"
import { RegionCreate } from "../../templates/infrastructure/region/RegionCreate"
import { trpc } from "../../trpc"

export const regionCreateRoute = new Route({
  getParentRoute: () => regionIndexRoute,
  preSearchFilters: [(search) => search],
  path: "create",
  component: Page,
  getContext: () => {
    return {
      pageTitle: "Create",
    }
  },
})

function Page() {
  const ability = useAbility(AbilityContext)
  const navigate = useNavigate()
  const context = trpc.useContext()

  if (!ability.can(Action.Create, "Region")) {
    return null
  }

  const createMutation = trpc.region.create.useMutation({
    onSuccess: (region) => {
      if (!region || !region.publicId) {
        // This really shouldn't happen
        toast({ title: "Failed to create region!" })
        return
      }

      const regionPublicId = region.publicId
      context.region.findById.setData({ publicId: regionPublicId }, region)

      toast({ title: `Created region ${region.name}` })
      void navigate({
        to: "/regions/edit/$publicId",
        params: { publicId: regionPublicId },
        replace: true,
      })
    },
    onError: (error) => {
      toast({ title: "Failed to create region!", description: error.message })
    },
  })

  const createRegion = async (region: CreateRegion) => {
    createMutation.reset()
    await createMutation.mutateAsync(region)
  }

  return <RegionCreate createRegion={createRegion} />
}
