export const notAllowedDomains = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "outlook.com",
  "live.com",
  "msn.com",
  "icloud.com",
  "yogirt.com",
  "pm.me",
  "techie.com",
  "emailbbox.pro",
  "w3fax.com",
  "rfcdrive.com",
]

export const isEmailDomainForbidden = (event: { email: string }) => {
  const emailDomain = event.email.split("@")[1] || ""
  return notAllowedDomains.includes(emailDomain.toLowerCase())
}
