import { subject } from "@casl/ability"
import { useAbility } from "@casl/react"
import { Action } from "@core-services/data-types"
import { UpdateUser } from "@core-services/portal"
import { Route, useNavigate, useParams } from "@tanstack/react-router"
import { toast } from "@vindral/components"
import { userIndexRoute } from "."
import { AbilityContext, pickPermittedFields } from "../../acl"
import { UserEdit } from "../../templates/user/UserEdit"
import { trpc } from "../../trpc"

function Page() {
  const { id } = useParams({ from: userEditRoute.id })
  const { data: user } = trpc.user.findById.useQuery({ publicId: id }, { suspense: true })
  const ability = useAbility(AbilityContext)
  const navigate = useNavigate()

  if (!user) {
    return null
  }

  const context = trpc.useContext()
  const userSubject = subject("User", { publicId: user.publicId, accessPermissions: user.accessPermissions })

  const updateMutation = trpc.user.update.useMutation({
    onSuccess: () => {
      void context.user.findById.invalidate({ publicId: user.publicId })
      toast({ title: "User updated" })
    },
    onError: (error) => {
      toast({ title: "Failed to update user!", description: error.message })
    },
  })
  const deleteMutation = trpc.user.delete.useMutation({
    onSuccess: () => {
      void context.user.findById.invalidate({ publicId: user.publicId })
      toast({ title: "User deleted" })
      void navigate({ to: "/users", replace: true })
    },
    onError: (error) => {
      toast({ title: "Failed to delete user!", description: error.message })
    },
  })

  const updateUser = async (updated: UpdateUser) => {
    const permitted = pickPermittedFields(ability, Action.Update, userSubject, {
      name: updated.name,
      email: updated.email,
      password: updated.password || undefined,
      accessPermissions: updated.accessPermissions,
    })

    updateMutation.reset()
    await updateMutation.mutateAsync({ ...permitted, id: user.publicId })
  }

  const deleteUser = async () => {
    deleteMutation.reset()
    await deleteMutation.mutateAsync(user)
  }

  return <UserEdit user={user} updateUser={updateUser} deleteUser={deleteUser} />
}

export const userEditRoute = new Route({
  getParentRoute: () => userIndexRoute,
  preSearchFilters: [(search) => search],
  path: "edit/$id",
  component: Page,
  getContext: () => {
    return {
      pageTitle: "Edit user",
    }
  },
})
