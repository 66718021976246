import { denyNothingNumberLimitation } from "@core-services/data-types"
import { UpdateSite } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect } from "react"
import { useController, useFieldArray, useForm } from "react-hook-form"
import { z } from "zod"
import { FormSection } from "../../../../atoms/FormSection"
import { SaveButton } from "../../../../atoms/SaveButton"
import { SaveSection } from "../../../../atoms/SaveSection"
import { LimitationModeSelect } from "../../../../molecules/LimitationModeSelect"
import { OrganizationMultiSelector } from "../../../../organisms/multiselect/OrganizationMultiSelector"
import { SiteEditPageProps } from "../../../../routes/site/site-edit"

const resolver = zodResolver(UpdateSite.merge(z.object({ organizationLimitation: z.any() })))

export const SiteEditOrganizationRules = ({
  site,
  updateSite,
  limitedOrganizations,
}: SiteEditPageProps & { limitedOrganizations: { publicId: string; name: string; id: number }[] }) => {
  const organizationLimitation = site.organizationLimitation ?? denyNothingNumberLimitation
  const form = useForm({
    resolver,
    mode: "onChange",
    defaultValues: {
      ...site,
      organizationLimitation: {
        mode: organizationLimitation.mode,
        limited: limitedOrganizations.map((l) => ({ organizationId: l.id, publicId: l.publicId })),
      },
    },
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, isSubmitSuccessful },
  } = form

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful, reset])

  const organizationLimitationMode = useController({ control, name: "organizationLimitation.mode" })
  const organizationFieldArray = useFieldArray({ control, name: "organizationLimitation.limited" })

  return (
    <form
      className="flex flex-col gap-4 py-4"
      onSubmit={handleSubmit((site) => {
        return updateSite({
          ...site,
          organizationLimitation: {
            mode: site.organizationLimitation.mode,
            limited: site.organizationLimitation.limited.map((limited) => limited.organizationId),
          },
        })
      })}
    >
      <FormSection title="Channel Limitation" description="Limit which channels may use this site.">
        <div className="flex flex-col gap-4">
          <div>
            <LimitationModeSelect
              value={organizationLimitationMode.field.value}
              onValueChange={(newMode) => organizationLimitationMode.field.onChange(newMode)}
            />
          </div>
          <div
            className={
              organizationLimitationMode.field.value === "disabled" ? "pointer-events-none select-none opacity-50" : ""
            }
          >
            <OrganizationMultiSelector
              onSelect={(item) => {
                organizationFieldArray.prepend({ organizationId: item.id, publicId: item.publicId })
              }}
              onDeselect={(index) => organizationFieldArray.remove(index)}
              selected={organizationFieldArray.fields}
              nothingSelectedText="Select organizations from the dropdown to apply selected limitation to them"
            />
          </div>
        </div>
      </FormSection>
      <SaveSection>
        <SaveButton text="Save" disabled={!isDirty || !isValid} />
      </SaveSection>
    </form>
  )
}
