import { Toast, ToastClose, ToastContent, ToastDescription, ToastProvider, ToastTitle } from "./Toast"
import { useToast } from "./useToast"

export function Toaster() {
  const { toasts, dismiss } = useToast()

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, action, ...props }) {
        return (
          <Toast key={id} {...props} onClick={() => dismiss(id)}>
            <ToastContent>
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && <ToastDescription>{description}</ToastDescription>}
            </ToastContent>
            {action}
            <ToastClose />
          </Toast>
        )
      })}
    </ToastProvider>
  )
}
