import { Copyable } from "@vindral/components"
import { FormSection } from "../../../../atoms/FormSection"
import { embedPlayerUrl } from "../../../../utils/urls"
import { ChannelFindById } from "../../ChannelEdit"

export const EmbedSnippet = (props: { channel: ChannelFindById }) => {
  const { channel } = props
  const embedPlayer = embedPlayerUrl({
    channelId: channel.channelId,
    authToken: channel.authToken,
  })

  return (
    <>
      <h1 className="mb-1 text-xl font-medium">Embed snippet</h1>
      <FormSection
        title="Embeddable Player"
        docsLink="https://docs.vindral.com/playout/embeddable-player/"
        description={
          <p>Embed your channel on your website by utilizing the iframe snippet from our CDN-hosted player.</p>
        }
      >
        <div className="flex flex-col gap-4">
          <div className="text-xs font-medium uppercase tracking-wider text-fg-subtle">Embed snippet</div>
          <Copyable
            text={`<iframe
                        src="${embedPlayer}"
                        frameborder="0"
                        allowfullscreen
                    ></iframe>`}
          >
            <code className="min-w-0 break-words font-mono text-xs">
              {`<iframe
                    src="${embedPlayer}"
                    frameborder="0"
                    allowfullscreen
                ></iframe>`}
            </code>
          </Copyable>
        </div>
      </FormSection>
    </>
  )
}
