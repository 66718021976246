import { IconPlus } from "@tabler/icons-react"
import { Link } from "@tanstack/react-router"
import { Button } from "@vindral/components"
import { Page, PageContainer, PageContent, PageHeader } from "../../../atoms/Page"
import { Breadcrumbs } from "../../../molecules/Breadcrumbs"
import { SiteTable, type SiteTableProps } from "../../../organisms/SiteTable"
import { WorldMap, WorldMapPin } from "../../../organisms/map/WorldMap"

export type SiteListProps = SiteTableProps & {
  isStaleData: boolean
  mapPins: WorldMapPin[]
}

export function SiteList({ isStaleData, ...props }: SiteListProps) {
  if (props.data.length === 0 && !props.state?.globalFilter && !isStaleData) {
    return (
      <div className="relative flex justify-center pt-40">
        <div>
          <h1 className="font-medium">There is nothing here right now</h1>
          <p className="py-1 text-fg-subtle last-of-type:pb-3">You will be able to see sites here very soon.</p>
        </div>
      </div>
    )
  }

  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
            <Link to="/sites/create">
              <Button variant="primary">
                <IconPlus size={16} />
                Create site
              </Button>
            </Link>
          </div>
        </PageHeader>
        <PageContainer>
          <div className="py-4">
            <SiteTable {...props} />
            <div className="pt-4">
              <WorldMap
                {...{
                  maxRadius: 1,
                  minRadius: 1,
                  metric: "viewers",
                  pins: props.mapPins,
                }}
              />
            </div>
          </div>
        </PageContainer>
      </PageContent>
    </Page>
  )
}
