import { Link, LinkPropsOptions } from "@tanstack/react-router"
import { VariantProps, cva } from "class-variance-authority"
import { ReactNode } from "react"

const routeTabs = cva(["flex", "gap-6", "border-b", "border-divider"], {
  variants: {
    border: {
      none: ["border-none"],
    },
  },
})

export function RouteTabs({ border, children }: { children: ReactNode } & VariantProps<typeof routeTabs>) {
  const className = routeTabs({ border })
  return <div className={className}>{children}</div>
}

const routeTrigger = cva(
  [
    "relative",
    "top-px",
    "flex",
    "border-b-2",
    "border-transparent",
    "py-2",
    "font-medium",
    "tracking-wide",
    "text-fg-subtle",
    "transition-all",
    "data-[status=active]:border-solid-primary",
    "data-[status=active]:text-fg",
  ],
  {
    variants: {
      border: {
        none: ["border-none"],
      },
    },
  }
)

export function RouteTrigger({
  children,
  border,
  ...props
}: { children: ReactNode } & LinkPropsOptions & VariantProps<typeof routeTabs>) {
  const className = routeTrigger({ border })

  return (
    <Link {...props} className={className}>
      {children}
    </Link>
  )
}
