import { RegisterUser, ResetUserPassword, UserLogin } from "@core-services/portal"
import { MakeLinkOptions, Navigate, Route, useSearch } from "@tanstack/react-router"
import { toast } from "@vindral/components"
import { useState } from "react"
import { z } from "zod"
import { checkIfSuperUser } from "../../acl"
import { useReCaptcha, useTwoFactor } from "../../contexts"
import { router } from "../../router"
import { Auth } from "../../templates/auth/Auth"
import { Login } from "../../templates/auth/Login"
import { Register } from "../../templates/auth/Register"
import { RequestResetPassword } from "../../templates/auth/RequestResetPassword"
import { ResetPassword } from "../../templates/auth/ResetPassword"
import { VerifyEmail } from "../../templates/auth/VerifyEmail"
import { trpc } from "../../trpc"
import { rootRoute } from "../root"

const authParams = z.object({
  resetToken: z.string().optional(),
  inviteToken: z.string().optional(),
  verificationToken: z.string().optional(),
  service: z.string().optional(),
  redirect: z.string().optional(),
  clid: z.coerce.string().optional(),
})

export const authRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "auth",
  component: Page,
  validateSearch: authParams,
  onLoad: async ({ context: { trpcContext }, search: { inviteToken } }) => {
    if (inviteToken) {
      const inviteTokenInfo = await trpcContext.auth.getInviteTokenInfo.fetch({ token: inviteToken })
      if (!inviteTokenInfo) {
        toast({ title: "Invalid invite token", description: "The invite token has already been used or has expired" })

        void router.navigate({
          to: ".",
          search: (prev) => ({ ...prev, inviteToken: undefined }),
          replace: true,
        })
      }
    }
  },
})

function Page() {
  const { redirect } = useSearch({ from: authRoute.id })
  const { data: profile } = trpc.user.profile.useQuery(undefined, { suspense: true, useErrorBoundary: false })
  const pathname = router.state.currentLocation.pathname

  if (profile) {
    // User already logged in
    // eslint-disable-next-line total-functions/no-unsafe-type-assertion
    const to = (redirect || "/dashboard") as MakeLinkOptions["to"]
    const organization = profile.accessPermissions?.[0]?.organization
    const isSuperUser = checkIfSuperUser({ userProfile: profile })
    if (!isSuperUser && organization) {
      return <Navigate to={to} search={{ organizationId: organization.publicId }} replace />
    }

    return <Navigate to={to} search={{}} replace />
  }

  return <Auth pathname={pathname} />
}

export const indexRoute = new Route({
  getParentRoute: () => authRoute,
  path: "/",
  component: IndexRoute,
})

function IndexRoute() {
  return <Navigate to="/auth/login" replace />
}

export const loginRoute = new Route({
  getParentRoute: () => authRoute,
  path: "login",
  component: LoginTab,
})

function LoginTab() {
  const context = trpc.useContext()
  const search = useSearch({ from: loginRoute.id })
  const { inviteToken, service } = search
  const { data: inviteTokenInfo } = trpc.auth.getInviteTokenInfo.useQuery(
    { token: inviteToken ?? "" },
    { enabled: !!inviteToken }
  )
  const [twoFactorAccessToken, setTwoFactorAccessToken] = useState<string | undefined>(undefined)

  const loginMutation = trpc.auth.login.useMutation({
    onSuccess: (data) => {
      // Two-factor authentication required
      if (data.twoFactorAccessToken) {
        setTwoFactorAccessToken(data.twoFactorAccessToken)
        return
      }

      // Single-sign-on
      if (data.redirectUrl) {
        window.location.href = data.redirectUrl
        return
      }
      void context.user.profile.invalidate()
    },
    onError: (error) => {
      toast({ title: "Login failed", description: error.message })
    },
  })

  const login = async (value: UserLogin) => {
    if (service) {
      const params = Object.fromEntries(new URLSearchParams(window.location.search))
      value.serviceParams = {
        service,
        ...params,
      }
    }

    await loginMutation.mutateAsync({
      ...value,
    })
  }

  const login2faMutation = trpc.auth.loginWithTwoFactor.useMutation({
    onSuccess: () => {
      void context.user.profile.invalidate()
    },
    onError: (error) => {
      if (error?.data?.code == "UNAUTHORIZED") {
        setTwoFactorAccessToken(undefined)
      }
      toast({ title: "Two-factor authentication failed!", description: error.message })
    },
  })

  const login2fa = async (value: { code: string }) => {
    if (!twoFactorAccessToken) {
      return
    }

    login2faMutation.reset()
    await login2faMutation.mutateAsync({
      ...value,
      accessToken: twoFactorAccessToken,
    })
  }

  return (
    <Login
      login={login}
      email={inviteTokenInfo?.email}
      verifyTwoFactor={login2fa}
      openTwoFactorModal={!!twoFactorAccessToken}
      onCloseTwoFactorModal={() => setTwoFactorAccessToken(undefined)}
    />
  )
}

export const forgotPasswordRoute = new Route({
  getParentRoute: () => authRoute,
  path: "forgot-password",
  component: AuthForgotPassword,
  preSearchFilters: [(search) => search],
})

function AuthForgotPassword() {
  const { resetToken } = useSearch({ from: forgotPasswordRoute.id })

  if (resetToken) {
    return <AuthResetPassword token={resetToken} />
  }

  return <AuthRequestResetPassword />
}

function AuthResetPassword({ token }: { token: string }) {
  const context = trpc.useContext()
  const { data: info } = trpc.auth.getResetPasswordTokenInfo.useQuery({ token }, { suspense: true })
  const { executeReCaptcha } = useReCaptcha()
  const { withTwoFactorUnauthenticated } = useTwoFactor()

  if (!info?.email) {
    return <Navigate to="/auth/login" replace />
  }

  const resetPasswordMutation = trpc.auth.resetPassword.useMutation({
    onSuccess: () => {
      toast({ title: "Saved successfully!" })
      void context.user.profile.invalidate()
    },
    onError: (error) => {
      toast({ title: "Failed to Save!", description: error.message })
    },
  })
  const resetPasswordFn = async (value: ResetUserPassword) => {
    const recaptchaToken = await executeReCaptcha("password_reset")
    await resetPasswordMutation.mutateAsync({
      ...value,
      recaptchaToken,
    })
  }

  const resetPassword = info.twoFactorEnabled
    ? withTwoFactorUnauthenticated(info.userPublicId, resetPasswordFn)
    : resetPasswordFn

  return <ResetPassword resetPassword={resetPassword} token={token} email={info.email} />
}

function AuthRequestResetPassword() {
  const requestResetPasswordMutation = trpc.auth.requestResetPassword.useMutation()
  const { executeReCaptcha } = useReCaptcha()

  const requestResetPassword = async (value: { email: string }) => {
    const recaptchaToken = await executeReCaptcha("request_password_reset")
    await requestResetPasswordMutation.mutateAsync({
      ...value,
      recaptchaToken,
    })
    toast({ title: "Password reset link sent" })
  }

  return <RequestResetPassword requestResetPassword={requestResetPassword} />
}

export const registerRoute = new Route({
  getParentRoute: () => authRoute,
  path: "register",
  component: AuthRegister,
})

function AuthRegister() {
  const { inviteToken, verificationToken, clid } = useSearch({ from: registerRoute.id })

  if (inviteToken) {
    return <AuthInviteToken inviteToken={inviteToken} />
  }

  if (verificationToken) {
    return <AuthVerificationToken emailVerificationToken={verificationToken} />
  }

  return <AuthVerifyEmail showLink={!!clid} />
}

function AuthVerifyEmail(props: { showLink: boolean }) {
  const verifyEmailMutation = trpc.auth.verifyEmail.useMutation({
    onError: (error) => {
      toast({ title: "Failed to request email verification", description: error.message })
    },
  })
  const { executeReCaptcha } = useReCaptcha()

  const verifyEmail = async (value: { email: string }) => {
    const recaptchaToken = await executeReCaptcha("verify_email")
    await verifyEmailMutation.mutateAsync({
      ...value,
      recaptchaToken,
    })
  }

  return <VerifyEmail verifyEmail={verifyEmail} showLink={props.showLink} />
}

function AuthVerificationToken(props: { emailVerificationToken: string }) {
  const { data: info } = trpc.auth.getVerifyEmailTokenInfo.useQuery(
    { token: props.emailVerificationToken },
    {
      suspense: true,
      useErrorBoundary: false,
      onError: (error) => toast({ title: "Invalid email verification token!", description: error.message }),
    }
  )

  if (!info?.email) {
    return <Navigate to="/auth/register" search={{}} replace />
  }

  return <AuthRegisterComponent email={info.email} emailVerificationToken={props.emailVerificationToken} />
}

function AuthInviteToken(props: { inviteToken: string }) {
  const { data: info } = trpc.auth.getInviteTokenInfo.useQuery({ token: props.inviteToken }, { suspense: true })

  if (!info?.email) {
    return <Navigate to="/auth/login" search={{}} replace />
  } else if (info.userExists) {
    return <Navigate to="/auth/login" search replace />
  }

  return <AuthRegisterComponent email={info.email} inviteToken={props.inviteToken} />
}

function AuthRegisterComponent(props: { email: string; emailVerificationToken?: string; inviteToken?: string }) {
  const context = trpc.useContext()
  const registerUserMutation = trpc.auth.registerUser.useMutation({
    onSuccess: () => {
      toast({ title: "Registered successfully!" })
      void context.user.profile.invalidate()
    },
    onError: (error) => {
      toast({ title: "Failed to Register!", description: error.message })
    },
  })
  const { executeReCaptcha } = useReCaptcha()

  const registerUser = async (value: RegisterUser) => {
    const recaptchaToken = await executeReCaptcha("register")
    await registerUserMutation.mutateAsync({
      ...value,
      recaptchaToken,
    })
  }

  return <Register registerUser={registerUser} {...props} />
}
