import { UpdateSite } from "@core-services/portal/src/validation"
import { zodResolver } from "@hookform/resolvers/zod"
import { Divider, FormControlLabel, Switch, TextField } from "@vindral/components"
import { useEffect } from "react"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import { z } from "zod"
import { FormSection } from "../../../../atoms/FormSection"
import { SaveButton } from "../../../../atoms/SaveButton"
import { SaveSection } from "../../../../atoms/SaveSection"
import { SiteCapabilityJobs } from "../../../../atoms/SiteCapabilityJobs"
import { SiteCapabilityNodes } from "../../../../atoms/SiteCapabilityNodes"
import { StatusTitle } from "../../../../atoms/StatusTitle"
import { useRegionSelect } from "../../../../hooks/useRegionSelect"
import { SingleSelectCombobox } from "../../../../molecules/SingleSelectCombobox"
import { TestLbNodes } from "../../../../organisms/TestLbNodes"
import { SiteMultiSelector } from "../../../../organisms/multiselect/SiteMultiSelector"
import { SiteEditPageProps } from "../../../../routes/site/site-edit"
import { trpc } from "../../../../trpc"
import { floatOrNull, integerOrNull } from "../../../../utils/numberOrNull"

const UpdateSiteForm = UpdateSite.merge(z.object({ transcodingPriority: z.object({ code: z.string() }).array() }))
type FormType = z.infer<typeof UpdateSiteForm>
const resolver = zodResolver(UpdateSiteForm)

export const SiteEditGeneral = ({ site, updateSite }: SiteEditPageProps) => {
  const regionSelect = useRegionSelect({ defaultSelected: site.region ?? undefined })
  const form = useForm<FormType>({
    resolver,
    mode: "onChange",
    defaultValues: {
      ...site,
      regionPublicId: site.region?.publicId,
      transcodingPriority: site.transcodingPriority?.map((code) => ({ code })) ?? [],
    },
  })

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { isDirty, isValid, isSubmitSuccessful, errors },
  } = form

  const fieldArray = useFieldArray({ control, name: "transcodingPriority" })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful, reset])

  const { data: edges } = trpc.infrastructure.getEdges.useQuery({ datacenter: site.code })
  const { data: packagers } = trpc.infrastructure.getPackagerServerNames.useQuery({ datacenter: site.code })
  const { data: hasMediaIngressJobs } = trpc.infrastructure.hasMediaIngressJobs.useQuery({ datacenter: site.code })
  const { data: hasIngressJobs } = trpc.infrastructure.hasIngressJobs.useQuery({ datacenter: site.code })
  const { data: hasTranscodingJobs } = trpc.infrastructure.hasTranscodingJobs.useQuery({ datacenter: site.code })
  const { data: ingressServers } = trpc.infrastructure.getIngressServers.useQuery({ datacenter: site.code })
  const { data: mediaIngressServers } = trpc.infrastructure.getMediaIngressServers.useQuery({ datacenter: site.code })

  return (
    <form
      className="flex flex-col gap-4"
      onSubmit={handleSubmit((site) => {
        return updateSite({
          ...site,
          transcodingPriority: site.transcodingPriority.map((priority) => priority.code),
        })
      })}
    >
      <FormSection title="Edit site" description="Change general settings">
        <div className="flex max-w-sm flex-col gap-4">
          <div className="flex flex-col gap-1">
            <StatusTitle>Code</StatusTitle>
            <span className="font-medium">{site.code}</span>
          </div>

          <div className="flex flex-col gap-1">
            <FormControlLabel required error={errors.name?.message}>
              Name
            </FormControlLabel>
            <TextField {...register("name")} />
          </div>

          <div className="flex flex-col gap-1">
            <FormControlLabel error={errors.providers?.message}>Provider</FormControlLabel>
            <TextField {...register("providers")} />
          </div>

          <div className="flex flex-col gap-1">
            <FormControlLabel required error={errors.regionPublicId?.message}>
              Region
            </FormControlLabel>
            <div>
              <Controller
                control={control}
                name="regionPublicId"
                render={({ field }) => (
                  <SingleSelectCombobox
                    options={regionSelect.options}
                    selected={regionSelect.selected}
                    search={regionSelect.search}
                    isLoading={regionSelect.query.isLoading}
                    onSearchChange={regionSelect.setSearch}
                    dataPwButton="regionSelector"
                    dataPwValue="regionSelectorValue"
                    placeholder="Choose region"
                    onSelect={(selected) => {
                      regionSelect.setSelected(selected)
                      field.onChange(selected.publicId)
                    }}
                    onReachedEnd={() => {
                      if (!regionSelect.query.hasNextPage) {
                        return
                      }
                      void regionSelect.query.fetchNextPage()
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex flex-col gap-1">
            <FormControlLabel error={errors.notes?.message}>Notes</FormControlLabel>
            <TextField multiline minRows={4} {...register("notes")} />
          </div>
        </div>
      </FormSection>
      <Divider />
      <FormSection title="Location" description="Specify site location for geo load balancing purposes">
        <div className="flex max-w-sm flex-col gap-4">
          <div className="flex flex-col gap-1">
            <FormControlLabel required error={errors.latitude?.message}>
              Latitude
            </FormControlLabel>
            <TextField placeholder="63.853470" {...register("latitude", { setValueAs: floatOrNull })} />
          </div>
          <div className="flex flex-col gap-1">
            <FormControlLabel required error={errors.longitude?.message}>
              Longitude
            </FormControlLabel>
            <TextField placeholder="20.144732" {...register("longitude", { setValueAs: floatOrNull })} />
          </div>
        </div>
      </FormSection>
      <Divider />
      <FormSection title="Limitations" description="Site specific limitations">
        <div className="flex max-w-sm flex-col gap-4">
          <div className="flex flex-col gap-1">
            <FormControlLabel error={errors.maxCapacity?.message}>Maximum Capacity (mbit/s)</FormControlLabel>
            <TextField placeholder="unlimited" {...register("maxCapacity", { setValueAs: integerOrNull })} />
          </div>

          <div className="flex w-full justify-between pt-4">
            <div className="flex-col font-medium">
              <h3 className="pb-2">Media ingress</h3>
              <Controller
                control={control}
                name="mediaIngressActive"
                render={({ field }) => (
                  <div className="flex w-full">
                    <Switch checked={field.value} onCheckedChange={field.onChange} label="Media Ingress" />
                  </div>
                )}
              />
            </div>
            <div className="flex space-x-4">
              <SiteCapabilityJobs
                hasJobs={hasMediaIngressJobs ?? false}
                type="media ingress"
                expectedJobs={[
                  "media-ingress-tls",
                  "media-ingress-rust",
                  "media-ingress-adapter",
                  "rabbitmq",
                  "or",
                  "media-ingress-tls",
                  "media-ingress-rtmp",
                  "traffic-router",
                ]}
              />
              <SiteCapabilityNodes type="media ingress" hosts={mediaIngressServers} />
            </div>
          </div>

          <div className="flex w-full justify-between pt-4">
            <div className="flex-col font-medium">
              <div className="pb-2">LB ingress</div>
              <Controller
                control={control}
                name="viewerTrafficActive"
                render={({ field }) => (
                  <div className="flex w-full">
                    <Switch checked={field.value} onCheckedChange={field.onChange} label="Load Balancer" />
                  </div>
                )}
              />
            </div>
            <div className="flex space-x-4">
              <TestLbNodes ingressServers={ingressServers} />
              <SiteCapabilityJobs
                hasJobs={hasIngressJobs ?? false}
                type="lb ingress"
                expectedJobs={["ingress-zerossl", "traffic-router"]}
              />
              <SiteCapabilityNodes type="lb ingress" hosts={ingressServers} />
            </div>
          </div>

          <div className="flex w-full justify-between pt-4">
            <div className="flex-col font-medium">
              <div className="pb-2">Transcoding</div>
              <Controller
                control={control}
                name="transcodingActive"
                render={({ field }) => (
                  <div className="flex w-full">
                    <Switch checked={field.value} onCheckedChange={field.onChange} label="Transcoding" />
                  </div>
                )}
              />
            </div>
            <div className="flex space-x-4">
              <SiteCapabilityJobs
                hasJobs={hasTranscodingJobs ?? false}
                type="transcoding"
                expectedJobs={["packager-adapter", "media-ingress-adapter", "rabbitmq"]}
              />
              <SiteCapabilityNodes type="transcoding" hosts={packagers} />
            </div>
          </div>

          <div className="flex w-full justify-between pt-4">
            <div className="flex-col font-medium">
              <div className="pb-2">Edge</div>
              <Controller
                control={control}
                name="edgeOriginActive"
                render={({ field }) => (
                  <div className="flex w-full pb-4">
                    <Switch checked={field.value} onCheckedChange={field.onChange} label="Edge Origin" />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="edgeEgressActive"
                render={({ field }) => (
                  <div className="flex w-full">
                    <Switch checked={field.value} onCheckedChange={field.onChange} label="Edge Egress" />
                  </div>
                )}
              />
            </div>
            <div>
              <SiteCapabilityNodes type="edge" hosts={edges?.map((x) => x.hostname)} />
            </div>
          </div>
        </div>
      </FormSection>
      <Divider />
      <FormSection
        title="Transcoding Priority"
        description="Specify transcoding priority for this site.
        When initiating a transcoding job, these sites will be prioritized first (as long as they are active for transcoding).
        If this list is empty or if there are too few active sites when a transcoding job starts, up to five datacenters will be added in order of distance to the origin site."
      >
        <SiteMultiSelector
          onSelect={(item) => fieldArray.append({ code: item.code })}
          onDeselect={(index) => fieldArray.remove(index)}
          selected={fieldArray.fields}
          nothingSelectedText="Select sites from the dropdown to have them be prioritized for transcoding jobs"
        />
      </FormSection>
      <SaveSection>
        <SaveButton text="Save" disabled={!isDirty || !isValid} />
      </SaveSection>
    </form>
  )
}
