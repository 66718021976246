"use client"

import { CalendarIcon } from "@radix-ui/react-icons"
import { add, endOfHour, format } from "date-fns"
import { useState } from "react"
import { Button } from "./Button"
import { Calendar } from "./Calendar"
import { Popover, PopoverContent, PopoverTrigger } from "./Popover"

type DefaultTime = "now" | "next_hour"

const defaultTimeMapping = {
  ["now"]: new Date(),
  ["next_hour"]: add(endOfHour(new Date()), { minutes: 1 }),
}

export function DateTimePicker(props: {
  onSelect: (date?: Date) => void
  selected?: Date
  disabled?: boolean
  defaultTime?: DefaultTime
}) {
  const { onSelect, selected, defaultTime = "now" } = props
  const [open, setOpen] = useState(false)

  const handleTimeChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const time = e.target.value
    if (!selected) {
      return
    }

    const [hours, minutes] = time.split(":").map((str) => parseInt(str, 10))
    const newDate = new Date(selected.getFullYear(), selected.getMonth(), selected.getDate(), hours, minutes)
    onSelect(newDate)
  }

  const handleDaySelect = (date: Date | undefined) => {
    if (!date) {
      onSelect(undefined)
      return
    }

    const timeDate = selected || defaultTimeMapping[defaultTime]

    const newDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      timeDate.getHours(),
      timeDate.getMinutes()
    )
    onSelect(newDate)
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button className={"w-[300px] justify-start text-left font-normal"} disabled={props.disabled}>
          <CalendarIcon className="mr-1 h-4 w-4" />
          Pick date
        </Button>
      </PopoverTrigger>
      <PopoverContent sideOffset={12} className="w-auto p-0" align="start" side="top">
        <Calendar
          initialFocus
          mode="single"
          disabled={{ before: new Date() }}
          selected={selected}
          onSelect={handleDaySelect}
          footer={
            <p className="mt-2">
              Pick a time:{" "}
              <input
                className="cursor-pointer p-1"
                type="time"
                value={selected ? format(selected, "HH:mm") : ""}
                onChange={handleTimeChange}
                disabled={!selected}
              />
            </p>
          }
        />
      </PopoverContent>
    </Popover>
  )
}
