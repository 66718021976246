import { z } from "zod"

const MAX_SUBJECT_LENGTH = 250
const MAX_DESCRIPTION_LENGTH = 5000
const MAX_ATTACHMENT_SIZE_MB = 20
export const MAX_ATTACHMENT_SIZE_BYTES = MAX_ATTACHMENT_SIZE_MB * 1024 * 1024
export const MAX_NUM_TICKET_ATTACHMENTS = 10

export const CreateSupportTicket = z.object({
  subject: z.string().max(MAX_SUBJECT_LENGTH, { message: `Subject can not exceed ${MAX_SUBJECT_LENGTH} characters.` }),
  description: z
    .string()
    .min(1, { message: "Please provide a description of your problem." })
    .max(MAX_DESCRIPTION_LENGTH, { message: `Description can not exceed ${MAX_DESCRIPTION_LENGTH} characters.` }),
  email: z.string().email(),
  attachments: z
    .custom<FileList>()
    .superRefine((files, ctx) => {
      if (files.length > MAX_NUM_TICKET_ATTACHMENTS) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `No more than ${MAX_NUM_TICKET_ATTACHMENTS} files can be attached to a ticket`,
        })
        return false
      }

      const totalFileSize = Array.from(files).reduce((total, file) => total + file.size, 0)
      if (totalFileSize > MAX_ATTACHMENT_SIZE_BYTES) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Total file size can not exceed ${MAX_ATTACHMENT_SIZE_MB} MB`,
        })
        return false
      }

      return true
    })
    .optional(),
})

export type CreateSupportTicket = z.infer<typeof CreateSupportTicket>
