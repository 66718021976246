import { z } from "zod"

export const RegisterUser = z
  .object({
    password: z.string().min(8).max(128),
    email: z.string().email(),
    name: z.string().trim().min(1).max(100),
    newsletterSignUp: z.boolean(),
    emailVerificationToken: z.string().optional(),
    inviteToken: z.string().optional(),
  })
  .refine((data) => {
    if (data.emailVerificationToken && data.inviteToken) {
      return false
    } else if (!data.emailVerificationToken && !data.inviteToken) {
      return false
    } else {
      return true
    }
  }, "Either emailVerificationToken or inviteToken must be provided")

export type RegisterUser = z.infer<typeof RegisterUser>
