import { AccessPermissionRole } from "@core-services/data-types"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@vindral/components"

export function AccessPermissionRoleSelect(props: {
  disabled?: boolean
  value?: AccessPermissionRole
  onValueChange: (value: AccessPermissionRole) => void
  organizationUnderEvaluation: boolean
}) {
  const { value, onValueChange, disabled, organizationUnderEvaluation } = props

  return (
    <Select
      disabled={disabled || (organizationUnderEvaluation && value === "administrator")} // Only allow one administrator per trial organization
      value={value}
      onValueChange={(value: AccessPermissionRole) => onValueChange(value)}
    >
      <SelectTrigger data-pw="permissionSelector">
        <SelectValue placeholder="Select a role" />
      </SelectTrigger>
      <SelectContent align="end">
        <SelectItem disabled={organizationUnderEvaluation} value="administrator">
          Administrator
        </SelectItem>
        <SelectItem value="channel_manager">Channel Manager</SelectItem>
        <SelectItem value="user">User</SelectItem>
      </SelectContent>
    </Select>
  )
}
