import { AppAbility } from "@common/access-control"
import { ChannelStatus, IngestStatus } from "@core-services/portal"
import { Link, Outlet } from "@tanstack/react-router"
import { Button, ConfirmDialogContent, Dialog, DialogTrigger, Stack, toast } from "@vindral/components"
import { checkIfSuperUser } from "../../../acl"
import { Message, MessageSection } from "../../../molecules/Message"
import { RouteTabs, RouteTrigger } from "../../../molecules/RouteTabs"
import { trpc } from "../../../trpc"
import { ChannelFindById } from "../ChannelEdit"
import { ChannelDetailsInfo } from "./ChannelDetailsInfo"
import { StartStopManualChannelButton } from "./StartStopManualChannelButton"

export interface ChannelEditDetailsProps {
  ability: AppAbility
  channel: ChannelFindById
  viewers: number | undefined
  channelStatus: ChannelStatus | undefined
  channelStatusLoading: boolean
  ingestStatus: IngestStatus | undefined
}

export function ChannelEditDetails({
  ability,
  channel,
  viewers,
  channelStatus,
  channelStatusLoading,
  ingestStatus,
}: ChannelEditDetailsProps) {
  const drop = trpc.channel.dropIngest.useMutation({
    onSuccess: () => {
      toast({ title: "Ingest dropped" })
    },
    onError: (error) => {
      toast({ title: "Could not drop ingest", description: error.message })
    },
  })

  const resubmit = trpc.channel.resubmitJob.useMutation({
    onSuccess: () => {
      toast({ title: "Transcoding job resubmitted" })
    },
    onError: (error) => {
      toast({ title: "Could not resubmit transcoding job", description: error.message })
    },
  })

  const isSuperUser = checkIfSuperUser({ ability })

  return (
    <div>
      <ChannelDetailsInfo
        channel={channel}
        viewers={viewers}
        channelStatus={channelStatus}
        channelStatusLoading={channelStatusLoading}
        ingestStatus={ingestStatus}
      />
      {!channel.mostRecentIngestSession && (
        <div className="pt-4">
          <Message severity="info">
            <MessageSection>
              <div className="flex w-full">
                <div className="grow">
                  <h1 className="mb-1 font-medium">Broadcasting on this channel</h1>
                  <span>
                    This channel has never been live. To go live you need to broadcast using one of the supported
                    protocols. Please check out the{" "}
                    <Link
                      to="/channels/edit/$channelId/broadcast"
                      search={(prev) => ({ ...prev, tab: "general" })}
                      params={{ channelId: channel.channelId }}
                      className="font-medium text-fg underline decoration-solid-primary decoration-2 underline-offset-2 hover:decoration-fg"
                    >
                      Broadcast tab
                    </Link>{" "}
                    for more information.
                  </span>
                </div>
              </div>
            </MessageSection>
          </Message>
        </div>
      )}
      <Stack spacing="large">
        <div className="flex grow flex-col gap-4 pt-4">
          <RouteTabs>
            <RouteTrigger to="/channels/edit/$channelId/details/general">General</RouteTrigger>
            {isSuperUser && <RouteTrigger to="/channels/edit/$channelId/details/transcoding">Transcoding</RouteTrigger>}
            <RouteTrigger to="/channels/edit/$channelId/details/restreaming">Restreaming</RouteTrigger>
            <RouteTrigger to="/channels/edit/$channelId/details/recording">Recording</RouteTrigger>
            {isSuperUser && <RouteTrigger to="/channels/edit/$channelId/details/sourcefeed">Source Feed</RouteTrigger>}
            {isSuperUser && <RouteTrigger to="/channels/edit/$channelId/details/siteaccess">Site Access</RouteTrigger>}
            <RouteTrigger to="/channels/edit/$channelId/details/vieweraccess">Viewer Access</RouteTrigger>
            {isSuperUser && <RouteTrigger to="/channels/edit/$channelId/details/overrides">Overrides</RouteTrigger>}
          </RouteTabs>
          <Outlet />
        </div>

        {/* Power tools (tm) */}
        {isSuperUser && (
          <div className="flex w-64 shrink-0 grow-0 flex-col gap-4 pt-14 2xl:w-80">
            {channel.mode === "manual" && (
              <Message>
                <MessageSection>
                  <span className="block pb-2 text-fg-subtle">Start the transcoding job to receive a feed</span>
                  <StartStopManualChannelButton channelId={channel.channelId} channelName={channel.name} />
                </MessageSection>
              </Message>
            )}

            <Message>
              <MessageSection>
                <span className="font-medium">Power tools</span>
              </MessageSection>
              <MessageSection>
                <span className="block pb-2 text-fg-subtle">
                  Resubmitting the transcoding job forces the transcoding job to be resubmitted with any new
                  configurations.
                </span>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button>Resubmit transcoding job</Button>
                  </DialogTrigger>
                  <ConfirmDialogContent
                    onConfirm={() => resubmit.mutateAsync({ channelId: channel.channelId })}
                    title={`Confirm resubmit transcoding job for ${channel.name}`}
                    description={
                      <>
                        Please confirm that you are absolutely sure that you want to resubmit transcoding job
                        <span className="font-medium"> {channel.name}</span>.
                      </>
                    }
                  />
                </Dialog>
              </MessageSection>

              <MessageSection>
                <span className="block pb-2 text-fg-subtle">
                  Drop the publisher connection to trigger a client reconnect and restart the transcoding jobs. This
                  also drops any clients viewing the stream.
                </span>

                <Dialog>
                  <DialogTrigger asChild>
                    <Button>Drop ingest connection</Button>
                  </DialogTrigger>
                  <ConfirmDialogContent
                    onConfirm={() => drop.mutateAsync({ channelId: channel.channelId })}
                    title={`Confirm drop ingest connection for ${channel.name}`}
                    description={
                      <>
                        Please confirm that you are absolutely sure that you want to drop ingest connection
                        <span className="font-medium"> {channel.name}</span>.
                      </>
                    }
                  />
                </Dialog>
              </MessageSection>
            </Message>
          </div>
        )}
      </Stack>
    </div>
  )
}
