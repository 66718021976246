import { useMemo, useState } from "react"
import { Channel } from "../interfaces/Channel"
import { trpc } from "../trpc"

function getNextPageParam<T>(lastPage: { total: number }, allPages: { total: number; items: T[] }[]) {
  const nextSkip = allPages.reduce((prev, page) => prev + page.items.length, 0)
  return nextSkip < lastPage.total ? nextSkip : undefined
}

export type SelectedChannel = Pick<Channel, "name" | "channelId" | "streamKey">

export function useChannelSelect({
  organizationId,
  defaultSelected,
  enabled,
}: {
  organizationId?: string
  defaultSelected?: SelectedChannel | null
  enabled?: boolean
}) {
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<SelectedChannel | null>(defaultSelected ?? null)
  const [search, setSearch] = useState("")
  const query = trpc.channel.findMany.useInfiniteQuery(
    {
      orderBy: "name",
      filter: organizationId ? { organization: { publicId: organizationId } } : undefined,
      search: search.length > 0 ? search : undefined,
    },
    { getNextPageParam, keepPreviousData: true, enabled }
  )

  const options = useMemo(() => {
    return [
      {
        id: "options",
        items: query.data?.pages.flatMap((pages) => pages.items) ?? [],
      },
    ]
  }, [query])

  return { query, options, selected, setSelected, search, setSearch, open, setOpen }
}
