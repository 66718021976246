import { Expose } from "class-transformer"
import { BaseDTO } from "../base.dto"

export class PackagerDTO extends BaseDTO<PackagerDTO> {
  @Expose()
  site!: string

  @Expose()
  nodeHostname!: string

  @Expose()
  serviceIp!: string

  @Expose()
  servicePort!: number
}
