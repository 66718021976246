import { Action, denyNothingLimitation } from "@core-services/data-types"
import { AppAbility, UpdateChannel } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { IconAlertCircle } from "@tabler/icons-react"
import { useEffect } from "react"
import { useController, useFieldArray, useForm } from "react-hook-form"
import { z } from "zod"
import { channelSubjectInOrganization, checkIfSuperUser } from "../../../acl"
import { FormSection } from "../../../atoms/FormSection"
import { SaveButton } from "../../../atoms/SaveButton"
import { SaveSection } from "../../../atoms/SaveSection"
import { LimitationModeSelect } from "../../../molecules/LimitationModeSelect"
import { RouteBlockDialog } from "../../../molecules/RouteBlockDialog"
import { CountryMultiSelector } from "../../../organisms/multiselect/CountryMultiSelector"
import { ChannelFindById } from "../ChannelEdit"

const resolver = zodResolver(UpdateChannel.merge(z.object({ viewerCountryLimitation: z.any() })))

export interface ChannelEditDetailsViewerAccessProps {
  ability: AppAbility
  channel: ChannelFindById
  onSave: (channel: UpdateChannel) => Promise<void>
}
export function ChannelEditDetailsViewerAccess({ ability, channel, onSave }: ChannelEditDetailsViewerAccessProps) {
  const subject = channelSubjectInOrganization(channel.organization.publicId)
  const isSuperUser = checkIfSuperUser({ ability })
  const viewerCountryLimitation = channel.viewerCountryLimitation ?? denyNothingLimitation

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid, isSubmitting, isSubmitSuccessful },
    reset,
  } = useForm({
    resolver,
    mode: "onChange",
    defaultValues: {
      channelId: channel.channelId,
      viewerCountryLimitation: {
        mode: viewerCountryLimitation.mode,
        limited: viewerCountryLimitation.limited.map((l) => ({ countryCode: l })),
      },
    },
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful, reset])

  const mode = useController({ control, name: "viewerCountryLimitation.mode" })
  const limits = useFieldArray({ control, name: "viewerCountryLimitation.limited" })
  const allowsNothingSet = limits.fields.length === 0 && mode.field.value === "allow"
  const cannotUpdateDueToAllowNothing = allowsNothingSet && !isSuperUser
  const cannotUpdateCountryLimitations = ability.cannot(Action.Update, subject, "viewerCountryLimitation")

  return (
    <div className="py-4">
      <RouteBlockDialog condition={isDirty && !isSubmitting} />
      <form
        onSubmit={handleSubmit((channel) => {
          return onSave({
            ...channel,
            viewerCountryLimitation: {
              mode: channel.viewerCountryLimitation.mode,
              limited: channel.viewerCountryLimitation.limited.map((limited) => limited.countryCode),
            },
          })
        })}
        className="flex flex-col gap-4"
      >
        <FormSection
          title="Viewer country limitation"
          description="Limitations can either allow or deny which viewers that can access the channel."
        >
          <div className="flex flex-col gap-4">
            <div>
              <LimitationModeSelect
                value={mode.field.value}
                onValueChange={(newRole) => mode.field.onChange(newRole)}
                readonly={cannotUpdateCountryLimitations}
              />
            </div>
            <div className={mode.field.value === "disabled" ? "pointer-events-none select-none opacity-50" : ""}>
              <CountryMultiSelector
                selected={limits.fields}
                onSelect={(item) => limits.prepend(item)}
                onDeselect={(index) => limits.remove(index)}
                readonly={cannotUpdateCountryLimitations}
              />
            </div>

            {allowsNothingSet ? (
              <div className="flex items-center gap-2 py-4 font-medium text-fg-danger-subtle">
                <IconAlertCircle className="h-4 w-4" />
                Allowing no countries will block all users from accessing the channel.
              </div>
            ) : null}
            {limits.fields.length === 0 ? (
              <div className="flex flex-col gap-2 py-4">
                Select countries from the dropdown to {mode.field.value} this channel to be viewed from there
              </div>
            ) : null}
          </div>
        </FormSection>
        {!cannotUpdateCountryLimitations && (
          <SaveSection>
            <SaveButton
              text="Save"
              disabled={cannotUpdateCountryLimitations || !isDirty || !isValid || cannotUpdateDueToAllowNothing}
            />
          </SaveSection>
        )}
      </form>
    </div>
  )
}
