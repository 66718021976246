import { Ability } from "@casl/ability"
import { Expose } from "class-transformer"
import { UserDTO } from "./user.dto"

export class UserProfileDTO extends UserDTO {
  static readonly modelName = "User"

  @Expose()
  rules!: Ability["rules"]
}
