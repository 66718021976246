import {
  formatBitRate,
  formatChannels,
  formatCodec,
  formatSampleRate,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "@vindral/components"
import { TranscodingProfileAudioRendition } from "../interfaces/TranscodingProfile"

interface AudioRenditionProps {
  rendition: TranscodingProfileAudioRendition
}

function AudioRendition({ rendition }: AudioRenditionProps) {
  return (
    <TableRow key={rendition.id}>
      <TableCell collapse>{formatCodec(rendition.codec)}</TableCell>
      <TableCell collapse className="tabular-nums">
        {rendition.bitRate ? formatBitRate(rendition.bitRate) : "N/A"}
      </TableCell>
      <TableCell collapse className="tabular-nums">
        {rendition.channels ? formatChannels(rendition.channels) : "Match source"}
      </TableCell>
      <TableCell collapse>{rendition.sampleRate ? formatSampleRate(rendition.sampleRate) : "N/A"}</TableCell>
    </TableRow>
  )
}

interface AudioRenditionsProps {
  renditions: TranscodingProfileAudioRendition[]
}

export function AudioRenditions({ renditions }: AudioRenditionsProps) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadCell>Codec</TableHeadCell>
          <TableHeadCell>Bit Rate</TableHeadCell>
          <TableHeadCell>Channels</TableHeadCell>
          <TableHeadCell>Sample Rate</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {renditions.map((rendition) => {
          return <AudioRendition key={rendition.id} rendition={rendition} />
        })}
      </TableBody>
    </Table>
  )
}
