import { ArrowLongRightIcon, ArrowTrendingDownIcon, ArrowTrendingUpIcon } from "@heroicons/react/20/solid"
import { Chip } from "@vindral/components"

export interface TrendProps {
  change: number
}

export function Trend({ change }: TrendProps) {
  return (
    <Chip variant="small" color={Math.abs(change) < 0.005 ? "blue" : change >= 0 ? "green" : "red"}>
      {Math.abs(change) < 0.005 ? (
        <ArrowLongRightIcon className="h-4 w-4" />
      ) : change >= 0 ? (
        <ArrowTrendingUpIcon className="h-4 w-4" />
      ) : (
        <ArrowTrendingDownIcon className="h-4 w-4" />
      )}
      {new Intl.NumberFormat("en-US", {
        style: "percent",
        signDisplay: "exceptZero",
        useGrouping: false,
      }).format(change)}
    </Chip>
  )
}
