import CryptoJS from "crypto-js"

export type ChannelIds = {
  channelId?: string
  channelGroupId?: string
}

export const signJWT = (authSecret: string, ids: ChannelIds, startsAt?: number, expiresAt?: number) => {
  const header = {
    alg: "HS256",
    typ: "JWT",
  }
  const exp = expiresAt ?? Math.round(Date.now() / 1000 + 60 * 60 * 24 * 1)
  const payload = {
    channelId: ids.channelId,
    channelGroupId: ids.channelGroupId,
    exp,
    nbf: startsAt,
  }
  const base64urlencoded = {
    fromString: (s: string) =>
      CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(s))
        .replace(/=/g, "")
        .replace(/\+/g, "-")
        .replace(/\//g, "_"),
    fromBase64: (s: string) => s.replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_"),
  }
  const encodedHeader = base64urlencoded.fromString(JSON.stringify(header))
  const encodedPayload = base64urlencoded.fromString(JSON.stringify(payload))
  const signature = base64urlencoded.fromBase64(
    CryptoJS.HmacSHA256(`${encodedHeader}.${encodedPayload}`, authSecret).toString(CryptoJS.enc.Base64)
  )
  return `${encodedHeader}.${encodedPayload}.${signature}`
}
