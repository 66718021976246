import { MagnifyingGlassIcon } from "@radix-ui/react-icons"
import { useNavigate } from "@tanstack/react-router"
import { DialogContent, Divider, Spinner, TextField } from "@vindral/components"
import React, { useCallback, useContext, useEffect, useRef } from "react"
import { SearchResults } from "./SearchResults"
import { getSearchResultUrlNew } from "./common"
import { SearchContext, getSearchResultId } from "./context"

interface SearchDialogContentProps {
  close: () => void
  open: boolean
  scope?: string
}

function Scope({ scope }: { scope?: string }) {
  if (!scope) {
    return null
  }

  return (
    <div className="flex whitespace-nowrap">
      <span className="max-w-[176px] overflow-hidden text-ellipsis font-medium">{scope}</span>
      <span className="pl-2">{"/"}</span>
    </div>
  )
}

export function SearchDialogContent({ close, open, scope }: SearchDialogContentProps) {
  const inputRef = useRef<HTMLInputElement>(null)

  const navigate = useNavigate()
  const { actions, focusedRowId, loading, text, result, hasResults } = useContext(SearchContext)

  useEffect(() => {
    if (!open) {
      actions.setText("")
    }
  }, [open, actions])

  const navigateToActiveSearchResult = useCallback(() => {
    if (!focusedRowId) {
      return
    }

    const searchResult = result.items.find((item) => {
      return focusedRowId === getSearchResultId(item)
    })

    if (searchResult) {
      const url = getSearchResultUrlNew(searchResult)
      void navigate({
        to: url,
        params: {},
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any, total-functions/no-unsafe-type-assertion, @typescript-eslint/no-unnecessary-type-assertion
        search: (prev) => prev as any,
      })
    }
  }, [focusedRowId, navigate, result.items])

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "ArrowUp") {
        event.preventDefault()
        actions.focusPreviousRow()
      }

      if (event.key === "ArrowDown") {
        event.preventDefault()
        actions.focusNextRow()
      }

      if (event.key === "Tab") {
        event.preventDefault()

        if (event.shiftKey) {
          actions.focusPreviousRow()
        } else {
          actions.focusNextRow()
        }
      }

      if (event.key === "Enter") {
        event.preventDefault()
        navigateToActiveSearchResult()
      }
    },
    [actions, navigateToActiveSearchResult]
  )

  const [showSpinner, setShowSpinner] = React.useState(false)
  const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | undefined>(undefined)
  React.useEffect(() => {
    if (loading) {
      const timeout = setTimeout(() => setShowSpinner(true), 100)
      setTimeoutId(timeout)
      return () => clearTimeout(timeout)
    }
  }, [loading])

  React.useEffect(() => {
    if (!loading) {
      setShowSpinner(false)
      clearTimeout(timeoutId)
    }
  }, [loading, timeoutId])

  const focusInputElement = () => inputRef.current?.focus()

  return (
    <DialogContent onEscapeKeyDown={close} onPointerDownOutside={close} size="small">
      <div className="flex flex-col overflow-hidden">
        <div onClick={focusInputElement} className="flex w-full flex-initial cursor-text items-center gap-2 p-4">
          {showSpinner ? (
            <div className="h-5 w-5 shrink-0 fill-current">
              <Spinner size="small" />
            </div>
          ) : (
            <MagnifyingGlassIcon className="h-5 w-5 shrink-0 fill-current" />
          )}
          <Scope scope={scope} />
          <TextField
            invisible
            ref={inputRef}
            autoFocus
            onKeyDown={handleKeyDown}
            placeholder="Search the portal..."
            value={text}
            onChange={(event) => actions.setText(event.target.value)}
          />
        </div>
        <Divider />
        <div className="min-h-0 flex-auto overflow-y-auto p-4">
          {text.length > 0 && hasResults && <SearchResults />}
          {text.length > 0 && !hasResults && <div className="py-2">No results found</div>}
        </div>
      </div>
    </DialogContent>
  )
}
