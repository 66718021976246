import { AppAbility } from "@core-services/portal"
import { Divider } from "@vindral/components"
import { Hyperlink } from "../../../atoms/Hyperlink"
import { ChannelFindById } from "../ChannelEdit"
import { Rtmp } from "./broadcast/BroadcastRtmp"
import { Srt } from "./broadcast/BroadcastSrt"
import { TimedMetadata } from "./broadcast/BroadcastTimedMetadata"
import { WebRtc } from "./broadcast/BroadcastWebRtc"

export interface ChannelEditBroadcastProps {
  channel: ChannelFindById
  ability: AppAbility
}

export function ChannelEditBroadcast({ channel, ability }: ChannelEditBroadcastProps) {
  return (
    <div className="flex flex-col gap-4">
      <Rtmp streamKey={channel.streamKey} />
      <Divider />
      <WebRtc streamKey={channel.streamKey} />
      <Divider />
      <Srt ability={ability} channel={channel} />
      <Divider />
      <TimedMetadata ability={ability} channel={channel} />
      <Divider />
      <h1 className="mb-1 text-xl font-medium">Other</h1>
      <p className="w-96">
        For information regarding <span className="font-bold italic">SDI, NDI, MPEG-TS</span> and other broadcast
        options <Hyperlink href={"mailto:info@realsprint.com"} text={"contact us"} />
      </p>
    </div>
  )
}
