import { ApiKeyScope } from "@core-services/data-types"
import { IsEnum, IsString, MaxLength } from "class-validator"
import { IsEntityName } from "../common"

export class UpdateApiKeyDTO {
  @IsEntityName()
  name!: string

  @IsString()
  @MaxLength(200)
  notes!: string

  @IsEnum(ApiKeyScope)
  scope!: ApiKeyScope
}
