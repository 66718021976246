import { flexRender, RowData, type Table as TanstackTable } from "@tanstack/react-table"
import { Pagination, Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from "@vindral/components"

export function InteractiveTable<TData extends RowData>({
  table,
  loadingProps,
  includePaginationFooter,
}: {
  table: TanstackTable<TData>
  loadingProps?: { isLoading: boolean; numRows?: number }
  includePaginationFooter?: boolean
}) {
  return (
    <div>
      <Table>
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
                  .filter((header) => !(header?.column?.columnDef.meta as any)?.isLogical)
                  .map((header) => {
                    return (
                      <TableHeadCell
                        key={header.id}
                        colSpan={header.colSpan}
                        isSortable={header.column.getCanSort()}
                        sortDirection={header.column.getIsSorted()}
                        onClick={header.column.getToggleSortingHandler()}
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
                        style={(header.column.columnDef.meta as any)?.style}
                        align="right"
                      >
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHeadCell>
                    )
                  })}
              </TableRow>
            )
          })}
        </TableHead>
        <TableBody>
          {loadingProps?.isLoading
            ? [...Array(loadingProps?.numRows || table.getState().pagination.pageSize).keys()].map((i) =>
                table.getHeaderGroups().map((headerGroup) => {
                  return (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
                        .filter((header) => !(header?.column?.columnDef.meta as any)?.isLogical)
                        .map((header) => {
                          return (
                            <TableCell
                              key={`loading:${header.id}:${i}`}
                              style={{
                                width:
                                  header.column.getSize() !== Number.MAX_SAFE_INTEGER
                                    ? header.column.getSize()
                                    : undefined,
                                maxWidth: header.column.columnDef.maxSize,
                              }}
                            >
                              <div className="my-1 h-3 w-full animate-pulse rounded-md bg-component"></div>
                            </TableCell>
                          )
                        })}
                    </TableRow>
                  )
                })
              )
            : table.getRowModel().rows.map((row) => {
                return (
                  <TableRow key={row.id}>
                    {row
                      .getVisibleCells()
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
                      .filter((cell) => !(cell?.column?.columnDef.meta as any)?.isLogical)
                      .map((cell) => {
                        return (
                          <TableCell
                            key={cell.id}
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            style={{
                              width:
                                cell.column.getSize() !== Number.MAX_SAFE_INTEGER ? cell.column.getSize() : undefined,
                              maxWidth: cell.column.columnDef.maxSize,
                              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
                              ...((cell.column.columnDef.meta as any)?.style || {}),
                            }}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        )
                      })}
                  </TableRow>
                )
              })}
        </TableBody>
      </Table>

      {includePaginationFooter && table.getPageCount() > 1 ? (
        <div className="flex justify-end py-4">
          <Pagination
            pageIndex={table.getState().pagination.pageIndex}
            pageCount={table.getPageCount()}
            nextPage={() => table.nextPage()}
            previousPage={() => table.previousPage()}
          />
        </div>
      ) : null}
    </div>
  )
}
