import { SearchResultDTO } from "@core-services/dtos"
import { SearchResultItem } from "./context"
import { defaultLinkGroups } from "./linkGroups"

const routes = defaultLinkGroups.flatMap((group) => group.links)

export const getSearchResultRoute = (resultType: string) => {
  const entity = resultType.toLowerCase()
  return routes.find((route) => route.entity === entity)
}

export const getSearchResultUrlNew = (result: SearchResultItem): string => {
  const route = getSearchResultRoute(result.type)

  if (!route) {
    return "/"
  }

  if (route.url === "/channels") {
    return `${route.url}/edit/${result.id}/details`
  }

  return `${route.url}/edit/${result.id}`
}

export const getSearchResultUrl = (result: SearchResultDTO): string => {
  const route = getSearchResultRoute(result.type)

  if (!route) {
    return "/"
  }

  if (route.url === "/channels") {
    return `${route.url}/edit/${result.entityId}/details`
  }

  return `${route.url}/edit/${result.entityId}`
}
