import { UserLogin } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { Button, FormControl, FormControlLabel, TextField } from "@vindral/components"
import { useForm } from "react-hook-form"
import { PasswordTextField } from "../../molecules/PasswordTextField"
import { VerifyTwoFactorModal } from "../../organisms/2fa/VerifyTwoFactorModal"
import Form from "../../organisms/Form"
import { ReCaptchaText } from "../../organisms/ReCaptchaText"

const resolver = zodResolver(UserLogin)

export interface LoginProps {
  login: (value: UserLogin) => Promise<void>
  email?: string
  verifyTwoFactor: (value: { code: string }) => Promise<void>
  openTwoFactorModal: boolean
  onCloseTwoFactorModal: () => void
}

export const Login = (props: LoginProps) => {
  const { login, email, verifyTwoFactor, openTwoFactorModal, onCloseTwoFactorModal } = props
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<UserLogin>({
    resolver,
    mode: "onChange",
    defaultValues: {
      email,
    },
  })

  return (
    <>
      <Form onSubmit={handleSubmit(login)} className="flex max-w-md flex-col gap-4 py-4" autoComplete="on">
        {email ? (
          <div>
            <span>Email address</span>
            <h1 className="font-medium underline">{email}</h1>
          </div>
        ) : (
          <FormControl>
            <FormControlLabel error={errors.email?.message} required>
              E-mail
            </FormControlLabel>
            <TextField
              {...register("email")}
              placeholder="email@domain.com"
              autoComplete="username"
              data-pw="email"
              required
            />
          </FormControl>
        )}
        <FormControl>
          <FormControlLabel error={errors.password?.message} required>
            Password
          </FormControlLabel>
          <PasswordTextField
            {...register("password")}
            placeholder="Your password..."
            autoComplete="current-password"
            data-pw="password"
            required
          />
          <ReCaptchaText />
        </FormControl>
        <div>
          <Button disabled={!isDirty || !isValid} type="submit" variant="primary" data-pw="loginButton">
            Sign in
          </Button>
        </div>
      </Form>
      <VerifyTwoFactorModal
        open={openTwoFactorModal}
        verify={verifyTwoFactor}
        onClose={onCloseTwoFactorModal}
        actionText="Sign in"
      />
    </>
  )
}
