import { useMemo, useState } from "react"
import { OrganizationItem } from "../../interfaces/Organization"
import { trpc } from "../../trpc"
import { MultiSelectorCombobox } from "./MultiSelector"

function getNextPageParam<T>(lastPage: { total: number }, allPages: { total: number; items: T[] }[]) {
  const nextSkip = allPages.reduce((prev, page) => prev + page.items.length, 0)
  return nextSkip < lastPage.total ? nextSkip : undefined
}

export interface MultiSelectorOrganizationComboboxProps {
  onSelect: (item: OrganizationItem) => void
  selected: { publicId: string }[]
}

export function MultiSelectorOrganizationCombobox(props: MultiSelectorOrganizationComboboxProps) {
  const [search, onSearchChange] = useState("")
  const query = trpc.organization.findMany.useInfiniteQuery(
    { orderBy: "name", search: search.length > 0 ? search : undefined },
    { getNextPageParam, keepPreviousData: true }
  )
  const allPages = useMemo(() => query.data?.pages.flatMap((page) => page.items) ?? [], [query.data])
  const selected = useMemo(() => new Set(props.selected.map((f) => f.publicId)), [props.selected])

  return (
    <MultiSelectorCombobox
      idField="publicId"
      selected={selected}
      options={[{ id: "organizations", title: "organizations", items: allPages }]}
      onSelect={(item) => props.onSelect(item)}
      renderItem={(item) => <div>{item.name}</div>}
      onReachedEnd={query.fetchNextPage}
      isLoading={query.isLoading}
      search={search}
      onSearchChange={onSearchChange}
    >
      Add organization
    </MultiSelectorCombobox>
  )
}
