import { Combobox as HeadlessCombobox, Transition } from "@headlessui/react"
import { MagnifyingGlassIcon, SymbolIcon } from "@radix-ui/react-icons"
import { cva } from "class-variance-authority"
import { ComponentProps, ForwardedRef, ReactElement, ReactNode, Ref, forwardRef, useEffect, useRef } from "react"
import { useIntersection } from "react-use"
import { Popover, PopoverContent, PopoverPortal, PopoverTrigger } from "../atoms/Popover"

export interface ItemGroup<TValue, Title> {
  id: string | number
  title?: Title
  items: TValue[]
}

interface CommonProps<TValue, Title> {
  isLoading?: boolean
  placeholder?: string
  emptyText?: string
  onReachedEnd?: () => void
  groups: ItemGroup<TValue, Title>[]
  query: string
  getId: (value: TValue) => string | number
  onQueryChange: (query: string) => void
  renderTitle: (title: Title) => ReactNode
  renderItem: (item: TValue, state: { active: boolean; selected: boolean }) => ReactElement
  popoverContentProps?: ComponentProps<typeof PopoverContent>
}

export interface SingleComboboxProps<TValue> {
  multiple: false
  selected: TValue | null
  onSelect: (item: TValue) => void
}

export interface MultiComboboxProps<TValue> {
  multiple: true
  selected: TValue[]
  onSelect: (item: TValue[]) => void
}

export type ComboboxProps<TValue, Title> = CommonProps<TValue, Title> &
  (SingleComboboxProps<TValue> | MultiComboboxProps<TValue>)

const option = cva(
  [
    "flex",
    "h-7",
    "cursor-pointer",
    "select-none",
    "items-center",
    "justify-between",
    "rounded-sm",
    "px-2",
    "outline-none",
    "aria-disabled:cursor-auto",
    "aria-disabled:text-fg-extra-subtle",
  ],
  {
    variants: {
      active: {
        true: ["bg-component-hover"],
        false: [""],
      },
    },
  }
)

export const ComboboxTrigger = PopoverTrigger
export const Combobox = Popover
export const ComboboxPortal = PopoverPortal

export function ComboboxContentImpl<TValue, Title>(
  props: ComboboxProps<TValue, Title>,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { onSelect, multiple, selected, getId, popoverContentProps } = props

  if (multiple) {
    return (
      <PopoverContent ref={ref} sideOffset={4} {...popoverContentProps}>
        <HeadlessCombobox<TValue[]> multiple value={selected} onChange={onSelect} by={(a, b) => getId(a) === getId(b)}>
          <ComboboxOptions {...props} />
        </HeadlessCombobox>
      </PopoverContent>
    )
  }

  return (
    <PopoverContent ref={ref} sideOffset={4} {...popoverContentProps}>
      <HeadlessCombobox
        value={selected}
        onChange={onSelect}
        by={(a, b) => {
          if (!a && !b) {
            return true
          }
          if (!a || !b) {
            return false
          }
          return getId(a) === getId(b)
        }}
      >
        <ComboboxOptions {...props} />
      </HeadlessCombobox>
    </PopoverContent>
  )
}

// eslint-disable-next-line total-functions/no-unsafe-type-assertion
export const ComboboxContent = forwardRef(ComboboxContentImpl) as <TValue, Title>(
  p: ComboboxProps<TValue, Title> & { ref?: Ref<HTMLDivElement> }
) => ReactElement

// TODO: Move this into a more general AnimatedSwitch-thingy
export function Fade({ children, show }: { children: ReactNode; show: boolean }) {
  return (
    <Transition
      show={show}
      enter="transform transition duration-200 delay-200"
      enterFrom="opacity-0 scale-50"
      enterTo="opacity-100 scale-100"
      leave="transform duration-200 transition ease-in-out delay-200"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-50"
    >
      {children}
    </Transition>
  )
}

const intersectionOptions = {
  rootMargin: "0px",
  threshold: 1,
}

function ComboboxOptions<T, Title>(props: ComboboxProps<T, Title>) {
  const {
    groups,
    onQueryChange,
    getId,
    placeholder = "Filter...",
    emptyText = "No results",
    query,
    renderItem,
    isLoading,
    onReachedEnd,
    renderTitle,
  } = props
  const topTargetRef = useRef(null)
  const bottomTargetRef = useRef(null)
  const bottomIntersection = useIntersection(bottomTargetRef, intersectionOptions)

  useEffect(() => {
    if (bottomIntersection?.isIntersecting) {
      onReachedEnd?.()
    }
  }, [bottomIntersection, onReachedEnd])

  const itemRef = (index: number) => {
    if (index === Math.max(0, groups.reduce((acc, { items }) => acc + items.length, 0) - 5)) {
      return bottomTargetRef
    }

    if (index === 0) {
      return topTargetRef
    }

    return null
  }

  const grandTotal = groups.reduce((prev, curr) => prev + curr.items.length, 0)

  return (
    <>
      <div className="flex items-center p-1 px-3">
        <div className="relative h-4 w-4 text-fg-subtle">
          <Fade show={!!isLoading}>
            <SymbolIcon className="absolute top-0 animate-spin" />
          </Fade>
          <Fade show={!isLoading}>
            <MagnifyingGlassIcon className="absolute top-0" />
          </Fade>
        </div>
        <HeadlessCombobox.Input
          type="search"
          name="search"
          autoComplete="off"
          className="w-full bg-transparent p-1 px-2 outline-none placeholder:text-fg-extra-subtle"
          placeholder={placeholder}
          value={query}
          onChange={(event) => onQueryChange(event.target.value)}
        />
      </div>
      <HeadlessCombobox.Options static className="max-h-60 overflow-y-auto border-t border-divider p-1">
        {grandTotal === 0 && (
          <div className="py-2 text-center text-sm font-semibold uppercase text-fg-subtle">{emptyText}</div>
        )}
        {groups.map((group, groupIndex) => {
          return (
            <div key={group.id}>
              {group.title && group.items.length > 0 && <div>{renderTitle(group.title)}</div>}
              <div>
                {group.items.map((item, index) => {
                  return (
                    <HeadlessCombobox.Option
                      key={getId(item)}
                      value={item}
                      className={option}
                      ref={itemRef(groups.length * groupIndex + index)}
                    >
                      {({ active, selected }) => renderItem(item, { active, selected })}
                    </HeadlessCombobox.Option>
                  )
                })}
              </div>
            </div>
          )
        })}
      </HeadlessCombobox.Options>
    </>
  )
}
