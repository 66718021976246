import { Link } from "@tanstack/react-router"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from "@vindral/components"
import prettyBytes from "pretty-bytes"
import { Page, PageContainer, PageContent } from "../../atoms/Page"
import { Trend } from "../../molecules/Trend"
import { WorldMap, generateMapPins } from "../../organisms/map/WorldMap"
import { RouterOutputs } from "../../trpc"
import { ProportionBars } from "./ProportionBars"

type DashboardStats = RouterOutputs["dashboard"]["get"]

const formatDataRate = (rate: number) => {
  return `${prettyBytes(rate, { bits: true })}/s`
}

type StatProps = {
  title: string
  currentValue: string | number
  otherValue?: string
  change?: number
  tooltip?: string
}
const Stat = ({ title, currentValue, change, otherValue, tooltip }: StatProps) => {
  const displayTrend = typeof change !== "undefined" && !isNaN(change) && isFinite(change)

  return (
    <div>
      <div className="flex min-h-[28px] items-center justify-between gap-1 tabular-nums text-fg-subtle">
        <span className="pr-1 text-sm font-medium uppercase tracking-wider">{title}</span>
        {displayTrend && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Trend change={change} />
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent side="right">{tooltip}</TooltipContent>
              </TooltipPortal>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
      <div className="pt-2 text-2xl font-medium tabular-nums">{currentValue}</div>
      {otherValue && <div className="pt-2 text-sm tabular-nums text-fg-subtle">{otherValue}</div>}
    </div>
  )
}

export const Dashboard = ({ stats, name }: { stats: DashboardStats; name: string }): JSX.Element => {
  const {
    viewersCurrent = 0,
    viewersCurrentByCountry = [],
    viewersAverage = 0,
    viewersAverageLastWeek = 0,
    egressCurrentBitrate = 0,
    egressCurrentBitrateAverage = 0,
    egressCurrentBitrateAverageLastWeek = 0,
    ingestBitrate = 0,
    ingestBitrateAverage = 0,
    ingestBitrateAverageLastWeek = 0,
    liveChannels = 0,
    topChannels = undefined,
  } = stats

  const getStatChange = (valueNow: number, valueThen: number) => {
    return valueNow / valueThen - 1
  }

  return (
    <Page>
      <PageContent>
        <PageContainer>
          <div className="pt-8">
            <h1 className="text-2xl font-medium leading-9">Welcome back, {name}</h1>
            <span className="text-fg-subtle">Here is what is happening right now.</span>
          </div>
          <div className="py-4">
            <div className="flex flex-col divide-y divide-divider rounded-sm border border-divider lg:flex-row lg:divide-x lg:divide-y-0">
              <div className="flex divide-x divide-divider lg:w-1/2">
                <div className="w-1/2 p-4 transition-colors">
                  <Stat title="Current Live Channels" currentValue={liveChannels} />
                </div>
                <div className="w-1/2 p-4 transition-colors">
                  <Stat
                    title="Current viewers"
                    currentValue={viewersCurrent}
                    otherValue={`${Math.round(viewersAverage)} viewers avg last 7 days`}
                    change={getStatChange(viewersAverage, viewersAverageLastWeek)}
                    tooltip="Compared to previous 7 day average"
                  />
                </div>
              </div>
              <div className="flex divide-x divide-divider lg:w-1/2">
                <div className="w-1/2 p-4 transition-colors">
                  <Stat
                    title="Current Egress"
                    currentValue={formatDataRate(egressCurrentBitrate)}
                    otherValue={`${formatDataRate(egressCurrentBitrateAverage)} avg last 7 days`}
                    change={getStatChange(egressCurrentBitrateAverage, egressCurrentBitrateAverageLastWeek)}
                    tooltip="Compared to previous 7 day average"
                  />
                </div>
                <div className="w-1/2 p-4 transition-colors">
                  <Stat
                    title="Current Ingress"
                    currentValue={formatDataRate(ingestBitrate)}
                    otherValue={`${formatDataRate(ingestBitrateAverage)} avg last 7 days`}
                    change={getStatChange(ingestBitrateAverage, ingestBitrateAverageLastWeek)}
                    tooltip="Compared to previous 7 day average"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="py-4">
            <div className="flex items-center justify-between py-4">
              <span className="text-base font-medium">Active Viewers Right Now</span>
            </div>

            <div className="flex gap-4">
              <div className="relative w-3/4">
                <WorldMap
                  {...{
                    maxRadius: 5,
                    minRadius: 1,
                    pins: generateMapPins(
                      viewersCurrentByCountry.map((data) => ({ ...data, value: data.viewers })),
                      "viewers"
                    ),
                  }}
                />
              </div>
              <div className="w-1/4">
                <div className="pb-2 text-base font-medium">Top countries</div>
                <ProportionBars
                  max={10}
                  data={viewersCurrentByCountry
                    .filter((stat) => stat.viewers > 0)
                    .map((stat) => ({
                      title: stat.country,
                      value: stat.viewers,
                    }))}
                />
              </div>
            </div>
          </div>

          <div className="py-4">
            <div className="flex items-center justify-between py-4">
              <span className="text-base font-medium">Top channels</span>
            </div>

            <Table>
              <TableHead>
                <TableRow>
                  {/* THUMBNAIL <TableHeadCell></TableHeadCell> */}
                  <TableHeadCell>Channel</TableHeadCell>
                  <TableHeadCell>Peak viewers last 24h</TableHeadCell>
                  <TableHeadCell>Current Viewers</TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {topChannels &&
                  topChannels.map((c) => {
                    return (
                      <TableRow key={c.id}>
                        <TableCell>
                          <Link
                            to="/channels/edit/$channelId/details"
                            params={{ channelId: c.channelId }}
                            className="font-medium"
                          >
                            {c.name}
                          </Link>
                        </TableCell>
                        <TableCell collapse>
                          <div className="text-right tabular-nums">{c.peakViewers}</div>
                        </TableCell>
                        <TableCell collapse>
                          <div className="text-right tabular-nums">{c.currentViewers}</div>
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </div>
        </PageContainer>
      </PageContent>
    </Page>
  )
}
