import { useAbility } from "@casl/react"
import { CreateApiKey, UpdateProfile, UpdateUserPassword, VerifyTwoFactor } from "@core-services/portal"
import { Navigate, Route } from "@tanstack/react-router"
import { toast } from "@vindral/components"
import { AbilityContext } from "../../acl"
import { useTwoFactor } from "../../contexts/twoFactor"
import { EditApiKeys } from "../../templates/profile/EditApiKeys"
import { EditPassword } from "../../templates/profile/EditPassword"
import { EditProfile } from "../../templates/profile/EditProfile"
import { Profile } from "../../templates/profile/Profile"
import { trpc } from "../../trpc"
import { onboardedRoute } from "../onboarded"

const profileRoute = new Route({
  getParentRoute: () => onboardedRoute,
  path: "profile",
  component: Page,
  getContext: () => {
    return {
      pageTitle: "Profile",
    }
  },
})

const indexRoute = new Route({
  getParentRoute: () => profileRoute,
  path: "/",
  component: IndexRoute,
  onLoad: async ({ context: { trpcContext } }) => {
    await trpcContext.user.profile.ensureData()
  },
})

function IndexRoute() {
  return <Navigate to="/profile/edit" replace />
}

function Page() {
  const { data: profile } = trpc.user.profile.useQuery(undefined, { suspense: true })

  if (!profile) {
    return null
  }

  return <Profile profile={profile} />
}

const profileEditRoute = new Route({
  getParentRoute: () => profileRoute,
  path: "edit",
  component: ProfileEdit,
})

function ProfileEdit() {
  const { data: profile } = trpc.user.profile.useQuery(undefined, { suspense: true })
  const ability = useAbility(AbilityContext)
  const context = trpc.useContext()

  if (!profile) {
    return null
  }

  const updateMutation = trpc.user.updateProfile.useMutation({
    onSuccess: () => {
      void context.user.profile.invalidate()
      toast({ title: "Saved successfully!" })
    },
    onError: (error) => {
      toast({ title: "Failed to save!", description: error.message })
    },
  })

  const updateUser = async (value: UpdateProfile) => {
    updateMutation.reset()
    await updateMutation.mutateAsync(value)
  }

  return <EditProfile profile={profile} ability={ability} updateUser={updateUser} />
}

const profileApiKeysRoute = new Route({
  getParentRoute: () => profileRoute,
  path: "api-keys",
  component: ProfileApiKeys,
})

function ProfileApiKeys() {
  const { data: profile } = trpc.user.profile.useQuery(undefined, { suspense: true })
  const { data: apiKeys = [] } = trpc.user.apiKeys.useQuery(undefined, { suspense: true })
  const ability = useAbility(AbilityContext)
  const context = trpc.useContext()

  if (!profile) {
    return null
  }

  const showApiKeys = profile.accessPermissions.find((a) => a.organization.apiAccessEnabled) != undefined
  if (!showApiKeys) {
    return <Navigate to="/profile/edit" />
  }

  const createMutation = trpc.apiKey.create.useMutation({
    onSuccess: () => {
      void context.user.apiKeys.invalidate()
      toast({ title: "Saved successfully!" })
    },
    onError: (error) => {
      toast({ title: "Failed to save!", description: error.message })
    },
  })

  const createApiKey = async (value: CreateApiKey) => {
    createMutation.reset()
    return await createMutation.mutateAsync(value)
  }

  const deleteMutation = trpc.apiKey.delete.useMutation({
    onSuccess: () => {
      void context.user.apiKeys.invalidate()
      toast({ title: "Api key deleted!" })
    },
    onError: (error) => {
      toast({ title: "Failed to delete api key!", description: error.message })
    },
  })

  const deleteApiKey = async (publicId: string) => {
    deleteMutation.reset()
    await deleteMutation.mutateAsync({ publicId })
  }

  return (
    <EditApiKeys
      profile={profile}
      ability={ability}
      apiKeys={apiKeys}
      createApiKey={createApiKey}
      deleteApiKey={deleteApiKey}
    />
  )
}

const profilePasswordRoute = new Route({
  getParentRoute: () => profileRoute,
  path: "password",
  component: ProfilePassword,
})

function ProfilePassword() {
  const { data: profile } = trpc.user.profile.useQuery(undefined, { suspense: true })
  const ability = useAbility(AbilityContext)
  const context = trpc.useContext()
  const { withTwoFactor } = useTwoFactor()

  if (!profile) {
    return null
  }

  const changePasswordMutation = trpc.user.changePassword.useMutation({
    onSuccess: () => {
      toast({ title: "Saved successfully!" })
    },
    onError: (error) => {
      toast({ title: "Failed to save!", description: error.message })
    },
  })

  const updateUserPassword = withTwoFactor(async (value: UpdateUserPassword) => {
    changePasswordMutation.reset()
    await changePasswordMutation.mutateAsync(value)
  })

  const generate2faSecretMutation = trpc.auth.generateTwoFactorSecret.useMutation()
  const generate2fa = withTwoFactor(async () => {
    generate2faSecretMutation.reset()
    return await generate2faSecretMutation.mutateAsync({})
  })

  const enable2faMutation = trpc.auth.enableTwoFactor.useMutation({
    onSuccess: () => {
      toast({ title: "Two-factor authentication enabled!" })
      void context.user.profile.invalidate()
    },
    onError: (error) => {
      toast({ title: "Two-factor authentication failed!", description: error.message })
    },
  })

  const enable2fa = async (value: VerifyTwoFactor) => {
    enable2faMutation.reset()
    await enable2faMutation.mutateAsync(value)
  }

  const disable2faMutation = trpc.auth.disableTwoFactor.useMutation({
    onSuccess: () => {
      toast({ title: "Two-factor authentication disabled!" })
      void context.user.profile.invalidate()
    },
    onError: (error) => {
      toast({ title: "Disable Two-factor authentication failed!", description: error.message })
    },
  })

  const disable2fa = withTwoFactor(async () => {
    disable2faMutation.reset()
    await disable2faMutation.mutateAsync({})
  })

  return (
    <EditPassword
      profile={profile}
      ability={ability}
      updateUserPassword={updateUserPassword}
      generate2fa={generate2fa}
      verify2fa={enable2fa}
      disable2fa={disable2fa}
    />
  )
}

export const profileRoutes = profileRoute.addChildren([
  indexRoute,
  profileEditRoute,
  profileApiKeysRoute,
  profilePasswordRoute,
])
