"use client"

import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons"
import cn from "classnames"
import * as React from "react"
import { DayPicker } from "react-day-picker"
import { buttonVariants } from "./Button"

export type CalendarProps = React.ComponentProps<typeof DayPicker>

function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-sm font-medium",
        nav: "space-x-1 flex items-center",
        nav_button: cn(buttonVariants({ variant: "secondary" }), "h-7 w-7 bg-transparent p-0"),
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell: "text-fg-extra-subtle rounded-md w-8 font-normal text-[0.8rem]",
        row: "flex w-full mt-2",
        cell: cn(
          "relative p-0 text-center text-fg-subtle text-sm [&:has([aria-selected])]:bg-component-active [&:has([aria-selected].day-outside)]:bg-transparent [&:has([aria-selected].day-range-end)]:rounded-r-md",
          props.mode === "range"
            ? "[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md"
            : "[&:has([aria-selected])]:rounded-md"
        ),
        day: "h-8 w-8 p-0 font-bold",
        day_range_start: "day-range-start",
        day_range_end: "day-range-end",
        day_selected: "bg-component-active text-fg hover:bg-component-hover hover:text-fg focus:text-fg",
        day_today: "",
        day_outside: "day-outside aria-selected:text-fg-extra-subtle invisible",
        day_disabled: "text-fg-extra-subtle disabled:cursor-not-allowed font-normal",
        day_range_middle: "aria-selected:bg-component-active hover:bg-component-hover aria-selected:text-fg",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        IconLeft: () => <ChevronLeftIcon className="absolute h-4 w-4" />,
        IconRight: () => <ChevronRightIcon className="absolute h-4 w-4" />,
      }}
      {...props}
    />
  )
}
Calendar.displayName = "Calendar"

export { Calendar }
