import { CaretUpIcon } from "@radix-ui/react-icons"
import { cva, cx, VariantProps } from "class-variance-authority"
import { forwardRef } from "react"

export const Table = forwardRef<HTMLTableElement, React.TableHTMLAttributes<HTMLTableElement>>((props, ref) => {
  return (
    <table
      {...props}
      ref={ref}
      className="w-full table-auto border-separate border-spacing-0 rounded-sm border border-divider"
    />
  )
})

export const TableBody = forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  (props, ref) => {
    return <tbody {...props} ref={ref} className="" />
  }
)

export const TableHead = forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  (props, ref) => {
    return <thead {...props} ref={ref} className="sticky top-0 z-10 rounded-sm bg-component" />
  }
)

export const TableFooter = forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  (props, ref) => {
    return <tfoot {...props} ref={ref} className="bottom-0 z-30 bg-component" />
  }
)

const sortIndicator = cva("ml-1 inline-block transition-all", {
  variants: {
    sortDirection: {
      asc: "",
      desc: "rotate-180",
      false: "hidden scale-75",
    },
  },
  defaultVariants: {
    sortDirection: false,
  },
})

const tableHeaderCell = cva(
  [
    "relative",
    "border-b",
    "border-divider",
    "whitespace-nowrap",
    "py-1",
    "px-2",
    "h-8",
    "text-left",
    "text-xs",
    "font-semibold",
    "uppercase",
    "tracking-widest",
    "text-fg-subtle",
  ],
  {
    variants: {
      isSortable: {
        true: ["cursor-pointer", "select-none", "hover:bg-component-hover", "active:bg-component-active"],
      },
    },
  }
)

export type SortIndicatorVariants = VariantProps<typeof sortIndicator>
export type TableHeaderCellVariants = VariantProps<typeof tableHeaderCell>

export interface TableHeadCellProps {
  sortDirection?: SortIndicatorVariants["sortDirection"]
  width?: number
}

export const TableHeadCell = forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement> & TableHeadCellProps & TableHeaderCellVariants
>(({ isSortable, sortDirection, children, width, style, ...rest }, ref) => {
  return (
    <th {...rest} ref={ref} className={tableHeaderCell({ isSortable })} style={{ width, ...style }}>
      {children}
      <CaretUpIcon
        className={sortIndicator({
          sortDirection,
        })}
      />
    </th>
  )
})

const tableFooterCell = cva([
  "whitespace-nowrap",
  "border-t",
  "border-divider",
  "p-2",
  "text-left",
  "text-xs",
  "font-semibold",
  "uppercase",
  "tracking-wider",
  "text-fg-subtle",
])

export const TableFooterCell = forwardRef<HTMLTableCellElement, React.TdHTMLAttributes<HTMLTableCellElement>>(
  ({ children, ...rest }, ref) => {
    return (
      <td {...rest} ref={ref} className={tableFooterCell()}>
        {children}
      </td>
    )
  }
)

export const TableRow = forwardRef<HTMLTableRowElement, React.HTMLAttributes<HTMLTableRowElement>>((props, ref) => {
  return <tr {...props} ref={ref} className="group" />
})

export const TableCell = forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement> & { collapse?: boolean }
>((props, ref) => {
  const { collapse, ...rest } = props
  return (
    <td
      {...rest}
      ref={ref}
      className={cx("truncate border-b border-divider p-2 group-last:border-b-0", collapse ? "w-px" : "max-w-0")}
    />
  )
})
