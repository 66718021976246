import { Expose, Transform } from "class-transformer"
import isNumber from "lodash/isNumber"
import { BaseDTO } from "../base.dto"
import { transformDate } from "../transforms"

export class RegionDTO extends BaseDTO<RegionDTO> {
  public static default = (forSites?: number[]): RegionDTO => {
    return new RegionDTO({
      id: 1,
      name: "region1",
      sites: isNumber(forSites) ? [forSites] : [],
      createdAt: new Date(0),
    })
  }

  @Expose()
  @Transform(transformDate)
  createdAt!: Date

  @Expose()
  @Transform(transformDate)
  updatedAt?: Date

  @Expose()
  id!: number

  @Expose()
  name!: string

  @Expose()
  sites!: number[]
}
