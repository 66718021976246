import { OrganizationType } from "@core-services/data-types"
import { OrganizationBillingStatus } from "@core-services/data-types/src/lib/organization-billing-status"
import { z } from "zod"
import { clientOverrides } from "../client-overrides"
import { clientTelemetry } from "../client-telemetry"
import { StringLimitation } from "../limitable"

export const UpdateOrganization = z.object({
  publicId: z.string(),
  name: z.string().max(50).optional(),
  suspended: z.boolean().optional(),

  maxStreams: z.number().nullish(),
  maxViewers: z.number().nullish(),
  maxCapacity: z.number().nullish(),

  maxPartnerStreams: z.number().nullish(),
  maxPartnerViewers: z.number().nullish(),
  maxPartnerCapacity: z.number().nullish(),

  maxChannels: z.number().optional(),
  maxChannelGroups: z.number().optional(),
  maxTranscodingProfiles: z.number().optional(),
  maxUsers: z.number().optional(),

  type: z.nativeEnum(OrganizationType).optional(),
  billingStatus: z.nativeEnum(OrganizationBillingStatus).optional(),
  siteLimitation: StringLimitation.optional(),
  clientOverrides: clientOverrides.optional(),
  clientTelemetry: clientTelemetry.optional(),
  apiAccessEnabled: z.boolean().optional(),
})

export type UpdateOrganization = z.infer<typeof UpdateOrganization>
