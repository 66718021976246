import { IconPlayerPlay } from "@tabler/icons-react"
import { Link } from "@tanstack/react-router"
import {
  Button,
  Copyable,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@vindral/components"
import { ChannelFindById } from "../templates/channel/ChannelEdit"
import { embedPlayerUrl, playGridUrlChannels, playUrl } from "../utils/urls"

interface OpenPlayerDropdownProps {
  channel: ChannelFindById
  noMetricsEdge?: string
  rtmpPlayUrl: string | undefined | null
}

function MenuHeader({ text }: { text: string }) {
  return <div className="p-2 text-sm font-semibold uppercase text-fg-extra-subtle">{text}</div>
}
export const OpenPlayerDropdown = ({ channel, noMetricsEdge, rtmpPlayUrl }: OpenPlayerDropdownProps) => {
  return (
    <div className="flex items-center gap-4">
      <Link to="/analytics/overview" search={(prev) => ({ ...prev, channelId: channel.channelId })}>
        <Button className="flex flex-col items-center" role="button">
          Analytics
        </Button>
      </Link>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button className="flex flex-col items-center" role="button">
            <IconPlayerPlay size={12} stroke={2} />
            Open
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent sideOffset={5} align="end">
          <MenuHeader text="Player links" />
          <DropdownMenuItem>
            <a
              href={embedPlayerUrl({
                channelId: channel.channelId,
                authToken: channel.authToken,
              })}
              target="_blank"
              className="flex h-full w-full items-center"
            >
              Embed Player
            </a>
          </DropdownMenuItem>

          <DropdownMenuItem>
            <a
              href={playUrl({
                channelId: channel.channelId,
                authToken: channel.authToken,
              })}
              target="_blank"
              className="flex h-full w-full items-center"
            >
              QoS Player
            </a>
          </DropdownMenuItem>

          <DropdownMenuItem>
            <a
              href={playGridUrlChannels({
                channelIds: [channel.channelId],
                authToken: channel.authToken,
              })}
              target="_blank"
              className="flex h-full w-full items-center"
            >
              Grid Player
            </a>
          </DropdownMenuItem>

          {noMetricsEdge && (
            <>
              <DropdownMenuSeparator />
              <MenuHeader text="Player links (no metrics)" />
              <DropdownMenuItem>
                <a
                  href={embedPlayerUrl({
                    channelId: channel.channelId,
                    authToken: channel.authToken,
                    edgeUrl: noMetricsEdge,
                  })}
                  target="_blank"
                  className="flex h-full w-full items-center"
                >
                  Embed Player (no metrics)
                </a>
              </DropdownMenuItem>

              <DropdownMenuItem>
                <a
                  href={playUrl({
                    channelId: channel.channelId,
                    authToken: channel.authToken,
                    edgeUrl: noMetricsEdge,
                  })}
                  target="_blank"
                  className="flex h-full w-full items-center"
                >
                  QoS Player (no metrics)
                </a>
              </DropdownMenuItem>

              <DropdownMenuItem>
                <a
                  href={playGridUrlChannels({
                    channelIds: [channel.channelId],
                    authToken: channel.authToken,
                    edgeUrl: noMetricsEdge,
                  })}
                  target="_blank"
                  className="flex h-full w-full items-center"
                >
                  Grid Player (no metrics)
                </a>
              </DropdownMenuItem>
            </>
          )}

          <DropdownMenuSeparator />

          <MenuHeader text="Internal links" />
          <DropdownMenuItem disabled={!rtmpPlayUrl}>
            {rtmpPlayUrl ? <Copyable text={rtmpPlayUrl}>RTMP Play URL</Copyable> : "RTMP Play URL"}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
