import { denyNothingNumberLimitation, denyNothingStringLimitation } from "@core-services/data-types"
import { UpdateSite } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { Divider } from "@vindral/components"
import { useEffect } from "react"
import { useController, useFieldArray, useForm } from "react-hook-form"
import { z } from "zod"
import { FormSection } from "../../../../atoms/FormSection"
import { SaveButton } from "../../../../atoms/SaveButton"
import { SaveSection } from "../../../../atoms/SaveSection"
import { LimitationModeSelect } from "../../../../molecules/LimitationModeSelect"
import { CountryMultiSelector } from "../../../../organisms/multiselect/CountryMultiSelector"
import { InputMultiSelector } from "../../../../organisms/multiselect/InputMultiSelector"
import { SiteEditPageProps } from "../../../../routes/site/site-edit"

const resolver = zodResolver(UpdateSite.merge(z.object({ countryLimitation: z.any(), asnLimitation: z.any() })))

export const SiteEditGeographicRules = ({ site, updateSite }: SiteEditPageProps) => {
  const countryLimitation = site.countryLimitation ?? denyNothingStringLimitation
  const asnLimitation = site.asnLimitation ?? denyNothingNumberLimitation
  const form = useForm({
    resolver,
    mode: "onChange",
    defaultValues: {
      ...site,
      countryLimitation: {
        mode: countryLimitation.mode,
        limited: countryLimitation.limited.map((l) => ({ countryCode: l })),
      },
      asnLimitation: {
        mode: asnLimitation.mode,
        limited: asnLimitation.limited.map((l) => ({ value: l.toString() })),
      },
    },
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, isSubmitSuccessful },
  } = form

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful, reset])

  const countryLimitationMode = useController({ control, name: "countryLimitation.mode" })
  const countryFieldArray = useFieldArray({ control, name: "countryLimitation.limited" })

  const asnLimitationMode = useController({ control, name: "asnLimitation.mode" })
  const asnFieldArray = useFieldArray({ control, name: "asnLimitation.limited" })

  return (
    <form
      className="flex flex-col gap-4"
      onKeyDown={(e) => {
        e.key === "Enter" && e.preventDefault()
      }}
      onSubmit={handleSubmit((site) => {
        return updateSite({
          ...site,
          countryLimitation: {
            mode: site.countryLimitation.mode,
            limited: site.countryLimitation.limited.map((limited) => limited.countryCode),
          },
          asnLimitation: {
            mode: site.asnLimitation.mode,
            limited: site.asnLimitation.limited.map((limited) => Number(limited.value)),
          },
        })
      })}
    >
      <FormSection
        title="Country Limitation"
        description="Limit which client countries that are allowed or denied on this site."
      >
        <div className="flex flex-col gap-4">
          <div>
            <LimitationModeSelect
              value={countryLimitationMode.field.value}
              onValueChange={(newMode) => countryLimitationMode.field.onChange(newMode)}
            />
          </div>
          <div
            className={
              countryLimitationMode.field.value === "disabled" ? "pointer-events-none select-none opacity-50" : ""
            }
          >
            <CountryMultiSelector
              onSelect={(item) => countryFieldArray.prepend(item)}
              onDeselect={(index) => countryFieldArray.remove(index)}
              selected={countryFieldArray.fields}
            />
          </div>
        </div>
      </FormSection>
      <Divider />
      <FormSection title="ASN Limitation" description="Limit which ASN that are allowed or denied on this site.">
        <div className="grid gap-4">
          <div>
            <LimitationModeSelect
              value={asnLimitationMode.field.value}
              onValueChange={(newMode) => asnLimitationMode.field.onChange(newMode)}
            />
          </div>
          <InputMultiSelector
            onSelect={(item) => {
              if (item.value && !Number.isNaN(Number(item.value))) {
                asnFieldArray.prepend(item)
              }
            }}
            onDeselect={(index) => asnFieldArray.remove(index)}
            selected={asnFieldArray.fields}
            nothingSelectedText="No ASNs selected"
          />
        </div>
      </FormSection>
      <SaveSection>
        <SaveButton text="Save" disabled={!isDirty || !isValid} />
      </SaveSection>
    </form>
  )
}
