import { zodResolver } from "@hookform/resolvers/zod"
import { Cross1Icon } from "@radix-ui/react-icons"
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  FormControl,
  FormControlLabel,
  TextField,
} from "@vindral/components"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"

const resolver = zodResolver(z.object({ code: z.string().length(6, "Please match the requested format") }))

export interface VerifyTwoFactorModalProps {
  open: boolean
  verify: (value: { code: string }) => Promise<void>
  onClose: () => void
  actionText: string
}

export const VerifyTwoFactorModal = (props: VerifyTwoFactorModalProps) => {
  const { verify, onClose, actionText } = props
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isDirty, isSubmitSuccessful },
  } = useForm<{ code: string }>({
    resolver,
    mode: "onSubmit",
  })

  useEffect(() => {
    if (props.open) {
      reset({ code: "" })
    }
  }, [props.open, reset])

  useEffect(() => {
    if (isSubmitSuccessful) {
      onClose()
    }
  }, [isSubmitSuccessful, onClose])

  const onSubmit = async (value: { code: string }) => {
    try {
      await verify(value)
    } catch (error) {
      if (error instanceof Error) {
        setError("code", { message: error.message })
      }
    }
  }

  const onOpenChange = (open: boolean) => {
    if (!open) {
      onClose()
    }
  }

  return (
    <Dialog open={props.open} onOpenChange={onOpenChange}>
      <DialogContent size="small" onPointerDownOutside={(e) => e.preventDefault()}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-between border-b border-divider p-4">
            <h1 className="font-medium">Two-Factor Authentication</h1>
            <DialogClose className="cursor-pointer focus:outline-none">
              <Cross1Icon />
            </DialogClose>
          </div>
          <div className="flex flex-col gap-4 p-4">
            <ol className="list-inside list-decimal space-y-6">
              <li>Open your Authenticator App</li>
              <li>Enter your verification code from the App in the field below</li>
            </ol>
            <FormControl>
              <FormControlLabel error={errors.code?.message}>Verification code</FormControlLabel>
              <TextField {...register("code")} type="text" placeholder="XXXXXX" />
            </FormControl>
          </div>
          <div className="border-t border-divider p-4 text-right">
            <Button disabled={!isDirty} type="submit" variant="primary">
              {actionText}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}
