import * as datefns from "date-fns"

export const allDateRanges = [
  "Last 1 hour",
  "Last 4 hours",
  "Last 12 hours",
  "Last 24 hours",
  "Last 7 days",
  "Last 14 days",
  "Last 30 days",
  "This month",
  "Last month",
] as const
export type DateRange = typeof allDateRanges[number]
export type AbsoluteDateRange = {
  from: Date
  to: Date
}
export type AnalyticsDateRange =
  | {
      type: "quick"
      range: DateRange
    }
  | {
      type: "absolute"
      range: AbsoluteDateRange
    }

export const dateRangeToString = (range: DateRange) => {
  if (range === "This month") {
    return `${range} (${datefns.format(new Date(), "MMMM")})`
  }

  if (range === "Last month") {
    return `${range} (${datefns.format(datefns.subMonths(new Date(), 1), "MMMM")})`
  }

  return range
}

export const getDateRangeSeconds = ({ type, range }: AnalyticsDateRange) => {
  // Rounds the date down to nearest 15 and returns the result in seconds
  const format = (d: Date) => {
    return roundDownToNearest15Seconds(d) / 1000
  }
  const roundDownToNearest15Seconds = (d: Date) => {
    const seconds = d.getSeconds()
    if (seconds > 45) {
      return d.setSeconds(45, 0)
    }
    if (seconds > 30) {
      return d.setSeconds(30, 0)
    }
    if (seconds > 15) {
      return d.setSeconds(15, 0)
    }
    return d.setSeconds(0, 0)
  }

  // If an absolute range is provided, use that
  if (type === "absolute") {
    const { from, to } = range

    return {
      from: format(from),
      to: format(to),
    }
  }

  const dateNow = new Date()
  const secondsNow = format(dateNow)
  switch (range) {
    case "Last 1 hour":
      return {
        from: format(datefns.sub(dateNow, { hours: 1 })),
        to: secondsNow,
      }
    case "Last 4 hours":
      return {
        from: format(datefns.sub(dateNow, { hours: 4 })),
        to: secondsNow,
      }
    case "Last 12 hours":
      return {
        from: format(datefns.sub(dateNow, { hours: 12 })),
        to: secondsNow,
      }
    case "Last 24 hours":
      return {
        from: format(datefns.sub(dateNow, { hours: 24 })),
        to: secondsNow,
      }
    case "Last 14 days":
      return {
        from: format(datefns.sub(dateNow, { days: 14 })),
        to: secondsNow,
      }
    case "Last 30 days":
      return {
        from: format(datefns.sub(dateNow, { days: 30 })),
        to: secondsNow,
      }
    case "This month": {
      const startOfThisMonth = datefns.startOfMonth(dateNow)
      const startOfLastMonth = datefns.startOfMonth(datefns.subMonths(dateNow, 1))
      const thisTimeLastMonth = datefns.subMonths(dateNow, 1)
      return {
        from: format(startOfThisMonth),
        to: secondsNow,
        subFrom: format(startOfLastMonth),
        subTo: format(thisTimeLastMonth),
      }
    }
    case "Last month": {
      const startOfLastMonth = datefns.startOfMonth(datefns.subMonths(dateNow, 1))
      const endOfLastMonth = datefns.endOfMonth(datefns.subMonths(dateNow, 1))
      const startOf2MonthsAgo = datefns.startOfMonth(datefns.subMonths(dateNow, 2))
      const endOf2MonthsAgo = datefns.subMonths(endOfLastMonth, 1)
      return {
        from: format(startOfLastMonth),
        to: format(endOfLastMonth),
        subFrom: format(startOf2MonthsAgo),
        subTo: format(endOf2MonthsAgo),
      }
    }
    case "Last 7 days":
    default:
      return {
        from: format(datefns.sub(dateNow, { days: 7 })),
        to: secondsNow,
      }
  }
}
