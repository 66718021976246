import { FormControl, FormControlLabel, TextField } from "@vindral/components"
import { useFormContext } from "react-hook-form"
import { floatOrNull } from "../utils/numberOrNull"

export function SourceOverridesForm() {
  const { register, formState } = useFormContext<{
    transcodingMultiplier: number | null | undefined
  }>()

  return (
    <div className="flex flex-col gap-4">
      <FormControl>
        <FormControlLabel error={formState.errors.transcodingMultiplier?.message}>
          Transcoding Multiplier
        </FormControlLabel>
        <TextField
          {...register("transcodingMultiplier", { setValueAs: floatOrNull })}
          error={!!formState.errors.transcodingMultiplier?.message}
          placeholder="1.0"
        />
      </FormControl>
    </div>
  )
}
