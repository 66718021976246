import { ForcedSubject, subject } from "@casl/ability"
import { permittedFieldsOf } from "@casl/ability/extra"
import { createContextualCan } from "@casl/react"
import { AccessPermissionRole, Action, OrganizationType } from "@core-services/data-types"
import { UserProfileDTO } from "@core-services/dtos"
import type { AppAbility, AppSubjectTypes, AppSubjects } from "@core-services/portal"
import { UserProfile } from "@core-services/portal"
import keys from "lodash/keys"
import pick from "lodash/pick"
import { createContext } from "react"

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-explicit-any
export const AbilityContext = createContext<AppAbility>(null as any)
export const Can = createContextualCan(AbilityContext.Consumer)

export const pickPermittedFields = <T>(
  ability: AppAbility,
  action: Action,
  targetSubject: AppSubjects & ForcedSubject<AppSubjectTypes>,
  data: T
) => {
  const permitted = permittedFieldsOf(ability, action, targetSubject, {
    fieldsFrom: (rule) => {
      return rule.fields || keys(data)
    },
  })

  return pick(data, permitted)
}

/**
 * Check if user or ability has super user permissions.
 **/
export const checkIfSuperUser = ({
  ability,
  userProfile,
  legacyUserProfile,
}: {
  ability?: AppAbility
  userProfile?: UserProfile
  legacyUserProfile?: UserProfileDTO
}) => {
  if (!ability && !userProfile && !legacyUserProfile) {
    console.warn("isSuperUser: One of ability, user profile or legacy user profile must be provided.")
    return false
  }

  if (ability) {
    return ability.can("manage", "all")
  }

  if (userProfile) {
    return !!userProfile.accessPermissions.find(
      (ap) => ap.organization.type === OrganizationType.SystemOwner && ap.role === AccessPermissionRole.Administrator
    )
  }

  if (!legacyUserProfile || !legacyUserProfile.accessPermissions) {
    console.warn("isSuperUser: Legacy user profile must include access permissions.")
    return false
  }

  if (legacyUserProfile) {
    return !!legacyUserProfile.accessPermissions.find(
      (ap) => ap.organization.type === OrganizationType.SystemOwner && ap.role === AccessPermissionRole.Administrator
    )
  }

  return false
}

export function channelSubjectInOrganization(organizationPublicId: string) {
  return subject("Channel", { organization: { publicId: organizationPublicId } })
}

export function sourceFeedSettingsInOrganization(organizationPublicId: string) {
  return subject("SourceFeedSettings", { channel: { organization: { publicId: organizationPublicId } } })
}

export function transcodingProfileSubjectInOrganization(organizationPublicId: string) {
  return subject("TranscodingProfile", { organization: { publicId: organizationPublicId } })
}

export function channelGroupSubjectInOrganization(organizationPublicId: string) {
  return subject("ChannelGroup", { organization: { publicId: organizationPublicId } })
}

export function organizationSubjectInOrganization(organizationPublicId: string) {
  return subject("Organization", { publicId: organizationPublicId })
}

export function userSubjectForUserInOrganization(args: {
  publicId: string
  accessPermissions: {
    organization: { publicId: string }
    role: AccessPermissionRole
  }[]
}) {
  const { accessPermissions, publicId } = args

  return subject("User", { accessPermissions, publicId })
}

export function accessPermissionsSubjectInOrganization(userPublicId: string, organizationPublicId: string) {
  return subject("AccessPermissions", {
    user: { publicId: userPublicId },
    organization: { publicId: organizationPublicId },
  })
}

export function sinkSubjectInOrganization(organizationPublicId: string) {
  return subject("Sink", { channel: { organization: { publicId: organizationPublicId } } })
}

export function hyperlocalEdgeSubjectInOrganization(organizationPublicId: string) {
  return subject("HyperlocalEdge", { organization: { publicId: organizationPublicId } })
}
