import type { Router } from "@core-services/portal"
import { createTRPCReact, type inferReactQueryProcedureOptions } from "@trpc/react-query"

export const trpc = createTRPCReact<Router>()

import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server"

export type ReactQueryOptions = inferReactQueryProcedureOptions<Router>
export type RouterInputs = inferRouterInputs<Router>
export type RouterOutputs = inferRouterOutputs<Router>
