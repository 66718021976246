import { Pencil2Icon } from "@radix-ui/react-icons"
import { IconAdjustmentsFilled, IconDownload, IconPlayerPlay } from "@tabler/icons-react"
import { Link } from "@tanstack/react-router"
import {
  TableOptions,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import {
  Button,
  Copyable,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSwitchItem,
  DropdownMenuTrigger,
} from "@vindral/components"
import { ReactNode, useMemo } from "react"
import { ChannelGroupItem } from "../interfaces/ChannelGroup"
import { DebouncedTextField } from "../molecules/DebouncedTextField"
import { playGridUrlChannelGroupId } from "../utils/urls"
import { InteractiveTable } from "./InteractiveTable"
import { NoResultsFound } from "./NoResultsFound"

function columnNames(id: string) {
  switch (id) {
    case "name":
      return "Name"
    case "organization.name":
      return "Organization"
    case "channelGroupId":
      return "Channel Group ID"
    case "actions":
      return "Actions"
    case "view":
      return "View"
  }

  return id
}

function ChannelGroupLink({ channelGroupId, children }: { channelGroupId: string; children: ReactNode }) {
  return (
    <Link to="/channel-groups/edit/$id" className="whitespace-nowrap font-semibold" params={{ id: channelGroupId }}>
      {children}
    </Link>
  )
}

export type UseChannelGroupTableProps = Omit<
  TableOptions<ChannelGroupItem>,
  "columns" | "getCoreRowModel" | "getSortedRowModel" | "getPaginationRowModel"
> & {
  downloadCsvUrl?: string
  noMetricsEdge?: string
}

export function useChannelGroupReactTable(options: UseChannelGroupTableProps) {
  const { noMetricsEdge } = options
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<ChannelGroupItem>()

    return [
      columnHelper.display({
        id: "view",
        header: () => "View",
        cell: (info) => (
          <div>
            <a
              className="block w-[72px]"
              href={playGridUrlChannelGroupId({
                channelGroupId: info.row.original.channelGroupId,
                authToken: info.row.original.authToken,
                useThumbnails: true,
                edgeUrl: noMetricsEdge,
              })}
              target="_blank"
              data-pw="playLink"
            >
              <Button data-pw="editChannelGroupButton" size="small">
                <IconPlayerPlay size={16} />
                View
              </Button>
            </a>
          </div>
        ),
        size: 90,
        maxSize: 90,
      }),
      columnHelper.accessor("name", {
        header: () => "Name",
        cell: (info) => (
          <div className="flex gap-2 whitespace-nowrap">
            <ChannelGroupLink channelGroupId={info.row.original.channelGroupId}>{info.getValue()}</ChannelGroupLink>
          </div>
        ),
        size: Number.MAX_SAFE_INTEGER,
      }),
      columnHelper.accessor("channelGroupId", {
        header: () => "Channel Group ID",
        cell: (info) => (
          <Copyable text={info.getValue()}>
            <span className="truncate text-fg-subtle">{info.getValue()}</span>
          </Copyable>
        ),
        size: Number.MAX_SAFE_INTEGER,
      }),
      columnHelper.accessor("organization.name", {
        id: "organization.name",
        header: () => "Organization",
        cell: (info) => (
          <span className="whitespace-nowrap font-semibold">
            <Link to="/organizations/edit/$publicId" params={{ publicId: info.row.original.organization.publicId }}>
              {info.getValue()}
            </Link>
          </span>
        ),
        size: Number.MAX_SAFE_INTEGER,
      }),
      columnHelper.display({
        id: "actions",
        header: () => "",
        cell: (info) => (
          <div className="flex justify-end">
            <Link to="/channel-groups/edit/$id" params={{ id: info.row.original.channelGroupId }}>
              <Button data-pw="editChannelGroupButton" size="small" variant="tertiary">
                <Pencil2Icon />
              </Button>
            </Link>
          </div>
        ),
        size: 50,
        maxSize: 50,
      }),
    ]
  }, [noMetricsEdge])

  return useReactTable({
    ...options,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowId: (row) => row.channelGroupId,
  })
}

export type ChannelGroupTableProps = Omit<
  TableOptions<ChannelGroupItem>,
  "columns" | "getCoreRowModel" | "getSortedRowModel" | "getPaginationRowModel"
> & {
  saveColumnVisibility: () => void
  resetColumnVisibilityToDefault: () => void
  downloadCsvUrl?: string
  table: ReturnType<typeof useChannelGroupReactTable>
}

export function ChannelGroupTable(options: ChannelGroupTableProps) {
  const { downloadCsvUrl, table } = options

  // If there is a filter AND no rows - that means we should show "no results".
  // This is different from zero rows and no filter - in that case we should show
  // a call to action.
  const noResults = table.getRowModel().rows.length === 0 && !!table.getState().globalFilter

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-2">
        <div>
          <DebouncedTextField
            // eslint-disable-next-line total-functions/no-unsafe-type-assertion
            initialValue={table.getState().globalFilter as string}
            onValueChange={table.setGlobalFilter}
            debounceMs={200}
            placeholder="Filter..."
          />
        </div>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button data-pw="configureButton">
              <IconAdjustmentsFilled size={16} />
              Configure
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent sideOffset={4} align="start">
            {table.getAllLeafColumns().map((column) => {
              return (
                <DropdownMenuSwitchItem
                  key={column.id}
                  checked={column.getIsVisible()}
                  onSelect={() => column.toggleVisibility()}
                >
                  {columnNames(column.id)}
                </DropdownMenuSwitchItem>
              )
            })}
            <DropdownMenuSeparator />
            <DropdownMenuItem onSelect={() => options.saveColumnVisibility()}>Save</DropdownMenuItem>
            <DropdownMenuItem onSelect={() => options.resetColumnVisibilityToDefault()}>
              Reset to default
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <div className="grow text-right">
          {downloadCsvUrl && (
            <a href={downloadCsvUrl} download="channel-groups.csv">
              <Button>
                <IconDownload size={16} />
                <span className="font-normal">Download CSV</span>
              </Button>
            </a>
          )}
        </div>
      </div>

      {noResults ? <NoResultsFound /> : <InteractiveTable table={table} />}
    </div>
  )
}
