import { Action, OrganizationBillingStatus, OrganizationType } from "@core-services/data-types"
import { AppAbility, OrganizationFindOne, UpdateOrganization } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  FormControlLabel,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Switch,
  TextField,
} from "@vindral/components"
import { useEffect } from "react"
import { useController, useForm } from "react-hook-form"
import { checkIfSuperUser, organizationSubjectInOrganization } from "../../../acl"
import { FormSection } from "../../../atoms/FormSection"
import { SaveButton } from "../../../atoms/SaveButton"
import { SaveSection } from "../../../atoms/SaveSection"
import { Message, MessageSection } from "../../../molecules/Message"
import { RouteBlockDialog } from "../../../molecules/RouteBlockDialog"

const resolver = zodResolver(UpdateOrganization)

export interface OrganizationEditGeneralProps {
  organization: OrganizationFindOne
  ability: AppAbility
  onUpdate: (organization: UpdateOrganization) => Promise<void>
}
export function OrganizationEditGeneral({ organization, ability, onUpdate }: OrganizationEditGeneralProps) {
  const subject = organizationSubjectInOrganization(organization.publicId)
  const isSuperUser = checkIfSuperUser({ ability })
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty, isValid, isSubmitting, isSubmitSuccessful },
  } = useForm<UpdateOrganization>({
    resolver,
    mode: "onChange",
    defaultValues: {
      publicId: organization.publicId,
      name: organization.name,
      type: organization.type,
      billingStatus: organization.billingStatus,
      apiAccessEnabled: organization.apiAccessEnabled,
      suspended: organization.suspended,
    },
  })

  useEffect(() => {
    reset({
      publicId: organization.publicId,
      name: organization.name,
      type: organization.type,
      billingStatus: organization.billingStatus,
      apiAccessEnabled: organization.apiAccessEnabled,
      suspended: organization.suspended,
    })
  }, [reset, organization])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful, reset])

  const typeController = useController({ control, name: "type" })
  const billingStatusController = useController({ control, name: "billingStatus" })
  const apiAccessController = useController({ control, name: "apiAccessEnabled" })
  const suspendedController = useController({ control, name: "suspended" })

  if (!isSuperUser) {
    return <></>
  }

  const columnWidth = "w-full max-w-lg"

  return (
    <div className="py-4">
      <RouteBlockDialog condition={isDirty && !isSubmitting} />
      <form onSubmit={handleSubmit(onUpdate)} className="flex flex-col gap-4">
        <FormSection
          width={columnWidth}
          title="Name"
          description="Changing the name does not change the unique name that is generated when creating the organization."
        >
          <TextField
            {...register("name")}
            error={!!errors?.name}
            disabled={ability.cannot(Action.Update, subject, "name")}
          />
          <FormControlLabel error={errors.name?.message} />
        </FormSection>
        <FormSection
          width={columnWidth}
          title="Type"
          description="The type affects what members of this organization are allowed to do."
        >
          <Select
            disabled={ability.cannot(Action.Update, subject, "type")}
            value={typeController.field.value}
            onValueChange={typeController.field.onChange}
          >
            <SelectTrigger size="medium">
              <SelectValue placeholder="Select type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={OrganizationType.Customer}>Customer</SelectItem>
              <SelectItem value={OrganizationType.Partner}>Partner</SelectItem>
              <SelectItem value={OrganizationType.SystemOwner}>System owner</SelectItem>
            </SelectContent>
          </Select>
        </FormSection>
        <FormSection
          width={columnWidth}
          title="Billing Status"
          description="The billing status affects the limits of the organization."
        >
          <Select
            disabled={ability.cannot(Action.Update, subject, "billingStatus")}
            value={billingStatusController.field.value}
            onValueChange={billingStatusController.field.onChange}
          >
            <SelectTrigger size="medium">
              <SelectValue placeholder="Select type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={OrganizationBillingStatus.Billable}>Billable</SelectItem>
              <SelectItem value={OrganizationBillingStatus.Evaluation}>Evaluation</SelectItem>
              <SelectItem value={OrganizationBillingStatus.UpgradeRequested}>Upgrade Requested</SelectItem>
            </SelectContent>
          </Select>
        </FormSection>
        <FormSection width={columnWidth} title="Api Access" description="Allow or deny API Access.">
          <Switch
            data-pw="apiAccessSwitch"
            checked={apiAccessController.field.value}
            disabled={ability.cannot(Action.Update, subject, "apiAccessEnabled")}
            onCheckedChange={apiAccessController.field.onChange}
          />
        </FormSection>
        <Message severity="error">
          <MessageSection>
            <div className="flex w-full">
              <div className="grow">
                <h1 className="mb-1 font-medium">Suspend</h1>
                <span>Suspending an organization will block it from ingesting.</span>
              </div>
              <Switch
                checked={suspendedController.field.value}
                disabled={ability.cannot(Action.Update, subject, "suspended")}
                onCheckedChange={suspendedController.field.onChange}
                variant="danger"
              />
            </div>
          </MessageSection>
        </Message>
        {ability.can(Action.Update, subject) && (
          <SaveSection width={columnWidth}>
            <SaveButton text="Save" disabled={!isDirty || !isValid} />
          </SaveSection>
        )}
      </form>
    </div>
  )
}
