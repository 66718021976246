import { useAbility } from "@casl/react"
import { formatFrameRate, getAudioMetadata, getVideoMetadata } from "@core-services/data-types"
import { IngestSessionItem } from "@core-services/portal"
import { IconAdjustmentsFilled } from "@tabler/icons-react"
import {
  TableOptions,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSwitchItem,
  DropdownMenuTrigger,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
  formatChannels,
  formatCodec,
  formatSampleRate,
} from "@vindral/components"
import { AbilityContext, checkIfSuperUser } from "../acl"
import { DebouncedTextField } from "../molecules/DebouncedTextField"
import { FormattedDate } from "../molecules/FormattedDate"
import { FormattedDuration } from "../molecules/FormattedDuration"
import { InteractiveTable } from "./InteractiveTable"
import { NoResultsFound } from "./NoResultsFound"

function TooltipText({ text, tooltip }: { text: string; tooltip: string }) {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild className="cursor-default">
          <div>{text}</div>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent>{tooltip}</TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  )
}

function Video({ ingestSession }: { ingestSession: IngestSessionItem }) {
  const metadata = getVideoMetadata(ingestSession.metadata)?.[0]

  if (!metadata) {
    return <span>N/A</span>
  }

  const videoSize = `${metadata.width}x${metadata.height}@${formatFrameRate(metadata.frameRate)}`

  const text = [formatCodec(metadata.codec ?? "-"), videoSize].filter((a) => !!a).join(" ")

  const tooltip = [formatCodec(metadata.codec ?? "-"), metadata.profile, metadata.level, videoSize]
    .filter((a) => !!a)
    .join(" ")

  return <TooltipText text={text} tooltip={tooltip} />
}

function Audio({ ingestSession }: { ingestSession: IngestSessionItem }) {
  const metadata = getAudioMetadata(ingestSession.metadata)?.[0]

  if (!metadata) {
    return <span>N/A</span>
  }

  const text = formatCodec(metadata.codec ?? "-")

  const tooltip = [
    formatCodec(metadata.codec ?? "-"),
    metadata.profile,
    metadata.sampleRate ? formatSampleRate(metadata.sampleRate) : undefined,
    metadata.channels ? formatChannels(metadata.channels) : undefined,
  ]
    .filter((a) => !!a)
    .join(" ")

  return <TooltipText text={text} tooltip={tooltip} />
}

function Duration({ ingestSession }: { ingestSession: IngestSessionItem }) {
  return (
    <FormattedDuration
      endDate={ingestSession.stoppedAt ? new Date(ingestSession.stoppedAt) : new Date()}
      startDate={new Date(ingestSession.startedAt)}
    />
  )
}

function Stopped({ ingestSession }: { ingestSession: IngestSessionItem }) {
  if (!ingestSession || !ingestSession.stoppedAt) {
    return <span>Live</span>
  }

  return <FormattedDate date={new Date(ingestSession.stoppedAt)} />
}

function columnNames(id: string) {
  switch (id) {
    case "hostname":
      return "Site"
    case "sourceIp":
      return "Client address"
    case "protocol":
      return "Protocol"
    case "video":
      return "Video"
    case "audio":
      return "Audio"
    case "duration":
      return "Duration"
    case "startedAt":
      return "Started"
    case "stoppedAt":
      return "Stopped"
  }

  return id
}

export type IngestSessionTableProps = Omit<
  TableOptions<IngestSessionItem>,
  "columns" | "getCoreRowModel" | "getSortedRowModel" | "getPaginationRowModel"
> & {
  saveColumnVisibility: () => void
  resetColumnVisibilityToDefault: () => void
  showFilter?: boolean
}

export function IngestSessionTable(options: IngestSessionTableProps) {
  const columnHelper = createColumnHelper<IngestSessionItem>()

  const superUserColumns = [
    columnHelper.accessor("hostname", {
      header: () => "Site",
      cell: (info) => info.row.original.hostname?.split("-")[0] ?? "-",
      size: 20,
    }),
  ]

  const defaultColumns = [
    columnHelper.accessor("sourceIp", {
      id: "sourceIp",
      header: () => "Client address",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("protocol", {
      id: "protocol",
      header: () => "Protocol",
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "video",
      header: () => "Video",
      cell: (info) => <Video ingestSession={info.row.original} />,
    }),
    columnHelper.display({
      id: "audio",
      header: () => "Audio",
      cell: (info) => <Audio ingestSession={info.row.original} />,
    }),
    columnHelper.display({
      id: "duration",
      header: () => "Duration",
      cell: (info) => <Duration ingestSession={info.row.original} />,
    }),
    columnHelper.accessor("startedAt", {
      header: () => "Started",
      cell: (info) => <FormattedDate date={new Date(info.getValue())} />,
    }),
    columnHelper.accessor("stoppedAt", {
      header: () => "Stopped",
      cell: (info) => <Stopped ingestSession={info.row.original} />,
    }),
  ]

  const ability = useAbility(AbilityContext)
  const isSuperUser = checkIfSuperUser({ ability })
  const columns = isSuperUser ? [...superUserColumns, ...defaultColumns] : defaultColumns

  const table = useReactTable({
    ...options,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowId: (row) => String(row.sessionId),
  })

  // If there is a filter AND no rows - that means we should show "no results".
  // This is different from zero rows and no filter - in that case we should show
  // a call to action.
  const noResults = table.getRowModel().rows.length === 0 && !!table.getState().globalFilter

  return (
    <div className="flex min-h-0 grow flex-col">
      <div className="flex items-end gap-2 py-4">
        {options.showFilter && (
          <div className="flex gap-4">
            <DebouncedTextField
              // eslint-disable-next-line total-functions/no-unsafe-type-assertion
              initialValue={table.getState().globalFilter as string}
              onValueChange={table.setGlobalFilter}
              debounceMs={200}
              placeholder="Filter..."
            />
          </div>
        )}

        <div className="ml-auto">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button>
                <IconAdjustmentsFilled size={16} />
                Configure
              </Button>
            </DropdownMenuTrigger>

            <DropdownMenuContent sideOffset={4} align="start">
              {table.getAllLeafColumns().map((column) => {
                return (
                  <DropdownMenuSwitchItem
                    key={column.id}
                    checked={column.getIsVisible()}
                    onSelect={() => column.toggleVisibility()}
                  >
                    {columnNames(column.id)}
                  </DropdownMenuSwitchItem>
                )
              })}
              <DropdownMenuSeparator />
              <DropdownMenuItem onSelect={() => options.saveColumnVisibility()}>Save</DropdownMenuItem>
              <DropdownMenuItem onSelect={() => options.resetColumnVisibilityToDefault()}>
                Reset to default
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      {noResults ? <NoResultsFound /> : <InteractiveTable table={table} includePaginationFooter={true} />}
    </div>
  )
}
