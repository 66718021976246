import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group"
import { cx } from "class-variance-authority"

import { ComponentProps, ElementRef, forwardRef } from "react"

const StyledToggleGroup = forwardRef<
  ElementRef<typeof ToggleGroupPrimitive.Root>,
  ComponentProps<typeof ToggleGroupPrimitive.Root>
>((props, ref) => {
  return (
    <ToggleGroupPrimitive.Root
      ref={ref}
      {...props}
      className="inline-flex rounded-sm border border-divider-interactive bg-canvas-2 shadow shadow-black/10 dark:shadow-outline"
    />
  )
})
StyledToggleGroup.displayName = "StyledToggleGroup"

const StyledItem = forwardRef<
  ElementRef<typeof ToggleGroupPrimitive.Item>,
  ComponentProps<typeof ToggleGroupPrimitive.Item>
>((props, ref) => {
  return (
    <ToggleGroupPrimitive.Item
      ref={ref}
      {...props}
      className={cx([
        "m-1",
        "py-0.5",
        "px-2",
        "font-medium",
        "text-sm",
        "outline-none",
        "transition-colors",
        "rounded-sm",
        "active:bg-component-active",
        "hover:bg-component-hover",
        "text-fg-subtle",
        "data-[state=on]:bg-component-active",
        "data-[state=on]:text-fg",
      ])}
    />
  )
})
StyledItem.displayName = "StyledItem"

// Exports
export const ToggleGroup = StyledToggleGroup
export const ToggleGroupItem = StyledItem
