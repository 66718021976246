import { AppAbility } from "@common/access-control"
import { AccessPermissionRole, OrganizationBillingStatus } from "@core-services/data-types"
import { OrganizationFindOne } from "@core-services/portal"
import {
  IconAlertTriangle,
  IconArrowFork,
  IconBuildingBank,
  IconChartHistogram,
  IconCloudDown,
  IconCpu,
  IconExternalLink,
  IconKey,
  IconLayoutDashboard,
  IconMessageDots,
  IconServer2,
  IconSettings,
  IconSettingsAutomation,
  IconStack3,
  IconUser,
  IconUsersGroup,
  IconVideo,
  IconWorld,
  IconWorldDownload,
  TablerIconsProps,
} from "@tabler/icons-react"
import { Link, RegisteredRoutesInfo } from "@tanstack/react-router"
import {
  Chip,
  Dialog,
  DialogTrigger,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from "@vindral/components"
import { ComponentProps, ReactNode, useCallback, useMemo } from "react"
import { checkIfSuperUser } from "../acl"
import { HorizontalLogo } from "../atoms/logos/HorizontalLogo"
import { useOrganizationSelect } from "../hooks/useOrganizationSelect"
import { useUsefulLinks } from "../http/queries"
import { SingleSelectCombobox } from "../molecules/SingleSelectCombobox"
import { SupportFormDialog } from "../organisms/SupportFormDialog"
import { Search } from "../organisms/search/Search"
import { UpgradeAccountMessage } from "../organisms/upgradeAccount/UpgradeAccountMessage"
import { UpgradeAccountPendingMessage } from "../organisms/upgradeAccount/UpgradeAccountPendingMessage"
import { RouterOutputs, trpc } from "../trpc"
import { ProfileMenu } from "./profile/ProfileMenu"

type UserProfile = RouterOutputs["user"]["profile"]

export interface NavMenuProps {
  ability: AppAbility
  profile: UserProfile
  selectedOrganization?: OrganizationFindOne
  onSelectOrganization: (selectedOrganization: OrganizationFindOne | null) => void
  onLogOut: () => void
}

type NavItemProps<TFrom extends RegisteredRoutesInfo["routePaths"] = "/", TTo extends string = ""> = ComponentProps<
  typeof Link<TFrom, TTo>
>

function NavItem({ children, ...props }: NavItemProps) {
  return (
    <Link
      {...props}
      className="flex items-center gap-1 rounded-sm p-2 py-1 font-medium transition hover:bg-component-hover active:bg-component-active aria-disabled:pointer-events-none aria-disabled:opacity-50"
      activeProps={{ className: "bg-component-active" }}
    >
      {children}
    </Link>
  )
}

function ExternalNavItem({ children, href }: { children: ReactNode; href: string }) {
  return (
    <a href={href} target="_blank" className="flex items-center gap-1 rounded-sm p-1 px-2 hover:bg-component-hover">
      {children}
    </a>
  )
}

function NavSectionTitle({ children }: { children: ReactNode }) {
  return <span className="text-xs font-semibold uppercase tracking-widest text-fg-subtle">{children}</span>
}

const ALL_ORGANIZATIONS = "All organizations" as const
const ALL_ORGANIZATIONS_PUBLIC_ID = "all-organizations" as const
const iconProps = {
  size: "16",
  // stroke: 1,
  className: "",
} as TablerIconsProps

export function NavMenu({ profile, onSelectOrganization, selectedOrganization, onLogOut }: NavMenuProps) {
  const isSuperUser = checkIfSuperUser({ userProfile: profile })
  const organizationSelect = useOrganizationSelect({ defaultOrganization: selectedOrganization })
  const { data: links } = useUsefulLinks()

  const supportFormSubject = selectedOrganization ? `${selectedOrganization.name} - ${profile.name}` : profile.name

  const onOrganizationChange = useCallback(
    (organization: OrganizationFindOne | { name: string; publicId: string }) => {
      const selected =
        // eslint-disable-next-line total-functions/no-unsafe-type-assertion
        organization.publicId === ALL_ORGANIZATIONS_PUBLIC_ID ? null : (organization as OrganizationFindOne)

      organizationSelect.setSelected(selected)
      onSelectOrganization(selected)
    },
    [organizationSelect, onSelectOrganization]
  )

  const { data: buildInfo } = trpc.configuration.getBuildInfo.useQuery()
  const options = useMemo(() => {
    // Only system owners can pick ALL_ORGANIZATIONS
    if (isSuperUser) {
      return organizationSelect.options.map((group) => ({
        ...group,
        items: [{ name: ALL_ORGANIZATIONS, publicId: ALL_ORGANIZATIONS_PUBLIC_ID }, ...group.items],
      }))
    }

    return organizationSelect.options
  }, [organizationSelect.options, isSuperUser])

  const onlyHasOneOrganization = !isSuperUser && profile.accessPermissions.length === 1
  const isAdministrator = profile.accessPermissions.some(
    (permission) =>
      permission.organization.publicId === selectedOrganization?.publicId &&
      permission.role === AccessPermissionRole.Administrator
  )
  const showUpgradeAccount =
    selectedOrganization?.publicId &&
    isAdministrator &&
    selectedOrganization?.billingStatus === OrganizationBillingStatus.Evaluation

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="overflow-y-auto">
        <section>
          <div className="flex h-16 items-center justify-between p-4 px-6">
            <Link to="/">
              <div className="-ml-4 p-4">
                <HorizontalLogo className="block h-5" />
              </div>
            </Link>
            <Search organization={selectedOrganization} />
          </div>
          <div className="flex grow px-4 py-2">
            <SingleSelectCombobox<OrganizationFindOne | { name: string; publicId: string }>
              fullWidth
              options={options}
              selected={organizationSelect.selected}
              disabled={!!organizationSelect.yourOnlyOption || onlyHasOneOrganization}
              disabledText={organizationSelect.yourOnlyOption?.name}
              search={organizationSelect.search}
              isLoading={organizationSelect.query.isLoading}
              onSearchChange={organizationSelect.setSearch}
              dataPwButton="organizationContextSelector"
              placeholder={ALL_ORGANIZATIONS}
              onSelect={onOrganizationChange}
              onReachedEnd={() => {
                if (!organizationSelect.query.hasNextPage) {
                  return
                }
                void organizationSelect.query.fetchNextPage()
              }}
            />
          </div>
          {selectedOrganization?.suspended && (
            <div className="flex justify-center gap-1 px-4 py-2">
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger>
                    <Chip color="red">
                      <IconAlertTriangle size={12} />
                      Organization Suspended
                    </Chip>
                  </TooltipTrigger>
                  <TooltipPortal>
                    <TooltipContent side="right">
                      Ingest is blocked for all channels in this organization.
                      <br />
                      Contact support for more information!
                    </TooltipContent>
                  </TooltipPortal>
                </Tooltip>
              </TooltipProvider>
            </div>
          )}
          <div className="flex flex-col gap-1 px-4 py-2">
            <NavItem to="/dashboard">
              <IconLayoutDashboard {...iconProps} />
              Dashboard
            </NavItem>
            <NavItem to="/channels">
              <IconVideo {...iconProps} />
              Channels
            </NavItem>
            <NavItem to="/channel-groups">
              <IconStack3 {...iconProps} />
              Channel groups
            </NavItem>
            <NavItem to="/transcoding-profiles">
              <IconSettingsAutomation {...iconProps} />
              Transcoding Profiles
            </NavItem>
            <NavItem disabled={!selectedOrganization} to="/team">
              <IconUser {...iconProps} />
              Team
            </NavItem>
            {isSuperUser && (
              <NavItem
                disabled={!selectedOrganization}
                search={{ organizationId: selectedOrganization?.publicId }}
                to="/usage"
              >
                <IconWorldDownload {...iconProps} />
                Usage
              </NavItem>
            )}
            <NavItem to="/analytics">
              <IconChartHistogram {...iconProps} />
              Analytics
            </NavItem>
            <NavItem disabled={!selectedOrganization} to="/settings">
              <IconSettings {...iconProps} />
              Settings
            </NavItem>
          </div>
        </section>

        {isSuperUser ? (
          <section>
            <div className="px-6 pt-3">
              <NavSectionTitle>Administration</NavSectionTitle>
            </div>
            <div className="flex flex-col gap-1 px-4 py-2">
              <NavItem to="/organizations">
                <IconBuildingBank {...iconProps} />
                Organizations
              </NavItem>
              <NavItem to="/api-keys">
                <IconKey {...iconProps} />
                Api Keys
              </NavItem>
              <NavItem to="/users">
                <IconUsersGroup {...iconProps} />
                Users
              </NavItem>
            </div>
          </section>
        ) : null}

        {isSuperUser ? (
          <section>
            <div className="px-6 pt-3">
              <NavSectionTitle>Infrastructure</NavSectionTitle>
            </div>
            <div className="flex flex-col gap-1 px-4 py-2">
              <NavItem to="/regions">
                <IconWorld {...iconProps} />
                Regions
              </NavItem>
              <NavItem to="/sites">
                <IconServer2 {...iconProps} />
                Sites
              </NavItem>
              <NavItem to="/edge-servers">
                <IconCloudDown {...iconProps} />
                Edge Servers
              </NavItem>
              <NavItem to="/packager-servers">
                <IconCpu {...iconProps} />
                Packager Servers
              </NavItem>
              <NavItem to="/ingest-points">
                <IconArrowFork {...iconProps} />
                Ingest Points
              </NavItem>
            </div>
          </section>
        ) : null}

        <section>
          <div className="px-6 pt-3">
            <NavSectionTitle>Support</NavSectionTitle>
          </div>
          <div className="flex flex-col gap-1 px-4 py-2">
            <Dialog>
              <DialogTrigger asChild>
                <div className="flex cursor-pointer items-center gap-1 rounded-sm p-1 px-2 font-medium transition hover:bg-component-hover active:bg-component-active aria-disabled:pointer-events-none aria-disabled:opacity-50">
                  <IconMessageDots {...iconProps} />
                  Contact Support
                </div>
              </DialogTrigger>
              <SupportFormDialog subject={`Support form: ${supportFormSubject}`} email={profile.email} />
            </Dialog>
          </div>
        </section>

        <section>
          <div className="px-6 pt-3">
            <NavSectionTitle>Useful links</NavSectionTitle>
          </div>
          <div className="flex flex-col gap-1 px-4 py-2">
            {links?.map((link) => {
              return (
                <ExternalNavItem href={link.url} key={link.name}>
                  <IconExternalLink {...iconProps} />
                  {link.name}
                </ExternalNavItem>
              )
            })}
          </div>
        </section>

        {isSuperUser && (
          <section>
            <div className="px-6 pt-3">
              <NavSectionTitle>Build info</NavSectionTitle>
            </div>
            <div className="flex flex-col gap-1 p-2 px-6 font-mono text-sm">
              {buildInfo?.replace("vindral-portal:", "").replace("ghcr.io/realsprint/", "") ?? ""}
            </div>
          </section>
        )}
      </div>
      <div className="flex flex-col gap-4 p-4">
        {showUpgradeAccount && (
          <UpgradeAccountMessage email={profile.email} organizationId={selectedOrganization.publicId} />
        )}
        {selectedOrganization?.billingStatus === OrganizationBillingStatus.UpgradeRequested && (
          <UpgradeAccountPendingMessage />
        )}
        <section>
          <ProfileMenu onLogOut={onLogOut} />
        </section>
      </div>
    </div>
  )
}
