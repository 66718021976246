import prettyBytes from "pretty-bytes"
import { trpc } from "../../../trpc"
import { AnalyticsQueryArgs } from "../Analytics"
import { BaseGraph } from "./Base"
import { getSubTime } from "./getSubTime"

const format = (rate: number | undefined) => `${prettyBytes(rate || 0, { bits: true })}/s`

export const IngressGraph = (props: AnalyticsQueryArgs) => {
  const { from, to } = props.time
  const subTime = getSubTime(props.time)

  const { data: ingress, isFetching } = trpc.analytics.ingressBitrateRange.useQuery(props, {
    keepPreviousData: true,
  })

  const { data: prevIngress, isFetching: subIsFetching } = trpc.analytics.ingressBitrateRange.useQuery(
    { ...props, time: subTime },
    { keepPreviousData: true }
  )

  const mainData = ingress?.[0]?.points || []
  const subData = prevIngress?.[0]?.points || []

  return (
    <BaseGraph
      mainData={mainData}
      subData={subData}
      title="Ingress traffic"
      mainDescription="Ingress traffic this period"
      subDescription="Ingress traffic previous period"
      formatValue={format}
      formatYTicks={format}
      time={{ from, to, subFrom: subTime.from, subTo: subTime.to }}
      isLoading={isFetching || subIsFetching}
    />
  )
}
