import { AppAbility, OrganizationFindOne, UpdateOrganization } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { FormControlLabel, TextField } from "@vindral/components"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { checkIfSuperUser } from "../../../acl"
import { FormSection } from "../../../atoms/FormSection"
import { SaveButton } from "../../../atoms/SaveButton"
import { SaveSection } from "../../../atoms/SaveSection"
import { RouteBlockDialog } from "../../../molecules/RouteBlockDialog"
import { integerOrNull } from "../../../utils/numberOrNull"

const resolver = zodResolver(UpdateOrganization)

export interface OrganizationEditLimitsProps {
  organization: OrganizationFindOne
  ability: AppAbility
  onUpdate: (organization: UpdateOrganization) => Promise<void>
}

export function OrganizationEditLimits({ organization, ability, onUpdate }: OrganizationEditLimitsProps) {
  const isSuperUser = checkIfSuperUser({ ability })
  const {
    register,
    reset,
    handleSubmit,
    getFieldState,
    formState: { errors, isDirty, isValid, isSubmitting, isSubmitSuccessful },
  } = useForm<UpdateOrganization>({
    resolver,
    mode: "onChange",
    defaultValues: {
      publicId: organization.publicId,
      maxChannels: organization.maxChannels,
      maxChannelGroups: organization.maxChannelGroups,
      maxStreams: organization.maxStreams,
      maxViewers: organization.maxViewers,
      maxUsers: organization.maxUsers,
      maxTranscodingProfiles: organization.maxTranscodingProfiles,
    },
  })

  useEffect(() => {
    reset({
      publicId: organization.publicId,
      maxChannels: organization.maxChannels,
      maxChannelGroups: organization.maxChannelGroups,
      maxStreams: organization.maxStreams,
      maxViewers: organization.maxViewers,
      maxUsers: organization.maxUsers,
      maxTranscodingProfiles: organization.maxTranscodingProfiles,
    })
  }, [reset, organization])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful, reset])

  const columnWidth = "w-full max-w-lg"

  return (
    <div className="py-4">
      {!isSuperUser && (
        <>
          <h1 className="mb-1 text-xl font-medium">Limits</h1>
          <div className="mb-6">Contact support if you want to increase any of your limits.</div>
        </>
      )}
      <RouteBlockDialog condition={isDirty && !isSubmitting} />
      <form onSubmit={handleSubmit(onUpdate)} className="flex flex-col gap-4">
        <FormSection
          width={columnWidth}
          title="Channels"
          description="The maximum number of channels that can be created."
        >
          {isSuperUser ? (
            <>
              <TextField
                {...register("maxChannels", { setValueAs: integerOrNull })}
                error={!!getFieldState("maxChannels").error?.message}
                readOnly={!isSuperUser}
              />
              <FormControlLabel error={errors.maxChannels?.message} />
            </>
          ) : (
            <div data-pw="maxChannels">{organization.maxChannels}</div>
          )}
        </FormSection>
        <FormSection
          width={columnWidth}
          title="Channel Groups"
          description="The maximum number of channel groups that can be created."
        >
          {isSuperUser ? (
            <>
              <TextField
                {...register("maxChannelGroups", { setValueAs: integerOrNull })}
                error={!!getFieldState("maxChannelGroups").error?.message}
                readOnly={!isSuperUser}
              />
              <FormControlLabel error={errors.maxChannelGroups?.message} />
            </>
          ) : (
            <div data-pw="maxChannelGroups">{organization.maxChannelGroups}</div>
          )}
        </FormSection>
        <FormSection
          width={columnWidth}
          title="Active Channels"
          description="The maximum number of channels that can be active at the same time."
        >
          {isSuperUser ? (
            <>
              <TextField
                {...register("maxStreams", { setValueAs: integerOrNull })}
                error={!!getFieldState("maxStreams").error?.message}
                readOnly={!isSuperUser}
              />
              <FormControlLabel error={errors.maxStreams?.message} />
            </>
          ) : (
            <div data-pw="maxStreams">{organization.maxStreams}</div>
          )}
        </FormSection>
        <FormSection
          width={columnWidth}
          title="Viewers"
          description="The maximum number of viewers that can view your streams at the same time."
        >
          {isSuperUser ? (
            <>
              <TextField
                {...register("maxViewers", { setValueAs: integerOrNull })}
                error={!!getFieldState("maxViewers").error?.message}
                readOnly={!isSuperUser}
              />
              <FormControlLabel error={errors.maxViewers?.message} />
            </>
          ) : (
            <div data-pw="maxViewers">{organization.maxViewers}</div>
          )}
        </FormSection>
        <FormSection
          width={columnWidth}
          title="Team Members"
          description="The maximum number of team members that can be added to this organization."
        >
          {isSuperUser ? (
            <>
              <TextField
                {...register("maxUsers", { setValueAs: integerOrNull })}
                error={!!getFieldState("maxUsers").error?.message}
                readOnly={!isSuperUser}
              />
              <FormControlLabel error={errors.maxUsers?.message} />
            </>
          ) : (
            <div data-pw="maxUsers">{organization.maxUsers}</div>
          )}
        </FormSection>
        {isSuperUser && (
          <FormSection
            width={columnWidth}
            title="Transcoding Profiles"
            description="The maximum number of transcoding profiles that can be created."
          >
            <TextField
              {...register("maxTranscodingProfiles", { setValueAs: integerOrNull })}
              error={!!getFieldState("maxTranscodingProfiles").error?.message}
              readOnly={!isSuperUser}
            />
            <FormControlLabel error={errors.maxTranscodingProfiles?.message} />
          </FormSection>
        )}

        {isSuperUser && (
          <SaveSection width={columnWidth}>
            <SaveButton text="Save" disabled={!isDirty || !isValid} />
          </SaveSection>
        )}
      </form>
    </div>
  )
}
