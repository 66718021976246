import { z } from "zod"

export const VideoMetadata = z.object({
  type: z.literal("video").default("video"),
  width: z.number().int().positive(),
  height: z.number().int().positive(),
  frameRate: z.tuple([z.number().int().nonnegative(), z.number().int().nonnegative()]),
  bitRate: z.number().int().nonnegative(),
  codec: z.string(),
  profile: z.string().optional(),
  level: z
    .string()
    .optional()
    .transform((v) => (v ? prettifyLevel(v) : v)),
})

const prettifyLevel = (level: string) => {
  if (level.length != 2 || !level[0] || !level[1]) {
    return level
  }

  if (level[1] === "0") {
    return level[0]
  }

  return `${level[0]}.${level[1]}`
}

export const AudioMetadata = z.object({
  type: z.literal("audio").default("audio"),
  bitRate: z.number().int().nonnegative(),
  codec: z.string(),
  profile: z.string(),
  channels: z.number().int().nonnegative(),
  sampleRate: z.number().int().nonnegative(),
})

export const MediaMetadata = z.union([VideoMetadata, AudioMetadata])

export type VideoMetadata = z.infer<typeof VideoMetadata>
export type AudioMetadata = z.infer<typeof AudioMetadata>
export type MediaMetadata = VideoMetadata | AudioMetadata

export const isVideoMetadata = (metadata: MediaMetadata): metadata is VideoMetadata => metadata.type === "video"
export const isAudioMetadata = (metadata: MediaMetadata): metadata is AudioMetadata => metadata.type === "audio"

export const getVideoMetadata = (metadata: MediaMetadata[]): VideoMetadata[] =>
  metadata.filter((m): m is VideoMetadata => isVideoMetadata(m))

export const getAudioMetadata = (metadata: MediaMetadata[]): AudioMetadata[] =>
  metadata.filter((m): m is AudioMetadata => isAudioMetadata(m))
