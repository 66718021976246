import { z } from "zod"

export const UpdateUserPassword = z
  .object({
    oldPassword: z.string(),
    newPassword: z.string().min(8).max(128),
    confirmNewPassword: z.string(),
  })
  .refine((data) => data.newPassword === data.confirmNewPassword, {
    path: ["confirmNewPassword"],
    message: "Passwords do not match",
  })

export type UpdateUserPassword = z.infer<typeof UpdateUserPassword>
