import { Link } from "@tanstack/react-router"
import { createColumnHelper } from "@tanstack/react-table"
import { Button, Copyable } from "@vindral/components"
import { ChannelItem } from "../../interfaces/Channel"
import { trpc } from "../../trpc"
import { MultiSelectorChannelCombobox } from "./MultiSelectChannelCombobox"
import { MultiSelector, MultiSelectorFilterTextField, MultiSelectorTable } from "./MultiSelector"

export interface ChannelMultiSelectorProps {
  organizationId?: string
  onSelect: (item: ChannelItem) => void
  onDeselect: (index: number) => void
  selected: { channelId: string }[]
  nothingSelectedText: string
}

export function ChannelMultiSelector(props: ChannelMultiSelectorProps) {
  const { organizationId, onSelect, onDeselect, selected, nothingSelectedText } = props

  const { data: organizationChannels = [] } = trpc.channel.findManyByOrganizationId.useQuery({
    organizationId,
  })

  const getChannelName = (channelId: string) => {
    const channel = organizationChannels.find((c) => c.channelId === channelId)
    return channel?.name ?? ""
  }
  const selectedWithChannelName = selected.map((c) => ({ ...c, channelName: getChannelName(c.channelId) }))

  const columnHelper = createColumnHelper<typeof selectedWithChannelName[0]>()
  const columns = [
    columnHelper.accessor("channelName", {
      header: () => "Name",
      cell: (info) => (
        <Link
          to="/channels/edit/$channelId/details"
          className="whitespace-nowrap font-semibold"
          params={{ channelId: info.row.original.channelId }}
          search={(search) => ({ ...search, tab: "general" })}
        >
          {info.getValue()}
        </Link>
      ),
      maxSize: 300,
    }),
    columnHelper.accessor("channelId", {
      header: () => "Channel ID",
      cell: (info) => (
        <Copyable text={info.getValue()}>
          <span className="truncate text-fg-subtle">{info.getValue()}</span>
        </Copyable>
      ),
      maxSize: 300,
    }),
    columnHelper.display({
      id: "actions",
      header: () => "",
      cell: (info) => (
        <div className="flex justify-end">
          <Button variant="danger" size="small" onClick={() => onDeselect(info.row.index)}>
            Remove
          </Button>
        </div>
      ),
      size: 1,
    }),
  ]

  const hasSelectedAtLeastOneItem = selected.length > 0

  return (
    <MultiSelector>
      <div className="flex justify-between gap-4 pb-4">
        <MultiSelectorChannelCombobox organizationId={organizationId} onSelect={onSelect} selected={selected} />
        <div className="shrink-0">
          <MultiSelectorFilterTextField />
        </div>
      </div>
      {hasSelectedAtLeastOneItem ? (
        <MultiSelectorTable data={selectedWithChannelName} getRowId={(row) => row.channelId} columns={columns} />
      ) : (
        <div className="flex flex-col gap-2 py-4">{nothingSelectedText}</div>
      )}
    </MultiSelector>
  )
}
