import { z } from "zod"
import { Order } from "../order"

export const HyperlocalEdgeFindManyParameters = z
  .object({
    organizationId: z.string(),
  })
  .merge(Order)

export const HyperlocalEdgeItem = z.object({
  publicId: z.string(),
  url: z.string(),
  ip: z.string(),
  enabled: z.boolean(),
})
