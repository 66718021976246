import { PureAbility } from "@casl/ability"
import { createPrismaAbility, PrismaQuery } from "@casl/prisma"
import { Action } from "@core-services/data-types"
import { AppSubjects, OrganizationFindOne } from "@core-services/portal"
import { useQueryClient } from "@tanstack/react-query"
import { Outlet, Route, useNavigate, useSearch } from "@tanstack/react-router"
import { useCallback, useMemo } from "react"
import { z } from "zod"
import { AbilityContext, checkIfSuperUser } from "../acl"
import useUpdateLastLogin from "../hooks/useUpdateLastLogin"
import { useLogout } from "../http/mutations"
import { router } from "../router"
import { NavMenu } from "../templates/NavMenu"
import { trpc } from "../trpc"
import { rootRoute } from "./root"

const OnboardedParams = z.object({
  organizationId: z.string().uuid().optional(),
  inviteToken: z.string().optional(),
})

export const onboardedRoute = new Route({
  id: "onboarded",
  getParentRoute: () => rootRoute,
  component: Index,
  validateSearch: OnboardedParams,
  preSearchFilters: [(search) => search],
  onLoad: async ({ context: { trpcContext }, search }) => {
    const invites = await trpcContext.auth.getInvites.ensureData()

    if (invites.length > 0) {
      void router.navigate({
        to: "/invitations",
      })
      return
    }

    const profile = await trpcContext.user.profile.ensureData()
    const isSuperUser = checkIfSuperUser({ userProfile: profile })
    const organization = profile.accessPermissions[0]?.organization

    void trpcContext.support.isFreshdeskUser.prefetch(
      { email: profile.email },
      { staleTime: 60_000 * 60, cacheTime: 60_000 * 60 }
    )

    if (search.organizationId) {
      await trpcContext.organization.findOne.ensureData({ publicId: search.organizationId })
    } else if (!isSuperUser) {
      if (organization) {
        await trpcContext.organization.findOne.ensureData({ publicId: organization.publicId })
        void router.navigate({
          to: ".",
          search: (prev) => ({ ...prev, organizationId: organization.publicId, inviteToken: undefined }),
          replace: true,
        })
      } else {
        void router.navigate({
          to: "/register-organization",
          search: (prev) => ({ ...prev, inviteToken: undefined }),
        })
      }
    }
  },
})

function Index() {
  useUpdateLastLogin()
  const navigate = useNavigate()
  const { organizationId } = useSearch({ from: onboardedRoute.id })
  const { data: organization } = trpc.organization.findOne.useQuery(
    { publicId: organizationId ?? "" },
    { enabled: !!organizationId, useErrorBoundary: true }
  )

  const { data: profile } = trpc.user.profile.useQuery(undefined, { suspense: true })

  if (!profile) {
    throw new Error("Missing user profile")
  }

  const selectOrganization = useCallback(
    (organization: OrganizationFindOne | null) => {
      void navigate({
        to: "./",
        search: (prev) => ({
          ...prev,
          ...{
            organizationId: organization?.publicId,
            channelView: undefined,
            channelGroupView: undefined,
            transcodingProfileView: undefined,
          },
        }),
      })
    },
    [navigate]
  )

  const ability = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
    () => createPrismaAbility<PureAbility<[Action, AppSubjects], PrismaQuery>>(profile?.rules as any),
    [profile]
  )

  const queryClient = useQueryClient()
  const logoutMutation = useLogout()

  const handleLogOut = async () => {
    await logoutMutation.mutateAsync(undefined, {
      onSuccess: () => {
        queryClient.removeQueries()
        void navigate({ to: "/auth/login" })
      },
    })
  }

  return (
    <AbilityContext.Provider value={ability}>
      <div className="flex max-h-screen">
        <div className="sticky inset-y-0 h-screen w-[240px] min-w-0 shrink-0 overflow-y-auto border-r border-divider">
          <NavMenu
            ability={ability}
            profile={profile}
            selectedOrganization={organization}
            onSelectOrganization={selectOrganization}
            onLogOut={handleLogOut}
          />
        </div>

        <div className="h-screen w-full overflow-y-hidden">
          <Outlet />
        </div>
      </div>
    </AbilityContext.Provider>
  )
}
