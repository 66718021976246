import { Tooltip, TooltipContent, TooltipPortal, TooltipProvider, TooltipTrigger } from "@vindral/components"
import DottedMapWithoutCountries, { DottedMapWithoutCountriesLib } from "dotted-map/without-countries"
import { isDefined } from "../../utils/is-defined"
import countriesData from "./countriesData"
import defaultMap from "./map.json"

type WorldMapProps = {
  maxRadius: number
  minRadius: number
  pins: WorldMapPin[]
  mapInput?: DottedMapWithoutCountriesLib.Map
  pinColor?: string
}

export interface WorldMapPin {
  lat: number
  lng: number
  value: number
  tooltip: JSX.Element
}

export const generateMapPins = (
  pinData: { value: number; countryCode: string; country: string }[],
  valueDescription = ""
): WorldMapPin[] => {
  const getCountryData = (countryCode: string) => countriesData.find((data) => data.code === countryCode)

  return pinData
    .filter(({ value }) => value > 0)
    .map((pin) => {
      const countryData = getCountryData(pin.countryCode)
      if (!countryData) {
        return
      }

      return {
        value: pin.value,
        name: pin.country,
        lng: countryData.longitude,
        lat: countryData.latitude,
        tooltip: (
          <div>
            <div className="font-medium">{pin.country}</div>
            <div className="text-fg-subtle">{`${pin.value} ${valueDescription}`}</div>
          </div>
        ),
      }
    })
    .filter(isDefined)
}

export const WorldMap = ({ mapInput, maxRadius, minRadius, pinColor, pins }: WorldMapProps) => {
  const map = new DottedMapWithoutCountries({
    // eslint-disable-next-line total-functions/no-unsafe-type-assertion
    map: mapInput || (JSON.parse(defaultMap) as unknown as DottedMapWithoutCountriesLib.Map),
  })
  const values = pins.map((p) => p.value)
  const maxVal = Math.max(...values)
  const minVal = Math.min(...values)
  const valueDiff = maxVal - minVal
  const radiusDiff = maxRadius - minRadius
  const getRadius =
    valueDiff > 0
      ? (value: number) => ((value - minVal) / valueDiff) * radiusDiff + minRadius
      : () => (maxRadius + minRadius) / 2

  pins
    .sort((pinA, pinB) => pinB.value - pinA.value) // Sort to draw small pins on top of bigger ones
    .forEach((pin) => {
      map.addPin({
        lat: pin.lat,
        lng: pin.lng,
        svgOptions: { color: pinColor || "rgba(84, 156, 224, 0.8)", radius: getRadius(pin.value) },
        data: pin.tooltip,
      })
    })

  const svgMap = map.getSVG({
    radius: 0.22,
    shape: "circle",
    backgroundColor: "transparent",
  })

  const points = map
    .getPoints()
    .filter((f) => !!f.data)
    .map((f) => ({ ...f, x: f.x / 194, y: f.y / 100 }))

  return (
    <div className="relative">
      <div className="fill-gray-8 opacity-60" dangerouslySetInnerHTML={{ __html: svgMap }} />

      <div className="absolute inset-0">
        {points.map((point) => {
          return (
            <div
              key={`${point.lng}-${point.lat}`}
              className="absolute -translate-x-1/2 -translate-y-1/2"
              style={{
                top: `${point.y * 100}%`,
                left: `${point.x * 100}%`,
                height: `${(point.svgOptions?.radius || minRadius) * 1.94}%`,
                width: `${point.svgOptions?.radius || minRadius}%`,
              }}
            >
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger
                    style={{
                      height: `100%`,
                      width: `100%`,
                      display: "block",
                    }}
                  ></TooltipTrigger>
                  <TooltipPortal>
                    <TooltipContent side="right">{point.data}</TooltipContent>
                  </TooltipPortal>
                </Tooltip>
              </TooltipProvider>
            </div>
          )
        })}
      </div>
    </div>
  )
}
