import { ApiKeyScope } from "@core-services/data-types"
import { z } from "zod"

export const CreateApiKey = z.object({
  name: z
    .string()
    .min(2, { message: "name must be longer than or equal to 2 characters" })
    .max(50, { message: "name cannot be longer than 50 characters" }),
  notes: z.string().max(500, { message: "notes cannot be longer than 500 characters" }),
  scope: z.nativeEnum(ApiKeyScope),
})

export type CreateApiKey = z.infer<typeof CreateApiKey>
