import { ApiKeyFindManyParameters } from "@core-services/portal"
import { Route, useNavigate, useSearch } from "@tanstack/react-router"
import { PaginationState, SortingState, VisibilityState } from "@tanstack/react-table"
import { useToast } from "@vindral/components"
import { useEffect, useState } from "react"
import { useAsyncFn, useLocalStorage } from "react-use"
import { z } from "zod"
import { apiKeyIndexRoute } from "."
import { ApiKeyList } from "../../templates/api-key/ApiKeyList"
import { trpc } from "../../trpc"

const defaultColumns = {
  name: true,
  maskedKey: true,
  "user.name": true,
  createdAt: true,
}

const params = z.object({
  apiKeyListView: z
    .object({
      sorting: z.array(
        z.object({
          id: ApiKeyFindManyParameters.shape.orderBy,
          desc: z.boolean(),
        })
      ),
      pagination: z.object({
        pageIndex: z.number().nonnegative(),
        pageSize: z.number().nonnegative(),
      }),
      search: z.string().default(""),
    })
    .catch({
      sorting: [{ desc: false, id: "name" }],
      pagination: { pageIndex: 0, pageSize: 100 },
      search: "",
    }),
})

type SearchParams = ReturnType<typeof useSearch<typeof apiKeyListRoute["id"]>>

function toTrpcParams(params: SearchParams) {
  const { pagination, sorting, search } = params.apiKeyListView

  return {
    cursor: pagination.pageIndex * pagination.pageSize,
    take: pagination.pageSize,
    orderBy: sorting[0]?.id ?? "name",
    order: sorting[0]?.desc ? "desc" : "asc",
    search: search.length > 0 ? search : undefined,
    filter: params.organizationId ? { organization: { publicId: params.organizationId } } : undefined,
  } as const
}

export const apiKeyListRoute = new Route({
  path: "/",
  getParentRoute: () => apiKeyIndexRoute,
  validateSearch: params,
  onLoad: async ({ search, context: { trpcContext } }) => {
    const params = toTrpcParams(search)
    await trpcContext.apiKey.findMany.ensureData(params, { staleTime: 1000 })
  },
  preSearchFilters: [(search) => search],
  component: List,
})

function List() {
  const searchParams = useSearch({ from: apiKeyListRoute.id })
  const navigate = useNavigate({ from: apiKeyListRoute.id })
  const trpcParams = toTrpcParams(searchParams)
  const { toast } = useToast()

  const { data } = trpc.apiKey.findMany.useQuery(trpcParams, { keepPreviousData: true, staleTime: 1000 })
  const [sorting, setSorting] = useState<SortingState>(searchParams.apiKeyListView.sorting)
  const [pagination, setPagination] = useState<PaginationState>(searchParams.apiKeyListView.pagination)
  const [globalFilter, setGlobalFilter] = useState(searchParams.apiKeyListView.search)
  const [value, setValue, remove] = useLocalStorage<VisibilityState>("admin-api-key-list-columns", defaultColumns)
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(value ?? defaultColumns)
  const pageCount = Math.ceil((data?.total ?? 0) / pagination.pageSize)
  const [navigationState, doNavigate] = useAsyncFn(
    async (...args: Parameters<typeof navigate>) => {
      return navigate(...args)
    },
    [navigate]
  )

  if (globalFilter !== searchParams.apiKeyListView.search && pagination.pageIndex !== 0) {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }))
  }

  useEffect(() => {
    void doNavigate({
      from: apiKeyListRoute.id,
      to: "/api-keys",
      params: {},
      search: (prev) => {
        return { ...prev, apiKeyListView: { sorting, pagination, search: globalFilter } }
      },
    })
  }, [doNavigate, sorting, pagination, globalFilter])

  return (
    <ApiKeyList
      data={data?.items ?? []}
      pageCount={pageCount}
      manualPagination
      manualSorting
      manualFiltering
      isStaleData={navigationState.loading || globalFilter !== searchParams.apiKeyListView.search}
      state={{ sorting, pagination, globalFilter, columnVisibility }}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      onGlobalFilterChange={setGlobalFilter}
      onColumnVisibilityChange={setColumnVisibility}
      saveColumnVisibility={() => {
        setValue(columnVisibility)
        toast({ title: "Saved configuration" })
      }}
      resetColumnVisibilityToDefault={() => {
        remove()
        setColumnVisibility(defaultColumns)
        toast({ title: "Restored to default" })
      }}
    />
  )
}
