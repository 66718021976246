import { EventType } from "@core-services/data-types"
import { Expose } from "class-transformer"
import { ChannelDTO } from "../channel/channel.dto"

export class ChannelEventDTO {
  @Expose()
  id!: number

  @Expose()
  createdAt!: Date

  @Expose()
  type!: EventType

  @Expose()
  channel?: ChannelDTO
}
