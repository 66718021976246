import { Route, useNavigate, useSearch } from "@tanstack/react-router"
import { PaginationState, SortingState, VisibilityState } from "@tanstack/react-table"
import { useToast } from "@vindral/components"
import { useEffect, useState } from "react"
import { useAsyncFn, useLocalStorage } from "react-use"
import { z } from "zod"
import { edgeServerIndexRoute } from "."
import { EdgeServerList } from "../../templates/infrastructure/edge-servers/EdgeServerList"
import { trpc } from "../../trpc"

const defaultColumns = {
  hostname: true,
  datacenter: true,
  originChannels: true,
  relayedChannels: true,
  bitRateIn: true,
  bitRateOut: true,
  ingressCount: true,
  ingressLoad: true,
  ingressFull: false,
  egressLoad: true,
  egressFull: false,
  adapterSpeedMbps: true,
  publicWsUrl: true,
}

const params = z.object({
  edgeServerListView: z
    .object({
      sorting: z.array(
        z.object({
          id: z.enum([
            "hostname",
            "datacenter",
            "originChannels",
            "relayedChannels",
            "bitRateIn",
            "bitRateOut",
            "ingressCount",
            "ingressLoad",
            "ingressFull",
            "egressLoad",
            "egressFull",
            "adapterSpeedMbps",
            "publicWsUrl",
          ]),
          desc: z.boolean(),
        })
      ),
      pagination: z.object({
        pageIndex: z.number().nonnegative(),
        pageSize: z.number().nonnegative(),
      }),
      search: z.string().default(""),
    })
    .catch({
      sorting: [{ desc: false, id: "hostname" }],
      pagination: { pageIndex: 0, pageSize: 100 },
      search: "",
    }),
})

export const edgeServerListRoute = new Route({
  path: "/",
  getParentRoute: () => edgeServerIndexRoute,
  validateSearch: params,
  onLoad: async ({ context: { trpcContext } }) => {
    await trpcContext.infrastructure.getEdges.ensureData(undefined, { staleTime: 1000 })
  },
  preSearchFilters: [(search) => search],
  component: List,
})

function List() {
  const searchParams = useSearch({ from: edgeServerListRoute.id })
  const navigate = useNavigate({ from: edgeServerListRoute.id })
  const { toast } = useToast()

  const { data } = trpc.infrastructure.getEdges.useQuery(undefined, {
    staleTime: 1000,
  })
  const { data: demoChannel } = trpc.channel.findMany.useQuery({ search: "vindral_demo1_", orderBy: "channelId" })

  const [sorting, setSorting] = useState<SortingState>(searchParams.edgeServerListView.sorting)
  const [pagination, setPagination] = useState<PaginationState>(searchParams.edgeServerListView.pagination)
  const [globalFilter, setGlobalFilter] = useState(searchParams.edgeServerListView.search)
  const [value, setValue, remove] = useLocalStorage<VisibilityState>("edge-server-list-columns", defaultColumns)
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(value ?? defaultColumns)
  const pageCount = Math.ceil((data?.length ?? 0) / pagination.pageSize)
  const [navigationState, doNavigate] = useAsyncFn(
    async (...args: Parameters<typeof navigate>) => {
      return navigate(...args)
    },
    [navigate]
  )

  if (globalFilter !== searchParams.edgeServerListView.search && pagination.pageIndex !== 0) {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }))
  }

  useEffect(() => {
    void doNavigate({
      from: edgeServerListRoute.id,
      to: "/edge-servers",
      params: {},
      search: (prev) => {
        return { ...prev, edgeServerListView: { sorting, pagination, search: globalFilter } }
      },
    })
  }, [doNavigate, sorting, pagination, globalFilter])

  const filteredEdgeServers = globalFilter
    ? data?.filter(
        (e) =>
          e.hostname.toLocaleLowerCase().includes(globalFilter.toLowerCase()) ||
          e.datacenter.toLocaleLowerCase().includes(globalFilter.toLocaleLowerCase()) ||
          e.channels.map((c) => c.streamKey).filter((s) => s.includes(globalFilter.toLocaleLowerCase())).length != 0
      )
    : data

  const filteredAdapterSpeedMbps = filteredEdgeServers
    ? filteredEdgeServers
        .map((e) => e.adapterSpeedMbps)
        .filter((s) => s !== undefined)
        .reduce((acc, curr) => acc + curr, 0)
    : 0

  return (
    <EdgeServerList
      filteredAdapterSpeedMbps={filteredAdapterSpeedMbps}
      testChannelId={demoChannel?.items[0]?.channelId ?? ""}
      globalFilter={globalFilter}
      data={filteredEdgeServers ?? []}
      manualFiltering
      onPaginationChange={setPagination}
      pageCount={pageCount}
      isStaleData={navigationState.loading || globalFilter !== searchParams.edgeServerListView.search}
      state={{ pagination, sorting, globalFilter, columnVisibility }}
      onSortingChange={setSorting}
      onGlobalFilterChange={setGlobalFilter}
      onColumnVisibilityChange={setColumnVisibility}
      saveColumnVisibility={() => {
        setValue(columnVisibility)
        toast({ title: "Saved configuration" })
      }}
      resetColumnVisibilityToDefault={() => {
        remove()
        setColumnVisibility(defaultColumns)
        toast({ title: "Restored to default" })
      }}
    />
  )
}
