import { AppAbility, OrganizationFindOne, UpdateOrganization } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { checkIfSuperUser } from "../../../acl"
import { FormSection } from "../../../atoms/FormSection"
import { SaveButton } from "../../../atoms/SaveButton"
import { SaveSection } from "../../../atoms/SaveSection"
import { RouteBlockDialog } from "../../../molecules/RouteBlockDialog"
import { ClientOverridesForm } from "../../../organisms/ClientOverridesForm"

const resolver = zodResolver(UpdateOrganization)

export interface OrganizationEditClientOverridesProps {
  organization: OrganizationFindOne
  ability: AppAbility
  onUpdate: (organization: UpdateOrganization) => Promise<void>
}

export function OrganizationEditClientOverrides({
  organization,
  ability,
  onUpdate,
}: OrganizationEditClientOverridesProps) {
  const isSuperUser = checkIfSuperUser({ ability })
  const form = useForm<UpdateOrganization>({
    resolver,
    mode: "onChange",
    defaultValues: {
      publicId: organization.publicId,
      clientOverrides: organization.clientOverrides,
    },
  })
  const {
    reset,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting, isSubmitSuccessful },
  } = form

  useEffect(() => {
    reset({
      publicId: organization.publicId,
      clientOverrides: organization.clientOverrides,
    })
  }, [reset, organization])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful, reset])

  const columnWidth = "w-full max-w-lg"

  return (
    <div className="py-4">
      <RouteBlockDialog condition={isDirty && !isSubmitting} />
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onUpdate)} className="flex flex-col gap-4">
          <FormSection
            width={columnWidth}
            title="Client overrides"
            description="Override any local client options connecting to channels that belong to this organization."
          >
            <ClientOverridesForm />
          </FormSection>

          {isSuperUser && (
            <SaveSection width={columnWidth}>
              <SaveButton text="Save" disabled={!isDirty || !isValid} />
            </SaveSection>
          )}
        </form>
      </FormProvider>
    </div>
  )
}
