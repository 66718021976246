import { COUNTRIES } from "@core-services/data-types"
import { useMemo, useState } from "react"
import { MultiSelectorCombobox } from "./MultiSelector"

export interface MultiSelectorCountryComboboxProps {
  onSelect: (item: { countryCode: string }) => void
  selected: { countryCode: string }[]
}

export function MultiSelectorCountryCombobox(props: MultiSelectorCountryComboboxProps) {
  const [search, onSearchChange] = useState("")
  const selected = useMemo(() => new Set(props.selected.map((s) => s.countryCode)), [props.selected])

  return (
    <MultiSelectorCombobox
      idField="code"
      selected={selected}
      options={[
        { id: "sites", title: "sites", items: COUNTRIES.filter((f) => f.name.toLocaleLowerCase().includes(search)) },
      ]}
      onSelect={(item) => props.onSelect({ countryCode: item.code })}
      renderItem={(item) => <div>{item.name}</div>}
      search={search}
      onSearchChange={(search) => onSearchChange(search.toLocaleLowerCase())}
    >
      Add country
    </MultiSelectorCombobox>
  )
}
