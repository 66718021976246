import { Indicator, Root } from "@radix-ui/react-progress"
import { VariantProps, cva } from "class-variance-authority"

const defaultVariants = {
  color: "blue",
} as const

const indicator = cva("h-full w-full transition-transform duration-500", {
  variants: {
    color: {
      blue: "bg-solid-primary",
      neutral: "bg-solid-hover",
    },
  },
  defaultVariants,
})

export function ProgressIndicator({ progress, color }: { progress: number } & VariantProps<typeof indicator>) {
  return <Indicator className={indicator({ color })} style={{ transform: `translateX(-${100 - progress}%)` }} />
}

export interface ProgressPropsBase {
  progress: number | ({ percent: number } & VariantProps<typeof indicator>)[]
}

export type ProgressProps = ProgressPropsBase & VariantProps<typeof indicator>

export function Progress({ progress, color }: ProgressProps) {
  return (
    <Root className="relative h-1.5 w-full overflow-hidden rounded-full bg-component" value={66}>
      {typeof progress === "number" ? (
        <ProgressIndicator color={color} progress={progress} />
      ) : (
        progress.map((p) => {
          return <ProgressIndicator progress={p.percent} color={p.color} />
        })
      )}
    </Root>
  )
}
