import * as RadixTooltip from "@radix-ui/react-tooltip"
import { ComponentProps, ElementRef, forwardRef } from "react"

export const TooltipContent = forwardRef<
  ElementRef<typeof RadixTooltip.Content>,
  ComponentProps<typeof RadixTooltip.Content>
>((props, ref) => {
  return (
    <RadixTooltip.Content
      ref={ref}
      {...props}
      className="z-40 rounded-sm border border-divider bg-component px-2 py-1 text-fg shadow-lg animate-in fade-in zoom-in-95 origin-radix-tooltip data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=closed]:zoom-out-95"
    >
      {props.children}
    </RadixTooltip.Content>
  )
})
TooltipContent.displayName = "TooltipContent"

export function Tooltip(props: ComponentProps<typeof RadixTooltip.Root>) {
  return <RadixTooltip.Root {...props}>{props.children}</RadixTooltip.Root>
}

export const TooltipProvider = RadixTooltip.Provider
export const TooltipTrigger = RadixTooltip.Trigger
export const TooltipPortal = RadixTooltip.Portal
