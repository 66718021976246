import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@vindral/components"

export interface LimitationModeSelect {
  value: string
  onValueChange: (value: string) => void
  readonly?: boolean
}

export function LimitationModeSelect({ value, onValueChange, readonly }: LimitationModeSelect) {
  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger size="medium" data-pw="selectLimit" disabled={readonly}>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="allow">Allow selected values</SelectItem>
        <SelectItem value="deny">Deny selected values</SelectItem>
        <SelectItem value="disabled">Disable limitations</SelectItem>
      </SelectContent>
    </Select>
  )
}
