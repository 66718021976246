import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { InteractiveTable } from "../../organisms/InteractiveTable"
import { WorldMap, generateMapPins } from "../../organisms/map/WorldMap"
import { trpc } from "../../trpc"
import { AnalyticsQueryArgs } from "./Analytics"

type ChannelTableItem = {
  country: string
  sessions: number
}

const TABLE_PAGE_SIZE = 10

const CountryTable = (props: {
  data: ChannelTableItem[]
  totalSessions: number
  isLoading: boolean
  loadingRows?: number
}): JSX.Element => {
  const { data, totalSessions, isLoading, loadingRows } = props

  const pageCount = Math.ceil((data.length ?? 0) / TABLE_PAGE_SIZE)
  const columnMeta = { style: { textAlign: "right" } }

  const formatOneFraction = (val: number) =>
    new Intl.NumberFormat("en", { notation: "compact", maximumFractionDigits: 1 }).format(val)

  const modifiedData = data
    .sort((a, b) => b.sessions - a.sessions)
    .map((item) => ({
      ...item,
      percent: (item.sessions * 100) / totalSessions,
    }))

  const columnHelper = createColumnHelper<ChannelTableItem & { percent: number }>()
  const columns = [
    columnHelper.accessor("country", {
      header: () => "Country",
      cell: (info) => <div className="font-medium">{info.getValue()}</div>,
      size: 450,
    }),
    columnHelper.accessor("sessions", {
      header: () => "Sessions",
      meta: columnMeta,
      cell: (info) => <div className="truncate text-right tabular-nums">{formatOneFraction(info.getValue())}</div>,
      size: 100,
    }),
    columnHelper.accessor("percent", {
      header: () => "Distribution",
      meta: columnMeta,
      cell: (info) => <div className="truncate text-right tabular-nums">{formatOneFraction(info.getValue())}%</div>,
      size: 100,
    }),
  ]

  const table = useReactTable({
    data: modifiedData,
    pageCount,
    autoResetPageIndex: false,
    manualFiltering: true,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })
  return modifiedData.length ? (
    <InteractiveTable table={table} loadingProps={{ isLoading, numRows: loadingRows }} includePaginationFooter />
  ) : (
    <></>
  )
}

export const Geographic = (props: AnalyticsQueryArgs): JSX.Element => {
  const { from, to } = props.time
  const lookback = to - from
  const { data: viewerSessionsByCountry = [], isLoading } = trpc.analytics.viewerSessionsByCountry.useQuery(
    { ...props, time: to, lookback },
    { keepPreviousData: true }
  )

  const loadingRows = props.country ? 1 : TABLE_PAGE_SIZE
  const showNoDataOverlay = !isLoading && viewerSessionsByCountry.length === 0
  const totalSessions = viewerSessionsByCountry.reduce((accumulator, item) => accumulator + item.sessions, 0)

  return (
    <div className="relative mt-4 grid gap-y-2">
      <h1 className="font-medium">Viewer sessions</h1>
      <div className={`relative ${isLoading ? "animate-pulse" : ""}`}>
        {showNoDataOverlay && (
          <div className="absolute z-10 flex h-full w-full items-center justify-center bg-blackA-2">
            <div className="pb-[15%] font-medium text-fg-subtle">No data</div>
          </div>
        )}
        <WorldMap
          {...{
            maxRadius: 5,
            minRadius: 1,
            pins: generateMapPins(
              viewerSessionsByCountry.map((a) => ({ ...a, value: a.sessions })),
              "sessions"
            ),
          }}
        />
      </div>
      <CountryTable
        data={viewerSessionsByCountry}
        totalSessions={totalSessions}
        isLoading={isLoading}
        loadingRows={loadingRows}
      />
    </div>
  )
}
