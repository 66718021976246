import type { Limitable } from "@core-services/data-types"
import { OrganizationType } from "@core-services/data-types"
import { IsEnum, IsNotEmptyObject, IsOptional, IsString } from "class-validator"
import { BaseDTO } from "../base.dto"
import { IsEntityName } from "../common"

export class CreateOrganizationDTO extends BaseDTO<CreateOrganizationDTO> {
  @IsEntityName()
  name!: string

  @IsEnum(OrganizationType)
  type!: OrganizationType

  @IsOptional()
  @IsString()
  authSecret?: string

  @IsOptional()
  @IsNotEmptyObject()
  siteLimitation?: Limitable = {
    mode: "deny",
    limited: [],
  }
}
