import { z } from "zod"

const Width = z
  .number({ description: "The video width in pixels" })
  .int()
  .min(32, "The width must be at least 32 pixels")
  .max(3840)
  .multipleOf(2)
  .positive()
const Height = z
  .number({ description: "The video height in pixels" })
  .int()
  .min(32, "The height must be at least 32 pixels")
  .max(3840)
  .multipleOf(2)
  .positive()
const ScaleFactor = z.number({ description: "The scale factor" }).min(0.1).max(1.0)
const ScaleFactorSize = z.object(
  { scaleFactor: ScaleFactor },
  { description: "Set size based on a scale factor on the incoming stream" }
)
const ExactSize = z.object({ width: Width, height: Height }, { description: "Set both width and height in pixels" })
const AspectRatio = z.object({ width: Width }, { description: "Set width and set height to match aspect ratio" })
const BitRate = z.number().int()

export const GopSizeMs = z.number().int()

export const VideoBitRate = BitRate.min(50000, "Bit rate must be 50 kbit/s or more").max(
  50000000,
  "Bit rate must be 50 mbit/s or less"
)

export const AudioBitRate = BitRate.min(8000, "Bit rate must be 8 kbit/s or more").max(
  500000,
  "Bit rate must be 500 kbit/s or less"
)

export const Size = z.union([ExactSize, AspectRatio, ScaleFactorSize])
export const VideoCodec = z.enum(["h264", "av1"])
export const AudioCodec = z.enum(["opus", "aac"])
export const ThumbnailCodec = z.enum(["mjpeg"])
export const FrameSkippingVideo = z.enum(["none", "half", "quarter", "frame_every_1_seconds", "frame_every_5_seconds"])
export const FrameSkippingThumbnail = z.enum(["frame_every_1_seconds", "frame_every_5_seconds"])

export const MatchSource = z.union([
  z.object({ streamId: z.number() }, { description: "Match stream by ID" }),
  z.object({ language: z.string() }, { description: "Match stream by language" }),
  z.object({ stream: z.enum(["best", "all"]) }, { description: "Match all or the best of the selected stream type" }),
])

export const BaseStream = z.object({ passthrough: z.boolean().optional(), matchSource: MatchSource })
export type BaseStream = z.infer<typeof BaseStream>

export type VideoSize = z.infer<typeof Size>
