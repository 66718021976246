import { PackagerItem } from "@core-services/portal"
import { IconAdjustmentsFilled } from "@tabler/icons-react"
import {
  TableOptions,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import {
  Button,
  Chip,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSwitchItem,
  DropdownMenuTrigger,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from "@vindral/components"
import ProgressWithLabel from "../atoms/ProgressWithLabel"
import { DebouncedTextField } from "../molecules/DebouncedTextField"
import { InteractiveTable } from "./InteractiveTable"
import { NoResultsFound } from "./NoResultsFound"

function columnNames(id: string) {
  switch (id) {
    case "nodeHostname":
      return "Hostname"
    case "status":
      return "Status"
    case "hardwareAcceleration":
      return "Hardware"
    case "features":
      return "Features"
    case "packagerCount":
      return "Packager Count"
    case "cpuAllocatedTotal":
      return "Allocated"
  }

  return id
}

const getAllocatedPercent = (cpuAllocatedTotal: number, cpuAvailableTotal: number) =>
  ((cpuAllocatedTotal * 1.0) / cpuAvailableTotal) * 100

const AllocationsCell = ({ cpuAvailableTotal, cpuAllocatedTotal }: PackagerItem) => {
  const load = Math.round(getAllocatedPercent(cpuAllocatedTotal, cpuAvailableTotal))
  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger style={{ width: "100%" }}>
          <ProgressWithLabel progress={load} label={`${load}%`} />
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent side="top">
            <span className="text-fg-subtle">
              {cpuAllocatedTotal} / {cpuAvailableTotal} MHz
            </span>
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  )
}

const PackagerCountCell = ({
  packagerCount,
  packagers,
  highlightStream,
}: PackagerItem & { highlightStream?: string }) => {
  const getStream = (streamKey: string, cpuAllocated: number, highlightStream?: string) => {
    const sx: React.CSSProperties = { textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }
    const text = `${streamKey} ${cpuAllocated} MHz`

    if (!highlightStream) {
      return (
        <div className="text-fg-subtle" style={sx}>
          {text}
        </div>
      )
    }

    if (!streamKey.includes(highlightStream)) {
      return (
        <div className="text-fg-subtle" style={{ ...sx }}>
          {text}
        </div>
      )
    }

    return <div style={sx}>{text}</div>
  }
  const StreamKeyList = () => (
    <>
      {packagers.map((p) => (
        <div key={p.streamKey}>{getStream(p.streamKey, p.cpuAllocated, highlightStream)}</div>
      ))}
    </>
  )
  return packagerCount > 0 ? (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger>
          <div className="text-fg-subtle">{packagerCount}</div>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent side="top">
            <StreamKeyList />
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  ) : (
    <div className="text-fg-subtle">{packagerCount}</div>
  )
}

const ChipCell = ({ id, values }: { id: string; values: string[] }) => {
  return (
    <>
      {values.map((v) => (
        <Chip key={`${id}-${v}`} color="blue">
          {v}
        </Chip>
      ))}
    </>
  )
}

export type UsePackagerServerTableProps = Omit<
  TableOptions<PackagerItem>,
  "columns" | "getCoreRowModel" | "getSortedRowModel" | "getPaginationRowModel"
> & {
  globalFilter?: string
}

export function usePackagerServerReactTable(options: UsePackagerServerTableProps) {
  const { globalFilter } = options

  const columnHelper = createColumnHelper<PackagerItem>()
  const columns = [
    columnHelper.accessor("nodeHostname", {
      header: () => "Hostname",
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor("status", {
      header: () => "Status",
      cell: (info) => <Chip color={getColor(info.getValue())}>{<span>{info.getValue()}</span>}</Chip>,
    }),
    columnHelper.accessor("hardwareAcceleration", {
      header: () => "Hardware",
      cell: (info) => (
        <div className="flex gap-1">
          <ChipCell id={info.row.original.id} values={info.getValue()} />
        </div>
      ),
    }),
    columnHelper.accessor("features", {
      header: () => "Features",
      cell: (info) => (
        <div className="flex gap-1">
          <ChipCell id={info.row.original.id} values={info.getValue()} />
        </div>
      ),
    }),
    columnHelper.accessor("packagerCount", {
      header: () => "Packager Count",
      meta: { style: { textAlign: "center" } },
      cell: (info) => <PackagerCountCell {...info.row.original} highlightStream={globalFilter} />,
    }),
    columnHelper.accessor("cpuAllocatedTotal", {
      // Need an accessor for sorting, cannot use columnHelper.display
      header: () => "Allocated",
      sortingFn: (v1, v2) => {
        const v1Percent = getAllocatedPercent(v1.original.cpuAllocatedTotal, v1.original.cpuAvailableTotal)
        const v2Percent = getAllocatedPercent(v2.original.cpuAllocatedTotal, v2.original.cpuAvailableTotal)

        return v1Percent - v2Percent
      },
      cell: (info) => <AllocationsCell {...info.row.original} />,
    }),
  ]

  return useReactTable({
    ...options,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowId: (row) => row.nodeHostname,
    autoResetPageIndex: false,
  })
}

export type PackagerServerTableProps = Omit<
  TableOptions<PackagerItem>,
  "columns" | "getCoreRowModel" | "getSortedRowModel" | "getPaginationRowModel"
> & {
  saveColumnVisibility: () => void
  resetColumnVisibilityToDefault: () => void
  table: ReturnType<typeof usePackagerServerReactTable>
}

export function PackagerServerTable(options: PackagerServerTableProps) {
  const { table } = options

  // If there is a filter AND no rows - that means we should show "no results".
  // This is different from zero rows and no filter - in that case we should show
  // a call to action.
  const noResults = table.getRowModel().rows.length === 0 && !!table.getState().globalFilter

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-end gap-2">
        <div>
          <DebouncedTextField
            // eslint-disable-next-line total-functions/no-unsafe-type-assertion
            initialValue={table.getState().globalFilter as string}
            onValueChange={table.setGlobalFilter}
            debounceMs={200}
            placeholder="Filter..."
          />
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button>
              <IconAdjustmentsFilled size={16} />
              Configure
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent sideOffset={4} align="start">
            {table.getAllLeafColumns().map((column) => {
              return (
                <DropdownMenuSwitchItem
                  key={column.id}
                  checked={column.getIsVisible()}
                  onSelect={() => column.toggleVisibility()}
                >
                  {columnNames(column.id)}
                </DropdownMenuSwitchItem>
              )
            })}
            <DropdownMenuSeparator />
            <DropdownMenuItem onSelect={() => options.saveColumnVisibility()}>Save</DropdownMenuItem>
            <DropdownMenuItem onSelect={() => options.resetColumnVisibilityToDefault()}>
              Reset to default
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      {noResults ? <NoResultsFound /> : <InteractiveTable table={table} />}
    </div>
  )
}

const getColor = (status: string) => {
  switch (status) {
    case "ok":
      return "green"
    case "ineligible":
      return "orange"
    default:
      return "red"
  }
}
