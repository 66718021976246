import { Cross1Icon } from "@radix-ui/react-icons"
import { ReactNode } from "react"
import { Button, ButtonProps } from "../atoms/Button"
import { DialogClose, DialogContent } from "../molecules/Dialog"

export interface ConfirmDialogContentProps {
  title: ReactNode
  description: ReactNode
  onConfirm: () => Promise<void> | void
  onCancel?: () => Promise<void> | void
  dataPw?: string
  buttonVariant?: ButtonProps["variant"]
  disableConfirmButton?: boolean
}

export function ConfirmDialogContent({
  title,
  description,
  onConfirm,
  onCancel,
  buttonVariant = "danger",
  disableConfirmButton = false,
}: ConfirmDialogContentProps) {
  return (
    <DialogContent size="small">
      <form className="break-words">
        <div className="flex items-start justify-between gap-2 p-3">
          <span className="min-w-0 font-medium">{title}</span>
          <DialogClose className="" onClick={onCancel}>
            <Cross1Icon></Cross1Icon>
          </DialogClose>
        </div>
        <div className="overflow-y-auto">
          <div className="p-3">{description}</div>
        </div>
        <div className="flex justify-end gap-3 border-t border-divider p-3">
          <DialogClose asChild>
            <Button size="small" variant="tertiary" onClick={onCancel}>
              Cancel
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button
              data-pw="dialogConfirmButton"
              size="small"
              variant={buttonVariant}
              onClick={onConfirm}
              disabled={disableConfirmButton}
            >
              Confirm
            </Button>
          </DialogClose>
        </div>
      </form>
    </DialogContent>
  )
}
