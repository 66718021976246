import { IconAdjustmentsFilled } from "@tabler/icons-react"
import { Link } from "@tanstack/react-router"
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  TableOptions,
  useReactTable,
} from "@tanstack/react-table"
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSwitchItem,
  DropdownMenuTrigger,
} from "@vindral/components"
import { ReactNode } from "react"
import { UserItem } from "../interfaces/User"
import { DebouncedTextField } from "../molecules/DebouncedTextField"
import { FormattedDate } from "../molecules/FormattedDate"
import { InteractiveTable } from "./InteractiveTable"
import { NoResultsFound } from "./NoResultsFound"

function columnNames(id: string) {
  switch (id) {
    case "organizations":
      return "Organization"
    case "name":
      return "Name"
    case "email":
      return "Email"
    case "lastLoginAt":
      return "Last login"
    case "createdAt":
      return "Created"
  }

  return id
}

function UserLink({ publicId, children }: { publicId: string; children: ReactNode }) {
  return (
    <Link to="/users/edit/$id" className="whitespace-nowrap font-semibold" params={{ id: publicId }}>
      {children}
    </Link>
  )
}

export type UseAdminUserReactTableProps = Omit<
  TableOptions<UserItem>,
  "columns" | "getCoreRowModel" | "getSortedRowModel" | "getPaginationRowModel"
>

export function useAdminUserReactTable(options: UseAdminUserReactTableProps) {
  const columnHelper = createColumnHelper<UserItem>()
  const columns = [
    columnHelper.accessor("name", {
      header: () => "Name",
      cell: (info) => <UserLink publicId={info.row.original.publicId}>{info.getValue()}</UserLink>,
      maxSize: 300,
    }),
    columnHelper.accessor("email", {
      header: () => "Email",
      cell: (info) => <span className="text-fg-subtle">{info.getValue()}</span>,
      maxSize: 300,
    }),
    columnHelper.accessor("accessPermissions", {
      id: "organizations",
      header: () => "Organization",
      cell: (info) => {
        const organizations = info
          .getValue()
          .map((accessPermission) => accessPermission.organization.name)
          .join(", ")
        return <span className="text-fg-subtle">{organizations}</span>
      },
      enableSorting: false,
      maxSize: 300,
    }),
    columnHelper.accessor("lastLoginAt", {
      header: () => "Last login",
      meta: { style: { textAlign: "right" } },
      cell: (info) => {
        const lastLoginAt = info.getValue()
        if (!lastLoginAt) {
          return null
        }

        return (
          <span className="whitespace-nowrap text-fg-subtle">
            <FormattedDate date={new Date(lastLoginAt)} />
          </span>
        )
      },
      maxSize: 150,
    }),
    columnHelper.accessor("createdAt", {
      header: () => "Created At",
      meta: { style: { textAlign: "right" } },
      cell: (info) => (
        <span className="whitespace-nowrap text-fg-subtle">
          <FormattedDate date={new Date(info.getValue())} />
        </span>
      ),
      maxSize: 150,
    }),
  ]

  return useReactTable({
    ...options,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowId: (row) => row.publicId,
  })
}

export interface AdminUserTableTableProps {
  saveColumnVisibility: () => void
  resetColumnVisibilityToDefault: () => void
  table: ReturnType<typeof useAdminUserReactTable>
}

export function AdminUserTable(options: AdminUserTableTableProps) {
  const { table } = options

  // If there is a filter AND no rows - that means we should show "no results".
  // This is different from zero rows and no filter - in that case we should show
  // a call to action.
  const noResults = table.getRowModel().rows.length === 0 && !!table.getState().globalFilter

  return (
    <div className="flex min-h-0 grow flex-col">
      <div className="flex items-end gap-2 py-4">
        <div>
          <DebouncedTextField
            // eslint-disable-next-line total-functions/no-unsafe-type-assertion
            initialValue={table.getState().globalFilter as string}
            onValueChange={table.setGlobalFilter}
            debounceMs={200}
            placeholder="Filter..."
          />
        </div>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button>
              <IconAdjustmentsFilled size={16} />
              Configure
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent sideOffset={4} align="start">
            {table.getAllLeafColumns().map((column) => {
              return (
                <DropdownMenuSwitchItem
                  key={column.id}
                  checked={column.getIsVisible()}
                  onSelect={() => column.toggleVisibility()}
                >
                  {columnNames(column.id)}
                </DropdownMenuSwitchItem>
              )
            })}
            <DropdownMenuSeparator />
            <DropdownMenuItem onSelect={() => options.saveColumnVisibility()}>Save</DropdownMenuItem>
            <DropdownMenuItem onSelect={() => options.resetColumnVisibilityToDefault()}>
              Reset to default
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      {noResults ? <NoResultsFound /> : <InteractiveTable table={table} />}
    </div>
  )
}
