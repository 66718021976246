import { Action } from "@core-services/data-types"
import { signJWT } from "@core-services/utils"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Button,
  ConfirmDialogContent,
  Copyable,
  DateTimePicker,
  Dialog,
  DialogTrigger,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
} from "@vindral/components"
import { format, parse } from "date-fns"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { channelSubjectInOrganization } from "../../../../acl"
import { FormSection } from "../../../../atoms/FormSection"
import { embedPlayerUrl } from "../../../../utils/urls"
import { ChannelEditPlayoutProps } from "../ChannelEditPlayout"

const DATE_FORMAT = "yyyy-MM-dd HH:mm"
const formatDate = (date: Date | undefined) => (date ? format(date, DATE_FORMAT) : "")

function parseDate(dateString: string): Date | undefined {
  const date = parse(dateString, DATE_FORMAT, new Date())

  return isNaN(date.getTime()) ? undefined : date
}

const PlayerBuilderSchema = z.object({
  poster: z.string().optional(),
  startsAt: z
    .string()
    .optional()
    .refine((x) => (x ? !!parseDate(x) : true), "Expected format: yyyy-MM-dd HH:mm"),
  expiresAt: z
    .string()
    .optional()
    .refine((x) => (x ? !!parseDate(x) : true), "Expected format: yyyy-MM-dd HH:mm"),
  controls: z.boolean().optional(),
  muted: z.boolean().optional(),
})
type PlayerBuilderSchema = z.infer<typeof PlayerBuilderSchema>
const resolver = zodResolver(PlayerBuilderSchema)

export const PlayerBuilder = ({ channel, ability, onSave }: ChannelEditPlayoutProps) => {
  const {
    authRequired,
    organization: { authSecret },
  } = channel
  const subject = channelSubjectInOrganization(channel.organization.publicId)
  const [playerUrl, setPlayerUrl] = useState<string>("-")
  const {
    register,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm<PlayerBuilderSchema>({ resolver, mode: "onChange" })

  const values = watch()
  const authToken = useMemo(() => {
    if (authRequired && isValid && (values.expiresAt || values.startsAt)) {
      const startsAt = values.startsAt ? new Date(values.startsAt).getTime() / 1000 : undefined
      const expiresAt = values.expiresAt ? new Date(values.expiresAt).getTime() / 1000 : undefined

      return signJWT(authSecret, { channelId: channel.channelId }, startsAt, expiresAt)
    }
    return channel.authToken
  }, [authRequired, authSecret, channel.authToken, channel.channelId, isValid, values.expiresAt, values.startsAt])

  useEffect(() => {
    if (!isValid) {
      return
    }

    const { poster, muted, controls = true } = values
    const playerUrl = embedPlayerUrl({
      channelId: channel.channelId,
      poster,
      authToken,
      muted,
      controlsDisabled: !controls,
    })
    setPlayerUrl(playerUrl)
  }, [authSecret, channel, isValid, values, authToken])

  const onToggleAuthentication = useCallback(async () => {
    await onSave({ channelId: channel.channelId, authRequired: !authRequired })
    // Reset values if authRequired is disabled
    if (authRequired) {
      setValue("startsAt", undefined)
      setValue("expiresAt", undefined)
    }
  }, [authRequired, channel.channelId, onSave, setValue])

  return (
    <div className="flex flex-col gap-4">
      <h1 className="mb-1 text-xl font-medium">Player link builder</h1>
      <FormSection
        title="Player Link"
        description={<p>Player builder allows you to create a custom player link for your channel.</p>}
      >
        <div className="flex flex-col gap-4">
          <div className="text-xs font-medium uppercase tracking-wider text-fg-subtle">Player link</div>
          <Copyable text={playerUrl}>
            <code className="min-w-0 break-words font-mono text-xs">{playerUrl}</code>
          </Copyable>
        </div>
      </FormSection>
      <FormSection title="Player options" description={<p>Personalize your player link by setting options.</p>}>
        <div className="flex flex-col gap-4">
          <div className="text-xs font-medium uppercase tracking-wider text-fg-subtle">Player options</div>
          <FormControl>
            <FormControlLabel error={errors.poster?.message}>Poster image</FormControlLabel>
            <TextField {...register("poster")} error={!!errors?.poster} placeholder="https://" />
          </FormControl>
          <FormControl>
            <div className="flex">
              <Switch
                defaultChecked={true}
                onCheckedChange={(value) => {
                  setValue("controls", value, { shouldDirty: true })
                }}
                label="Controls"
              />
            </div>
            <div className="flex">
              <Switch
                defaultChecked={false}
                onCheckedChange={(value) => {
                  setValue("muted", value, { shouldDirty: true })
                }}
                label="Muted"
              />
            </div>
          </FormControl>
        </div>
      </FormSection>
      <FormSection
        title="Authentication"
        description={
          <p>
            Set a time range for when the player link should be valid. This will require that authentication is enabled
            for your channel.
          </p>
        }
      >
        <div className="flex flex-col gap-4">
          <div className="text-xs font-medium uppercase tracking-wider text-fg-subtle">Authentication</div>
          <FormControl>
            <FormControlLabel error={errors.startsAt?.message}>From</FormControlLabel>
            <div className="flex gap-2">
              <div className="w-48">
                <TextField {...register("startsAt")} error={!!errors?.startsAt} disabled={!authRequired} />
              </div>
              <DateTimePicker
                selected={values.startsAt ? parseDate(values.startsAt) : undefined}
                onSelect={(date) => {
                  setValue("startsAt", formatDate(date), { shouldDirty: true, shouldValidate: true })
                }}
                disabled={!authRequired}
              />
              <div className="ml-auto">
                <AuthenticationButton
                  authRequired={authRequired}
                  onClick={onToggleAuthentication}
                  disabled={ability.cannot(Action.Update, subject, "authRequired")}
                />
              </div>
            </div>
          </FormControl>
          <FormControl>
            <FormControlLabel error={errors.expiresAt?.message}>To</FormControlLabel>
            <div className="flex gap-2">
              <div className="w-48">
                <TextField {...register("expiresAt")} error={!!errors?.expiresAt} disabled={!authRequired} />
              </div>
              <DateTimePicker
                selected={values.expiresAt ? parseDate(values.expiresAt) : undefined}
                onSelect={(date) => {
                  setValue("expiresAt", formatDate(date), { shouldDirty: true, shouldValidate: true })
                }}
                disabled={!authRequired}
                defaultTime="next_hour"
              />
            </div>
          </FormControl>
        </div>
      </FormSection>
    </div>
  )
}

const AuthenticationButton = (props: { authRequired: boolean; onClick: () => void; disabled: boolean }) => {
  const { disabled, onClick, authRequired } = props
  const title = authRequired ? "Disable Authentication" : "Enable Authentication"
  const description = authRequired
    ? "Are you sure you want to disable authentication?"
    : "Are you sure you want to enable authentication?"
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button disabled={disabled} variant="primary">
          {title}
        </Button>
      </DialogTrigger>
      <ConfirmDialogContent onConfirm={onClick} title={title} description={description} buttonVariant={"primary"} />
    </Dialog>
  )
}
