import { subject } from "@casl/ability"
import { Action } from "@core-services/data-types"
import { AppAbility, UpdateProfile } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { IconAlertTriangle } from "@tabler/icons-react"
import {
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  TextField,
} from "@vindral/components"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { FormSection } from "../../atoms/FormSection"
import { VindralTheme, useVindralTheme } from "../../contexts/theme"
import { UserProfile } from "./Profile"

const resolver = zodResolver(UpdateProfile)

interface EditProfileProps {
  profile: UserProfile
  ability: AppAbility
  updateUser: (value: UpdateProfile) => Promise<void>
}

export const EditProfile = (props: EditProfileProps): JSX.Element => {
  const { profile, ability, updateUser } = props
  const { savedTheme, setTheme } = useVindralTheme()

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isDirty, isValid, isSubmitSuccessful },
  } = useForm<UpdateProfile>({
    resolver,
    mode: "onChange",
    defaultValues: {
      name: profile.name,
      email: profile.email,
    },
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues())
    }
  }, [isSubmitSuccessful, reset, getValues])

  const onAppearanceChange = (value: Exclude<VindralTheme, undefined>) => {
    setTheme(value)
  }

  return (
    <div>
      <div className="py-4">
        <FormSection
          title="Personal Information"
          description="Your personal information is only visible to your teammates in the organizations that you are part of."
        >
          <form onSubmit={handleSubmit(updateUser)} className="flex flex-col gap-4">
            <div className="flex gap-4">
              <FormControl>
                <FormControlLabel error={errors.name?.message}>Name</FormControlLabel>
                <TextField
                  {...register("name")}
                  required
                  error={!!errors?.name}
                  disabled={ability.cannot("update", subject("User", profile), "name")}
                />
              </FormControl>
              <FormControl>
                <FormControlLabel error={errors.email?.message}>E-mail</FormControlLabel>
                <TextField {...register("email")} required error={!!errors.email} disabled />
              </FormControl>
            </div>
            {ability.can(Action.Update, subject("User", profile)) ? (
              <div>
                <Button disabled={!isDirty || !isValid} data-pw="saveButton" type="submit" variant="primary">
                  Save
                </Button>
              </div>
            ) : null}
          </form>
        </FormSection>
      </div>
      <div className="py-4">
        <FormSection title="Appearance" description="Customize the user interface appearance.">
          <Select value={savedTheme || "system"} onValueChange={onAppearanceChange}>
            <SelectTrigger size="medium">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="light">Light</SelectItem>
              <SelectItem value="dark">Dark</SelectItem>
              <SelectItem value="system">Use system settings</SelectItem>
            </SelectContent>
          </Select>
        </FormSection>
      </div>
      <div className="py-4">
        <FormSection
          title="Organizations"
          description="The organizations that you are a member of controls what you can access."
        >
          <form className="flex flex-col gap-4">
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadCell>Name</TableHeadCell>
                  <TableHeadCell>Permissions</TableHeadCell>
                  <TableHeadCell></TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {profile.accessPermissions.map(({ organization, role }) => {
                  return (
                    <TableRow key={organization.publicId}>
                      <TableCell>{organization.name}</TableCell>
                      <TableCell>
                        <span style={{ textTransform: "capitalize" }}>{role}</span>
                      </TableCell>
                      <TableCell>
                        {organization.suspended && (
                          <Chip color="red">
                            <IconAlertTriangle size={12} />
                            Suspended
                          </Chip>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </form>
        </FormSection>
      </div>
    </div>
  )
}
