import { SinkTypeEnum } from "@core-services/data-types"
import { z } from "zod"
import { Order } from "../order"

export const SinkFindManyParameters = z
  .object({
    channelId: z.string(),
    type: z.nativeEnum(SinkTypeEnum),
  })
  .merge(Order)

export const SinkItem = z.object({
  publicId: z.string(),
  url: z.string(),
  enabled: z.boolean(),
  type: z.nativeEnum(SinkTypeEnum),
})
