import { ReactNode, createContext, useContext, useMemo } from "react"
import { useHotkeys } from "react-hotkeys-hook"
import { useLocalStorage } from "react-use"

const htmlTag = document.documentElement

type PaletteMode = "light" | "dark"

interface VindralThemeContextProps {
  appliedTheme: PaletteMode
  savedTheme: VindralTheme
  setTheme: (variant: VindralTheme) => void
}

const VindralThemeContext = createContext<VindralThemeContextProps | undefined>(undefined)

export const useVindralTheme = () => {
  const context = useContext(VindralThemeContext)
  if (!context) {
    throw new Error("useVindralTheme must be used within a VindralThemeProvider")
  }
  return context
}

export type VindralTheme = PaletteMode | "system" | undefined

export const VindralThemeProvider = ({ children }: { children?: ReactNode }): JSX.Element => {
  const [savedVariant, setSavedVariant] = useLocalStorage<VindralTheme>("theme-preference")
  const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches

  const theme = useMemo(() => {
    // If the user hasn't specified a preference yet or if the user's preference is "system"
    // ask the browser for the preferred color scheme.
    let newVariant: PaletteMode = prefersDarkMode ? "dark" : "light"

    // If the user has specified light or dark, use that.
    if (savedVariant && savedVariant !== "system") {
      newVariant = savedVariant
    }

    // Set the "data-mode" attribute on the iFrame html tag
    htmlTag.setAttribute("class", newVariant)

    return newVariant
  }, [savedVariant, prefersDarkMode])

  useHotkeys(
    "ctrl+.",
    (event: KeyboardEvent) => {
      event.preventDefault()
      const newTheme = theme === "dark" ? "light" : "dark"
      setSavedVariant(newTheme)
    },
    [theme]
  )

  const contextValue: VindralThemeContextProps = {
    appliedTheme: theme,
    savedTheme: savedVariant,
    setTheme: setSavedVariant,
  }

  return <VindralThemeContext.Provider value={contextValue}>{children}</VindralThemeContext.Provider>
}
