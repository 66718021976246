import { SinkTypeEnum } from "@core-services/data-types"
import { z } from "zod"

export const UpdateSinks = z.object({
  channelId: z.string(),
  type: z.nativeEnum(SinkTypeEnum),
  sinks: z
    .array(
      z.object({
        url: z.string(),
        enabled: z.boolean(),
      })
    )
    .max(5),
})

export type UpdateSinks = z.infer<typeof UpdateSinks>
