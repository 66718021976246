import { useAbility } from "@casl/react"
import { Action } from "@core-services/data-types"
import { CreateOrganization } from "@core-services/portal"
import { Route, useNavigate } from "@tanstack/react-router"
import { toast } from "@vindral/components"
import { organizationIndexRoute } from "."
import { AbilityContext } from "../../acl"
import { OrganizationCreate } from "../../templates/organization/OrganizationCreate"
import { trpc } from "../../trpc"

function Page() {
  const ability = useAbility(AbilityContext)
  const navigate = useNavigate()
  const context = trpc.useContext()

  if (!ability.can(Action.Create, "Organization")) {
    return null
  }

  const createMutation = trpc.organization.create.useMutation({
    onSuccess: async (organization) => {
      const organizationPublicId = organization.publicId
      await context.organization.findMany.invalidate()
      context.organization.findOne.setData({ publicId: organizationPublicId }, organization)

      toast({ title: `Created organization ${organization.name}` })
      void navigate({
        to: "/organizations/edit/$publicId",
        params: { publicId: organizationPublicId },
        replace: true,
      })
    },
    onError: (error) => {
      toast({ title: "Failed to create organization!", description: error.message })
    },
  })

  const createOrganization = async (user: CreateOrganization) => {
    createMutation.reset()
    await createMutation.mutateAsync(user)
  }

  return <OrganizationCreate createOrganization={createOrganization} />
}

export const organizationCreateRoute = new Route({
  getParentRoute: () => organizationIndexRoute,
  preSearchFilters: [(search) => search],
  path: "create",
  component: Page,
  getContext: () => {
    return {
      pageTitle: "Create organization",
    }
  },
})
