export const LimitationMode = {
  Allow: "allow",
  Deny: "deny",
  Disabled: "disabled",
} as const

export type LimitationMode = typeof LimitationMode[keyof typeof LimitationMode]
export interface Limitable {
  mode: LimitationMode
  limited: string[] | number[]
}

export interface StringLimitable extends Limitable {
  limited: string[]
}

export interface NumberLimitable extends Limitable {
  limited: number[]
}

export const denyNothingLimitation: Limitable = {
  mode: "deny",
  limited: [],
}

export const denyNothingStringLimitation: StringLimitable = {
  mode: "deny",
  limited: [],
}

export const denyNothingNumberLimitation: NumberLimitable = {
  mode: "deny",
  limited: [],
}
