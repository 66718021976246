import { cva } from "class-variance-authority"
import { ReactNode } from "react"
import { Hyperlink } from "./Hyperlink"

const formSection = cva("flex w-full gap-8", {
  variants: {
    disabled: { true: "pointer-events-none opacity-50" },
  },
})

export interface FormSectionProps {
  title: string
  description: ReactNode
  docsLink?: string
  keyboardShortcut?: string
  children: ReactNode
  width?: string
  disabled?: boolean
}

export function FormSection({
  title,
  description,
  docsLink,
  keyboardShortcut,
  children,
  width = "w-96 max-[1280px]:max-w-xs",
  disabled = false,
}: FormSectionProps) {
  return (
    <div className={formSection({ disabled })}>
      <div className={`${width} shrink-0 grow-0`}>
        <h1 className="mb-1 font-semibold">{title}</h1>
        <div className="text-fg-subtle">{description}</div>
        {docsLink && <Hyperlink href={docsLink} text="Learn more" external={true} />}
        {keyboardShortcut && (
          <div className="mt-2 inline-block rounded border-2 border-divider px-1 py-0.5 font-mono">
            {keyboardShortcut}
          </div>
        )}
      </div>
      <div className="min-w-0 grow">{children}</div>
    </div>
  )
}
