import { formatDate, formatDateDistance, Tooltip, TooltipContent, TooltipTrigger } from "@vindral/components"
import { useMemo } from "react"

export interface FormattedDateProps {
  date: Date
}

export function FormattedDate(props: FormattedDateProps) {
  const date = useMemo(() => {
    return {
      formatted: formatDate(props.date),
      distance: formatDateDistance(props.date),
    }
  }, [props.date])

  return (
    <Tooltip>
      <TooltipTrigger asChild className="cursor-default">
        <div>{date.distance}</div>
      </TooltipTrigger>
      <TooltipContent>{date.formatted}</TooltipContent>
    </Tooltip>
  )
}
