import {
  Chip,
  ChipProps,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from "@vindral/components"
import { IngestPointRegionalItem } from "../../../interfaces/IngestPoints"

type IngestEndpointStatus = IngestPointRegionalItem["primaryEndpoints"][number]["status"]

const statusMap: {
  [key in IngestEndpointStatus]: ChipProps["color"]
} = {
  Online: "green",
  Offline: "red",
  Degraded: "orange",
  Disabled: "neutral",
}

export const StatusChip = ({ label, status }: { label: string; status: IngestEndpointStatus }) => (
  <TooltipProvider>
    <Tooltip delayDuration={0}>
      <TooltipTrigger>
        <Chip color={statusMap[status]}>{label}</Chip>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent side="right">{status}</TooltipContent>
      </TooltipPortal>
    </Tooltip>
  </TooltipProvider>
)
