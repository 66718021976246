import { subject } from "@casl/ability"
import { Action } from "@core-services/data-types"
import { AppAbility, UpdateTranscodingProfile } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { Link } from "@tanstack/react-router"
import {
  Button,
  ConfirmDialogContent,
  Dialog,
  DialogTrigger,
  FormControl,
  FormControlLabel,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  Switch,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  TextField,
} from "@vindral/components"
import { useForm } from "react-hook-form"
import { FormSection } from "../../atoms/FormSection"
import { Page, PageContainer, PageContent, PageFooter, PageHeader } from "../../atoms/Page"
import { SaveButton } from "../../atoms/SaveButton"
import {
  type TranscodingProfile,
  type TranscodingProfileAudioStream,
  type TranscodingProfileStream,
  type TranscodingProfileVideoStream,
} from "../../interfaces/TranscodingProfile"
import { Breadcrumbs } from "../../molecules/Breadcrumbs"
import { FormattedDate } from "../../molecules/FormattedDate"
import { Titled } from "../../molecules/Titled"
import { AudioRenditions } from "../../organisms/TranscodingProfileAudioRenditions"
import { TranscodingProfileRawConfigForm } from "../../organisms/TranscodingProfileRawConfigForm"
import { ThumbnailRenditions } from "../../organisms/TranscodingProfileThumbnailRenditions"
import { VideoRenditions } from "../../organisms/TranscodingProfileVideoRenditions"

const resolver = zodResolver(UpdateTranscodingProfile)

function isThumbnailRendition(rendition: { codec: string }) {
  return rendition.codec === "mjpeg"
}

function isVideoRendition(rendition: { codec: string }) {
  return !isThumbnailRendition(rendition)
}

function selectorText(matcher: TranscodingProfileStream["matchSource"]) {
  if ("stream" in matcher) {
    return matcher.stream === "best" ? "Match the best source stream" : "Match all source streams"
  } else if ("streamId" in matcher) {
    return "Match by stream ID"
  } else {
    return "Match by language"
  }
}

function selectorValue(matcher: TranscodingProfileStream["matchSource"]) {
  if ("stream" in matcher) {
    return matcher.stream
  } else if ("streamId" in matcher) {
    return "streamId"
  } else {
    return "language"
  }
}

function StreamSelector({ matcher }: { matcher: TranscodingProfileStream["matchSource"] }) {
  return (
    <Select disabled value={selectorValue(matcher)}>
      <SelectTrigger size="medium">{selectorText(matcher)}</SelectTrigger>
      <SelectContent align="start">
        <SelectItem value="best">{selectorText({ stream: "best" })}</SelectItem>
        <SelectItem value="all">{selectorText({ stream: "all" })}</SelectItem>
        <SelectItem value="streamId">{selectorText({ streamId: 123 })}</SelectItem>
        <SelectItem value="language">{selectorText({ language: "swe" })}</SelectItem>
      </SelectContent>
    </Select>
  )
}

export function TranscodingProfileVideoStream({ stream }: { stream: TranscodingProfileVideoStream }) {
  const videoRenditions = stream.renditions.filter(isVideoRendition)
  const thumbnailRenditions = stream.renditions.filter(isThumbnailRendition)

  return (
    <div className="py-8">
      <FormSection
        title="Video Stream Matcher"
        description="A video stream matcher uses a condition to match against one or many source streams. The matched stream(s) is then used to transcode into different renditions and optionally passthrough as-is to clients."
      >
        <div className="flex flex-col gap-4">
          <div>
            <StreamSelector matcher={stream.matchSource} />
          </div>
          <div className="max-w-md">
            <label className="flex items-center justify-between pb-2">
              <div>
                <h2 className="font-medium">Passthrough</h2>
                <span className="text-fg-subtle">Let the incoming stream be passed through to clients.</span>
              </div>
              <Switch disabled label="" size="small" checked={stream.passthrough} />
            </label>
            <label className="flex items-center justify-between">
              <div>
                <h2 className="font-medium">GOP Alignment</h2>
                <span className="text-fg-subtle">Align the GOP based on the passthrough stream.</span>
              </div>

              <Switch disabled label="" size="small" checked={stream.gopAlignment} />
            </label>
          </div>
          <div>
            <h2 className="pb-2 font-medium">Transcoded Video Renditions</h2>

            <VideoRenditions renditions={videoRenditions} matchSourceGop={stream.gopAlignment} />
          </div>
          <div>
            <h2 className="pb-2 font-medium">Transcoded Thumbnail Renditions</h2>

            <ThumbnailRenditions renditions={thumbnailRenditions} />
          </div>
        </div>
      </FormSection>
    </div>
  )
}

export function TranscodingProfileAudioStream({ stream }: { stream: TranscodingProfileAudioStream }) {
  return (
    <div className="py-8">
      <FormSection
        title="Audio Stream Matcher"
        description="An audio stream matcher uses a condition to match against one or many source streams. The matched stream(s) is then used to transcode into different renditions and optionally passthrough as-is to clients."
      >
        <div className="flex flex-col gap-4">
          <div>
            <StreamSelector matcher={stream.matchSource} />
          </div>

          <div className="max-w-md">
            <label className="flex items-center justify-between">
              <div>
                <h2 className="font-medium">Passthrough</h2>
                <span className="text-fg-subtle">Let the incoming stream be passed through to clients.</span>
              </div>
              <Switch disabled label="" size="small" checked={stream.passthrough} />
            </label>
          </div>
          <div>
            <h2 className="pb-2 font-medium">Transcoded Audio Renditions</h2>

            <AudioRenditions renditions={stream.renditions} />
          </div>
        </div>
      </FormSection>
    </div>
  )
}

export interface TranscodingProfileEditProps {
  transcodingProfile: TranscodingProfile
  ability: AppAbility
  onUpdate: (transcodingProfile: UpdateTranscodingProfile) => Promise<void>
  onDelete: (transcodingProfileId: string) => Promise<void>
}

export function TranscodingProfileEdit({
  transcodingProfile,
  onUpdate,
  onDelete,
  ability,
}: TranscodingProfileEditProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver,
    mode: "onChange",
    defaultValues: {
      name: transcodingProfile.name,
      notes: transcodingProfile.notes,
    },
  })

  return (
    <Page>
      <PageContent>
        <Tabs defaultValue="general">
          <PageHeader>
            <div className="flex h-16 items-center justify-between">
              <Breadcrumbs />
            </div>
            <TabsList border="none">
              <TabsTrigger value="general">General</TabsTrigger>
              {transcodingProfile.rawConfig ? <TabsTrigger value="edit-raw-config">Edit Raw Config</TabsTrigger> : null}
            </TabsList>
          </PageHeader>
          <PageContainer>
            <TabsContent asChild value="general">
              <div className="divide-divider">
                <div className="py-8">
                  <FormSection
                    title="General Information"
                    description="Information about your profile that will help you recognize it when picking a profile for your channels."
                    docsLink="https://docs.vindral.com/broadcast/transcoding"
                  >
                    <form onSubmit={handleSubmit(onUpdate)} className="flex max-w-md flex-col gap-4">
                      <FormControl>
                        <FormControlLabel required error={errors.name?.message}>
                          Name
                        </FormControlLabel>
                        <TextField
                          {...register("name")}
                          error={!!errors?.name}
                          disabled={ability.cannot("update", subject("TranscodingProfile", transcodingProfile), "name")}
                        />
                      </FormControl>
                      <FormControl>
                        <FormControlLabel error={errors.notes?.message}>Notes</FormControlLabel>
                        <TextField
                          {...register("notes")}
                          multiline={true}
                          minRows={3}
                          error={!!errors.notes}
                          disabled={ability.cannot(
                            "update",
                            subject("TranscodingProfile", transcodingProfile),
                            "notes"
                          )}
                        />
                      </FormControl>
                      {ability.can(Action.Update, subject("TranscodingProfile", transcodingProfile)) ? (
                        <div>
                          <SaveButton text="Save" disabled={!isDirty || !isValid} />
                        </div>
                      ) : null}
                    </form>
                  </FormSection>
                </div>
                {transcodingProfile.config.streams.map((stream) => {
                  switch (stream.type) {
                    case "video":
                      return <TranscodingProfileVideoStream key={stream.id} stream={stream} />
                    case "audio":
                      return <TranscodingProfileAudioStream key={stream.id} stream={stream} />
                  }
                })}
              </div>
            </TabsContent>
            {transcodingProfile.rawConfig ? (
              <TabsContent value="edit-raw-config">
                <div className="py-8 ">
                  <TranscodingProfileRawConfigForm rawConfig={transcodingProfile.rawConfig} onSave={onUpdate} />
                </div>
              </TabsContent>
            ) : null}
          </PageContainer>
        </Tabs>
      </PageContent>

      <PageFooter>
        <div className="flex h-16 items-center justify-between">
          <div className="flex gap-8">
            <Titled title="Organization">
              <Link to="/organizations/edit/$publicId" params={{ publicId: transcodingProfile.organization.publicId }}>
                {transcodingProfile.organization.name}
              </Link>
            </Titled>
            <Titled title="Created">
              <FormattedDate date={new Date(transcodingProfile.createdAt)} />
            </Titled>
            {typeof transcodingProfile.updatedAt !== "undefined" && (
              <Titled title="Updated">
                <FormattedDate date={new Date(transcodingProfile.updatedAt)} />
              </Titled>
            )}
          </div>
          {ability.can(Action.Delete, subject("TranscodingProfile", transcodingProfile)) && (
            <Dialog>
              <DialogTrigger asChild>
                <Button variant="danger">Delete</Button>
              </DialogTrigger>
              <ConfirmDialogContent
                onConfirm={() => onDelete(transcodingProfile.publicId)}
                title={`Confirm deletion of ${transcodingProfile.name}`}
                description={
                  <>
                    Please confirm that you are absolutely sure that you want to delete
                    <span className="font-medium"> {transcodingProfile.name}</span>.
                  </>
                }
              />
            </Dialog>
          )}
        </div>
      </PageFooter>
    </Page>
  )
}
