import { Button } from "@vindral/components"
import { FormSection } from "../../../../atoms/FormSection"
import { Hyperlink } from "../../../../atoms/Hyperlink"
import { webrtcIngestUrl } from "../../../../utils/urls"

export const WebRtc = (props: { streamKey: string }) => {
  const { streamKey } = props

  return (
    <>
      <h1 className="mb-1 text-xl font-medium">WebRTC</h1>
      <FormSection
        title="Ingest"
        description={
          <p>
            Can be used from any browser and does not require any additional software or plugins. Use the turn-key ready
            QoS ingest client or create your own{" "}
            <Hyperlink
              href="https://docs.vindral.com/broadcast/webrtc-ingest/embeddable-sender/"
              text="custom integration"
            />
            .
          </p>
        }
      >
        <div className="flex flex-col gap-4">
          <div className="text-xs font-medium uppercase tracking-wider text-fg-subtle">WebRTC QoS Client</div>
          <a target="_blank" href={`${webrtcIngestUrl({ streamKey })}`}>
            <Button>Broadcast from your browser</Button>
          </a>
        </div>
      </FormSection>
    </>
  )
}
