import { useAbility } from "@casl/react"
import { Action } from "@core-services/data-types"
import { UpdateTranscodingProfile } from "@core-services/portal"
import { Route, useNavigate, useParams } from "@tanstack/react-router"
import { toast } from "@vindral/components"
import { transcodingProfileIndexRoute } from "."
import { AbilityContext, pickPermittedFields, transcodingProfileSubjectInOrganization } from "../../acl"
import { TranscodingProfileEdit } from "../../templates/transcoding-profile/TranscodingProfileEdit"
import { trpc } from "../../trpc"

const trpcOptions = { staleTime: 1000 }

export const transcodingProfileEditRoute = new Route({
  getParentRoute: () => transcodingProfileIndexRoute,
  path: "edit/$transcodingProfileId",
  component: Index,
  onLoad: async ({ preload, context: { trpcContext }, params }) => {
    if (preload) {
      await trpcContext.transcodingProfile.findById.prefetch(params, trpcOptions)
    } else {
      await trpcContext.transcodingProfile.findById.fetch(params, trpcOptions)
    }
  },
  preSearchFilters: [(search) => search],
  getContext: ({ context, params }) => {
    const profile = context.trpcContext.transcodingProfile.findById.getData({
      transcodingProfileId: params.transcodingProfileId,
    })

    return {
      pageTitle: profile?.name ?? "Transcoding profile",
    }
  },
})

function Index() {
  const context = trpc.useContext()
  const navigate = useNavigate()
  const { transcodingProfileId } = useParams({ from: transcodingProfileEditRoute.id })
  const { data } = trpc.transcodingProfile.findById.useQuery({ transcodingProfileId }, trpcOptions)
  const ability = useAbility(AbilityContext)
  const updateMutation = trpc.transcodingProfile.update.useMutation({
    onSuccess: (transcodingProfile) => {
      void context.transcodingProfile.findMany.invalidate()
      context.transcodingProfile.findById.setData({ transcodingProfileId }, transcodingProfile)
      // The routes getContext does not get invalidated without navigate
      void navigate({ to: ".", replace: true })
      toast({ title: "Saved successfully!" })
    },
    onError: (error) => {
      toast({ title: "Failed to save!", description: error.message })
    },
  })
  const deleteMutation = trpc.transcodingProfile.delete.useMutation({
    onSuccess() {
      void context.transcodingProfile.findMany.invalidate()
      void navigate({ to: "/transcoding-profiles", replace: true })
    },
    onError: (error) => {
      toast({ title: "Failed to delete!", description: error.message })
    },
  })

  if (!data) {
    return null
  }

  const subject = transcodingProfileSubjectInOrganization(data.organization.publicId)

  const onUpdate = async (transcodingProfile: UpdateTranscodingProfile) => {
    updateMutation.reset()
    const permitted = pickPermittedFields(ability, Action.Update, subject, transcodingProfile)
    await updateMutation.mutateAsync({ ...permitted, transcodingProfileId })
  }

  const onDelete = async (transcodingProfileId: string) => {
    deleteMutation.reset()
    await deleteMutation.mutateAsync({ transcodingProfileId })
  }

  return <TranscodingProfileEdit transcodingProfile={data} onUpdate={onUpdate} onDelete={onDelete} ability={ability} />
}
