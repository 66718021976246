import { z } from "zod"

const errorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.too_big && issue.type === "string") {
    return { message: `Cannot be longer than ${issue.maximum} characters` }
  }

  if (issue.code === z.ZodIssueCode.too_small && issue.type === "string") {
    return { message: `Must be at least ${issue.minimum} character${issue.minimum > 1 ? "s" : ""}` }
  }

  return { message: ctx.defaultError }
}

export const setupCustomErrorMessages = () => {
  z.setErrorMap(errorMap)
}
