import { AppAbility } from "@common/access-control"
import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, toast } from "@vindral/components"
import { useState } from "react"
import { checkIfSuperUser } from "../../../../acl"
import { FormSection } from "../../../../atoms/FormSection"
import { LiveStatus } from "../../../../organisms/LiveStatus"
import { trpc } from "../../../../trpc"
import { playUrl } from "../../../../utils/urls"
import { ChannelFindById } from "../../ChannelEdit"

export interface TimedMetadataProps {
  channel: ChannelFindById
  ability: AppAbility
}

export const TimedMetadata = ({ channel, ability }: TimedMetadataProps) => {
  const isSuperUser = checkIfSuperUser({ ability })
  const [metadataSite, setMetadataSite] = useState<string>("global")

  const metadataMutation = trpc.channel.testMetadata.useMutation({
    onSuccess() {
      toast({ title: "Metadata sent" })
    },
    onError: (error) => {
      toast({ title: "Failed to send metadata!", description: error.message })
    },
  })
  const sendMetadata = async () => {
    await metadataMutation.mutateAsync({
      channelId: channel.channelId,
      datacenter: isSuperUser ? metadataSite : "global",
    })
  }

  const { data: mediaIngressSites } = trpc.infrastructure.getMediaIngressSites.useQuery(undefined, {
    enabled: isSuperUser,
  })

  return (
    <>
      <h1 className="mb-1 text-xl font-medium">Timed Metadata</h1>
      <FormSection
        title="Test metadata injection"
        description={
          <p>
            Inject timed metadata into your stream, sometimes called cue points or out-of-band data. This can be used to
            trigger events in your player.
          </p>
        }
        docsLink="https://docs.vindral.com/manage/timed-metadata/"
      >
        <div className="flex min-h-[32px] flex-nowrap pb-2">
          <div className="flex-1 pr-2">Start ingest to this channel.</div>
          <div className="flex-initial pr-2 2xl:flex-1">
            <LiveStatus isLive={channel.isLive} suspended={channel.suspended} />
          </div>
        </div>
        <div className="flex min-h-[32px] flex-nowrap pb-2">
          <div className="flex-1 pr-2">Open QoS player with "Show Metadata Events" enabled.</div>
          <div className="flex-initial pr-2 2xl:flex-1">
            <a
              href={playUrl({
                channelId: channel.channelId,
                authToken: channel.authToken,
                visualMetadata: true,
              })}
              target="_blank"
            >
              <Button size="small">Open QoS Player</Button>
            </a>
          </div>
        </div>
        {isSuperUser && (
          <div className="flex min-h-[32px] flex-nowrap pb-2">
            <div className="flex-1 pr-2">Choose site for metadata injection:</div>
            <div className="flex-initial pr-2 2xl:flex-1">
              <Select value={metadataSite} onValueChange={(value: string) => setMetadataSite(value)}>
                <SelectTrigger>
                  <SelectValue placeholder="Choose site" />
                </SelectTrigger>
                <SelectContent align="end">
                  <SelectItem value="global">global</SelectItem>
                  {mediaIngressSites &&
                    mediaIngressSites?.map((site) => (
                      <SelectItem key={site} value={site}>
                        {site}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
            </div>
          </div>
        )}
        <div className="flex min-h-[32px] flex-nowrap pb-2">
          <div className="flex-1 pr-2">Send metadata test to channel.</div>
          <div className="flex-initial pr-2 2xl:flex-1">
            <Button size="small" onClick={sendMetadata} disabled={!channel.isLive}>
              Send metadata
            </Button>
          </div>
        </div>
        <div className="flex min-h-[32px] flex-nowrap pb-2">
          <div className="flex-1 pr-2">Observe metadata in QoS player.</div>
        </div>
      </FormSection>
    </>
  )
}
