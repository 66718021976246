import { Expose } from "class-transformer"
import { IsBoolean, IsOptional, IsString, Max } from "class-validator"
import { IsNullable, IsPositiveFloat, IsPositiveInteger } from "./common"

export class ClientTelemetryDTO {
  @Expose()
  @IsOptional()
  @IsNullable()
  @IsString()
  url?: string

  @Expose()
  @IsOptional()
  @IsNullable()
  @IsPositiveFloat()
  @Max(1)
  probability?: number

  @Expose()
  @IsOptional()
  @IsNullable()
  @IsBoolean()
  includeErrors?: boolean

  @Expose()
  @IsOptional()
  @IsNullable()
  @IsBoolean()
  includeEvents?: boolean

  @Expose()
  @IsOptional()
  @IsNullable()
  @IsBoolean()
  includeStats?: boolean

  @Expose()
  @IsOptional()
  @IsNullable()
  @IsPositiveInteger()
  maxRetries?: number

  @Expose()
  @IsOptional()
  @IsNullable()
  @IsPositiveInteger()
  maxErrorReports?: number

  @Expose()
  @IsOptional()
  @IsNullable()
  @IsPositiveInteger()
  interval?: number
}
