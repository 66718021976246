import { severity } from "@vindral/components"
import { cva, VariantProps } from "class-variance-authority"
import { ReactNode } from "react"

// eslint-disable-next-line tailwindcss/no-custom-classname
const message = cva("flex-col divide-y overflow-hidden rounded-sm border", {
  variants: {
    severity,
  },
  defaultVariants: {
    severity: "info",
  },
})

export function Message(props: VariantProps<typeof message> & { children?: ReactNode }) {
  return <div className={message(props)}>{props.children}</div>
}

export function MessageSection(props: { children?: ReactNode }) {
  return <div className="p-4">{props.children}</div>
}
