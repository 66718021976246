import { IngestSessionTable, type IngestSessionTableProps } from "../../../organisms/IngestSessionTable"

export type ChannelEditIngestSessionsProps = IngestSessionTableProps & {
  isStaleData: boolean
}

export function ChannelEditIngestSessions({ isStaleData, ...props }: ChannelEditIngestSessionsProps) {
  if (props.data.length === 0 && !props.state?.globalFilter && !isStaleData) {
    return (
      <div className="relative flex justify-center pt-40">
        <div>
          <h1 className="font-medium">There is nothing here right now</h1>
          <p className="py-1 text-fg-subtle last-of-type:pb-3">You will be able to see your sessions here.</p>
        </div>
      </div>
    )
  }

  return (
    <div className="mx-auto flex min-h-0 w-full max-w-screen-2xl grow flex-col">
      <IngestSessionTable {...props} />
    </div>
  )
}
