import { cva, VariantProps } from "class-variance-authority"
import { ReactNode } from "react"

// eslint-disable-next-line tailwindcss/no-custom-classname
const stack = cva(["flex"], {
  variants: {
    orientation: {
      row: "flex-row",
      col: "flex-col",
    },
    spacing: {
      none: "gap-0",
      small: "gap-1",
      medium: "gap-2",
      large: "gap-4",
      xlarge: "gap-8",
    },
  },
  defaultVariants: {
    orientation: "row",
    spacing: "none",
  },
})

export type StackProps = VariantProps<typeof stack> & { children?: ReactNode }

export function Stack({ children, ...props }: StackProps) {
  return <div className={stack(props)}>{children}</div>
}
