import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { Divider } from "@vindral/components"
import { useEffect, useMemo, useState } from "react"
import { Statistic } from "../../molecules/Statistic"
import { InteractiveTable } from "../../organisms/InteractiveTable"
import { trpc } from "../../trpc"
import { compactNumber } from "../../utils/compactNumber"
import { isDefined } from "../../utils/is-defined"
import { AnalyticsQueryArgs } from "./Analytics"
import { mergeSessionsAndSessionTime } from "./helpers"

const formatTime = (seconds: number) => {
  const minutes = Math.round(seconds / 6) / 10
  const compact = new Intl.NumberFormat("en", {
    notation: "compact",
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
  }).format(minutes)
  return `${compact} minutes`
}

const SessionStatistics = (props: AnalyticsQueryArgs) => {
  const { time } = props
  const lookback = time.to - time.from
  const getChange = (first: number | undefined, second: number | undefined) => {
    if (typeof first === "undefined" || typeof second === "undefined") {
      return undefined
    }
    return first / second - 1
  }

  const { data: viewerSessionsTotalTime } = trpc.analytics.viewerSessionsTotalTime.useQuery(
    {
      ...props,
      time: time.to,
      lookback,
    },
    { keepPreviousData: true }
  )
  const { data: previousViewerSessionsTotalTime } = trpc.analytics.viewerSessionsTotalTime.useQuery(
    {
      ...props,
      time: time.from,
      lookback,
    },
    { keepPreviousData: true }
  )

  const { data: sessionAvgDuration } = trpc.analytics.viewerSessionsAvgDuration.useQuery(
    {
      ...props,
      time: time.to,
      lookback,
    },
    { keepPreviousData: true }
  )

  const { data: previousSessionAvgDuration } = trpc.analytics.viewerSessionsAvgDuration.useQuery(
    {
      ...props,
      time: time.from,
      lookback,
    },
    { keepPreviousData: true }
  )

  return (
    <div className="my-4 mb-12 flex flex-col gap-4 xl:flex-row">
      <div className="flex gap-4 xl:w-1/2">
        <div className="w-1/2 transition-colors">
          <Statistic
            value={isDefined(viewerSessionsTotalTime) ? formatTime(viewerSessionsTotalTime) : "-"}
            title="VIEWER TIME"
            subtext="Total viewer time"
            tooltip="Trend comparing selected time period to earlier adjacent period of same length"
            change={getChange(viewerSessionsTotalTime, previousViewerSessionsTotalTime)}
          />
        </div>
        <Divider variant="vertical" />
        <div className="w-1/2 transition-colors">
          <Statistic
            value={isDefined(sessionAvgDuration) ? formatTime(sessionAvgDuration) : "-"}
            title="SESSION DURATION"
            subtext="Average session duration"
            info="Average duration of every started viewer session"
            change={getChange(sessionAvgDuration, previousSessionAvgDuration)}
          />
        </div>
      </div>
    </div>
  )
}

type ChannelTableItem = {
  streamKey: string
  name: string
  sessionsTotalTime: number
  sessions: number
  sessionDuration: number
}

const TABLE_PAGE_SIZE = 10

const ChannelTable = (
  props: { data: ChannelTableItem[] } & { isLoading: boolean; loadingRows?: number }
): JSX.Element => {
  const { data, isLoading, loadingRows } = props

  const pageCount = Math.ceil((data.length ?? 0) / TABLE_PAGE_SIZE)
  const columnMeta = {
    style: { textAlign: "right" },
  }

  const columnHelper = createColumnHelper<ChannelTableItem>()
  const columns = [
    columnHelper.accessor("name", {
      header: () => "Channel",
      cell: (info) => <div className="font-medium">{info.getValue()}</div>,
      size: 250,
    }),
    columnHelper.accessor("sessions", {
      header: () => "Sessions",
      meta: columnMeta,
      cell: (info) => <div className="truncate text-right tabular-nums">{compactNumber(info.getValue())}</div>,
      size: 100,
    }),
    columnHelper.accessor("sessionDuration", {
      header: () => "SESSION DURATION",
      meta: columnMeta,
      cell: (info) => <div className="truncate text-right tabular-nums">{formatTime(info.getValue())}</div>,
      size: 100,
    }),
    columnHelper.accessor("sessionsTotalTime", {
      header: () => "Total Viewer Time",
      meta: columnMeta,
      cell: (info) => <div className="truncate text-right tabular-nums">{formatTime(info.getValue())}</div>,
      size: 100,
    }),
  ]

  const table = useReactTable({
    data,
    pageCount,
    autoResetPageIndex: false,
    manualFiltering: true,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })
  return <InteractiveTable table={table} loadingProps={{ isLoading, numRows: loadingRows }} includePaginationFooter />
}

const ChannelList = (props: AnalyticsQueryArgs): JSX.Element => {
  const { time, organizationId } = props
  const lookback = time.to - time.from
  const [tableData, setTableData] = useState<ChannelTableItem[]>([])

  const { data: sessionsByChannel, isLoading: isLoadingSessionsByChannel } =
    trpc.analytics.viewerSessionsByChannel.useQuery(
      {
        ...props,
        time: time.to,
        lookback,
      },
      { keepPreviousData: true }
    )
  const { data: sessionsTotalTimeByChannel, isLoading: isLoadingSessionsTotalTimeByChannel } =
    trpc.analytics.viewerSessionsTotalTimeByChannel.useQuery(
      {
        ...props,
        time: time.to,
        lookback,
      },
      { keepPreviousData: true }
    )

  const channelFilter = organizationId ? { organization: { publicId: organizationId } } : undefined
  const { data: channels, isLoading: isLoadingChannels } = trpc.channel.findMany.useQuery(
    { orderBy: "name", search: props.streamKey, filter: channelFilter },
    { keepPreviousData: true }
  )

  const channelNamesAndKeys = useMemo(
    () => channels?.items.map(({ name, streamKey }) => ({ name, streamKey })) || [],
    [channels]
  )

  const loadingRows = props.country ? 1 : TABLE_PAGE_SIZE
  const isLoading = isLoadingSessionsByChannel || isLoadingSessionsTotalTimeByChannel || isLoadingChannels

  useEffect(() => {
    if (!sessionsByChannel || !sessionsTotalTimeByChannel) {
      return
    }

    // Merge by streamKey
    // Calculate avg session duration
    // Sort by total time
    const createTableData = (sessionTimes: typeof sessionsTotalTimeByChannel, sessions: typeof sessionsByChannel) =>
      mergeSessionsAndSessionTime(channelNamesAndKeys, sessionTimes, sessions).sort(
        (a, b) => b.sessionsTotalTime - a.sessionsTotalTime
      )

    setTableData(createTableData(sessionsTotalTimeByChannel, sessionsByChannel))
  }, [sessionsByChannel, sessionsTotalTimeByChannel, channelNamesAndKeys])

  return <ChannelTable data={tableData} isLoading={isLoading} loadingRows={loadingRows} />
}

export const Sessions = (props: AnalyticsQueryArgs): JSX.Element => {
  return (
    <div className="relative">
      <SessionStatistics {...props} />
      <ChannelList {...props} />
    </div>
  )
}
