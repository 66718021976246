import { z } from "zod"
import { Order } from "../order"

export const ApiKeyFindManyParameters = z
  .object({
    orderBy: z.enum(["name", "createdAt", "maskedKey", "user.name"]),
    search: z.string().optional(),
  })
  .merge(Order)

export type ApiKeyFindManyParameters = z.infer<typeof ApiKeyFindManyParameters>
