import {
  IconArrowFork,
  IconBuildingBank,
  IconCloudDown,
  IconCpu,
  IconKey,
  IconLayoutDashboard,
  IconServer2,
  IconSettingsAutomation,
  IconStack3,
  IconUsersGroup,
  IconVideo,
  IconWorld,
} from "@tabler/icons-react"

const iconClassNames = "h-full w-full"

const streamingLinkGroup = (args: { showTranscodingProfiles: boolean }) => [
  {
    title: "Streaming",
    links: [
      {
        title: "Dashboard",
        url: "/dashboard",
        entity: "dashboard",
        icon: <IconLayoutDashboard className={iconClassNames} />,
      },
      {
        title: "Channels",
        url: "/channels",
        entity: "channel",
        icon: <IconVideo className={iconClassNames} />,
      },
      {
        title: "Channel groups",
        url: "/channel-groups",
        entity: "channelgroup",
        icon: <IconStack3 className={iconClassNames} />,
      },
      ...(args.showTranscodingProfiles
        ? [
            {
              title: "Transcoding profiles",
              url: "/transcoding-profiles",
              entity: "transcodingprofile",
              icon: <IconSettingsAutomation className={iconClassNames} />,
            },
          ]
        : []),
    ],
  },
]

const administrationLinkGroup = (args: { showApiKey: boolean }) => [
  {
    title: "Administration",
    links: [
      {
        title: "Organizations",
        url: "/organizations",
        entity: "organization",
        icon: <IconBuildingBank className={iconClassNames} />,
      },
      {
        title: "Users",
        url: "/users",
        entity: "user",
        icon: <IconUsersGroup className={iconClassNames} />,
      },
      ...(args.showApiKey
        ? [
            {
              title: "Api keys",
              url: "/api-keys",
              entity: "apiKey",
              icon: <IconKey className={iconClassNames} />,
            },
          ]
        : []),
    ],
  },
]

const infrastructureLinkGroup = (args: { isSuperUser: boolean }) =>
  args.isSuperUser
    ? [
        {
          title: "Infrastructure",
          links: [
            {
              title: "Regions",
              url: "/regions",
              entity: "region",
              icon: <IconWorld className={iconClassNames} />,
            },
            {
              title: "Sites",
              url: "/sites",
              entity: "site",
              icon: <IconServer2 className={iconClassNames} />,
            },
            {
              title: "Edge Servers",
              url: "/edge-servers",
              entity: "edge",
              icon: <IconCloudDown className={iconClassNames} />,
            },
            {
              title: "Packager Servers",
              url: "/packager-servers",
              entity: "packager-servers",
              icon: <IconCpu className={iconClassNames} />,
            },
            {
              title: "Ingest Points",
              url: "/ingest-points",
              entity: "Ingest Points",
              icon: <IconArrowFork className={iconClassNames} />,
            },
          ],
        },
      ]
    : []

export const defaultLinkGroups = [
  ...streamingLinkGroup({ showTranscodingProfiles: true }),
  ...administrationLinkGroup({ showApiKey: false }),
  ...infrastructureLinkGroup({ isSuperUser: true }),
]
