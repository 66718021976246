import { COUNTRIES } from "@core-services/data-types"
import { RegisterOrganization as RegisterOrganizationType } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { ChevronDownIcon } from "@radix-ui/react-icons"
import {
  Button,
  Checkbox,
  Combobox,
  ComboboxContent,
  ComboboxPortal,
  ComboboxTrigger,
  FormControl,
  FormControlLabel,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  TextField,
} from "@vindral/components"
import { useState } from "react"
import { useController, useForm } from "react-hook-form"
import { Logo } from "../../atoms/logos/Logo"
import { OrganizationUniqueNameStatus } from "../../molecules/OrganizationUniqueNameStatus"
import { trpc } from "../../trpc"

const resolver = zodResolver(RegisterOrganizationType)

interface RegisterOrganizationProps {
  registerOrganization: (args: RegisterOrganizationType) => void
}

const industryOptions = [
  "Horse racing",
  "Sports betting",
  "Live casino",
  "Online auctions",
  "Interactive formats",
  "Public safety",
  "Esports",
  "Live shopping",
  "Live events",
  "Other",
]

const companySizeOptions = [
  "1 - 5 employees",
  "5 - 20 employees",
  "20 - 100 employees",
  "100 - 500 employees",
  "500+ employees",
]

export const RegisterOrganization = (props: RegisterOrganizationProps) => {
  const { registerOrganization } = props
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    setValue,
    watch,
  } = useForm<RegisterOrganizationType>({
    resolver,
    mode: "onChange",
  })

  register("country")
  const [countryQuery, setCountryQuery] = useState("")
  const [countryListOpen, setCountryListOpen] = useState(false)
  const [agreedTerms, setAgreedTerms] = useState(false)
  const filteredCountries = COUNTRIES.filter((country) =>
    country.name.toLowerCase().includes(countryQuery.toLowerCase())
  ).sort()

  const industryController = useController({
    control,
    name: "industry",
    defaultValue: undefined,
    rules: { required: true },
  })

  const companyController = useController({
    control,
    name: "companySize",
    defaultValue: undefined,
    rules: { required: true },
  })

  const name = watch("name")
  const { data: uniqueNameData, isFetching: checkinguniqueName } = trpc.organization.generateUniqueName.useQuery(
    { name },
    { enabled: !!name, keepPreviousData: true }
  )
  const { uniqueName, notAllowedReason, allowed: uniqueNameAllowed } = uniqueNameData || {}
  const invalidUniqueName = checkinguniqueName || !uniqueNameAllowed

  return (
    <div className="flex h-screen flex-col-reverse lg:flex-row">
      <div className="flex h-full justify-center bg-canvas-1 pt-80 lg:w-1/2">
        <form onSubmit={handleSubmit(registerOrganization)} className="flex max-w-md flex-col gap-4">
          <h1 className="text-xl">Organization details</h1>
          <span>
            Vindral exclusively provides live streaming services to registered business entities, ensuring
            professional-grade solutions tailored for corporate needs.
          </span>
          <FormControl>
            <OrganizationUniqueNameStatus
              input={name}
              uniqueName={uniqueName}
              checkinguniqueName={checkinguniqueName}
            />
            <FormControlLabel error={errors.name?.message || notAllowedReason} required>
              Company name
            </FormControlLabel>
            <TextField autoFocus {...register("name")} data-pw="name" />
          </FormControl>
          <FormControl>
            <FormControlLabel error={errors.country?.message} required>
              Country
            </FormControlLabel>
            <Combobox
              onOpenChange={(open) => {
                setCountryListOpen(open)
                setCountryQuery("")
              }}
              open={countryListOpen}
            >
              <ComboboxTrigger asChild data-pw="countrySelector">
                <Button style={{ transform: "none" }} size="small">
                  <span className="font-normal capitalize">{watch("country")}</span>
                  <ChevronDownIcon className="ml-auto h-4 w-4" />
                </Button>
              </ComboboxTrigger>

              <ComboboxPortal>
                <ComboboxContent
                  popoverContentProps={{
                    align: "start",
                    side: "bottom",
                  }}
                  groups={[
                    {
                      id: 1,
                      title: "",
                      items: filteredCountries.map((country) => ({ id: country.code, name: country.name })),
                    },
                  ]}
                  selected={[]}
                  query={countryQuery}
                  onQueryChange={setCountryQuery}
                  onSelect={([country]) => {
                    setValue("country", country?.name || "")
                    setCountryListOpen(false)
                    setCountryQuery("")
                  }}
                  multiple={true}
                  getId={(item) => item.id}
                  renderTitle={(title) => title}
                  renderItem={(item) => <div>{item.name}</div>}
                />
              </ComboboxPortal>
            </Combobox>
          </FormControl>
          <FormControl>
            <FormControlLabel error={errors.industry?.message} required>
              Industry
            </FormControlLabel>
            <Select onValueChange={industryController.field.onChange} value={industryController.field.value}>
              <SelectTrigger data-pw="industrySelector">
                <SelectValue className="font-medium" />
              </SelectTrigger>
              <SelectContent>
                {industryOptions.map((industry) => (
                  <SelectItem key={industry} value={industry}>
                    {industry}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormControl>
          <FormControl>
            <FormControlLabel error={errors.companySize?.message}>Company size</FormControlLabel>
            <Select onValueChange={companyController.field.onChange} value={companyController.field.value}>
              <SelectTrigger data-pw="companySize">
                <SelectValue className="font-medium" />
              </SelectTrigger>
              <SelectContent>
                {companySizeOptions.map((size) => (
                  <SelectItem key={size} value={size}>
                    {size}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormControl>
          <FormControl>
            <div className="flex items-start gap-2">
              <Checkbox
                data-pw="agreeToTerms"
                checked={agreedTerms}
                onCheckedChange={(checked) => setAgreedTerms(checked === true)}
              />
              <span className="text-xs">
                I confirm that I am representing a registered business entity, not as an individual. I understand that
                Vindral Live Streaming Service is exclusively for business use, and I possess a valid Company
                registration ID. I acknowledge and agree to comply with all copyright laws, including the prohibition of
                re-broadcasting protected content without explicit written consent.
              </span>
            </div>
          </FormControl>
          <div>
            <Button
              disabled={!isDirty || !isValid || invalidUniqueName || !agreedTerms}
              type="submit"
              variant="primary"
            >
              Create 🚀
            </Button>
          </div>
        </form>
      </div>
      <div className="relative hidden h-full justify-center overflow-hidden bg-canvas-alt pt-80 lg:flex lg:w-1/2">
        <Logo className="absolute left-0 top-1/3 scale-150 text-component" />
        <div className="z-10 flex w-1/2 max-w-md flex-col gap-4">
          <h1 className="text-2xl">Next steps</h1>
          <ul className="list-inside list-disc text-lg">
            <li>Create your first channel to start streaming</li>
            <li>Invite coworkers to your team in Vindral Portal</li>
            <li>Activate 2FA for higher security</li>
            <li>Contact us for pricing information and account upgrades</li>
          </ul>
        </div>
      </div>
    </div>
  )
}
