import { subject } from "@casl/ability"
import { useAbility } from "@casl/react"
import { AccessPermissionRole, Action, OrganizationBillingStatus } from "@core-services/data-types"
import { UpdateUser } from "@core-services/portal"
import { User } from "@core-services/portal/src/user/user.routes"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Button,
  ConfirmDialogContent,
  Dialog,
  DialogTrigger,
  Divider,
  FormControlLabel,
  TextField,
} from "@vindral/components"
import { useEffect } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { AbilityContext } from "../../acl"
import { FormSection } from "../../atoms/FormSection"
import { Page, PageContainer, PageContent, PageFooter, PageHeader } from "../../atoms/Page"
import { SaveButton } from "../../atoms/SaveButton"
import { SaveSection } from "../../atoms/SaveSection"
import { Breadcrumbs } from "../../molecules/Breadcrumbs"
import { FormattedDate } from "../../molecules/FormattedDate"
import { Titled } from "../../molecules/Titled"
import { AccessPermissionRoleMultiSelector } from "../../organisms/multiselect/AccessPermissionsMultiSelector"

type UpdateUserForm = Omit<UpdateUser, "accessPermissions"> & {
  accessPermissions: {
    organization: { publicId: string; name: string; billingStatus: OrganizationBillingStatus }
    role: AccessPermissionRole
  }[]
}

const resolver = zodResolver(UpdateUser)

export const UserEdit = ({
  user,
  updateUser,
  deleteUser,
}: {
  user: User
  updateUser: (value: UpdateUser) => Promise<void>
  deleteUser: () => Promise<void>
}) => {
  const ability = useAbility(AbilityContext)
  const userSubject = subject("User", { publicId: user.publicId, accessPermissions: user.accessPermissions })

  const form = useForm<UpdateUserForm>({
    resolver,
    mode: "onChange",
    defaultValues: {
      id: user.publicId,
      name: user.name,
      email: user.email,
      password: undefined,
      accessPermissions: user.accessPermissions,
    },
  })
  const {
    control,
    reset,
    handleSubmit,
    register,
    formState: { errors, isDirty, isValid, isSubmitSuccessful },
  } = form

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful, reset])

  const accessPermissions = useFieldArray({ control, name: "accessPermissions" })

  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
          </div>
        </PageHeader>
        <PageContainer>
          <form onSubmit={handleSubmit(updateUser)} className="flex flex-col gap-4 py-4">
            <FormSection title="General" description="Change general settings">
              <div className="flex max-w-sm flex-col gap-4">
                <div className="flex flex-col gap-1">
                  <FormControlLabel required error={errors.name?.message}>
                    Full name
                  </FormControlLabel>
                  <TextField {...register("name")} readOnly={ability.cannot(Action.Update, userSubject, "name")} />
                </div>

                <div className="flex flex-col gap-1">
                  <FormControlLabel required error={errors.email?.message}>
                    E-mail
                  </FormControlLabel>
                  <TextField {...register("email")} readOnly={ability.cannot(Action.Update, userSubject, "email")} />
                </div>

                <div className="flex flex-col gap-1">
                  <FormControlLabel required error={errors.password?.message}>
                    Password
                  </FormControlLabel>
                  <TextField
                    {...register("password", {
                      setValueAs: (value: string) => (value?.length > 0 ? value : undefined),
                    })}
                    readOnly={ability.cannot(Action.Update, userSubject, "password")}
                    placeholder="••••••••••••••••"
                    type="password"
                  />
                </div>

                {user?.lastLoginAt && (
                  <div className="flex flex-col gap-1">
                    <FormControlLabel>Last login</FormControlLabel>
                    <FormattedDate date={new Date(user.lastLoginAt)} />
                  </div>
                )}
              </div>
            </FormSection>
            <Divider />
            <FormSection title="Permissions" description="Change which organizations the user can access">
              <AccessPermissionRoleMultiSelector
                readonly={ability.cannot(Action.Update, userSubject, "accessPermissions")}
                onSelect={accessPermissions.append}
                onDeselect={accessPermissions.remove}
                onUpdate={accessPermissions.update}
                selected={accessPermissions.fields}
              />
            </FormSection>
            <SaveSection>
              <SaveButton text="Save" disabled={!isDirty || !isValid} />
            </SaveSection>
          </form>
        </PageContainer>
      </PageContent>
      <PageFooter>
        <div className="flex h-16 items-center justify-between">
          <div className="flex gap-8">
            <Titled title="Created">
              <FormattedDate date={new Date(user.createdAt)} />
            </Titled>
            {typeof user.updatedAt !== "undefined" && (
              <Titled title="Updated">
                <FormattedDate date={new Date(user.updatedAt)} />
              </Titled>
            )}
          </div>
          {ability.can(Action.Delete, subject("User", user)) && (
            <Dialog>
              <DialogTrigger asChild>
                <Button variant="danger" data-pw="deleteButton">
                  Delete
                </Button>
              </DialogTrigger>
              <ConfirmDialogContent
                onConfirm={deleteUser}
                title={`Confirm deletion of ${user.name}`}
                description={
                  <>
                    Please confirm that you are absolutely sure that you want to delete
                    <span className="font-medium"> {user.name}</span>.
                  </>
                }
              />
            </Dialog>
          )}
        </div>
      </PageFooter>
    </Page>
  )
}
