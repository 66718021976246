import { Link, Route } from "@tanstack/react-router"
import { Button } from "@vindral/components"
import { rootRoute } from "./root"

export const notFoundRoute = new Route({
  path: "*",
  getParentRoute: () => rootRoute,
  component: () => (
    <div className="relative flex justify-center pt-40">
      <div>
        <h1 className="font-medium">Page not found</h1>
        <p className="py-1 text-fg-subtle last-of-type:pb-3">You have found a non-existing page.</p>
        <Link to="/dashboard">
          <Button variant="primary" size="large">
            Go back to the dashboard
          </Button>
        </Link>
      </div>
    </div>
  ),
})
