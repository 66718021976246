import { z } from "zod"
import * as common from "./transcoding-profile.common"
import * as create from "./transcoding-profile.create"

const Id = z.object({ id: z.string().uuid() })

const UpdateVideoRendition = Id.and(create.CreateVideoRendition)
export type UpdateVideoRendition = z.infer<typeof UpdateVideoRendition>
const UpdateAudioRendition = Id.and(create.CreateAudioRendition)
export type UpdateAudioRendition = z.infer<typeof UpdateAudioRendition>
const UpdateThumbnailRendition = Id.and(create.CreateThumbnailRendition)
export type UpdateThumbnailRendition = z.infer<typeof UpdateThumbnailRendition>

const UpdateVideoStream = z.object({
  type: z.literal("video"),
  gopAlignment: z.boolean(),
  renditions: z.array(Id.and(UpdateVideoRendition.or(UpdateThumbnailRendition))).max(10),
})
export type UpdateVideoStream = z.infer<typeof UpdateVideoStream>

const UpdateAudioStream = z.object({
  type: z.literal("audio"),
  renditions: z.array(Id.and(UpdateAudioRendition)).max(10),
})
export type UpdateAudioStream = z.infer<typeof UpdateAudioStream>

export const UpdateStream = Id.and(common.BaseStream).and(
  z.discriminatedUnion("type", [UpdateAudioStream, UpdateVideoStream])
)
export type UpdateStream = z.infer<typeof UpdateStream>

// Ignored until we add support for updating this
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UpdateConfig = z.object({
  streams: z.array(UpdateStream).max(5),
})

export const UpdateTranscodingProfile = z.object({
  name: z.string().min(2).max(50).optional(),
  notes: z.string().max(500).optional(),
  rawConfig: z.string().optional(),
})
export type UpdateTranscodingProfile = z.infer<typeof UpdateTranscodingProfile>
