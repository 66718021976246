import { useAbility } from "@casl/react"
import { Action } from "@core-services/data-types"
import { CreateUser } from "@core-services/portal"
import { Route, useNavigate } from "@tanstack/react-router"
import { toast } from "@vindral/components"
import { userIndexRoute } from "."
import { AbilityContext } from "../../acl"
import { UserCreate } from "../../templates/user/UserCreate"
import { trpc } from "../../trpc"

function Page() {
  const ability = useAbility(AbilityContext)
  const navigate = useNavigate()
  const context = trpc.useContext()

  if (!ability.can(Action.Create, "User")) {
    return null
  }

  const createMutation = trpc.user.create.useMutation({
    onSuccess: (user) => {
      const userPublicId = user.publicId
      context.user.findById.setData({ publicId: userPublicId }, user)

      toast({ title: `Created user ${user.name}` })
      void navigate({
        to: "/users/edit/$id",
        params: { id: userPublicId },
        replace: true,
      })
    },
    onError: (error) => {
      toast({ title: "Failed to create user!", description: error.message })
    },
  })

  const createUser = async (user: CreateUser) => {
    createMutation.reset()
    await createMutation.mutateAsync(user)
  }

  return <UserCreate createUser={createUser} />
}

export const userCreateRoute = new Route({
  getParentRoute: () => userIndexRoute,
  preSearchFilters: [(search) => search],
  path: "create",
  component: Page,
  getContext: () => {
    return {
      pageTitle: "Create user",
    }
  },
})
