import { IsString } from "class-validator"

export class RtmpServerUpdateDTO {
  @IsString()
  addr!: string

  @IsString()
  clientid!: string

  @IsString()
  flashver!: string

  @IsString()
  name!: string

  @IsString()
  swfurl!: string

  @IsString()
  tcurl!: string

  @IsString()
  call!: string
}
