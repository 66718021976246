import { z } from "zod"
import { Order } from "../order"

export const SiteFindManyParameters = z
  .object({
    orderBy: z.enum([
      "name",
      "code",
      "providers",
      "latitude",
      "longitude",
      "mediaIngressActive",
      "viewerTrafficActive",
      "transcodingActive",
      "edgeOriginActive",
      "edgeEgressActive",
    ]),
    search: z.string().optional(),
  })
  .merge(Order)

export type SiteFindManyParameters = z.infer<typeof SiteFindManyParameters>
