import { cva, VariantProps } from "class-variance-authority"

const defaultVariants = {
  variant: "regular",
} as const

const divider = cva(["border-divider"], {
  variants: {
    variant: {
      regular: [],
      vertical: ["h-auto border-0 border-l"],
    },
  },
  defaultVariants,
})

type DividerProps = VariantProps<typeof divider>

export function Divider({ variant }: DividerProps) {
  return <hr className={divider({ variant })}></hr>
}
