import { denyNothingNumberLimitation } from "@core-services/data-types"
import { Site, UpdateSite } from "@core-services/portal"
import { Navigate, Route, useNavigate, useParams } from "@tanstack/react-router"
import { toast } from "@vindral/components"
import { z } from "zod"
import { siteIndexRoute } from "."
import { SiteEdit } from "../../templates/infrastructure/site/SiteEdit"
import { SiteEditChannelRules } from "../../templates/infrastructure/site/edit/SiteEditChannelRules"
import { SiteEditGeneral } from "../../templates/infrastructure/site/edit/SiteEditGeneral"
import { SiteEditGeographicRules } from "../../templates/infrastructure/site/edit/SiteEditGeographicRules"
import { SiteEditOrganizationRules } from "../../templates/infrastructure/site/edit/SiteEditOrganizationRules"
import { trpc } from "../../trpc"

const Params = z.object({ code: z.string() })

export const siteEditRoute = new Route({
  getParentRoute: () => siteIndexRoute,
  preSearchFilters: [(search) => search],
  path: "edit/$code",
  parseParams: (params) => Params.parse(params),
  component: Edit,
  getContext: () => {
    return {
      pageTitle: "Edit Site",
    }
  },
})
function Edit() {
  const context = trpc.useContext()
  const navigate = useNavigate()
  const { code } = useParams({ from: siteEditRoute.id })
  const { data: site } = trpc.site.findBySiteCode.useQuery({ code })

  const deleteMutation = trpc.site.delete.useMutation({
    onSuccess() {
      void context.site.findBySiteCode.invalidate({ code: site?.code })
      void context.site.findAll.invalidate()
      void context.site.findAllWithActiveInfo.invalidate()
      void context.site.findMany.invalidate()
      toast({ title: "Deleted successfully!" })
      void navigate({ to: "/sites", replace: true })
    },
    onError: (error) => {
      toast({ title: "Failed to delete!", description: error.message })
    },
  })

  if (!code || !site) {
    return null
  }

  const deleteSite = async () => {
    deleteMutation.reset()
    await deleteMutation.mutateAsync({ publicId: site.publicId })
  }

  return <SiteEdit site={site} onDelete={deleteSite} />
}

const childRoute = (
  path: "/" | "general" | "geographic-rules" | "channel-rules" | "organization-rules",
  component: () => JSX.Element
) =>
  new Route({
    getParentRoute: () => siteEditRoute,
    preSearchFilters: [(search) => search],
    path,
    component,
  })

export const siteEditIndexRoute = childRoute("/", IndexRoutePage)
export const siteEditGeneralRoute = childRoute("general", () => EditPage(SiteEditGeneral))
export const siteEditGeographicRulesRoute = childRoute("geographic-rules", () => EditPage(SiteEditGeographicRules))
export const siteEditChannelRulesRoute = childRoute("channel-rules", () => EditPage(SiteEditChannelRulesWrapper))
export const siteEditOrganizationRulesRoute = childRoute("organization-rules", () =>
  EditPage(SiteEditOrganizationRulesWrapper)
)

function IndexRoutePage() {
  const { code } = useParams({ from: siteEditIndexRoute.id })
  return <Navigate to="/sites/edit/$code/general" params={{ code }} replace />
}

const SiteEditChannelRulesWrapper = ({ site, updateSite }: SiteEditPageProps) => {
  const channelLimitation = site.channelLimitation ?? denyNothingNumberLimitation
  const { data: limitedChannels = [], isInitialLoading } = trpc.channel.findIdentifiersByIds.useQuery(
    {
      ids: channelLimitation.limited,
    },
    { keepPreviousData: true }
  )

  return isInitialLoading ? (
    <></>
  ) : (
    <SiteEditChannelRules site={site} updateSite={updateSite} limitedChannels={limitedChannels} />
  )
}

export const SiteEditOrganizationRulesWrapper = ({ site, updateSite }: SiteEditPageProps) => {
  const organizationLimitation = site.organizationLimitation ?? denyNothingNumberLimitation
  const { data: limitedOrganizations = [], isInitialLoading } = trpc.organization.findIdentifiersByIds.useQuery(
    {
      ids: organizationLimitation.limited,
    },
    { keepPreviousData: true }
  )

  return isInitialLoading ? (
    <></>
  ) : (
    <SiteEditOrganizationRules site={site} updateSite={updateSite} limitedOrganizations={limitedOrganizations} />
  )
}

export type SiteEditPageProps = {
  site: Site
  updateSite: (site: UpdateSite) => void
}

function EditPage(Page: (props: SiteEditPageProps) => JSX.Element) {
  const navigate = useNavigate()
  const context = trpc.useContext()
  const { code } = useParams({ from: siteEditGeneralRoute.id })
  const { data: site } = trpc.site.findBySiteCode.useQuery({ code }, { suspense: true, refetchInterval: 5000 })
  const updateMutation = trpc.site.update.useMutation({
    onSuccess: (site) => {
      void context.site.findMany.invalidate()
      void context.site.findAll.invalidate()
      void context.site.findBySiteCode.invalidate({ code: site.code })
      toast({ title: "Site updated" })
      if (site.code !== code) {
        void navigate({
          to: "/sites/edit/$code/general",
          params: { code: site.code },
          replace: true,
        })
      }
    },
    onError: (error) => {
      toast({ title: "Failed to update site!", description: error.message })
    },
  })
  const updateSite = async (updated: UpdateSite) => {
    updateMutation.reset()
    await updateMutation.mutateAsync(updated)
  }

  return site ? <Page site={site} updateSite={updateSite} /> : <></>
}
