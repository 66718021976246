import { ArrowLeftIcon, ArrowRightIcon } from "@radix-ui/react-icons"
import { Button } from "../atoms/Button"

export interface PaginationProps {
  pageIndex: number
  pageCount: number
  nextPage: () => void
  previousPage: () => void
}

export function Pagination(props: PaginationProps) {
  return props.pageCount > 1 ? (
    <div className="flex items-center gap-2">
      <span>
        Page {props.pageIndex + 1} of {Math.max(1, props.pageCount)}
      </span>
      <Button size="small" shortcut="left" disabled={props.pageIndex === 0} onClick={props.previousPage}>
        <ArrowLeftIcon />
        Prev
      </Button>
      <Button
        size="small"
        shortcut="right"
        disabled={props.pageCount === 0 || props.pageCount === props.pageIndex + 1}
        onClick={props.nextPage}
      >
        Next
        <ArrowRightIcon />
      </Button>
    </div>
  ) : null
}
