import { RecaptchaAction } from "@core-services/portal"
import { ReactNode, createContext, useContext, useEffect } from "react"
import { trpc } from "../trpc"

interface ReCaptchaProviderProps {
  children: ReactNode
}

interface ReCaptchaContextProps {
  executeReCaptcha: (action?: RecaptchaAction) => Promise<string>
}

const ReCaptchaContext = createContext<ReCaptchaContextProps | undefined>(undefined)

export const useReCaptcha = () => {
  const context = useContext(ReCaptchaContext)
  if (!context) {
    throw new Error("useReCaptcha must be used within a ReCaptchaProvider")
  }
  return context
}

export const ReCaptchaProvider = ({ children }: ReCaptchaProviderProps) => {
  const { data: siteKey } = trpc.configuration.getReCaptchaSiteKey.useQuery()

  useEffect(() => {
    if (siteKey) {
      const script = document.createElement("script")
      script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`
      script.async = true
      document.body.appendChild(script)
    }
  }, [siteKey])

  const executeReCaptcha = async (action?: RecaptchaAction) => {
    if (!siteKey) {
      // return empty string if reCAPTCHA is disabled
      return ""
    }

    return new Promise<string>((resolve) => {
      if (typeof window.grecaptcha === "undefined") {
        throw new Error(
          "Unable to load reCAPTCHA: Please ensure that your browser allows third-party scripts and try again."
        )
      }

      window.grecaptcha.ready(async () => {
        const token: string = await window.grecaptcha.execute(siteKey, { action })
        resolve(token)
      })
    })
  }

  const contextValue: ReCaptchaContextProps = {
    executeReCaptcha,
  }

  return <ReCaptchaContext.Provider value={contextValue}>{children}</ReCaptchaContext.Provider>
}
