import { MagnifyingGlassIcon } from "@radix-ui/react-icons"
import { useRouter } from "@tanstack/react-router"
import {
  Dialog,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
  useShortcut,
} from "@vindral/components"
import { useEffect, useState } from "react"
import { SearchDialogContent } from "./SearchDialogContent"
import { SearchContextProvider } from "./context"

interface SearchProps {
  organization?: { name: string; publicId: string }
}

export const Search = (props: SearchProps) => {
  const [open, setOpen] = useState(false)
  const router = useRouter()
  const { organization } = props

  useEffect(() => {
    setOpen(false)
  }, [router.state.currentLocation.pathname])

  const { Shortcut } = useShortcut({
    shortcut: "mod+k",
    callback: (event: KeyboardEvent) => {
      event.preventDefault()
      setOpen((current) => !current)
    },
    options: {
      enableOnFormTags: ["INPUT"],
      preventDefault: false,
    },
  })

  return (
    <Dialog open={open}>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <div onClick={() => setOpen(true)} className="-m-2 rounded-full p-2 hover:bg-component-hover">
              <MagnifyingGlassIcon className="h-5 w-5" />
            </div>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent side="right">
              <Shortcut />
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </TooltipProvider>
      <SearchContextProvider enabled={open} organizationId={organization?.publicId}>
        <SearchDialogContent close={() => setOpen(false)} open={open} scope={organization?.name} />
      </SearchContextProvider>
    </Dialog>
  )
}
