import { Action } from "@core-services/data-types"
import { AppAbility, OrganizationFindOne } from "@core-services/portal"
import { Outlet } from "@tanstack/react-router"
import { Button, ConfirmDialogContent, Dialog, DialogTrigger, FormControlLabel, TextField } from "@vindral/components"
import { useState } from "react"
import { checkIfSuperUser, organizationSubjectInOrganization } from "../acl"
import { Page, PageContainer, PageContent, PageFooter, PageHeader } from "../atoms/Page"
import { Breadcrumbs } from "../molecules/Breadcrumbs"
import { FormattedDate } from "../molecules/FormattedDate"
import { RouteTabs, RouteTrigger } from "../molecules/RouteTabs"
import { Titled } from "../molecules/Titled"

interface OrganizationEditPageProps {
  path: "/organizations/edit/$publicId" | "/settings"
  organization: OrganizationFindOne
  ability: AppAbility
  onDelete: () => Promise<void>
}

const OrganizationDeleteDialog = (props: OrganizationEditPageProps) => {
  const { ability, organization, onDelete } = props
  const [deleteConfirmation, setDeleteConfirmation] = useState<string | null>(null)
  const subject = organizationSubjectInOrganization(organization.publicId)

  if (ability.cannot(Action.Delete, subject)) {
    return null
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="danger" data-pw="deleteButton">
          Delete
        </Button>
      </DialogTrigger>
      <ConfirmDialogContent
        onConfirm={onDelete}
        onCancel={() => setDeleteConfirmation(null)}
        title={`Confirm deletion of this organization`}
        disableConfirmButton={deleteConfirmation !== organization.uniqueName}
        description={
          <>
            <div className="mb-3">
              Please confirm that you are absolutely sure that you want to delete organization{" "}
              <span className="font-medium text-fg-danger-subtle">{organization.uniqueName}</span>
            </div>
            <div className="flex flex-col gap-1">
              <FormControlLabel>Type the name of the organization to confirm</FormControlLabel>
              <TextField onChange={(event) => setDeleteConfirmation(event.target.value)} data-pw="deleteConfirmation" />
            </div>
          </>
        }
      />
    </Dialog>
  )
}

export function OrganizationEditPage(props: OrganizationEditPageProps) {
  const { path, organization, ability } = props
  const isSuperUser = checkIfSuperUser({ ability })

  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
          </div>
          <RouteTabs border="none">
            {isSuperUser && <RouteTrigger to={`${path}/general`}>General</RouteTrigger>}
            <RouteTrigger to={`${path}/webhooks`}>Webhooks</RouteTrigger>
            <RouteTrigger to={`${path}/authentication`}>Authentication</RouteTrigger>
            <RouteTrigger to={`${path}/limits`}>Limits</RouteTrigger>
            {isSuperUser && <RouteTrigger to={`${path}/site-rules`}>Site rules</RouteTrigger>}
            {isSuperUser && <RouteTrigger to={`${path}/client-overrides`}>Client overrides</RouteTrigger>}
            {isSuperUser && <RouteTrigger to={`${path}/telemetry`}>Telemetry</RouteTrigger>}
            {isSuperUser && <RouteTrigger to={`${path}/hyperlocal`}>Hyperlocal</RouteTrigger>}
          </RouteTabs>
        </PageHeader>
        <PageContainer>
          <Outlet />
        </PageContainer>
      </PageContent>
      <PageFooter>
        <div className="flex h-16 items-center justify-between">
          <div className="flex gap-8">
            <Titled title="Organization">{organization.name}</Titled>
            <Titled title="Created">
              <FormattedDate date={new Date(organization.createdAt)} />
            </Titled>
            {typeof organization.updatedAt !== "undefined" && (
              <Titled title="Updated">
                <FormattedDate date={new Date(organization.updatedAt)} />
              </Titled>
            )}
          </div>
          <OrganizationDeleteDialog {...props} />
        </div>
      </PageFooter>
    </Page>
  )
}
