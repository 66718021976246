import Editor, { Monaco } from "@monaco-editor/react"
import { useController, useForm } from "react-hook-form"
import { SaveButton } from "../atoms/SaveButton"
import { useVindralTheme } from "../contexts/theme"
import { TranscodingProfileSchema } from "../schemas/TranscodingProfileSchema"

export interface TranscodingProfileRawConfigFormProps {
  rawConfig: string
  onSave: ({ rawConfig }: { rawConfig: string }) => Promise<void>
}

export function TranscodingProfileRawConfigForm({ rawConfig, onSave }: TranscodingProfileRawConfigFormProps) {
  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm({ defaultValues: { rawConfig } })
  const { appliedTheme } = useVindralTheme()
  const { field } = useController({ control, name: "rawConfig" })

  const handleEditorWillMount = (monaco: Monaco) => {
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      schemas: [
        {
          fileMatch: ["**"],
          uri: "http://vindral/packager-config.json",
          schema: TranscodingProfileSchema,
        },
      ],
    })
  }

  const editorOptions = {
    lineNumbers: "off",
    minimap: {
      enabled: false,
    },
  } as const

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <Editor
        saveViewState
        height="700px"
        defaultLanguage="json"
        onChange={field.onChange}
        theme={appliedTheme === "dark" ? "vs-dark" : "light"}
        defaultValue={rawConfig}
        options={editorOptions}
        beforeMount={handleEditorWillMount}
      />
      <div className="sticky bottom-0 bg-canvas-1 py-4">
        <SaveButton text="Save" disabled={!isDirty || !isValid} />
      </div>
    </form>
  )
}
