import { Cross2Icon } from "@radix-ui/react-icons"
import * as RadixToast from "@radix-ui/react-toast"
import { cva } from "class-variance-authority"
import { ComponentProps, ComponentPropsWithoutRef, ElementRef, forwardRef, ReactNode } from "react"
import { Severity, severity } from "../../style/severity"

const toast = cva(
  [
    "rounded",
    "relative",
    "p-4",
    "pr-8",
    "border",
    "group",
    "flex",
    "pointer-events-auto",
    "items-center",
    "gap-2",
    "border-divider",
    "bg-canvas-3",
    "shadow-xl",
    "radix-state-open:animate-toast-slide-in-right",
    "radix-state-closed:animate-toast-hide",
    "radix-swipe-direction-right:radix-swipe-end:animate-toast-swipe-out-x",
    "radix-swipe-direction-right:translate-x-radix-toast-swipe-move-x",
    "radix-swipe-direction-down:radix-swipe-end:animate-toast-swipe-out-y",
    "radix-swipe-direction-down:translate-y-radix-toast-swipe-move-y",
    "radix-swipe-cancel:ease-out",
    "radix-swipe-cancel:translate-x-0",
    "radix-swipe-cancel:duration-200",
  ],
  {
    variants: {
      severity,
    },
  }
)

export const Toast = forwardRef<
  ElementRef<typeof RadixToast.Root>,
  ComponentProps<typeof RadixToast.Root> & { severity?: Severity }
>(({ children, severity = "info", ...props }, ref) => {
  return (
    <RadixToast.Root ref={ref} {...props} className={toast({ severity })}>
      {children}
    </RadixToast.Root>
  )
})
Toast.displayName = "Toast"

export function ToastContent({ children }: { children: ReactNode }) {
  return <div className="flex grow flex-col gap-1">{children}</div>
}

ToastContent.displayName = "ToastContent"

export const ToastTitle = forwardRef<ElementRef<typeof RadixToast.Title>, ComponentProps<typeof RadixToast.Title>>(
  ({ children, ...props }, ref) => {
    return (
      <RadixToast.Title ref={ref} {...props} className="font-medium">
        {children}
      </RadixToast.Title>
    )
  }
)
ToastTitle.displayName = "ToastTitle"

export const ToastDescription = forwardRef<
  ElementRef<typeof RadixToast.Description>,
  ComponentProps<typeof RadixToast.Description>
>(({ children, ...props }, ref) => {
  return (
    <RadixToast.Description ref={ref} {...props}>
      {children}
    </RadixToast.Description>
  )
})
ToastDescription.displayName = "ToastDescription"

export const ToastAction = forwardRef<ElementRef<typeof RadixToast.Action>, ComponentProps<typeof RadixToast.Action>>(
  ({ children, ...props }, ref) => {
    return (
      <RadixToast.Action ref={ref} {...props} className="flex items-center justify-end">
        {children}
      </RadixToast.Action>
    )
  }
)
ToastAction.displayName = "ToastAction"

export const ToastClose = forwardRef<
  ElementRef<typeof RadixToast.Close>,
  ComponentPropsWithoutRef<typeof RadixToast.Close>
>((props, ref) => (
  <RadixToast.Close
    ref={ref}
    {...props}
    className="absolute right-1 top-1 p-1 text-inherit opacity-0 transition-opacity hover:text-fg focus:opacity-100 focus:outline-none group-hover:opacity-100"
    toast-close=""
  >
    <Cross2Icon />
  </RadixToast.Close>
))
ToastClose.displayName = "ToastClose"

const defaultVariants = {
  position: "topRight",
} as const

const provider = cva(
  ["fixed", "z-50", "w-96", "max-w-full", "p-8", "outline-none", "flex", "flex-col", "gap-4", "pointer-events-none"],
  {
    variants: {
      position: {
        topRight: ["right-0", "top-0"],
        bottomRight: ["right-0", "bottom-0"],
      },
    },
    defaultVariants,
  }
)

export function ToastProvider({ children, ...props }: ComponentProps<typeof RadixToast.Provider>) {
  return (
    <RadixToast.Provider {...props}>
      {children}
      <RadixToast.Viewport className={provider({ position: "topRight" })} />
    </RadixToast.Provider>
  )
}
ToastProvider.displayName = "ToastProvider"

export type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>
export type ToastActionElement = React.ReactElement<typeof ToastAction>
