import { z } from "zod"
import * as common from "./transcoding-profile.common"

const sizeDescription = "The video size, if left unset it will match the source"

export const CreateThumbnailRendition = z.object({
  size: common.Size.optional().describe(sizeDescription),
  codec: common.ThumbnailCodec,
  frameSkipping: common.FrameSkippingThumbnail,
})

export const CreateVideoRendition = z.object({
  gopSizeMs: common.GopSizeMs.min(500, "GOP size must be 500 ms or more").max(
    10000,
    "GOP size must be 10000 ms or less"
  ),
  size: common.Size.optional().describe(sizeDescription),
  bitRate: common.VideoBitRate.default(500000),
  codec: common.VideoCodec,
  frameSkipping: common.FrameSkippingVideo.optional(),
})

export const CreateAudioRendition = z
  .object({
    bitRate: common.AudioBitRate.default(96000),
    codec: common.AudioCodec,
    sampleRate: z.number().int().optional(),
    channels: z.number().int().min(1).max(2).optional(),
  })
  .refine(
    (rendition) => {
      if (rendition.codec !== "opus") {
        return true
      }

      return rendition.sampleRate === 48000
    },
    {
      message: "Sample rate must be 48000 with OPUS",
    }
  )

const CreateVideoStream = z.object({
  type: z.literal("video"),
  gopAlignment: z.boolean(),
  renditions: z.array(CreateVideoRendition.or(CreateThumbnailRendition)).max(10),
})

const CreateAudioStream = z.object({
  type: z.literal("audio"),
  renditions: z.array(CreateAudioRendition).max(10),
})

const CreateStream = z.discriminatedUnion("type", [CreateAudioStream, CreateVideoStream]).and(common.BaseStream)

const CreateConfig = z.object({
  streams: z.array(CreateStream).max(5),
})

export const CreateTranscodingProfile = z.object({
  name: z.string().min(2).max(50),
  notes: z.string().max(500),
  organizationId: z.string().uuid({ message: "Must be provided" }),
  config: CreateConfig,
  rawConfig: z.string().optional(),
})
export type CreateTranscodingProfile = z.infer<typeof CreateTranscodingProfile>
