import { AppAbility, UpdateChannel } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { checkIfSuperUser } from "../../../acl"
import { FormSection } from "../../../atoms/FormSection"
import { SaveButton } from "../../../atoms/SaveButton"
import { SaveSection } from "../../../atoms/SaveSection"
import { RouteBlockDialog } from "../../../molecules/RouteBlockDialog"
import { ClientOverridesForm } from "../../../organisms/ClientOverridesForm"
import { SourceOverridesForm } from "../../../organisms/SourceOverridesForm"
import { TelemetryOverridesForm } from "../../../organisms/TelemetryOverridesForm"
import { ChannelFindById } from "../ChannelEdit"

const resolver = zodResolver(UpdateChannel)

export interface ChannelEditDetailsOverridesProps {
  ability: AppAbility
  channel: ChannelFindById
  onSave: (channel: UpdateChannel) => Promise<void>
}

export function ChannelEditDetailsOverrides({ ability, channel, onSave }: ChannelEditDetailsOverridesProps) {
  const form = useForm<UpdateChannel>({
    resolver,
    mode: "onChange",
    defaultValues: {
      channelId: channel.channelId,
      clientOverrides: channel.clientOverrides,
      clientTelemetry: channel.clientTelemetry,
      transcodingMultiplier: channel.transcodingMultiplier,
    },
  })
  const {
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting, isSubmitSuccessful },
    reset,
  } = form

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful, reset])

  const isSuperUser = checkIfSuperUser({ ability })
  if (!isSuperUser) {
    return <></>
  }

  return (
    <div className="py-4">
      <RouteBlockDialog condition={isDirty && !isSubmitting} />
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSave)} className="flex flex-col gap-8">
          <FormSection
            title="Client overrides"
            description="Override any local client options connecting to this channel."
          >
            <ClientOverridesForm />
          </FormSection>

          <FormSection
            title="Telemetry overrides"
            description={[
              <p>
                Configure client telemetry options for this channel. This will override the default options in the
                client and any options specified for this organization.
              </p>,
              <p className="pt-2">Default client behavior:</p>,
              <ul>
                <li> - 100% probability to send telemetry</li>
                <li> - Send telemetry every 30 seconds</li>
                <li> - Always send errors</li>
                <li> - Always send stats</li>
                <li> - Send telemetry to the connected edge</li>
              </ul>,
            ]}
          >
            <TelemetryOverridesForm />
          </FormSection>
          <FormSection
            title="Source Overrides"
            description="Override transcoding multiplier to increase/decrease the amount of CPU allocated to packagers for this channel"
          >
            <SourceOverridesForm />
          </FormSection>

          <SaveSection>
            <SaveButton text="Save" disabled={!isDirty || !isValid} />
          </SaveSection>
        </form>
      </FormProvider>
    </div>
  )
}
