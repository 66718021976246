import { COUNTRIES } from "@core-services/data-types"
import { createColumnHelper } from "@tanstack/react-table"
import { Button } from "@vindral/components"
import { useMemo } from "react"
import { MultiSelectorCountryCombobox } from "./MultiSelectCountryCombobox"
import { MultiSelector, MultiSelectorFilterTextField, MultiSelectorTable } from "./MultiSelector"

export interface CountryMultiSelectorProps {
  onSelect: (item: { countryCode: string }) => void
  onDeselect: (index: number) => void
  selected: { countryCode: string }[]
  readonly?: boolean
}

export function CountryMultiSelector(props: CountryMultiSelectorProps) {
  const countryLookup = useMemo(() => {
    const countries = new Map<string, string>()

    COUNTRIES.forEach((c) => {
      countries.set(c.code, c.name)
    })

    return countries
  }, [])

  const data = useMemo(() => {
    return props.selected.map((s) => ({ name: countryLookup.get(s.countryCode) }))
  }, [props.selected, countryLookup])

  const columnHelper = createColumnHelper<typeof data[0]>()
  const columns = [
    columnHelper.accessor("name", {
      header: () => "Name",
      cell: (info) => <div className="truncate whitespace-nowrap ">{info.getValue()}</div>,
      maxSize: 280,
    }),
    columnHelper.display({
      id: "actions",
      header: () => "",
      cell: (info) =>
        props.readonly ? null : (
          <div className="flex justify-end">
            <Button variant="danger" size="small" onClick={() => props.onDeselect(info.row.index)}>
              Remove
            </Button>
          </div>
        ),
      size: 1,
    }),
  ]

  return (
    <MultiSelector>
      <div className="flex w-full justify-between gap-4 pb-4">
        {!props.readonly && (
          <MultiSelectorCountryCombobox onSelect={(item) => props.onSelect(item)} selected={props.selected} />
        )}
        <div className="shrink-0">
          <MultiSelectorFilterTextField />
        </div>
      </div>
      {props.selected.length > 0 ? <MultiSelectorTable data={data} columns={columns} /> : null}
    </MultiSelector>
  )
}
