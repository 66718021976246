import { useState } from "react"
import { useDebounce } from "react-use"
import { RouterOutputs } from "../trpc"

export type ChannelWithThumbnail = RouterOutputs["channel"]["findById"] & { thumbnail: { baseUrl: string } }

type ImageSize = {
  width: number
  height: number
}

export interface ChannelThumbnailProps {
  channelId: string
  authToken?: string
  sizes: ImageSize[]
  baseUrl: string
}

const getSmallestSize = (sizes: ImageSize[]) => {
  let bestMatchingSize: ImageSize | undefined = undefined

  sizes.forEach((s) => {
    if (!bestMatchingSize) {
      bestMatchingSize = s
    } else if (s.height <= bestMatchingSize.height && s.width <= bestMatchingSize.width) {
      bestMatchingSize = s
    }
  })

  return bestMatchingSize as ImageSize | undefined
}

export function channelThumbnailUrl(channel: ChannelThumbnailProps) {
  const size = getSmallestSize(channel.sizes)
  const url = new URL("", channel.baseUrl)
  url.searchParams.set("channelId", channel.channelId)

  if (size) {
    url.searchParams.set("width", size.width.toString())
    url.searchParams.set("height", size.height.toString())
  }

  if (channel.authToken) {
    url.searchParams.set("auth.token", channel.authToken)
    url.searchParams.set("auth.type", "jwt")
  }

  return url
}

function ChannelThumbnailInner(channel: ChannelThumbnailProps) {
  const [url, setUrl] = useState(() => {
    const url = channelThumbnailUrl(channel)
    url.searchParams.set("t", Date.now().toString())
    return url
  })

  useDebounce(
    () => {
      const imageToLoad = new Image()
      const newUrl = new URL(url)
      newUrl.searchParams.set("t", Date.now().toString())
      imageToLoad.src = newUrl.toString()
      imageToLoad.onload = () => setUrl(new URL(newUrl))
    },
    5000,
    [url, channel]
  )

  const size = getSmallestSize(channel.sizes)
  const aspect = !size ? "16/9" : `${size.width}/${size.height}`

  return (
    <div
      style={{ backgroundImage: `url(${url.toString()})`, aspectRatio: aspect }}
      className={`mx-auto h-full bg-cover transition-all duration-200`}
    />
  )
}

export function ChannelThumbnail(channel: ChannelThumbnailProps) {
  return <ChannelThumbnailInner {...channel} key={channel.channelId} />
}
