export const formatSampleRate = (sampleRate: number): string => {
  if (sampleRate % 1000 === 0) {
    return `${sampleRate / 1000} kHz` as const
  }

  if (sampleRate > 1000) {
    return `${(sampleRate / 1000).toFixed(1)} kHz` as const
  }

  return `${sampleRate} Hz` as const
}
