import { z } from "zod"

export const CreateSite = z.object({
  name: z.string().min(1).max(100),
  code: z.string().min(4).max(100),
  regionPublicId: z.string(),
  providers: z.string(),
  latitude: z.number().min(-90).max(90),
  longitude: z.number().min(-180).max(180),
  viewerTrafficActive: z.boolean(),
  edgeEgressActive: z.boolean(),
  edgeOriginActive: z.boolean(),
  mediaIngressActive: z.boolean(),
  transcodingActive: z.boolean(),
  notes: z.string().max(500).optional(),
  maxCapacity: z.number().int().positive().nullish(),
})

export type CreateSite = z.infer<typeof CreateSite>
