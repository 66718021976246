import { Expose } from "class-transformer"
import { Rendition } from "../rendition.dto"
import { ChannelDTO } from "./channel.dto"
import { ThumbnailDTO } from "./thumbnail.dto"

export class ChannelWithActiveInfoDTO extends ChannelDTO {
  static readonly modelName = "Channel"

  @Expose()
  isLive!: boolean

  @Expose()
  thumbnail!: ThumbnailDTO

  @Expose()
  renditions!: Rendition[]
}

export class ChannelWithExtendedActiveInfoDTO extends ChannelWithActiveInfoDTO {
  static readonly modelName = "Channel"

  @Expose()
  lastActive?: Date
}
