import { ClientOverrides } from "@core-services/portal"
import { FormControlLabel, TextField, ToggleGroup, ToggleGroupItem, formatBitRate } from "@vindral/components"
import { Controller, useFormContext } from "react-hook-form"
import { integerOrNull } from "../utils/numberOrNull"

export function ClientOverridesForm() {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<{
    clientOverrides: ClientOverrides
  }>()

  return (
    <div className="flex flex-col gap-2">
      <Controller
        control={control}
        name="clientOverrides.maxVideoBitRate"
        render={({ field }) => (
          <div className="flex flex-col gap-1">
            <FormControlLabel error={errors.clientOverrides?.maxVideoBitRate?.message}>
              Maximum Video Bit Rate{Number.isInteger(field.value) ? ` (${formatBitRate(Number(field.value))})` : ""}
            </FormControlLabel>
            <TextField
              value={field.value ?? ""}
              onChange={(e) => field.onChange(integerOrNull(e.target.value))}
              placeholder="No override"
            />
          </div>
        )}
      />

      <div className="flex flex-col gap-1">
        <FormControlLabel error={errors.clientOverrides?.minBufferTime?.message}>
          Minimum Buffer Time (ms)
        </FormControlLabel>
        <TextField
          {...register("clientOverrides.minBufferTime", { setValueAs: integerOrNull })}
          placeholder="No override"
        />
      </div>
      <div className="flex flex-col gap-1">
        <FormControlLabel error={errors.clientOverrides?.maxBufferTime?.message}>
          Maximum Buffer Time (ms)
        </FormControlLabel>
        <TextField
          {...register("clientOverrides.maxBufferTime", { setValueAs: integerOrNull })}
          placeholder="No override"
        />
      </div>
      <Controller
        control={control}
        name="clientOverrides.burstEnabled"
        render={({ field }) => (
          <div className="flex flex-col items-start gap-1">
            <FormControlLabel>Buffer Burst</FormControlLabel>
            <ToggleGroup
              value={field.value == null ? "" : field.value ? "true" : "false"}
              onValueChange={(value) => field.onChange(value === "" ? null : value === "true")}
              type="single"
            >
              <ToggleGroupItem value={"false"}>Disable Buffer Burst</ToggleGroupItem>
              <ToggleGroupItem value={"true"}>Enable Buffer Burst</ToggleGroupItem>
            </ToggleGroup>
          </div>
        )}
      />
      <Controller
        control={control}
        name="clientOverrides.sizeBasedResolutionCapEnabled"
        render={({ field }) => (
          <div className="flex flex-col items-start gap-1">
            <FormControlLabel>Size Based Resolution Cap</FormControlLabel>
            <ToggleGroup
              value={field.value == null ? "" : field.value ? "true" : "false"}
              onValueChange={(value) => field.onChange(value === "" ? null : value === "true")}
              type="single"
            >
              <ToggleGroupItem value={"false"}>Disable Resolution Cap</ToggleGroupItem>
              <ToggleGroupItem value={"true"}>Enable Resolution Cap</ToggleGroupItem>
            </ToggleGroup>
          </div>
        )}
      />
      <Controller
        control={control}
        name="clientOverrides.separateVideoSocketEnabled"
        render={({ field }) => (
          <div className="flex flex-col items-start gap-1">
            <FormControlLabel>Separate Video Socket</FormControlLabel>
            <ToggleGroup
              value={field.value == null ? "" : field.value ? "true" : "false"}
              onValueChange={(value) => field.onChange(value === "" ? null : value === "true")}
              type="single"
            >
              <ToggleGroupItem value={"false"}>Disable Separate Socket</ToggleGroupItem>
              <ToggleGroupItem value={"true"}>Enable Separate Socket</ToggleGroupItem>
            </ToggleGroup>
          </div>
        )}
      />
    </div>
  )
}
