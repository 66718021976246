import { createColumnHelper } from "@tanstack/react-table"
import { Button } from "@vindral/components"
import { OrganizationItem } from "../../interfaces/Organization"
import { trpc } from "../../trpc"
import { MultiSelectorOrganizationCombobox } from "./MultiSelectOrganizationCombobox"
import { MultiSelector, MultiSelectorFilterTextField, MultiSelectorTable } from "./MultiSelector"

export interface OrganizationMultiSelectorProps {
  onSelect: (item: OrganizationItem) => void
  onDeselect: (index: number) => void
  selected: { publicId: string }[]
  nothingSelectedText: string
}

export function OrganizationMultiSelector(props: OrganizationMultiSelectorProps) {
  const { onSelect, onDeselect, selected, nothingSelectedText } = props

  const { data: organizationNames = [] } = trpc.organization.findIdentifiersByIds.useQuery({
    publicIds: selected.map((s) => s.publicId),
  })
  const getOrganizationName = (index: number) => organizationNames[index]?.name || ""

  const selectedOrganizationWithName = selected.map((c, index) => ({
    ...c,
    organizationName: getOrganizationName(index),
  }))

  const columnHelper = createColumnHelper<typeof selectedOrganizationWithName[0]>()
  const columns = [
    columnHelper.accessor("organizationName", {
      header: () => "Name",
      cell: (info) => <div className="flex gap-2 whitespace-nowrap">{info.getValue()}</div>,
    }),
    columnHelper.display({
      id: "actions",
      header: () => "",
      cell: (info) => (
        <div className="flex justify-end">
          <Button variant="danger" size="small" onClick={() => onDeselect(info.row.index)}>
            Remove
          </Button>
        </div>
      ),
      size: 1,
    }),
  ]
  const hasSelectedAtLeastOneItem = selected.length > 0

  return (
    <MultiSelector>
      <div className="flex justify-between gap-4 pb-4">
        <MultiSelectorOrganizationCombobox onSelect={onSelect} selected={selected} />
        <div className="shrink-0">
          <MultiSelectorFilterTextField />
        </div>
      </div>
      {hasSelectedAtLeastOneItem ? (
        <MultiSelectorTable data={selectedOrganizationWithName} getRowId={(row) => row.publicId} columns={columns} />
      ) : (
        <div className="flex flex-col gap-2 py-4">{nothingSelectedText}</div>
      )}
    </MultiSelector>
  )
}
