import { subject } from "@casl/ability"
import { Action } from "@core-services/data-types"
import { AppAbility, UpdateChannel } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { Label } from "@vindral/components"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { SaveButton } from "../../../atoms/SaveButton"
import { RouteBlockDialog } from "../../../molecules/RouteBlockDialog"
import { ChannelFindById } from "../ChannelEdit"
import { ChannelEditTranscoding } from "./ChannelEditTranscoding"

const resolver = zodResolver(UpdateChannel)

export interface ChannelEditDetailsTranscodingProps {
  ability: AppAbility
  channel: ChannelFindById
  onSave: (channel: UpdateChannel) => Promise<void>
}

export function ChannelEditDetailsTranscoding({ ability, channel, onSave }: ChannelEditDetailsTranscodingProps) {
  const [invalidPackagerConfiguration, setInvalidPackagerConfiguration] = useState(false)
  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting, isSubmitSuccessful },
    reset,
  } = useForm<UpdateChannel>({
    resolver,
    mode: "onChange",
    defaultValues: {
      channelId: channel.channelId,
      packagerConfiguration: channel.packagerConfiguration ? channel.packagerConfiguration : undefined,
    },
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful, reset])

  return (
    <div className="py-4">
      <RouteBlockDialog condition={isDirty && !isSubmitting} />
      <form onSubmit={handleSubmit(onSave)} className="flex flex-col gap-4">
        <ChannelEditTranscoding
          control={control}
          setError={(hasError) => {
            setInvalidPackagerConfiguration(hasError)
          }}
        />
        <Label error={invalidPackagerConfiguration ? "Invalid configuration" : ""} />
        {ability.can(Action.Update, subject("Channel", channel)) && (
          <div>
            <SaveButton text="Save" disabled={!isDirty || !isValid || invalidPackagerConfiguration} />
          </div>
        )}
      </form>
    </div>
  )
}
