import { Light, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from "@vindral/components"
import { StatusTitle } from "./StatusTitle"

interface SiteCapabilityNodesProps {
  type: string
  hosts: string[] | undefined
}

export function SiteCapabilityNodes({ type, hosts }: SiteCapabilityNodesProps) {
  const color = hosts && hosts.length > 0 ? "green" : "red"

  if (!hosts || hosts.length === 0) {
    return (
      <div>
        <Tooltip>
          <TooltipTrigger>
            <StatusTitle>NODES</StatusTitle>
            <Light color={color} />
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>Site does NOT have {type} nodes</TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </div>
    )
  }

  return (
    <div>
      <Tooltip>
        <TooltipTrigger>
          <StatusTitle>NODES</StatusTitle>
          <Light color={color} />
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent>
            <p className="pb-2">
              Site has {hosts.length} {type} node{hosts.length > 1 && "s"}
            </p>
            <ul>
              {hosts.map((host) => (
                <li key={host}>{host}</li>
              ))}
            </ul>
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </div>
  )
}
