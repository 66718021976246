import { Link, useRouter } from "@tanstack/react-router"

export function Breadcrumbs() {
  const router = useRouter()

  const breadcrumbs = router.state.currentMatches
    .map((match) => {
      return {
        title: match.routeContext?.pageTitle,
        match: match,
      }
    })
    .filter((match) => !!match.title)

  return (
    <div className="flex">
      {breadcrumbs.map((bc, index) => {
        const isLastItem = index === breadcrumbs.length - 1
        return (
          <div key={bc.title}>
            <Link
              activeOptions={{ exact: true }}
              to={bc.match.route.fullPath}
              params={bc.match.params}
              inactiveProps={{ className: "text-lg font-medium text-fg-subtle" }}
              activeProps={{ className: "text-lg font-medium text-fg" }}
            >
              {bc.title}
            </Link>
            <span className="px-2 text-lg">{isLastItem ? "" : "/"}</span>
          </div>
        )
      })}
    </div>
  )
}
