import { Link } from "@tanstack/react-router"
import { useContext, useEffect, useMemo, useRef } from "react"
import { getSearchResultRoute, getSearchResultUrlNew } from "./common"
import { SearchContext, SearchResultItem, getSearchResultId } from "./context"

const getIcon = (resultType: string) => {
  const route = getSearchResultRoute(resultType)
  return route?.icon || <></>
}

export const SearchResultRow = ({ result, type }: { result: SearchResultItem; type: string }) => {
  const context = useContext(SearchContext)

  const icon = useMemo(() => getIcon(type), [type])
  const id = getSearchResultId(result)
  const active = id === context.focusedRowId
  const url = getSearchResultUrlNew(result)

  const myRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (active && myRef.current) {
      myRef.current.scrollIntoView({ block: "nearest" })
    }
  }, [active])

  return (
    <Link to={url} search={{}} params={{}} data-active={active}>
      <div
        ref={myRef}
        className={`-mx-2 -my-1 flex items-center gap-2 p-2 ${
          active ? "bg-component-active" : "hover:bg-component-hover"
        }`}
      >
        <div className="h-4 w-4">{icon}</div>
        <div className="truncate">{result.name}</div>
      </div>
    </Link>
  )
}
