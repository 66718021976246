import { UpdateChannel } from "@core-services/portal"
import Editor, { Monaco } from "@monaco-editor/react"
import { Control, Controller } from "react-hook-form"
import { useVindralTheme } from "../../../contexts/theme"
import { configSchema } from "./configSchema"

interface Props {
  control: Control<UpdateChannel>
  setError: (hasError: boolean) => void
}

export const ChannelEditTranscoding = (props: Props): JSX.Element => {
  const { appliedTheme } = useVindralTheme()

  const handleEditorWillMount = (monaco: Monaco) => {
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      schemas: [
        {
          fileMatch: ["**"],
          uri: "http://vindral/packager-config.json",
          schema: configSchema,
        },
      ],
    })
  }

  const editorOptions = {
    lineNumbers: "off",
    minimap: {
      enabled: false,
    },
  } as const

  return (
    <Controller
      control={props.control}
      name="packagerConfiguration"
      render={({ field }) => (
        <Editor
          saveViewState
          height="500px"
          defaultLanguage="json"
          onChange={(value) => {
            try {
              if (value?.trim().length) {
                field.onChange(JSON.parse(value))
              } else {
                field.onChange(null)
              }
              props.setError(false)
            } catch (error) {
              props.setError(true)
              field.onChange(null)
            }
          }}
          theme={appliedTheme === "dark" ? "vs-dark" : "light"}
          defaultValue={field.value ? JSON.stringify(field.value, null, 2) : ""}
          options={editorOptions}
          beforeMount={handleEditorWillMount}
        />
      )}
    />
  )
}
