import { RegisterOrganization as RegisterOrganizationType } from "@core-services/portal"
import { Route } from "@tanstack/react-router"
import { toast } from "@vindral/components"
import { router } from "../../router"
import { MaxOrganizationsReached } from "../../templates/register-organization/MaxOrganizationsReached"
import { RegisterOrganization } from "../../templates/register-organization/RegisterOrganization"
import { trpc } from "../../trpc"
import { noNavRoute } from "../no-nav"

export const registerOrganizationRoute = new Route({
  getParentRoute: () => noNavRoute,
  preSearchFilters: [(search) => search],
  path: "register-organization",
  component: Page,
  getContext: () => {
    return {
      pageTitle: "Register Organization",
    }
  },
  onLoad: async ({ context: { trpcContext } }) => {
    const profile = await trpcContext.user.profile.ensureData(undefined)
    const organization = profile.accessPermissions[0]?.organization
    if (organization) {
      void router.navigate({
        to: "/",
      })
    }
    await trpcContext.organization.maxLimitReached.ensureData()
  },
})

function Page() {
  const context = trpc.useContext()
  const { data: maxLimitReached } = trpc.organization.maxLimitReached.useQuery()
  if (maxLimitReached) {
    return <MaxOrganizationsReached />
  }

  const createOrganizationMutation = trpc.organization.register.useMutation({
    onSuccess: async () => {
      toast({ title: "Registered successfully!" })
      await context.user.profile.refetch()
      void router.navigate({
        to: "/",
      })
    },
    onError: (error) => {
      toast({ title: "Failed to Register!", description: error.message })
    },
  })

  const registerOrganization = (args: RegisterOrganizationType) => {
    createOrganizationMutation.mutate({ ...args })
  }

  return <RegisterOrganization registerOrganization={registerOrganization} />
}
