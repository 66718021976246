const KILO_BIT = 1000
const MEGA_BIT = KILO_BIT * 1000
const GIGA_BIT = MEGA_BIT * 1000

const formatNumber = (numerator: number, denominator: number, decimals: number) => {
  if (numerator % denominator === 0) {
    return numerator / denominator
  }

  return (numerator / denominator).toFixed(decimals)
}

export const formatBitRate = (bitRate: number, decimals = 1): string => {
  if (bitRate >= GIGA_BIT) {
    return `${formatNumber(bitRate, GIGA_BIT, decimals)} Gbit/s`
  }

  if (bitRate >= MEGA_BIT) {
    return `${formatNumber(bitRate, MEGA_BIT, decimals)} Mbit/s`
  }

  if (bitRate >= KILO_BIT) {
    return `${formatNumber(bitRate, KILO_BIT, decimals)} Kbit/s`
  }

  return `${bitRate} bit/s`
}
