import { cva, VariantProps } from "class-variance-authority"

const spinner = cva(
  [
    "inline-block",
    "animate-spin",
    "rounded-full",
    "border-solid",
    "border-current",
    "border-r-transparent",
    "align-[-0.125em]",
    "motion-reduce:animate-[spin_1.5s_linear_infinite]",
  ],
  {
    variants: {
      size: {
        small: ["h-4", "w-4", "border-2"],
        medium: ["h-8", "w-8", "border-4"],
        large: ["h-12", "w-12", "border-4"],
      },
      color: {
        blue: ["text-blue-9"],
        text: [],
      },
    },
  }
)

type SpinnerProps = VariantProps<typeof spinner>

export function Spinner({ size = "medium", color = "blue" }: SpinnerProps) {
  return <div className={spinner({ size, color })} />
}
