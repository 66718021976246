import {
  AudioMetadata,
  VideoMetadata,
  formatFrameRate,
  getAudioMetadata,
  getVideoMetadata,
} from "@core-services/data-types"
import { IngestStatus } from "@core-services/portal"
import { formatCodec, formatSampleRate } from "@vindral/components"
import { StatusTitle } from "../atoms/StatusTitle"

export interface IngestInfoProps {
  ingestStatus: IngestStatus
}

const Video = ({ videoMetadata }: { videoMetadata: VideoMetadata[] }) => {
  if (!videoMetadata) {
    return <span>N/A</span>
  }

  const videoMeta = videoMetadata.map((m) => {
    const videoSize =
      m.width && m.height && m.frameRate ? `${m.width}x${m.height}@${formatFrameRate(m.frameRate)}` : "No video"
    const info = [formatCodec(m.codec ?? "-"), m.profile, m.level, videoSize].filter((a) => !!a).join(" ")

    return <div>{info}</div>
  })

  return <div>{videoMeta}</div>
}

const Audio = ({ audioMetadata }: { audioMetadata: AudioMetadata[] }) => {
  if (!audioMetadata) {
    return <span>N/A</span>
  }

  const audioMeta = audioMetadata.map((m) => {
    const sampleRate = m.sampleRate ? formatSampleRate(m.sampleRate) : undefined
    const info = [formatCodec(m.codec ?? "-"), m.profile, sampleRate].filter((a) => !!a).join(" ")

    return <div>{info}</div>
  })

  return <div>{audioMeta}</div>
}

export function IngestInfo({ ingestStatus }: IngestInfoProps) {
  const videoMetadata = ingestStatus.metadata ? getVideoMetadata(ingestStatus.metadata) : []
  const audioMetadata = ingestStatus.metadata ? getAudioMetadata(ingestStatus.metadata) : []

  return (
    <div className="p-2">
      <StatusTitle>Metadata</StatusTitle>
      <span className="items-center gap-2">
        <span className="block text-ellipsis">
          <span className="tabular-nums">
            <Video videoMetadata={videoMetadata} />
          </span>
        </span>
        <span className="block text-ellipsis">
          <span className="tabular-nums">
            <Audio audioMetadata={audioMetadata} />
          </span>
        </span>
      </span>
    </div>
  )
}
