import { AccessPermissionRole, OrganizationBillingStatus } from "@core-services/data-types"
import { CreateUser } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { FormControlLabel, TextField } from "@vindral/components"
import { useFieldArray, useForm } from "react-hook-form"
import { FormSection } from "../../atoms/FormSection"
import { Page, PageContainer, PageContent, PageHeader } from "../../atoms/Page"
import { SaveButton } from "../../atoms/SaveButton"
import { SaveSection } from "../../atoms/SaveSection"
import { Breadcrumbs } from "../../molecules/Breadcrumbs"
import { AccessPermissionRoleMultiSelector } from "../../organisms/multiselect/AccessPermissionsMultiSelector"

type CreateUserForm = Omit<CreateUser, "accessPermissions"> & {
  accessPermissions: {
    organization: { publicId: string; name: string; billingStatus: OrganizationBillingStatus }
    role: AccessPermissionRole
  }[]
}

const resolver = zodResolver(CreateUser)

export const UserCreate = ({ createUser }: { createUser: (value: CreateUser) => Promise<void> }) => {
  const form = useForm<CreateUserForm>({
    resolver,
    mode: "onChange",
    defaultValues: {
      email: "",
      name: "",
      password: "",
      accessPermissions: [],
    },
  })
  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isDirty, isValid },
  } = form

  const accessPermissions = useFieldArray({ control, name: "accessPermissions" })

  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
          </div>
        </PageHeader>
        <PageContainer>
          <form onSubmit={handleSubmit(createUser)} autoComplete="off" className="flex flex-col items-start gap-8 py-4">
            <FormSection title="General" description="Change general settings">
              <div className="flex flex-col items-start gap-4">
                <div className="flex w-96 flex-col gap-1">
                  <FormControlLabel required error={errors.name?.message}>
                    Full name
                  </FormControlLabel>
                  <TextField {...register("name")} />
                </div>

                <div className="flex w-96 flex-col gap-1">
                  <FormControlLabel required error={errors.email?.message}>
                    E-mail
                  </FormControlLabel>
                  <TextField {...register("email")} />
                </div>

                <div className="flex w-96 flex-col gap-1">
                  <FormControlLabel required error={errors.password?.message}>
                    Password
                  </FormControlLabel>
                  <TextField
                    {...register("password", {
                      setValueAs: (value: string) => (value?.length > 0 ? value : undefined),
                    })}
                    type="password"
                    autoComplete="new-password"
                  />
                </div>
              </div>
            </FormSection>

            <FormSection title="Permissions" description="Change which organizations the user can access">
              <AccessPermissionRoleMultiSelector
                onSelect={accessPermissions.append}
                onDeselect={accessPermissions.remove}
                onUpdate={accessPermissions.update}
                selected={accessPermissions.fields}
              />
            </FormSection>
            <SaveSection>
              <SaveButton text="Save" disabled={!isDirty || !isValid} />
            </SaveSection>
          </form>
        </PageContainer>
      </PageContent>
    </Page>
  )
}
