import { cva } from "class-variance-authority"

export const itemClasses = cva([
  "flex h-7 cursor-pointer select-none items-center justify-between gap-4 rounded-sm px-2 outline-none data-[disabled]:cursor-auto data-[highlighted]:bg-component-hover data-[disabled]:text-fg-extra-subtle",
])
export const contentClasses = cva([
  "min-w-[200px]",
  "z-50",
  "rounded",
  "border",
  "border-divider",
  "bg-canvas-2",
  "p-1",
  "shadow-xl",
  "animate-in",
  "fade-in",
  "zoom-in-95",
  "data-[state=closed]:animate-out",
  "data-[state=closed]:fade-out",
  "data-[state=closed]:zoom-out-95",
])
export const separatorClasses = cva(["-mx-1 my-1 h-px bg-divider"])
