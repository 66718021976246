import { denyNothingLimitation } from "@core-services/data-types"
import { UpdateSite } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect } from "react"
import { useController, useFieldArray, useForm } from "react-hook-form"
import { z } from "zod"
import { FormSection } from "../../../../atoms/FormSection"
import { SaveButton } from "../../../../atoms/SaveButton"
import { SaveSection } from "../../../../atoms/SaveSection"
import { LimitationModeSelect } from "../../../../molecules/LimitationModeSelect"
import { ChannelMultiSelector } from "../../../../organisms/multiselect/ChannelMultiSelector"
import { SiteEditPageProps } from "../../../../routes/site/site-edit"

const resolver = zodResolver(UpdateSite.merge(z.object({ channelLimitation: z.any() })))

export const SiteEditChannelRules = ({
  site,
  updateSite,
  limitedChannels,
}: SiteEditPageProps & { limitedChannels: { channelId: string; name: string; id: number }[] }) => {
  const channelLimitation = site.channelLimitation ?? denyNothingLimitation
  const form = useForm({
    resolver,
    mode: "onChange",
    defaultValues: {
      ...site,
      channelLimitation: {
        mode: channelLimitation.mode,
        limited: limitedChannels.map((l) => ({ internalId: l.id, channelId: l.channelId })),
      },
    },
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, isSubmitSuccessful },
  } = form

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful, reset])

  const channelLimitationMode = useController({ control, name: "channelLimitation.mode" })
  const channelFieldArray = useFieldArray({ control, name: "channelLimitation.limited" })

  return (
    <form
      className="flex flex-col gap-4 pt-4"
      onSubmit={handleSubmit((site) => {
        site.publicId
        return updateSite({
          ...site,
          channelLimitation: {
            mode: site.channelLimitation.mode,
            limited: site.channelLimitation.limited.map((limited) => limited.internalId),
          },
        })
      })}
    >
      <FormSection title="Channel Limitation" description="Limit which channels may use this site.">
        <div className="flex flex-col gap-4">
          <div>
            <LimitationModeSelect
              value={channelLimitationMode.field.value}
              onValueChange={(newMode) => channelLimitationMode.field.onChange(newMode)}
            />
          </div>
          <div
            className={
              channelLimitationMode.field.value === "disabled" ? "pointer-events-none select-none opacity-50" : ""
            }
          >
            <ChannelMultiSelector
              onSelect={(item) => channelFieldArray.prepend({ internalId: item.id, channelId: item.channelId })}
              onDeselect={(index) => channelFieldArray.remove(index)}
              selected={channelFieldArray.fields}
              nothingSelectedText="Select channels from the dropdown to apply selected limitation to them"
            />
          </div>
        </div>
      </FormSection>
      <SaveSection>
        <SaveButton text="Save" disabled={!isDirty || !isValid} />
      </SaveSection>
    </form>
  )
}
