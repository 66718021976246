import { zodResolver } from "@hookform/resolvers/zod"
import { CalendarIcon } from "@radix-ui/react-icons"
import {
  Button,
  DateRangePicker,
  Divider,
  FormControlLabel,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SelectedDates,
  TextField,
} from "@vindral/components"
import { differenceInDays, format, parse, startOfMonth, subMonths } from "date-fns"
import { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { AnalyticsDateRange, allDateRanges, dateRangeToString } from "./dateRanges"

function parseDate(dateString: string): Date | undefined {
  const date = parse(dateString, DATE_FORMAT, new Date())

  return isNaN(date.getTime()) ? undefined : date
}

const MAX_DATE_RANGE_DAYS = 31
const DATE_FORMAT = "yyyy-MM-dd HH:mm"
const formatDate = (date: Date | undefined) => (date ? format(date, DATE_FORMAT) : "")
const absoluteDatesLabel = (from: Date, to: Date) => `${formatDate(from)} - ${formatDate(to)}`
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
const now = new Date()
const startOf1MonthsAgo = startOfMonth(subMonths(now, 1))
const DateRangeFormInner = z.object({
  from: z.string().refine((x) => !!parseDate(x), "Invalid date"),
  to: z.string().refine((x) => !!parseDate(x), "Invalid date"),
  rangeError: z.void().optional(),
})
const DateRangeForm = DateRangeFormInner.superRefine((val, ctx) => {
  const from = typeof val.from === "string" ? parseDate(val.from) : val.from
  const to = typeof val.to === "string" ? parseDate(val.to) : val.to

  if (!from || !to) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Expected format: yyyy-MM-dd HH:mm",
    })

    return z.NEVER
  }

  if (to.getTime() < from.getTime()) {
    ctx.addIssue({
      path: ["rangeError"],
      code: z.ZodIssueCode.custom,
      message: "To date must be after from date.",
    })

    return z.NEVER
  }

  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  tomorrow.setHours(0, 0, 0, 0)
  if (to.getTime() > tomorrow.getTime()) {
    ctx.addIssue({
      path: ["rangeError"],
      code: z.ZodIssueCode.custom,
      message: "Range may not be in the future.",
    })

    return z.NEVER
  }

  if (from.getTime() < startOf1MonthsAgo.getTime()) {
    ctx.addIssue({
      path: ["rangeError"],
      code: z.ZodIssueCode.custom,
      message: `First allowed date: ${format(startOf1MonthsAgo, "yyyy-MM-dd")}`,
    })

    return z.NEVER
  }

  const rangeInDays = differenceInDays(to.getTime(), from.getTime())
  if (rangeInDays > MAX_DATE_RANGE_DAYS) {
    ctx.addIssue({
      path: ["rangeError"],
      code: z.ZodIssueCode.custom,
      message: `Max time range: ${MAX_DATE_RANGE_DAYS} days.`,
    })

    return z.NEVER
  }

  return val
})

type DateRangeForm = z.infer<typeof DateRangeForm>
const resolver = zodResolver(DateRangeForm)

export function DateRangeSelect(props: {
  defaultValue: AnalyticsDateRange
  onSelect: (dateRange: AnalyticsDateRange) => void
}) {
  const [open, setOpen] = useState(false)
  const defaultLabel = useMemo(() => {
    return props.defaultValue.type === "quick"
      ? dateRangeToString(props.defaultValue.range)
      : absoluteDatesLabel(props.defaultValue.range.from, props.defaultValue.range.to)
  }, [props.defaultValue])
  const { formState, handleSubmit, register, setValue, reset, watch, trigger } = useForm<DateRangeForm>({
    resolver,
    mode: "onChange",
    defaultValues: {
      from: undefined,
      to: undefined,
    },
  })

  const onSubmit = (range: DateRangeForm) => {
    const from = typeof range.from === "string" ? parseDate(range.from) : range.from
    const to = typeof range.to === "string" ? parseDate(range.to) : range.to

    if (!from || !to) {
      // If the user has entered a date that is not valid, the form should not be submitted.
      return
    }

    props.onSelect({
      type: "absolute",
      range: {
        from,
        to,
      },
    })
    setOpen(false)
  }

  useEffect(() => {
    const subscription = watch(() => formState.isDirty && trigger())
    return () => subscription.unsubscribe()
  }, [formState, watch, trigger])

  const from = watch("from")
  const to = watch("to")

  return (
    <Popover
      open={open}
      onOpenChange={(open) => {
        setOpen(open)

        if (!formState.isValid) {
          reset()
        }
      }}
    >
      <PopoverTrigger asChild>
        <Button className={"w-[300px] justify-start text-left font-normal"}>
          <CalendarIcon className="mr-2 h-4 w-4" />
          {defaultLabel}
        </Button>
      </PopoverTrigger>
      <PopoverContent sideOffset={4} className="w-auto p-0" align="start">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex">
            <div className="flex-col p-2">
              <h3 className="pb-2">Quick time range</h3>
              {allDateRanges.map((dateRange) => (
                <div className="w-full" key={dateRange}>
                  <Button
                    variant="list"
                    fullWidth
                    active={props.defaultValue.type === "quick" && props.defaultValue.range === dateRange}
                    onClick={() => {
                      props.onSelect({ type: "quick", range: dateRange })
                      reset()
                      setOpen(false)
                    }}
                  >
                    <span className="block truncate text-left max-2xl:max-w-[120px]">
                      {dateRangeToString(dateRange)}
                    </span>
                  </Button>
                </div>
              ))}
            </div>
            <Divider variant="vertical" />
            <div className="flex h-auto flex-col justify-between p-2">
              <div>
                <h3 className="pb-2">Absolute time range</h3>
                <div className="grid gap-1">
                  <DateRangePicker
                    selectedDates={{
                      from: from ? parseDate(from) : undefined,
                      to: to ? parseDate(to) : undefined,
                    }}
                    fromDate={startOf1MonthsAgo}
                    toDate={now}
                    maxDays={MAX_DATE_RANGE_DAYS}
                    onSelect={(value: SelectedDates) => {
                      setValue("from", formatDate(value.from), { shouldDirty: true, shouldValidate: true })
                      setValue("to", formatDate(value.to), { shouldDirty: true, shouldValidate: true })
                    }}
                  />
                  <FormControlLabel error={formState.errors.from?.message}>From</FormControlLabel>
                  <TextField {...register("from")} error={!!formState.errors.from?.message} />
                  <FormControlLabel error={formState.errors.to?.message}>To</FormControlLabel>
                  <TextField {...register("to")} error={!!formState.errors.to?.message} />
                  <Button disabled={formState.isSubmitting || !formState.isValid} type="submit" variant="primary">
                    Apply time range
                  </Button>
                  <FormControlLabel error={formState.errors.rangeError?.message}></FormControlLabel>
                </div>
              </div>
              <div className="text-sm text-fg-subtle">{`${timezone} ${format(new Date(), "OOOO")}`}</div>
            </div>
          </div>
        </form>
      </PopoverContent>
    </Popover>
  )
}
