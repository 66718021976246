import { SiteItem } from "@core-services/portal"
import { useMemo, useState } from "react"
import { trpc } from "../../trpc"
import { MultiSelectorCombobox } from "./MultiSelector"

function getNextPageParam<T>(lastPage: { total: number }, allPages: { total: number; items: T[] }[]) {
  const nextSkip = allPages.reduce((prev, page) => prev + page.items.length, 0)
  return nextSkip < lastPage.total ? nextSkip : undefined
}

export interface MultiSelectorSiteCombobox {
  onSelect: (item: SiteItem) => void
  selected: { code: string }[]
}

export function MultiSelectorSiteCombobox(props: MultiSelectorSiteCombobox) {
  const [search, onSearchChange] = useState("")
  const query = trpc.site.findMany.useInfiniteQuery(
    { orderBy: "name", search: search.length > 0 ? search : undefined },
    { getNextPageParam, keepPreviousData: true }
  )
  const allPages = useMemo(() => query.data?.pages.flatMap((page) => page.items) ?? [], [query.data])
  const selected = useMemo(() => new Set(props.selected.map((f) => f.code)), [props.selected])

  return (
    <MultiSelectorCombobox
      idField="code"
      selected={selected}
      options={[{ id: "sites", title: "sites", items: allPages }]}
      onSelect={(item) => props.onSelect(item)}
      renderItem={(item) => <div>{item.code}</div>}
      onReachedEnd={query.fetchNextPage}
      isLoading={query.isLoading}
      search={search}
      onSearchChange={onSearchChange}
    >
      Add site
    </MultiSelectorCombobox>
  )
}
