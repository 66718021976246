import { cva, VariantProps } from "class-variance-authority"
import { ButtonHTMLAttributes, forwardRef, ReactNode } from "react"
import { useShortcut } from "./Shortcut"
import { Tooltip, TooltipContent, TooltipTrigger } from "./Tooltip"

const defaultVariants = {
  size: "medium",
  variant: "secondary",
} as const

export const buttonVariants = cva(
  [
    "inline-flex",
    "cursor-pointer",
    "items-center",
    "gap-1",
    "rounded-sm",
    "tracking-wider",
    "font-medium",
    "shadow-sm",
    "dark:shadow-outline",
    "shadow-black/10",
    "outline-none",
    "transition-all",
    "disabled:cursor-not-allowed",
    "disabled:opacity-60",
    "focus:ring-1",
    "ring-offset-canvas-1",
    "ring-offset-1",
    "focus:ring-component",
  ],
  {
    variants: {
      variant: {
        primary: [
          "justify-center",
          "border",
          "bg-component-primary",
          "text-fg-primary",
          "enabled:hover:bg-component-primary-hover",
          "enabled:active:bg-component-primary-active",
          "border-divider-primary-interactive",
        ],
        secondary: [
          "justify-center",
          "border",
          "bg-canvas-3",
          "border-divider",
          "enabled:hover:border-divider-active",
          "enabled:active:bg-component-active",
        ],
        tertiary: [
          "justify-center",
          "shadow-none",
          "dark:shadow-none",
          "bg-component",
          "enabled:hover:bg-component-hover",
          "enabled:active:bg-component-active",
        ],
        danger: [
          "justify-center",
          "border",
          "bg-component-danger",
          "text-fg-danger",
          "border-divider-danger-interactive",
          "enabled:hover:bg-component-danger-hover",
          "enabled:active:bg-component-danger-active",
        ],
        appeal: [
          "justify-center",
          "border",
          "bg-component-positive-hover",
          "border-divider-positive",
          "text-fg-positive-subtle",
          "enabled:hover:border-divider-positive-active",
          "enabled:active:bg-component-positive-active",
          "ring-offset-component-positive",
        ],
        list: [
          "justify-start",
          "shadow-none",
          "dark:shadow-none",
          "enabled:hover:bg-component-primary-hover",
          "enabled:active:bg-component-primary-active",
        ],
      },
      size: {
        small: ["h-6", "px-2", "text-sm"],
        medium: ["h-7", "px-3", "text-sm"],
        large: ["h-8", "px-4", "text-base"],
      },
      fullWidth: {
        true: ["w-full"],
      },
      active: {
        true: ["bg-component-active"],
      },
    },
    defaultVariants,
  }
)

export type ButtonProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, "onClick"> &
  VariantProps<typeof buttonVariants> & {
    children?: ReactNode
    tooltip?: boolean
    shortcut?: string
    onClick?: () => void
  }

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, size, variant, fullWidth, shortcut, active, ...rest }, ref) => {
    const className = buttonVariants({ size, variant, fullWidth, active })
    const { Shortcut } = useShortcut({
      shortcut: shortcut ?? "",
      callback: () => rest.onClick?.(),
      options: { enabled: !!shortcut },
    })

    if (!shortcut) {
      return (
        <button type="button" ref={ref} {...rest} className={className}>
          {children}
        </button>
      )
    }

    return (
      <Tooltip open={rest.disabled ? false : undefined}>
        <TooltipTrigger asChild>
          <button type="button" ref={ref} {...rest} className={className}>
            {children}
          </button>
        </TooltipTrigger>
        <TooltipContent sideOffset={4}>
          <Shortcut />
        </TooltipContent>
      </Tooltip>
    )
  }
)

Button.displayName = "Button"
