import { COUNTRIES, LimitationMode } from "@core-services/data-types"
import { z } from "zod"

export const StringLimitation = z.object({
  mode: z.nativeEnum(LimitationMode),
  limited: z.array(z.string()),
})

export const NumberLimitation = z.object({
  mode: z.nativeEnum(LimitationMode),
  limited: z.array(z.number()),
})

const countryCodes = COUNTRIES.map((country) => country.code) as ReadonlyArray<string>

export const CountryCodeLimitation = z.object({
  mode: z.enum(["allow", "deny", "disabled"]).describe("The mode of the viewer country limitation."),
  limited: z
    .array(z.string().refine((val) => countryCodes.includes(val), { message: `Invalid country code` }))
    .describe("The list of countries to allow or deny, in two letter ISO 3166-1 format."),
})
