import { Divider, formatBytes } from "@vindral/components"
import { Statistic } from "../../molecules/Statistic"
import { trpc } from "../../trpc"
import { isDefined } from "../../utils/is-defined"
import { AnalyticsQueryArgs } from "./Analytics"
import { EgressGraph } from "./graphs/Egress"
import { IngressGraph } from "./graphs/Ingress"

const TrafficStatistics = (props: AnalyticsQueryArgs) => {
  const { time } = props
  const lookback = time.to - time.from
  const getChange = (first: number | undefined, second: number | undefined) => {
    if (typeof first === "undefined" || typeof second === "undefined") {
      return undefined
    }
    return first / second - 1
  }

  const { data: totalEgressBytes } = trpc.analytics.totalEgressBytes.useQuery(
    {
      ...props,
      time: time.to,
      lookback,
    },
    { keepPreviousData: true }
  )
  const { data: previousTotalEgressBytes } = trpc.analytics.totalEgressBytes.useQuery(
    {
      ...props,
      time: time.from,
      lookback,
    },
    { keepPreviousData: true }
  )

  const { data: totalIngressBytes } = trpc.analytics.totalIngressBytes.useQuery(
    {
      ...props,
      time: time.to,
      lookback,
    },
    { keepPreviousData: true }
  )
  const { data: previousTotalIngressBytes } = trpc.analytics.totalIngressBytes.useQuery(
    {
      ...props,
      time: time.from,
      lookback,
    },
    { keepPreviousData: true }
  )

  return (
    <div className="my-4 mb-12 flex flex-col gap-4 xl:flex-row">
      <div className="flex gap-4 xl:w-1/2">
        <div className="w-1/2">
          <Statistic
            value={isDefined(totalEgressBytes) ? formatBytes(totalEgressBytes) : "-"}
            title="EGRESS TOTAL"
            subtext="Total egress over selected period"
            info="Total amount of data transfered to user sessions over the selected time period"
            tooltip="Trend comparing selected time period to earlier adjacent period of same length"
            change={getChange(totalEgressBytes, previousTotalEgressBytes)}
          />
        </div>
        <Divider variant="vertical" />
        <div className="w-1/2">
          <Statistic
            value={isDefined(totalIngressBytes) ? formatBytes(totalIngressBytes) : "-"}
            title="INGRESS TOTAL"
            subtext="Total ingress over selected period"
            info={
              <div>
                <div>Total amount of data transfered to ingest server over the selected time period</div>
                <div>Disregards selected country and region</div>
              </div>
            }
            tooltip="Trend comparing selected time period to earlier adjacent period of same length"
            change={getChange(totalIngressBytes, previousTotalIngressBytes)}
          />
        </div>
      </div>
    </div>
  )
}

export const Traffic = (props: AnalyticsQueryArgs): JSX.Element => {
  return (
    <>
      <TrafficStatistics {...props} />
      <EgressGraph {...props} />
      <div className="pt-8">
        <IngressGraph {...props} />
      </div>
    </>
  )
}
