import { Label } from "@radix-ui/react-label"
import { SwitchProps as RadixSwitchProps, Root, Thumb } from "@radix-ui/react-switch"
import { VariantProps, cva } from "class-variance-authority"

const defaultRootVariants = {
  variant: "regular",
  size: "medium",
} as const

const root = cva(["relative rounded-full p-0.5 transition-all disabled:opacity-60"], {
  variants: {
    variant: {
      regular: ["bg-gray-6", "data-[state=checked]:bg-blue-8"],
      danger: ["bg-tomato-5", "data-[state=checked]:bg-tomato-8"],
    },
    size: {
      small: ["h-4", "w-6"],
      medium: ["h-5", "w-8"],
    },
  },
  defaultVariants: defaultRootVariants,
})

const defaultThumbVariants = {
  size: "medium",
} as const

const thumb = cva(["relative block rounded-full bg-white transition-transform duration-150"], {
  variants: {
    size: {
      small: ["h-3", "w-3", "data-[state=checked]:translate-x-2"],
      medium: ["h-4", "w-4", "data-[state=checked]:translate-x-3"],
    },
  },
  defaultVariants: defaultThumbVariants,
})

export interface SwitchProps extends Omit<RadixSwitchProps, "color">, VariantProps<typeof root> {
  label?: string
}

export function Switch({ variant, size, label, ...rest }: SwitchProps) {
  return (
    <Label className="relative flex items-center gap-2">
      <Root {...rest} className={root({ variant, size })}>
        <Thumb className={thumb({ size })} />
      </Root>
      {label}
    </Label>
  )
}
