const knownCodecs = {
  h264: "H.264",
  av1: "AV1",
  opus: "Opus",
  aac: "AAC",
  mjpeg: "JPEG",
} as Record<string, string>

export const formatCodec = (codec: string) => {
  return knownCodecs[codec] ?? codec
}
