export const ReCaptchaText = () => {
  return (
    <span className="pt-1 text-sm text-fg-extra-subtle">
      This site is protected by reCAPTCHA and the Google{" "}
      <a href="https://policies.google.com/privacy" className="underline" target="_blank">
        Privacy Policy
      </a>{" "}
      and{" "}
      <a href="https://policies.google.com/terms" className="underline" target="_blank">
        Terms of Service
      </a>{" "}
      apply.
    </span>
  )
}
