import { useAbility } from "@casl/react"
import { CreateChannelGroup } from "@core-services/portal"
import { Route, useNavigate, useSearch } from "@tanstack/react-router"
import { toast } from "@vindral/components"
import { useCallback } from "react"
import { channelGroupIndexRoute } from "."
import { AbilityContext } from "../../acl"
import { ChannelGroupCreate } from "../../templates/channel-group/ChannelGroupCreate"
import { trpc } from "../../trpc"

export const channelGroupCreateRoute = new Route({
  getParentRoute: () => channelGroupIndexRoute,
  preSearchFilters: [(search) => search],
  path: "create",
  component: Page,
  getContext: () => {
    return {
      pageTitle: "Create",
    }
  },
})

function Page() {
  const search = useSearch({ from: channelGroupCreateRoute.id })
  const context = trpc.useContext()
  const navigate = useNavigate()
  const ability = useAbility(AbilityContext)
  const createMutation = trpc.channelGroup.create.useMutation({
    onSuccess: (channelGroup) => {
      const channelGroupId = channelGroup.channelGroupId
      context.channelGroup.findByChannelGroupId.setData({ channelGroupId }, channelGroup)

      toast({ title: `Created ${channelGroup.name}` })

      void navigate({
        to: "/channel-groups/edit/$id",
        params: { id: channelGroupId },
        replace: true,
      })
    },
    onError: (error) => {
      toast({ title: "Failed to create!", description: error.message })
    },
  })

  const onCreate = useCallback(
    async (channelGroup: CreateChannelGroup) => {
      createMutation.reset()
      await createMutation.mutateAsync(channelGroup)
    },
    [createMutation]
  )

  return <ChannelGroupCreate organizationId={search.organizationId} onCreate={onCreate} ability={ability} />
}
