import ProgressWithLabel from "../../atoms/ProgressWithLabel"

type ProportionBarsProps = {
  data: {
    title: string
    value: number
  }[]
  max: number
}
export const ProportionBars = (props: ProportionBarsProps) => {
  const sorted = props.data.sort((a, b) => b.value - a.value)
  const total = sorted.reduce((a, b) => a + b.value, 0)
  const getProportion = (value: number) => (value > 0 ? Math.round((value / total) * 100) : 0)
  return (
    <>
      {sorted.slice(0, props.max).map(({ title, value }) => (
        <div key={`${title}-${value}`}>
          {title}
          <ProgressWithLabel color="neutral" progress={getProportion(value)} label={`${getProportion(value)}%`} />
        </div>
      ))}
    </>
  )
}
