import { OrganizationType } from "@core-services/data-types"
import { Type } from "class-transformer"
import { IsBoolean, IsEnum, IsNotEmptyObject, IsOptional, IsString, ValidateNested } from "class-validator"
import { ClientOverridesDTO } from "../client-overrides.dto"
import { ClientTelemetryDTO } from "../client-telemetry.dto"
import { IsEntityName, IsNullable, IsPositiveInteger } from "../common"
import { LimitableDTO } from "../limitable.dto"

export class UpdateOrganizationDTO {
  id!: number

  @IsEntityName()
  name!: string

  @IsEnum(OrganizationType)
  type!: OrganizationType

  @IsNullable()
  @IsPositiveInteger()
  max_streams!: number | null

  @IsNullable()
  @IsPositiveInteger()
  max_viewers!: number | null

  @IsNullable()
  @IsPositiveInteger()
  max_capacity!: number | null

  @IsNullable()
  @IsPositiveInteger()
  max_partner_streams!: number | null

  @IsNullable()
  @IsPositiveInteger()
  max_partner_viewers!: number | null

  @IsNullable()
  @IsPositiveInteger()
  max_partner_capacity!: number | null

  @IsPositiveInteger()
  max_channels!: number

  @IsPositiveInteger()
  max_channel_groups!: number

  @IsPositiveInteger()
  max_transcoding_profiles!: number

  @IsPositiveInteger()
  max_users!: number

  @IsOptional()
  @IsString()
  authSecret!: string

  @IsOptional()
  @IsNotEmptyObject()
  siteLimitation: LimitableDTO = {
    mode: "deny",
    limited: [],
  }

  @IsOptional()
  @IsNullable()
  @ValidateNested()
  @Type(() => ClientOverridesDTO)
  clientOverrides?: ClientOverridesDTO

  @IsOptional()
  @IsNullable()
  @ValidateNested()
  @Type(() => ClientTelemetryDTO)
  clientTelemetry?: ClientTelemetryDTO

  @IsBoolean()
  apiAccessEnabled?: boolean
}
