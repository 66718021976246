import { CallToAction } from "@vindral/components"
import empty from "../templates/empty-page.svg"

interface EmptyStateMessageProps {
  title: string
  subTitle: string
  createButtonText: string
  onClickCreate?: () => void
  subTitleNoCreate: string
}

export function EmptyStateMessage(props: EmptyStateMessageProps) {
  const { onClickCreate } = props
  return (
    <div className="relative flex justify-center pt-40">
      <div className="max-w-2xl">
        {onClickCreate ? (
          <CallToAction
            imageSrc={empty}
            onClick={onClickCreate}
            callToActionText={props.createButtonText}
            title={props.title}
            paragraphs={[props.subTitle]}
          />
        ) : null}
        {!onClickCreate ? (
          <div>
            <h1 className="font-medium">{props.title}</h1>
            <p className="py-1 text-fg-subtle last-of-type:pb-3">{props.subTitleNoCreate}</p>
          </div>
        ) : null}
      </div>
    </div>
  )
}
