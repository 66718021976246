import { ReactNode } from "react"
import { StatusTitle } from "../atoms/StatusTitle"

export interface TitledProps {
  title: string
  children: ReactNode
}

export function Titled({ title, children }: TitledProps) {
  return (
    <div>
      <StatusTitle>{title}</StatusTitle>
      {children}
    </div>
  )
}
