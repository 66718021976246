import * as TabsPrimitive from "@radix-ui/react-tabs"
import { cva, VariantProps } from "class-variance-authority"

import { ComponentProps, ElementRef, forwardRef } from "react"

const tabsList = cva(["flex", "gap-6", "border-b", "border-divider"], {
  variants: {
    border: {
      none: ["border-none"],
    },
  },
})

const tabsTrigger = cva(
  [
    "relative",
    "top-px",
    "flex",
    "border-b-2",
    "border-transparent",
    "py-2",
    "font-medium",
    "tracking-wide",
    "text-fg-subtle",
    "transition-all",
    "data-[state=active]:border-solid-primary",
    "data-[state=active]:text-fg",
  ],
  {
    variants: {
      border: {
        none: ["border-none"],
      },
    },
  }
)

const StyledTabs = forwardRef<ElementRef<typeof TabsPrimitive.Root>, ComponentProps<typeof TabsPrimitive.Root>>(
  (props, ref) => {
    return <TabsPrimitive.Root ref={ref} {...props} className="" />
  }
)
StyledTabs.displayName = "StyledTabs"

const StyledList = forwardRef<
  ElementRef<typeof TabsPrimitive.List>,
  VariantProps<typeof tabsList> & ComponentProps<typeof TabsPrimitive.List>
>(({ border, ...props }, ref) => {
  const className = tabsList({ border })
  return <TabsPrimitive.List ref={ref} {...props} className={className} />
})
StyledList.displayName = "StyledList"

const StyledTrigger = forwardRef<
  ElementRef<typeof TabsPrimitive.Trigger>,
  VariantProps<typeof tabsTrigger> & ComponentProps<typeof TabsPrimitive.Trigger>
>(({ border, ...props }, ref) => {
  const className = tabsTrigger({ border })
  return <TabsPrimitive.Trigger ref={ref} {...props} className={className} />
})
StyledTrigger.displayName = "StyledTrigger"

const StyledContent = forwardRef<
  ElementRef<typeof TabsPrimitive.Content>,
  ComponentProps<typeof TabsPrimitive.Content>
>((props, ref) => {
  return <TabsPrimitive.Content ref={ref} {...props} className="outline-none" />
})
StyledContent.displayName = "StyledContent"

export const Tabs = StyledTabs
export const TabsList = StyledList
export const TabsTrigger = StyledTrigger
export const TabsContent = StyledContent
