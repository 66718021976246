import { Message, MessageSection } from "../../molecules/Message"

export function UpgradeAccountPendingMessage() {
  return (
    <Message severity="appeal">
      <MessageSection>
        <div className="flex flex-col gap-2">
          <span className="font-medium">Evaluation account</span>
          <span>We have received your request to upgrade your account. We will be in touch with you soon.</span>
        </div>
      </MessageSection>
    </Message>
  )
}
