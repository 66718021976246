import { EdgeItem, EdgeItemChannels, UpdateAdapterSpeedRequest } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { Cross1Icon } from "@radix-ui/react-icons"
import { IconAdjustmentsFilled } from "@tabler/icons-react"
import {
  TableOptions,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSwitchItem,
  DropdownMenuTrigger,
  FormControl,
  FormControlLabel,
  TextField,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
  formatBitRate,
  toast,
} from "@vindral/components"
import { useForm } from "react-hook-form"
import { Hyperlink } from "../atoms/Hyperlink"
import ProgressWithLabel from "../atoms/ProgressWithLabel"
import { DebouncedTextField } from "../molecules/DebouncedTextField"
import { trpc } from "../trpc"
import { integerOrNull } from "../utils/numberOrNull"
import { playUrl } from "../utils/urls"
import { InteractiveTable } from "./InteractiveTable"
import { NoResultsFound } from "./NoResultsFound"

const resolver = zodResolver(UpdateAdapterSpeedRequest)

function columnNames(id: string) {
  switch (id) {
    case "hostname":
      return "Hostname"
    case "datacenter":
      return "Datacenter"
    case "originChannels":
      return "Origin Channels"
    case "relayedChannels":
      return "Relayed Channels"
    case "bitRateIn":
      return "Bit Rate In"
    case "bitRateOut":
      return "Bit Rate Out"
    case "ingressCount":
      return "Ingress Count"
    case "ingressLoad":
      return "Ingress Load"
    case "ingressFull":
      return "Ingress Full"
    case "egressLoad":
      return "Egress Load"
    case "egressFull":
      return "Egress Full"
    case "publicWsUrl":
      return "Public Ws Url"
    case "adapterSpeedMbps":
      return "Adapter Speed"
  }

  return id
}

const getOriginTraffic = (channels: EdgeItemChannels) =>
  channels.filter((channel) => channel.isOrigin).sort((v1, v2) => v1.streamKey.localeCompare(v2.streamKey))

const getRelayedTraffic = (channels: EdgeItemChannels) =>
  channels.filter((channel) => !channel.isOrigin).sort((v1, v2) => v1.streamKey.localeCompare(v2.streamKey))

const LoadCell = ({ load }: { load: number }) => <ProgressWithLabel progress={load} label={`${load}%`} />

const BitRateCell = ({ bitRate }: { bitRate: number | undefined }) => (
  <span className="text-fg-subtle">{bitRate ? formatBitRate(bitRate) : "-"}</span>
)

const StreamsCell = ({ channels, highlightStream }: { channels: EdgeItemChannels; highlightStream?: string }) => {
  const getStream = (streamKey: string, highlightStream?: string) => {
    const sx: React.CSSProperties = { textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }

    if (!highlightStream) {
      return (
        <div className="text-fg-subtle" style={sx}>
          {streamKey}
        </div>
      )
    }

    if (!streamKey.includes(highlightStream)) {
      return (
        <div className="text-fg-subtle" style={{ ...sx }}>
          {streamKey}
        </div>
      )
    }

    return <div style={sx}>{streamKey}</div>
  }
  const StreamKeyList = () => (
    <>
      {channels.map((c) => (
        <div key={c.streamKey}>{getStream(c.streamKey, highlightStream)}</div>
      ))}
    </>
  )
  return channels.length > 0 ? (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger
          style={{
            height: `100%`,
            width: `100%`,
            display: "block",
          }}
        >
          <span className="text-fg-subtle">{channels.length}</span>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent side="top">
            <StreamKeyList />
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  ) : (
    <div className="text-center text-fg-subtle">{channels.length}</div>
  )
}

const OriginStreamsCell = ({ channels, highlightStream }: { channels: EdgeItemChannels; highlightStream?: string }) => (
  <StreamsCell channels={getOriginTraffic(channels)} highlightStream={highlightStream} />
)

const RelayedStreamsCell = ({
  channels,
  highlightStream,
}: {
  channels: EdgeItemChannels
  highlightStream?: string
}) => <StreamsCell channels={getRelayedTraffic(channels)} highlightStream={highlightStream} />

const QosLink = ({ channelId, publicWsUrl }: { channelId: string; publicWsUrl: string }) => {
  if (!publicWsUrl) {
    return <></>
  }

  return <Hyperlink href={playUrl({ channelId, edgeUrl: publicWsUrl })} text="QoS" external />
}

export type EdgeServerTableProps = Omit<
  TableOptions<EdgeItem>,
  "columns" | "getCoreRowModel" | "getSortedRowModel" | "getPaginationRowModel"
> & {
  saveColumnVisibility: () => void
  resetColumnVisibilityToDefault: () => void
  testChannelId: string
  globalFilter?: string
}

function SetAdapterSpeedDialog(props: { edge: EdgeItem }) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button>Set</Button>
      </DialogTrigger>
      <AdapterSpeedDialogContent {...props} />
    </Dialog>
  )
}

function AdapterSpeedDialogContent(props: { edge: EdgeItem }) {
  const updateAdapterSpeedMutation = trpc.infrastructure.updateAdapterSpeed.useMutation({
    onSuccess: () => {
      toast({ title: "Adapter speed updated!" })
    },
    onError: (error) => {
      toast({ title: "Failed to update adapter speed!", description: error.message })
    },
  })

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid, errors },
  } = useForm<UpdateAdapterSpeedRequest>({
    resolver,
    mode: "onChange",
    defaultValues: {
      adapterSpeedMbps: props.edge.adapterSpeedMbps,
      hostname: props.edge.hostname,
    },
  })

  return (
    <DialogContent size="small">
      <form onSubmit={handleSubmit((args) => updateAdapterSpeedMutation.mutateAsync(args))}>
        <div className="flex justify-between border-b border-divider p-4">
          <h1 className="font-medium">
            Set adapter speed for <b>{props.edge.hostname}</b>
          </h1>
          <DialogClose>
            <Cross1Icon />
          </DialogClose>
        </div>
        <div className="p-4">
          <div className="flex flex-col gap-4">
            <FormControl>
              <FormControlLabel error={errors.adapterSpeedMbps?.message}>Adapter speed in Mbps</FormControlLabel>
              <TextField
                type="number"
                {...register("adapterSpeedMbps", { setValueAs: integerOrNull })}
                error={!!errors?.adapterSpeedMbps}
              />
            </FormControl>
            <p className="text-sm italic">
              *Takes effect immediately, but values in the table are updated within a few minutes.
            </p>
          </div>
        </div>
        <div className="border-t border-divider p-4 text-right">
          <DialogClose asChild>
            <Button disabled={!isDirty || !isValid} type="submit" variant="primary">
              Set
            </Button>
          </DialogClose>
        </div>
      </form>
    </DialogContent>
  )
}

export function EdgeServerTable(options: EdgeServerTableProps) {
  const { testChannelId, globalFilter } = options
  const columnHelper = createColumnHelper<EdgeItem>()
  const columns = [
    columnHelper.accessor("hostname", {
      header: () => "Hostname",
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor("datacenter", {
      header: () => "Datacenter",
      cell: (info) => <span className="text-fg-subtle">{info.getValue()}</span>,
    }),
    columnHelper.accessor("channels", {
      id: "originChannels",
      header: () => "Origin Streams",
      sortingFn: (a, b) => getOriginTraffic(a.original.channels).length - getOriginTraffic(b.original.channels).length,
      cell: (info) => <OriginStreamsCell channels={info.row.original.channels} highlightStream={globalFilter} />,
    }),
    columnHelper.accessor("channels", {
      id: "relayedChannels",
      header: () => "Relayed Streams",
      sortingFn: (a, b) =>
        getRelayedTraffic(a.original.channels).length - getRelayedTraffic(b.original.channels).length,
      cell: (info) => <RelayedStreamsCell channels={info.row.original.channels} highlightStream={globalFilter} />,
    }),
    columnHelper.accessor("bitRateIn", {
      header: () => "Bitrate In",
      cell: (info) => <BitRateCell bitRate={info.getValue()} />,
    }),
    columnHelper.accessor("bitRateOut", {
      header: () => "Bitrate Out",
      cell: (info) => <BitRateCell bitRate={info.getValue()} />,
    }),
    columnHelper.accessor("ingressCount", {
      header: () => "Ingress Count",
      cell: (info) => <div className="text-center text-fg-subtle">{info.getValue()}</div>,
    }),
    columnHelper.accessor("ingressLoad", {
      header: () => "Ingress Load",
      cell: (info) => <LoadCell load={info.getValue() ?? 0} />,
    }),
    columnHelper.accessor("ingressFull", {
      header: () => "Ingress Full",
      cell: (info) => <span className="text-fg-subtle">{info.getValue() ? "Yes" : "No"}</span>,
    }),
    columnHelper.accessor("egressLoad", {
      header: () => "Egress Load",
      cell: (info) => <LoadCell load={info.getValue() ?? 0} />,
    }),
    columnHelper.accessor("egressFull", {
      header: () => "Egress Full",
      cell: (info) => <span className="text-fg-subtle">{info.getValue() ? "Yes" : "No"}</span>,
    }),
    columnHelper.accessor("adapterSpeedMbps", {
      header: () => "Adapter Speed",
      cell: (info) => (
        <span className="flex items-center justify-between text-fg-subtle">
          <span className="pr-1">{info.getValue()} Mbps</span>
          <SetAdapterSpeedDialog edge={info.row.original} />
        </span>
      ),
    }),
    columnHelper.accessor("publicWsUrl", {
      header: () => "QoS",
      cell: (info) => <QosLink channelId={testChannelId} publicWsUrl={info.getValue()} />,
      enableSorting: false,
    }),
  ]

  const table = useReactTable({
    ...options,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowId: (row) => row.hostname,
    autoResetPageIndex: false,
  })

  // If there is a filter AND no rows - that means we should show "no results".
  // This is different from zero rows and no filter - in that case we should show
  // a call to action.
  const noResults = table.getRowModel().rows.length === 0 && !!table.getState().globalFilter

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-end gap-2">
        <div>
          <DebouncedTextField
            // eslint-disable-next-line total-functions/no-unsafe-type-assertion
            initialValue={table.getState().globalFilter as string}
            onValueChange={table.setGlobalFilter}
            debounceMs={200}
            placeholder="Filter..."
          />
        </div>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button>
              <IconAdjustmentsFilled size={16} />
              Configure
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent sideOffset={4} align="start">
            {table.getAllLeafColumns().map((column) => {
              return (
                <DropdownMenuSwitchItem
                  key={column.id}
                  checked={column.getIsVisible()}
                  onSelect={() => column.toggleVisibility()}
                >
                  {columnNames(column.id)}
                </DropdownMenuSwitchItem>
              )
            })}
            <DropdownMenuSeparator />
            <DropdownMenuItem onSelect={() => options.saveColumnVisibility()}>Save</DropdownMenuItem>
            <DropdownMenuItem onSelect={() => options.resetColumnVisibilityToDefault()}>
              Reset to default
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      {noResults ? <NoResultsFound /> : <InteractiveTable table={table} includePaginationFooter />}
    </div>
  )
}
