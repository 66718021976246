import { Expose, Transform } from "class-transformer"
import { transformDate } from "../transforms"

export class ChannelGroupDTO {
  static readonly modelName = "ChannelGroup"

  @Expose()
  @Transform(transformDate)
  createdAt!: Date

  @Expose()
  @Transform(transformDate)
  updatedAt?: Date

  @Expose()
  id!: number

  @Expose()
  name!: string

  @Expose()
  channelGroupId!: string

  @Expose()
  organization!: number

  @Expose()
  channels!: number[]
}
