import { z } from "zod"

export const RegisterOrganization = z.object({
  name: z.string().min(2).max(50),
  country: z.string().min(1).max(100),
  industry: z.string().min(1).max(100),
  companySize: z.string().max(100),
})

export type RegisterOrganization = z.infer<typeof RegisterOrganization>
