import { Expose, Type } from "class-transformer"
import { BaseDTO } from "../base.dto"
import { PublicApiChannelWithoutRenditionsDTO } from "../channel/channel-without-internal.dto"

export class PublicChannelGroupDTO extends BaseDTO<PublicChannelGroupDTO> {
  @Expose()
  name!: string

  @Expose()
  channelGroupId!: string

  @Expose()
  @Type(() => PublicApiChannelWithoutRenditionsDTO)
  channels!: PublicApiChannelWithoutRenditionsDTO[]
}
