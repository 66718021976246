import { useAbility } from "@casl/react"
import { Action } from "@core-services/data-types"
import { Route, useSearch } from "@tanstack/react-router"
import { AbilityContext } from "../../acl"
import { EmptyStateMessage } from "../../organisms/EmptyStateMessage"
import { router } from "../../router"
import { Dashboard } from "../../templates/dashboard/Dashboard"
import { trpc } from "../../trpc"
import { onboardedRoute } from "../onboarded"

export const dashboardRoute = new Route({
  getParentRoute: () => onboardedRoute,
  preSearchFilters: [(search) => search],
  path: "dashboard",
  component: Page,
  getContext: () => {
    return {
      pageTitle: "Dashboard",
    }
  },
  onLoad: async ({ context, search: { organizationId } }) => {
    await context.trpcContext.dashboard.get.ensureData({ organizationId })

    if (organizationId) {
      await context.trpcContext.channel.findManyByOrganizationId.ensureData({ organizationId })
    }
  },
})

function Page() {
  const { organizationId } = useSearch({ from: dashboardRoute.id })
  const { data: userProfile } = trpc.user.profile.useQuery(undefined)
  const { data } = trpc.dashboard.get.useQuery({ organizationId })
  const { data: organizationChannels } = trpc.channel.findManyByOrganizationId.useQuery(
    { organizationId: organizationId ?? "" },
    { enabled: !!organizationId }
  )
  const ability = useAbility(AbilityContext)

  if (!data || !userProfile) {
    return null
  }

  if (organizationId && (!organizationChannels || organizationChannels.length === 0)) {
    const onClickCreate = ability.can(Action.Create, "Channel")
      ? () =>
          void router.navigate({
            to: "/channels/create",
          })
      : undefined

    return (
      <EmptyStateMessage
        onClickCreate={onClickCreate}
        title="To begin harnessing the power of the Dashboard, you'll need to set up channels for your organization."
        subTitle="Create your first channel to start streaming."
        createButtonText="Create a channel"
        subTitleNoCreate="Ask your organization administrator or a channel manager to create a channel."
      />
    )
  }

  return <Dashboard stats={data} name={userProfile.name} />
}
