import { useEffect } from "react"
import { trpc } from "../trpc"

const THIRTY_MINUTES = 30 * 60 * 1000

function useUpdateLastLogin() {
  const { mutate: updateLastLoginAt } = trpc.user.updateLastLoginAt.useMutation()

  useEffect(() => {
    updateLastLoginAt({})

    const timerId = setInterval(() => {
      updateLastLoginAt({})
    }, THIRTY_MINUTES)

    return () => clearInterval(timerId)
  }, [updateLastLoginAt])
}

export default useUpdateLastLogin
