import { AccessPermissionRole } from "@core-services/data-types"
import { z } from "zod"

export const InviteUser = z.object({
  email: z.string().email(),
  role: z.nativeEnum(AccessPermissionRole),
  organizationPublicId: z.string(),
})

export type InviteUser = z.infer<typeof InviteUser>
