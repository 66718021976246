import { InformationCircleIcon } from "@heroicons/react/20/solid"
import { Tooltip, TooltipContent, TooltipPortal, TooltipProvider, TooltipTrigger } from "@vindral/components"
import { Trend } from "./Trend"

type StatProps = {
  title: string
  value: string | number | undefined
  subtext?: string
  change?: number
  tooltip?: string
  info?: string | JSX.Element
}
export function Statistic({ title, value, subtext, change, tooltip, info }: StatProps) {
  const displayTrend = typeof change !== "undefined" && !isNaN(change) && isFinite(change)

  return (
    <div>
      <div className="flex min-h-[28px] items-center justify-between gap-1 tabular-nums text-fg-subtle">
        <div className="flex items-center text-sm font-medium uppercase tracking-wider">
          <span className="mr-1">{title}</span>
          {info && (
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger>
                  <InformationCircleIcon className="h-4 w-4" />
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent side="right">{info}</TooltipContent>
                </TooltipPortal>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>

        {displayTrend && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Trend change={change} />
              </TooltipTrigger>
              {tooltip && (
                <TooltipPortal>
                  <TooltipContent side="right">{tooltip}</TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
      <div className="pt-2 text-2xl font-medium tabular-nums">{value}</div>
      {subtext && <div className="pt-2 text-sm tabular-nums text-fg-subtle">{subtext}</div>}
    </div>
  )
}
