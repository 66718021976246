import { useAbility } from "@casl/react"
import { Action } from "@core-services/data-types"
import { UpdateChannelGroup, normalizeIdentifier } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { IconPlayerPlay } from "@tabler/icons-react"
import {
  Button,
  ConfirmDialogContent,
  Copyable,
  Dialog,
  DialogTrigger,
  FormControl,
  FormControlLabel,
  TextField,
} from "@vindral/components"
import { useEffect } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { z } from "zod"
import { AbilityContext, channelGroupSubjectInOrganization } from "../../acl"
import { FormSection } from "../../atoms/FormSection"
import { Page, PageContainer, PageContent, PageFooter, PageHeader } from "../../atoms/Page"
import { SaveButton } from "../../atoms/SaveButton"
import { SaveSection } from "../../atoms/SaveSection"
import { StatusTitle } from "../../atoms/StatusTitle"
import { useNoMetricsEdge } from "../../hooks/useNoMetricsEdge"
import { SingleChannelGroup } from "../../interfaces/ChannelGroup"
import { Breadcrumbs } from "../../molecules/Breadcrumbs"
import { FormattedDate } from "../../molecules/FormattedDate"
import { Titled } from "../../molecules/Titled"
import { ChannelMultiSelector } from "../../organisms/multiselect/ChannelMultiSelector"
import { playGridUrlChannelGroupId } from "../../utils/urls"
import { Link } from "@tanstack/react-router"

interface EditChannelGroupProps {
  channelGroup: SingleChannelGroup
  onSave: (channelGroup: UpdateChannelGroup) => Promise<void>
  onDelete: () => Promise<void>
}

const resolver = zodResolver(UpdateChannelGroup.merge(z.object({ channelIds: z.any() })))

export const EditChannelGroup = ({ channelGroup, onSave, onDelete }: EditChannelGroupProps): JSX.Element => {
  const ability = useAbility(AbilityContext)
  const subject = channelGroupSubjectInOrganization(channelGroup.organizationId)
  const noMetricsEdge = useNoMetricsEdge()

  const form = useForm({
    resolver,
    mode: "onChange",
    defaultValues: {
      name: channelGroup.name,
      channelIds: channelGroup.channelIds?.map((id) => ({ channelId: id })) ?? [],
    },
  })

  const {
    reset,
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors, isDirty, isValid, isSubmitSuccessful },
  } = form
  const fieldArray = useFieldArray({ control, name: "channelIds" })
  const name = watch("name")

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful, reset])

  const readonly = ability.cannot(Action.Update, subject)

  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
            <a
              className="block w-[72px]"
              href={playGridUrlChannelGroupId({
                channelGroupId: channelGroup.channelGroupId,
                authToken: channelGroup.authToken,
                useThumbnails: true,
                edgeUrl: noMetricsEdge,
              })}
              target="_blank"
              data-pw="playLink"
            >
              <Button data-pw="editChannelGroupButton">
                <IconPlayerPlay size={16} />
                View
              </Button>
            </a>
          </div>
        </PageHeader>
        <PageContainer>
          <form
            onSubmit={handleSubmit((update) => {
              return onSave({
                ...update,
                channelIds: update.channelIds.map((c) => c.channelId),
              })
            })}
            className="flex flex-col gap-4 pt-4"
          >
            <FormSection
              title="General Information"
              description="Information about your profile that will help you recognize it when picking a profile for your channels."
            >
              <div className="flex flex-col gap-4">
                <div className="max-w-md">
                  <FormControl>
                    <FormControlLabel error={errors.name?.message} required>
                      Name
                    </FormControlLabel>
                    <TextField {...register("name")} error={!!errors?.name} className="w-full" disabled={readonly} />
                    <div className="mt-1 flex h-2 w-full items-center">
                      {name && (
                        <div className="text-xs text-fg-subtle">
                          Normalized name: <span className="font-semibold">{normalizeIdentifier(name)}</span>
                        </div>
                      )}
                    </div>
                  </FormControl>
                </div>
                <FormControl>
                  <StatusTitle>Channel Group Id</StatusTitle>
                  <Copyable text={channelGroup.channelGroupId}>
                    <span data-pw="channelGroupId" className="block truncate font-mono text-sm">
                      {channelGroup.channelGroupId}
                    </span>
                  </Copyable>
                </FormControl>
              </div>
            </FormSection>
            <FormSection
              title="Channel group members"
              description="Channels that are included in the channel group. Note that channels can be members of multiple channel groups."
            >
              <ChannelMultiSelector
                organizationId={channelGroup.organizationId}
                onSelect={(item) => fieldArray.prepend(item)}
                onDeselect={(index) => fieldArray.remove(index)}
                selected={fieldArray.fields}
                nothingSelectedText="Select channels from the dropdown to add them to this channel group"
              />
            </FormSection>
            {!readonly ? (
              <SaveSection>
                <SaveButton text="Save" disabled={!isDirty || !isValid} />
              </SaveSection>
            ) : null}
          </form>
        </PageContainer>
      </PageContent>
      <PageFooter>
        <div className="flex h-16 items-center justify-between">
          <div className="flex gap-8">
            <Titled title="Organization">
              <Link to="/organizations/edit/$publicId" params={{ publicId: channelGroup.organization.publicId }}>
                {channelGroup.organization.name}
              </Link>
            </Titled>
            <Titled title="Created">
              <FormattedDate date={new Date(channelGroup.createdAt)} />
            </Titled>
            {typeof channelGroup.updatedAt !== "undefined" && (
              <Titled title="Updated">
                <FormattedDate date={new Date(channelGroup.updatedAt)} />
              </Titled>
            )}
          </div>
          {ability.can(Action.Delete, subject) && (
            <Dialog>
              <DialogTrigger asChild>
                <Button variant="danger" data-pw="deleteButton">
                  Delete
                </Button>
              </DialogTrigger>
              <ConfirmDialogContent
                onConfirm={onDelete}
                title="Confirm deletion"
                description={
                  <>
                    Please confirm that you are absolutely sure that you want to delete{" "}
                    <span className="font-medium text-fg-danger">{channelGroup.name}</span>
                  </>
                }
              />
            </Dialog>
          )}
        </div>
      </PageFooter>
    </Page>
  )
}
