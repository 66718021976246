import { Transition } from "@headlessui/react"
import { cva } from "class-variance-authority"
import { Fragment, ReactNode, useState } from "react"

const MESSAGE_COPIED_SUCCESS = "Copied"
const MESSAGE_COPIED_FAILURE = "Copy failed"

export interface CopyButtonProps {
  text: string
  children: ReactNode
}

const message = cva("absolute left-1 top-0", {
  variants: {
    error: { true: "text-fg-danger-subtle" },
  },
})

const classNames = {
  enter: "",
  enterFrom: "",
  enterTo: "",
  leave: "transform duration-500 transition-all ease-in",
  leaveFrom: "translate-y-0 opacity-100",
  leaveTo: "-translate-y-8 opacity-0",
}

export function Copyable({ text, children }: CopyButtonProps) {
  const [showingMessage, setShowingMessage] = useState(false)
  const [copyMessage, setCopyMessage] = useState(MESSAGE_COPIED_FAILURE)

  const handleInputFieldClick = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    e.preventDefault()

    if (navigator.clipboard) {
      setCopyMessage(MESSAGE_COPIED_FAILURE)
      await navigator.clipboard.writeText(text)
      setCopyMessage(MESSAGE_COPIED_SUCCESS)
    }

    setShowingMessage(true)

    setTimeout(() => {
      setShowingMessage(false)
    }, 10)
  }

  return (
    <div className="group/copyable flex cursor-pointer items-center gap-0.5" onClick={handleInputFieldClick}>
      {children}
      <div className="relative px-1 font-medium opacity-0 transition-opacity duration-300 group-hover/copyable:opacity-100">
        <div>Copy</div>
        <Transition
          show={showingMessage}
          as={Fragment}
          enter={classNames.enter}
          enterFrom={classNames.enterFrom}
          enterTo={classNames.enterTo}
          leave={classNames.leave}
          leaveFrom={classNames.leaveFrom}
          leaveTo={classNames.leaveTo}
        >
          <span
            className={message({
              error: copyMessage === MESSAGE_COPIED_FAILURE,
            })}
          >
            {copyMessage}
          </span>
        </Transition>
      </div>
    </div>
  )
}
