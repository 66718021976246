import { subject } from "@casl/ability"
import { AccessPermissionRole, Action } from "@core-services/data-types"
import { AppAbility, InviteUser } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { Cross1Icon, PlusIcon } from "@radix-ui/react-icons"
import {
  TableOptions,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import {
  Button,
  ConfirmDialogContent,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
  FormControl,
  FormControlLabel,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  TextField,
} from "@vindral/components"
import { useController, useForm } from "react-hook-form"
import { FormSection } from "../../atoms/FormSection"
import { FormattedDate } from "../../molecules/FormattedDate"
import { InteractiveTable } from "../../organisms/InteractiveTable"
import { RouterOutputs } from "../../trpc"
import { accessPermissionRoleMapping } from "../../utils/accessPermissionRole"

export type UserInvite = RouterOutputs["auth"]["getInvites"][number]

const resolver = zodResolver(InviteUser)

export type UserInvitesTableProps = Omit<
  TableOptions<UserInvite>,
  "columns" | "getCoreRowModel" | "getSortedRowModel" | "getPaginationRowModel"
> & {
  ability: AppAbility
  organizationPublicId: string
  inviteUser: (value: InviteUser) => Promise<void>
  deleteInvite: (publicId: string) => Promise<void>
}

const InviteUserDialog = (props: UserInvitesTableProps) => {
  const { ability, inviteUser, organizationPublicId } = props
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<InviteUser>({
    resolver,
    mode: "onChange",
    defaultValues: {
      email: "",
      role: AccessPermissionRole.User,
      organizationPublicId,
    },
  })
  const roleController = useController({ control, name: "role" })

  return (
    <form onSubmit={handleSubmit(inviteUser)}>
      <div className="flex justify-between border-b border-divider p-4">
        <h1 className="mb-2 font-medium">Send invite link</h1>
        <DialogClose>
          <Cross1Icon />
        </DialogClose>
      </div>
      <div className="p-4">
        <div className="flex max-w-xs flex-col gap-4">
          <FormControl>
            <FormControlLabel required error={errors.email?.message}>
              E-mail
            </FormControlLabel>
            <TextField {...register("email")} error={!!errors?.email} />
          </FormControl>
          <FormControl>
            <FormControlLabel required>Role</FormControlLabel>
            <div>
              <Select onValueChange={roleController.field.onChange} value={roleController.field.value}>
                <SelectTrigger size="medium">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {Object.entries(AccessPermissionRole).map(([_key, value]) => (
                    <SelectItem key={value} value={value}>
                      {accessPermissionRoleMapping[value]}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </FormControl>
        </div>
      </div>
      {ability.can(Action.Create, subject("UserInviteToken", { organization: { publicId: organizationPublicId } })) ? (
        <div className="border-t border-divider p-4 text-right">
          <DialogClose asChild>
            <Button disabled={!isDirty || !isValid} type="submit" variant="primary">
              Send Invite
            </Button>
          </DialogClose>
        </div>
      ) : null}
    </form>
  )
}

function UserInvitesTable(props: UserInvitesTableProps) {
  const columnHelper = createColumnHelper<UserInvite>()
  const { ability, organizationPublicId, deleteInvite } = props
  const columns = [
    columnHelper.accessor("email", {
      header: () => "E-mail",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("role", {
      header: () => "Permissions",
      meta: { style: { textAlign: "right" } },
      cell: (info) => accessPermissionRoleMapping[info.getValue()],
    }),
    columnHelper.accessor("expiresAt", {
      header: () => "Expires",
      meta: { style: { textAlign: "right" } },
      cell: (info) => <FormattedDate date={new Date(info.getValue())} />,
    }),
    columnHelper.display({
      id: "publicId",
      header: () => "",
      meta: { style: { textAlign: "right" } },
      cell: (info) =>
        ability.can(
          Action.Delete,
          subject("UserInviteToken", { organization: { publicId: organizationPublicId } })
        ) && (
          <Dialog>
            <DialogTrigger asChild>
              <Button data-pw="cancelButton" size="small" variant="danger">
                Cancel
              </Button>
            </DialogTrigger>
            <ConfirmDialogContent
              onConfirm={() => deleteInvite(info.row.original.publicId)}
              title={`Confirm cancel of ${info.row.original.email} invitation`}
              description={
                <>
                  Please confirm that you are absolutely sure that you want to cancel the invitation for
                  <span className="font-medium"> {info.row.original.email}</span>.
                </>
              }
            />
          </Dialog>
        ),
      size: 1,
    }),
  ]

  const table = useReactTable({
    ...props,
    columns,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  return <InteractiveTable table={table} includePaginationFooter />
}

export const UserInvitesList = (props: UserInvitesTableProps) => {
  const pendingInvites = !!props.data.length
  return (
    <div className="py-4">
      <FormSection
        title="Pending invites"
        description={
          pendingInvites ? "Invites that are awaiting a response." : "There are currently no invites awaiting response."
        }
      >
        <div className="flex flex-col">
          <div className="pb-4">
            <Dialog>
              <DialogTrigger asChild>
                <Button>
                  <PlusIcon />
                  Invite Member
                </Button>
              </DialogTrigger>
              <DialogContent size="small">
                <InviteUserDialog {...props} />
              </DialogContent>
            </Dialog>
          </div>
          {pendingInvites && <UserInvitesTable {...props} />}
        </div>
      </FormSection>
    </div>
  )
}
