import { UpdateApiKey } from "@core-services/portal"
import { Route, useNavigate, useParams } from "@tanstack/react-router"
import { toast } from "@vindral/components"
import { z } from "zod"
import { apiKeyIndexRoute } from "."
import { ApiKeyEdit } from "../../templates/api-key/ApiKeyEdit"
import { trpc } from "../../trpc"

const Params = z.object({ id: z.coerce.string() })

function Page() {
  const context = trpc.useContext()
  const navigate = useNavigate()

  const { id } = useParams({ from: apiKeyEditRoute.id })
  const { data: apiKey } = trpc.apiKey.findById.useQuery({ publicId: id }, { suspense: true })

  if (!apiKey) {
    return null
  }

  const updateMutation = trpc.apiKey.update.useMutation({
    onSuccess: () => {
      void context.apiKey.findById.invalidate({ publicId: apiKey.publicId })
      toast({ title: "API key updated" })
    },
    onError: (error) => {
      toast({ title: "Failed to update API key!", description: error.message })
    },
  })
  const deleteMutation = trpc.apiKey.delete.useMutation({
    onSuccess: () => {
      void context.apiKey.findById.invalidate({ publicId: apiKey.publicId })
      toast({ title: "API key deleted" })
      void navigate({ to: "/api-keys", replace: true })
    },
    onError: (error) => {
      toast({ title: "Failed to delete API key!", description: error.message })
    },
  })

  const updateApiKey = async (value: UpdateApiKey) => {
    updateMutation.reset()
    await updateMutation.mutateAsync(value)
  }

  const deleteApiKey = async () => {
    await deleteMutation.mutateAsync({ publicId: apiKey.publicId })
  }

  return <ApiKeyEdit apiKey={apiKey} updateApiKey={updateApiKey} deleteApiKey={deleteApiKey} />
}

export const apiKeyEditRoute = new Route({
  getParentRoute: () => apiKeyIndexRoute,
  path: "edit/$id",
  parseParams: (params) => Params.parse(params),
  preSearchFilters: [(search) => search],
  component: Page,
  getContext: () => {
    return {
      pageTitle: "Edit API Key",
    }
  },
})
