import { cva, VariantProps } from "class-variance-authority"
import { forwardRef } from "react"
import { Modal, ModalClose, ModalContent, ModalContentProps, ModalContentRef, ModalTrigger } from "./Modal"

const defaultVariants = {
  size: "large",
} as const

const dialog = cva(
  [
    "flex",
    "max-h-full",
    "max-w-full",
    "flex-col",
    "overflow-y-auto",
    "rounded-sm",
    "border",
    "border-divider",
    "bg-canvas-1",
    "shadow-xl",
    "shadow-black/20",
  ],
  {
    variants: {
      size: {
        small: ["w-[480px]"],
        medium: ["w-[768px]"],
        large: ["w-[1024px]"],
      },
    },
    defaultVariants,
  }
)

export type Variants = VariantProps<typeof dialog>

export const DialogContent = forwardRef<ModalContentRef, ModalContentProps & Variants>(
  ({ children, ...props }, ref) => {
    const { size, ...rest } = props
    return (
      <ModalContent ref={ref} {...rest}>
        <div className={dialog({ size })}>{children}</div>
      </ModalContent>
    )
  }
)
DialogContent.displayName = "DialogContent"

export const Dialog = Modal
export const DialogTrigger = ModalTrigger
export const DialogClose = ModalClose
