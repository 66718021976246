import { Outlet } from "@tanstack/react-router"
import { Page, PageContainer, PageContent, PageHeader } from "../../atoms/Page"
import { Breadcrumbs } from "../../molecules/Breadcrumbs"
import { RouteTabs, RouteTrigger } from "../../molecules/RouteTabs"
import { RouterOutputs } from "../../trpc"

export type UserProfile = RouterOutputs["user"]["profile"]
export type ApiKeys = RouterOutputs["user"]["apiKeys"]

interface ProfileProps {
  profile: UserProfile
}

export const Profile = (props: ProfileProps) => {
  // If any of the user's organization has API access enabled, show the API keys tab
  const showApiKeys = props.profile.accessPermissions.find((a) => a.organization.apiAccessEnabled) != undefined

  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
          </div>
          <RouteTabs border="none">
            <RouteTrigger to="/profile/edit" data-pw="profileTab" search>
              Profile
            </RouteTrigger>
            {showApiKeys && (
              <RouteTrigger to="/profile/api-keys" data-pw="apiKeysTab" search>
                API Keys
              </RouteTrigger>
            )}
            <RouteTrigger to="/profile/password" data-pw="passwordTab" search>
              Password & Authentication
            </RouteTrigger>
          </RouteTabs>
        </PageHeader>
        <PageContainer>
          <Outlet />
        </PageContainer>
      </PageContent>
    </Page>
  )
}
