import { VerifyTwoFactor } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { Cross1Icon } from "@radix-ui/react-icons"
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
  FormControl,
  FormControlLabel,
  TextField,
} from "@vindral/components"
import QRCode from "qrcode"
import { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"

const resolver = zodResolver(
  z.object({ code: z.string().length(6, "Please match the requested format"), publicId: z.string() })
)

interface ConfigureTwoFactorModalProps {
  title: string
  actionText: string
  generate: () => Promise<{ publicId: string; uri: string }>
  verify: (value: VerifyTwoFactor) => Promise<void>
}

export const ConfigureTwoFactorModal = (props: ConfigureTwoFactorModalProps) => {
  const { title, actionText, generate, verify } = props
  const [open, setOpen] = useState(false)
  const [qrCode, setQrCode] = useState<string | undefined>(undefined)
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    setError,
    formState: { errors, isDirty, isValid, isSubmitSuccessful },
  } = useForm<VerifyTwoFactor>({
    resolver,
    mode: "onChange",
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      setOpen(false)
    }
  }, [isSubmitSuccessful])

  const onOpenChange = useCallback(
    async (open: boolean) => {
      if (open) {
        const { uri, publicId } = await generate()
        setValue("publicId", publicId)

        const qrCode = await QRCode.toDataURL(uri)
        setQrCode(qrCode)
      } else {
        setQrCode(undefined)
        reset({ code: "", publicId: "" })
      }

      setOpen(open)
    },
    [generate, reset, setValue]
  )

  const onSubmit = async (value: VerifyTwoFactor) => {
    try {
      await verify(value)
    } catch (error) {
      if (error instanceof Error) {
        setError("code", { message: error.message })
      }
    }
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <Button type="button">{actionText}</Button>
      </DialogTrigger>
      <DialogContent size="small" onPointerDownOutside={(e) => e.preventDefault()}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-between border-b border-divider">
            <h1 className="font-medium">{title}</h1>
            <DialogClose className="cursor-pointer focus:outline-none">
              <Cross1Icon />
            </DialogClose>
          </div>
          <div className="flex flex-col gap-4 p-4">
            <ol className="list-inside list-decimal space-y-6">
              <li>
                Install an Authenticator App. 1Password, Google Authenticator or Microsoft Authenticator are som
                examples
              </li>
              <li>
                Scan the QR code below with your Authenticator App
                <img className="mt-4" src={qrCode} />
              </li>
              <li>Enter your verification code from the App in the field below</li>
            </ol>
            <FormControl>
              <FormControlLabel error={errors.code?.message}>Verification code</FormControlLabel>
              <TextField {...register("code")} type="text" placeholder="XXXXXX" />
            </FormControl>
          </div>
          <div className="border-t border-divider p-4 text-right ">
            <Button disabled={!qrCode || !isDirty || !isValid} type="submit" variant="primary">
              {`${actionText} Two-Factor Authentication`}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}
