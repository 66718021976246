import { OrganizationType } from "@core-services/data-types"
import { CreateOrganization } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  FormControlLabel,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  TextField,
} from "@vindral/components"
import { Controller, useForm } from "react-hook-form"
import { FormSection } from "../../atoms/FormSection"
import { Page, PageContainer, PageContent, PageHeader } from "../../atoms/Page"
import { SaveButton } from "../../atoms/SaveButton"
import { Breadcrumbs } from "../../molecules/Breadcrumbs"
import { OrganizationUniqueNameStatus } from "../../molecules/OrganizationUniqueNameStatus"
import { trpc } from "../../trpc"
import { organizationTypeMapping } from "../../utils/organizationTypeMapping"

const resolver = zodResolver(CreateOrganization)

export const OrganizationCreate = ({
  createOrganization,
}: {
  createOrganization: (value: CreateOrganization) => Promise<void>
}) => {
  const form = useForm<CreateOrganization>({
    resolver,
    mode: "onChange",
    defaultValues: {
      name: "",
      type: OrganizationType.Customer,
    },
  })
  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors, isDirty, isValid },
  } = form
  const name = watch("name")

  const { data: uniqueNameData, isFetching: checkinguniqueName } = trpc.organization.generateUniqueName.useQuery(
    { name },
    { enabled: !!name, keepPreviousData: true }
  )

  const { uniqueName, notAllowedReason, allowed: uniqueNameAllowed } = uniqueNameData || {}
  const invalidUniqueName = checkinguniqueName || !uniqueNameAllowed

  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
          </div>
        </PageHeader>
        <PageContainer>
          <div className="flex flex-col gap-4 py-4">
            <FormSection title="General" description="Change general settings">
              <form onSubmit={handleSubmit(createOrganization)} className="flex flex-col items-start">
                <div className="flex w-96 flex-col gap-1 pb-4">
                  <FormControlLabel required error={errors.name?.message || notAllowedReason}>
                    Name
                  </FormControlLabel>
                  <TextField {...register("name")} />
                  <OrganizationUniqueNameStatus
                    input={name}
                    uniqueName={uniqueName}
                    checkinguniqueName={checkinguniqueName}
                  />

                  <FormControlLabel required error={errors.type?.message}>
                    Type
                  </FormControlLabel>
                  <Controller
                    control={control}
                    name="type"
                    render={({ field }) => (
                      <div>
                        <Select value={field.value} onValueChange={field.onChange}>
                          <SelectTrigger data-pw="scopeSelector">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            {Object.entries(organizationTypeMapping).map(([value, label]) => (
                              <SelectItem value={value} key={value}>
                                {label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                    )}
                  />
                </div>

                <SaveButton text="Save" disabled={!isDirty || !isValid || invalidUniqueName} />
              </form>
            </FormSection>
          </div>
        </PageContainer>
      </PageContent>
    </Page>
  )
}
