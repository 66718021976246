import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { Copyable } from "@vindral/components"
import { useMemo } from "react"
import { IngestPointDatacenterItem } from "../../../interfaces/IngestPoints"
import { InteractiveTable } from "../../../organisms/InteractiveTable"
import { StatusChip } from "./StatusChip"

interface Props {
  data: IngestPointDatacenterItem[]
}

export function IngestPointsDatacenter({ data }: Props) {
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<IngestPointDatacenterItem>()

    return [
      columnHelper.display({
        id: "name",
        header: () => "Ingest Point",
        cell: (info) => info.row.original.name,
        size: 1,
      }),
      columnHelper.display({
        id: "url",
        header: () => "Ingest Url",
        cell: (info) => <Copyable text={info.row.original.url}>{info.row.original.url}</Copyable>,
        size: 1,
      }),
      columnHelper.display({
        id: "nodes",
        header: () => "Nodes",
        cell: (info) => (
          <>
            {info.row.original.nodes.map((e) => (
              <StatusChip key={e.name} label={e.name} status={e.status} />
            ))}
          </>
        ),
        size: 1,
      }),
    ]
  }, [])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowId: (row) => row.name,
    state: { pagination: { pageSize: 30, pageIndex: 0 } },
  })

  return (
    <div className="flex min-h-0 grow flex-col">
      <InteractiveTable table={table} includePaginationFooter />
    </div>
  )
}
