import React from "react"

interface IVindralLogoProps {
  className?: string
}

export const HorizontalLogo = (props: IVindralLogoProps): JSX.Element => (
  <svg className={props.className} viewBox="0 0 1450.3066 299.02667">
    <g id="g10" transform="matrix(1.3333333,0,0,-1.3333333,0,299.02667)">
      <g id="g12" transform="scale(0.1)">
        <path
          d="m 93.4141,1753.46 0.039,0.02 C 23.4102,1816.26 -13.8594,1913.05 4.77344,2013.79 30.6445,2153.68 159.941,2253 301.773,2241.91 442.297,2230.92 548.211,2119.7 559.609,1986.57 l 0.047,0.02 c 19.004,-219.33 39.817,-438.51 62.426,-657.51 2.539,-24.58 12.484,-47.82 28.496,-66.65 L 1097.31,737.16 c 8.79,-10.328 25.38,-7.859 30.77,4.59 l 576.2,1329.84 c 38.26,90.21 122.81,158.05 228.04,169.5 145.43,15.84 280.33,-85.12 306.05,-229.13 16.76,-93.83 -15,-184.19 -76.55,-246.56 L 1159.94,733.469 c -17.62,-18.149 -18.47,-46.739 -1.97,-65.91 l 174.94,-203.188 -0.06,-0.031 c 37.38,-42.879 62.24,-97.59 67.78,-159.199 C 1413.71,159.66 1310.35,26.8711 1166.15,3.62109 1017.27,-20.3594 878.203,77.2109 847.391,221.602 L 618.262,1245.59 c -5.481,24.52 -18.391,46.74 -36.961,63.65 L 93.4141,1753.46"
          style={{ fill: "currentColor", fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
          id="path14"
        />
        <path
          d="m 3754.79,911.148 c 14.77,-50.199 32.48,-126.976 32.48,-132.878 l 38.4,132.878 209.65,676.252 h 183.1 L 3893.58,583.371 H 3676.53 L 3364.99,1587.4 h 186.04 l 203.76,-676.252"
          style={{ fill: "currentColor", fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
          id="path16"
        />
        <path
          d="M 4803.19,583.371 H 4626 V 1587.4 h 177.19 V 583.371"
          style={{ fill: "currentColor", fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
          id="path18"
        />
        <path
          d="m 5879.85,1013.03 c 22.16,-33.971 63.5,-100.389 76.8,-122.55 0,32.481 -2.96,218.54 -2.96,360.28 V 1587.4 H 6129.4 V 583.371 h -165.37 l -395.72,603.889 c -31.01,48.73 -62.01,103.37 -63.48,103.37 1.47,-47.25 2.95,-203.77 2.95,-372.091 V 583.371 H 5332.07 V 1587.4 h 165.37 l 382.41,-574.37"
          style={{ fill: "currentColor", fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
          id="path20"
        />
        <path
          d="m 6840.4,744.309 h 116.65 c 112.21,0 197.84,1.472 259.86,60.543 47.25,48.726 90.07,153.546 90.07,277.578 0,122.55 -35.43,222.95 -103.35,282.01 -59.06,51.69 -134.37,59.06 -265.78,59.06 H 6840.4 Z M 6661.74,583.371 V 1587.4 h 280.53 c 162.41,0 264.3,0 366.18,-70.87 109.27,-79.73 187.52,-231.82 187.52,-428.2 0,-168.31 -53.16,-311.541 -146.17,-400.131 C 7242.01,583.371 7125.37,583.371 6937.85,583.371 h -276.11"
          style={{ fill: "currentColor", fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
          id="path22"
        />
        <path
          d="m 8088.61,1144.44 h 190.46 c 72.36,0 110.76,5.91 141.75,19.19 44.3,20.69 70.88,70.87 70.88,125.51 0,54.63 -26.58,103.37 -70.88,122.55 -30.99,13.3 -69.39,19.2 -141.75,19.2 h -190.46 z m 0,-159.46 V 583.371 H 7911.42 V 1587.4 h 367.65 c 85.64,0 175.71,-1.47 252.5,-45.77 88.58,-51.68 143.22,-134.37 143.22,-256.92 0,-110.73 -51.68,-191.95 -132.89,-246.56 -23.62,-14.78 -54.64,-26.59 -78.26,-35.46 L 8714.65,583.371 H 8503.5 L 8270.22,984.98 h -181.61"
          style={{ fill: "currentColor", fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
          id="path24"
        />
        <path
          d="m 9492.94,1274.39 c -16.24,47.25 -26.59,90.05 -36.91,128.44 h -1.48 c -8.86,-36.9 -20.68,-78.25 -36.92,-125.51 l -94.49,-308.582 h 262.82 z M 9194.67,583.371 H 9010.1 L 9358.58,1587.4 h 200.8 L 9904.89,583.371 h -190.48 l -76.77,227.379 h -364.7 l -78.27,-227.379"
          style={{ fill: "currentColor", fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
          id="path26"
        />
        <path
          d="M 10438.9,1587.4 V 745.781 h 438.4 v -162.41 h -617 V 1587.4 h 178.6"
          style={{ fill: "currentColor", fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
          id="path28"
        />
      </g>
    </g>
  </svg>
)
