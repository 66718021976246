import { Page, PageContainer, PageContent, PageFooter, PageHeader } from "../../../atoms/Page"
import { Breadcrumbs } from "../../../molecules/Breadcrumbs"
import { Titled } from "../../../molecules/Titled"
import { EdgeServerTable, type EdgeServerTableProps } from "../../../organisms/EdgeServerTable"

export type EdgeServerListProps = EdgeServerTableProps & {
  isStaleData: boolean
  filteredAdapterSpeedMbps: number
}

export function EdgeServerList({ isStaleData, ...props }: EdgeServerListProps) {
  if (props.data.length === 0 && !props.state?.globalFilter && !isStaleData) {
    return (
      <div className="relative flex justify-center pt-40">
        <div>
          <h1 className="font-medium">There is nothing here right now</h1>
          <p className="py-1 text-fg-subtle last-of-type:pb-3">You will be able to see edge servers here very soon.</p>
        </div>
      </div>
    )
  }

  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
          </div>
        </PageHeader>
        <PageContainer>
          <div className="py-4">
            <EdgeServerTable {...props} />
          </div>
        </PageContainer>
      </PageContent>
      <PageFooter>
        <div className="flex h-16 items-center justify-between">
          <div>
            {props.data.length} edge server{props.data.length > 1 ? "s" : ""}
          </div>
          <div className="flex gap-4">
            <Titled title="Adapter speed">
              {props.filteredAdapterSpeedMbps.toLocaleString().replace(/,/g, " ")} Mbps
            </Titled>
          </div>
        </div>
      </PageFooter>
    </Page>
  )
}
