import { Route } from "@tanstack/react-router"
import { onboardedRoute } from "../onboarded"

export const userIndexRoute = new Route({
  getParentRoute: () => onboardedRoute,
  preSearchFilters: [(search) => search],
  path: "users",
  getContext: () => {
    return {
      pageTitle: "Users",
    }
  },
})
