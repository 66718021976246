export const mergeSessionsAndSessionTime = (
  channelNamesAndKeys?: { name: string; streamKey: string }[],
  sessionTimes?: { streamKey: string; sessionsTotalTime: number }[],
  sessions?: { streamKey: string; sessions: number }[]
) => {
  if (!channelNamesAndKeys || !sessionTimes || !sessions) {
    return []
  }

  return channelNamesAndKeys
    .map((nameAndKey) => ({
      ...nameAndKey,
      sessions:
        sessions.find((numSessions) => nameAndKey.streamKey === numSessions.streamKey && numSessions)?.sessions || 0,
      sessionsTotalTime:
        sessionTimes.find((time) => nameAndKey.streamKey === time.streamKey && time)?.sessionsTotalTime || 0,
    }))
    .map((item) => ({ ...item, sessionDuration: item.sessions !== 0 ? item.sessionsTotalTime / item.sessions : 0 }))
}
