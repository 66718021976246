import { Expose, plainToClassFromExist } from "class-transformer"
import { BaseDTO } from "../base.dto"

export class PackagerServerBasicDTO extends BaseDTO<PackagerServerBasicDTO> {
  @Expose()
  id!: string

  @Expose()
  nodeHostname!: string

  @Expose()
  cpuAvailableTotal!: number

  @Expose()
  hardwareAcceleration!: KnownHardwareAccelerators[]

  @Expose()
  features!: string[]
}

export class PackagerServerDTO extends PackagerServerBasicDTO {
  constructor(props?: { [K in keyof PackagerServerDTO]: PackagerServerDTO[K] }) {
    super()
    plainToClassFromExist(this, props)
  }

  @Expose()
  cpuAllocatedTotal!: number

  @Expose()
  cpuAllocatedPackager!: number

  @Expose()
  cpuAllocatedOther!: number

  @Expose()
  packagerCount!: number

  @Expose()
  packagers!: {
    streamKey: string
    cpuAllocated: number
  }[]
}

export type KnownHardwareAccelerators = "nvidia" | "netint"
