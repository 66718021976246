import { useQueryClient } from "@tanstack/react-query"
import { Navigate, useNavigate } from "@tanstack/react-router"
import { TRPCClientError } from "@trpc/client"
import { CallToAction } from "@vindral/components"
import { isAxiosError } from "../http/error"
import { router } from "../router"
import bug from "./assets/bug.svg"

const validateHref = (href: string) => {
  const hrefRegex = /^\/?$/
  if (!hrefRegex.test(href)) {
    return href
  } else {
    return undefined
  }
}

export function PageError({ error, redirectOn403 = true }: { error: Error; redirectOn403?: boolean }) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const isAxiosAuthError = isAxiosError(error) && error.response?.status === 403
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const isTRPCAuthError = error instanceof TRPCClientError && error.data?.code == "UNAUTHORIZED"
  if (redirectOn403 && (isAxiosAuthError || isTRPCAuthError)) {
    queryClient.removeQueries()
    return (
      <Navigate
        to="/auth/login"
        search={{
          redirect: validateHref(router.state.currentLocation.href),
        }}
        replace
      />
    )
  }

  return (
    <div className="relative flex justify-center pt-40">
      <CallToAction
        title="We were unable to load this page"
        paragraphs={[error.message]}
        callToActionText="Go back to dashboard"
        onClick={() => {
          void navigate({
            to: "/",
            search: {},
            replace: true,
          })
        }}
        imageSrc={bug}
      />
    </div>
  )
}
