import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons"
import * as SelectPrimitive from "@radix-ui/react-select"
import { VariantProps, cva } from "class-variance-authority"
import React, { ComponentProps, ElementRef, ReactNode } from "react"

const defaultVariants = {
  size: "small",
} as const

const trigger = cva(
  [
    "inline-flex",
    "cursor-pointer",
    "items-center",
    "justify-between",
    "gap-1",
    "rounded-sm",
    "tracking-wide",
    "font-medium",
    "shadow",
    "dark:shadow-outline",
    "dark:shadow-black/10",
    "outline-none",
    "transition-all",
    "disabled:cursor-not-allowed",
    "disabled:opacity-60",
    "focus:ring-1",
    "ring-offset-canvas-1",
    "ring-offset-1",
    "focus:ring-component",
    "border",
    "border-divider",
    "bg-canvas-3",
    "enabled:hover:border-divider-active",
    "enabled:active:bg-component-active",
    "radix-placeholder:text-fg-subtle",
  ],
  {
    variants: {
      size: {
        small: ["h-6", "px-2", "text-sm"],
        medium: ["h-7", "px-3", "text-sm"],
        large: ["h-8", "px-4", "text-base"],
      },
    },
    defaultVariants,
  }
)

const StyledTrigger = React.forwardRef<
  ElementRef<typeof SelectPrimitive.SelectTrigger>,
  ComponentProps<typeof SelectPrimitive.SelectTrigger> & VariantProps<typeof trigger>
>((props, ref) => {
  return (
    <SelectPrimitive.SelectTrigger ref={ref} {...props} className={trigger({ size: props.size })}>
      {props.children}
      <StyledIcon>
        <ChevronDownIcon className="h-4 w-4" />
      </StyledIcon>
    </SelectPrimitive.SelectTrigger>
  )
})
StyledTrigger.displayName = "StyledTrigger"

const StyledIcon = React.forwardRef<
  ElementRef<typeof SelectPrimitive.SelectIcon>,
  ComponentProps<typeof SelectPrimitive.SelectIcon>
>((props, ref) => {
  return <SelectPrimitive.SelectIcon ref={ref} {...props} className="" />
})
StyledIcon.displayName = "StyledIcon"

const StyledContent = React.forwardRef<
  ElementRef<typeof SelectPrimitive.Content>,
  ComponentProps<typeof SelectPrimitive.Content>
>(({ children, ...rest }, ref) => {
  return (
    <SelectPrimitive.Content
      ref={ref}
      {...rest}
      className="z-50 w-fit overflow-hidden rounded-sm border border-divider bg-canvas-2 p-1 shadow-xl animate-in fade-in zoom-in-95 origin-radix-select data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=closed]:zoom-out-95"
    >
      <SelectScrollUpButton>
        <ChevronUpIcon />
      </SelectScrollUpButton>
      <SelectViewport>{children}</SelectViewport>
      <SelectScrollDownButton>
        <ChevronDownIcon />
      </SelectScrollDownButton>
    </SelectPrimitive.Content>
  )
})
StyledContent.displayName = "StyledContent"

const StyledViewport = React.forwardRef<
  ElementRef<typeof SelectPrimitive.Viewport>,
  ComponentProps<typeof SelectPrimitive.Viewport>
>((props, ref) => {
  return <SelectPrimitive.Viewport ref={ref} {...props} className="" />
})
StyledViewport.displayName = "StyledViewport"

interface ContentProps {
  children?: ReactNode | undefined
}

export function SelectContent({ children, ...props }: ContentProps & ComponentProps<typeof StyledContent>) {
  return (
    <SelectPrimitive.Portal>
      <StyledContent position="popper" sideOffset={4} {...props}>
        {children}
      </StyledContent>
    </SelectPrimitive.Portal>
  )
}

const StyledItem = React.forwardRef<
  ElementRef<typeof SelectPrimitive.Item>,
  ComponentProps<typeof SelectPrimitive.Item>
>((props, ref) => {
  return (
    <SelectPrimitive.Item
      ref={ref}
      {...props}
      className="flex h-7 cursor-pointer select-none items-center justify-between rounded-sm px-2 pl-6 outline-none data-[disabled]:cursor-auto data-[highlighted]:bg-component-hover data-[disabled]:text-fg-extra-subtle"
    >
      <StyledItemIndicator>
        <CheckIcon className="h-4 w-4 " />
      </StyledItemIndicator>
      <SelectPrimitive.ItemText>{props.children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
  )
})
StyledItem.displayName = "StyledItem"

const StyledLabel = React.forwardRef<
  ElementRef<typeof SelectPrimitive.Label>,
  ComponentProps<typeof SelectPrimitive.Label>
>((props, ref) => {
  return (
    <SelectPrimitive.Label
      ref={ref}
      {...props}
      className="px-4 py-1 pl-7 text-sm font-medium uppercase text-fg-subtle"
    />
  )
})
StyledLabel.displayName = "StyledLabel"

const StyledSeparator = React.forwardRef<
  ElementRef<typeof SelectPrimitive.Separator>,
  ComponentProps<typeof SelectPrimitive.Separator>
>((props, ref) => {
  return <SelectPrimitive.Separator ref={ref} {...props} className="my-1 h-px bg-divider" />
})
StyledSeparator.displayName = "StyledSeparator"

const StyledItemIndicator = React.forwardRef<
  ElementRef<typeof SelectPrimitive.ItemIndicator>,
  ComponentProps<typeof SelectPrimitive.ItemIndicator>
>((props, ref) => {
  return (
    <SelectPrimitive.ItemIndicator
      ref={ref}
      {...props}
      className="absolute left-1 inline-flex items-center justify-center"
    />
  )
})

StyledItemIndicator.displayName = "StyledItemIndicator"

const StyledScrollUpButton = React.forwardRef<
  ElementRef<typeof SelectPrimitive.ScrollUpButton>,
  ComponentProps<typeof SelectPrimitive.ScrollUpButton>
>((props, ref) => {
  return (
    <SelectPrimitive.ScrollUpButton
      ref={ref}
      {...props}
      className="flex h-6 items-center justify-center text-fg-subtle"
    />
  )
})
StyledScrollUpButton.displayName = "StyledScrollUpButton"

const StyledScrollDownButton = React.forwardRef<
  ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  ComponentProps<typeof SelectPrimitive.ScrollDownButton>
>((props, ref) => {
  return (
    <SelectPrimitive.ScrollDownButton
      ref={ref}
      {...props}
      className="flex h-6 items-center justify-center text-fg-subtle"
    />
  )
})
StyledScrollDownButton.displayName = "StyledScrollDownButton"

export const Select = SelectPrimitive.Root
export const SelectTrigger = StyledTrigger
export const SelectValue = SelectPrimitive.Value
export const SelectIcon = StyledIcon
export const SelectViewport = StyledViewport
export const SelectGroup = SelectPrimitive.Group
export const SelectItem = StyledItem
export const SelectItemText = SelectPrimitive.ItemText
export const SelectItemIndicator = StyledItemIndicator
export const SelectLabel = StyledLabel
export const SelectSeparator = StyledSeparator
export const SelectScrollUpButton = StyledScrollUpButton
export const SelectScrollDownButton = StyledScrollDownButton

export type SelectProps = SelectPrimitive.SelectProps
