import { ResetUserPassword } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { Button, FormControl, FormControlLabel } from "@vindral/components"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { PasswordTextField } from "../../molecules/PasswordTextField"
import Form from "../../organisms/Form"
import { ReCaptchaText } from "../../organisms/ReCaptchaText"

const resolver = zodResolver(ResetUserPassword)

export interface ResetPasswordProps {
  resetPassword: (value: ResetUserPassword) => Promise<void>
  email: string
  token: string
}

export const ResetPassword = (props: ResetPasswordProps) => {
  const { resetPassword, email, token } = props
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid, isSubmitSuccessful },
  } = useForm<ResetUserPassword>({
    resolver,
    mode: "onChange",
    defaultValues: {
      email,
      token,
    },
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ newPassword: "" })
    }
  }, [isSubmitSuccessful, reset])

  return (
    <Form onSubmit={handleSubmit(resetPassword)} className="flex max-w-md flex-col gap-4 py-4">
      <div>
        <span>Email address</span>
        <h1 className="font-medium underline">{email}</h1>
      </div>
      <FormControl>
        <FormControlLabel error={errors.newPassword?.message}>New Password</FormControlLabel>
        <PasswordTextField
          {...register("newPassword")}
          error={!!errors.newPassword}
          autoComplete="new-password"
          data-pw="newPassword"
        />
        <ReCaptchaText />
      </FormControl>
      <div>
        <Button disabled={!isDirty || !isValid} type="submit" variant="primary">
          Save Password
        </Button>
      </div>
    </Form>
  )
}
