import { IconPlus } from "@tabler/icons-react"
import { Link } from "@tanstack/react-router"
import { Button, Pagination } from "@vindral/components"
import { Page, PageContainer, PageContent, PageFooter, PageHeader } from "../../../atoms/Page"
import { Breadcrumbs } from "../../../molecules/Breadcrumbs"
import { RegionTable, UseRegionReactTableProps, useRegionReactTable } from "../../../organisms/RegionTable"

export type RegionListProps = UseRegionReactTableProps & {
  saveColumnVisibility: () => void
  resetColumnVisibilityToDefault: () => void
  isStaleData: boolean
  totalCount: number
}

export function RegionList({ isStaleData, totalCount, ...props }: RegionListProps) {
  const regionTable = useRegionReactTable(props)

  if (props.data.length === 0 && !props.state?.globalFilter && !isStaleData) {
    return (
      <div className="relative flex justify-center pt-40">
        <div>
          <h1 className="font-medium">There is nothing here right now</h1>
          <p className="py-1 text-fg-subtle last-of-type:pb-3">You will be able to see regions here very soon.</p>
        </div>
      </div>
    )
  }

  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
            <Link to="/regions/create">
              <Button variant="primary">
                <IconPlus size={16} />
                Create region
              </Button>
            </Link>
          </div>
        </PageHeader>
        <PageContainer>
          <div className="py-4">
            <RegionTable {...props} table={regionTable} />
          </div>
        </PageContainer>
      </PageContent>
      <PageFooter>
        <div className="flex h-16 items-center justify-between">
          <div>
            {totalCount} Region{totalCount > 1 ? "s" : ""}
          </div>
          <Pagination
            pageIndex={regionTable.getState().pagination.pageIndex}
            pageCount={regionTable.getPageCount()}
            nextPage={() => regionTable.nextPage()}
            previousPage={() => regionTable.previousPage()}
          />
        </div>
      </PageFooter>
    </Page>
  )
}
