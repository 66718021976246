import { Divider, formatBitRate } from "@vindral/components"
import { Statistic } from "../../molecules/Statistic"
import { trpc } from "../../trpc"
import { compactNumber } from "../../utils/compactNumber"
import { isDefined } from "../../utils/is-defined"
import { AnalyticsQueryArgs } from "./Analytics"
import { ViewersOverTimeGraph } from "./graphs/ViewersOverTime"

const OverviewStatistics = (props: AnalyticsQueryArgs) => {
  const { time } = props
  const lookback = time.to - time.from
  const getChange = (first: number | undefined, second: number | undefined) => {
    if (typeof first === "undefined" || typeof second === "undefined") {
      return undefined
    }
    return first / second - 1
  }

  const { data: viewerSessions } = trpc.analytics.viewerSessions.useQuery(
    {
      ...props,
      time: time.to,
      lookback,
    },
    { keepPreviousData: true }
  )
  const { data: previousViewerSessions } = trpc.analytics.viewerSessions.useQuery(
    {
      ...props,
      time: time.from,
      lookback,
    },
    { keepPreviousData: true }
  )

  const { country, continent, ...withoutCountryAndContinent } = props
  const { data: viewerBitrateAverage } = trpc.analytics.viewerBitrateAverage.useQuery(
    {
      ...withoutCountryAndContinent,
      time: time.to,
      lookback,
    },
    { keepPreviousData: true }
  )
  const { data: previousViewerBitrateAverage } = trpc.analytics.viewerBitrateAverage.useQuery(
    {
      ...withoutCountryAndContinent,
      time: time.from,
      lookback,
    },
    { keepPreviousData: true }
  )

  const { data: timeToFirstFrameMedian } = trpc.analytics.timeToFirstFrameMedian.useQuery(
    {
      ...props,
    },
    { keepPreviousData: true }
  )

  const { data: roundTripTimeMedian } = trpc.analytics.roundTripTimeMedian.useQuery(
    {
      ...props,
    },
    { keepPreviousData: true }
  )

  return (
    <div className="my-4 mb-12 flex flex-col gap-4 xl:flex-row">
      <div className="flex gap-4 xl:w-1/2">
        <div className="w-1/2 transition-colors">
          <Statistic
            value={isDefined(viewerSessions) ? compactNumber(viewerSessions) : "-"}
            title="SESSION COUNT"
            subtext="Total unique viewer sessions"
            info="Total amount of unique viewer sessions over the selected time period"
            tooltip="Trend comparing selected time period to earlier adjacent period of same length"
            change={getChange(viewerSessions, previousViewerSessions)}
          />
        </div>
        <Divider variant="vertical" />
        <div className="w-1/2 transition-colors">
          <Statistic
            value={timeToFirstFrameMedian ? `${Math.round(timeToFirstFrameMedian) / 1000} s` : "-"}
            title="TTFF"
            subtext="Median time to first frame"
            info="Time to first frame, represents the time from creation of player until media starts playing"
          />
        </div>
      </div>
      <div className="hidden xl:flex">
        <Divider variant="vertical" />
      </div>
      <div className="flex gap-4 xl:w-1/2 ">
        <div className="w-1/2 transition-colors">
          <Statistic
            value={isDefined(viewerBitrateAverage) ? formatBitRate(viewerBitrateAverage) : "-"}
            title="BIT RATE"
            subtext="Average session bit rate"
            info={
              <div>
                <div>Average bit rate of all sessions over the selected time period</div>
                <div>Disregards selected country and region</div>
              </div>
            }
            tooltip="Trend comparing selected time period to earlier adjacent period of same length"
            change={getChange(viewerBitrateAverage, previousViewerBitrateAverage)}
          />
        </div>
        <Divider variant="vertical" />
        <div className="w-1/2 transition-colors">
          <Statistic
            value={roundTripTimeMedian ? `${Math.round(roundTripTimeMedian)} ms` : "-"}
            title="RTT"
            subtext="Median round trip time"
            info="Round trip time, represents the time it takes for a request from a viewer to reach the server and get back"
          />
        </div>
      </div>
    </div>
  )
}

export const Overview = (props: AnalyticsQueryArgs): JSX.Element => {
  return (
    <>
      <OverviewStatistics {...props} />
      <ViewersOverTimeGraph {...props} />
    </>
  )
}
