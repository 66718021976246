import { Route } from "@tanstack/react-router"
import { onboardedRoute } from "../onboarded"

export const channelIndexRoute = new Route({
  getParentRoute: () => onboardedRoute,
  preSearchFilters: [(search) => search],
  path: "channels",
  getContext: () => {
    return {
      pageTitle: "Channels",
    }
  },
})
