import { useAbility } from "@casl/react"
import { Action } from "@core-services/data-types"
import { ChannelGroupFindManyParameters } from "@core-services/portal"
import { Route, useNavigate, useSearch } from "@tanstack/react-router"
import { PaginationState, SortingState, VisibilityState } from "@tanstack/react-table"
import { useToast } from "@vindral/components"
import { useCallback, useEffect, useState } from "react"
import { useAsyncFn, useLocalStorage } from "react-use"
import { z } from "zod"
import { channelGroupIndexRoute } from "."
import { AbilityContext } from "../../acl"
import { useNoMetricsEdge } from "../../hooks/useNoMetricsEdge"
import { ChannelGroupList } from "../../templates/channel-group/ChannelGroupList"
import { trpc } from "../../trpc"

const defaultColumns = {
  name: true,
  channelGroupId: false,
  "organization.name": false,
}

const params = z.object({
  channelGroupView: z
    .object({
      sorting: z.array(
        z.object({
          id: ChannelGroupFindManyParameters.shape.orderBy,
          desc: z.boolean(),
        })
      ),
      pagination: z.object({
        pageIndex: z.number().nonnegative(),
        pageSize: z.number().nonnegative(),
      }),
      search: z.string().default(""),
    })
    .catch({
      sorting: [{ desc: false, id: "name" }],
      pagination: { pageIndex: 0, pageSize: 100 },
      search: "",
    }),
})

type SearchParams = ReturnType<typeof useSearch<typeof channelGroupListRoute["id"]>>

function toTrpcParams(params: SearchParams) {
  const { pagination, sorting, search } = params.channelGroupView

  return {
    cursor: pagination.pageIndex * pagination.pageSize,
    take: pagination.pageSize,
    orderBy: sorting[0]?.id ?? "name",
    order: sorting[0]?.desc ? "desc" : "asc",
    search: search.length > 0 ? search : undefined,
    filter: params.organizationId ? { organization: { publicId: params.organizationId } } : undefined,
  } as const
}

export const channelGroupListRoute = new Route({
  path: "/",
  getParentRoute: () => channelGroupIndexRoute,
  validateSearch: params,
  onLoad: async ({ search, context: { trpcContext } }) => {
    const params = toTrpcParams(search)

    await trpcContext.channelGroup.findMany.ensureData(params, { staleTime: 1000 })
  },
  preSearchFilters: [(search) => search],
  component: Page,
})

function Page() {
  const searchParams = useSearch({ from: channelGroupListRoute.id })
  return <List key={searchParams.organizationId} />
}

function List() {
  const searchParams = useSearch({ from: channelGroupListRoute.id })
  const navigate = useNavigate({ from: channelGroupListRoute.id })
  const trpcParams = toTrpcParams(searchParams)
  const { toast } = useToast()
  const ability = useAbility(AbilityContext)
  const noMetricsEdge = useNoMetricsEdge()

  const { data } = trpc.channelGroup.findMany.useQuery(trpcParams, {
    keepPreviousData: true,
    staleTime: 1000,
    refetchInterval: 10000,
  })

  const [sorting, setSorting] = useState<SortingState>(searchParams.channelGroupView.sorting)
  const [pagination, setPagination] = useState<PaginationState>(searchParams.channelGroupView.pagination)
  const [globalFilter, setGlobalFilter] = useState(searchParams.channelGroupView.search)
  const [value, setValue, remove] = useLocalStorage<VisibilityState>("channel-group-columns", defaultColumns)
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(value ?? defaultColumns)
  const pageCount = Math.ceil((data?.total ?? 0) / pagination.pageSize)
  const [navigationState, doNavigate] = useAsyncFn(
    async (...args: Parameters<typeof navigate>) => {
      return navigate(...args)
    },
    [navigate]
  )

  if (globalFilter !== searchParams.channelGroupView.search && pagination.pageIndex !== 0) {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }))
  }

  useEffect(() => {
    void doNavigate({
      from: channelGroupListRoute.id,
      to: "/channel-groups",
      replace: true,
      params: {},
      search: (prev) => {
        return { ...prev, channelGroupView: { sorting, pagination, search: globalFilter } }
      },
    })
  }, [doNavigate, sorting, pagination, globalFilter, setPagination])

  const saveColumnVisibility = useCallback(() => {
    setValue(columnVisibility)
    toast({ title: "Saved configuration" })
  }, [toast, setValue, columnVisibility])

  const resetColumnVisibilityToDefault = useCallback(() => {
    remove()
    setColumnVisibility(defaultColumns)
    toast({ title: "Restored to default" })
  }, [toast, remove, setColumnVisibility])

  const onClickCreate = useCallback(() => {
    void doNavigate({
      from: channelGroupListRoute.id,
      to: "/channel-groups/create",
      params: {},
      search: (prev) => {
        return { ...prev, channelGroupView: { sorting, pagination, search: globalFilter } }
      },
    })
  }, [globalFilter, doNavigate, pagination, sorting])

  const downloadCsvUrl = searchParams.organizationId
    ? `/api/organizations/${searchParams.organizationId}/channel-groups/csv`
    : undefined

  return (
    <ChannelGroupList
      downloadCsvUrl={downloadCsvUrl}
      noMetricsEdge={noMetricsEdge}
      onClickCreate={ability.can(Action.Create, "ChannelGroup") ? onClickCreate : undefined}
      data={data?.items ?? []}
      totalCount={data?.total ?? 0}
      pageCount={pageCount}
      manualPagination
      manualSorting
      manualFiltering
      isStaleData={navigationState.loading || globalFilter !== searchParams.channelGroupView.search}
      state={{ sorting, pagination, globalFilter, columnVisibility }}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      onGlobalFilterChange={setGlobalFilter}
      onColumnVisibilityChange={setColumnVisibility}
      saveColumnVisibility={saveColumnVisibility}
      resetColumnVisibilityToDefault={resetColumnVisibilityToDefault}
    />
  )
}
