import { ApiKeyItem } from "@core-services/portal"
import { IconAdjustmentsFilled } from "@tabler/icons-react"
import { Link } from "@tanstack/react-router"
import {
  TableOptions,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSwitchItem,
  DropdownMenuTrigger,
} from "@vindral/components"
import { ReactNode } from "react"
import { DebouncedTextField } from "../molecules/DebouncedTextField"
import { FormattedDate } from "../molecules/FormattedDate"
import { InteractiveTable } from "./InteractiveTable"
import { NoResultsFound } from "./NoResultsFound"

function columnNames(id: string) {
  switch (id) {
    case "name":
      return "Name"
    case "maskedKey":
      return "Key"
    case "user.name":
      return "User"
    case "createdAt":
      return "Created At"
  }

  return id
}

function ApiKeyLink({ publicId, children }: { publicId: string; children: ReactNode }) {
  return (
    <Link to="/api-keys/edit/$id" className="whitespace-nowrap font-semibold" params={{ id: publicId }}>
      {children}
    </Link>
  )
}

function CreatedAt({ date }: { date: string }) {
  return (
    <span className="whitespace-nowrap text-fg-subtle">
      <FormattedDate date={new Date(date)} />
    </span>
  )
}

export type ApiKeyTableProps = Omit<
  TableOptions<ApiKeyItem>,
  "columns" | "getCoreRowModel" | "getSortedRowModel" | "getPaginationRowModel"
> & {
  saveColumnVisibility: () => void
  resetColumnVisibilityToDefault: () => void
}

export function ApiKeyTable(options: ApiKeyTableProps) {
  const columnHelper = createColumnHelper<Omit<ApiKeyItem, "packagerConfiguration">>()
  const columns = [
    columnHelper.accessor("name", {
      header: () => "Name",
      cell: (info) => <ApiKeyLink publicId={info.row.original.publicId}>{info.getValue()}</ApiKeyLink>,
      size: 200,
      maxSize: 0,
    }),
    columnHelper.accessor("maskedKey", {
      header: () => "Key",
      cell: (info) => <span className="whitespace-nowrap text-fg-subtle">{info.getValue()}</span>,
      // enableSorting: false,
    }),
    columnHelper.accessor("user.name", {
      id: "user.name",
      header: () => "User",
      cell: (info) => <span className="whitespace-nowrap text-fg-subtle">{info.getValue()}</span>,
      // enableSorting: false,
      size: 200,
      maxSize: 0,
    }),
    columnHelper.accessor("createdAt", {
      header: () => "Created At",
      meta: { style: { textAlign: "right" } },
      cell: (info) => <CreatedAt date={info.getValue()} />,
      size: 150,
      maxSize: 150,
    }),
  ]

  const table = useReactTable({
    ...options,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowId: (row) => row.publicId,
  })

  // If there is a filter AND no rows - that means we should show "no results".
  // This is different from zero rows and no filter - in that case we should show
  // a call to action.
  const noResults = table.getRowModel().rows.length === 0 && !!table.getState().globalFilter

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-end gap-2">
        <div>
          <DebouncedTextField
            // eslint-disable-next-line total-functions/no-unsafe-type-assertion
            initialValue={table.getState().globalFilter as string}
            onValueChange={table.setGlobalFilter}
            debounceMs={200}
            placeholder="Filter..."
          />
        </div>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button>
              <IconAdjustmentsFilled size={16} />
              Configure
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent sideOffset={4} align="start">
            {table.getAllLeafColumns().map((column) => {
              return (
                <DropdownMenuSwitchItem
                  key={column.id}
                  checked={column.getIsVisible()}
                  onSelect={() => column.toggleVisibility()}
                >
                  {columnNames(column.id)}
                </DropdownMenuSwitchItem>
              )
            })}
            <DropdownMenuSeparator />
            <DropdownMenuItem onSelect={() => options.saveColumnVisibility()}>Save</DropdownMenuItem>
            <DropdownMenuItem onSelect={() => options.resetColumnVisibilityToDefault()}>
              Reset to default
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      {noResults ? <NoResultsFound /> : <InteractiveTable table={table} />}
    </div>
  )
}
