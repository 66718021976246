import { AccessPermissionRole } from "@core-services/data-types"
import { z } from "zod"

export const UpdateUser = z.object({
  id: z.string().uuid(),
  password: z.string().min(8).max(128).optional(),
  email: z.string().email().optional(),
  name: z.string().max(100).optional(),
  accessPermissions: z
    .array(
      z.object({
        role: z.nativeEnum(AccessPermissionRole),
        organization: z.object({
          publicId: z.string(),
        }),
      })
    )
    .optional(),
})

export type UpdateUser = z.infer<typeof UpdateUser>
