import { ChannelMode } from "@core-services/data-types"
import { AppAbility, UpdateChannel } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@vindral/components"
import { useEffect, useMemo } from "react"
import { Controller, useForm } from "react-hook-form"
import { checkIfSuperUser } from "../../../acl"
import { FormSection } from "../../../atoms/FormSection"
import { SaveButton } from "../../../atoms/SaveButton"
import { SaveSection } from "../../../atoms/SaveSection"
import { useQueryPackagerServersWithoutAllocations, useQuerySites } from "../../../http/queries"
import { RouteBlockDialog } from "../../../molecules/RouteBlockDialog"
import { ChannelFindById } from "../ChannelEdit"

const AnyChannelSite = "Any"
const AnyChannelHostname = "Any"

const resolver = zodResolver(UpdateChannel)

export interface ChannelEditDetailsSourceFeedProps {
  ability: AppAbility
  channel: ChannelFindById
  onSave: (channel: UpdateChannel) => Promise<void>
}

export function ChannelEditDetailsSourceFeed({ ability, channel, onSave }: ChannelEditDetailsSourceFeedProps) {
  const defaultValues = useMemo(
    () => ({
      channelId: channel.channelId,
      mode: channel.mode,
      site: channel.site?.code ?? AnyChannelSite,
      hostName: channel.hostName ?? AnyChannelHostname,
    }),
    [channel]
  )

  const {
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { isDirty, isValid, isSubmitting, isSubmitSuccessful },
    reset,
  } = useForm<UpdateChannel>({
    resolver,
    mode: "onChange",
    defaultValues,
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultValues, { keepValues: true })
    }
  }, [isSubmitSuccessful, reset, defaultValues])

  const submit = async (channel: UpdateChannel) => {
    const data = {
      ...channel,
      site: channel.site === AnyChannelSite ? null : channel.site,
      hostName: channel.hostName === AnyChannelHostname ? null : channel.hostName,
    }
    await onSave(data)
  }

  const isSuperUser = checkIfSuperUser({ ability })

  const { data: sites } = useQuerySites({ suspense: false, enabled: isSuperUser })
  const { data: allHosts } = useQueryPackagerServersWithoutAllocations({ suspense: false, enabled: isSuperUser })
  const selectedSiteCode = watch("site")
  const selectSiteHostDisabled = watch("mode") !== ChannelMode.Manual
  const hosts = useMemo(() => {
    if (!selectedSiteCode || !allHosts) {
      return []
    }

    return allHosts.filter((host) => host.nodeHostname.startsWith(selectedSiteCode))
  }, [allHosts, selectedSiteCode])

  if (!sites || !allHosts || !isSuperUser) {
    return <></>
  }

  const columnWidth = "w-full max-w-lg max-[1280px]:max-w-md"
  return (
    <div className="py-4">
      <RouteBlockDialog condition={isDirty && !isSubmitting} />
      <form onSubmit={handleSubmit(submit)} className="flex flex-col gap-4">
        <FormSection
          width={columnWidth}
          title="Channel Mode"
          description="Determine whether or not site and host should be configured automatically or manually. Manual mode is required if ingest type is MPEG-TS."
        >
          <Controller
            name="mode"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col items-start gap-1">
                <Select
                  value={field.value}
                  onValueChange={(value) => {
                    if (value === ChannelMode.Auto) {
                      // Reset selection when auto is set
                      setValue("site", AnyChannelSite, { shouldDirty: true })
                      setValue("hostName", AnyChannelHostname, { shouldDirty: true })
                    }
                    field.onChange(value)
                  }}
                >
                  <SelectTrigger size="medium">
                    <SelectValue className="font-medium" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem key={ChannelMode.Auto} value={ChannelMode.Auto}>
                      Auto
                    </SelectItem>
                    <SelectItem key={ChannelMode.Manual} value={ChannelMode.Manual}>
                      Manual
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>
            )}
          />
        </FormSection>
        <FormSection width={columnWidth} title="Site" description="Choose which site to be used to serve the channel.">
          <Controller
            name="site"
            control={control}
            render={({ field }) => (
              <div className="truncate">
                <Select
                  disabled={selectSiteHostDisabled}
                  value={field.value || AnyChannelSite}
                  onValueChange={(value) => {
                    setValue("hostName", AnyChannelHostname, { shouldDirty: true })
                    field.onChange(value)
                  }}
                >
                  <SelectTrigger size="medium">
                    <SelectValue className="font-medium" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value={AnyChannelSite}>Any</SelectItem>
                    {sites?.map((site) => {
                      const fullString = `${site.code} - ${site.name}`
                      const itemString = fullString.length > 30 ? fullString.slice(0, 28) + "..." : fullString
                      return (
                        <SelectItem key={site.code} value={site.code}>
                          {itemString}
                        </SelectItem>
                      )
                    })}
                  </SelectContent>
                </Select>
              </div>
            )}
          />
        </FormSection>

        <FormSection width={columnWidth} title="Host" description="Choose which host to be used to serve the channel.">
          <Controller
            name="hostName"
            control={control}
            render={({ field }) => (
              <div className="truncate">
                <Select
                  disabled={selectSiteHostDisabled}
                  value={field.value || AnyChannelHostname}
                  onValueChange={field.onChange}
                >
                  <SelectTrigger size="medium">
                    <SelectValue className="font-medium" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value={AnyChannelHostname}>Any</SelectItem>
                    {hosts?.map((host) => (
                      <SelectItem key={host.nodeHostname} value={host.nodeHostname}>
                        {host.nodeHostname}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            )}
          />
        </FormSection>
        <SaveSection width={columnWidth}>
          <SaveButton text="Save" disabled={!isDirty || !isValid} />
        </SaveSection>
      </form>
    </div>
  )
}
