import { IconAdjustmentsFilled } from "@tabler/icons-react"
import {
  TableOptions,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSwitchItem,
  DropdownMenuTrigger,
} from "@vindral/components"
import { DebouncedTextField } from "../molecules/DebouncedTextField"
import { UsageRowItem } from "../routes/usage/usage-list"
import { formatGigabyte } from "../utils/format"
import { InteractiveTable } from "./InteractiveTable"
import { NoResultsFound } from "./NoResultsFound"

function columnNames(id: string) {
  switch (id) {
    case "channelName":
      return "Channel Name"
    case "channelId":
      return "Channel ID"
    case "bytesEgress":
      return "Egress Traffic"
    case "bytesThumbnail":
      return "Thumbnail Traffic"
    case "bytesTotal":
      return "Total"
  }

  return id
}

const GigaByteCell = ({ bytes }: { bytes: number }) => {
  const text = formatGigabyte(bytes)

  return <span className="text-fg-subtle">{text}</span>
}

export type UsageTableProps = Omit<
  TableOptions<UsageRowItem>,
  "columns" | "getCoreRowModel" | "getSortedRowModel" | "getPaginationRowModel"
> & {
  saveColumnVisibility: () => void
  resetColumnVisibilityToDefault: () => void
}

export function UsageTable(options: UsageTableProps) {
  const columnHelper = createColumnHelper<UsageRowItem>()
  const columns = [
    columnHelper.accessor("channelName", {
      header: () => "Channel Name",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("channelId", {
      header: () => "Channel ID",
      cell: (info) => <span className="text-fg-subtle">{info.getValue()}</span>,
    }),
    columnHelper.accessor("bytesEgress", {
      header: () => "Egress Traffic",
      cell: (info) => <GigaByteCell bytes={info.getValue()} />,
    }),
    columnHelper.accessor("bytesThumbnail", {
      header: () => "Thumbnail Traffic",
      cell: (info) => <GigaByteCell bytes={info.getValue()} />,
    }),
    columnHelper.accessor("bytesTotal", {
      header: () => "Total",
      cell: (info) => <GigaByteCell bytes={info.getValue()} />,
    }),
  ]

  const table = useReactTable({
    ...options,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowId: (row) => row.channelId,
  })

  // If there is a filter AND no rows - that means we should show "no results".
  // This is different from zero rows and no filter - in that case we should show
  // a call to action.
  const noResults = table.getRowModel().rows.length === 0 && !!table.getState().globalFilter

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-end gap-2">
        <div>
          <DebouncedTextField
            // eslint-disable-next-line total-functions/no-unsafe-type-assertion
            initialValue={table.getState().globalFilter as string}
            onValueChange={table.setGlobalFilter}
            debounceMs={200}
            placeholder="Filter..."
          />
        </div>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button>
              <IconAdjustmentsFilled size={16} />
              Configure
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent sideOffset={4} align="start">
            {table.getAllLeafColumns().map((column) => {
              return (
                <DropdownMenuSwitchItem
                  key={column.id}
                  checked={column.getIsVisible()}
                  onSelect={() => column.toggleVisibility()}
                >
                  {columnNames(column.id)}
                </DropdownMenuSwitchItem>
              )
            })}
            <DropdownMenuSeparator />
            <DropdownMenuItem onSelect={() => options.saveColumnVisibility()}>Save</DropdownMenuItem>
            <DropdownMenuItem onSelect={() => options.resetColumnVisibilityToDefault()}>
              Reset to default
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      {noResults ? <NoResultsFound /> : <InteractiveTable table={table} includePaginationFooter />}
    </div>
  )
}
