import { Action, OrganizationBillingStatus } from "@core-services/data-types"
import { AppAbility } from "@core-services/portal"
import {
  TableOptions,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { Button, Chip, ConfirmDialogContent, Dialog, DialogTrigger } from "@vindral/components"
import { useMemo } from "react"
import { accessPermissionsSubjectInOrganization } from "../acl"
import { UserAccessPermissions, UserItem } from "../interfaces/User"
import { AccessPermissionRoleSelect } from "../molecules/AccessPermissionRoleSelect"
import { InteractiveTable } from "./InteractiveTable"

export type UsersTableProps = Omit<
  TableOptions<UserItem>,
  "columns" | "getCoreRowModel" | "getSortedRowModel" | "getPaginationRowModel"
> & {
  ability: AppAbility
  organizationId: string | undefined
  isAdministrator: boolean
  removeUser: (user: UserItem, role: UserAccessPermissions) => void
  changeUserAccessPermission: (user: UserItem, role: UserAccessPermissions, newRole: UserAccessPermissions) => void
}

function RemoveButton(props: {
  disabled: boolean
  user: UserItem
  organizationId: string
  removeUserPermission: (user: UserItem, role: UserAccessPermissions) => void
}) {
  const { user, organizationId, removeUserPermission, disabled } = props
  const role = user.accessPermissions.find((ap) => ap.organization.publicId === organizationId)?.role
  const onClick = () => {
    if (!role) {
      return
    }
    removeUserPermission(user, role)
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button size="small" data-pw="deleteButton" variant="danger" disabled={disabled || !role}>
          Remove
        </Button>
      </DialogTrigger>
      <ConfirmDialogContent
        onConfirm={onClick}
        title={`Remove user from organization`}
        description={
          <>
            Are you sure you want to remove user <span className="font-medium">{user.email}</span> from the
            organization?
          </>
        }
      />
    </Dialog>
  )
}

export function AccessPermissionSelect(props: {
  disabled: boolean
  user: UserItem
  organizationId: string
  changeUserPermission: (user: UserItem, role: UserAccessPermissions, newRole: UserAccessPermissions) => void
}) {
  const { user, organizationId, changeUserPermission, disabled } = props
  const accessPermission = user.accessPermissions.find((ap) => ap.organization.publicId === organizationId)
  const currentRole = accessPermission?.role
  const organizationUnderTrial = accessPermission?.organization.billingStatus !== OrganizationBillingStatus.Billable

  const onSelect = (newRole: UserAccessPermissions) => {
    if (!currentRole) {
      return
    }
    changeUserPermission(user, currentRole, newRole)
  }

  return (
    <AccessPermissionRoleSelect
      disabled={disabled || !currentRole}
      value={currentRole}
      organizationUnderEvaluation={organizationUnderTrial}
      onValueChange={(newRole: UserAccessPermissions) => {
        onSelect(newRole)
      }}
    />
  )
}

export function UsersManageTable(props: UsersTableProps) {
  const columns = useMemo(() => {
    const canUpdateAccessPermissions = (user: UserItem) => {
      return (
        props.organizationId &&
        props.ability.can(Action.Manage, accessPermissionsSubjectInOrganization(user.publicId, props.organizationId))
      )
    }

    const columnHelper = createColumnHelper<UserItem>()

    return [
      columnHelper.accessor("name", {
        header: () => "Name",
        cell: (info) => (
          <div>
            <span className="block whitespace-nowrap font-semibold">{info.getValue()}</span>
            <span className="block whitespace-nowrap lowercase text-fg-subtle">{info.row.original.email}</span>
          </div>
        ),
        size: Number.MAX_SAFE_INTEGER,
      }),
      columnHelper.display({
        id: "twoFactorEnabled",
        header: () => "Two-factor Authentication",
        cell: (info) => (
          <Chip color={info.row.original.twoFactorEnabled ? "green" : "red"}>
            {info.row.original.twoFactorEnabled ? "Enabled" : "Disabled"}
          </Chip>
        ),
        size: 1,
        meta: { style: { textAlign: "right" } },
      }),
      columnHelper.display({
        id: "accessPermissions",
        header: () => "Permissions",
        meta: { style: { textAlign: "right" } },
        cell: (info) => {
          return props.organizationId ? (
            <AccessPermissionSelect
              disabled={!canUpdateAccessPermissions(info.row.original)}
              user={info.row.original}
              organizationId={props.organizationId}
              changeUserPermission={props.changeUserAccessPermission}
            />
          ) : (
            <></>
          )
        },
      }),
      columnHelper.display({
        id: "publicId",
        header: () => "",
        meta: { style: { textAlign: "right" } },
        cell: (info) => {
          return props.organizationId ? (
            <RemoveButton
              disabled={!canUpdateAccessPermissions(info.row.original)}
              user={info.row.original}
              organizationId={props.organizationId}
              removeUserPermission={props.removeUser}
            />
          ) : null
        },
        size: 100,
      }),
    ]
  }, [props])

  const table = useReactTable({
    ...props,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowId: (row) => row.publicId,
    state: {
      ...props.state,
      columnVisibility: {
        twoFactorEnabled: props.isAdministrator,
      },
    },
  })

  return <InteractiveTable table={table} includePaginationFooter />
}
