import { IsNotEmpty } from "class-validator"
import { IsEntityName } from "../common"

export class CreateChannelGroupDTO {
  @IsEntityName()
  name!: string

  @IsNotEmpty()
  organization!: number
}
