import { useAbility } from "@casl/react"
import { Navigate, Route, useNavigate, useSearch } from "@tanstack/react-router"
import { toast } from "@vindral/components"
import { z } from "zod"
import { AbilityContext, checkIfSuperUser } from "../../acl"
import { OrganizationSettings } from "../../templates/organization-settings/OrganizationSettings"
import { trpc } from "../../trpc"
import { onboardedRoute } from "../onboarded"
import { getChildRoutes } from "../organization/organization-edit.common"

const organizationSettingsParams = z.object({
  organizationId: z.string(),
})
const trpcOptions = { staleTime: 1000 }

const organizationSettingsRoute = new Route({
  getParentRoute: () => onboardedRoute,
  path: "settings",
  validateSearch: organizationSettingsParams,
  preSearchFilters: [(search) => search],
  component: SettingsWrapper,
  onLoad: async ({ preload, context: { trpcContext }, search: { organizationId } }) => {
    if (preload) {
      await trpcContext.organization.findOne.prefetch({ publicId: organizationId }, trpcOptions)
    } else {
      await trpcContext.organization.findOne.fetch({ publicId: organizationId }, trpcOptions)
    }
  },
  getContext: () => {
    return {
      pageTitle: "Settings",
    }
  },
})

function SettingsWrapper() {
  const { organizationId: publicId } = useSearch({ from: organizationSettingsRoute.id })
  const context = trpc.useContext()
  const navigate = useNavigate()
  const { data: organization } = trpc.organization.findOne.useQuery({ publicId }, trpcOptions)
  const ability = useAbility(AbilityContext)

  const deleteMutation = trpc.organization.delete.useMutation({
    async onSuccess() {
      await context.organization.findMany.invalidate()
      await context.organization.findOne.invalidate({ publicId })

      await navigate({ to: "/dashboard", replace: true, search: { organizationId: undefined } })
      toast({ title: "Deleted successfully!" })
    },
    onError: (error) => {
      toast({ title: "Failed to delete!", description: error.message })
    },
  })

  if (!organization) {
    return null
  }

  const onDelete = async () => {
    await deleteMutation.mutateAsync({ publicId })
  }

  return <OrganizationSettings organization={organization} ability={ability} onDelete={onDelete} />
}

const organizationSettingsIndexRoute = new Route({
  getParentRoute: () => organizationSettingsRoute,
  path: "/",
  component: IndexRoutePage,
})

function IndexRoutePage() {
  const ability = useAbility(AbilityContext)
  const isSuperUser = checkIfSuperUser({ ability })
  const search = useSearch({ from: organizationSettingsIndexRoute.id })

  return isSuperUser ? (
    <Navigate to="/settings/general" replace search={search} />
  ) : (
    <Navigate to="/settings/webhooks" replace search={search} />
  )
}

const childRoutes = getChildRoutes(organizationSettingsRoute)
export const organizationSettingsRoutes = organizationSettingsRoute.addChildren([
  organizationSettingsIndexRoute,
  ...childRoutes,
])
