import { useAbility } from "@casl/react"
import { AbilityContext, checkIfSuperUser } from "../acl"
import { trpc } from "../trpc"

export function useNoMetricsEdge() {
  const ability = useAbility(AbilityContext)
  const isSuperUser = checkIfSuperUser({ ability })

  if (!isSuperUser) {
    return undefined
  }

  const { data } = trpc.configuration.getNoMetricsEdge.useQuery(undefined, { staleTime: 600_000 })

  return data ? data : undefined
}
