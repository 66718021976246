import * as RadixHoverCard from "@radix-ui/react-hover-card"
import { ComponentProps, ElementRef, forwardRef } from "react"

const StyledContent = forwardRef<
  ElementRef<typeof RadixHoverCard.Content>,
  ComponentProps<typeof RadixHoverCard.Content>
>((props, ref) => {
  return (
    <RadixHoverCard.Content
      ref={ref}
      {...props}
      className="z-50 rounded-sm border border-divider bg-component text-fg shadow-lg animate-in fade-in zoom-in-95 origin-radix-hover-card data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=closed]:zoom-out-95"
    >
      {props.children}
    </RadixHoverCard.Content>
  )
})

StyledContent.displayName = "StyledContent"

export const HoverCardContent = StyledContent
export const HoverCard = RadixHoverCard.Root
export const HoverCardTrigger = RadixHoverCard.Trigger
export const HoverCardPortal = RadixHoverCard.Portal
