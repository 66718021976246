import { useAbility } from "@casl/react"
import { UpdateRegion } from "@core-services/portal"
import { Route, useNavigate, useParams } from "@tanstack/react-router"
import { toast } from "@vindral/components"
import { z } from "zod"
import { regionIndexRoute } from "."
import { AbilityContext } from "../../acl"
import { RegionEdit } from "../../templates/infrastructure/region/RegionEdit"
import { trpc } from "../../trpc"

const Params = z.object({ publicId: z.string() })
const trpcOptions = { staleTime: 1000 }

export const regionEditRoute = new Route({
  getParentRoute: () => regionIndexRoute,
  preSearchFilters: [(search) => search],
  path: "edit/$publicId",
  parseParams: (params) => Params.parse(params),
  component: Edit,
  onLoad: async ({ preload, context: { trpcContext }, params }) => {
    if (preload) {
      await trpcContext.region.findById.prefetch(params, trpcOptions)
    } else {
      await trpcContext.region.findById.fetch(params, trpcOptions)
    }
  },
  getContext: () => {
    return {
      pageTitle: "Edit Region",
    }
  },
})

function Edit() {
  const context = trpc.useContext()
  const navigate = useNavigate()
  const { publicId } = useParams({ from: regionEditRoute.id })
  const { data } = trpc.region.findById.useQuery({ publicId }, trpcOptions)
  const ability = useAbility(AbilityContext)

  const updateMutation = trpc.region.update.useMutation({
    async onSuccess(input) {
      await context.region.findMany.invalidate()

      if (!input || !input.publicId) {
        return
      }

      context.region.findById.setData({ publicId: input.publicId }, input)

      // The routes getContext does not get invalidated without navigate
      void navigate({ to: ".", replace: true })
      toast({ title: "Saved successfully!" })
    },
    onError: (error) => {
      toast({ title: "Failed to save!", description: error.message })
    },
  })

  const deleteMutation = trpc.region.delete.useMutation({
    async onSuccess() {
      await context.region.findMany.invalidate()
      await context.region.findById.invalidate({ publicId })

      // The routes getContext does not get invalidated without navigate
      await navigate({ to: "/regions", replace: true })
      toast({ title: "Deleted successfully!" })
    },
    onError: (error) => {
      toast({ title: "Failed to delete!", description: error.message })
    },
  })

  if (!data) {
    return null
  }

  const onUpdate = async (region: UpdateRegion) => {
    updateMutation.reset()
    await updateMutation.mutateAsync({ ...region, publicId })
  }

  const onDelete = async () => {
    await deleteMutation.mutateAsync({ publicId })
  }

  return (
    <RegionEdit
      region={data}
      onUpdate={onUpdate}
      onDelete={onDelete}
      ability={ability}
      error={updateMutation.error ?? deleteMutation.error}
    />
  )
}
