import { IsEmail, IsString, Length } from "class-validator"
import { AccessPermissionsDTO } from "../access-permissions/access-permissions.dto"
import { IsAccessPermissions, IsEntityName } from "../common"

export class CreateUserDTO {
  @IsString()
  @Length(8, 128)
  password!: string

  @IsEmail()
  email!: string

  @IsEntityName()
  name!: string

  @IsAccessPermissions()
  accessPermissions!: AccessPermissionsDTO[]
}
