export const configSchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  title: "Config",
  oneOf: [
    {
      type: "object",
      required: ["version"],
      properties: {
        aliases: {
          default: [],
          type: "array",
          items: {
            type: "object",
            required: ["key", "value"],
            properties: {
              key: {
                oneOf: [
                  {
                    type: "object",
                    required: ["field", "type"],
                    properties: {
                      field: {
                        type: "integer",
                        format: "int64",
                      },
                      type: {
                        type: "string",
                        enum: ["number"],
                      },
                    },
                  },
                  {
                    type: "object",
                    required: ["field", "type"],
                    properties: {
                      field: {
                        type: "string",
                      },
                      type: {
                        type: "string",
                        enum: ["string"],
                      },
                    },
                  },
                  {
                    type: "object",
                    required: ["field", "type"],
                    properties: {
                      field: {
                        type: "string",
                        format: "uuid",
                      },
                      type: {
                        type: "string",
                        enum: ["uuid"],
                      },
                    },
                  },
                ],
              },
              value: {
                oneOf: [
                  {
                    type: "object",
                    required: ["field", "type"],
                    properties: {
                      field: {
                        type: "integer",
                        format: "int64",
                      },
                      type: {
                        type: "string",
                        enum: ["number"],
                      },
                    },
                  },
                  {
                    type: "object",
                    required: ["field", "type"],
                    properties: {
                      field: {
                        type: "string",
                      },
                      type: {
                        type: "string",
                        enum: ["string"],
                      },
                    },
                  },
                  {
                    type: "object",
                    required: ["field", "type"],
                    properties: {
                      field: {
                        type: "string",
                        format: "uuid",
                      },
                      type: {
                        type: "string",
                        enum: ["uuid"],
                      },
                    },
                  },
                ],
              },
            },
          },
        },
        api: {
          default: {
            bind: "0.0.0.0:4040",
          },
          type: "object",
          required: ["bind"],
          properties: {
            bind: {
              type: "string",
            },
          },
        },
        logging: {
          default: {
            ffmpeg_log_level: "quiet",
            log_level: "info",
            log_type: "fancy",
          },
          type: "object",
          properties: {
            ffmpeg_log_level: {
              default: "quiet",
              type: "string",
              enum: ["quiet", "panic", "fatal", "error", "warning", "info", "verbose", "debug", "trace"],
            },
            log_level: {
              default: "info",
              type: "string",
              enum: ["trace", "debug", "info", "warn", "error"],
            },
            log_type: {
              default: "fancy",
              type: "string",
              enum: ["json", "fancy"],
            },
          },
        },
        multiplexers: {
          default: [],
          type: "array",
          items: {
            type: "object",
            required: ["id", "source_ids"],
            properties: {
              id: {
                type: "string",
                format: "uuid",
              },
              name: {
                default: null,
                type: ["string", "null"],
              },
              queue_size: {
                default: 10000,
                type: "integer",
                format: "uint",
                minimum: 0.0,
              },
              source_ids: {
                type: "array",
                items: {
                  type: "string",
                  format: "uuid",
                },
              },
            },
          },
        },
        reporters: {
          default: [],
          type: "array",
          items: {
            type: "object",
            oneOf: [
              {
                type: "object",
                required: ["dals_sink_id", "pop", "source_id", "type", "url"],
                properties: {
                  api_key: {
                    type: ["string", "null"],
                    format: "uuid",
                  },
                  dals_sink_id: {
                    type: "string",
                    format: "uuid",
                  },
                  hostname: {
                    type: ["string", "null"],
                  },
                  pop: {
                    type: "string",
                  },
                  source_id: {
                    type: "string",
                    format: "uuid",
                  },
                  type: {
                    type: "string",
                    enum: ["dals"],
                  },
                  url: {
                    type: "string",
                    format: "uri",
                  },
                },
              },
            ],
            required: ["id"],
            properties: {
              id: {
                type: "string",
                format: "uuid",
              },
              interval_ms: {
                default: 5000,
                type: "integer",
                format: "uint64",
                minimum: 0.0,
              },
              name: {
                default: null,
                type: ["string", "null"],
              },
            },
          },
        },
        sinks: {
          default: [],
          type: "array",
          items: {
            type: "object",
            oneOf: [
              {
                title: "Rtmp sink",
                description: "Expects the url to be using the rtmp/rtmps protocol",
                type: "object",
                required: ["type"],
                properties: {
                  type: {
                    type: "string",
                    enum: ["rtmp"],
                  },
                },
              },
              {
                title: "Mpeg-ts SINK",
                description: "Expects the url to be using the udp or rtp protocol",
                type: "object",
                required: ["output", "type"],
                properties: {
                  output: {
                    type: "object",
                  },
                  type: {
                    type: "string",
                    enum: ["mpegts"],
                  },
                },
              },
              {
                type: "object",
                required: ["ingest_id", "type"],
                properties: {
                  discontinuity_threshold_ms: {
                    type: ["integer", "null"],
                    format: "int64",
                  },
                  fragment_duration: {
                    type: ["integer", "null"],
                    format: "uint",
                    minimum: 0.0,
                  },
                  ingest_id: {
                    type: "string",
                  },
                  max_unordered_dts_count: {
                    default: 50,
                    type: "integer",
                    format: "uint",
                    minimum: 0.0,
                  },
                  type: {
                    type: "string",
                    enum: ["dals"],
                  },
                },
              },
              {
                type: "object",
                oneOf: [
                  {
                    type: "object",
                    required: ["http"],
                    properties: {
                      http: {
                        type: "object",
                        required: ["headers"],
                        properties: {
                          headers: {
                            type: "object",
                            additionalProperties: {
                              type: "string",
                            },
                          },
                          method: {
                            default: "post",
                            type: "string",
                            enum: ["post", "put", "get"],
                          },
                        },
                      },
                    },
                    additionalProperties: false,
                  },
                ],
                required: ["type"],
                properties: {
                  type: {
                    type: "string",
                    enum: ["screenshot"],
                  },
                },
              },
              {
                type: "object",
                required: ["type"],
                properties: {
                  max_send_queue: {
                    default: 1000,
                    type: ["integer", "null"],
                    format: "uint",
                    minimum: 0.0,
                  },
                  type: {
                    type: "string",
                    enum: ["vindral_ws"],
                  },
                },
              },
            ],
            required: ["id", "source_id", "url"],
            properties: {
              id: {
                type: "string",
                format: "uuid",
              },
              name: {
                default: null,
                type: ["string", "null"],
              },
              queue_size: {
                default: 10000,
                type: "integer",
                format: "uint",
                minimum: 0.0,
              },
              reconnect_delay_ms: {
                default: 1000,
                type: "integer",
                format: "uint64",
                minimum: 0.0,
              },
              source_id: {
                type: "string",
                format: "uuid",
              },
              timeout_ms: {
                type: ["integer", "null"],
                format: "uint64",
                minimum: 0.0,
              },
              url: {
                type: "string",
                format: "uri",
              },
            },
          },
        },
        sources: {
          default: [],
          type: "array",
          items: {
            type: "object",
            oneOf: [
              {
                title: "Rtmp source",
                description: "Expects the url to be using the rtmp/rtmps protocol",
                type: "object",
                required: ["type"],
                properties: {
                  buffer: {
                    default: 0,
                    type: "integer",
                    format: "uint",
                    minimum: 0.0,
                  },
                  type: {
                    type: "string",
                    enum: ["rtmp"],
                  },
                },
              },
              {
                title: "Mpeg-ts source",
                description: "Expects the url to be using the udp or rtp protocol",
                type: "object",
                required: ["type"],
                properties: {
                  type: {
                    type: "string",
                    enum: ["mpegts"],
                  },
                },
              },
              {
                title: "Decklink source",
                type: "object",
                required: ["device", "type"],
                properties: {
                  audio_depth: {
                    type: ["integer", "null"],
                    format: "uint32",
                    minimum: 0.0,
                  },
                  audio_timestamp_source: {
                    type: ["string", "null"],
                    enum: ["video", "audio", "reference", "wallclock", "abs_wallclock"],
                  },
                  channels: {
                    type: ["integer", "null"],
                    format: "uint32",
                    minimum: 0.0,
                  },
                  device: {
                    type: "string",
                  },
                  format: {
                    type: ["string", "null"],
                    enum: ["UYVY422", "YUV422P10", "ARGB", "BGRA", "RGB10"],
                  },
                  format_code: {
                    type: ["string", "null"],
                    enum: [
                      "BmdNTSC",
                      "BmdNTSC2398",
                      "BmdPAL",
                      "BmdNTSCp",
                      "BmdHD1080p2398",
                      "BmdHD1080p24",
                      "BmdHD1080p25",
                      "BmdHD1080p2997",
                      "BmdHD1080p30",
                      "BmdHD1080i50",
                      "BmdHD1080i5994",
                      "BmdHD1080i6000",
                      "BmdHD1080p50",
                      "BmdHD1080p5994",
                      "BmdHD1080p6000",
                      "BmdHD720p50",
                      "BmdHD720p5994",
                      "BmdHD720p60",
                      "Bmd2k2398",
                      "Bmd2k24",
                      "Bmd2k25",
                      "Bmd2kDCI2398",
                      "Bmd2kDCI24",
                      "Bmd2kDCI25",
                      "Bmd4K2160p2398",
                      "Bmd4K2160p24",
                      "Bmd4K2160p25",
                      "Bmd4K2160p2997",
                      "Bmd4K2160p30",
                      "Bmd4K2160p50",
                      "Bmd4K2160p5994",
                      "Bmd4K2160p60",
                      "Bmd4kDCI2398",
                      "Bmd4kDCI24",
                      "Bmd4kDCI25",
                      "BmdUnknown",
                    ],
                  },
                  type: {
                    type: "string",
                    enum: ["decklink"],
                  },
                  video_timestamp_source: {
                    type: ["string", "null"],
                    enum: ["video", "audio", "reference", "wallclock", "abs_wallclock"],
                  },
                },
              },
              {
                type: "object",
                required: ["type"],
                properties: {
                  type: {
                    type: "string",
                    enum: ["file"],
                  },
                },
              },
            ],
            required: ["id"],
            properties: {
              connect_timeout_ms: {
                type: ["integer", "null"],
                format: "uint64",
                minimum: 0.0,
              },
              handle_timestamp_rollover: {
                default: true,
                type: "boolean",
              },
              id: {
                type: "string",
                format: "uuid",
              },
              idle_timeout_ms: {
                default: 20000,
                type: "integer",
                format: "uint64",
                minimum: 0.0,
              },
              interleave_buffer: {
                type: ["object", "null"],
                properties: {
                  max_buffer_size_ms: {
                    type: ["integer", "null"],
                    format: "uint64",
                    minimum: 0.0,
                  },
                },
              },
              name: {
                default: null,
                type: ["string", "null"],
              },
              reconnect_delay_ms: {
                default: 1000,
                type: "integer",
                format: "uint64",
                minimum: 0.0,
              },
              stream_metadata: {
                default: [],
                type: "array",
                items: {
                  anyOf: [
                    {
                      type: "object",
                      required: ["decorate", "stream_index"],
                      properties: {
                        decorate: {
                          type: "object",
                          properties: {
                            bit_rate: {
                              type: ["integer", "null"],
                              format: "int64",
                            },
                            id: {
                              type: ["integer", "null"],
                              format: "int32",
                            },
                            language: {
                              type: ["string", "null"],
                            },
                          },
                        },
                        is_required: {
                          default: false,
                          type: "boolean",
                        },
                        stream_index: {
                          type: "integer",
                          format: "uint",
                          minimum: 0.0,
                        },
                      },
                    },
                    {
                      type: "object",
                      required: ["decorate", "stream_id"],
                      properties: {
                        decorate: {
                          type: "object",
                          properties: {
                            bit_rate: {
                              type: ["integer", "null"],
                              format: "int64",
                            },
                            id: {
                              type: ["integer", "null"],
                              format: "int32",
                            },
                            language: {
                              type: ["string", "null"],
                            },
                          },
                        },
                        is_required: {
                          default: false,
                          type: "boolean",
                        },
                        stream_id: {
                          type: "integer",
                          format: "int32",
                        },
                      },
                    },
                    {
                      type: "object",
                      required: ["decorate", "stream_type"],
                      properties: {
                        decorate: {
                          type: "object",
                          properties: {
                            bit_rate: {
                              type: ["integer", "null"],
                              format: "int64",
                            },
                            id: {
                              type: ["integer", "null"],
                              format: "int32",
                            },
                            language: {
                              type: ["string", "null"],
                            },
                          },
                        },
                        is_required: {
                          default: false,
                          type: "boolean",
                        },
                        stream_type: {
                          type: "string",
                          enum: ["video", "audio"],
                        },
                      },
                    },
                  ],
                },
              },
              url: {
                type: ["string", "null"],
                format: "uri",
              },
            },
          },
        },
        transcoders: {
          default: [],
          type: "array",
          items: {
            type: "object",
            required: ["id", "source_id", "streams"],
            properties: {
              device: {
                description: "Override the hw device that will be used to accelerate transcoding",
                type: ["string", "null"],
                enum: ["cpu", "nvidia", "netint_quadra", "netint_t4xx", "qsv"],
              },
              id: {
                type: "string",
                format: "uuid",
              },
              name: {
                default: null,
                type: ["string", "null"],
              },
              queue_size: {
                description: "The default queue size to allow into the transcoder before dropping packets",
                default: 10000,
                type: "integer",
                format: "uint",
                minimum: 0.0,
              },
              source_id: {
                description: "The source id that this transcoder will receive streams and packets from",
                type: "string",
                format: "uuid",
              },
              streams: {
                description: "The streams that the transcoder will produce",
                type: "array",
                items: {
                  oneOf: [
                    {
                      type: "object",
                      required: ["id", "match_source", "type"],
                      properties: {
                        decoder: {
                          description: "Override default decoder config options",
                          default: null,
                          type: ["object", "null"],
                          properties: {
                            hw_frames_enabled: {
                              description: "Controls if the decoder should attempt to output hw frames",
                              default: true,
                              type: "boolean",
                            },
                            name: {
                              description: "Set a specific decoder to be used by name",
                              default: null,
                              type: ["string", "null"],
                            },
                            options: {
                              description: "Additional options to provide to the decoder",
                              default: {},
                              type: "object",
                              additionalProperties: {
                                anyOf: [
                                  {
                                    type: "string",
                                  },
                                  {
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["type", "value"],
                                        properties: {
                                          type: {
                                            type: "string",
                                            enum: ["string"],
                                          },
                                          value: {
                                            type: "string",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["type", "value"],
                                        properties: {
                                          type: {
                                            type: "string",
                                            enum: ["int"],
                                          },
                                          value: {
                                            type: "integer",
                                            format: "int64",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["type", "value"],
                                        properties: {
                                          type: {
                                            type: "string",
                                            enum: ["u_int"],
                                          },
                                          value: {
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["type", "value"],
                                        properties: {
                                          type: {
                                            type: "string",
                                            enum: ["double"],
                                          },
                                          value: {
                                            type: "number",
                                            format: "double",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["type", "value"],
                                        properties: {
                                          type: {
                                            type: "string",
                                            enum: ["bool"],
                                          },
                                          value: {
                                            type: "boolean",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["type", "value"],
                                        properties: {
                                          type: {
                                            type: "string",
                                            enum: ["ratio"],
                                          },
                                          value: {
                                            type: "array",
                                            items: [
                                              {
                                                type: "integer",
                                                format: "int32",
                                              },
                                              {
                                                type: "integer",
                                                format: "int32",
                                              },
                                            ],
                                            maxItems: 2,
                                            minItems: 2,
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["type", "value"],
                                        properties: {
                                          type: {
                                            type: "string",
                                            enum: ["pixel"],
                                          },
                                          value: {
                                            type: "string",
                                            format: "string",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["type", "value"],
                                        properties: {
                                          type: {
                                            type: "string",
                                            enum: ["sample"],
                                          },
                                          value: {
                                            type: "string",
                                            format: "string",
                                          },
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            },
                            thread: {
                              description: "Controls thread configuration",
                              default: null,
                              type: ["object", "null"],
                              properties: {
                                count: {
                                  default: 0,
                                  type: "integer",
                                  format: "uint32",
                                  minimum: 0.0,
                                },
                                kind: {
                                  default: "slice",
                                  type: "string",
                                  enum: ["slice", "frame"],
                                },
                              },
                            },
                          },
                        },
                        id: {
                          type: "string",
                          format: "uuid",
                        },
                        match_source: {
                          description: "Determines which source stream(s) to generate transcoded streams from",
                          anyOf: [
                            {
                              type: "object",
                              required: ["stream_id"],
                              properties: {
                                stream_id: {
                                  type: "integer",
                                  format: "int32",
                                },
                              },
                            },
                            {
                              type: "object",
                              required: ["stream"],
                              properties: {
                                stream: {
                                  type: "string",
                                  enum: ["all", "best"],
                                },
                              },
                            },
                            {
                              type: "object",
                              required: ["language"],
                              properties: {
                                language: {
                                  type: "string",
                                },
                              },
                            },
                          ],
                        },
                        name: {
                          default: null,
                          type: ["string", "null"],
                        },
                        passthrough: {
                          description: "Enables or disables passthrough of the source stream",
                          default: false,
                          type: "boolean",
                        },
                        processing: {
                          description: "Override default processing options",
                          default: null,
                          type: ["object", "null"],
                          properties: {
                            deinterlace: {
                              default: "every_field",
                              type: "string",
                              enum: ["disabled", "every_frame", "every_field"],
                            },
                            gop_alignment: {
                              description: "Describes how the transcoded renditions GOPs will be",
                              default: null,
                              anyOf: [
                                {
                                  oneOf: [
                                    {
                                      type: "string",
                                      enum: ["match_incoming"],
                                    },
                                    {
                                      type: "object",
                                      required: ["aligned"],
                                      properties: {
                                        aligned: {
                                          type: "object",
                                          required: ["gop_size_ms"],
                                          properties: {
                                            gop_size_ms: {
                                              type: "integer",
                                              format: "uint",
                                              minimum: 0.0,
                                            },
                                          },
                                        },
                                      },
                                      additionalProperties: false,
                                    },
                                  ],
                                },
                                {
                                  type: "null",
                                },
                              ],
                            },
                            hw_filtering_enabled: {
                              description: "Controls if the pipeline should attempt to use a hw scaler",
                              default: true,
                              type: "boolean",
                            },
                            max_frame_rate: {
                              description: "The maximum frame rate that will be produced for each transcoded rendition",
                              default: null,
                              type: ["array", "null"],
                              items: [
                                {
                                  type: "integer",
                                  format: "int32",
                                },
                                {
                                  type: "integer",
                                  format: "int32",
                                },
                              ],
                              maxItems: 2,
                              minItems: 2,
                            },
                          },
                        },
                        renditions: {
                          description: "The renditions that will be generated based on the source stream(s)",
                          default: [],
                          type: "array",
                          items: {
                            type: "object",
                            required: ["codec", "id"],
                            properties: {
                              bit_rate: {
                                description: "Bit rate for the video",
                                default: null,
                                type: ["integer", "null"],
                                format: "uint64",
                                minimum: 0.0,
                              },
                              codec: {
                                type: "string",
                                format: "string",
                              },
                              encoder_name: {
                                description: "Name a specific encoder implementation to use",
                                default: null,
                                type: ["string", "null"],
                              },
                              format: {
                                default: null,
                                type: "string",
                                format: "string",
                                enum: [
                                  "yuv420p",
                                  "yuyv422",
                                  "rgb24",
                                  "bgr24",
                                  "yuv422p",
                                  "yuv444p",
                                  "yuv410p",
                                  "yuv411p",
                                  "gray",
                                  "monow",
                                  "monob",
                                  "pal8",
                                  "yuvj420p",
                                  "yuvj422p",
                                  "yuvj444p",
                                  "uyvy422",
                                  "uyyvyy411",
                                  "bgr8",
                                  "bgr4",
                                  "bgr4_byte",
                                  "rgb8",
                                  "rgb4",
                                  "rgb4_byte",
                                  "nv12",
                                  "nv21",
                                  "argb",
                                  "rgba",
                                  "abgr",
                                  "bgra",
                                  "gray16be",
                                  "gray16le",
                                  "yuv440p",
                                  "yuvj440p",
                                  "yuva420p",
                                  "rgb48be",
                                  "rgb48le",
                                  "rgb565be",
                                  "rgb565le",
                                  "rgb555be",
                                  "rgb555le",
                                  "bgr565be",
                                  "bgr565le",
                                  "bgr555be",
                                  "bgr555le",
                                  "vaapi",
                                  "yuv420p16le",
                                  "yuv420p16be",
                                  "yuv422p16le",
                                  "yuv422p16be",
                                  "yuv444p16le",
                                  "yuv444p16be",
                                  "dxva2_vld",
                                  "rgb444le",
                                  "rgb444be",
                                  "bgr444le",
                                  "bgr444be",
                                  "ya8",
                                  "bgr48be",
                                  "bgr48le",
                                  "yuv420p9be",
                                  "yuv420p9le",
                                  "yuv420p10be",
                                  "yuv420p10le",
                                  "yuv422p10be",
                                  "yuv422p10le",
                                  "yuv444p9be",
                                  "yuv444p9le",
                                  "yuv444p10be",
                                  "yuv444p10le",
                                  "yuv422p9be",
                                  "yuv422p9le",
                                  "gbrp",
                                  "gbrp9be",
                                  "gbrp9le",
                                  "gbrp10be",
                                  "gbrp10le",
                                  "gbrp16be",
                                  "gbrp16le",
                                  "yuva422p",
                                  "yuva444p",
                                  "yuva420p9be",
                                  "yuva420p9le",
                                  "yuva422p9be",
                                  "yuva422p9le",
                                  "yuva444p9be",
                                  "yuva444p9le",
                                  "yuva420p10be",
                                  "yuva420p10le",
                                  "yuva422p10be",
                                  "yuva422p10le",
                                  "yuva444p10be",
                                  "yuva444p10le",
                                  "yuva420p16be",
                                  "yuva420p16le",
                                  "yuva422p16be",
                                  "yuva422p16le",
                                  "yuva444p16be",
                                  "yuva444p16le",
                                  "vdpau",
                                  "xyz12le",
                                  "xyz12be",
                                  "nv16",
                                  "nv20le",
                                  "nv20be",
                                  "rgba64be",
                                  "rgba64le",
                                  "bgra64be",
                                  "bgra64le",
                                  "yvyu422",
                                  "ya16be",
                                  "ya16le",
                                  "gbrap",
                                  "gbrap16be",
                                  "gbrap16le",
                                  "qsv",
                                  "mmal",
                                  "d3d11va_vld",
                                  "cuda",
                                  "0rgb",
                                  "rgb0",
                                  "0bgr",
                                  "bgr0",
                                  "yuv420p12be",
                                  "yuv420p12le",
                                  "yuv420p14be",
                                  "yuv420p14le",
                                  "yuv422p12be",
                                  "yuv422p12le",
                                  "yuv422p14be",
                                  "yuv422p14le",
                                  "yuv444p12be",
                                  "yuv444p12le",
                                  "yuv444p14be",
                                  "yuv444p14le",
                                  "gbrp12be",
                                  "gbrp12le",
                                  "gbrp14be",
                                  "gbrp14le",
                                  "yuvj411p",
                                  "bayer_bggr8",
                                  "bayer_rggb8",
                                  "bayer_gbrg8",
                                  "bayer_grbg8",
                                  "bayer_bggr16le",
                                  "bayer_bggr16be",
                                  "bayer_rggb16le",
                                  "bayer_rggb16be",
                                  "bayer_gbrg16le",
                                  "bayer_gbrg16be",
                                  "bayer_grbg16le",
                                  "bayer_grbg16be",
                                  "xvmc",
                                  "yuv440p10le",
                                  "yuv440p10be",
                                  "yuv440p12le",
                                  "yuv440p12be",
                                  "ayuv64le",
                                  "ayuv64be",
                                  "videotoolbox_vld",
                                  "p010le",
                                  "p010be",
                                  "gbrap12be",
                                  "gbrap12le",
                                  "gbrap10be",
                                  "gbrap10le",
                                  "mediacodec",
                                  "gray12be",
                                  "gray12le",
                                  "gray10be",
                                  "gray10le",
                                  "p016le",
                                  "p016be",
                                  "d3d11",
                                  "gray9be",
                                  "gray9le",
                                  "gbrpf32be",
                                  "gbrpf32le",
                                  "gbrapf32be",
                                  "gbrapf32le",
                                  "drm_prime",
                                  "opencl",
                                  "gray14be",
                                  "gray14le",
                                  "grayf32be",
                                  "grayf32le",
                                  "yuva422p12be",
                                  "yuva422p12le",
                                  "yuva444p12be",
                                  "yuva444p12le",
                                  "nv24",
                                  "nv42",
                                  "vulkan",
                                  "y210be",
                                  "y210le",
                                  "x2rgb10le",
                                  "x2rgb10be",
                                  "x2bgr10le",
                                  "x2bgr10be",
                                  "p210be",
                                  "p210le",
                                  "p410be",
                                  "p410le",
                                  "p216be",
                                  "p216le",
                                  "p416be",
                                  "p416le",
                                ],
                              },
                              frame_skipping: {
                                default: "none",
                                type: "string",
                                enum: ["none", "half", "quarter", "frame_every_1_seconds", "frame_every_5_seconds"],
                              },
                              gop_size_ms: {
                                description: "Gop size expressed in milliseconds",
                                default: 2000,
                                type: "integer",
                                format: "uint",
                                minimum: 0.0,
                              },
                              id: {
                                type: "string",
                                format: "uuid",
                              },
                              max_bit_rate: {
                                description: "The maximum bit rate to be allowed",
                                default: {
                                  unit: "fraction",
                                  value: 0.10000000149011612,
                                },
                                oneOf: [
                                  {
                                    type: "object",
                                    required: ["unit", "value"],
                                    properties: {
                                      unit: {
                                        type: "string",
                                        enum: ["fraction"],
                                      },
                                      value: {
                                        type: "number",
                                        format: "float",
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    required: ["unit", "value"],
                                    properties: {
                                      unit: {
                                        type: "string",
                                        enum: ["absolute"],
                                      },
                                      value: {
                                        type: "integer",
                                        format: "uint64",
                                        minimum: 0.0,
                                      },
                                    },
                                  },
                                ],
                              },
                              min_bit_rate: {
                                description: "The minimum bit rate to be allowed",
                                default: {
                                  unit: "fraction",
                                  value: 0.10000000149011612,
                                },
                                oneOf: [
                                  {
                                    type: "object",
                                    required: ["unit", "value"],
                                    properties: {
                                      unit: {
                                        type: "string",
                                        enum: ["fraction"],
                                      },
                                      value: {
                                        type: "number",
                                        format: "float",
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    required: ["unit", "value"],
                                    properties: {
                                      unit: {
                                        type: "string",
                                        enum: ["absolute"],
                                      },
                                      value: {
                                        type: "integer",
                                        format: "uint64",
                                        minimum: 0.0,
                                      },
                                    },
                                  },
                                ],
                              },
                              name: {
                                default: null,
                                type: ["string", "null"],
                              },
                              options: {
                                description: "Encoder implementation specific options",
                                default: {},
                                anyOf: [
                                  {
                                    type: "object",
                                    additionalProperties: {
                                      type: "object",
                                      additionalProperties: {
                                        anyOf: [
                                          {
                                            type: "string",
                                          },
                                          {
                                            oneOf: [
                                              {
                                                type: "object",
                                                required: ["type", "value"],
                                                properties: {
                                                  type: {
                                                    type: "string",
                                                    enum: ["string"],
                                                  },
                                                  value: {
                                                    type: "string",
                                                  },
                                                },
                                              },
                                              {
                                                type: "object",
                                                required: ["type", "value"],
                                                properties: {
                                                  type: {
                                                    type: "string",
                                                    enum: ["int"],
                                                  },
                                                  value: {
                                                    type: "integer",
                                                    format: "int64",
                                                  },
                                                },
                                              },
                                              {
                                                type: "object",
                                                required: ["type", "value"],
                                                properties: {
                                                  type: {
                                                    type: "string",
                                                    enum: ["u_int"],
                                                  },
                                                  value: {
                                                    type: "integer",
                                                    format: "uint64",
                                                    minimum: 0.0,
                                                  },
                                                },
                                              },
                                              {
                                                type: "object",
                                                required: ["type", "value"],
                                                properties: {
                                                  type: {
                                                    type: "string",
                                                    enum: ["double"],
                                                  },
                                                  value: {
                                                    type: "number",
                                                    format: "double",
                                                  },
                                                },
                                              },
                                              {
                                                type: "object",
                                                required: ["type", "value"],
                                                properties: {
                                                  type: {
                                                    type: "string",
                                                    enum: ["bool"],
                                                  },
                                                  value: {
                                                    type: "boolean",
                                                  },
                                                },
                                              },
                                              {
                                                type: "object",
                                                required: ["type", "value"],
                                                properties: {
                                                  type: {
                                                    type: "string",
                                                    enum: ["ratio"],
                                                  },
                                                  value: {
                                                    type: "array",
                                                    items: [
                                                      {
                                                        type: "integer",
                                                        format: "int32",
                                                      },
                                                      {
                                                        type: "integer",
                                                        format: "int32",
                                                      },
                                                    ],
                                                    maxItems: 2,
                                                    minItems: 2,
                                                  },
                                                },
                                              },
                                              {
                                                type: "object",
                                                required: ["type", "value"],
                                                properties: {
                                                  type: {
                                                    type: "string",
                                                    enum: ["pixel"],
                                                  },
                                                  value: {
                                                    type: "string",
                                                    format: "string",
                                                  },
                                                },
                                              },
                                              {
                                                type: "object",
                                                required: ["type", "value"],
                                                properties: {
                                                  type: {
                                                    type: "string",
                                                    enum: ["sample"],
                                                  },
                                                  value: {
                                                    type: "string",
                                                    format: "string",
                                                  },
                                                },
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    additionalProperties: {
                                      anyOf: [
                                        {
                                          type: "string",
                                        },
                                        {
                                          oneOf: [
                                            {
                                              type: "object",
                                              required: ["type", "value"],
                                              properties: {
                                                type: {
                                                  type: "string",
                                                  enum: ["string"],
                                                },
                                                value: {
                                                  type: "string",
                                                },
                                              },
                                            },
                                            {
                                              type: "object",
                                              required: ["type", "value"],
                                              properties: {
                                                type: {
                                                  type: "string",
                                                  enum: ["int"],
                                                },
                                                value: {
                                                  type: "integer",
                                                  format: "int64",
                                                },
                                              },
                                            },
                                            {
                                              type: "object",
                                              required: ["type", "value"],
                                              properties: {
                                                type: {
                                                  type: "string",
                                                  enum: ["u_int"],
                                                },
                                                value: {
                                                  type: "integer",
                                                  format: "uint64",
                                                  minimum: 0.0,
                                                },
                                              },
                                            },
                                            {
                                              type: "object",
                                              required: ["type", "value"],
                                              properties: {
                                                type: {
                                                  type: "string",
                                                  enum: ["double"],
                                                },
                                                value: {
                                                  type: "number",
                                                  format: "double",
                                                },
                                              },
                                            },
                                            {
                                              type: "object",
                                              required: ["type", "value"],
                                              properties: {
                                                type: {
                                                  type: "string",
                                                  enum: ["bool"],
                                                },
                                                value: {
                                                  type: "boolean",
                                                },
                                              },
                                            },
                                            {
                                              type: "object",
                                              required: ["type", "value"],
                                              properties: {
                                                type: {
                                                  type: "string",
                                                  enum: ["ratio"],
                                                },
                                                value: {
                                                  type: "array",
                                                  items: [
                                                    {
                                                      type: "integer",
                                                      format: "int32",
                                                    },
                                                    {
                                                      type: "integer",
                                                      format: "int32",
                                                    },
                                                  ],
                                                  maxItems: 2,
                                                  minItems: 2,
                                                },
                                              },
                                            },
                                            {
                                              type: "object",
                                              required: ["type", "value"],
                                              properties: {
                                                type: {
                                                  type: "string",
                                                  enum: ["pixel"],
                                                },
                                                value: {
                                                  type: "string",
                                                  format: "string",
                                                },
                                              },
                                            },
                                            {
                                              type: "object",
                                              required: ["type", "value"],
                                              properties: {
                                                type: {
                                                  type: "string",
                                                  enum: ["sample"],
                                                },
                                                value: {
                                                  type: "string",
                                                  format: "string",
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                              qmax: {
                                default: null,
                                type: ["integer", "null"],
                                format: "uint32",
                                minimum: 0.0,
                              },
                              qmin: {
                                default: null,
                                type: ["integer", "null"],
                                format: "uint32",
                                minimum: 0.0,
                              },
                              rc_buffer_size: {
                                description: "The rate control buffer size",
                                default: {
                                  unit: "milliseconds",
                                  value: 2000,
                                },
                                oneOf: [
                                  {
                                    type: "object",
                                    required: ["unit", "value"],
                                    properties: {
                                      unit: {
                                        type: "string",
                                        enum: ["milliseconds"],
                                      },
                                      value: {
                                        type: "integer",
                                        format: "uint64",
                                        minimum: 0.0,
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    required: ["unit", "value"],
                                    properties: {
                                      unit: {
                                        type: "string",
                                        enum: ["bits"],
                                      },
                                      value: {
                                        type: "integer",
                                        format: "uint64",
                                        minimum: 0.0,
                                      },
                                    },
                                  },
                                ],
                              },
                              size: {
                                default: {},
                                anyOf: [
                                  {
                                    type: "object",
                                    required: ["height", "width"],
                                    properties: {
                                      height: {
                                        type: "integer",
                                        format: "uint32",
                                        minimum: 0.0,
                                      },
                                      width: {
                                        type: "integer",
                                        format: "uint32",
                                        minimum: 0.0,
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    required: ["width"],
                                    properties: {
                                      width: {
                                        type: "integer",
                                        format: "uint32",
                                        minimum: 0.0,
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    required: ["scale_factor"],
                                    properties: {
                                      scale_factor: {
                                        type: "number",
                                        format: "float",
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                  },
                                ],
                              },
                              thread: {
                                default: null,
                                type: ["object", "null"],
                                properties: {
                                  count: {
                                    default: 0,
                                    type: "integer",
                                    format: "uint32",
                                    minimum: 0.0,
                                  },
                                  kind: {
                                    default: "slice",
                                    type: "string",
                                    enum: ["slice", "frame"],
                                  },
                                },
                              },
                            },
                          },
                        },
                        type: {
                          type: "string",
                          enum: ["video"],
                        },
                      },
                    },
                    {
                      type: "object",
                      required: ["id", "match_source", "type"],
                      properties: {
                        decoder: {
                          description: "Override default decoder config options",
                          default: null,
                          type: ["object", "null"],
                          properties: {
                            hw_frames_enabled: {
                              description: "Controls if the decoder should attempt to output hw frames",
                              default: true,
                              type: "boolean",
                            },
                            name: {
                              description: "Set a specific decoder to be used by name",
                              default: null,
                              type: ["string", "null"],
                            },
                            options: {
                              description: "Additional options to provide to the decoder",
                              default: {},
                              type: "object",
                              additionalProperties: {
                                anyOf: [
                                  {
                                    type: "string",
                                  },
                                  {
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["type", "value"],
                                        properties: {
                                          type: {
                                            type: "string",
                                            enum: ["string"],
                                          },
                                          value: {
                                            type: "string",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["type", "value"],
                                        properties: {
                                          type: {
                                            type: "string",
                                            enum: ["int"],
                                          },
                                          value: {
                                            type: "integer",
                                            format: "int64",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["type", "value"],
                                        properties: {
                                          type: {
                                            type: "string",
                                            enum: ["u_int"],
                                          },
                                          value: {
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["type", "value"],
                                        properties: {
                                          type: {
                                            type: "string",
                                            enum: ["double"],
                                          },
                                          value: {
                                            type: "number",
                                            format: "double",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["type", "value"],
                                        properties: {
                                          type: {
                                            type: "string",
                                            enum: ["bool"],
                                          },
                                          value: {
                                            type: "boolean",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["type", "value"],
                                        properties: {
                                          type: {
                                            type: "string",
                                            enum: ["ratio"],
                                          },
                                          value: {
                                            type: "array",
                                            items: [
                                              {
                                                type: "integer",
                                                format: "int32",
                                              },
                                              {
                                                type: "integer",
                                                format: "int32",
                                              },
                                            ],
                                            maxItems: 2,
                                            minItems: 2,
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["type", "value"],
                                        properties: {
                                          type: {
                                            type: "string",
                                            enum: ["pixel"],
                                          },
                                          value: {
                                            type: "string",
                                            format: "string",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["type", "value"],
                                        properties: {
                                          type: {
                                            type: "string",
                                            enum: ["sample"],
                                          },
                                          value: {
                                            type: "string",
                                            format: "string",
                                          },
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            },
                            thread: {
                              description: "Controls thread configuration",
                              default: null,
                              type: ["object", "null"],
                              properties: {
                                count: {
                                  default: 0,
                                  type: "integer",
                                  format: "uint32",
                                  minimum: 0.0,
                                },
                                kind: {
                                  default: "slice",
                                  type: "string",
                                  enum: ["slice", "frame"],
                                },
                              },
                            },
                          },
                        },
                        id: {
                          type: "string",
                          format: "uuid",
                        },
                        match_source: {
                          description: "Determines which source stream(s) to generate transcoded streams from",
                          anyOf: [
                            {
                              type: "object",
                              required: ["stream_id"],
                              properties: {
                                stream_id: {
                                  type: "integer",
                                  format: "int32",
                                },
                              },
                            },
                            {
                              type: "object",
                              required: ["stream"],
                              properties: {
                                stream: {
                                  type: "string",
                                  enum: ["all", "best"],
                                },
                              },
                            },
                            {
                              type: "object",
                              required: ["language"],
                              properties: {
                                language: {
                                  type: "string",
                                },
                              },
                            },
                          ],
                        },
                        name: {
                          default: null,
                          type: ["string", "null"],
                        },
                        passthrough: {
                          description: "Enables or disables passthrough of the source stream",
                          default: false,
                          type: "boolean",
                        },
                        processing: {
                          description: "Override default processing options",
                          default: null,
                          type: ["object", "null"],
                        },
                        renditions: {
                          description: "The renditions that will be generated based on the source stream(s)",
                          default: [],
                          type: "array",
                          items: {
                            type: "object",
                            required: ["codec", "id"],
                            properties: {
                              bit_rate: {
                                default: null,
                                type: ["integer", "null"],
                                format: "uint64",
                                minimum: 0.0,
                              },
                              channels: {
                                default: null,
                                type: ["integer", "null"],
                                format: "uint32",
                                minimum: 0.0,
                              },
                              codec: {
                                type: "string",
                                format: "string",
                              },
                              encoder_name: {
                                default: null,
                                type: ["string", "null"],
                              },
                              format: {
                                default: null,
                                type: "string",
                              },
                              id: {
                                type: "string",
                                format: "uuid",
                              },
                              name: {
                                default: null,
                                type: ["string", "null"],
                              },
                              options: {
                                description: "Encoder implementation specific options",
                                default: {},
                                anyOf: [
                                  {
                                    type: "object",
                                    additionalProperties: {
                                      type: "object",
                                      additionalProperties: {
                                        anyOf: [
                                          {
                                            type: "string",
                                          },
                                          {
                                            oneOf: [
                                              {
                                                type: "object",
                                                required: ["type", "value"],
                                                properties: {
                                                  type: {
                                                    type: "string",
                                                    enum: ["string"],
                                                  },
                                                  value: {
                                                    type: "string",
                                                  },
                                                },
                                              },
                                              {
                                                type: "object",
                                                required: ["type", "value"],
                                                properties: {
                                                  type: {
                                                    type: "string",
                                                    enum: ["int"],
                                                  },
                                                  value: {
                                                    type: "integer",
                                                    format: "int64",
                                                  },
                                                },
                                              },
                                              {
                                                type: "object",
                                                required: ["type", "value"],
                                                properties: {
                                                  type: {
                                                    type: "string",
                                                    enum: ["u_int"],
                                                  },
                                                  value: {
                                                    type: "integer",
                                                    format: "uint64",
                                                    minimum: 0.0,
                                                  },
                                                },
                                              },
                                              {
                                                type: "object",
                                                required: ["type", "value"],
                                                properties: {
                                                  type: {
                                                    type: "string",
                                                    enum: ["double"],
                                                  },
                                                  value: {
                                                    type: "number",
                                                    format: "double",
                                                  },
                                                },
                                              },
                                              {
                                                type: "object",
                                                required: ["type", "value"],
                                                properties: {
                                                  type: {
                                                    type: "string",
                                                    enum: ["bool"],
                                                  },
                                                  value: {
                                                    type: "boolean",
                                                  },
                                                },
                                              },
                                              {
                                                type: "object",
                                                required: ["type", "value"],
                                                properties: {
                                                  type: {
                                                    type: "string",
                                                    enum: ["ratio"],
                                                  },
                                                  value: {
                                                    type: "array",
                                                    items: [
                                                      {
                                                        type: "integer",
                                                        format: "int32",
                                                      },
                                                      {
                                                        type: "integer",
                                                        format: "int32",
                                                      },
                                                    ],
                                                    maxItems: 2,
                                                    minItems: 2,
                                                  },
                                                },
                                              },
                                              {
                                                type: "object",
                                                required: ["type", "value"],
                                                properties: {
                                                  type: {
                                                    type: "string",
                                                    enum: ["pixel"],
                                                  },
                                                  value: {
                                                    type: "string",
                                                    format: "string",
                                                  },
                                                },
                                              },
                                              {
                                                type: "object",
                                                required: ["type", "value"],
                                                properties: {
                                                  type: {
                                                    type: "string",
                                                    enum: ["sample"],
                                                  },
                                                  value: {
                                                    type: "string",
                                                    format: "string",
                                                  },
                                                },
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    additionalProperties: {
                                      anyOf: [
                                        {
                                          type: "string",
                                        },
                                        {
                                          oneOf: [
                                            {
                                              type: "object",
                                              required: ["type", "value"],
                                              properties: {
                                                type: {
                                                  type: "string",
                                                  enum: ["string"],
                                                },
                                                value: {
                                                  type: "string",
                                                },
                                              },
                                            },
                                            {
                                              type: "object",
                                              required: ["type", "value"],
                                              properties: {
                                                type: {
                                                  type: "string",
                                                  enum: ["int"],
                                                },
                                                value: {
                                                  type: "integer",
                                                  format: "int64",
                                                },
                                              },
                                            },
                                            {
                                              type: "object",
                                              required: ["type", "value"],
                                              properties: {
                                                type: {
                                                  type: "string",
                                                  enum: ["u_int"],
                                                },
                                                value: {
                                                  type: "integer",
                                                  format: "uint64",
                                                  minimum: 0.0,
                                                },
                                              },
                                            },
                                            {
                                              type: "object",
                                              required: ["type", "value"],
                                              properties: {
                                                type: {
                                                  type: "string",
                                                  enum: ["double"],
                                                },
                                                value: {
                                                  type: "number",
                                                  format: "double",
                                                },
                                              },
                                            },
                                            {
                                              type: "object",
                                              required: ["type", "value"],
                                              properties: {
                                                type: {
                                                  type: "string",
                                                  enum: ["bool"],
                                                },
                                                value: {
                                                  type: "boolean",
                                                },
                                              },
                                            },
                                            {
                                              type: "object",
                                              required: ["type", "value"],
                                              properties: {
                                                type: {
                                                  type: "string",
                                                  enum: ["ratio"],
                                                },
                                                value: {
                                                  type: "array",
                                                  items: [
                                                    {
                                                      type: "integer",
                                                      format: "int32",
                                                    },
                                                    {
                                                      type: "integer",
                                                      format: "int32",
                                                    },
                                                  ],
                                                  maxItems: 2,
                                                  minItems: 2,
                                                },
                                              },
                                            },
                                            {
                                              type: "object",
                                              required: ["type", "value"],
                                              properties: {
                                                type: {
                                                  type: "string",
                                                  enum: ["pixel"],
                                                },
                                                value: {
                                                  type: "string",
                                                  format: "string",
                                                },
                                              },
                                            },
                                            {
                                              type: "object",
                                              required: ["type", "value"],
                                              properties: {
                                                type: {
                                                  type: "string",
                                                  enum: ["sample"],
                                                },
                                                value: {
                                                  type: "string",
                                                  format: "string",
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                              sample_rate: {
                                default: null,
                                type: ["integer", "null"],
                                format: "uint32",
                                minimum: 0.0,
                              },
                            },
                          },
                        },
                        type: {
                          type: "string",
                          enum: ["audio"],
                        },
                      },
                    },
                  ],
                },
              },
            },
          },
        },
        version: {
          type: "string",
          enum: ["2"],
        },
      },
    },
    {
      type: "object",
      required: ["version"],
      properties: {
        aliases: {
          default: [],
          type: "array",
          items: {
            type: "object",
            required: ["key", "value"],
            properties: {
              key: {
                oneOf: [
                  {
                    type: "object",
                    required: ["field", "type"],
                    properties: {
                      field: {
                        type: "integer",
                        format: "int64",
                      },
                      type: {
                        type: "string",
                        enum: ["number"],
                      },
                    },
                  },
                  {
                    type: "object",
                    required: ["field", "type"],
                    properties: {
                      field: {
                        type: "string",
                      },
                      type: {
                        type: "string",
                        enum: ["string"],
                      },
                    },
                  },
                  {
                    type: "object",
                    required: ["field", "type"],
                    properties: {
                      field: {
                        type: "string",
                        format: "uuid",
                      },
                      type: {
                        type: "string",
                        enum: ["uuid"],
                      },
                    },
                  },
                ],
              },
              value: {
                oneOf: [
                  {
                    type: "object",
                    required: ["field", "type"],
                    properties: {
                      field: {
                        type: "integer",
                        format: "int64",
                      },
                      type: {
                        type: "string",
                        enum: ["number"],
                      },
                    },
                  },
                  {
                    type: "object",
                    required: ["field", "type"],
                    properties: {
                      field: {
                        type: "string",
                      },
                      type: {
                        type: "string",
                        enum: ["string"],
                      },
                    },
                  },
                  {
                    type: "object",
                    required: ["field", "type"],
                    properties: {
                      field: {
                        type: "string",
                        format: "uuid",
                      },
                      type: {
                        type: "string",
                        enum: ["uuid"],
                      },
                    },
                  },
                ],
              },
            },
          },
        },
        api: {
          default: {
            bind: "0.0.0.0:4040",
          },
          type: "object",
          required: ["bind"],
          properties: {
            bind: {
              type: "string",
            },
          },
        },
        logging: {
          default: {
            ffmpeg_log_level: "quiet",
            log_level: "info",
            log_type: "fancy",
          },
          type: "object",
          properties: {
            ffmpeg_log_level: {
              default: "quiet",
              type: "string",
              enum: ["quiet", "panic", "fatal", "error", "warning", "info", "verbose", "debug", "trace"],
            },
            log_level: {
              default: "info",
              type: "string",
              enum: ["trace", "debug", "info", "warn", "error"],
            },
            log_type: {
              default: "fancy",
              type: "string",
              enum: ["json", "fancy"],
            },
          },
        },
        multiplexers: {
          default: [],
          type: "array",
          items: {
            type: "object",
            required: ["id", "source_ids"],
            properties: {
              id: {
                type: "string",
                format: "uuid",
              },
              name: {
                default: null,
                type: ["string", "null"],
              },
              queue_size: {
                default: 10000,
                type: "integer",
                format: "uint",
                minimum: 0.0,
              },
              source_ids: {
                type: "array",
                items: {
                  type: "string",
                  format: "uuid",
                },
              },
            },
          },
        },
        reporters: {
          default: [],
          type: "array",
          items: {
            type: "object",
            oneOf: [
              {
                type: "object",
                required: ["dals_sink_id", "pop", "source_id", "type", "url"],
                properties: {
                  api_key: {
                    type: ["string", "null"],
                    format: "uuid",
                  },
                  dals_sink_id: {
                    type: "string",
                    format: "uuid",
                  },
                  hostname: {
                    type: ["string", "null"],
                  },
                  pop: {
                    type: "string",
                  },
                  source_id: {
                    type: "string",
                    format: "uuid",
                  },
                  type: {
                    type: "string",
                    enum: ["dals"],
                  },
                  url: {
                    type: "string",
                    format: "uri",
                  },
                },
              },
            ],
            required: ["id"],
            properties: {
              id: {
                type: "string",
                format: "uuid",
              },
              interval_ms: {
                default: 5000,
                type: "integer",
                format: "uint64",
                minimum: 0.0,
              },
              name: {
                default: null,
                type: ["string", "null"],
              },
            },
          },
        },
        sinks: {
          default: [],
          type: "array",
          items: {
            type: "object",
            oneOf: [
              {
                title: "Rtmp sink",
                description: "Expects the url to be using the rtmp/rtmps protocol",
                type: "object",
                required: ["type"],
                properties: {
                  type: {
                    type: "string",
                    enum: ["rtmp"],
                  },
                },
              },
              {
                title: "Mpeg-ts SINK",
                description: "Expects the url to be using the udp or rtp protocol",
                type: "object",
                required: ["output", "type"],
                properties: {
                  output: {
                    type: "object",
                  },
                  type: {
                    type: "string",
                    enum: ["mpegts"],
                  },
                },
              },
              {
                type: "object",
                required: ["ingest_id", "type"],
                properties: {
                  discontinuity_threshold_ms: {
                    type: ["integer", "null"],
                    format: "int64",
                  },
                  fragment_duration: {
                    type: ["integer", "null"],
                    format: "uint",
                    minimum: 0.0,
                  },
                  ingest_id: {
                    type: "string",
                  },
                  max_unordered_dts_count: {
                    default: 50,
                    type: "integer",
                    format: "uint",
                    minimum: 0.0,
                  },
                  type: {
                    type: "string",
                    enum: ["dals"],
                  },
                },
              },
              {
                type: "object",
                oneOf: [
                  {
                    type: "object",
                    required: ["http"],
                    properties: {
                      http: {
                        type: "object",
                        required: ["headers"],
                        properties: {
                          headers: {
                            type: "object",
                            additionalProperties: {
                              type: "string",
                            },
                          },
                          method: {
                            default: "post",
                            type: "string",
                            enum: ["post", "put", "get"],
                          },
                        },
                      },
                    },
                    additionalProperties: false,
                  },
                ],
                required: ["type"],
                properties: {
                  type: {
                    type: "string",
                    enum: ["screenshot"],
                  },
                },
              },
              {
                type: "object",
                required: ["type"],
                properties: {
                  max_send_queue: {
                    default: 1000,
                    type: ["integer", "null"],
                    format: "uint",
                    minimum: 0.0,
                  },
                  type: {
                    type: "string",
                    enum: ["vindral_ws"],
                  },
                },
              },
            ],
            required: ["id", "source_id", "url"],
            properties: {
              id: {
                type: "string",
                format: "uuid",
              },
              name: {
                default: null,
                type: ["string", "null"],
              },
              queue_size: {
                default: 10000,
                type: "integer",
                format: "uint",
                minimum: 0.0,
              },
              reconnect_delay_ms: {
                default: 1000,
                type: "integer",
                format: "uint64",
                minimum: 0.0,
              },
              source_id: {
                type: "string",
                format: "uuid",
              },
              timeout_ms: {
                type: ["integer", "null"],
                format: "uint64",
                minimum: 0.0,
              },
              url: {
                type: "string",
                format: "uri",
              },
            },
          },
        },
        sources: {
          default: [],
          type: "array",
          items: {
            type: "object",
            oneOf: [
              {
                title: "Rtmp source",
                description: "Expects the url to be using the rtmp/rtmps protocol",
                type: "object",
                required: ["type"],
                properties: {
                  buffer: {
                    default: 0,
                    type: "integer",
                    format: "uint",
                    minimum: 0.0,
                  },
                  type: {
                    type: "string",
                    enum: ["rtmp"],
                  },
                },
              },
              {
                title: "Mpeg-ts source",
                description: "Expects the url to be using the udp or rtp protocol",
                type: "object",
                required: ["type"],
                properties: {
                  type: {
                    type: "string",
                    enum: ["mpegts"],
                  },
                },
              },
              {
                title: "Decklink source",
                type: "object",
                required: ["device", "type"],
                properties: {
                  audio_depth: {
                    type: ["integer", "null"],
                    format: "uint32",
                    minimum: 0.0,
                  },
                  audio_timestamp_source: {
                    type: ["string", "null"],
                    enum: ["video", "audio", "reference", "wallclock", "abs_wallclock"],
                  },
                  channels: {
                    type: ["integer", "null"],
                    format: "uint32",
                    minimum: 0.0,
                  },
                  device: {
                    type: "string",
                  },
                  format: {
                    type: ["string", "null"],
                    enum: ["UYVY422", "YUV422P10", "ARGB", "BGRA", "RGB10"],
                  },
                  format_code: {
                    type: ["string", "null"],
                    enum: [
                      "BmdNTSC",
                      "BmdNTSC2398",
                      "BmdPAL",
                      "BmdNTSCp",
                      "BmdHD1080p2398",
                      "BmdHD1080p24",
                      "BmdHD1080p25",
                      "BmdHD1080p2997",
                      "BmdHD1080p30",
                      "BmdHD1080i50",
                      "BmdHD1080i5994",
                      "BmdHD1080i6000",
                      "BmdHD1080p50",
                      "BmdHD1080p5994",
                      "BmdHD1080p6000",
                      "BmdHD720p50",
                      "BmdHD720p5994",
                      "BmdHD720p60",
                      "Bmd2k2398",
                      "Bmd2k24",
                      "Bmd2k25",
                      "Bmd2kDCI2398",
                      "Bmd2kDCI24",
                      "Bmd2kDCI25",
                      "Bmd4K2160p2398",
                      "Bmd4K2160p24",
                      "Bmd4K2160p25",
                      "Bmd4K2160p2997",
                      "Bmd4K2160p30",
                      "Bmd4K2160p50",
                      "Bmd4K2160p5994",
                      "Bmd4K2160p60",
                      "Bmd4kDCI2398",
                      "Bmd4kDCI24",
                      "Bmd4kDCI25",
                      "BmdUnknown",
                    ],
                  },
                  type: {
                    type: "string",
                    enum: ["decklink"],
                  },
                  video_timestamp_source: {
                    type: ["string", "null"],
                    enum: ["video", "audio", "reference", "wallclock", "abs_wallclock"],
                  },
                },
              },
              {
                type: "object",
                required: ["type"],
                properties: {
                  type: {
                    type: "string",
                    enum: ["file"],
                  },
                },
              },
            ],
            required: ["id"],
            properties: {
              connect_timeout_ms: {
                type: ["integer", "null"],
                format: "uint64",
                minimum: 0.0,
              },
              handle_timestamp_rollover: {
                default: true,
                type: "boolean",
              },
              id: {
                type: "string",
                format: "uuid",
              },
              idle_timeout_ms: {
                default: 20000,
                type: "integer",
                format: "uint64",
                minimum: 0.0,
              },
              interleave_buffer: {
                type: ["object", "null"],
                properties: {
                  max_buffer_size_ms: {
                    type: ["integer", "null"],
                    format: "uint64",
                    minimum: 0.0,
                  },
                },
              },
              name: {
                default: null,
                type: ["string", "null"],
              },
              reconnect_delay_ms: {
                default: 1000,
                type: "integer",
                format: "uint64",
                minimum: 0.0,
              },
              stream_metadata: {
                default: [],
                type: "array",
                items: {
                  anyOf: [
                    {
                      type: "object",
                      required: ["decorate", "stream_index"],
                      properties: {
                        decorate: {
                          type: "object",
                          properties: {
                            bit_rate: {
                              type: ["integer", "null"],
                              format: "int64",
                            },
                            id: {
                              type: ["integer", "null"],
                              format: "int32",
                            },
                            language: {
                              type: ["string", "null"],
                            },
                          },
                        },
                        is_required: {
                          default: false,
                          type: "boolean",
                        },
                        stream_index: {
                          type: "integer",
                          format: "uint",
                          minimum: 0.0,
                        },
                      },
                    },
                    {
                      type: "object",
                      required: ["decorate", "stream_id"],
                      properties: {
                        decorate: {
                          type: "object",
                          properties: {
                            bit_rate: {
                              type: ["integer", "null"],
                              format: "int64",
                            },
                            id: {
                              type: ["integer", "null"],
                              format: "int32",
                            },
                            language: {
                              type: ["string", "null"],
                            },
                          },
                        },
                        is_required: {
                          default: false,
                          type: "boolean",
                        },
                        stream_id: {
                          type: "integer",
                          format: "int32",
                        },
                      },
                    },
                    {
                      type: "object",
                      required: ["decorate", "stream_type"],
                      properties: {
                        decorate: {
                          type: "object",
                          properties: {
                            bit_rate: {
                              type: ["integer", "null"],
                              format: "int64",
                            },
                            id: {
                              type: ["integer", "null"],
                              format: "int32",
                            },
                            language: {
                              type: ["string", "null"],
                            },
                          },
                        },
                        is_required: {
                          default: false,
                          type: "boolean",
                        },
                        stream_type: {
                          type: "string",
                          enum: ["video", "audio"],
                        },
                      },
                    },
                  ],
                },
              },
              url: {
                type: ["string", "null"],
                format: "uri",
              },
            },
          },
        },
        transcoders: {
          default: [],
          type: "array",
          items: {
            type: "object",
            required: ["id", "source_id", "streams"],
            properties: {
              device: {
                type: ["string", "null"],
                enum: ["cpu", "nvidia", "netint_quadra", "netint_t4xx", "qsv"],
              },
              gop_alignment: {
                anyOf: [
                  {
                    oneOf: [
                      {
                        type: "string",
                        enum: ["match_incoming"],
                      },
                      {
                        type: "object",
                        required: ["aligned"],
                        properties: {
                          aligned: {
                            type: "object",
                            required: ["gop_size_ms"],
                            properties: {
                              gop_size_ms: {
                                type: "integer",
                                format: "uint",
                                minimum: 0.0,
                              },
                            },
                          },
                        },
                        additionalProperties: false,
                      },
                    ],
                  },
                  {
                    type: "null",
                  },
                ],
              },
              id: {
                type: "string",
                format: "uuid",
              },
              name: {
                default: null,
                type: ["string", "null"],
              },
              queue_size: {
                default: 10000,
                type: "integer",
                format: "uint",
                minimum: 0.0,
              },
              source_id: {
                type: "string",
                format: "uuid",
              },
              streams: {
                type: "array",
                items: {
                  oneOf: [
                    {
                      type: "object",
                      anyOf: [
                        {
                          type: "object",
                          required: ["passthrough", "stream_index"],
                          properties: {
                            auto_deinterlace: {
                              default: "every_field",
                              type: "string",
                              enum: ["disabled", "every_frame", "every_field"],
                            },
                            filters: {
                              default: [],
                              type: "array",
                              items: {
                                type: "string",
                              },
                            },
                            max_frame_rate: {
                              type: ["array", "null"],
                              items: [
                                {
                                  type: "integer",
                                  format: "int32",
                                },
                                {
                                  type: "integer",
                                  format: "int32",
                                },
                              ],
                              maxItems: 2,
                              minItems: 2,
                            },
                            passthrough: {
                              type: "boolean",
                            },
                            renditions: {
                              default: [],
                              type: "array",
                              items: {
                                type: "object",
                                oneOf: [
                                  {
                                    type: "object",
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["encoder_name", "netint", "nvenc", "x264"],
                                        properties: {
                                          encoder_name: {
                                            type: "string",
                                            enum: ["auto"],
                                          },
                                          netint: {
                                            type: "object",
                                            properties: {
                                              cbr: {
                                                default: false,
                                                type: "boolean",
                                              },
                                              enable_hvs_qp: {
                                                default: false,
                                                type: "boolean",
                                              },
                                              enable_region_of_interest: {
                                                default: false,
                                                type: "boolean",
                                              },
                                              force_8bit: {
                                                default: false,
                                                type: "boolean",
                                              },
                                              level: {
                                                default: null,
                                                type: ["string", "null"],
                                              },
                                              low_delay: {
                                                default: true,
                                                type: "boolean",
                                              },
                                              profile: {
                                                default: "baseline",
                                                type: "string",
                                              },
                                              rdo_level: {
                                                default: null,
                                                type: ["integer", "null"],
                                                format: "uint8",
                                                minimum: 0.0,
                                              },
                                            },
                                          },
                                          nvenc: {
                                            type: "object",
                                            properties: {
                                              gpu_selection: {
                                                default: "any",
                                                type: "string",
                                              },
                                              preset: {
                                                default: "llhp",
                                                type: "string",
                                              },
                                              profile: {
                                                default: "baseline",
                                                type: "string",
                                              },
                                              rate_control: {
                                                default: "cbr",
                                                type: "string",
                                              },
                                              rate_control_lookahead: {
                                                default: 0,
                                                type: "integer",
                                                format: "uint64",
                                                minimum: 0.0,
                                              },
                                              surfaces_count: {
                                                default: 0,
                                                type: "integer",
                                                format: "uint64",
                                                minimum: 0.0,
                                              },
                                            },
                                          },
                                          x264: {
                                            type: "object",
                                            properties: {
                                              profile: {
                                                default: "baseline",
                                                type: "string",
                                              },
                                              sliced_threads: {
                                                default: true,
                                                type: "boolean",
                                              },
                                              speed_preset: {
                                                default: "veryfast",
                                                type: "string",
                                              },
                                              tune: {
                                                default: "zerolatency",
                                                type: "string",
                                              },
                                            },
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          encoder_name: {
                                            type: "string",
                                            enum: ["h264_nvenc"],
                                          },
                                          gpu_selection: {
                                            default: "any",
                                            type: "string",
                                          },
                                          preset: {
                                            default: "llhp",
                                            type: "string",
                                          },
                                          profile: {
                                            default: "baseline",
                                            type: "string",
                                          },
                                          rate_control: {
                                            default: "cbr",
                                            type: "string",
                                          },
                                          rate_control_lookahead: {
                                            default: 0,
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                          surfaces_count: {
                                            default: 0,
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          encoder_name: {
                                            type: "string",
                                            enum: ["libx264"],
                                          },
                                          profile: {
                                            default: "baseline",
                                            type: "string",
                                          },
                                          sliced_threads: {
                                            default: true,
                                            type: "boolean",
                                          },
                                          speed_preset: {
                                            default: "veryfast",
                                            type: "string",
                                          },
                                          tune: {
                                            default: "zerolatency",
                                            type: "string",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          cbr: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          enable_hvs_qp: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          enable_region_of_interest: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          encoder_name: {
                                            type: "string",
                                            enum: ["netint"],
                                          },
                                          force_8bit: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          level: {
                                            default: null,
                                            type: ["string", "null"],
                                          },
                                          low_delay: {
                                            default: true,
                                            type: "boolean",
                                          },
                                          profile: {
                                            default: "baseline",
                                            type: "string",
                                          },
                                          rdo_level: {
                                            default: null,
                                            type: ["integer", "null"],
                                            format: "uint8",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                    ],
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["h264"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          encoder_name: {
                                            type: "string",
                                            enum: ["hevc_nvenc"],
                                          },
                                          gpu_selection: {
                                            default: "any",
                                            type: "string",
                                          },
                                          preset: {
                                            default: "llhp",
                                            type: "string",
                                          },
                                          profile: {
                                            default: "baseline",
                                            type: "string",
                                          },
                                          rate_control: {
                                            default: "cbr",
                                            type: "string",
                                          },
                                          rate_control_lookahead: {
                                            default: 0,
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                          surfaces_count: {
                                            default: 0,
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                    ],
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["hevc"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["mpeg1"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["jpeg"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          encoder_name: {
                                            type: "string",
                                            enum: ["aom"],
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          encoder_name: {
                                            type: "string",
                                            enum: ["svt"],
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          cbr: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          enable_hvs_qp: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          enable_region_of_interest: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          encoder_name: {
                                            type: "string",
                                            enum: ["netint"],
                                          },
                                          force_8bit: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          level: {
                                            default: null,
                                            type: ["string", "null"],
                                          },
                                          low_delay: {
                                            default: true,
                                            type: "boolean",
                                          },
                                          profile: {
                                            default: "baseline",
                                            type: "string",
                                          },
                                          rdo_level: {
                                            default: null,
                                            type: ["integer", "null"],
                                            format: "uint8",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                    ],
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["av1"],
                                      },
                                    },
                                  },
                                ],
                                required: ["bit_rate", "id"],
                                properties: {
                                  bit_rate: {
                                    type: "integer",
                                    format: "uint64",
                                    minimum: 0.0,
                                  },
                                  frame_skipping: {
                                    default: "none",
                                    type: "string",
                                    enum: ["none", "half", "quarter", "frame_every_1_seconds", "frame_every_5_seconds"],
                                  },
                                  gop_size_ms: {
                                    default: 2000,
                                    type: "integer",
                                    format: "uint",
                                    minimum: 0.0,
                                  },
                                  id: {
                                    type: "string",
                                    format: "uuid",
                                  },
                                  max_bit_rate: {
                                    default: {
                                      unit: "fraction",
                                      value: 0.10000000149011612,
                                    },
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["unit", "value"],
                                        properties: {
                                          unit: {
                                            type: "string",
                                            enum: ["fraction"],
                                          },
                                          value: {
                                            type: "number",
                                            format: "float",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["unit", "value"],
                                        properties: {
                                          unit: {
                                            type: "string",
                                            enum: ["absolute"],
                                          },
                                          value: {
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                    ],
                                  },
                                  min_bit_rate: {
                                    default: {
                                      unit: "fraction",
                                      value: 0.10000000149011612,
                                    },
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["unit", "value"],
                                        properties: {
                                          unit: {
                                            type: "string",
                                            enum: ["fraction"],
                                          },
                                          value: {
                                            type: "number",
                                            format: "float",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["unit", "value"],
                                        properties: {
                                          unit: {
                                            type: "string",
                                            enum: ["absolute"],
                                          },
                                          value: {
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                    ],
                                  },
                                  name: {
                                    default: null,
                                    type: ["string", "null"],
                                  },
                                  qmax: {
                                    type: ["integer", "null"],
                                    format: "uint32",
                                    minimum: 0.0,
                                  },
                                  qmin: {
                                    type: ["integer", "null"],
                                    format: "uint32",
                                    minimum: 0.0,
                                  },
                                  rc_buffer_size: {
                                    default: {
                                      unit: "milliseconds",
                                      value: 2000,
                                    },
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["unit", "value"],
                                        properties: {
                                          unit: {
                                            type: "string",
                                            enum: ["milliseconds"],
                                          },
                                          value: {
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["unit", "value"],
                                        properties: {
                                          unit: {
                                            type: "string",
                                            enum: ["bits"],
                                          },
                                          value: {
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                    ],
                                  },
                                  size: {
                                    default: {},
                                    anyOf: [
                                      {
                                        type: "object",
                                        required: ["height", "width"],
                                        properties: {
                                          height: {
                                            type: "integer",
                                            format: "uint32",
                                            minimum: 0.0,
                                          },
                                          width: {
                                            type: "integer",
                                            format: "uint32",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["width"],
                                        properties: {
                                          width: {
                                            type: "integer",
                                            format: "uint32",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["scale_factor"],
                                        properties: {
                                          scale_factor: {
                                            type: "number",
                                            format: "float",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                      },
                                    ],
                                  },
                                  thread_count: {
                                    type: ["integer", "null"],
                                    format: "uint",
                                    minimum: 0.0,
                                  },
                                },
                              },
                            },
                            stream_index: {
                              type: "integer",
                              format: "uint",
                              minimum: 0.0,
                            },
                            video_decoder: {
                              type: ["object", "null"],
                              properties: {
                                decoder_name: {
                                  type: ["string", "null"],
                                },
                                thread_count: {
                                  type: ["integer", "null"],
                                  format: "uint",
                                  minimum: 0.0,
                                },
                                thread_type: {
                                  type: ["string", "null"],
                                  enum: ["slice", "frame"],
                                },
                              },
                            },
                          },
                        },
                        {
                          type: "object",
                          required: ["passthrough", "stream_id"],
                          properties: {
                            auto_deinterlace: {
                              default: "every_field",
                              type: "string",
                              enum: ["disabled", "every_frame", "every_field"],
                            },
                            filters: {
                              default: [],
                              type: "array",
                              items: {
                                type: "string",
                              },
                            },
                            max_frame_rate: {
                              type: ["array", "null"],
                              items: [
                                {
                                  type: "integer",
                                  format: "int32",
                                },
                                {
                                  type: "integer",
                                  format: "int32",
                                },
                              ],
                              maxItems: 2,
                              minItems: 2,
                            },
                            passthrough: {
                              type: "boolean",
                            },
                            renditions: {
                              default: [],
                              type: "array",
                              items: {
                                type: "object",
                                oneOf: [
                                  {
                                    type: "object",
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["encoder_name", "netint", "nvenc", "x264"],
                                        properties: {
                                          encoder_name: {
                                            type: "string",
                                            enum: ["auto"],
                                          },
                                          netint: {
                                            type: "object",
                                            properties: {
                                              cbr: {
                                                default: false,
                                                type: "boolean",
                                              },
                                              enable_hvs_qp: {
                                                default: false,
                                                type: "boolean",
                                              },
                                              enable_region_of_interest: {
                                                default: false,
                                                type: "boolean",
                                              },
                                              force_8bit: {
                                                default: false,
                                                type: "boolean",
                                              },
                                              level: {
                                                default: null,
                                                type: ["string", "null"],
                                              },
                                              low_delay: {
                                                default: true,
                                                type: "boolean",
                                              },
                                              profile: {
                                                default: "baseline",
                                                type: "string",
                                              },
                                              rdo_level: {
                                                default: null,
                                                type: ["integer", "null"],
                                                format: "uint8",
                                                minimum: 0.0,
                                              },
                                            },
                                          },
                                          nvenc: {
                                            type: "object",
                                            properties: {
                                              gpu_selection: {
                                                default: "any",
                                                type: "string",
                                              },
                                              preset: {
                                                default: "llhp",
                                                type: "string",
                                              },
                                              profile: {
                                                default: "baseline",
                                                type: "string",
                                              },
                                              rate_control: {
                                                default: "cbr",
                                                type: "string",
                                              },
                                              rate_control_lookahead: {
                                                default: 0,
                                                type: "integer",
                                                format: "uint64",
                                                minimum: 0.0,
                                              },
                                              surfaces_count: {
                                                default: 0,
                                                type: "integer",
                                                format: "uint64",
                                                minimum: 0.0,
                                              },
                                            },
                                          },
                                          x264: {
                                            type: "object",
                                            properties: {
                                              profile: {
                                                default: "baseline",
                                                type: "string",
                                              },
                                              sliced_threads: {
                                                default: true,
                                                type: "boolean",
                                              },
                                              speed_preset: {
                                                default: "veryfast",
                                                type: "string",
                                              },
                                              tune: {
                                                default: "zerolatency",
                                                type: "string",
                                              },
                                            },
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          encoder_name: {
                                            type: "string",
                                            enum: ["h264_nvenc"],
                                          },
                                          gpu_selection: {
                                            default: "any",
                                            type: "string",
                                          },
                                          preset: {
                                            default: "llhp",
                                            type: "string",
                                          },
                                          profile: {
                                            default: "baseline",
                                            type: "string",
                                          },
                                          rate_control: {
                                            default: "cbr",
                                            type: "string",
                                          },
                                          rate_control_lookahead: {
                                            default: 0,
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                          surfaces_count: {
                                            default: 0,
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          encoder_name: {
                                            type: "string",
                                            enum: ["libx264"],
                                          },
                                          profile: {
                                            default: "baseline",
                                            type: "string",
                                          },
                                          sliced_threads: {
                                            default: true,
                                            type: "boolean",
                                          },
                                          speed_preset: {
                                            default: "veryfast",
                                            type: "string",
                                          },
                                          tune: {
                                            default: "zerolatency",
                                            type: "string",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          cbr: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          enable_hvs_qp: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          enable_region_of_interest: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          encoder_name: {
                                            type: "string",
                                            enum: ["netint"],
                                          },
                                          force_8bit: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          level: {
                                            default: null,
                                            type: ["string", "null"],
                                          },
                                          low_delay: {
                                            default: true,
                                            type: "boolean",
                                          },
                                          profile: {
                                            default: "baseline",
                                            type: "string",
                                          },
                                          rdo_level: {
                                            default: null,
                                            type: ["integer", "null"],
                                            format: "uint8",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                    ],
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["h264"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          encoder_name: {
                                            type: "string",
                                            enum: ["hevc_nvenc"],
                                          },
                                          gpu_selection: {
                                            default: "any",
                                            type: "string",
                                          },
                                          preset: {
                                            default: "llhp",
                                            type: "string",
                                          },
                                          profile: {
                                            default: "baseline",
                                            type: "string",
                                          },
                                          rate_control: {
                                            default: "cbr",
                                            type: "string",
                                          },
                                          rate_control_lookahead: {
                                            default: 0,
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                          surfaces_count: {
                                            default: 0,
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                    ],
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["hevc"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["mpeg1"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["jpeg"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          encoder_name: {
                                            type: "string",
                                            enum: ["aom"],
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          encoder_name: {
                                            type: "string",
                                            enum: ["svt"],
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          cbr: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          enable_hvs_qp: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          enable_region_of_interest: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          encoder_name: {
                                            type: "string",
                                            enum: ["netint"],
                                          },
                                          force_8bit: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          level: {
                                            default: null,
                                            type: ["string", "null"],
                                          },
                                          low_delay: {
                                            default: true,
                                            type: "boolean",
                                          },
                                          profile: {
                                            default: "baseline",
                                            type: "string",
                                          },
                                          rdo_level: {
                                            default: null,
                                            type: ["integer", "null"],
                                            format: "uint8",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                    ],
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["av1"],
                                      },
                                    },
                                  },
                                ],
                                required: ["bit_rate", "id"],
                                properties: {
                                  bit_rate: {
                                    type: "integer",
                                    format: "uint64",
                                    minimum: 0.0,
                                  },
                                  frame_skipping: {
                                    default: "none",
                                    type: "string",
                                    enum: ["none", "half", "quarter", "frame_every_1_seconds", "frame_every_5_seconds"],
                                  },
                                  gop_size_ms: {
                                    default: 2000,
                                    type: "integer",
                                    format: "uint",
                                    minimum: 0.0,
                                  },
                                  id: {
                                    type: "string",
                                    format: "uuid",
                                  },
                                  max_bit_rate: {
                                    default: {
                                      unit: "fraction",
                                      value: 0.10000000149011612,
                                    },
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["unit", "value"],
                                        properties: {
                                          unit: {
                                            type: "string",
                                            enum: ["fraction"],
                                          },
                                          value: {
                                            type: "number",
                                            format: "float",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["unit", "value"],
                                        properties: {
                                          unit: {
                                            type: "string",
                                            enum: ["absolute"],
                                          },
                                          value: {
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                    ],
                                  },
                                  min_bit_rate: {
                                    default: {
                                      unit: "fraction",
                                      value: 0.10000000149011612,
                                    },
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["unit", "value"],
                                        properties: {
                                          unit: {
                                            type: "string",
                                            enum: ["fraction"],
                                          },
                                          value: {
                                            type: "number",
                                            format: "float",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["unit", "value"],
                                        properties: {
                                          unit: {
                                            type: "string",
                                            enum: ["absolute"],
                                          },
                                          value: {
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                    ],
                                  },
                                  name: {
                                    default: null,
                                    type: ["string", "null"],
                                  },
                                  qmax: {
                                    type: ["integer", "null"],
                                    format: "uint32",
                                    minimum: 0.0,
                                  },
                                  qmin: {
                                    type: ["integer", "null"],
                                    format: "uint32",
                                    minimum: 0.0,
                                  },
                                  rc_buffer_size: {
                                    default: {
                                      unit: "milliseconds",
                                      value: 2000,
                                    },
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["unit", "value"],
                                        properties: {
                                          unit: {
                                            type: "string",
                                            enum: ["milliseconds"],
                                          },
                                          value: {
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["unit", "value"],
                                        properties: {
                                          unit: {
                                            type: "string",
                                            enum: ["bits"],
                                          },
                                          value: {
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                    ],
                                  },
                                  size: {
                                    default: {},
                                    anyOf: [
                                      {
                                        type: "object",
                                        required: ["height", "width"],
                                        properties: {
                                          height: {
                                            type: "integer",
                                            format: "uint32",
                                            minimum: 0.0,
                                          },
                                          width: {
                                            type: "integer",
                                            format: "uint32",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["width"],
                                        properties: {
                                          width: {
                                            type: "integer",
                                            format: "uint32",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["scale_factor"],
                                        properties: {
                                          scale_factor: {
                                            type: "number",
                                            format: "float",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                      },
                                    ],
                                  },
                                  thread_count: {
                                    type: ["integer", "null"],
                                    format: "uint",
                                    minimum: 0.0,
                                  },
                                },
                              },
                            },
                            stream_id: {
                              type: "integer",
                              format: "int32",
                            },
                            video_decoder: {
                              type: ["object", "null"],
                              properties: {
                                decoder_name: {
                                  type: ["string", "null"],
                                },
                                thread_count: {
                                  type: ["integer", "null"],
                                  format: "uint",
                                  minimum: 0.0,
                                },
                                thread_type: {
                                  type: ["string", "null"],
                                  enum: ["slice", "frame"],
                                },
                              },
                            },
                          },
                        },
                        {
                          type: "object",
                          required: ["passthrough", "stream"],
                          properties: {
                            auto_deinterlace: {
                              default: "every_field",
                              type: "string",
                              enum: ["disabled", "every_frame", "every_field"],
                            },
                            filters: {
                              default: [],
                              type: "array",
                              items: {
                                type: "string",
                              },
                            },
                            max_frame_rate: {
                              type: ["array", "null"],
                              items: [
                                {
                                  type: "integer",
                                  format: "int32",
                                },
                                {
                                  type: "integer",
                                  format: "int32",
                                },
                              ],
                              maxItems: 2,
                              minItems: 2,
                            },
                            passthrough: {
                              type: "boolean",
                            },
                            renditions: {
                              default: [],
                              type: "array",
                              items: {
                                type: "object",
                                oneOf: [
                                  {
                                    type: "object",
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["encoder_name", "netint", "nvenc", "x264"],
                                        properties: {
                                          encoder_name: {
                                            type: "string",
                                            enum: ["auto"],
                                          },
                                          netint: {
                                            type: "object",
                                            properties: {
                                              cbr: {
                                                default: false,
                                                type: "boolean",
                                              },
                                              enable_hvs_qp: {
                                                default: false,
                                                type: "boolean",
                                              },
                                              enable_region_of_interest: {
                                                default: false,
                                                type: "boolean",
                                              },
                                              force_8bit: {
                                                default: false,
                                                type: "boolean",
                                              },
                                              level: {
                                                default: null,
                                                type: ["string", "null"],
                                              },
                                              low_delay: {
                                                default: true,
                                                type: "boolean",
                                              },
                                              profile: {
                                                default: "baseline",
                                                type: "string",
                                              },
                                              rdo_level: {
                                                default: null,
                                                type: ["integer", "null"],
                                                format: "uint8",
                                                minimum: 0.0,
                                              },
                                            },
                                          },
                                          nvenc: {
                                            type: "object",
                                            properties: {
                                              gpu_selection: {
                                                default: "any",
                                                type: "string",
                                              },
                                              preset: {
                                                default: "llhp",
                                                type: "string",
                                              },
                                              profile: {
                                                default: "baseline",
                                                type: "string",
                                              },
                                              rate_control: {
                                                default: "cbr",
                                                type: "string",
                                              },
                                              rate_control_lookahead: {
                                                default: 0,
                                                type: "integer",
                                                format: "uint64",
                                                minimum: 0.0,
                                              },
                                              surfaces_count: {
                                                default: 0,
                                                type: "integer",
                                                format: "uint64",
                                                minimum: 0.0,
                                              },
                                            },
                                          },
                                          x264: {
                                            type: "object",
                                            properties: {
                                              profile: {
                                                default: "baseline",
                                                type: "string",
                                              },
                                              sliced_threads: {
                                                default: true,
                                                type: "boolean",
                                              },
                                              speed_preset: {
                                                default: "veryfast",
                                                type: "string",
                                              },
                                              tune: {
                                                default: "zerolatency",
                                                type: "string",
                                              },
                                            },
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          encoder_name: {
                                            type: "string",
                                            enum: ["h264_nvenc"],
                                          },
                                          gpu_selection: {
                                            default: "any",
                                            type: "string",
                                          },
                                          preset: {
                                            default: "llhp",
                                            type: "string",
                                          },
                                          profile: {
                                            default: "baseline",
                                            type: "string",
                                          },
                                          rate_control: {
                                            default: "cbr",
                                            type: "string",
                                          },
                                          rate_control_lookahead: {
                                            default: 0,
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                          surfaces_count: {
                                            default: 0,
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          encoder_name: {
                                            type: "string",
                                            enum: ["libx264"],
                                          },
                                          profile: {
                                            default: "baseline",
                                            type: "string",
                                          },
                                          sliced_threads: {
                                            default: true,
                                            type: "boolean",
                                          },
                                          speed_preset: {
                                            default: "veryfast",
                                            type: "string",
                                          },
                                          tune: {
                                            default: "zerolatency",
                                            type: "string",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          cbr: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          enable_hvs_qp: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          enable_region_of_interest: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          encoder_name: {
                                            type: "string",
                                            enum: ["netint"],
                                          },
                                          force_8bit: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          level: {
                                            default: null,
                                            type: ["string", "null"],
                                          },
                                          low_delay: {
                                            default: true,
                                            type: "boolean",
                                          },
                                          profile: {
                                            default: "baseline",
                                            type: "string",
                                          },
                                          rdo_level: {
                                            default: null,
                                            type: ["integer", "null"],
                                            format: "uint8",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                    ],
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["h264"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          encoder_name: {
                                            type: "string",
                                            enum: ["hevc_nvenc"],
                                          },
                                          gpu_selection: {
                                            default: "any",
                                            type: "string",
                                          },
                                          preset: {
                                            default: "llhp",
                                            type: "string",
                                          },
                                          profile: {
                                            default: "baseline",
                                            type: "string",
                                          },
                                          rate_control: {
                                            default: "cbr",
                                            type: "string",
                                          },
                                          rate_control_lookahead: {
                                            default: 0,
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                          surfaces_count: {
                                            default: 0,
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                    ],
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["hevc"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["mpeg1"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["jpeg"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          encoder_name: {
                                            type: "string",
                                            enum: ["aom"],
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          encoder_name: {
                                            type: "string",
                                            enum: ["svt"],
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          cbr: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          enable_hvs_qp: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          enable_region_of_interest: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          encoder_name: {
                                            type: "string",
                                            enum: ["netint"],
                                          },
                                          force_8bit: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          level: {
                                            default: null,
                                            type: ["string", "null"],
                                          },
                                          low_delay: {
                                            default: true,
                                            type: "boolean",
                                          },
                                          profile: {
                                            default: "baseline",
                                            type: "string",
                                          },
                                          rdo_level: {
                                            default: null,
                                            type: ["integer", "null"],
                                            format: "uint8",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                    ],
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["av1"],
                                      },
                                    },
                                  },
                                ],
                                required: ["bit_rate", "id"],
                                properties: {
                                  bit_rate: {
                                    type: "integer",
                                    format: "uint64",
                                    minimum: 0.0,
                                  },
                                  frame_skipping: {
                                    default: "none",
                                    type: "string",
                                    enum: ["none", "half", "quarter", "frame_every_1_seconds", "frame_every_5_seconds"],
                                  },
                                  gop_size_ms: {
                                    default: 2000,
                                    type: "integer",
                                    format: "uint",
                                    minimum: 0.0,
                                  },
                                  id: {
                                    type: "string",
                                    format: "uuid",
                                  },
                                  max_bit_rate: {
                                    default: {
                                      unit: "fraction",
                                      value: 0.10000000149011612,
                                    },
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["unit", "value"],
                                        properties: {
                                          unit: {
                                            type: "string",
                                            enum: ["fraction"],
                                          },
                                          value: {
                                            type: "number",
                                            format: "float",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["unit", "value"],
                                        properties: {
                                          unit: {
                                            type: "string",
                                            enum: ["absolute"],
                                          },
                                          value: {
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                    ],
                                  },
                                  min_bit_rate: {
                                    default: {
                                      unit: "fraction",
                                      value: 0.10000000149011612,
                                    },
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["unit", "value"],
                                        properties: {
                                          unit: {
                                            type: "string",
                                            enum: ["fraction"],
                                          },
                                          value: {
                                            type: "number",
                                            format: "float",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["unit", "value"],
                                        properties: {
                                          unit: {
                                            type: "string",
                                            enum: ["absolute"],
                                          },
                                          value: {
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                    ],
                                  },
                                  name: {
                                    default: null,
                                    type: ["string", "null"],
                                  },
                                  qmax: {
                                    type: ["integer", "null"],
                                    format: "uint32",
                                    minimum: 0.0,
                                  },
                                  qmin: {
                                    type: ["integer", "null"],
                                    format: "uint32",
                                    minimum: 0.0,
                                  },
                                  rc_buffer_size: {
                                    default: {
                                      unit: "milliseconds",
                                      value: 2000,
                                    },
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["unit", "value"],
                                        properties: {
                                          unit: {
                                            type: "string",
                                            enum: ["milliseconds"],
                                          },
                                          value: {
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["unit", "value"],
                                        properties: {
                                          unit: {
                                            type: "string",
                                            enum: ["bits"],
                                          },
                                          value: {
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                    ],
                                  },
                                  size: {
                                    default: {},
                                    anyOf: [
                                      {
                                        type: "object",
                                        required: ["height", "width"],
                                        properties: {
                                          height: {
                                            type: "integer",
                                            format: "uint32",
                                            minimum: 0.0,
                                          },
                                          width: {
                                            type: "integer",
                                            format: "uint32",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["width"],
                                        properties: {
                                          width: {
                                            type: "integer",
                                            format: "uint32",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["scale_factor"],
                                        properties: {
                                          scale_factor: {
                                            type: "number",
                                            format: "float",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                      },
                                    ],
                                  },
                                  thread_count: {
                                    type: ["integer", "null"],
                                    format: "uint",
                                    minimum: 0.0,
                                  },
                                },
                              },
                            },
                            stream: {
                              type: "string",
                              enum: ["all", "best"],
                            },
                            video_decoder: {
                              type: ["object", "null"],
                              properties: {
                                decoder_name: {
                                  type: ["string", "null"],
                                },
                                thread_count: {
                                  type: ["integer", "null"],
                                  format: "uint",
                                  minimum: 0.0,
                                },
                                thread_type: {
                                  type: ["string", "null"],
                                  enum: ["slice", "frame"],
                                },
                              },
                            },
                          },
                        },
                        {
                          type: "object",
                          required: ["passthrough", "stream_language"],
                          properties: {
                            auto_deinterlace: {
                              default: "every_field",
                              type: "string",
                              enum: ["disabled", "every_frame", "every_field"],
                            },
                            filters: {
                              default: [],
                              type: "array",
                              items: {
                                type: "string",
                              },
                            },
                            max_frame_rate: {
                              type: ["array", "null"],
                              items: [
                                {
                                  type: "integer",
                                  format: "int32",
                                },
                                {
                                  type: "integer",
                                  format: "int32",
                                },
                              ],
                              maxItems: 2,
                              minItems: 2,
                            },
                            passthrough: {
                              type: "boolean",
                            },
                            renditions: {
                              default: [],
                              type: "array",
                              items: {
                                type: "object",
                                oneOf: [
                                  {
                                    type: "object",
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["encoder_name", "netint", "nvenc", "x264"],
                                        properties: {
                                          encoder_name: {
                                            type: "string",
                                            enum: ["auto"],
                                          },
                                          netint: {
                                            type: "object",
                                            properties: {
                                              cbr: {
                                                default: false,
                                                type: "boolean",
                                              },
                                              enable_hvs_qp: {
                                                default: false,
                                                type: "boolean",
                                              },
                                              enable_region_of_interest: {
                                                default: false,
                                                type: "boolean",
                                              },
                                              force_8bit: {
                                                default: false,
                                                type: "boolean",
                                              },
                                              level: {
                                                default: null,
                                                type: ["string", "null"],
                                              },
                                              low_delay: {
                                                default: true,
                                                type: "boolean",
                                              },
                                              profile: {
                                                default: "baseline",
                                                type: "string",
                                              },
                                              rdo_level: {
                                                default: null,
                                                type: ["integer", "null"],
                                                format: "uint8",
                                                minimum: 0.0,
                                              },
                                            },
                                          },
                                          nvenc: {
                                            type: "object",
                                            properties: {
                                              gpu_selection: {
                                                default: "any",
                                                type: "string",
                                              },
                                              preset: {
                                                default: "llhp",
                                                type: "string",
                                              },
                                              profile: {
                                                default: "baseline",
                                                type: "string",
                                              },
                                              rate_control: {
                                                default: "cbr",
                                                type: "string",
                                              },
                                              rate_control_lookahead: {
                                                default: 0,
                                                type: "integer",
                                                format: "uint64",
                                                minimum: 0.0,
                                              },
                                              surfaces_count: {
                                                default: 0,
                                                type: "integer",
                                                format: "uint64",
                                                minimum: 0.0,
                                              },
                                            },
                                          },
                                          x264: {
                                            type: "object",
                                            properties: {
                                              profile: {
                                                default: "baseline",
                                                type: "string",
                                              },
                                              sliced_threads: {
                                                default: true,
                                                type: "boolean",
                                              },
                                              speed_preset: {
                                                default: "veryfast",
                                                type: "string",
                                              },
                                              tune: {
                                                default: "zerolatency",
                                                type: "string",
                                              },
                                            },
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          encoder_name: {
                                            type: "string",
                                            enum: ["h264_nvenc"],
                                          },
                                          gpu_selection: {
                                            default: "any",
                                            type: "string",
                                          },
                                          preset: {
                                            default: "llhp",
                                            type: "string",
                                          },
                                          profile: {
                                            default: "baseline",
                                            type: "string",
                                          },
                                          rate_control: {
                                            default: "cbr",
                                            type: "string",
                                          },
                                          rate_control_lookahead: {
                                            default: 0,
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                          surfaces_count: {
                                            default: 0,
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          encoder_name: {
                                            type: "string",
                                            enum: ["libx264"],
                                          },
                                          profile: {
                                            default: "baseline",
                                            type: "string",
                                          },
                                          sliced_threads: {
                                            default: true,
                                            type: "boolean",
                                          },
                                          speed_preset: {
                                            default: "veryfast",
                                            type: "string",
                                          },
                                          tune: {
                                            default: "zerolatency",
                                            type: "string",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          cbr: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          enable_hvs_qp: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          enable_region_of_interest: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          encoder_name: {
                                            type: "string",
                                            enum: ["netint"],
                                          },
                                          force_8bit: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          level: {
                                            default: null,
                                            type: ["string", "null"],
                                          },
                                          low_delay: {
                                            default: true,
                                            type: "boolean",
                                          },
                                          profile: {
                                            default: "baseline",
                                            type: "string",
                                          },
                                          rdo_level: {
                                            default: null,
                                            type: ["integer", "null"],
                                            format: "uint8",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                    ],
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["h264"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          encoder_name: {
                                            type: "string",
                                            enum: ["hevc_nvenc"],
                                          },
                                          gpu_selection: {
                                            default: "any",
                                            type: "string",
                                          },
                                          preset: {
                                            default: "llhp",
                                            type: "string",
                                          },
                                          profile: {
                                            default: "baseline",
                                            type: "string",
                                          },
                                          rate_control: {
                                            default: "cbr",
                                            type: "string",
                                          },
                                          rate_control_lookahead: {
                                            default: 0,
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                          surfaces_count: {
                                            default: 0,
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                    ],
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["hevc"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["mpeg1"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["jpeg"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          encoder_name: {
                                            type: "string",
                                            enum: ["aom"],
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          encoder_name: {
                                            type: "string",
                                            enum: ["svt"],
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["encoder_name"],
                                        properties: {
                                          cbr: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          enable_hvs_qp: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          enable_region_of_interest: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          encoder_name: {
                                            type: "string",
                                            enum: ["netint"],
                                          },
                                          force_8bit: {
                                            default: false,
                                            type: "boolean",
                                          },
                                          level: {
                                            default: null,
                                            type: ["string", "null"],
                                          },
                                          low_delay: {
                                            default: true,
                                            type: "boolean",
                                          },
                                          profile: {
                                            default: "baseline",
                                            type: "string",
                                          },
                                          rdo_level: {
                                            default: null,
                                            type: ["integer", "null"],
                                            format: "uint8",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                    ],
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["av1"],
                                      },
                                    },
                                  },
                                ],
                                required: ["bit_rate", "id"],
                                properties: {
                                  bit_rate: {
                                    type: "integer",
                                    format: "uint64",
                                    minimum: 0.0,
                                  },
                                  frame_skipping: {
                                    default: "none",
                                    type: "string",
                                    enum: ["none", "half", "quarter", "frame_every_1_seconds", "frame_every_5_seconds"],
                                  },
                                  gop_size_ms: {
                                    default: 2000,
                                    type: "integer",
                                    format: "uint",
                                    minimum: 0.0,
                                  },
                                  id: {
                                    type: "string",
                                    format: "uuid",
                                  },
                                  max_bit_rate: {
                                    default: {
                                      unit: "fraction",
                                      value: 0.10000000149011612,
                                    },
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["unit", "value"],
                                        properties: {
                                          unit: {
                                            type: "string",
                                            enum: ["fraction"],
                                          },
                                          value: {
                                            type: "number",
                                            format: "float",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["unit", "value"],
                                        properties: {
                                          unit: {
                                            type: "string",
                                            enum: ["absolute"],
                                          },
                                          value: {
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                    ],
                                  },
                                  min_bit_rate: {
                                    default: {
                                      unit: "fraction",
                                      value: 0.10000000149011612,
                                    },
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["unit", "value"],
                                        properties: {
                                          unit: {
                                            type: "string",
                                            enum: ["fraction"],
                                          },
                                          value: {
                                            type: "number",
                                            format: "float",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["unit", "value"],
                                        properties: {
                                          unit: {
                                            type: "string",
                                            enum: ["absolute"],
                                          },
                                          value: {
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                    ],
                                  },
                                  name: {
                                    default: null,
                                    type: ["string", "null"],
                                  },
                                  qmax: {
                                    type: ["integer", "null"],
                                    format: "uint32",
                                    minimum: 0.0,
                                  },
                                  qmin: {
                                    type: ["integer", "null"],
                                    format: "uint32",
                                    minimum: 0.0,
                                  },
                                  rc_buffer_size: {
                                    default: {
                                      unit: "milliseconds",
                                      value: 2000,
                                    },
                                    oneOf: [
                                      {
                                        type: "object",
                                        required: ["unit", "value"],
                                        properties: {
                                          unit: {
                                            type: "string",
                                            enum: ["milliseconds"],
                                          },
                                          value: {
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["unit", "value"],
                                        properties: {
                                          unit: {
                                            type: "string",
                                            enum: ["bits"],
                                          },
                                          value: {
                                            type: "integer",
                                            format: "uint64",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                    ],
                                  },
                                  size: {
                                    default: {},
                                    anyOf: [
                                      {
                                        type: "object",
                                        required: ["height", "width"],
                                        properties: {
                                          height: {
                                            type: "integer",
                                            format: "uint32",
                                            minimum: 0.0,
                                          },
                                          width: {
                                            type: "integer",
                                            format: "uint32",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["width"],
                                        properties: {
                                          width: {
                                            type: "integer",
                                            format: "uint32",
                                            minimum: 0.0,
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                        required: ["scale_factor"],
                                        properties: {
                                          scale_factor: {
                                            type: "number",
                                            format: "float",
                                          },
                                        },
                                      },
                                      {
                                        type: "object",
                                      },
                                    ],
                                  },
                                  thread_count: {
                                    type: ["integer", "null"],
                                    format: "uint",
                                    minimum: 0.0,
                                  },
                                },
                              },
                            },
                            stream_language: {
                              type: "string",
                            },
                            video_decoder: {
                              type: ["object", "null"],
                              properties: {
                                decoder_name: {
                                  type: ["string", "null"],
                                },
                                thread_count: {
                                  type: ["integer", "null"],
                                  format: "uint",
                                  minimum: 0.0,
                                },
                                thread_type: {
                                  type: ["string", "null"],
                                  enum: ["slice", "frame"],
                                },
                              },
                            },
                          },
                        },
                      ],
                      required: ["type"],
                      properties: {
                        type: {
                          type: "string",
                          enum: ["video"],
                        },
                      },
                    },
                    {
                      type: "object",
                      anyOf: [
                        {
                          type: "object",
                          required: ["passthrough", "stream_index"],
                          properties: {
                            passthrough: {
                              type: "boolean",
                            },
                            renditions: {
                              default: [],
                              type: "array",
                              items: {
                                type: "object",
                                oneOf: [
                                  {
                                    type: "object",
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["aac"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["vorbis"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["mp2"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["opus"],
                                      },
                                    },
                                  },
                                ],
                                required: ["bit_rate", "id"],
                                properties: {
                                  bit_rate: {
                                    type: "integer",
                                    format: "uint",
                                    minimum: 0.0,
                                  },
                                  channel_layout: {
                                    type: ["string", "null"],
                                    enum: ["mono", "stereo"],
                                  },
                                  id: {
                                    type: "string",
                                    format: "uuid",
                                  },
                                  name: {
                                    default: null,
                                    type: ["string", "null"],
                                  },
                                  sample_rate: {
                                    type: ["integer", "null"],
                                    format: "int32",
                                  },
                                  thread_count: {
                                    type: ["integer", "null"],
                                    format: "uint",
                                    minimum: 0.0,
                                  },
                                },
                              },
                            },
                            stream_index: {
                              type: "integer",
                              format: "uint",
                              minimum: 0.0,
                            },
                          },
                        },
                        {
                          type: "object",
                          required: ["passthrough", "stream_id"],
                          properties: {
                            passthrough: {
                              type: "boolean",
                            },
                            renditions: {
                              default: [],
                              type: "array",
                              items: {
                                type: "object",
                                oneOf: [
                                  {
                                    type: "object",
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["aac"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["vorbis"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["mp2"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["opus"],
                                      },
                                    },
                                  },
                                ],
                                required: ["bit_rate", "id"],
                                properties: {
                                  bit_rate: {
                                    type: "integer",
                                    format: "uint",
                                    minimum: 0.0,
                                  },
                                  channel_layout: {
                                    type: ["string", "null"],
                                    enum: ["mono", "stereo"],
                                  },
                                  id: {
                                    type: "string",
                                    format: "uuid",
                                  },
                                  name: {
                                    default: null,
                                    type: ["string", "null"],
                                  },
                                  sample_rate: {
                                    type: ["integer", "null"],
                                    format: "int32",
                                  },
                                  thread_count: {
                                    type: ["integer", "null"],
                                    format: "uint",
                                    minimum: 0.0,
                                  },
                                },
                              },
                            },
                            stream_id: {
                              type: "integer",
                              format: "int32",
                            },
                          },
                        },
                        {
                          type: "object",
                          required: ["passthrough", "stream"],
                          properties: {
                            passthrough: {
                              type: "boolean",
                            },
                            renditions: {
                              default: [],
                              type: "array",
                              items: {
                                type: "object",
                                oneOf: [
                                  {
                                    type: "object",
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["aac"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["vorbis"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["mp2"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["opus"],
                                      },
                                    },
                                  },
                                ],
                                required: ["bit_rate", "id"],
                                properties: {
                                  bit_rate: {
                                    type: "integer",
                                    format: "uint",
                                    minimum: 0.0,
                                  },
                                  channel_layout: {
                                    type: ["string", "null"],
                                    enum: ["mono", "stereo"],
                                  },
                                  id: {
                                    type: "string",
                                    format: "uuid",
                                  },
                                  name: {
                                    default: null,
                                    type: ["string", "null"],
                                  },
                                  sample_rate: {
                                    type: ["integer", "null"],
                                    format: "int32",
                                  },
                                  thread_count: {
                                    type: ["integer", "null"],
                                    format: "uint",
                                    minimum: 0.0,
                                  },
                                },
                              },
                            },
                            stream: {
                              type: "string",
                              enum: ["all", "best"],
                            },
                          },
                        },
                        {
                          type: "object",
                          required: ["passthrough", "stream_language"],
                          properties: {
                            passthrough: {
                              type: "boolean",
                            },
                            renditions: {
                              default: [],
                              type: "array",
                              items: {
                                type: "object",
                                oneOf: [
                                  {
                                    type: "object",
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["aac"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["vorbis"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["mp2"],
                                      },
                                    },
                                  },
                                  {
                                    type: "object",
                                    required: ["codec"],
                                    properties: {
                                      codec: {
                                        type: "string",
                                        enum: ["opus"],
                                      },
                                    },
                                  },
                                ],
                                required: ["bit_rate", "id"],
                                properties: {
                                  bit_rate: {
                                    type: "integer",
                                    format: "uint",
                                    minimum: 0.0,
                                  },
                                  channel_layout: {
                                    type: ["string", "null"],
                                    enum: ["mono", "stereo"],
                                  },
                                  id: {
                                    type: "string",
                                    format: "uuid",
                                  },
                                  name: {
                                    default: null,
                                    type: ["string", "null"],
                                  },
                                  sample_rate: {
                                    type: ["integer", "null"],
                                    format: "int32",
                                  },
                                  thread_count: {
                                    type: ["integer", "null"],
                                    format: "uint",
                                    minimum: 0.0,
                                  },
                                },
                              },
                            },
                            stream_language: {
                              type: "string",
                            },
                          },
                        },
                      ],
                      required: ["type"],
                      properties: {
                        type: {
                          type: "string",
                          enum: ["audio"],
                        },
                      },
                    },
                  ],
                },
              },
            },
          },
        },
        version: {
          type: "string",
          enum: ["1"],
        },
      },
    },
  ],
}
