import { useAbility } from "@casl/react"
import { Action } from "@core-services/data-types"
import { IconPlus } from "@tabler/icons-react"
import { Link } from "@tanstack/react-router"
import { Button, Pagination } from "@vindral/components"
import { AbilityContext } from "../../acl"
import { Page, PageContainer, PageContent, PageFooter, PageHeader } from "../../atoms/Page"
import { Breadcrumbs } from "../../molecules/Breadcrumbs"
import {
  TranscodingProfileTable,
  useTranscodingProfileReactTable,
  type UseTranscodingProfileTableProps,
} from "../../organisms/TranscodingProfileTable"

function CreateTranscodingProfileButton() {
  const ability = useAbility(AbilityContext)

  if (!ability.can(Action.Create, "TranscodingProfile")) {
    return null
  }

  return (
    <Link to="/transcoding-profiles/create">
      <Button variant="primary">
        <IconPlus size={16} />
        Create transcoding profile
      </Button>
    </Link>
  )
}

export type TranscodingProfileListProps = UseTranscodingProfileTableProps & {
  onClickCreate: () => void
  saveColumnVisibility: () => void
  resetColumnVisibilityToDefault: () => void
  isStaleData: boolean
  totalCount: number
}

export function TranscodingProfileList({
  onClickCreate,
  isStaleData,
  totalCount,
  ...props
}: TranscodingProfileListProps) {
  const transcodingProfileTable = useTranscodingProfileReactTable(props)

  if (props.data.length === 0 && !props.state?.globalFilter && !isStaleData) {
    return (
      <div className="relative flex justify-center pt-40">
        <div>
          <h1 className="font-medium">There is nothing here right now</h1>
          <p className="py-1 text-fg-subtle last-of-type:pb-3">
            You will be able to see your transcoding profiles here very soon.
          </p>
        </div>
      </div>
    )
  }

  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
            <CreateTranscodingProfileButton />
          </div>
        </PageHeader>
        <PageContainer>
          <div className="py-4">
            <TranscodingProfileTable {...props} table={transcodingProfileTable} />
          </div>
        </PageContainer>
      </PageContent>
      <PageFooter>
        <div className="flex h-16 items-center justify-between">
          <div>
            {totalCount} Transcoding profile{totalCount > 1 ? "s" : ""}
          </div>
          <Pagination
            pageIndex={transcodingProfileTable.getState().pagination.pageIndex}
            pageCount={transcodingProfileTable.getPageCount()}
            nextPage={() => transcodingProfileTable.nextPage()}
            previousPage={() => transcodingProfileTable.previousPage()}
          />
        </div>
      </PageFooter>
    </Page>
  )
}
