import { ClientTelemetry } from "@core-services/portal"
import { Divider, FormControlLabel, Slider, Switch, TextField, ToggleGroup, ToggleGroupItem } from "@vindral/components"
import { useState } from "react"
import { Controller, useFormContext } from "react-hook-form"

export const formatTime = (time?: number) => {
  if (!time) {
    return ""
  }
  const date = new Date(time)
  const minutes = date.getUTCMinutes()
  const seconds = date.getUTCSeconds()
  const segments = []

  if (minutes > 0) segments.push(`${minutes} minute` + (minutes == 1 ? "" : "s"))
  if (segments.length === 0 || seconds > 0) segments.push(`${seconds} second` + (seconds == 1 ? "" : "s"))
  const dateString = segments.join(", ")
  return dateString
}

export function TelemetryOverridesForm() {
  const {
    register,
    control,
    getValues,
    formState: { errors },
  } = useFormContext<{
    clientTelemetry: ClientTelemetry
  }>()

  const probabilityValue = getValues("clientTelemetry.probability")
  const intervalValue = getValues("clientTelemetry.interval")
  const minIntervalValue = 5000

  const [disabledProbabilityValue, setDisabledProbabilityValue] = useState<number>(probabilityValue || 0)
  const [disabledIntervalValue, setDisabledIntervalValue] = useState<number>(intervalValue || minIntervalValue)
  const [probabilityEnabled, setProbabilityEnabled] = useState(typeof probabilityValue === "number")
  const [intervalEnabled, setIntervalEnabled] = useState(typeof getValues("clientTelemetry.interval") === "number")

  return (
    <div className="flex flex-col gap-4">
      <Controller
        control={control}
        name="clientTelemetry.probability"
        render={({ field }) => (
          <div className="flex flex-col gap-2">
            <FormControlLabel>Probability to enable telemetry </FormControlLabel>
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <Switch
                  checked={probabilityEnabled}
                  onCheckedChange={(checked) => {
                    if (!checked) {
                      setDisabledProbabilityValue(field.value || 0)
                      field.onChange(null)
                    } else {
                      field.onChange(disabledProbabilityValue)
                    }
                    setProbabilityEnabled(checked)
                  }}
                />
                <span className="text-fg-subtle">Override</span>
                {field.value != undefined && (
                  <>
                    <div className="flex self-stretch">
                      <Divider variant={"vertical"}></Divider>
                    </div>
                    <span className="text-fg-subtle">{`${(field.value * 100).toFixed()}%`}</span>
                  </>
                )}
              </div>
              <div className="w-40">
                <Slider
                  defaultValue={[1]}
                  disabled={!probabilityEnabled}
                  max={1}
                  min={0}
                  step={0.01}
                  value={field.value != null ? [field.value] : [disabledProbabilityValue]}
                  onValueChange={(value) => field.onChange(value[0])}
                />
              </div>
            </div>
          </div>
        )}
      />

      <Controller
        control={control}
        name="clientTelemetry.interval"
        render={({ field }) => (
          <div className="flex flex-col gap-2">
            <FormControlLabel>Reporting interval</FormControlLabel>
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <Switch
                  checked={intervalEnabled}
                  onCheckedChange={(checked) => {
                    if (!checked) {
                      setDisabledIntervalValue(field.value || minIntervalValue)
                      field.onChange(null)
                    } else {
                      field.onChange(disabledIntervalValue)
                    }
                    setIntervalEnabled(checked)
                  }}
                />
                <span className="text-fg-subtle">Override</span>
                {field.value != undefined && (
                  <>
                    <div className="flex self-stretch">
                      <Divider variant={"vertical"}></Divider>
                    </div>
                    <span className="text-fg-subtle">{`${formatTime(field.value)}`}</span>
                  </>
                )}
              </div>
              <div className="w-40">
                <Slider
                  disabled={!intervalEnabled}
                  defaultValue={[intervalValue || minIntervalValue]}
                  max={60 * 10 * 1000}
                  min={minIntervalValue}
                  step={5000}
                  value={field.value != null ? [field.value] : [disabledIntervalValue]}
                  onValueChange={(value) => field.onChange(value[0])}
                />
              </div>
            </div>
          </div>
        )}
      />

      <Controller
        control={control}
        name="clientTelemetry.includeErrors"
        render={({ field }) => (
          <div className="flex flex-col items-start gap-1">
            <FormControlLabel>Errors</FormControlLabel>
            <ToggleGroup
              value={field.value == null ? "" : field.value ? "true" : "false"}
              onValueChange={(value) => field.onChange(value === "" ? null : value === "true")}
              type="single"
            >
              <ToggleGroupItem value={"false"}>Never send errors</ToggleGroupItem>
              <ToggleGroupItem value={"true"}>Include errors</ToggleGroupItem>
            </ToggleGroup>
          </div>
        )}
      />

      <Controller
        control={control}
        name="clientTelemetry.includeStats"
        render={({ field }) => (
          <div className="flex flex-col items-start gap-1">
            <FormControlLabel>Stats</FormControlLabel>
            <ToggleGroup
              value={field.value == null ? "" : field.value ? "true" : "false"}
              onValueChange={(value) => field.onChange(value === "" ? null : value === "true")}
              type="single"
            >
              <ToggleGroupItem value={"false"}>Never send stats</ToggleGroupItem>
              <ToggleGroupItem value={"true"}>Include stats</ToggleGroupItem>
            </ToggleGroup>
          </div>
        )}
      />

      <div className="flex flex-col gap-1">
        <FormControlLabel error={errors.clientTelemetry?.url?.message}>Telemetry URL</FormControlLabel>
        <TextField
          {...register("clientTelemetry.url", { setValueAs: (value: string) => (value !== "" ? value : null) })}
          placeholder="Use default"
        />
      </div>
    </div>
  )
}
