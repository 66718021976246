import { LimitationMode } from "@core-services/data-types"
import { Expose } from "class-transformer"

export class EdgeInfoSourceDTO {
  @Expose()
  isOrigin!: boolean

  @Expose()
  type!: string

  @Expose()
  url?: string
}

export class EdgeInfoChannelDTO {
  @Expose()
  authSecret?: string

  @Expose()
  maxViewers?: number

  @Expose()
  streamKey!: string

  @Expose()
  channelId!: string

  @Expose()
  channelGroupIds!: string[]

  @Expose()
  sources!: EdgeInfoSourceDTO[]

  @Expose()
  overrides!: Record<string, unknown>

  @Expose()
  // viewer country limitation mode
  viewerCountryLimitationMode?: LimitationMode

  @Expose()
  // viewer country limitation countries
  viewerCountryLimitationCountries?: string[] | number[]
}

export class EdgeInfoChannelGroupDTO {
  @Expose()
  authSecret?: string

  @Expose()
  maxViewers?: number

  @Expose()
  channelGroupId!: string

  @Expose()
  channelStreamKeys!: string[]
}

export class EdgeInfoDTO {
  @Expose()
  channels!: EdgeInfoChannelDTO[]

  @Expose()
  channelGroups!: EdgeInfoChannelGroupDTO[]

  @Expose()
  edgeOverrides!: Record<string, unknown>
}
