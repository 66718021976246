import { Route } from "@tanstack/react-router"
import { onboardedRoute } from "../onboarded"

export const usageIndexRoute = new Route({
  getParentRoute: () => onboardedRoute,
  preSearchFilters: [(search) => search],
  path: "usage",
  getContext: () => {
    return {
      pageTitle: "Usage",
    }
  },
})
