import { Button, Dialog, DialogTrigger } from "@vindral/components"
import { Message, MessageSection } from "../../molecules/Message"
import { UpgradeAccountDialog, UpgradeAccountDialogProps } from "./UpgradeAccountDialog"

export function UpgradeAccountMessage(props: UpgradeAccountDialogProps) {
  return (
    <Message severity="appeal">
      <MessageSection>
        <div className="flex flex-col gap-2">
          <span className="font-medium">Evaluation account</span>
          <span className="text-fg-subtle">Unlock all features and get a price quote</span>
          <Dialog>
            <DialogTrigger asChild>
              <Button className="hidden" variant="appeal">
                Request Full Access
              </Button>
            </DialogTrigger>
            <UpgradeAccountDialog {...props} />
          </Dialog>
        </div>
      </MessageSection>
    </Message>
  )
}
