import { Button } from "@vindral/components"
import { UserInviteItem, UserInvites } from "../../interfaces/UserInvite"
import { PageLayout } from "../../organisms/PageLayout"
import { accessPermissionRoleMapping } from "../../utils/accessPermissionRole"

interface ManageInvitationsProps {
  invites: UserInvites
  onAccept: (invitePublicId: string) => Promise<void>
  onDecline: (invitePublicId: string) => Promise<void>
}

interface InvitationProps {
  invite: UserInviteItem
  onAccept: (invitePublicId: string) => Promise<void>
  onDecline: (invitePublicId: string) => Promise<void>
}

const Invitation = (props: InvitationProps) => {
  const { invite, onAccept, onDecline } = props

  const handleAcceptInvite = () => {
    void onAccept(invite.publicId)
  }

  const handleDeclineInvite = () => {
    void onDecline(invite.publicId)
  }

  return (
    <div className="flex gap-8">
      <div className="flex-1 self-center whitespace-nowrap text-lg">
        Join <span className="font-medium">{invite.organization?.name}</span> as{" "}
        <span className="font-medium">{accessPermissionRoleMapping[invite.role]}</span>
      </div>
      <div className="flex flex-1 justify-end gap-2">
        <Button variant="primary" onClick={handleAcceptInvite}>
          Accept
        </Button>
        <button
          className="h-7 whitespace-nowrap px-2 tracking-wide transition-all active:scale-95"
          onClick={handleDeclineInvite}
        >
          Decline invitation
        </button>
      </div>
    </div>
  )
}

export const ManageInvitations = (props: ManageInvitationsProps) => {
  const { invites, onAccept, onDecline } = props

  return (
    <PageLayout
      description={
        <>
          <h1 className="text-2xl">Accept invitation</h1>
          <p className="text-lg">Do you want to join this Organization?</p>
        </>
      }
    >
      <div className="flex flex-col gap-4">
        {invites.map((invite) => (
          <Invitation key={invite.publicId} invite={invite} onAccept={onAccept} onDecline={onDecline} />
        ))}
      </div>
    </PageLayout>
  )
}
