import type { PackagerConfig } from "@core-services/data-types"
import { ChannelMode } from "@core-services/data-types"
import { Type } from "class-transformer"
import {
  IsBoolean,
  IsEnum,
  IsNotEmptyObject,
  IsNumber,
  IsOptional,
  IsString,
  MaxLength,
  ValidateNested,
} from "class-validator"
import { ClientOverridesDTO } from "../client-overrides.dto"
import { ClientTelemetryDTO } from "../client-telemetry.dto"
import { IsEntityName, IsNullable, IsPositiveFloat } from "../common"
import { LimitableDTO } from "../limitable.dto"

export class UpdateChannelDTO {
  static readonly modelName = "Channel"

  @IsEntityName()
  name!: string

  @IsString()
  @MaxLength(200)
  notes!: string

  @IsBoolean()
  suspended!: boolean

  @IsOptional()
  groups?: number[]

  @IsOptional()
  packager_configuration?: PackagerConfig

  @IsBoolean()
  authRequired!: boolean

  @IsEnum(ChannelMode)
  mode!: ChannelMode

  @IsNumber()
  @IsNullable()
  @IsOptional()
  site?: number | null

  @IsString()
  @IsNullable()
  @IsOptional()
  hostName?: string | null

  @IsOptional()
  @IsNotEmptyObject()
  siteLimitation: LimitableDTO = {
    mode: "deny",
    limited: [],
  }

  @IsOptional()
  @IsNullable()
  @IsPositiveFloat()
  transcodingMultiplier?: number | null

  @IsOptional()
  @IsNullable()
  @ValidateNested()
  @Type(() => ClientOverridesDTO)
  clientOverrides?: ClientOverridesDTO

  @IsOptional()
  @IsNullable()
  @ValidateNested()
  @Type(() => ClientTelemetryDTO)
  clientTelemetry?: ClientTelemetryDTO
}
