import { useAbility } from "@casl/react"
import { Action } from "@core-services/data-types"
import { SearchResultDTO } from "@core-services/dtos"
import { ReactNode, createContext, useCallback, useMemo, useState } from "react"
import { AbilityContext, checkIfSuperUser } from "../../acl"
import { trpc } from "../../trpc"

interface SearchContextActions {
  setText(value: string): void
  setFocusedRowId(id: string): void
  focusNextRow(): void
  focusPreviousRow(): void
}

interface SearchContextState {
  // result: PageDTO<SearchResultDTO>
  text: string
  loading: boolean
  durationMs?: number
  focusedRowId: string | null
  actions: SearchContextActions
  hasResults: boolean
  result: SearchResult
  groups: Groups
}

interface SearchContextProviderProps {
  children?: ReactNode
  enabled: boolean
  organizationId?: string
}

type Groups = { type: string; items: SearchResultItem[] }[]
export type SearchResult = { items: SearchResultItem[]; total: number }
export type SearchResultItem = { id: string; name: string; type: string }

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-argument
export const SearchContext = createContext<SearchContextState>(null as any)

export const SearchContextProvider = (props: SearchContextProviderProps) => {
  const [text, setText] = useState("")
  const ability = useAbility(AbilityContext)
  const isSuperUser = checkIfSuperUser({ ability })
  const { enabled, organizationId } = props

  const filter = organizationId ? { filter: { organization: { publicId: organizationId } } } : {}
  const queryInput = {
    orderBy: "name" as const,
    search: text,
    ...filter,
  }
  const enableQuery = enabled && text.length > 0
  const queryOpts = { keepPreviousData: true, enabled: enableQuery }

  const { data: channels, isFetching: channelsLoading } = trpc.channel.findMany.useQuery(queryInput, queryOpts)
  const { data: organizations, isFetching: organizationsLoading } = trpc.organization.findMany.useQuery(queryInput, {
    ...queryOpts,
    enabled: queryOpts.enabled && isSuperUser,
  })
  const { data: users, isFetching: usersLoading } = trpc.user.findMany.useQuery(queryInput, {
    ...queryOpts,
    enabled: queryOpts.enabled && isSuperUser,
  })
  const { data: regions, isFetching: regionsLoading } = trpc.region.findMany.useQuery(queryInput, {
    ...queryOpts,
    enabled: queryOpts.enabled && ability.can(Action.Read, "Region"),
  })
  const { data: sites, isFetching: sitesLoading } = trpc.site.findMany.useQuery(queryInput, {
    ...queryOpts,
    enabled: queryOpts.enabled && ability.can(Action.Read, "Site"),
  })

  const { result, groups }: { result: SearchResult; groups: Groups } = useMemo(() => {
    const channelItems = channels?.items.map((item) => ({ id: item.channelId, type: "channel", name: item.name })) || []
    const organizationItems =
      organizations?.items.map((item) => ({
        id: item.publicId,
        type: "organization",
        name: item.name,
      })) || []
    const userItems = users?.items.map((item) => ({ id: item.publicId, type: "user", name: item.name })) || []
    const regionItems = regions?.items.map((item) => ({ id: item.publicId, type: "region", name: item.name })) || []
    const siteItems = sites?.items.map((item) => ({ id: item.code, type: "site", name: item.name })) || []

    const items = [...channelItems, ...organizationItems, ...userItems, ...regionItems, ...siteItems]
    const groups = [
      { type: "channel", items: channelItems },
      { type: "organization", items: organizationItems },
      { type: "user", items: userItems },
      { type: "region", items: regionItems },
      { type: "site", items: siteItems },
    ].filter((group) => group.items.length > 0)

    return { result: { items, total: items.length }, groups }
  }, [channels, organizations, users, regions, sites])

  const [focusedRowId, setFocusedRowId] = useState<string | null>(null)
  const firstRow = useMemo(() => result.items[0] || undefined, [result])
  const lastRow = useMemo(() => result.items[result.items.length - 1] || undefined, [result])

  const previousRow = useMemo(() => {
    if (focusedRowId === null || getSearchResultId(firstRow) === focusedRowId) {
      return lastRow
    }

    const currentRowIndex = result.items.findIndex((row) => getSearchResultId(row) === focusedRowId)
    const row = result.items[currentRowIndex - 1]

    return row || null
  }, [firstRow, focusedRowId, lastRow, result.items])

  const nextRow = useMemo(() => {
    if (focusedRowId === null || getSearchResultId(lastRow) === focusedRowId) {
      return firstRow
    }

    const currentRowIndex = result.items.findIndex((row) => getSearchResultId(row) === focusedRowId)
    const row = result.items[currentRowIndex + 1]

    return row || null
  }, [focusedRowId, lastRow, result.items, firstRow])

  const focusNextRow = useCallback(() => {
    if (nextRow) {
      setFocusedRowId(getSearchResultId(nextRow))
    }
  }, [nextRow])

  const focusPreviousRow = useCallback(() => {
    if (previousRow) {
      setFocusedRowId(getSearchResultId(previousRow))
    }
  }, [previousRow])

  const loading = channelsLoading || organizationsLoading || usersLoading || regionsLoading || sitesLoading
  const state = {
    text,
    result,
    loading,
    focusedRowId,
    groups,
    hasResults: result.total > 0,
    actions: {
      setText,
      setFocusedRowId,
      focusNextRow,
      focusPreviousRow,
    },
  }

  return <SearchContext.Provider value={state}>{props.children}</SearchContext.Provider>
}

export const getSearchResultId = (result?: SearchResultItem): string | null => {
  if (result) {
    return `${result.type}-${result.id}`
  }

  return null
}

export const getSearchResultIdOld = (result?: SearchResultDTO): string | null => {
  if (result) {
    return `${result.type}-${result.entityId}`
  }

  return null
}
