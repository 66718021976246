import { trpc } from "../../../trpc"
import { AnalyticsQueryArgs } from "../Analytics"
import { BaseGraph } from "./Base"
import { getSubTime } from "./getSubTime"

const formatNumber = (viewers: number | undefined) => {
  if (typeof viewers === "undefined") {
    return 0
  }

  if (viewers > 9999) {
    return `${new Intl.NumberFormat("en", { notation: "compact", maximumFractionDigits: 1 }).format(viewers / 1000)}K`
  }

  return viewers
}

const format = (viewers: number | undefined) => {
  return `${formatNumber(viewers)} Viewers`
}

export const ViewersOverTimeGraph = (props: AnalyticsQueryArgs) => {
  const { from, to } = props.time
  const subTime = getSubTime(props.time)

  const { data: viewers, isFetching } = trpc.analytics.viewersRange.useQuery(props, {
    keepPreviousData: true,
  })
  const { data: prevViewers, isFetching: subIsFetching } = trpc.analytics.viewersRange.useQuery(
    { ...props, time: subTime },
    { keepPreviousData: true }
  )

  const mainData = viewers?.[0]?.points || []
  const subData = prevViewers?.[0]?.points || []

  return (
    <BaseGraph
      mainData={mainData}
      subData={subData}
      title="Viewers Over Time"
      mainDescription="Viewers this period"
      subDescription="Viewers previous period"
      time={{ from, to, subFrom: subTime.from, subTo: subTime.to }}
      formatValue={format}
      isLoading={isFetching || subIsFetching}
    />
  )
}
