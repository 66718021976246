import { useAbility } from "@casl/react"
import { Action } from "@core-services/data-types"
import { UpdateChannelGroup } from "@core-services/portal"
import { Route, useNavigate, useParams } from "@tanstack/react-router"
import { toast } from "@vindral/components"
import { z } from "zod"
import { channelGroupIndexRoute } from "."
import { AbilityContext, channelGroupSubjectInOrganization, pickPermittedFields } from "../../acl"
import { EditChannelGroup } from "../../templates/channel-group/ChannelGroupEdit"
import { trpc } from "../../trpc"

function Page() {
  const { id } = useParams({ from: channelGroupEditRoute.id })
  const context = trpc.useContext()
  const navigate = useNavigate()
  const ability = useAbility(AbilityContext)

  const { data: channelGroup } = trpc.channelGroup.findByChannelGroupId.useQuery(
    { channelGroupId: id },
    { suspense: true }
  )

  if (!channelGroup) {
    return null
  }

  const subject = channelGroupSubjectInOrganization(channelGroup.organizationId)
  const deleteMutation = trpc.channelGroup.delete.useMutation({
    onSuccess() {
      void context.channelGroup.findMany.invalidate()
      void navigate({ to: "/channel-groups", replace: true })
    },
    onError: (error) => {
      toast({ title: "Failed to delete!", description: error.message })
    },
  })
  const onDelete = async () => {
    deleteMutation.reset()
    await deleteMutation.mutateAsync({ channelGroupId: channelGroup.channelGroupId })
  }

  const updateMutation = trpc.channelGroup.update.useMutation({
    onSuccess: (channelGroup) => {
      void context.channelGroup.findMany.invalidate()
      void context.channelGroup.findManyByOrganizationId.invalidate()
      context.channelGroup.findByChannelGroupId.setData({ channelGroupId: channelGroup.channelGroupId }, channelGroup)
      toast({ title: "Saved successfully!" })
      void navigate({ to: ".", replace: true })
    },
    onError: (error) => {
      toast({ title: "Failed to save!", description: error.message })
    },
  })
  const onSave = async (update: UpdateChannelGroup) => {
    const permitted = pickPermittedFields(ability, Action.Update, subject, update)
    await updateMutation.mutateAsync({ ...permitted, channelGroupId: channelGroup.channelGroupId })
  }

  return <EditChannelGroup channelGroup={channelGroup} onDelete={onDelete} onSave={onSave} />
}

const Params = z.object({ id: z.string() })

export const channelGroupEditRoute = new Route({
  getParentRoute: () => channelGroupIndexRoute,
  preSearchFilters: [(search) => search],
  path: "edit/$id",
  parseParams: (params) => Params.parse(params),
  component: Page,
  getContext: ({ context, params }) => {
    const channelGroup = context.trpcContext.channelGroup.findByChannelGroupId.getData({ channelGroupId: params.id })

    return {
      pageTitle: channelGroup?.name ?? "Channel group",
    }
  },
})
