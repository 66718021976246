import { useAbility } from "@casl/react"
import { Action, OrganizationBillingStatus } from "@core-services/data-types"
import { AppAbility, IngestSessionFindManyParameters, UpdateChannel, UpdateRecordingSink } from "@core-services/portal"
import { Navigate, Route, useNavigate, useParams, useSearch } from "@tanstack/react-router"
import { PaginationState, SortingState, VisibilityState } from "@tanstack/react-table"
import { toast, useToast } from "@vindral/components"
import { useEffect, useState } from "react"
import { useAsyncFn, useLocalStorage } from "react-use"
import { z } from "zod"
import { channelIndexRoute } from "."
import {
  AbilityContext,
  channelSubjectInOrganization,
  checkIfSuperUser,
  pickPermittedFields,
  sinkSubjectInOrganization,
} from "../../acl"
import { ChannelEdit, ChannelFindById } from "../../templates/channel/ChannelEdit"
import { ChannelEditBroadcast } from "../../templates/channel/edit/ChannelEditBroadcast"
import { ChannelEditDebug } from "../../templates/channel/edit/ChannelEditDebug"
import { ChannelEditDetails } from "../../templates/channel/edit/ChannelEditDetails"
import { ChannelEditDetailsChannelAccess } from "../../templates/channel/edit/ChannelEditDetailsChannelAccess"
import { ChannelEditDetailsGeneral } from "../../templates/channel/edit/ChannelEditDetailsGeneral"
import { ChannelEditDetailsOverrides } from "../../templates/channel/edit/ChannelEditDetailsOverrides"
import { ChannelEditDetailsRecording } from "../../templates/channel/edit/ChannelEditDetailsRecording"
import { ChannelEditDetailsRestreaming, SinkSchema } from "../../templates/channel/edit/ChannelEditDetailsRestreaming"
import { ChannelEditDetailsSourceFeed } from "../../templates/channel/edit/ChannelEditDetailsSourceFeed"
import { ChannelEditDetailsTranscoding } from "../../templates/channel/edit/ChannelEditDetailsTranscoding"
import { ChannelEditDetailsViewerAccess } from "../../templates/channel/edit/ChannelEditDetailsViewerAccess"
import { ChannelEditIngestSessions } from "../../templates/channel/edit/ChannelEditIngestSessions"
import { ChannelEditPlayout } from "../../templates/channel/edit/ChannelEditPlayout"
import { trpc } from "../../trpc"

const trpcOptions = { staleTime: 5000 }

const defaultColumns = {
  hostname: true,
  sourceIp: true,
  protocol: true,
  video: true,
  audio: true,
  duration: true,
  createdAt: true,
  stoppedAt: true,
}

type SearchParams = ReturnType<typeof useSearch<typeof channelEditSessionRoute["id"]>>

const params = z.object({
  channelEditSessionView: z
    .object({
      sorting: z.array(
        z.object({
          id: IngestSessionFindManyParameters.shape.orderBy,
          desc: z.boolean(),
        })
      ),
      pagination: z.object({
        pageIndex: z.number().nonnegative(),
        pageSize: z.number().nonnegative(),
      }),
      search: z.string().default(""),
    })
    .catch({
      sorting: [{ desc: true, id: "stoppedAt" }],
      pagination: { pageIndex: 0, pageSize: 100 },
      search: "",
    }),
})

function toTrpcParams(params: SearchParams, channelId: string) {
  const { pagination, sorting, search } = params.channelEditSessionView

  return {
    cursor: pagination.pageIndex * pagination.pageSize,
    take: pagination.pageSize,
    orderBy: sorting[0]?.id ?? "stoppedAt",
    order: sorting[0]?.desc ? "desc" : "asc",
    search: search.length > 0 ? search : undefined,
    channelId,
  } as const
}

export const channelEditRoute = new Route({
  getParentRoute: () => channelIndexRoute,
  path: "edit/$channelId",
  component: Page,
  onLoad: async ({ context: { trpcContext }, params }) => {
    await trpcContext.channel.findById.prefetch(params, trpcOptions)
    await trpcContext.infrastructure.ingestStatus.prefetch({ channelId: params.channelId }, trpcOptions)
  },
  preSearchFilters: [(search) => search],
})
function Page() {
  const { channelId } = useParams({ from: channelEditRoute.id })
  const { data: channel } = trpc.channel.findById.useQuery({ channelId }, { suspense: true, refetchInterval: 5000 })
  const { data: ingestStatus } = trpc.infrastructure.ingestStatus.useQuery(
    { channelId },
    {
      suspense: true,
      refetchInterval: 5000,
    }
  )
  const context = trpc.useContext()
  const navigate = useNavigate()
  const ability = useAbility(AbilityContext)
  const deleteMutation = trpc.channel.delete.useMutation({
    onSuccess() {
      void context.channel.findMany.invalidate()
      void navigate({ to: "/channels", replace: true })
    },
    onError: (error) => {
      toast({ title: "Failed to delete!", description: error.message })
    },
  })

  if (!channel) {
    return null
  }

  const onDelete = async () => {
    deleteMutation.reset()
    await deleteMutation.mutateAsync({ channelId: channel.channelId })
  }

  return <ChannelEdit ability={ability} channel={channel} onDelete={onDelete} rtmpPlayUrl={ingestStatus?.rtmpPlayUrl} />
}

export const channelEditDetailsRoute = new Route({
  getParentRoute: () => channelEditRoute,
  path: "details",
  preSearchFilters: [(search) => search],
  component: () => <Details />,
})
function Details() {
  const { channelId } = useParams({ from: channelEditRoute.id })
  const { data: channel } = trpc.channel.findById.useQuery({ channelId }, { suspense: true, refetchInterval: 5000 })
  const ability = useAbility(AbilityContext)

  if (!channel) {
    return <></>
  }

  const { data: viewers } = trpc.analytics.viewersCurrent.useQuery(
    { streamKey: channel.streamKey, organizationId: channel.organization.publicId },
    { suspense: false, refetchInterval: 5000 }
  )
  const { data: channelStatus, isLoading: channelStatusLoading } = trpc.infrastructure.channelStatus.useQuery(
    { streamKey: channel.streamKey },
    {
      suspense: true,
      refetchInterval: 5000,
    }
  )
  const { data: ingestStatus } = trpc.infrastructure.ingestStatus.useQuery(
    { channelId },
    {
      suspense: false,
      refetchInterval: 5000,
    }
  )

  return (
    <ChannelEditDetails
      ability={ability}
      channel={channel}
      viewers={viewers}
      channelStatus={channelStatus}
      channelStatusLoading={channelStatusLoading}
      ingestStatus={ingestStatus}
    />
  )
}

const childRoute = (
  path:
    | "/"
    | "general"
    | "transcoding"
    | "restreaming"
    | "sourcefeed"
    | "siteaccess"
    | "vieweraccess"
    | "overrides"
    | "recording",
  component: () => JSX.Element
) =>
  new Route({
    getParentRoute: () => channelEditDetailsRoute,
    preSearchFilters: [(search) => search],
    path,
    component,
    getContext: ({ context, params }) => {
      const channel = context.trpcContext.channel.findById.getData({ channelId: params.channelId })
      return {
        pageTitle: channel?.name ?? "Channel",
      }
    },
  })

export const channelEditDetailsIndexRoute = childRoute("/", ChannelEditDetailsIndexRoutePage)
export const channelEditDetailsGeneralRoute = childRoute("general", () =>
  ChannelEditDetailsPage(ChannelEditDetailsGeneral)
)
export const channelEditDetailsTranscodingRoute = childRoute("transcoding", () =>
  ChannelEditDetailsPage(ChannelEditDetailsTranscoding)
)
export const channelEditDetailsSourceFeedRoute = childRoute("sourcefeed", () =>
  ChannelEditDetailsPage(ChannelEditDetailsSourceFeed)
)

export const channelEditDetailsSiteAccessRoute = childRoute("siteaccess", () =>
  ChannelEditDetailsPage(ChannelEditDetailsChannelAccess)
)
export const channelEditDetailsViewerAccessRoute = childRoute("vieweraccess", () =>
  ChannelEditDetailsPage(ChannelEditDetailsViewerAccess)
)
export const channelEditDetailsOverridesRoute = childRoute("overrides", () =>
  ChannelEditDetailsPage(ChannelEditDetailsOverrides)
)

function ChannelEditDetailsIndexRoutePage() {
  const { channelId } = useParams({ from: channelEditDetailsIndexRoute.id })
  return <Navigate to="/channels/edit/$channelId/details/general" params={{ channelId }} replace />
}

export type ChannelEditDetailsPageProps = {
  ability: AppAbility
  channel: ChannelFindById
  onSave: (channel: UpdateChannel) => Promise<void>
}
function ChannelEditDetailsPage(Page: (props: ChannelEditDetailsPageProps) => JSX.Element) {
  const { channelId } = useParams({ from: channelEditRoute.id })
  const { data: channel } = trpc.channel.findById.useQuery({ channelId }, { suspense: true, refetchInterval: 5000 })
  const context = trpc.useContext()
  const navigate = useNavigate()
  const ability = useAbility(AbilityContext)
  const updateMutation = trpc.channel.update.useMutation({
    onSuccess: (channel) => {
      void context.channel.findMany.invalidate()
      void context.channel.findManyByOrganizationId.invalidate()
      context.channel.findById.setData({ channelId: channel.channelId }, channel)
      toast({ title: "Saved successfully!" })
      void navigate({ to: ".", replace: true })
    },
    onError: (error) => {
      toast({ title: "Failed to save!", description: error.message })
    },
  })

  if (!channel) {
    return <></>
  }

  const subject = channelSubjectInOrganization(channel.organization.publicId)
  const onSave = async (channel: UpdateChannel) => {
    updateMutation.reset()
    const permitted = pickPermittedFields(ability, Action.Update, subject, channel)

    await updateMutation.mutateAsync({ ...permitted, channelId: channel.channelId })
  }

  return <Page ability={ability} channel={channel} onSave={onSave} />
}

export const channelEditDetailsRestreamingRoute = childRoute("restreaming", () => {
  const { channelId } = useParams({ from: channelEditRoute.id })
  const { data: channel } = trpc.channel.findById.useQuery({ channelId }, { suspense: true })

  if (!channel) {
    return <></>
  }

  const { data, refetch } = trpc.sink.findManyByChannelId.useQuery(
    { channelId, type: "restreaming", take: 5 },
    { suspense: true }
  )
  const { data: organization } = trpc.organization.findOne.useQuery(
    { publicId: channel.organization.publicId },
    { suspense: true }
  )
  const ability = useAbility(AbilityContext)
  const updateMutation = trpc.sink.updateMany.useMutation({
    onSuccess: () => {
      toast({
        title: "Saved successfully!",
        description: channel.isLive ? "Please restart your stream in order to apply the changes" : "",
      })
      void refetch()
    },
    onError: (error) => {
      toast({ title: "Failed to save!", description: error.message })
    },
  })

  const onSave = async ({ sinks }: { sinks: SinkSchema[] }) => {
    updateMutation.reset()

    await updateMutation.mutateAsync({
      type: "restreaming",
      channelId,
      sinks,
    })
  }

  const isSuperUser = checkIfSuperUser({ ability })
  const organizationUnderTrial = !isSuperUser && organization?.billingStatus !== OrganizationBillingStatus.Billable
  const canEdit =
    ability.can(Action.Update, sinkSubjectInOrganization(channel.organization.publicId)) && !organizationUnderTrial

  return (
    <ChannelEditDetailsRestreaming
      sinks={data?.items || []}
      canEdit={canEdit}
      trial={organizationUnderTrial}
      onSave={onSave}
    />
  )
})

export const channelEditSessionRoute = new Route({
  getParentRoute: () => channelEditRoute,
  path: "sessions",
  validateSearch: params,
  onLoad: async ({ params, search, context: { trpcContext } }) => {
    const { channelId } = params

    if (!channelId) {
      return
    }

    const trpcParams = toTrpcParams(search, channelId)
    await trpcContext.ingestSession.findManyByChannelId.ensureData(trpcParams, { staleTime: 1000 })
  },
  preSearchFilters: [(search) => search],
  component: () => <IngestSessions />,
})

function IngestSessions() {
  const searchParams = useSearch({ from: channelEditSessionRoute.id })
  const navigate = useNavigate({ from: channelEditSessionRoute.id })
  const { channelId } = useParams({ from: channelEditRoute.id })
  const trpcParams = toTrpcParams(searchParams, channelId)
  const { toast } = useToast()

  const { data } = trpc.ingestSession.findManyByChannelId.useQuery(trpcParams, {
    keepPreviousData: true,
    staleTime: 1000,
  })

  const [sorting, setSorting] = useState<SortingState>(searchParams.channelEditSessionView.sorting)
  const [pagination, setPagination] = useState<PaginationState>(searchParams.channelEditSessionView.pagination)
  const [globalFilter, setGlobalFilter] = useState(searchParams.channelEditSessionView.search)
  const [value, setValue, remove] = useLocalStorage<VisibilityState>(
    "channel-edit-ingest-sessions-columns",
    defaultColumns
  )
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(value ?? defaultColumns)
  const pageCount = Math.ceil((data?.total ?? 0) / pagination.pageSize)
  const [navigationState, doNavigate] = useAsyncFn(
    async (...args: Parameters<typeof navigate>) => {
      return navigate(...args)
    },
    [navigate]
  )

  if (globalFilter !== searchParams.channelEditSessionView.search && pagination.pageIndex !== 0) {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }))
  }

  useEffect(() => {
    void doNavigate({
      to: "/channels/edit/$channelId/sessions",
      params: { channelId: channelId },
      search: (prev) => {
        return { ...prev, channelEditSessionView: { sorting, pagination, search: globalFilter } }
      },
    })
  }, [doNavigate, sorting, pagination, globalFilter, channelId])

  return (
    <ChannelEditIngestSessions
      data={data?.items ?? []}
      pageCount={pageCount}
      manualPagination
      manualSorting
      manualFiltering
      showFilter={false}
      isStaleData={navigationState.loading || globalFilter !== searchParams.channelEditSessionView.search}
      state={{ sorting, pagination, globalFilter, columnVisibility }}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      onGlobalFilterChange={setGlobalFilter}
      onColumnVisibilityChange={setColumnVisibility}
      saveColumnVisibility={() => {
        setValue(columnVisibility)
        toast({ title: "Saved configuration" })
      }}
      resetColumnVisibilityToDefault={() => {
        remove()
        setColumnVisibility(defaultColumns)
        toast({ title: "Restored to default" })
      }}
    />
  )
}

export const channelEditBroadcastRoute = new Route({
  getParentRoute: () => channelEditRoute,
  path: "broadcast",
  preSearchFilters: [(search) => search],
  component: () => <Broadcast />,
  getContext: ({ context, params }) => {
    const channel = context.trpcContext.channel.findById.getData({ channelId: params.channelId })
    return {
      pageTitle: channel?.name ?? "Channel",
    }
  },
})
function Broadcast() {
  const { channelId } = useParams({ from: channelEditRoute.id })
  const { data: channel } = trpc.channel.findById.useQuery({ channelId }, { suspense: true })
  const ability = useAbility(AbilityContext)

  return channel ? <ChannelEditBroadcast channel={channel} ability={ability} /> : null
}

export const channelEditPlayoutRoute = new Route({
  getParentRoute: () => channelEditRoute,
  path: "playout",
  preSearchFilters: [(search) => search],
  component: () => ChannelEditDetailsPage(ChannelEditPlayout),
  getContext: ({ context, params }) => {
    const channel = context.trpcContext.channel.findById.getData({ channelId: params.channelId })
    return {
      pageTitle: channel?.name ?? "Channel",
    }
  },
})

export const channelEditDebugRoute = new Route({
  getParentRoute: () => channelEditRoute,
  path: "debug",
  preSearchFilters: [(search) => search],
  component: () => <Debug />,
  getContext: ({ context, params }) => {
    const channel = context.trpcContext.channel.findById.getData({ channelId: params.channelId })
    return {
      pageTitle: channel?.name ?? "Channel",
    }
  },
})
function Debug() {
  const { channelId } = useParams({ from: channelEditRoute.id })
  const { data: channel } = trpc.channel.findById.useQuery({ channelId }, { suspense: true })
  const ability = useAbility(AbilityContext)

  if (!checkIfSuperUser({ ability })) {
    return <></>
  }

  return channel ? <ChannelEditDebug channel={channel} /> : null
}

export const channelEditDetailsRecordingRoute = childRoute("recording", () => {
  const { channelId } = useParams({ from: channelEditRoute.id })
  const { data: channel } = trpc.channel.findById.useQuery({ channelId }, { suspense: true })
  const { data: recordingSink } = trpc.sink.findRecordingSink.useQuery(
    { channelId },
    { suspense: true, refetchInterval: 5000 }
  )

  const context = trpc.useContext()
  const navigate = useNavigate()
  const ability = useAbility(AbilityContext)

  const updateMutation = trpc.sink.updateRecordingSink.useMutation({
    onSuccess: (recording) => {
      context.sink.findRecordingSink.setData({ channelId }, recording)
      toast({ title: "Saved successfully!" })
      void navigate({ to: ".", replace: true })
    },
    onError: (error) => {
      toast({ title: "Failed to save!", description: error.message })
    },
  })

  if (!channel) {
    return <></>
  }

  // Currently we don't want to enable this for anyone but super users
  //const currentSubject = subject("Sink", { channel: { organization: { publicId: channel.organization.publicId } } })
  //const canEdit = ability.can(Action.Update, currentSubject)

  const canEdit = checkIfSuperUser({ ability })

  const onSave = async (recording: UpdateRecordingSink) => {
    updateMutation.reset()
    // TODO: This messes stuff up. Is it needed?
    // const permitted = pickPermittedFields(ability, Action.Update, currentSubject, recording)
    await updateMutation.mutateAsync(recording)
  }

  return (
    <ChannelEditDetailsRecording
      streamKey={channel.streamKey}
      channelId={channelId}
      canEdit={canEdit}
      ability={ability}
      recordingSink={recordingSink}
      onSave={onSave}
    />
  )
})
