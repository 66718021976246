import * as RadixSlider from "@radix-ui/react-slider"
import { SliderProps } from "@radix-ui/react-slider"

export function Slider(props: SliderProps) {
  return (
    <RadixSlider.Root
      {...props}
      className="relative flex h-5 w-full touch-none select-none items-center aria-disabled:opacity-50 aria-disabled:dark:opacity-40"
    >
      <RadixSlider.Track className="relative h-1 grow rounded-full bg-gray-6">
        <RadixSlider.Range className="absolute h-full rounded-full bg-blue-8" />
      </RadixSlider.Track>
      <RadixSlider.Thumb className="block h-4 w-4 rounded-full bg-solid-primary outline-none ring-solid-primary transition-all focus:ring-2" />
    </RadixSlider.Root>
  )
}
