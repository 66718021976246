import {
  AppAbility,
  HyperlocalEdge,
  HyperlocalEdgeItem,
  OrganizationFindOne,
  UpdateHyperlocalEdges,
} from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { Cross1Icon, PlusIcon } from "@radix-ui/react-icons"
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import {
  Button,
  Chip,
  ConfirmDialogContent,
  Copyable,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
} from "@vindral/components"
import { useEffect } from "react"
import { useController, useFieldArray, useForm } from "react-hook-form"
import { FormSection } from "../../../atoms/FormSection"
import { SaveButton } from "../../../atoms/SaveButton"
import { Message, MessageSection } from "../../../molecules/Message"
import { RouteBlockDialog } from "../../../molecules/RouteBlockDialog"
import { InteractiveTable } from "../../../organisms/InteractiveTable"

const itemResolver = zodResolver(HyperlocalEdge)
const arrayResolver = zodResolver(UpdateHyperlocalEdges)

export interface OrganizationEditHyperlocalProps {
  organization: OrganizationFindOne
  ability: AppAbility
  edges: HyperlocalEdgeItem[]
  canEdit: boolean
  trial: boolean
  onSave: (value: UpdateHyperlocalEdges) => Promise<void>
  onResetHyperlocalApiKey: () => Promise<void>
}

export function OrganizationEditHyperlocal({
  organization,
  edges,
  canEdit,
  trial,
  onSave,
  onResetHyperlocalApiKey,
}: OrganizationEditHyperlocalProps) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty, isValid, isSubmitting, isSubmitSuccessful },
  } = useForm({
    resolver: arrayResolver,
    mode: "onChange",
    defaultValues: {
      organizationId: organization.publicId,
      edges: edges.map((edge) => ({
        url: edge.url,
        ip: edge.ip,
        enabled: edge.enabled,
      })),
    },
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful, reset])

  const { fields, append, update, remove } = useFieldArray({ control, name: "edges" })
  const columnHelper = createColumnHelper<typeof fields[0]>()
  const columns = [
    columnHelper.accessor("url", {
      header: () => "Domain Name",
      cell: (info) => <div className="truncate whitespace-nowrap">{info.getValue()}</div>,
      maxSize: 300,
    }),
    columnHelper.accessor("ip", {
      header: () => "External IP",
      cell: (info) => <div className="truncate whitespace-nowrap">{info.getValue()}</div>,
      maxSize: 300,
    }),
    columnHelper.accessor("enabled", {
      header: () => "Status",
      cell: (info) => <Chip color={info.getValue() ? "green" : "red"}>{info.getValue() ? "Enabled" : "Disabled"}</Chip>,
    }),
    columnHelper.display({
      id: "actions",

      header: () => "",
      meta: { style: { textAlign: "right" } },
      cell: (info) => (
        <div className="flex justify-end gap-2">
          <EditItemDialog edge={info.row.original} onSubmit={(value) => update(info.row.index, value)} />
          <Button variant="danger" size="medium" onClick={() => remove(info.row.index)}>
            Remove
          </Button>
        </div>
      ),
    }),
  ]

  const table = useReactTable({
    data: fields,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      columnVisibility: {
        actions: canEdit,
      },
    },
  })
  const defaultUrl = `${organization.uniqueName}-edge-0${fields.length + 1}.hyperlocal.cdn.vindral.com`

  return (
    <div className="flex flex-col gap-4 py-4">
      {trial ? (
        <Message severity="warning">
          <MessageSection>
            <div className="flex w-full">
              <div className="grow">
                <span>
                  Your organization does not currently have access to manage hyperlocal edges. To enable this feature,
                  please request full access.
                </span>
              </div>
            </div>
          </MessageSection>
        </Message>
      ) : null}
      <RouteBlockDialog condition={isDirty && !isSubmitting} />
      <FormSection
        title="Hyperlocal API Key"
        description="The API key is used in the configuration of each hyperlocal edge to enable secure communication with the Vindral API."
        docsLink="https://docs.vindral.com/manage/hyperlocal#hyperlocal-api-key"
      >
        <div className="text-right">
          <div className="mr-4 inline-block">
            <Copyable text={organization.hyperlocalApiKey}>
              <span className="block truncate font-mono text-sm">{organization.hyperlocalApiKey}</span>
            </Copyable>
          </div>
          <Dialog>
            <DialogTrigger asChild>
              <Button disabled={!canEdit} variant="danger">
                Regenerate API Key
              </Button>
            </DialogTrigger>
            <ConfirmDialogContent
              onConfirm={onResetHyperlocalApiKey}
              title="Regenerate API Key"
              description="Please confirm that you want to regenerate the API Key! All active hyperlocal edges using the current API key will no longer have access to the Vindral API."
            />
          </Dialog>
        </div>
      </FormSection>
      <FormSection
        title="Hyperlocal Edges"
        description="Set up your hyperlocal edges. Enter the external IP address and domain name for your local edges here. Before the edge can be used, you have to contact us and get the edge package and start it on your server."
        docsLink="https://docs.vindral.com/manage/hyperlocal"
      >
        <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSave)}>
          <div className="flex justify-end">
            {canEdit && (
              <AddItemDialog
                onSubmit={(value) => {
                  append(value)
                }}
                defaultUrl={defaultUrl}
                disabled={fields.length >= 5}
              />
            )}
          </div>
          {errors.edges?.message && <FormControlLabel error={errors.edges.message} />}
          <InteractiveTable table={table} includePaginationFooter />
          {canEdit && (
            <div>
              <SaveButton text="Save" disabled={!isDirty || !isValid} />
            </div>
          )}
        </form>
      </FormSection>
    </div>
  )
}

function AddItemDialog(props: { onSubmit: (value: HyperlocalEdge) => void; disabled: boolean; defaultUrl: string }) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button disabled={props.disabled}>
          <PlusIcon />
          Add new
        </Button>
      </DialogTrigger>
      <ItemDialogContent
        title="Add Hyperlocal Edge"
        actionTitle="Add"
        edge={{ url: props.defaultUrl, ip: "", enabled: true }}
        onSubmit={props.onSubmit}
      />
    </Dialog>
  )
}

function EditItemDialog(props: { edge: HyperlocalEdge; onSubmit: (value: HyperlocalEdge) => void }) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button size="medium">Edit</Button>
      </DialogTrigger>
      <ItemDialogContent
        title="Edit Hyperlocal Edge"
        actionTitle="Confirm"
        edge={props.edge}
        onSubmit={props.onSubmit}
      />
    </Dialog>
  )
}

function ItemDialogContent(props: {
  title: string
  actionTitle: string
  edge: HyperlocalEdge
  onSubmit: (value: HyperlocalEdge) => void
}) {
  const { title, actionTitle, edge, onSubmit } = props
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<HyperlocalEdge>({
    resolver: itemResolver,
    mode: "onChange",
    defaultValues: {
      url: edge.url,
      ip: edge.ip,
      enabled: edge.enabled,
    },
  })

  const enabledController = useController({ control, name: "enabled" })

  return (
    <DialogContent
      size="medium"
      onOpenAutoFocus={() =>
        reset({
          url: edge.url,
          ip: edge.ip,
          enabled: edge.enabled,
        })
      }
    >
      <form
        onSubmit={(event) => {
          event.stopPropagation()
          void handleSubmit(onSubmit)(event)
        }}
      >
        <div className="flex justify-between border-b border-divider p-4">
          <h1 className="font-medium">{title}</h1>
          <DialogClose>
            <Cross1Icon />
          </DialogClose>
        </div>
        <div className="p-4">
          <div className="flex flex-col gap-4">
            <FormControl>
              <FormControlLabel error={errors.url?.message}>Domain Name</FormControlLabel>
              <TextField {...register("url")} error={!!errors?.url} />
            </FormControl>
            <FormControl>
              <FormControlLabel error={errors.ip?.message}>External IP address</FormControlLabel>
              <TextField {...register("ip")} error={!!errors?.ip} placeholder="93.184.215.14" />
            </FormControl>
            <FormControl>
              <FormControlLabel>Enabled</FormControlLabel>
              <Switch onCheckedChange={enabledController.field.onChange} checked={enabledController.field.value} />
            </FormControl>
          </div>
        </div>
        <div className="border-t border-divider p-4 text-right">
          <DialogClose asChild>
            <Button disabled={!isDirty || !isValid} type="submit" variant="primary">
              {actionTitle}
            </Button>
          </DialogClose>
        </div>
      </form>
    </DialogContent>
  )
}
