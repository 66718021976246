import { Action } from "@core-services/data-types"
import { AppAbility, OrganizationFindOne } from "@core-services/portal"
import { Button, ConfirmDialogContent, Copyable, Dialog, DialogTrigger } from "@vindral/components"
import { organizationSubjectInOrganization } from "../../../acl"
import { FormSection } from "../../../atoms/FormSection"

export interface OrganizationEditAuthProps {
  organization: OrganizationFindOne
  ability: AppAbility
  onResetSecret: () => Promise<void>
}

export function OrganizationEditAuth({ organization, ability, onResetSecret }: OrganizationEditAuthProps) {
  const subject = organizationSubjectInOrganization(organization.publicId)
  const columnWidth = "w-full max-w-lg"

  return (
    <div className="py-4">
      <FormSection
        width={columnWidth}
        title="Authentication Secret"
        description="The secret is used to sign JWT tokens for channels that require authentication."
        docsLink="https://docs.vindral.com/manage/advanced-authorization"
      >
        <div className="text-right">
          <div className="mr-4 inline-block">
            <Copyable text={organization.authSecret}>
              <span className="block truncate font-mono text-sm" data-pw="authsecret">
                {organization.authSecret}
              </span>
            </Copyable>
          </div>
          <Dialog>
            <DialogTrigger asChild>
              <Button disabled={ability.cannot(Action.Update, subject, "authSecret")} variant="danger">
                Regenerate Secret
              </Button>
            </DialogTrigger>
            <ConfirmDialogContent
              onConfirm={onResetSecret}
              title="Regenerate Secret"
              description="Please confirm that you want to regenerate the secret! All active clients using tokens signed with the current secret will be instantly invalid and required to get a new token signed with the new secret."
            />
          </Dialog>
        </div>
      </FormSection>
    </div>
  )
}
