import { AxiosError } from "axios"

export const isAxiosError = (error: unknown): error is AxiosError<{ message?: string }> => {
  if (typeof error === "object") {
    if (!error) {
      return false
    }

    if ("isAxiosError" in error) {
      return true
    }
  }

  return false
}

const isPlainError = (error: unknown): error is Error => {
  if (error instanceof Object) {
    return "message" in error && "name" in error
  }

  return false
}

export const getErrorMessage = (error: unknown): string => {
  if (isAxiosError(error)) {
    const message = error.response?.data?.message
    if (message) {
      return message
    }
  }

  if (isPlainError(error)) {
    return error.message
  }

  return "An error occured!"
}
