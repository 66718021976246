import { useAbility } from "@casl/react"
import { IconPlus } from "@tabler/icons-react"
import { Link } from "@tanstack/react-router"
import { Button, Pagination } from "@vindral/components"
import { AbilityContext, checkIfSuperUser } from "../../acl"
import { Page, PageContainer, PageContent, PageFooter, PageHeader } from "../../atoms/Page"
import { Breadcrumbs } from "../../molecules/Breadcrumbs"
import {
  OrganizationTable,
  useOrganizationReactTable,
  type UseOrganizationReactTableProps,
} from "../../organisms/OrganizationTable"

function CreateOrganizationButton() {
  const ability = useAbility(AbilityContext)
  const isSuperUser = checkIfSuperUser({ ability })

  if (!isSuperUser) {
    return null
  }

  return (
    <Link to="/organizations/create">
      <Button variant="primary">
        <IconPlus size={16} />
        Create organization
      </Button>
    </Link>
  )
}

export type OrganizationListProps = UseOrganizationReactTableProps & {
  saveColumnVisibility: () => void
  resetColumnVisibilityToDefault: () => void
  isStaleData: boolean
  totalCount: number
}

export function OrganizationList({ isStaleData, totalCount, ...props }: OrganizationListProps) {
  const organizationTable = useOrganizationReactTable(props)

  if (props.data.length === 0 && !props.state?.globalFilter && !isStaleData) {
    return (
      <div className="relative flex justify-center pt-40">
        <div>
          <h1 className="font-medium">There is nothing here right now</h1>
          <p className="py-1 text-fg-subtle last-of-type:pb-3">You will be able to see organizations here very soon.</p>
        </div>
      </div>
    )
  }

  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
            <CreateOrganizationButton />
          </div>
        </PageHeader>
        <PageContainer>
          <div className="py-4">
            <OrganizationTable {...props} table={organizationTable} />
          </div>
        </PageContainer>
      </PageContent>
      <PageFooter>
        <div className="flex h-16 items-center justify-between">
          <div>
            {totalCount} Organization{totalCount > 1 ? "s" : ""}
          </div>
          <Pagination
            pageIndex={organizationTable.getState().pagination.pageIndex}
            pageCount={organizationTable.getPageCount()}
            nextPage={() => organizationTable.nextPage()}
            previousPage={() => organizationTable.previousPage()}
          />
        </div>
      </PageFooter>
    </Page>
  )
}
