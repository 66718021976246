import { z } from "zod"
import { normalizeIdentifier } from "../normalize"

export const UpdateChannelGroup = z.object({
  name: z
    .string()
    .min(2)
    .max(50)
    .refine((val) => normalizeIdentifier(val).length >= 2, {
      message: "Normalized name must be at least 2 characters long",
    })
    .optional(),
  channelIds: z.array(z.string()).optional().describe("List of channel IDs that should be part of the group."),
})

export type UpdateChannelGroup = z.infer<typeof UpdateChannelGroup>
