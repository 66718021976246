import { useQueryClient } from "@tanstack/react-query"
import { Outlet, Route, useNavigate } from "@tanstack/react-router"
import { useLogout } from "../http/mutations"
import { ProfileMenu } from "../templates/profile/ProfileMenu"
import { rootRoute } from "./root"

export const noNavRoute = new Route({
  id: "no-nav",
  getParentRoute: () => rootRoute,
  component: Index,
})

function Index() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const logoutMutation = useLogout()

  const handleLogOut = async () => {
    await logoutMutation.mutateAsync(undefined, {
      onSuccess: () => {
        queryClient.removeQueries()
        void navigate({ to: "/auth/login" })
      },
    })
  }

  return (
    <div className="relative">
      <div className="absolute right-0 top-0 z-40 p-6">
        <ProfileMenu onLogOut={handleLogOut} hideProfileLink />
      </div>
      <Outlet />
    </div>
  )
}
