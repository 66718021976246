export class VindralEnvironment {
  public readonly domain: string

  constructor(origin: string) {
    const url = new URL(origin)

    if (url.hostname.includes("vindral.test")) {
      this.domain = "dev.cdn.vindral.com"
      return
    }

    // Capture everything after the first dot as long as the part before the dot contains portal
    const domainRegex = /portal[^.]*\.(.*)/
    const match = url.hostname.match(domainRegex)
    this.domain = match?.[1] ?? "cdn.vindral.com"
  }
}

export function qosUrl() {
  return "https://qos.vindral.com"
}

export const embedUrl = () => {
  return "https://embed.vindral.com"
}

export const gridUrl = () => {
  return "https://grid.vindral.com"
}

export const webrtcIngestBaseUrl = () => {
  return "https://webrtc-ingest-qos.vindral.com"
}

export const webrtcIngestUrl = (args: { streamKey: string }) => {
  return `${webrtcIngestBaseUrl()}/?core.url=${lbUrl()}&core.streamKey=${args.streamKey}`
}

export function lbUrl() {
  const environment = new VindralEnvironment(window.origin)

  return `https://lb.${environment.domain}`
}

export const rtmpIngestBaseUrl = (args: { secure: boolean }) => {
  const environment = new VindralEnvironment(window.origin)
  const protocol = args.secure ? "rtmps" : "rtmp"

  return `${protocol}://rtmp.global.${environment.domain}/publish`
}

export const rtmpIngestUrl = ({ streamKey, secure }: { streamKey: string; secure: boolean }) => {
  return `${rtmpIngestBaseUrl({ secure })}/${streamKey}`
}

export const srtIngestBaseUrl = () => {
  const environment = new VindralEnvironment(window.origin)
  return `srt://srt.global.${environment.domain}:3333`
}

export const srtStreamId = (args: { streamKey: string }) => `#!::r=${args.streamKey}`

export const srtIngestUrl = (args: { streamKey: string; passphrase: string | undefined }) => {
  const base = `${srtIngestBaseUrl()}?streamid=${srtStreamId(args)}`

  if (!args.passphrase) {
    return base
  }

  return `${base}&passphrase=${args.passphrase}`
}

export const embedPlayerUrl = (args: {
  channelId: string
  authToken?: string
  edgeUrl?: string
  poster?: string
  muted?: boolean
  controlsDisabled?: boolean
}): string => {
  let url = `${embedUrl()}/?core.url=${lbUrl()}&core.channelId=${args.channelId}`

  if (args.edgeUrl) {
    url += `&core.edgeUrl=${args.edgeUrl}`
  }

  if (args.authToken) {
    url += `&core.authenticationToken=${args.authToken}`
  }

  if (args.poster) {
    url += `&core.poster=${args.poster}`
  }

  if (args.muted) {
    url += "&core.muted=true"
  }

  if (args.controlsDisabled) {
    url += "&player.controlsEnabled=false"
  }

  return url
}

export const playUrl = (args: {
  channelId: string
  authToken?: string
  edgeUrl?: string
  visualMetadata?: boolean
}): string => {
  let url = `${qosUrl()}?core.url=${lbUrl()}&core.channelId=${args.channelId}`

  if (args.edgeUrl) {
    url += `&core.edgeUrl=${args.edgeUrl}`
  }

  if (args.authToken) {
    url += `&core.authenticationToken=${args.authToken}`
  }

  if (args.visualMetadata) {
    url += "&qos.visualMetadataEnabled=true"
  }

  return url
}

export const playGridUrlChannels = (args: {
  channelIds: string[]
  authToken?: string
  useThumbnails?: boolean
  edgeUrl?: string
}): string => {
  const { authToken, useThumbnails, edgeUrl } = args
  const channelParam = `&core.channelId=${args.channelIds.join(",")}`

  return playGridUrl({ authToken, useThumbnails, channelParam, edgeUrl })
}

export const playGridUrlChannelGroupId = (args: {
  channelGroupId: string
  authToken?: string
  useThumbnails?: boolean
  edgeUrl?: string
}): string => {
  const { authToken, useThumbnails, edgeUrl } = args
  const channelParam = `&core.channelGroupId=${args.channelGroupId}`

  return playGridUrl({ authToken, useThumbnails, channelParam, edgeUrl })
}

const playGridUrl = (args: { channelParam: string; authToken?: string; useThumbnails?: boolean; edgeUrl?: string }) => {
  const thumbnailsParam = args.useThumbnails ? "&onlyThumbnails=true" : ""
  const authTokenParam = args.authToken ? `&core.authenticationToken=${args.authToken}` : ""
  const edgeUrlParam = args.edgeUrl ? `&core.edgeUrl=${args.edgeUrl}` : ""

  return `${gridUrl()}?core.url=${lbUrl()}${args.channelParam}${thumbnailsParam}${authTokenParam}${edgeUrlParam}`
}

export function grafanaUrl() {
  const environment = new VindralEnvironment(window.origin)
  return `https://grafana.${environment.domain}`
}

export function nomadUrl() {
  const environment = new VindralEnvironment(window.origin)
  return `https://nomad.${environment.domain}`
}
