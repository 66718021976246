import { cx } from "class-variance-authority"
import { ReactNode } from "react"

/**
 * Sets up a vertical stack with horizontal stretching
 */
export function Page({ children }: { children: ReactNode }) {
  return <div className="flex h-screen flex-col items-stretch">{children}</div>
}

/**
 * Wraps header content with an optional border and container to limit the width
 */
export function PageHeader({ children, border = true }: { children: ReactNode; border?: boolean }) {
  return (
    <div className={border ? "border-b border-divider" : ""}>
      <PageContainer paddingDirection="horizontal">{children}</PageContainer>
    </div>
  )
}

/**
 * PageContent grows to fill its flex parent but handles overflow by scrolling
 */
export function PageContent({ children }: { children: ReactNode }) {
  return <div className="grow overflow-y-auto">{children}</div>
}

/**
 * Limits the width of the page and adds padding
 */
export function PageContainer({
  children,
  paddingDirection = "both",
}: {
  children: ReactNode
  paddingDirection?: "horizontal" | "both"
}) {
  return (
    <div className={cx("mx-auto w-full max-w-screen-xl px-4", paddingDirection === "both" ? "py-4" : "")}>
      {children}
    </div>
  )
}

export function PageFooter({ children }: { children: ReactNode }) {
  return (
    <div className="border-t border-divider">
      <PageContainer paddingDirection="horizontal">{children}</PageContainer>
    </div>
  )
}
