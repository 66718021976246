import { Route } from "@tanstack/react-router"
import { onboardedRoute } from "../onboarded"

export const teamIndexRoute = new Route({
  getParentRoute: () => onboardedRoute,
  preSearchFilters: [(search) => search],
  path: "team",
  getContext: () => {
    return {
      pageTitle: "Team",
    }
  },
})
