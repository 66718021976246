import { z } from "zod"

export const UpgradeAccountRequest = z.object({
  useCase: z.string().min(1, { message: "Please give a short description of your use case." }),
  peakViewers: z.string().min(1, { message: "Please provide the expected peak viewers." }),
  geographic: z.string().min(1, { message: "Please provide the expected geographic location of your viewers." }),
  streamingFrequency: z.string().min(1, { message: "Please state how often you plan to stream." }),
  channelCount: z.string().min(1, { message: "Please provide the expected number of channels." }),
  email: z.string(),
  organizationId: z.string(),
})
export type UpgradeAccountRequest = z.infer<typeof UpgradeAccountRequest>
