import { FormSection } from "../../atoms/FormSection"
import { UsersManageTable, UsersTableProps } from "../../organisms/UsersManageTable"

export const UserList = (props: UsersTableProps) => {
  return (
    <div className="py-4">
      <FormSection
        title="Team Members"
        description={
          <div className="flex flex-col gap-2">
            <p>Team members can access resources belonging to this organization.</p>
            <p>
              <span className="font-semibold">Users</span> have read-only access to transcoding profiles, channels,
              channel groups and the organization.
            </p>
            <p>
              <span className="font-semibold">Channel Managers</span> inherit User permissions but can also edit
              transcoding profiles, channels and channel groups.
            </p>
            <p>
              <span className="font-semibold">Administrators</span> inherit Channel Manager permissions but can also
              edit organization members access permissions.
            </p>
          </div>
        }
      >
        <UsersManageTable {...props} />
      </FormSection>
    </div>
  )
}
