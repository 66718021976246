import { useContext } from "react"
import { Titled } from "../../molecules/Titled"
import { SearchResultRow } from "./Row"
import { SearchContext, SearchResultItem, getSearchResultId } from "./context"

export const SearchResults = () => {
  const context = useContext(SearchContext)
  const { groups } = context
  return (
    <div className="flex flex-col gap-4">
      {groups.map((group) => (
        <div key={group.type}>
          <Titled title={group.type}>
            <div className="flex flex-col justify-center gap-3 pt-3 align-middle">
              {group.items.map((item: SearchResultItem) => {
                const id = getSearchResultId(item) || ""
                return (
                  <div key={id}>
                    <SearchResultRow result={item} type={group.type} />
                  </div>
                )
              })}
            </div>
          </Titled>
        </div>
      ))}
    </div>
  )
}
