import { subject } from "@casl/ability"
import { useAbility } from "@casl/react"
import { Action, OrganizationBillingStatus } from "@core-services/data-types"
import { AppAbility, OrganizationFindOne, UpdateHyperlocalEdges, UpdateOrganization } from "@core-services/portal"
import { AnyRoute, Route, useNavigate, useParams, useSearch } from "@tanstack/react-router"
import { toast } from "@vindral/components"
import {
  AbilityContext,
  checkIfSuperUser,
  hyperlocalEdgeSubjectInOrganization,
  organizationSubjectInOrganization,
  pickPermittedFields,
} from "../../acl"
import { OrganizationEditAuth } from "../../templates/organization/edit/OrganizationEditAuth"
import { OrganizationEditClientOverrides } from "../../templates/organization/edit/OrganizationEditClientOverrides"
import { OrganizationEditGeneral } from "../../templates/organization/edit/OrganizationEditGeneral"
import { OrganizationEditHyperlocal } from "../../templates/organization/edit/OrganizationEditHyperlocal"
import { OrganizationEditLimits } from "../../templates/organization/edit/OrganizationEditLimits"
import { OrganizationEditSiteRules } from "../../templates/organization/edit/OrganizationEditSiteRules"
import { OrganizationEditTelemetry } from "../../templates/organization/edit/OrganizationEditTelemetry"
import {
  OrganizationEditWebhooks,
  webhookConfigSchema,
} from "../../templates/organization/edit/OrganizationEditWebhooks"
import { trpc } from "../../trpc"

export const trpcOptions = { staleTime: 1000 }

const useOrganizationPublicId = (): string => {
  const { publicId } = useParams()
  const search = useSearch({ from: "/onboarded", strict: false })

  const organizationPublicId = publicId || search?.organizationId

  if (!organizationPublicId) {
    throw new Error("publicId is undefined")
  }

  return organizationPublicId
}

export const getChildRoutes = (parentRoute: AnyRoute) => {
  const childRoute = (
    path:
      | "general"
      | "webhooks"
      | "authentication"
      | "limits"
      | "site-rules"
      | "client-overrides"
      | "telemetry"
      | "hyperlocal",
    component: () => JSX.Element,
    pageTitle: string
  ) =>
    new Route({
      getParentRoute: () => parentRoute,
      path,
      component,
      getContext: () => ({ pageTitle }),
    })

  const organizationEditGeneralRoute = childRoute("general", () => EditPage(OrganizationEditGeneral), "General")
  const organizationEditWebhooksRoute = childRoute("webhooks", OrganizationEditWebhooksWrapper, "Webhooks")
  const organizationEditAuthenticationRoute = childRoute(
    "authentication",
    () => EditPage(OrganizationEditAuthWrapper),
    "Authentication"
  )
  const organizationEditLimitsRoute = childRoute("limits", () => EditPage(OrganizationEditLimits), "Limits")
  const organizationEditSiteRulesRoute = childRoute(
    "site-rules",
    () => EditPage(OrganizationEditSiteRules),
    "Site Rules"
  )
  const organizationEditClientOverridesRoute = childRoute(
    "client-overrides",
    () => EditPage(OrganizationEditClientOverrides),
    "Client Overrides"
  )
  const organizationEditTelemetryRoute = childRoute("telemetry", () => EditPage(OrganizationEditTelemetry), "Telemetry")
  const organizationEditHyperlocalRoute = childRoute("hyperlocal", OrganizationEditHyperlocalWrapper, "Hyperlocal")

  return [
    organizationEditGeneralRoute,
    organizationEditWebhooksRoute,
    organizationEditAuthenticationRoute,
    organizationEditLimitsRoute,
    organizationEditSiteRulesRoute,
    organizationEditClientOverridesRoute,
    organizationEditTelemetryRoute,
    organizationEditHyperlocalRoute,
  ]
}

const OrganizationEditAuthWrapper = ({ ability, organization }: OrganizationEditPageProps) => {
  const context = trpc.useContext()
  const resetSecretMutation = trpc.organization.resetOrganizationAuthSecret.useMutation({
    async onSuccess(input) {
      await context.organization.findMany.invalidate()
      context.organization.findOne.setData({ publicId: input.publicId }, input)
    },
    onError: (error) => {
      toast({ title: "Failed to reset secret!", description: error.message })
    },
  })
  const onResetSecret = async () => {
    resetSecretMutation.reset()
    await resetSecretMutation.mutateAsync({ publicId: organization.publicId })
  }

  return <OrganizationEditAuth ability={ability} organization={organization} onResetSecret={onResetSecret} />
}

type OrganizationEditPageProps = {
  organization: OrganizationFindOne
  ability: AppAbility
  onUpdate: (organization: UpdateOrganization) => Promise<void>
}
function EditPage(Page: (props: OrganizationEditPageProps) => JSX.Element) {
  const context = trpc.useContext()
  const navigate = useNavigate()
  const publicId = useOrganizationPublicId()
  const ability = useAbility(AbilityContext)
  const { data: organization } = trpc.organization.findOne.useQuery({ publicId }, trpcOptions)

  const updateMutation = trpc.organization.update.useMutation({
    async onSuccess(input) {
      await context.organization.findMany.invalidate()

      context.organization.findOne.setData({ publicId: input.publicId }, input)

      // The routes getContext does not get invalidated without navigate
      void navigate({ to: ".", replace: true })
      toast({ title: "Saved successfully!" })
    },
    onError: (error) => {
      toast({ title: "Failed to save!", description: error.message })
    },
  })
  const subject = organizationSubjectInOrganization(publicId)
  const onUpdate = async (organization: UpdateOrganization) => {
    const permittedFields = pickPermittedFields(ability, Action.Update, subject, organization)
    await updateMutation.mutateAsync({ ...permittedFields, publicId })
  }

  return organization ? <Page ability={ability} organization={organization} onUpdate={onUpdate} /> : <></>
}

export function OrganizationEditWebhooksWrapper() {
  const organizationId = useOrganizationPublicId()
  const { data: webhookConfig } = trpc.webhook.findByOrganizationId.useQuery({ organizationId }, { suspense: true })
  const context = trpc.useContext()
  const ability = useAbility(AbilityContext)
  const canEditWebhookConfig = ability.can(
    Action.Create,
    subject("OrganizationWebhookConfig", { organization: { publicId: organizationId } })
  )

  const createMutation = trpc.webhook.create.useMutation({
    onSuccess: () => {
      void context.webhook.findByOrganizationId.invalidate()
      toast({ title: "Saved successfully!" })
    },
    onError: (error) => {
      toast({ title: "Failed to save!", description: error.message })
    },
  })
  const onCreate = async (value: webhookConfigSchema) => {
    createMutation.reset()
    await createMutation.mutateAsync({
      organizationPublicId: organizationId,
      ...value,
    })
  }

  const updateMutation = trpc.webhook.update.useMutation({
    onSuccess: () => {
      void context.webhook.findByOrganizationId.invalidate()
      toast({ title: "Saved successfully!" })
    },
    onError: (error) => {
      toast({ title: "Failed to save!", description: error.message })
    },
  })
  const onUpdate = async (value: webhookConfigSchema) => {
    if (!webhookConfig) {
      return
    }
    updateMutation.reset()
    await updateMutation.mutateAsync({
      organizationPublicId: organizationId,
      publicId: webhookConfig?.publicId ?? "",
      ...value,
    })
  }

  const testCallbackUrlMutation = trpc.webhook.testCallbackUrl.useMutation()
  const onTestCallbackUrl = async (url: string) => {
    testCallbackUrlMutation.reset()

    return await testCallbackUrlMutation.mutateAsync({
      organizationPublicId: organizationId,
      url,
    })
  }

  return (
    <OrganizationEditWebhooks
      webhookConfig={webhookConfig}
      onCreate={onCreate}
      onUpdate={onUpdate}
      onTestCallbackUrl={onTestCallbackUrl}
      canEdit={canEditWebhookConfig}
    />
  )
}

export function OrganizationEditHyperlocalWrapper() {
  const publicId = useOrganizationPublicId()
  const { data: organization } = trpc.organization.findOne.useQuery({ publicId }, trpcOptions)
  const { data, refetch } = trpc.hyperlocalEdge.findManyByOrganizationId.useQuery(
    { organizationId: publicId },
    { suspense: true }
  )
  const context = trpc.useContext()
  const ability = useAbility(AbilityContext)

  if (!organization) {
    return <></>
  }

  const updateMutation = trpc.hyperlocalEdge.updateMany.useMutation({
    onSuccess: () => {
      toast({
        title: "Saved successfully!",
      })
      void refetch()
    },
    onError: (error) => {
      toast({ title: "Failed to save!", description: error.message })
    },
  })

  const onSave = async (value: UpdateHyperlocalEdges) => {
    updateMutation.reset()

    await updateMutation.mutateAsync(value)
  }
  const resetHyperlocalApiKeyMutation = trpc.organization.resetHyperlocalApiKey.useMutation({
    async onSuccess(input) {
      await context.organization.findMany.invalidate()
      context.organization.findOne.setData({ publicId: input.publicId }, input)
    },
    onError: (error) => {
      toast({ title: "Failed to reset secret!", description: error.message })
    },
  })
  const onResetHyperlocalApiKey = async () => {
    resetHyperlocalApiKeyMutation.reset()
    await resetHyperlocalApiKeyMutation.mutateAsync({ publicId: organization.publicId })
  }

  const isSuperUser = checkIfSuperUser({ ability })
  const organizationUnderTrial = !isSuperUser && organization?.billingStatus !== OrganizationBillingStatus.Billable
  const canEdit =
    ability.can(Action.Update, hyperlocalEdgeSubjectInOrganization(organization.publicId)) && !organizationUnderTrial

  return (
    <OrganizationEditHyperlocal
      organization={organization}
      ability={ability}
      edges={data?.items || []}
      canEdit={canEdit}
      trial={organizationUnderTrial}
      onSave={onSave}
      onResetHyperlocalApiKey={onResetHyperlocalApiKey}
    />
  )
}
