import { Router } from "@tanstack/react-router"
import { analyticsRoutes } from "./routes/analytics/analytics"
import { apiKeyIndexRoute } from "./routes/api-key"
import { apiKeyEditRoute } from "./routes/api-key/api-key-edit"
import { apiKeyListRoute } from "./routes/api-key/api-key-list"
import {
  indexRoute as authIndexRoute,
  authRoute,
  forgotPasswordRoute,
  loginRoute,
  registerRoute,
} from "./routes/auth/auth"
import { channelIndexRoute } from "./routes/channel"
import { channelGroupIndexRoute } from "./routes/channel-group"
import { channelGroupCreateRoute } from "./routes/channel-group/channel-group-create"
import { channelGroupEditRoute } from "./routes/channel-group/channel-group-edit"
import { channelGroupListRoute } from "./routes/channel-group/channel-group-list"
import { channelCreateRoute } from "./routes/channel/channel-create"
import {
  channelEditBroadcastRoute,
  channelEditDebugRoute,
  channelEditDetailsGeneralRoute,
  channelEditDetailsIndexRoute,
  channelEditDetailsOverridesRoute,
  channelEditDetailsRecordingRoute,
  channelEditDetailsRestreamingRoute,
  channelEditDetailsRoute,
  channelEditDetailsSiteAccessRoute,
  channelEditDetailsSourceFeedRoute,
  channelEditDetailsTranscodingRoute,
  channelEditDetailsViewerAccessRoute,
  channelEditPlayoutRoute,
  channelEditRoute,
  channelEditSessionRoute,
} from "./routes/channel/channel-edit"
import { channelListRoute } from "./routes/channel/channel-list"
import { dashboardRoute } from "./routes/dashboard/dashboard"
import { edgeServerIndexRoute } from "./routes/edge-server"
import { edgeServerListRoute } from "./routes/edge-server/edge-server-list"
import { indexRoute } from "./routes/index"
import { ingestPointIndexRoute } from "./routes/ingest-point"
import {
  ingestPointDatacenterRoute,
  ingestPointListRoute,
  ingestPointLoadBalancerRoute,
  ingestPointRegionalRoute,
} from "./routes/ingest-point/ingest-point-list"
import { invitationRoute } from "./routes/invitation"
import { noNavRoute } from "./routes/no-nav"
import { notFoundRoute } from "./routes/not-found"
import { onboardedRoute } from "./routes/onboarded"
import { organizationIndexRoute } from "./routes/organization"
import { organizationSettingsRoutes } from "./routes/organization-settings"
import { organizationCreateRoute } from "./routes/organization/organization-create"
import { organizationEditRoutes } from "./routes/organization/organization-edit"
import { organizationListRoute } from "./routes/organization/organization-list"
import { packagerServerIndexRoute } from "./routes/packager-server"
import { packagerServerListRoute } from "./routes/packager-server/packager-server-list"
import { profileRoutes } from "./routes/profile/profile"
import { regionIndexRoute } from "./routes/region"
import { regionCreateRoute } from "./routes/region/region-create"
import { regionEditRoute } from "./routes/region/region-edit"
import { regionListRoute } from "./routes/region/region-list"
import { registerOrganizationRoute } from "./routes/register-organization"
import { rootRoute } from "./routes/root"
import { siteIndexRoute } from "./routes/site"
import { siteCreateRoute } from "./routes/site/site-create"
import {
  siteEditChannelRulesRoute,
  siteEditGeneralRoute,
  siteEditGeographicRulesRoute,
  siteEditIndexRoute,
  siteEditOrganizationRulesRoute,
  siteEditRoute,
} from "./routes/site/site-edit"
import { siteListRoute } from "./routes/site/site-list"
import { teamIndexRoute } from "./routes/team"
import { teamListRoute } from "./routes/team/team-list"
import { transcodingProfileIndexRoute } from "./routes/transcoding-profile"
import { transcodingProfileCreateRoute } from "./routes/transcoding-profile/transcoding-profile-create"
import { transcodingProfileEditRoute } from "./routes/transcoding-profile/transcoding-profile-edit"
import { transcodingProfileListRoute } from "./routes/transcoding-profile/transcoding-profile-list"
import { usageIndexRoute } from "./routes/usage"
import { usageListRoute } from "./routes/usage/usage-list"
import { userIndexRoute } from "./routes/user"
import { userCreateRoute } from "./routes/user/user-create"
import { userEditRoute } from "./routes/user/user-edit"
import { userListRoute } from "./routes/user/user-list"
import { PageError } from "./templates/PageError"
import { trpc } from "./trpc"

const routeTree = rootRoute.addChildren([
  authRoute.addChildren([authIndexRoute, registerRoute, loginRoute, forgotPasswordRoute]),
  notFoundRoute,
  onboardedRoute.addChildren([
    indexRoute,
    dashboardRoute,
    analyticsRoutes,
    profileRoutes,
    siteIndexRoute.addChildren([
      siteListRoute,
      siteEditRoute.addChildren([
        siteEditIndexRoute,
        siteEditGeneralRoute,
        siteEditGeographicRulesRoute,
        siteEditChannelRulesRoute,
        siteEditOrganizationRulesRoute,
      ]),
      siteCreateRoute,
    ]),
    teamIndexRoute.addChildren([teamListRoute]),
    usageIndexRoute.addChildren([usageListRoute]),
    regionIndexRoute.addChildren([regionListRoute, regionEditRoute, regionCreateRoute]),
    apiKeyIndexRoute.addChildren([apiKeyListRoute, apiKeyEditRoute]),
    channelIndexRoute.addChildren([
      channelListRoute,
      channelEditRoute.addChildren([
        channelEditDetailsRoute.addChildren([
          channelEditDetailsIndexRoute,
          channelEditDetailsGeneralRoute,
          channelEditDetailsTranscodingRoute,
          channelEditDetailsRestreamingRoute,
          channelEditDetailsSourceFeedRoute,
          channelEditDetailsSiteAccessRoute,
          channelEditDetailsViewerAccessRoute,
          channelEditDetailsOverridesRoute,
          channelEditDetailsRecordingRoute,
        ]),
        channelEditSessionRoute,
        channelEditBroadcastRoute,
        channelEditPlayoutRoute,
        channelEditDebugRoute,
      ]),
      channelCreateRoute,
    ]),
    edgeServerIndexRoute.addChildren([edgeServerListRoute]),
    ingestPointIndexRoute.addChildren([
      ingestPointListRoute.addChildren([
        ingestPointRegionalRoute,
        ingestPointDatacenterRoute,
        ingestPointLoadBalancerRoute,
      ]),
    ]),
    packagerServerIndexRoute.addChildren([packagerServerListRoute]),
    transcodingProfileIndexRoute.addChildren([
      transcodingProfileListRoute,
      transcodingProfileCreateRoute,
      transcodingProfileEditRoute,
    ]),
    channelGroupIndexRoute.addChildren([channelGroupListRoute, channelGroupEditRoute, channelGroupCreateRoute]),
    organizationIndexRoute.addChildren([organizationListRoute, organizationEditRoutes, organizationCreateRoute]),
    userIndexRoute.addChildren([userListRoute, userEditRoute, userCreateRoute]),
    organizationSettingsRoutes,
  ]),
  noNavRoute.addChildren([registerOrganizationRoute, invitationRoute]),
])

export const router = new Router({
  routeTree,
  defaultErrorComponent: PageError,
  // eslint-disable-next-line total-functions/no-unsafe-type-assertion
  context: { trpcContext: {} as ReturnType<typeof trpc["useContext"]> },
})

declare module "@tanstack/router" {
  interface Register {
    router: typeof router
  }

  interface RouterContext {
    trpcContext: ReturnType<typeof trpc["useContext"]>
  }
}
