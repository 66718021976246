import { Expose } from "class-transformer"

export class SearchResultDTO {
  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(entity: { constructor: Function }) {
    this.type = entity.constructor.name
  }

  @Expose()
  readonly type!: string

  @Expose()
  entityId!: string

  @Expose()
  title!: string
}
