import { CreateSite } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import { Divider, FormControlLabel, Switch, TextField } from "@vindral/components"
import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { FormSection } from "../../../atoms/FormSection"
import { Page, PageContainer, PageContent, PageHeader } from "../../../atoms/Page"
import { SaveButton } from "../../../atoms/SaveButton"
import { SaveSection } from "../../../atoms/SaveSection"
import { useRegionSelect } from "../../../hooks/useRegionSelect"
import { Breadcrumbs } from "../../../molecules/Breadcrumbs"
import { SingleSelectCombobox } from "../../../molecules/SingleSelectCombobox"
import { floatOrNull, integerOrNull } from "../../../utils/numberOrNull"

const resolver = zodResolver(CreateSite)

export const SiteCreate = ({ createSite }: { createSite: (site: CreateSite) => void }) => {
  const regionSelect = useRegionSelect({})

  const form = useForm<CreateSite>({
    resolver,
    mode: "onChange",
    defaultValues: {
      edgeEgressActive: false,
      edgeOriginActive: false,
      mediaIngressActive: false,
      transcodingActive: false,
      viewerTrafficActive: false,
    },
  })

  const {
    control,
    handleSubmit,
    reset,
    register,
    formState: { isDirty, isValid, isSubmitSuccessful, errors },
  } = form

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful, reset])

  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
          </div>
        </PageHeader>
        <PageContainer>
          <form onSubmit={handleSubmit(createSite)} className="flex flex-col gap-4 py-4">
            <FormSection title="General" description="Change general settings">
              <div className="flex max-w-sm flex-col gap-4">
                <div className="flex flex-col gap-1">
                  <FormControlLabel required error={errors.name?.message}>
                    Name
                  </FormControlLabel>
                  <TextField {...register("name")} />
                </div>

                <div className="flex flex-col gap-1">
                  <FormControlLabel required error={(() => errors.code?.message)()}>
                    Code
                  </FormControlLabel>
                  <TextField {...register("code")} />
                </div>

                <div className="flex flex-col gap-1">
                  <FormControlLabel error={errors.providers?.message}>Provider</FormControlLabel>
                  <TextField {...register("providers")} />
                </div>

                <div className="flex flex-col gap-1">
                  <FormControlLabel required error={errors.regionPublicId?.message}>
                    Region
                  </FormControlLabel>
                  <div>
                    <Controller
                      control={control}
                      name="regionPublicId"
                      render={({ field }) => (
                        <SingleSelectCombobox
                          options={regionSelect.options}
                          selected={regionSelect.selected}
                          search={regionSelect.search}
                          isLoading={regionSelect.query.isLoading}
                          onSearchChange={regionSelect.setSearch}
                          dataPwButton="regionSelector"
                          dataPwValue="regionSelectorValue"
                          placeholder="Choose region"
                          onSelect={(selected) => {
                            regionSelect.setSelected(selected)
                            field.onChange(selected.publicId)
                          }}
                          onReachedEnd={() => {
                            if (!regionSelect.query.hasNextPage) {
                              return
                            }
                            void regionSelect.query.fetchNextPage()
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-1">
                  <FormControlLabel error={errors.notes?.message}>Notes</FormControlLabel>
                  <TextField multiline minRows={4} {...register("notes")} />
                </div>
              </div>
            </FormSection>
            <Divider />
            <FormSection title="Location" description="Specify site location for geo load balancing purposes">
              <div className="flex max-w-sm flex-col gap-4">
                <div className="flex flex-col gap-1">
                  <FormControlLabel required error={errors.latitude?.message}>
                    Latitude
                  </FormControlLabel>
                  <TextField placeholder="63.853470" {...register("latitude", { setValueAs: floatOrNull })} />
                </div>
                <div className="flex flex-col gap-1">
                  <FormControlLabel required error={errors.longitude?.message}>
                    Longitude
                  </FormControlLabel>
                  <TextField placeholder="20.144732" {...register("longitude", { setValueAs: floatOrNull })} />
                </div>
              </div>
            </FormSection>
            <Divider />
            <FormSection title="Limitations" description="Site specific limitations">
              <div className="flex max-w-sm flex-col gap-4 pb-4">
                <div className="flex flex-col gap-1">
                  <FormControlLabel error={errors.maxCapacity?.message}>Maximum Capacity (mbit/s)</FormControlLabel>
                  <TextField placeholder="unlimited" {...register("maxCapacity", { setValueAs: integerOrNull })} />
                </div>

                <div className="font-medium">Ingress</div>
                <Controller
                  control={control}
                  name="mediaIngressActive"
                  render={({ field }) => (
                    <Switch checked={field.value} onCheckedChange={field.onChange} label="Media Ingress" />
                  )}
                />
                <Controller
                  control={control}
                  name="viewerTrafficActive"
                  render={({ field }) => (
                    <Switch checked={field.value} onCheckedChange={field.onChange} label="Load Balancer" />
                  )}
                />

                <div className="font-medium">Transcoding</div>
                <Controller
                  control={control}
                  name="transcodingActive"
                  render={({ field }) => (
                    <Switch checked={field.value} onCheckedChange={field.onChange} label="Transcoding" />
                  )}
                />

                <div className="font-medium">Edge</div>
                <Controller
                  control={control}
                  name="edgeOriginActive"
                  render={({ field }) => (
                    <Switch checked={field.value} onCheckedChange={field.onChange} label="Edge Origin" />
                  )}
                />
                <Controller
                  control={control}
                  name="edgeEgressActive"
                  render={({ field }) => (
                    <Switch checked={field.value} onCheckedChange={field.onChange} label="Edge Egress" />
                  )}
                />
              </div>
            </FormSection>
            <SaveSection>
              <SaveButton text="Create" disabled={!isDirty || !isValid} />
            </SaveSection>
          </form>
        </PageContainer>
      </PageContent>
    </Page>
  )
}
