import { Button } from "@vindral/components"
import { FormSection } from "../../../../atoms/FormSection"
import { playGridUrlChannels, playUrl } from "../../../../utils/urls"
import { ChannelFindById } from "../../ChannelEdit"

export const PlayerLinks = (props: { channel: ChannelFindById }) => {
  const { channel } = props

  return (
    <>
      <h1 className="mb-1 text-xl font-medium">Player Links</h1>
      <FormSection
        title="QoS Player"
        docsLink="https://docs.vindral.com/playout/qos-client"
        description={<p>The Vindral Quality of Service Client (QoS Client) is a tool for viewing Vindral channels.</p>}
      >
        <div className="flex flex-col gap-4">
          <div className="text-xs font-medium uppercase tracking-wider text-fg-subtle">QoS Player</div>
          <a
            href={playUrl({
              channelId: channel.channelId,
              authToken: channel.authToken,
            })}
            target="_blank"
          >
            <Button size="small">Open QoS Player</Button>
          </a>
        </div>
      </FormSection>
      <FormSection
        title="Grid Player"
        docsLink="https://docs.vindral.com/playout/grid-view-player"
        description={<p>The Vindral Grid View is a tool for viewing multiple Vindral channels simultaneously.</p>}
      >
        <div className="flex flex-col gap-4">
          <div className="text-xs font-medium uppercase tracking-wider text-fg-subtle">Grid Player</div>
          <a
            href={playGridUrlChannels({
              channelIds: [channel.channelId],
              authToken: channel.authToken,
            })}
            target="_blank"
          >
            <Button size="small">Open Grid Player</Button>
          </a>
        </div>
      </FormSection>
    </>
  )
}
