import { zodResolver } from "@hookform/resolvers/zod"
import { Button, FormControl, FormControlLabel, TextField } from "@vindral/components"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import Form from "../../organisms/Form"
import { ReCaptchaText } from "../../organisms/ReCaptchaText"

const resolver = zodResolver(z.object({ email: z.string().email({ message: "Email Address must be valid" }) }))

export interface RequestResetPasswordProps {
  requestResetPassword: (value: { email: string }) => Promise<void>
}

export const RequestResetPassword = (props: RequestResetPasswordProps) => {
  const { requestResetPassword } = props
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid, isSubmitSuccessful },
  } = useForm<{ email: string }>({
    resolver,
    mode: "onChange",
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ email: "" })
    }
  }, [isSubmitSuccessful, reset])

  return (
    <Form onSubmit={handleSubmit(requestResetPassword)} className="flex max-w-md flex-col gap-4 py-4">
      <FormControl>
        <FormControlLabel error={errors.email?.message} required>
          E-mail
        </FormControlLabel>
        <TextField {...register("email")} placeholder="email@domain.com" autoComplete="email" data-pw="email" />
        <ReCaptchaText />
      </FormControl>
      <div>
        <Button disabled={!isDirty || !isValid} type="submit" variant="primary">
          Send reset link
        </Button>
      </div>
    </Form>
  )
}
