import { SiteFindManyParameters } from "@core-services/portal"
import { Route, useNavigate, useSearch } from "@tanstack/react-router"
import { PaginationState, SortingState, VisibilityState } from "@tanstack/react-table"
import { useToast } from "@vindral/components"
import { useEffect, useMemo, useState } from "react"
import { useAsyncFn, useLocalStorage } from "react-use"
import { z } from "zod"
import { siteIndexRoute } from "."
import { WorldMapPin } from "../../organisms/map/WorldMap"
import { SiteList } from "../../templates/infrastructure/site/SiteList"
import { trpc } from "../../trpc"

const defaultColumns = {
  name: true,
  code: true,
  provider: true,
  "region.name": true,
  latitude: true,
  longitude: true,
  limited: true,
  mediaIngress: true,
  viewerTrafficActive: true,
  transcoding: true,
  edgeOrigin: true,
  edgeEdgess: true,
}

const params = z.object({
  siteListView: z
    .object({
      sorting: z.array(
        z.object({
          id: SiteFindManyParameters.shape.orderBy,
          desc: z.boolean(),
        })
      ),
      pagination: z.object({
        pageIndex: z.number().nonnegative(),
        pageSize: z.number().nonnegative(),
      }),
      search: z.string().default(""),
    })
    .catch({
      sorting: [{ desc: false, id: "name" }],
      pagination: { pageIndex: 0, pageSize: 100 },
      search: "",
    }),
})

type SearchParams = ReturnType<typeof useSearch<typeof siteListRoute["id"]>>

function toTrpcParams(params: SearchParams) {
  const { pagination, sorting, search } = params.siteListView

  return {
    cursor: pagination.pageIndex * pagination.pageSize,
    take: pagination.pageSize,
    orderBy: sorting[0]?.id ?? "name",
    order: sorting[0]?.desc ? "desc" : "asc",
    search: search.length > 0 ? search : undefined,
  } as const
}

export const siteListRoute = new Route({
  path: "/",
  getParentRoute: () => siteIndexRoute,
  validateSearch: params,
  onLoad: async ({ search, context: { trpcContext } }) => {
    const params = toTrpcParams(search)
    await trpcContext.site.findMany.ensureData(params, { staleTime: 1000 })
  },
  preSearchFilters: [(search) => search],
  component: List,
})

function List() {
  const searchParams = useSearch({ from: siteListRoute.id })
  const navigate = useNavigate({ from: siteListRoute.id })
  const trpcParams = toTrpcParams(searchParams)
  const { toast } = useToast()

  const { data } = trpc.site.findMany.useQuery(trpcParams, { keepPreviousData: true, staleTime: 1000 })
  const [sorting, setSorting] = useState<SortingState>(searchParams.siteListView.sorting)
  const [pagination, setPagination] = useState<PaginationState>(searchParams.siteListView.pagination)
  const [globalFilter, setGlobalFilter] = useState(searchParams.siteListView.search)
  const [value, setValue, remove] = useLocalStorage<VisibilityState>("site-list-columns", defaultColumns)
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(value ?? defaultColumns)
  const pageCount = Math.ceil((data?.total ?? 0) / pagination.pageSize)
  const [navigationState, doNavigate] = useAsyncFn(
    async (...args: Parameters<typeof navigate>) => {
      return navigate(...args)
    },
    [navigate]
  )

  if (globalFilter !== searchParams.siteListView.search && pagination.pageIndex !== 0) {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }))
  }

  useEffect(() => {
    void doNavigate({
      from: siteListRoute.id,
      to: "/sites",
      params: {},
      search: (prev) => {
        return { ...prev, siteListView: { sorting, pagination, search: globalFilter } }
      },
    })
  }, [doNavigate, sorting, pagination, globalFilter])

  const { data: ping } = trpc.infrastructure.ping.useQuery()
  const { data: sites } = trpc.site.findAllWithActiveInfo.useQuery()
  const [mapPins, setMapPins] = useState<WorldMapPin[]>([])

  useMemo(() => {
    if (sites && ping) {
      const pins = sites
        .sort((a, b) => a.code.localeCompare(b.code))
        .map((site) => {
          const someServerInSitePingResult = ping.result.find((p) => p.node.includes(site.code))
          const rows = someServerInSitePingResult?.roundTripTimesToNodes.map((r) => {
            const node = `${r.node}:`.padEnd(18, " ")
            const rtt = `${r.rtt?.toFixed(3) || "N/A "}ms`
            return <div key={node} className="whitespace-pre font-mono text-fg-subtle">{`${node} ${rtt}`}</div>
          })

          // Random offset for long/lat so we can sites that are close to each other
          const rnd = Math.random() * 1.6 + 1

          return {
            value: 1,
            name: site.code,
            lng: site.longitude + rnd,
            lat: site.latitude + rnd / 2,
            tooltip: (
              <div>
                <div className="font-medium">{site.code} rtt </div>
                {rows}
              </div>
            ),
          }
        })
      setMapPins(pins)
    }
  }, [sites, ping])

  return (
    <SiteList
      mapPins={mapPins}
      data={data?.items ?? []}
      pageCount={pageCount}
      manualPagination
      manualSorting
      manualFiltering
      isStaleData={navigationState.loading || globalFilter !== searchParams.siteListView.search}
      state={{ sorting, pagination, globalFilter, columnVisibility }}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      onGlobalFilterChange={setGlobalFilter}
      onColumnVisibilityChange={setColumnVisibility}
      saveColumnVisibility={() => {
        setValue(columnVisibility)
        toast({ title: "Saved configuration" })
      }}
      resetColumnVisibilityToDefault={() => {
        remove()
        setColumnVisibility(defaultColumns)
        toast({ title: "Restored to default" })
      }}
    />
  )
}
