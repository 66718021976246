import { ApiKeyScope } from "@core-services/data-types"
import { UpdateApiKey } from "@core-services/portal"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Button,
  ConfirmDialogContent,
  Dialog,
  DialogTrigger,
  FormControlLabel,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  TextField,
} from "@vindral/components"
import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { FormSection } from "../../atoms/FormSection"
import { Page, PageContainer, PageContent, PageFooter, PageHeader } from "../../atoms/Page"
import { SaveButton } from "../../atoms/SaveButton"
import { SaveSection } from "../../atoms/SaveSection"
import { Breadcrumbs } from "../../molecules/Breadcrumbs"
import { FormattedDate } from "../../molecules/FormattedDate"
import { Titled } from "../../molecules/Titled"
import { RouterOutputs } from "../../trpc"

type ApiKey = NonNullable<RouterOutputs["apiKey"]["findById"]>
const resolver = zodResolver(UpdateApiKey)

export interface EditApiKeyProps {
  apiKey: ApiKey
  updateApiKey: (apiKey: UpdateApiKey) => Promise<void>
  deleteApiKey: () => Promise<void>
  error?: { message?: string; name?: string } | null
}

export const ApiKeyEdit = ({ apiKey, updateApiKey, deleteApiKey }: EditApiKeyProps) => {
  const form = useForm<UpdateApiKey>({
    resolver,
    mode: "onChange",
    defaultValues: {
      ...apiKey,
    },
  })
  const {
    control,
    reset,
    handleSubmit,
    register,
    formState: { isDirty, isValid, isSubmitSuccessful, errors },
  } = form

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful, reset])

  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
          </div>
        </PageHeader>
        <PageContainer>
          <form onSubmit={handleSubmit(updateApiKey)} className="flex flex-col gap-8 pt-4">
            <FormSection title="General" description="Change general settings">
              <div className="flex max-w-sm flex-col gap-4 ">
                <div className="flex flex-col gap-1">
                  <FormControlLabel required error={errors.name?.message}>
                    Name
                  </FormControlLabel>
                  <TextField {...register("name")} />
                </div>

                <div className="flex flex-col gap-1">
                  <FormControlLabel error={errors.notes?.message}>Notes</FormControlLabel>
                  <TextField multiline minRows={4} {...register("notes")} />
                </div>

                <div className="flex flex-col gap-1">
                  <FormControlLabel required>Key</FormControlLabel>
                  {apiKey.maskedKey}
                </div>
              </div>
            </FormSection>

            <FormSection title="Scope" description="Set the scope of the API key">
              <div className="flex flex-col gap-1 ">
                <FormControlLabel required error={errors.scope?.message}>
                  Scope
                </FormControlLabel>
                <div>
                  <Controller
                    control={control}
                    name="scope"
                    render={({ field }) => (
                      <Select value={field.value} onValueChange={field.onChange}>
                        <SelectTrigger data-pw="scopeSelector">
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value={ApiKeyScope.ReadWrite} key={ApiKeyScope.ReadWrite}>
                            Read and Write
                          </SelectItem>
                          <SelectItem value={ApiKeyScope.Read} key={ApiKeyScope.Read}>
                            Read Only
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    )}
                  />
                </div>
              </div>
            </FormSection>
            <SaveSection>
              <SaveButton text="Save" disabled={!isDirty || !isValid} />
            </SaveSection>
          </form>
        </PageContainer>
      </PageContent>
      <PageFooter>
        <div className="flex h-16 items-center justify-between">
          <div className="flex gap-8">
            <Titled title="Created">
              <FormattedDate date={new Date(apiKey.createdAt)} />
            </Titled>
            {typeof apiKey.updatedAt !== "undefined" && (
              <Titled title="Updated">
                <FormattedDate date={new Date(apiKey.updatedAt)} />
              </Titled>
            )}
          </div>
          <Dialog>
            <DialogTrigger asChild>
              <Button variant="danger" data-pw="deleteButton">
                Delete
              </Button>
            </DialogTrigger>
            <ConfirmDialogContent
              onConfirm={deleteApiKey}
              title={`Confirm deletion of ${apiKey.name}`}
              description={
                <>
                  Please confirm that you are absolutely sure that you want to delete
                  <span className="font-medium"> {apiKey.name}</span>.
                </>
              }
            />
          </Dialog>
        </div>
      </PageFooter>
    </Page>
  )
}
