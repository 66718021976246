import { useAbility } from "@casl/react"
import { Cross2Icon, ExclamationTriangleIcon } from "@radix-ui/react-icons"
import { IconAdjustmentsFilled } from "@tabler/icons-react"
import { Link } from "@tanstack/react-router"
import {
  TableOptions,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import {
  Button,
  Chip,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSwitchItem,
  DropdownMenuTrigger,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from "@vindral/components"
import { ReactNode } from "react"
import { AbilityContext, checkIfSuperUser } from "../acl"
import { OrganizationItem } from "../interfaces/Organization"
import { DebouncedTextField } from "../molecules/DebouncedTextField"
import { FormattedDate } from "../molecules/FormattedDate"
import { isOrganizationLimited } from "../utils/limited"
import { organizationTypeMapping } from "../utils/organizationTypeMapping"
import { InteractiveTable } from "./InteractiveTable"
import { NoResultsFound } from "./NoResultsFound"

function columnNames(id: string) {
  switch (id) {
    case "name":
      return "Name"
    case "type":
      return "Type"
    case "billingStatus":
      return "Billing Status"
    case "siteLimitation":
      return "Site Limitation"
    case "suspended":
      return "Suspended"
    case "createdAt":
      return "Created At"
  }

  return id
}

function OrganizationLink({ publicId, children }: { publicId: string; children: ReactNode }) {
  return (
    <Link to="/organizations/edit/$publicId" className="whitespace-nowrap font-semibold" params={{ publicId }}>
      {children}
    </Link>
  )
}

const LimitationsCell = (props: OrganizationItem) => {
  if (!isOrganizationLimited(props)) {
    return <></>
  }

  const Limitations = () => (
    <>
      <span style={{ textTransform: "capitalize" }}>{props.siteLimitation?.mode}</span> sites:{" "}
      {props.siteLimitation?.limited.join(", ")}
    </>
  )

  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger
          style={{
            height: `100%`,
            width: `100%`,
            display: "block",
          }}
        >
          <Chip color="orange">
            <ExclamationTriangleIcon />
          </Chip>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent side="top">
            <Limitations />
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  )
}

export type UseOrganizationReactTableProps = Omit<
  TableOptions<OrganizationItem>,
  "columns" | "getCoreRowModel" | "getSortedRowModel" | "getPaginationRowModel"
>

export function useOrganizationReactTable(options: UseOrganizationReactTableProps) {
  const ability = useAbility(AbilityContext)
  const isSuperUser = checkIfSuperUser({ ability })

  const columnHelper = createColumnHelper<Omit<OrganizationItem, "packagerConfiguration">>()

  const defaultColumns = [
    columnHelper.accessor("name", {
      header: () => "Name",
      cell: (info) => <OrganizationLink publicId={info.row.original.publicId}>{info.getValue()}</OrganizationLink>,
      size: 280,
      maxSize: 280,
    }),
    columnHelper.accessor("suspended", {
      header: () => "Suspended",
      cell: (info) =>
        info.row.original.suspended && (
          <Chip color="red">
            <Cross2Icon style={{ display: "inline-block" }} /> Suspended
          </Chip>
        ),
    }),
  ]

  const superUserColumns = [
    columnHelper.accessor("siteLimitation", {
      header: () => "Site Limitation",
      cell: (info) => <LimitationsCell {...info.row.original} />,
      enableSorting: false,
    }),
    columnHelper.accessor("type", {
      header: () => "Type",
      cell: (info) => (info.row.original.type ? organizationTypeMapping[info.row.original.type] : ""),
    }),
    columnHelper.accessor("billingStatus", {
      header: () => "Billing Status",
      cell: (info) => <span style={{ textTransform: "capitalize" }}>{info.getValue()}</span>,
    }),
  ]

  const createdAtColumn = columnHelper.accessor("createdAt", {
    header: () => "Created At",
    meta: { style: { textAlign: "right" } },
    cell: (info) => (
      <span className="whitespace-nowrap text-fg-subtle">
        <FormattedDate date={new Date(info.getValue())} />
      </span>
    ),
    size: 150,
    maxSize: 150,
  })

  const columns = isSuperUser
    ? [...defaultColumns, ...superUserColumns, createdAtColumn]
    : [...defaultColumns, createdAtColumn]

  return useReactTable({
    ...options,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowId: (row) => row.publicId,
  })
}

export interface OrganizationTableProps {
  saveColumnVisibility: () => void
  resetColumnVisibilityToDefault: () => void
  table: ReturnType<typeof useOrganizationReactTable>
}

export function OrganizationTable(options: OrganizationTableProps) {
  const { table } = options

  // If there is a filter AND no rows - that means we should show "no results".
  // This is different from zero rows and no filter - in that case we should show
  // a call to action.
  const noResults = table.getRowModel().rows.length === 0 && !!table.getState().globalFilter

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-end gap-2">
        <div>
          <DebouncedTextField
            // eslint-disable-next-line total-functions/no-unsafe-type-assertion
            initialValue={table.getState().globalFilter as string}
            onValueChange={table.setGlobalFilter}
            debounceMs={200}
            placeholder="Filter..."
          />
        </div>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button>
              <IconAdjustmentsFilled size={16} />
              Configure
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent sideOffset={4} align="start">
            {table.getAllLeafColumns().map((column) => {
              return (
                <DropdownMenuSwitchItem
                  key={column.id}
                  checked={column.getIsVisible()}
                  onSelect={() => column.toggleVisibility()}
                >
                  {columnNames(column.id)}
                </DropdownMenuSwitchItem>
              )
            })}
            <DropdownMenuSeparator />
            <DropdownMenuItem onSelect={() => options.saveColumnVisibility()}>Save</DropdownMenuItem>
            <DropdownMenuItem onSelect={() => options.resetColumnVisibilityToDefault()}>
              Reset to default
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      {noResults ? <NoResultsFound /> : <InteractiveTable table={table} />}
    </div>
  )
}
