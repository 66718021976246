import * as DialogPrimitive from "@radix-ui/react-dialog"

import { ComponentProps, ElementRef, forwardRef, ReactNode } from "react"

const StyledOverlay = forwardRef<
  ElementRef<typeof DialogPrimitive.Overlay>,
  ComponentProps<typeof DialogPrimitive.Overlay>
>(({ ...rest }, ref) => {
  return (
    <DialogPrimitive.Overlay
      ref={ref}
      {...rest}
      className={
        "fixed inset-0 z-50 bg-black/50 animate-in fade-in data-[state=closed]:animate-out data-[state=closed]:fade-out"
      }
    />
  )
})
StyledOverlay.displayName = "StyledOverlay"

export type ModalContentProps = ComponentProps<typeof DialogPrimitive.Content>
export type ModalContentRef = ElementRef<typeof DialogPrimitive.Content>

const StyledContent = forwardRef<ModalContentRef, ModalContentProps>(({ ...rest }, ref) => {
  return (
    <div className="fixed inset-x-4 inset-y-[20%] z-50 flex justify-center">
      <DialogPrimitive.Content
        ref={ref}
        {...rest}
        className="flex max-h-full max-w-fit origin-top flex-col justify-self-center outline-none ease-out animate-in fade-in zoom-in-95 data-[state=closed]:ease-in data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=closed]:zoom-out-95"
      />
    </div>
  )
})
StyledContent.displayName = "StyledContent"

interface ContentProps {
  children?: ReactNode | undefined
}

function Content({ children, ...props }: ContentProps & ComponentProps<typeof StyledContent>) {
  return (
    <DialogPrimitive.Portal>
      <StyledOverlay />
      <StyledContent {...props}>{children}</StyledContent>
    </DialogPrimitive.Portal>
  )
}

const StyledDescription = forwardRef<
  ElementRef<typeof DialogPrimitive.Description>,
  ComponentProps<typeof DialogPrimitive.Description>
>(({ ...rest }, ref) => {
  return <DialogPrimitive.Description ref={ref} {...rest} />
})
StyledDescription.displayName = "StyledDescription"

export const Modal = DialogPrimitive.Root
export const ModalTrigger = DialogPrimitive.Trigger
export const ModalContent = Content
export const ModalClose = DialogPrimitive.Close
