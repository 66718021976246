import { useAbility } from "@casl/react"
import { Navigate, Route, useNavigate, useParams } from "@tanstack/react-router"
import { toast } from "@vindral/components"
import { z } from "zod"
import { organizationIndexRoute } from "."
import { AbilityContext, checkIfSuperUser } from "../../acl"
import { OrganizationEdit } from "../../templates/organization/OrganizationEdit"
import { trpc } from "../../trpc"
import { getChildRoutes } from "./organization-edit.common"

const Params = z.object({ publicId: z.string() })
const trpcOptions = { staleTime: 1000 }

const organizationEditRoute = new Route({
  getParentRoute: () => organizationIndexRoute,
  preSearchFilters: [(search) => search],
  path: "edit/$publicId",
  parseParams: (params) => Params.parse(params),
  component: EditWrapper,
  onLoad: async ({ preload, context: { trpcContext }, params }) => {
    if (preload) {
      await trpcContext.organization.findOne.prefetch(params, trpcOptions)
    } else {
      await trpcContext.organization.findOne.fetch(params, trpcOptions)
    }
  },
  getContext: ({ context, params }) => {
    const organization = context.trpcContext.organization.findOne.getData({ publicId: params.publicId })

    return {
      pageTitle: organization?.name ?? "Organization",
    }
  },
})

function EditWrapper() {
  const { publicId } = useParams({ from: organizationEditRoute.id })
  const context = trpc.useContext()
  const navigate = useNavigate()
  const { data: organization } = trpc.organization.findOne.useQuery({ publicId }, trpcOptions)
  const ability = useAbility(AbilityContext)

  const deleteMutation = trpc.organization.delete.useMutation({
    async onSuccess() {
      await context.organization.findMany.invalidate()
      await context.organization.findOne.invalidate({ publicId })

      await navigate({ to: "/organizations", replace: true })
      toast({ title: "Deleted successfully!" })
    },
    onError: (error) => {
      toast({ title: "Failed to delete!", description: error.message })
    },
  })

  if (!organization) {
    return null
  }

  const onDelete = async () => {
    await deleteMutation.mutateAsync({ publicId })
  }

  return <OrganizationEdit organization={organization} ability={ability} onDelete={onDelete} />
}

const organizationEditIndexRoute = new Route({
  getParentRoute: () => organizationEditRoute,
  path: "/",
  component: IndexRoutePage,
})

function IndexRoutePage() {
  const { publicId } = useParams({ from: organizationEditIndexRoute.id })
  const ability = useAbility(AbilityContext)
  const isSuperUser = checkIfSuperUser({ ability })

  return isSuperUser ? (
    <Navigate to="/organizations/edit/$publicId/general" params={{ publicId }} replace />
  ) : (
    <Navigate to="/settings/webhooks" replace />
  )
}

const childRoutes = getChildRoutes(organizationEditRoute)
export const organizationEditRoutes = organizationEditRoute.addChildren([organizationEditIndexRoute, ...childRoutes])
