import { Expose } from "class-transformer"
import { IsBoolean, IsOptional } from "class-validator"
import { IsNullable, IsPositiveInteger } from "./common"

export class ClientOverridesDTO {
  @Expose()
  @IsOptional()
  @IsNullable()
  @IsPositiveInteger()
  maxVideoBitRate?: number | null

  @Expose()
  @IsOptional()
  @IsNullable()
  @IsPositiveInteger()
  minBufferTime?: number | null

  @Expose()
  @IsOptional()
  @IsNullable()
  @IsPositiveInteger()
  maxBufferTime?: number | null

  @Expose()
  @IsOptional()
  @IsNullable()
  @IsBoolean()
  burstEnabled?: boolean | null
}
