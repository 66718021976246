import { Navigate, Outlet, useRouter } from "@tanstack/react-router"
import { Page, PageContainer, PageContent, PageHeader } from "../../../atoms/Page"
import { Breadcrumbs } from "../../../molecules/Breadcrumbs"
import { RouteTabs, RouteTrigger } from "../../../molecules/RouteTabs"

export function IngestPoints() {
  const router = useRouter()

  if (router.state.currentLocation.pathname === "/ingest-points") {
    return <Navigate replace to="/ingest-points/datacenter" />
  }

  return (
    <Page>
      <PageContent>
        <PageHeader>
          <div className="flex h-16 items-center justify-between">
            <Breadcrumbs />
          </div>
          <RouteTabs border="none">
            <RouteTrigger to="/ingest-points/datacenter"> Datacenter </RouteTrigger>
            <RouteTrigger to="/ingest-points/regional"> Regional </RouteTrigger>
            <RouteTrigger to="/ingest-points/load-balancer"> Load Balancer </RouteTrigger>
          </RouteTabs>
        </PageHeader>
        <PageContainer>
          <div className="py-4">
            <Outlet />
          </div>
        </PageContainer>
      </PageContent>
    </Page>
  )
}
