import { Route } from "@tanstack/react-router"
import { onboardedRoute } from "../onboarded"

export const channelGroupIndexRoute = new Route({
  getParentRoute: () => onboardedRoute,
  preSearchFilters: [(search) => search],
  path: "channel-groups",
  getContext: () => {
    return {
      pageTitle: "Channel groups",
    }
  },
})
