import { Route } from "@tanstack/react-router"
import { onboardedRoute } from "../onboarded"

export const ingestPointIndexRoute = new Route({
  getParentRoute: () => onboardedRoute,
  preSearchFilters: [(search) => search],
  path: "ingest-points",
  getContext: () => {
    return {
      pageTitle: "Ingest Points",
    }
  },
})
