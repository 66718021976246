import { Expose } from "class-transformer"
import { BaseDTO } from "../base.dto"

export class PackagerJobDTO extends BaseDTO<PackagerJobDTO> {
  @Expose()
  id!: string

  @Expose()
  status?: "running" | "pending" | "dead"
}
