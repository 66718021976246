import { Navigate, Route, useSearch } from "@tanstack/react-router"
import { z } from "zod"
import {
  Analytics,
  AnalyticsDateRange,
  DateRange,
  Distribution,
  Geographic,
  Overview,
  Sessions,
  Traffic,
  allDateRanges,
  getDateRangeSeconds,
} from "../../templates/analytics/"
import { trpc } from "../../trpc"
import { onboardedRoute } from "../onboarded"
const analyticsQueryParams = z.object({
  country: z.string().optional(),
  streamKey: z.string().optional(),
  continent: z.string().optional(),
  channelId: z.string().optional(),
  from: z.number().optional(),
  to: z.number().optional(),
  dateRange: z.enum(allDateRanges).optional(),
})

function parseDateRangeParams(dateRange?: DateRange, from?: number, to?: number): AnalyticsDateRange {
  if (from && to) {
    const fromDate = new Date(from)
    const toDate = new Date(to)
    if (fromDate && toDate) {
      return { type: "absolute", range: { from: fromDate, to: toDate } }
    }
  }

  if (dateRange) {
    return { type: "quick", range: dateRange }
  }

  // This will be the default if no date range param is provided
  return { type: "quick", range: "Last 7 days" }
}

const analyticsRoute = new Route({
  getParentRoute: () => onboardedRoute,
  path: "analytics",
  component: AnalyticsPage,
  validateSearch: analyticsQueryParams,
  preSearchFilters: [(search) => search],
  getContext: () => ({ pageTitle: "Analytics" }),
  onLoad: async ({ context, search: { channelId } }) => {
    if (channelId) {
      await context.trpcContext.channel.findById.prefetch({ channelId })
    }
  },
})
function AnalyticsPage() {
  const { organizationId, country, continent, channelId, dateRange, from, to } = useSearch({ from: analyticsRoute.id })
  const { data: channel } = trpc.channel.findById.useQuery({ channelId: channelId || "" })
  const range = parseDateRangeParams(dateRange, from, to)

  return (
    <Analytics
      defaultChannel={channel}
      organizationId={organizationId}
      country={country}
      continent={continent}
      dateRange={range}
    />
  )
}

const childRoute = (
  path: "/" | "overview" | "sessions" | "traffic" | "geography" | "devices",
  component: () => JSX.Element,
  pageTitle: string
) =>
  new Route({
    getParentRoute: () => analyticsRoute,
    validateSearch: analyticsQueryParams,
    path,
    component,
    preSearchFilters: [(search) => search],
    getContext: () => ({ pageTitle }),
  })

const indexRoute = childRoute("/", IndexRoutePage, "")
const overviewRoute = childRoute("overview", OverviewPage, "Overview")
const sessionsRoute = childRoute("sessions", SessionsPage, "Sessions")
const trafficRoute = childRoute("traffic", TrafficPage, "Traffic")
const geographyRoute = childRoute("geography", GeographyPage, "Geography")
const devicesRoute = childRoute("devices", DevicesPage, "Devices")

function IndexRoutePage() {
  return <Navigate to="/analytics/overview" replace />
}
function OverviewPage() {
  const { country, streamKey, continent, dateRange, from, to, organizationId } = useSearch({ from: overviewRoute.id })
  const time = getDateRangeSeconds(parseDateRangeParams(dateRange, from, to))
  return <Overview {...{ time, country, streamKey, continent, organizationId }} />
}
function SessionsPage() {
  const { country, streamKey, continent, dateRange, from, to, organizationId } = useSearch({ from: sessionsRoute.id })
  const time = getDateRangeSeconds(parseDateRangeParams(dateRange, from, to))
  return <Sessions {...{ time, country, streamKey, continent, organizationId }} />
}
function TrafficPage() {
  const { country, streamKey, continent, dateRange, from, to, organizationId } = useSearch({ from: trafficRoute.id })
  const time = getDateRangeSeconds(parseDateRangeParams(dateRange, from, to))
  return <Traffic {...{ time, country, streamKey, continent, organizationId }} />
}
function GeographyPage() {
  const { country, streamKey, continent, dateRange, from, to, organizationId } = useSearch({ from: geographyRoute.id })
  const time = getDateRangeSeconds(parseDateRangeParams(dateRange, from, to))
  return <Geographic {...{ time, country, streamKey, continent, organizationId }} />
}
function DevicesPage() {
  const { country, streamKey, continent, dateRange, from, to, organizationId } = useSearch({ from: devicesRoute.id })
  const time = getDateRangeSeconds(parseDateRangeParams(dateRange, from, to))
  return <Distribution {...{ time, country, streamKey, continent, organizationId }} />
}

export const analyticsRoutes = analyticsRoute.addChildren([
  indexRoute,
  overviewRoute,
  sessionsRoute,
  trafficRoute,
  geographyRoute,
  devicesRoute,
])
