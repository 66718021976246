import { z } from "zod"
import { Order } from "../order"

export const OrganizationFindManyParameters = z
  .object({
    orderBy: z.enum(["name", "type", "createdAt", "suspended", "billingStatus"]),
    search: z.string().optional(),
  })
  .merge(Order)

export type OrganizationFindManyParameters = z.infer<typeof OrganizationFindManyParameters>
