import { DownloadIcon } from "@radix-ui/react-icons"
import { Button, Spinner } from "@vindral/components"
import { downloadZip } from "client-zip"
import { useState } from "react"
import { trpc } from "../../trpc"
import { AnalyticsQueryArgs } from "./Analytics"
import { mergeSessionsAndSessionTime } from "./helpers"

function timeStampToIsoString(timestamp: number) {
  return new Date(timestamp).toISOString()
}

function rangeCsvFile(fileName: string, headers: string[], points?: [number, number][]) {
  const rows =
    !points || points.length === 0
      ? ["No data"]
      : points.map((point) => `${timeStampToIsoString(point[0])},${point[1]}`)
  rows.unshift(headers.join(","))
  const csv = rows.join("\r\n")
  return generateCsvFile(fileName, csv)
}

function instantsCsvFile(fileName: string, instantValues: { header: string; data?: number }[]) {
  const headerRow = instantValues.map((arg) => arg.header).join(",")
  const dataRow = instantValues.map((arg) => arg.data?.toString() || "No data").join(",")
  const csv = [headerRow, dataRow].join("\r\n")
  return generateCsvFile(fileName, csv)
}

function objectListCsvFile(fileName: string, headers: string[], data?: object[]) {
  if (!data?.[0]) {
    return generateCsvFile(fileName, "No data")
  }
  const rows = data.map((obj) => {
    const values = Object.values(obj)
    return values.join(",")
  })
  rows.unshift(headers.join(","))
  const csv = rows.join("\r\n")
  return generateCsvFile(fileName, csv)
}

function devicesCsvFile(fileName: string, header: string, data?: { key: string; value: number }[]) {
  if (!data?.[0]) {
    return generateCsvFile(fileName, [header, "No data"].join("\r\n"))
  }

  const total = data.reduce((a, b) => a + b.value, 0)
  const rows = data.map((item) => {
    return `${item.key},${((item.value / total) * 100).toFixed(1)}%`
  })
  rows.unshift(`${header},Percentage`)
  const csv = rows.join("\r\n")
  return generateCsvFile(fileName, csv)
}

function generateCsvFile(name: string, content: string) {
  const blob = new Blob(["sep=,\n" + content], { type: "text/csv" })
  return new File([blob], name, { type: "text/csv" })
}

function generateArgsFile(args: AnalyticsQueryArgs) {
  const keyMapping: Record<keyof AnalyticsQueryArgs, string> = {
    time: "Time",
    country: "Country",
    streamKey: "Stream key",
    continent: "Region",
    organizationId: "Organization ID",
  }

  const rows = Object.entries(args).map(([key, value]) => {
    // eslint-disable-next-line total-functions/no-unsafe-type-assertion
    const name = keyMapping[key as keyof AnalyticsQueryArgs]

    if (!name) {
      return
    }

    if (typeof value === "object") {
      return `${name}: ${timeStampToIsoString(value.from * 1000)} - ${timeStampToIsoString(value.to * 1000)}`
    }
    return `${name}: ${value || "All"}`
  })
  const content = rows.filter((row) => !!row).join("\r\n")
  const blob = new Blob([content], { type: "text/plain" })
  return new File([blob], "export-arguments.txt", { type: "text/plain" })
}

export const ExportButton = (props: AnalyticsQueryArgs) => {
  const [isLoading, setIsLoading] = useState(false)

  const { time, organizationId } = props
  const lookback = time.to - time.from
  const instantArgs = { ...props, time: time.to, lookback }

  // Queries
  // Range values
  const { refetch: refetchViewers } = trpc.analytics.viewersRange.useQuery(props, {
    refetchOnWindowFocus: false,
    enabled: false,
  })

  const { refetch: refetchEgress } = trpc.analytics.egressRange.useQuery(props, {
    refetchOnWindowFocus: false,
    enabled: false,
  })

  const { refetch: refetchIngress } = trpc.analytics.ingressBitrateRange.useQuery(props, {
    refetchOnWindowFocus: false,
    enabled: false,
  })

  // Instant values from overview
  const { refetch: refetchViewerSessions } = trpc.analytics.viewerSessions.useQuery(instantArgs, {
    refetchOnWindowFocus: false,
    enabled: false,
  })

  const { refetch: refetchViewerBitrate } = trpc.analytics.viewerBitrateAverage.useQuery(instantArgs, {
    refetchOnWindowFocus: false,
    enabled: false,
  })

  const { refetch: refetchTTFF } = trpc.analytics.timeToFirstFrameMedian.useQuery(props, {
    refetchOnWindowFocus: false,
    enabled: false,
  })

  const { refetch: refetchRTT } = trpc.analytics.roundTripTimeMedian.useQuery(props, {
    refetchOnWindowFocus: false,
    enabled: false,
  })

  // Instant values from session & viewer time
  const { refetch: refetchViewerSessionsTotalTime } = trpc.analytics.viewerSessionsTotalTime.useQuery(instantArgs, {
    refetchOnWindowFocus: false,
    enabled: false,
  })

  const { refetch: refetchViewerSessionsAvgDuration } = trpc.analytics.viewerSessionsAvgDuration.useQuery(instantArgs, {
    refetchOnWindowFocus: false,
    enabled: false,
  })

  // Table values from session & viewer time
  const { refetch: refetchViewerSessionsByChannel } = trpc.analytics.viewerSessionsByChannel.useQuery(instantArgs, {
    refetchOnWindowFocus: false,
    enabled: false,
  })
  const { refetch: refetchViewerSessionsTotalTimeByChannel } = trpc.analytics.viewerSessionsTotalTimeByChannel.useQuery(
    instantArgs,
    { refetchOnWindowFocus: false, enabled: false }
  )

  const channelFilter = organizationId ? { organization: { publicId: organizationId } } : undefined
  const { refetch: refetchChannels } = trpc.channel.findMany.useQuery(
    { orderBy: "name", search: props.streamKey, filter: channelFilter },
    { refetchOnWindowFocus: false, enabled: false }
  )

  // Instant values from traffic
  const { refetch: refetchTotalEgressBytes } = trpc.analytics.totalEgressBytes.useQuery(instantArgs, {
    refetchOnWindowFocus: false,
    enabled: false,
  })

  const { refetch: refetchTotalIngressBytes } = trpc.analytics.totalIngressBytes.useQuery(instantArgs, {
    refetchOnWindowFocus: false,
    enabled: false,
  })

  // Geography
  const { refetch: refetchViewerSessionsByCountry } = trpc.analytics.viewerSessionsByCountry.useQuery(instantArgs, {
    refetchOnWindowFocus: false,
    enabled: false,
  })

  // Devices
  const { refetch: refetchBrowsers } = trpc.analytics.browsers.useQuery(props, {
    refetchOnWindowFocus: false,
    enabled: false,
  })

  const { refetch: refetchOS } = trpc.analytics.os.useQuery(props, {
    refetchOnWindowFocus: false,
    enabled: false,
  })

  const { refetch: refetchDevices } = trpc.analytics.devices.useQuery(props, {
    refetchOnWindowFocus: false,
    enabled: false,
  })

  const downloadQueryData = async () => {
    setIsLoading(true)
    const [
      viewers,
      egress,
      ingress,
      viewerSessions,
      viewerBitrate,
      ttff,
      rtt,
      viewerSessionsTotalTime,
      viewerSessionsAvgDuration,
      viewerSessionsByChannel,
      viewerSessionsTotalTimeByChannel,
      channels,
      totalEgressBytes,
      totalIngressBytes,
      totalViewerSessionsByCountry,
      browsers,
      os,
      devices,
    ] = await Promise.all([
      refetchViewers(),
      refetchEgress(),
      refetchIngress(),
      refetchViewerSessions(),
      refetchViewerBitrate(),
      refetchTTFF(),
      refetchRTT(),
      refetchViewerSessionsTotalTime(),
      refetchViewerSessionsAvgDuration(),
      refetchViewerSessionsByChannel(),
      refetchViewerSessionsTotalTimeByChannel(),
      refetchChannels(),
      refetchTotalEgressBytes(),
      refetchTotalIngressBytes(),
      refetchViewerSessionsByCountry(),
      refetchBrowsers(),
      refetchOS(),
      refetchDevices(),
    ])

    // Range values
    const viewersRangeFile = rangeCsvFile("viewers-over-time.csv", ["Time ", "Viewers"], viewers.data?.[0]?.points)
    const egressRangeFile = rangeCsvFile(
      "egress-traffic-over-time.csv",
      ["Time ", "Egress (bit/s)"],
      egress.data?.[0]?.points
    )
    const ingressRangeFile = rangeCsvFile(
      "ingress-traffic-over-time.csv",
      ["Time ", "Ingress (bit/s)"],
      ingress.data?.[0]?.points
    )

    // Instant values
    const instantData = [
      // Overview
      { header: "Sessions", data: viewerSessions.data },
      { header: "Bitrate session avg (bit/s)", data: viewerBitrate.data },
      { header: "TTFF median (ms)", data: ttff.data },
      { header: "RTT median (ms)", data: rtt.data },
      // Session & viewer time
      { header: "Total viewer time (s)", data: viewerSessionsTotalTime.data },
      { header: "Sessions avg duration (s)", data: viewerSessionsAvgDuration.data },
      // Traffic
      { header: "Total egress (bytes)", data: totalEgressBytes.data },
      { header: "Total ingress (bytes)", data: totalIngressBytes.data },
    ]
    const instantFile = instantsCsvFile("single-metrics.csv", instantData)

    // Session & viewer time table
    const channelNamesAndKeys = channels.data?.items.map(({ name, streamKey }) => ({ name, streamKey })) || []
    const mergedData = mergeSessionsAndSessionTime(
      channelNamesAndKeys,
      viewerSessionsTotalTimeByChannel.data,
      viewerSessionsByChannel.data
    )
    const sesssionsTableFile = objectListCsvFile(
      "sessions-and-viewer-time.csv",
      ["Name", "StreamKey", "Sessions", "Sessions total time (s)", "Session avg duration (s)"],
      mergedData
    )

    // Geography
    const totalSessions =
      totalViewerSessionsByCountry?.data?.reduce((accumulator, item) => accumulator + item.sessions, 0) || 0
    const dataWithDistribution = totalViewerSessionsByCountry?.data?.map((item) => ({
      ...item,
      distribution: ((item.sessions * 100) / totalSessions).toFixed(1),
    }))
    const viewerSessionsByCountryFile = objectListCsvFile(
      "sessions-by-country.csv",
      ["Country", "Country code", "Sessions", "Distribution (%)"],
      dataWithDistribution
    )

    // Devices
    const browsersFile = devicesCsvFile("browser-distribution.csv", "Browser", browsers.data)
    const osFile = devicesCsvFile("os-distribution.csv", "OS", os.data)
    const devicesFile = devicesCsvFile("device-distribution.csv", "Device", devices.data)

    const argsFile = generateArgsFile(props)

    // Create zip file and download it
    const blob = await downloadZip([
      argsFile,
      viewersRangeFile,
      egressRangeFile,
      ingressRangeFile,
      sesssionsTableFile,
      viewerSessionsByCountryFile,
      browsersFile,
      osFile,
      devicesFile,
      instantFile,
    ]).blob()
    const url = URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = `vindral-analytics-${new Date().toISOString()}.zip`
    a.click()
    URL.revokeObjectURL(url)

    setIsLoading(false)
  }

  return (
    <Button variant="primary" onClick={downloadQueryData} disabled={isLoading}>
      {isLoading ? <Spinner size="small" color="text" /> : <DownloadIcon />}
      <span className="font-normal">Export</span>
    </Button>
  )
}
