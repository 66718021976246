import { Navigate, Route } from "@tanstack/react-router"
import { toast } from "@vindral/components"
import { router } from "../../router"
import { ManageInvitations } from "../../templates/invitation/ManageInvitations"
import { trpc } from "../../trpc"
import { noNavRoute } from "../no-nav"

export const invitationRoute = new Route({
  getParentRoute: () => noNavRoute,
  preSearchFilters: [(search) => search],
  path: "invitations",
  component: Page,
  getContext: () => {
    return {
      pageTitle: "Manage Invitations",
    }
  },
  onLoad: async ({ context: { trpcContext } }) => {
    const invites = await trpcContext.auth.getInvites.ensureData(undefined)

    if (invites.length === 0) {
      void router.navigate({
        to: "/",
      })
    }
  },
})

function Page() {
  const context = trpc.useContext()
  const { data: invites = [] } = trpc.auth.getInvites.useQuery(undefined, { suspense: true })

  const acceptInviteMutation = trpc.auth.acceptInvite.useMutation({
    onSuccess: async (data) => {
      // refetch user profile to invalidate ensureData
      await context.user.profile.prefetch()
      void context.auth.getInvites.invalidate()
      toast({
        title: "Invitation accepted!",
        description: `You have been added to the organization: ${data.organizationName}`,
      })
    },
    onError: (error) => {
      toast({ title: "Failed to Accept invitation!", description: error.message })
    },
  })

  const declineInviteMutation = trpc.auth.declineInvite.useMutation({
    onSuccess: () => {
      void context.auth.getInvites.invalidate()
      toast({ title: "Invitation declined!" })
    },
    onError: (error) => {
      toast({ title: "Failed to decline invitation!", description: error.message })
    },
  })

  const acceptInvite = async (publicId: string) => {
    await acceptInviteMutation.mutateAsync({ publicId })
  }

  const declineInvite = async (publicId: string) => {
    await declineInviteMutation.mutateAsync({ publicId })
  }

  if (!invites || invites.length === 0) {
    return <Navigate to="/" search />
  }

  return <ManageInvitations invites={invites} onAccept={acceptInvite} onDecline={declineInvite} />
}
