import { EventType } from "@core-services/data-types"
import { Expose, Type } from "class-transformer"
import { Allow, IsEnum, IsOptional, IsString } from "class-validator"
import { ChannelEventExtraDTO, ChannelEventMetadataDTO } from "./channel-event-extra.dto"

export class CreateChannelEventDTO {
  constructor(props?: { [K in keyof CreateChannelEventDTO]: CreateChannelEventDTO[K] }) {
    Object.assign(this, props)
  }

  /**
   * @deprecated
   * remove as soon as traffic-router instances are updated to send "type" instead
   */
  @Expose()
  @IsEnum(EventType)
  @IsOptional()
  role?: EventType

  @Expose()
  @IsEnum(EventType)
  @IsOptional()
  type!: EventType

  @Expose()
  @IsString()
  streamKey!: string

  @Expose()
  @IsString()
  @IsOptional()
  site?: string

  @Allow()
  @Type(() => ChannelEventExtraDTO, {
    discriminator: {
      property: "__type",
      subTypes: [{ value: ChannelEventMetadataDTO, name: "rtmp_metadata" }],
    },
  })
  @Expose()
  extra?: ChannelEventMetadataDTO
}
