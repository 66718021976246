import { TransformFnParams } from "class-transformer"

export const transformDate = ({ value }: TransformFnParams) => {
  if (value instanceof Date) {
    return value
  }

  if (typeof value === "string" || typeof value === "number") {
    return new Date(value)
  }

  // TODO: Would it be safe do return undefined here?
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return value
}
