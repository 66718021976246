import { Button, Spinner, toast } from "@vindral/components"
import { useState } from "react"
import { trpc } from "../trpc"

export const TestLbNodes = ({ ingressServers }: { ingressServers: string[] | undefined }) => {
  const context = trpc.useContext()
  const [loading, setLoading] = useState(false)
  const { data: channelSearch } = trpc.channel.findMany.useQuery({ search: "vindral_demo1_", orderBy: "channelId" })
  const demoChannel = channelSearch?.items?.find((x) => x.channelId.startsWith("vindral_demo1_"))

  const onTestIngressNodes = async (nodes: string[] | undefined) => {
    setLoading(true)
    if (!nodes || nodes.length === 0) {
      toast({ title: "No ingress nodes to check", severity: "warning" })
      setLoading(false)
      return
    }

    if (!demoChannel) {
      toast({ title: "Failed to get demo channel id", severity: "warning" })
      setLoading(false)
      return
    }

    try {
      const tests = nodes.map((s) =>
        context.infrastructure.testIngressConnection.fetch({
          hostname: s.split(".")[0] || s,
          channelId: demoChannel.channelId,
        })
      )
      const data = await Promise.all(tests)

      if (data.length > 0 && data.every((x) => x)) {
        toast({ title: "All ingress nodes OK", severity: "success" })
      } else {
        toast({ title: "Ingress check failed", severity: "error" })
      }
    } catch (err) {
      toast({ title: "Ingress check failed", severity: "error" })
    }

    setLoading(false)
  }

  return (
    <Button onClick={() => onTestIngressNodes(ingressServers)} disabled={!ingressServers || !demoChannel || loading}>
      Test LB
      <div className="absolute m-auto">{loading && <Spinner size="small" />}</div>
    </Button>
  )
}
