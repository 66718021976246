import { z } from "zod"
import { Order } from "../order"

export const TranscodingProfileFindManyParameters = z
  .object({
    orderBy: z.enum(["name", "notes", "createdAt", "organization.name"]),
    search: z.string().optional(),
    filter: z
      .object({
        organization: z.object({ publicId: z.string() }).optional(),
      })
      .optional(),
  })
  .merge(Order)
