import { Light } from "@vindral/components"
import { ParentSize } from "@visx/responsive"
import * as datefns from "date-fns"
import { LinePathGraph, LinePathGraphProps } from "../../../organisms/LinePathGraph"

type BaseProps = {
  title: string
  time: {
    from: number
    to: number
    subFrom: number
    subTo: number
  }
  mainDescription: string
  subDescription?: string
}

export type BaseGraphProps = BaseProps & Omit<LinePathGraphProps, "width" | "height">

export const BaseGraph = (props: BaseGraphProps) => {
  const { title, time, mainDescription, subDescription, mainData, subData, isLoading, ...graphProps } = props

  const timeSpan = time.to - time.from
  const numDays = Math.round(timeSpan / (24 * 60 * 60))
  const displayDate = numDays > 2

  const formatXTicks = displayDate
    ? (value: Date | number) => datefns.format(new Date(value), "MMM d")
    : (value: Date | number) => datefns.format(new Date(value), "kk:mm")

  const ticks = Math.min(numDays, 14)
  const numXTicks = displayDate ? ([ticks, ticks / 2] as [number, number]) : ([10, 5] as [number, number])

  return (
    <div className="mb-8 h-72 min-w-0">
      <div className="flex items-center justify-between">
        <div className="text-sm font-medium uppercase tracking-wider text-fg-subtle">{title}</div>
        <div className="flex gap-4 pr-20 text-sm text-fg-subtle">
          <div className="flex items-center gap-2">
            <Light color="blue" />
            {mainDescription}
          </div>
          {subDescription && (
            <div className="flex items-center gap-2">
              <Light color="orange" />
              {subDescription}
            </div>
          )}
        </div>
      </div>
      <ParentSize debounceTime={10}>
        {({ width, height }) => {
          return (
            <LinePathGraph
              {...{
                ...graphProps,
                mainData,
                subData,
                width,
                height,
                isLoading,
                numXTicks,
                formatXTicks,
              }}
            />
          )
        }}
      </ParentSize>
    </div>
  )
}
