import { ChevronDownIcon } from "@radix-ui/react-icons"
import { Button, Combobox, ComboboxContent, ComboboxPortal, ComboboxTrigger } from "@vindral/components"
import { useState } from "react"

export type Item = { name: string; publicId: string } | { name: string; channelId: string }

export interface SingleSelectComboboxProps<T extends Item> {
  dataPwButton?: string
  dataPwValue?: string
  search: string
  onSearchChange: (search: string) => void
  selected: T | null
  onSelect: (item: T) => void
  placeholder?: string
  disabledText?: string
  disabled?: boolean
  isLoading?: boolean
  options: { id: string; title?: string; items: T[] }[]
  onReachedEnd?: () => void
  fullWidth?: boolean
  renderTriggerContent?: () => React.ReactNode
  buttonSize?: "small" | "medium" | "large"
}

function TriggerButtonText<T extends Item>(props: {
  disabled?: boolean
  disabledText?: string
  placeholder?: string
  selected: T | null
  dataPwValue?: string
}) {
  if (props.disabled) {
    return <span className="truncate text-fg-subtle">{props.disabledText}</span>
  }

  if (!props.selected?.name) {
    return <span className="truncate text-fg-subtle">{props.placeholder}</span>
  }

  return (
    <span className="truncate" data-pw={props.dataPwValue}>
      {props.selected.name}
    </span>
  )
}

export function SingleSelectCombobox<T extends Item>(props: SingleSelectComboboxProps<T>) {
  const [open, setOpen] = useState(false)
  return (
    <Combobox open={open} onOpenChange={setOpen}>
      <ComboboxTrigger asChild disabled={props.disabled}>
        <Button data-pw={props.dataPwButton} size={props.buttonSize || "medium"} fullWidth={props.fullWidth}>
          <div className="flex w-full justify-between gap-1">
            {props.renderTriggerContent ? props.renderTriggerContent() : <TriggerButtonText {...props} />}
            <ChevronDownIcon className="h-4 w-4" />
          </div>
        </Button>
      </ComboboxTrigger>
      <ComboboxPortal>
        <ComboboxContent
          onReachedEnd={props.onReachedEnd}
          popoverContentProps={{ align: "start", sideOffset: 4 }}
          isLoading={props.isLoading}
          groups={props.options}
          renderItem={(item) => <div>{item.name}</div>}
          selected={props.selected}
          multiple={false}
          query={props.search}
          onSelect={(selected) => {
            props.onSelect(selected)
            props.onSearchChange("")
            setOpen(false)
          }}
          getId={(item) => ("publicId" in item ? item.publicId : item.channelId)}
          onQueryChange={props.onSearchChange}
          renderTitle={(title) => (
            <div className="p-2 text-sm font-medium uppercase tracking-wider text-fg-subtle">{title}</div>
          )}
        />
      </ComboboxPortal>
    </Combobox>
  )
}
