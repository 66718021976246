import { z } from "zod"

const protocolRegex = /^[a-z][a-z0-9+\-.]*:\/\//i
const domainRegex = /^(?!-)[a-z0-9-]{1,63}(?<!-)(\.(?!-)[a-z0-9-]{1,63}(?<!-))*$/i
const ipv4WithOptionalCidrRegex =
  /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/([0-9]|[1-2][0-9]|3[0-2]))?$/
const ipv6WithOptionalCidrRegex =
  /^([0-9a-fA-F]{0,4}:){2,7}[0-9a-fA-F]{0,4}(\/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))?$/

export const HyperlocalEdge = z.object({
  enabled: z.boolean(),
  url: z
    .string()
    .refine((url) => !protocolRegex.test(url), {
      message: "URL should not contain protocol",
    })
    .refine((url) => domainRegex.test(url), {
      message: "URL should be a valid domain name",
    }),
  ip: z.string().refine((ip) => ipv4WithOptionalCidrRegex.test(ip) || ipv6WithOptionalCidrRegex.test(ip), {
    message: "Invalid IP address",
  }),
})

export type HyperlocalEdge = z.infer<typeof HyperlocalEdge>

export const UpdateHyperlocalEdges = z.object({
  organizationId: z.string(),
  edges: z.array(HyperlocalEdge).max(5),
})

export type UpdateHyperlocalEdges = z.infer<typeof UpdateHyperlocalEdges>
